import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {ButtonToolbar, Form, OverlayTrigger, Popover} from 'react-bootstrap';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';

function CustomerInputComponent(props) {
  const {label, maxLength, oneRow, code, required, error, value, field} = props;
  const isOptional = !required;

  const {t} = useTranslation();
  const popoverRight = (
    <Popover id="popover">
      <div className="popover-content">
        {t(error) }
      </div>
    </Popover>
  );
  const ref = useRef(null);
  const target = useRef(null);
  const inputRef = useRef(null);
  const wrapperClass = oneRow ? "col-sm-12" : "col-sm-6";
  const inputType = code === 'email' ? "email" : "text";
  const [canClear, setCanClear] = useState(false);
  const labelContent =
    isOptional ? t('{{label}} ', {label}) : <span className="asterisk-required">{t(label)}</span>;
  const handleOnBlur = () => {
    setTimeout(() => {
      setCanClear(false);
    }, 200);
    props.handleOnblur(field, inputRef.current.value);
  };
  const handleOnFocus = () => {
    setCanClear(true);
  };
  const clearInput = () => {
    if (inputRef) {
      inputRef.current.value = '';
    }
    props.setFieldValue(field, '');
  };
  const valueInput = value ? value : '';
  const clearClass = (value && canClear) ? "btn-remove show" : "btn-remove hidden";
  const errorTooltip = error ? (
    <ButtonToolbar className="validation-advice open" >
      <OverlayTrigger
        trigger={['click', 'hover', 'focus']}
        rootClose
        placement="bottom"
        overlay={popoverRight}
        container={ref}
        target={target}
      >
        <span className="dropdown-toggle" ref={target} />
      </OverlayTrigger>
    </ButtonToolbar>
  ) : null;
  return (
    <div id={`${code}_root`} className={wrapperClass} ref={ref}>
      <Form.Label><span>{labelContent}</span></Form.Label>
      <Form.Control
        maxLength={maxLength}
        key={code}
        id={code}
        type={inputType}
        className="form-control"
        value={valueInput}
        ref={inputRef}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onChange={(event) => props.setFieldValue(field, event.target.value)}
      />
      {errorTooltip}
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
      <a id={`${code}_clear`} onClick={clearInput} className={clearClass} />
    </div>
  );
}

CustomerInputComponent.className = 'CustomerInputComponent';
CustomerInputComponent.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number,
  oneRow: PropTypes.bool,
  code: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  setFieldValue: PropTypes.func,
  error: PropTypes.string,
  field: PropTypes.object,
  handleOnblur: PropTypes.func,
};

export default React.memo(FunctionComponentFactory.get(CustomerInputComponent));
