import AbstractResourceModel from "./AbstractResourceModel";

export default class CountryResourceModel extends AbstractResourceModel {
  static className = 'CountryResourceModel';

  resourceName = 'Country';

  /**
   * Get all countries online
   * @returns {*}
   */
  getAllCountriesOnline() {
    return this.getResourceOnline().getAllCountriesOnline();
  }
}
