import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import Config from '../../config/Config';
import PermissionConstant from '../../view/constant/PermissionConstant';

/**
 * Staff Service Class
 */
export class PermissionServiceClass extends CoreService {
  static className = 'PermissionServiceClass';

  /**
   * Get Permissions
   * @returns {*|*[]}
   */
  getPermissions() {
    if (Config && Config.permissions) {
      return Config.permissions;
    }
    return [];
  }

  /**
   *  check permission for component
   *
   * @param {string} acl
   * @return {boolean}
   */
  isAllowed(acl = '') {
    const permissions = this.getPermissions();
    if (permissions.indexOf(PermissionConstant.PERMISSION_TYPE_ALL) !== -1) {
      return true;
    }
    if (acl && permissions.length) {
      return permissions.indexOf(acl) !== -1;
    }
    return true;
  }
}

/**
 * @type {PermissionServiceClass}
 */
const PermissionService = ServiceFactory.get(PermissionServiceClass);

export default PermissionService;
