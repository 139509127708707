import {ApolloClient, InMemoryCache, HttpLink, ApolloLink} from '@apollo/client';
import {onError} from "@apollo/client/link/error";

import {fire} from '../../../event-bus';

/**
 * get base url
 * @returns {string}
 */
function getBaseUrl() {
  return process.env.REACT_APP_API_URL;
}

function getUri() {
  return `${getBaseUrl()}graphql`;
}

/**
 * Check force sign out when request get error code
 *
 * @param data
 * @param responseCode
 */
function checkForceSignOut(data, responseCode) {
  fire('omc_check_force_sign_out', {
    data,
    responseCode,
  });
}

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions,
  link: ApolloLink.from([
    onError(({graphQLErrors}) => {
      if (!(graphQLErrors && graphQLErrors.length)) {
        return;
      }
      let forceSignOut = false;
      let message = '';
      // eslint-disable-next-line @shopify/prefer-early-return
      graphQLErrors.forEach((graphQLError) => {
        if (graphQLError.extensions && graphQLError.extensions.category === "graphql-authorization") {
          forceSignOut = true;
          message = graphQLError.message;
        }
      });
      if (forceSignOut) {
        return checkForceSignOut(graphQLErrors, message);
      }
    }),
    new HttpLink({uri: getUri}),
  ]),
});

export default client;
