import _ from 'lodash';

import CoreService from "../CoreService";
import ServiceFactory from "../../framework/factory/ServiceFactory";
import Config from '../../config/Config';
import CustomerPopupConstant
  from '../../view/constant/customer/CustomerPopupConstant';

import CustomerPopupService from './CustomerPopupService';

export class CustomerFieldServiceClass extends CoreService {
  static className = 'CustomerFieldService';

  getCustomerFormConfig() {
    const customerFields = [];
    if (!Config.config) {
      return [];
    }
    const customerAttributeFormMetadata = _.cloneDeep(Config.config.customerAttributeFormMetadata);
    customerAttributeFormMetadata.sort((item1, item2) => item1.sortOrder - item2.sortOrder);
    customerAttributeFormMetadata.forEach((element) => {
      if (!element.visible) {
        return;
      }
      if (element.frontendInput === CustomerPopupConstant.FRONTEND_INPUT_TYPE_TEXT) {
        let requiredEmail = false;
        if (element.attributeCode === CustomerPopupConstant.ATTRIBUTE_CODE_EMAIL) {
          requiredEmail = true;
        }
        customerFields.push(
          CustomerPopupService.createCustomerFieldInput(
            element.attributeCode,
            element.attributeCode,
            CustomerPopupConstant.TYPE_FIELD_INPUT,
            element.frontendLabel,
            element.required,
            requiredEmail,
            element.maxLength,
            false,
          ),
        );
      } else if (element.frontendInput === CustomerPopupConstant.FRONTEND_INPUT_TYPE_SELECT) {
        const options = element.options;
        customerFields.push(
          CustomerPopupService.createCustomerFieldSelect(
            element.attributeCode,
            element.attributeCode,
            CustomerPopupConstant.TYPE_FIELD_SELECT,
            element.frontendLabel,
            element.required,
            options,
            false,
          ),
        );
      } else if (element.frontendInput === CustomerPopupConstant.FRONTEND_INPUT_TYPE_COMBOBOX) {
        customerFields.push(
          CustomerPopupService.createCustomerFieldCombobox(
            element.attributeCode,
            element.attributeCode,
            CustomerPopupConstant.TYPE_FIELD_COMBOBOX,
            element.frontendLabel,
            element.required,
            element.options,
            true,
          ),
        );
        customerFields.push(
          CustomerPopupService.createCustomerFieldComboboxSuggest(
            `${element.attributeCode}_suggest`,
            `${element.attributeCode}_suggest`,
            CustomerPopupConstant.TYPE_FIELD_COMBOBOX_SUGGEST,
            element.frontendLabel,
            element.required,
            element.options,
            true,
          ),
        );
      }
    });
    return customerFields;
  }
}

/** @type CustomerFieldServiceClass */
const customerDefaultFieldService = ServiceFactory.get(CustomerFieldServiceClass);

export default customerDefaultFieldService;
