import DataResourceFactory from "../../framework/factory/DataResourceFactory";
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';

import OmcAbstract from "./OmcAbstract";
import GraphqlSession from "./graphql/GraphqlSession";

export class OmcSession extends OmcAbstract {
  static className = 'OmcSession';

  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlSession,
  };

  /**
   * Get Sessions
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getSessions(input) {
    return this.getApiResource().getSessions(input);
  }

  /**
   * Get Session Detail
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getSessionDetail(input) {
    return this.getApiResource().getSessionDetail(input);
  }

  /**
   * Open Session
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  openSession(input) {
    return this.getApiResource().openSession(input);
  }

  /**
   * Close Session
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  closeSession(input) {
    return this.getApiResource().closeSession(input);
  }

  /**
   * Put Money In
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  addTransaction(input) {
    return this.getApiResource().addTransaction(input);
  }

}

/**
 * @type {OmcSession}
 */
export default DataResourceFactory.get(OmcSession);
