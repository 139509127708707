import AbstractResourceModel from "../AbstractResourceModel";

export default class CustomerResourceModel extends AbstractResourceModel {
  static className = 'CustomerResourceModel';

  resourceName = 'Customer';

  /**
   * Get Customers
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getCustomers(input) {
    return this.getResourceOnline().getCustomers(input);
  }

  /**
   * Save Customer
   * @param customer
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  saveCustomer(customer) {
    return this.getResourceOnline().saveCustomer(customer);
  }

  /**
   * Get customer by id
   * @param customerId
   * @returns {*|null}
   */
  getCustomer(customerId) {
    return this.getResourceOnline().getCustomer(customerId);
  }
}
