import DataResourceFactory from "../../framework/factory/DataResourceFactory";
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';

import OmcAbstract from "./OmcAbstract";
import GraphqlShipping from './graphql/GraphqlShipping';

export class OmcShipping extends OmcAbstract {
  static className = 'OmcShipping';

  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlShipping,
  };

  /**
   * Get Address listing by customer id
   * @param customerId
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getAddressListingByCustomerId(customerId) {
    return this.getApiResource().getAddressListingByCustomerId(customerId);
  }
}

/**
 * @type {OmcShipping}
 */
export default DataResourceFactory.get(OmcShipping);
