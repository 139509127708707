const LoadingConstant = {
  UPDATE_FINISHED_LIST: '[LOADING] UPDATE_FINISHED_LIST',
  RESET_STATE: '[LOADING] RESET_STATE',
  CLEAR_DATA: '[LOADING] CLEAR_DATA',
  CLEAR_DATA_SUCCESS: '[LOADING] CLEAR_DATA_SUCCESS',
  CLEAR_DATA_ERROR: '[LOADING] CLEAR_DATA_ERROR',

  MAX_REQUEST_TIME: 10,
};
export default LoadingConstant;
