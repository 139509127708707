import React, {useEffect, useState} from 'react';
import 'react-circular-progressbar/dist/styles.css';
import {useDispatch, useSelector} from 'react-redux';

import FunctionComponentFactory
  from '../../../framework/factory/FunctionComponentFactory';
import OpenMenuButton from '../menu/OpenMenuButton.jsx';
import BackButton from '../menu/BackButton.jsx';
import CheckoutAction from '../../action/checkout/CheckoutAction';
import PrintReceiptComponent from '../print/PrintReceiptComponent.jsx';
import OrderAction from '../../action/OrderAction';
import QuoteAction from '../../action/checkout/QuoteAction';
import QuoteService from '../../../service/checkout/QuoteService';

import Cart from './Cart.jsx';
import CartHeader from './cart/CartHeader.jsx';
import LeftAction from './LeftAction.jsx';
import ProductList from './catalog/ProductList.jsx';
import Payment from './Payment.jsx';
import ConflictProductAutoDiscountPopup from './discount/discount-conflict/ConflictProductAutoDiscountPopup.jsx';
import ConflictProductCouponDiscountPopup from './discount/discount-conflict/ConflictProductCouponDiscountPopup.jsx';
import ConflictCustomOrderDiscountPopup from './discount/discount-conflict/ConflictCustomOrderDiscountPopup.jsx';

function Checkout() {
  const dispatch = useDispatch();
  const {currentPage} = useSelector((state) => state.core.checkout.index);
  const {quote} = useSelector((state) => state.core.checkout);

  const [pages, setPages] = useState([]);
  const {isPrintReceipt} = useSelector((state) => state.core.order.orderComplete);

  useEffect(() => {
    const newPages = [
      ProductList,
      Payment,
    ];
    setPages(newPages);
  }, []);

  useEffect(() => {
    if (currentPage !== ProductList.className) {
      return;
    }
    if (QuoteService.isAppliedDiscountExceptCustomDiscountLineItem(quote)) {
      dispatch(QuoteAction.removeQuoteDiscountExceptCustomDiscount(quote));
    }
  }, [currentPage, dispatch, quote]);

  const getMenuButton = () => {
    if (currentPage !== ProductList.className) {
      return null;
    }
    return <OpenMenuButton />;
  };

  const onClickBack = () => {
    dispatch(CheckoutAction.checkoutToCatalog());
  };

  const getBackButton = () => {
    if (currentPage === ProductList.className) {
      return null;
    }
    return <BackButton onClickBack={onClickBack} />;
  };

  function printReceiptAfterCreateOrderSuccess() {
    dispatch(OrderAction.printReceiptAfterCreateOrderSuccess());
  }
  function printReceiptAfterCreateOrderFailed() {
    dispatch(OrderAction.printReceiptAfterCreateOrderFailed());
  }

  return (
    <>
      {getMenuButton()}
      {getBackButton()}
      <CartHeader />
      <LeftAction />
      <Cart />
      {
        pages.map((page) => {
          const Element = page.type || page;
          return currentPage === Element.className && (
            <Element key={Element.className} />
          );
        })
      }
      <div style={{display: 'none'}} >
        {
          isPrintReceipt && <PrintReceiptComponent onAfterPrint={printReceiptAfterCreateOrderSuccess} onPrintError={printReceiptAfterCreateOrderFailed} isReprint={false} />
        }
      </div>
      <ConflictProductAutoDiscountPopup />
      <ConflictProductCouponDiscountPopup />
      <ConflictCustomOrderDiscountPopup />
    </>
  );
}

Checkout.className = 'Checkout';
Checkout.propTypes = {};

export default FunctionComponentFactory.get(Checkout);
