import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from './GraphqlAbstract';

export class GraphqlStockClass extends GraphqlAbstract {
  static className = 'GraphqlStockClass';

  /**
   * Get Stock
   *
   * @param id
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getStockOneVariantProduct(id) {
    return this.queryWithGQLBuilder(
      'getStockOneVariantProduct',
      {
        id: {
          name: 'id',
          type: 'String!',
          value: id || null,
        },
      },
      [
        'id',
        'name',
        'address1',
        'address2',
        'city',
        'country',
        'phone',
        'province',
        'zip',
        'available',
        'isActive',
      ],
      {
        operationName: 'getStockOneVariantProduct',
      },
    );
  }

  /**
   * Get Stock by product options
   * @param id
   * @param productOptions
   * @returns {*}
   */
  getStockByProductOptions(id, productOptions) {
    return this.queryWithGQLBuilder(
      'getStockByProductOptions',
      {
        input: {
          name: 'input',
          type: 'SelectedProductOptionsInput!',
          value: {
            product_id: id,
            selected_options: productOptions,
          },
        },
      },
      [
        'id',
        'name',
        'address1',
        'address2',
        'city',
        'country',
        'phone',
        'province',
        'zip',
        'available',
        'isActive',
      ],
      {
        operationName: 'getStockByProductOptions',
      },
    );
  }
}

/**
 * @type {GraphqlStockClass}
 */
export default DataResourceFactory.get(GraphqlStockClass);
