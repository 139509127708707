
import React, {Fragment, useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {cloneDeep} from 'lodash';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';
import "../../../../../assets/AddNote.css";
import QuoteAction from '../../../../action/checkout/QuoteAction';

const AddNoteComponent = () => {
  const {quote} = useSelector((state) => state.core.checkout);
  const [isOpenAddNotePopup, setIsOpenAddNotePopup] = useState(false);
  let buttonClass = 'btn btn-add-comment';
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [defaultNote, setDefaultNote] = useState("");
  const [comment, setComment] = useState(defaultNote);

  useEffect(() => {
    setDefaultNote(quote.note || "");
    setComment(quote.note || "");
  }, [quote]);

  const [btnSaveClassName, setBtnSaveClassName] = useState(comment && comment.length > 0 ? "save" : "save disabled");


  /**
   * Add new comment
   */
  function saveComment() {
    if (!comment) {
      return;
    }
    const newQuote = {
      ...cloneDeep(quote),
      note: comment,
    };
    dispatch(QuoteAction.setQuote(newQuote));
    toggle();
  }

  /**
   * toggle popup
   */

  function toggle() {
    setIsOpenAddNotePopup(!isOpenAddNotePopup);
  }

  /**
   * cancel add note
   */
  function cancelAddComment() {
    toggle();
    setComment(defaultNote);
  }

  /**
   * comment change
   * @param event
   */
  function commentChange(event) {
    if (event.target.value) {
      setComment(event.target.value);
      setBtnSaveClassName("save");
    } else {
      setComment("");
      setBtnSaveClassName("save disabled");
    }
  }

  /**
   * check delete cart or not
   *
   * @returns {boolean}
   */
  const canAddComment = () => {
    return quote && quote.id;
  };

  /**
   * Cart button handle
   */
  const showAddCommentModal = () => {
    toggle();
  };

  let isDisabled = false;
  if (!canAddComment()) {
    buttonClass += ' disabled';
    isDisabled = true;
  }

  /**
   * handle on hide popup
   */
  const handleOnHidePopup = () => {
    toggle();
    setComment(defaultNote);
  };

  const spanCommentContent = "comment";
  return (
    <>
      <button
        className={buttonClass} disabled={isDisabled} type="button"
        onClick={() => showAddCommentModal()}
      >
        <span>{spanCommentContent}</span>
      </button>
      <Modal
        size="lg"
        className="popup-messages popup-add-comment"
        show={isOpenAddNotePopup}
        onHide={handleOnHidePopup}
      >
        <div className="modal-header">
          <button
            type="button" className="cancel" data-dismiss="modal" aria-label={t("Close")}
            onClick={() => cancelAddComment()}
          >
            {t('Cancel')}
          </button>
          <h4 className="modal-title">{t('Add Note')}</h4>
          <button
            type="button" className={btnSaveClassName}
            onClick={() => saveComment()}
          >
            {t('Save')}
          </button>
        </div>
        <div data-scrollbar className="modal-body">
          <div className="add-comment-order">
            <div className="box-text-area">
              <textarea
                className="form-control"
                placeholder={t('Add note for this order')}
                onChange={(event) => commentChange(event)}
                value={comment}
                aria-label={t("")}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

AddNoteComponent.className = 'AddNoteComponent';
AddNoteComponent.propTypes = {
};

export default FunctionComponentFactory.get(AddNoteComponent);
