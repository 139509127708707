import _ from 'lodash';

import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import Config from '../../config/Config';

/**
 * Address Service Class
 */
export class AddressServiceClass extends CoreService {
  static className = 'AddressServiceClass';

  PREFIX = 'UNSAVED_';

  /**
   * Generate unsaved id
   * @returns {string}
   */
  generateUnsavedId() {
    return this.PREFIX + Date.now();
  }

  /**
   * Is unsaved address
   * @param addressId
   * @returns {boolean}
   */
  isUnsavedAddress(addressId) {
    return Boolean(addressId.includes(this.PREFIX));
  }

  /**
   * Is add new address or not
   * @param addressListing
   * @param address
   * @returns {boolean}
   */
  isAddNewAddress(addressListing, address) {
    if (!addressListing) {
      return true;
    }
    const findAddress = addressListing.find((addressItem) => {
      return addressItem.id === address.id;
    });
    return !findAddress;
  }

  /**
   * Update address listing
   * @param addressListing
   * @param address
   * @returns {*}
   */
  updateAddress(addressListing, address) {
    return addressListing.map(
      (addressItem) => {
        if (addressItem.id === address.id) {
          return address;
        }
        return addressItem;
      },
    );
  }

  /**
   * Convert address in form to address in address listing
   * @param address
   * @returns {*}
   */
  convertAddressInFormToAddressListing(address) {
    const CountryService = require('../CountryService').default;
    const newAddress = _.cloneDeep(address);
    newAddress.countryCode = address.country;
    newAddress.provinceCode = address.zone ? address.zone : '';
    newAddress.zip = address.postalCode;
    const countryData = CountryService.loadCountryData(newAddress.countryCode);
    newAddress.country = countryData.name;
    const province = countryData.zones.find(
      (zone) => {
        return zone.code === newAddress.provinceCode;
      },
    );
    if (province) {
      newAddress.province = province.name;
    }
    delete newAddress.postalCode;
    delete newAddress.zone;
    return newAddress;
  }

  /**
   * Convert address in listing to address in form
   * @param address
   * @returns {*}
   */
  convertAddressInAddressListingToAddressInForm(address) {
    const newAddress = _.cloneDeep(address);
    newAddress.country = address.countryCode;
    newAddress.zone = address.provinceCode;
    newAddress.postalCode = address.zip;
    delete newAddress.countryCode;
    delete newAddress.provinceCode;
    delete newAddress.zip;
    return newAddress;
  }

  /**
   * Check if the same empty
   * @param value1
   * @param value2
   * @returns {boolean}
   */
  isTheSameEmpty(value1, value2) {
    return _.isEmpty(value1) && _.isEmpty(value2);
  }

  /**
   * Is address exist or not
   * @param address
   * @param addressListing
   * @returns {boolean}
   */
  isAddressExist(address, addressListing) {
    if (!addressListing) {
      return false;
    }
    const findAddress = addressListing.find(
      (addressItem) => {
        let isTheSame = true;
        if (address.id === addressItem.id) {
          return false;
        }
        [
          'firstName',
          'lastName',
          'countryCode',
          'provinceCode',
          'zip',
          'city',
          'phone',
          'address1',
          'address2',
          'company',
        ].forEach(
          (fieldCode) => {
            if (!this.isTheSameEmpty(addressItem[fieldCode], address[fieldCode]) && addressItem[fieldCode] !== address[fieldCode]) {
              isTheSame = false;
            }
          },
        );
        return isTheSame;
      },
    );
    if (findAddress) {
      return findAddress;
    } else {
      return false;
    }
  }

  /**
   * Get default country
   * @returns {*|string}
   */
  getDefaultCountry() {
    return Config.config ? Config.config.shopConfig.defaultCountryCode : '';
  }
}


/**
 * @type {AddressServiceClass}
 */
const addressService = ServiceFactory.get(AddressServiceClass);

export default addressService;
