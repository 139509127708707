const CustomerPopupConstant = {
  POPUP_TYPE_CUSTOMER_LIST: "customer_list",
  POPUP_TYPE_CUSTOMER: "customer",
  POPUP_TYPE_ADDRESS: "address",
  TYPE_FIELD_INPUT: 'input',
  TYPE_FIELD_GROUP: 'group',
  TYPE_FIELD_CHECKBOX: 'checkbox',
  TYPE_FIELD_SELECT: 'select',
  TYPE_FIELD_STATE: 'state',
  TYPE_FIELD_DATE: 'date',
  TYPE_FIELD_COMBOBOX: 'combobox',
  TYPE_FIELD_COMBOBOX_SUGGEST: 'combobox_suggest',

  FRONTEND_INPUT_TYPE_SELECT: 'select',
  FRONTEND_INPUT_TYPE_TEXT: 'text',
  FRONTEND_INPUT_TYPE_MULTILINE: 'multiline',
  FRONTEND_INPUT_TYPE_HIDDEN: 'hidden',
  FRONTEND_INPUT_TYPE_BOOLEAN: 'boolean',
  FRONTEND_INPUT_TYPE_DATE: 'date',
  FRONTEND_INPUT_TYPE_COMBOBOX: 'combobox',

  VALIDATION_RULE_MAX_LENGTH: 'max_text_length',
  VALIDATION_RULE_MIN_LENGTH: 'min_text_length',

  ATTRIBUTE_CODE_POSTCODE: 'postcode',
  ATTRIBUTE_CODE_STREET: 'street',
  ATTRIBUTE_CODE_VAT: 'vat_id',
  ATTRIBUTE_CODE_COUNTRY: 'country_id',
  ATTRIBUTE_CODE_REGION: 'region',
  ATTRIBUTE_CODE_REGION_ID: 'region_id',
  ATTRIBUTE_CODE_EMAIL: 'email',
  ATTRIBUTE_CODE_GROUP_ID: 'group_id',
};

export default CustomerPopupConstant;
