import {cloneDeep} from 'lodash';

import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import ProductResourceModel
  from '../../resource-model/product/ProductResourceModel';
import ConfigService from '../config/ConfigService';
import ProductConstant from '../../view/constant/ProductConstant';
import CurrencyService from '../CurrencyService';
import {ProductPlaceHolder} from '../../assets/images';
import DeviceService from '../DeviceService';
import PermissionService from '../permission/PermissionService';
import PermissionConstant from '../../view/constant/PermissionConstant';

/**
 * Staff Service Class
 */
export class ProductServiceClass extends CoreService {
  static className = 'ProductServiceClass';
  resourceModel = ProductResourceModel;

  /**
   * Get Products
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProducts(input) {
    return this.getResourceModel().getProducts(input);
  }

  /**
   * Is Product Available
   * @param product
   * @returns {boolean}
   */
  isProductAvailable(product) {
    return this.isSalable(product);
  }

  /**
   * Check product is composite
   *
   * @param product
   * @return {boolean}
   */
  isComposite(product) {
    return !product.hasOnlyDefaultVariant;
  }

  /**
   * Is Manage Stock
   * @param product
   * @returns {*}
   */
  isManageStock(product) {
    return product.tracksInventory;
  }

  /**
   * Verify Stock
   *
   * @param product
   * @returns {boolean}
   */
  verifyStock(product) {
    return !this.isComposite(product) && !product.hasOutOfStockVariants;
  }

  /**
   * @param product
   * @return {boolean}
   */
  isSalable(product) {
    if (!this.isActive(product)) {
      return false;
    }

    if (ConfigService.isAllowToAddOutOfStockProduct()) {
      return product.status === ProductConstant.STATUS_ACTIVE;
    }

    if (!this.isComposite(product)) {
      if (!this.isManageStock(product)) {
        return true;
      }
      return this.verifyStock(product);
    }
    return product.status === ProductConstant.STATUS_ACTIVE;
  }

  /**
   * Is Active
   * @param product
   * @returns {boolean}
   */
  isActive(product) {
    return product.status === ProductConstant.STATUS_ACTIVE;
  }

  /**
   * Get Product Name
   * @param product
   * @returns {*}
   */
  getProductName(product) {
    if (!product) {
      return '';
    }
    return product.title;
  }

  /**
   * Get Product Image
   * @param product
   * @returns {*|null}
   */
  getProductImage(product) {
    if (product && product.featuredImage && product.featuredImage.url) {
      return product.featuredImage.url;
    }
    return ProductPlaceHolder;
  }

  getDisplayPrice(product) {
    let displayPrice = '';
    if (product && product.priceRangeV2 && product.priceRangeV2.minVariantPrice) {
      displayPrice = CurrencyService.format(product.priceRangeV2.minVariantPrice.amount);
    }
    return displayPrice;
  }

  /**
   * Get Additional Attributes In List
   * @returns {string[]}
   */
  getAdditionalAttributesInList() {
    return [
      'sku',
    ];
  }

  /**
   * Check is show external stock
   *
   * @param product
   * @returns {boolean}
   */
  isShowExternalStock(product) {
    return !DeviceService.isMobile() && this.isManageStock(product);
  }

  /**
   * Is Allow Check External Stock
   * @returns {boolean}
   */
  isAllowCheckExternalStock() {
    return PermissionService.isAllowed(PermissionConstant.PERMISSION_CHECK_EXTERNAL_STOCK);
  }

  /**
   * Get product options
   * @param id
   * @param isIncludeCollection
   * @returns {*}
   */
  getProductOptions(id, isIncludeCollection = false) {
    return this.getResourceModel().getProductOptions(id, isIncludeCollection);
  }

  /**
   * Get Product With One Variant
   *
   * @param id
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProductWithOneVariant(id) {
    return this.getResourceModel().getProductWithOneVariant(id);
  }

  /**
   * Search By Barcode
   * @param barcode
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  searchByBarcode(barcode) {
    const query = `barcode:${barcode}`;
    return this.getResourceModel().searchByBarcode(query);
  }

  /**
   * Process Barcode
   *
   * @param barcode
   * @param state
   * @returns {Promise<unknown>|Promise<ApolloQueryResult<*>>}
   */
  processBarcode(barcode, state) {
    const allItems = state.core.checkout.quote.lineItems;
    let isExistProductInCart = false;
    let variantToSearch = {};
    allItems.map((item) => {
      if (item.variant.barcode &&
          item.variant.barcode === barcode
      ) {
        isExistProductInCart = true;
        variantToSearch = cloneDeep(item.variant);
        variantToSearch.product = cloneDeep(item.product);
      }
      return item;
    });
    if (isExistProductInCart) {
      return new Promise((resolve) => {
        resolve({
          data: {
            searchByBarcode: {
              items: [variantToSearch],
              pageInfo: {
                hasNextPage: false,
                hasPreviousPage: false,
              },
            },
          },
        });
      });
    } else {
      return this.searchByBarcode(barcode);
    }
  }

  /**
   * get products by collection id
   *
   * @param questionId
   * @param productInput
   * @returns {Promise<unknown>|Promise<ApolloQueryResult<*>>}
   */
  getProductsByCollectionId(collectionId, productInput) {
    return this.getResourceModel().getProductsByCollectionId(collectionId, productInput);
  }


  /**
   * Get Product Variant By Product Options
   * @param productId
   * @param selectedOptions
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProductVariantByProductOptions(productId, selectedOptions) {
    return this.getResourceModel().getProductVariantByProductOptions(productId, selectedOptions);
  }

  /**
   * Get Product Variant By Their Ids
   * @param variantIds
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProductVariants(variantIds) {
    return this.getResourceModel().getProductVariants(variantIds);
  }

  /** Get Product Variant By Ids Variant
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getQuantityProductVariant(input) {
    return this.getResourceModel().getQuantityProductVariant(input);
  }
}


/**
 * @type {ProductServiceClass}
 */
const productService = ServiceFactory.get(ProductServiceClass);

export default productService;
