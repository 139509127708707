import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import localStorageService from '../../../service/LocalStorageService';
import LocalStorageConstant from '../../constant/LocalStorageConstant';
import LocationService from '../../../service/location/LocationService';
import OrderService from '../../../service/order/OrderService';
import orderConstant from '../../constant/OrderConstant';

function HeaderReceiptComponent(props) {
  const {t} = useTranslation();
  const {orderData, storeInfo, printType} = props;

  function templateCompanyName() {
    const companyName = storeInfo.name;

    const className = (companyName) ? "title" : "hidden";
    return (
      <div id="receipt-company-name" className={className}>{companyName}</div>
    );
  }

  const printTypeTitle = printType ? (
    <>
      <div id="receipt-company-name" className="title">{printType}</div>
    </>
  ) : null;

  function templateLocationName() {
    const locationName = localStorageService.get(LocalStorageConstant.LOCATION_NAME);
    const className = (locationName) ? "" : "hidden";
    return (
      <>
        <p id="receipt-location-name" className={className}>{locationName}</p>
        <div className="spacer" />
      </>
    );
  }
  function concatStrings(obj, seperate = " ") {
    const filterObj = Object.keys(obj).reduce((filterObjResult, key) => {
      if (obj[key]) {
        filterObjResult[key] = obj[key];
      }
      return filterObjResult;
    }, {});
    let concatResult = "";
    Object.values(filterObj).forEach((value, index) => {
      if (!value) {
        return;
      }
      concatResult += `${value}`;
      if (index < Object.values(filterObj).length - 1) {
        concatResult += `${seperate}`;
      }
    });
    return concatResult;
  }
  function templateLocationAddress() {
    const locationAddress = LocationService.getAddressCurrentLocation();
    const {address1, address2, province, city, zip, country} = locationAddress;
    const address = {
      address1,
      address2,
      province,
      city,
      zip,
      country,
    };
    const locationAddressDisplay = concatStrings(address, ', ');
    const className = (locationAddress) ? "" : "hidden";
    return (
      <p id="receipt-location-address" className={className}>{locationAddressDisplay}</p>
    );
  }

  function templateLocationTelephone() {
    const locationAddress = LocationService.getAddressCurrentLocation();
    const {phone} = locationAddress;
    const className = (phone) ? "" : "hidden";
    return (
      <p id="receipt-location-telephone" className={className}>{phone}</p>
    );
  }

  function templateIncrementId() {
    const incrementId = (orderData.name) ? orderData.name : "";

    const createAt = moment(orderData.createdAt).format('L LT');

    return (
      <tr id="receipt-increment-id">
        <td>{incrementId}</td>
        <td className="text-right">{createAt}</td>
      </tr>
    );
  }

  function templateLocationPlacedOrder() {
    const location = OrderService.getCustomAttributeByKey(orderData, orderConstant.CUSTOM_ATTRIBUTE_KEY_LOCATION);
    if (location) {
      return (
        <tr id="receipt-location-placed-order" className="created-at-location-field">
          <td>{t('Created At')}</td>
          <td className="text-right">{location}</td>
        </tr>
      );
    }
  }

  function templateCashier() {
    const staff = OrderService.getCustomAttributeByKey(orderData, orderConstant.CUSTOM_ATTRIBUTE_KEY_CASHIER);

    if (staff) {
      return (
        <tr id="receipt-cashier">
          <td>{t('Cashier')}</td>
          <td className="text-right">{staff}</td>
        </tr>
      );
    }
  }

  function templateCustomerName() {
    const customerName = orderData.customer?.displayName;
    const className = customerName ? "" : "hidden";
    return (
      <tr id="receipt-customer-name" className={className}>
        <td>{t('Customer')}</td>
        <td className="text-right">{customerName}</td>
      </tr>
    );
  }

  function templatePhone() {
    const phone = orderData.customer?.phone;
    const className = phone ? "" : "hidden";
    return (
      <tr id="receipt-phone" className={className}>
        <td>{t('Phone')}</td>
        <td className="text-right">{phone}</td>
      </tr>
    );
  }

  return (
    <>
      <div className="text-center">
        {templateCompanyName()}
        {templateLocationName()}
        {templateLocationAddress()}
        {templateLocationTelephone()}
        {printTypeTitle}
      </div>
      <table>
        <tbody>
          <div className="spacer" />
          <tr>
            <td colSpan="4">
              <hr />
            </td>
          </tr>
          <div className="spacer" />
          {templateIncrementId()}
          {templateLocationPlacedOrder(0)}
          {templateCashier()}
          {templateCustomerName()}
          {templatePhone()}
          <div className="spacer" />
          <tr>
            <td colSpan="4">
              <hr />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

}

HeaderReceiptComponent.className = 'HeaderReceiptComponent';
HeaderReceiptComponent.propTypes = {
  orderData: PropTypes.object,
  storeInfo: PropTypes.object,
  printType: PropTypes.string,
};

export default FunctionComponentFactory.get(HeaderReceiptComponent);
