import React, {Fragment} from "react";
import {Form} from "react-bootstrap";
import PropTypes from "prop-types";

import FunctionComponentFactory from "../../../../../../framework/factory/FunctionComponentFactory";
import i18n from "../../../../../../config/i18n";

import '../../../../../../assets/CreateShipment.css';

function CreateShipment(props) {
  const {isCreateShipment, fulfillAllItemsChange, canChange} = props;

  const labelClassName = canChange ? "checkbox" : "checkbox not-allowed-check-box";
  return (
    <>
      <div className="payment-full-amount shipping-toggle-button">
        <div className="info">
          <div className="price">
            <div className="box">
              <span className="label">{i18n.t('Fulfill all items?')}</span>
              <span className="value">
                <Form.Label className={labelClassName}>
                  <Form.Control
                    type="checkbox"
                    checked={isCreateShipment}
                    onChange={fulfillAllItemsChange}
                  />
                  <span />
                </Form.Label>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

CreateShipment.className = 'CreateShipment';
CreateShipment.propTypes = {
  isCreateShipment: PropTypes.bool,
  canChange: PropTypes.bool,
  fulfillAllItemsChange: PropTypes.func,
};
export default FunctionComponentFactory.get(CreateShipment);
