import {cloneDeep} from 'lodash';

import ServiceFactory from "../../../framework/factory/ServiceFactory";
import NumberService from '../../NumberService';

import AbstractQuoteService from "./AbstractService";
import AddProductService from './AddProductService';

export class QuoteUpdateProductServiceClass extends AbstractQuoteService {
  static className = 'QuoteUpdateProductServiceClass';

  /**
   * Update qty after change on number pad
   * @param quote
   * @param item
   * @param qty
   * @return {*}
   */
  updateQty(quote, item, qty) {
    const newQuote = cloneDeep(quote);
    let quantity = qty;
    if (!quantity) {
      quantity = 0;
    }
    const product = item.product;
    const variant = item.variant;

    let totalQty = this.getProductTotalItemsQtyInCart(null, newQuote, variant.id);
    totalQty = NumberService.addNumber(totalQty, quantity, -item.quantity);

    if (!item.custom) {
      const validateQtyResult = AddProductService.getAddProductService(product).validateQty(product, variant, quantity, totalQty);
      if (!validateQtyResult.success) {
        return validateQtyResult;
      }
    }

    const existedItem = newQuote.lineItems.find((quoteItem) => quoteItem.id === item.id);
    if (existedItem) {
      existedItem.quantity = quantity;
    }
    return {
      success: true,
      quote: newQuote,
    };
  }
}

/** @type QuoteUpdateProductServiceClass */
const QuoteUpdateProductService = ServiceFactory.get(QuoteUpdateProductServiceClass);

export default QuoteUpdateProductService;
