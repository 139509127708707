import AbstractResourceModel from "../AbstractResourceModel";

export default class SubscriptionResourceModel extends AbstractResourceModel {
  static className = 'SubscriptionResourceModel';

  resourceName = 'Subscription';

  /**
   * validate current plans with list permission requires
   *
   * @param listPermissionRequire
   * @param shop
   * @return: promise
   */
  validatePermissionPlan(listPermissionRequire, shop) {
    return this.getResourceOnline().validatePermissionPlan(listPermissionRequire, shop);
  }
}
