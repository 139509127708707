import {toast} from 'react-toastify';

import ActionFactory from '../../framework/factory/ActionFactory';
import StockConstant from '../constant/StockConstant';
import StockService from '../../service/stock/StockService';
import ProductService from '../../service/product/ProductService';
import i18n from '../../config/i18n';
import LocationService from '../../service/location/LocationService';

export class StockActionClass {
  showExternalStock(product, preSelectOptions = null, canBack = false) {
    return async (dispatch) => {
      try {
        if (product.hasOnlyDefaultVariant) {
          dispatch(this.showExternalStockRequest(product, preSelectOptions, canBack));
          const stockResponse = await StockService.getStockOneVariantProduct(product.id);
          dispatch(this.showExternalStockResult(stockResponse.data.getStockOneVariantProduct));
        } else {
          dispatch(this.showProductOptions(product, preSelectOptions, canBack));
          const productOptions = await ProductService.getProductOptions(product.id);
          dispatch(this.showProductOptionsResult(productOptions.data.getProductOptions.productOptions));
        }
      } catch (error) {
        toast.error(
          i18n.t('Fail to get options!'),
        );
      }
    };
  }

  showExternalStockByOptions(product, selectedOptions) {
    return async (dispatch) => {
      dispatch({
        type: StockConstant.SHOW_EXTERNAL_STOCK_BY_OPTIONS,
        product,
        selectedOptions,
      });
      try {
        const stockResponse = await StockService.getStockByProductOptions(product.id, selectedOptions);
        dispatch(this.showExternalStockResult(stockResponse.data.getStockByProductOptions));
      } catch (error) {
        toast.error(
          i18n.t('Fail to get location quantity!'),
        );
      }
    };
  }

  showProductOptions(product, preSelectOptions = null, canBack = false) {
    return {
      type: StockConstant.SHOW_EXTERNAL_STOCK_PRODUCT_OPTIONS,
      product,
      preSelectOptions,
      canBack,
    };
  }

  showProductOptionsResult(result) {
    return {
      type: StockConstant.SHOW_EXTERNAL_STOCK_PRODUCT_OPTIONS_RESULT,
      result,
    };
  }

  showExternalStockRequest(product, preSelectOptions = null, canBack = false) {
    return {
      type: StockConstant.SHOW_EXTERNAL_STOCK,
      product,
      preSelectOptions,
      canBack,
    };
  }

  showExternalStockResult(result) {
    let locations = [];
    const currentLocationId = LocationService.getCurrentLocationId();
    const currentLocation = result.find((element) => element.id === currentLocationId);
    if (currentLocation && currentLocation.isActive) {
      locations.push(currentLocation);
    }
    const resultWithCurrentLocation = result.filter((element) => (element.id !== currentLocationId && element.isActive))
    .sort((item1, item2) => {
      if (item1.available > item2.available) {
        return -1;
      }
      if (item1.available < item2.available) {
        return 1;
      }
      return 0;
    });
    locations = locations.concat(resultWithCurrentLocation);
    return {
      type: StockConstant.SHOW_EXTERNAL_STOCK_RESULT,
      result: locations,
    };
  }

  showPopup() {
    return {
      type: StockConstant.SHOW_POPUP,
    };
  }

  hidePopup() {
    return {
      type: StockConstant.HIDE_POPUP,
    };
  }
}

/**
 * @type {StockActionClass}
 */
const StockAction = ActionFactory.get(StockActionClass);
export default StockAction;
