import StaffConstant from "../../constant/StaffConstant";

const initialState = {
  isContinueLoginSuccess: false,
  isContinueLoginLoading: false,
  continueLoginError: '',
};

/**
 * Continue Login reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */
const continueLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case StaffConstant.USER_CONTINUE_LOGIN:
      return {
        ...state,
        isContinueLoginSuccess: false,
        isContinueLoginLoading: true,
        continueLoginError: '',
      };
    case StaffConstant.USER_CONTINUE_LOGIN_SUCCESS:
      return {
        ...state,
        isContinueLoginSuccess: true,
        isContinueLoginLoading: false,
        continueLoginError: '',
      };
    case StaffConstant.USER_CONTINUE_LOGIN_FAIL:
      return {
        ...state,
        isContinueLoginSuccess: false,
        isContinueLoginLoading: false,
        continueLoginError: action.error,
      };
    case StaffConstant.RESET_CONTINUE_TO_LOGIN_STATE:
      return {
        ...state,
        isContinueLoginSuccess: false,
        isContinueLoginLoading: false,
        continueLoginError: '',
      };
    default:
      return state;
  }
};

export default continueLoginReducer;
