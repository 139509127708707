import PropTypes from "prop-types";
import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useReactToPrint} from 'react-to-print';

import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import OrderAction from "../../action/OrderAction";

import ToPrintComponent from './ToPrintComponent.jsx';
import StylePrintComponent2x from './StylePrintComponent2x.jsx';

const PrintComponentReceipt = (props) => {
  const {onAfterPrint, onPrintError, isReprint} = props;
  const {order} = useSelector((state) => state.core.order.orderDetail);
  const storeInfo = useSelector((state) => state.core.config.configs.shopConfig);
  const toPrintRef = useRef();
  const dispatch = useDispatch();


  function closeLoadingReprint() {
    setTimeout(() => {
      dispatch(OrderAction.setIsLoadingReprint(false));
    }, 500);
  }

  const handlePrint = useReactToPrint({
    content: () => toPrintRef.current,
    documentTitle: 'receipt',
    onAfterPrint,
    onPrintError,
    onBeforePrint: closeLoadingReprint,
  });

  useEffect(() => {
    handlePrint();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <StylePrintComponent2x />
      <div className="" ref={toPrintRef}>
        <ToPrintComponent orderData={order} isReprint={isReprint} storeInfo={storeInfo} />
      </div>
    </>
  );
};

PrintComponentReceipt.className = "PrintComponentReceipt";
PrintComponentReceipt.propTypes = {
  onAfterPrint: PropTypes.func,
  onPrintError: PropTypes.func,
  isReprint: PropTypes.bool,
};

export default FunctionComponentFactory.get(PrintComponentReceipt);

