import React, {useEffect} from "react";
import {Form} from "react-bootstrap";
import PropTypes from "prop-types";
import {cloneDeep} from "lodash";
import {useDispatch, useSelector} from "react-redux";

import i18n from "../../../../../config/i18n";
import OrderService from "../../../../../service/order/OrderService";
import OrderRefundAction from "../../../../action/order/refund/OrderRefundAction";
import OrderConstant from "../../../../constant/OrderConstant";
import FunctionComponentFactory from "../../../../../framework/factory/FunctionComponentFactory";

import RefundTableItem from "./RefundTableItem.jsx";

function RefundTable(props) {
  const dispatch = useDispatch();
  const {lineItems, order} = props;
  const {listSelectedItems, isMaxQtyRefund} = useSelector((state) => state.core.order.orderRefund);
  const setListSelectedItems = (type) => {
    const lineItemsClone = cloneDeep(lineItems.items);
    return lineItemsClone.map((item) => {
      if (item.quantity > item.refundedQuantity) {
        if (type === OrderConstant.SET_LIST_SELECTED_ITEMS_TYPE.DEFAULT) {
          return {
            ...item,
            qtyRefund: 0,
            isReturnToStock: true,
            refundAmountAvailable: item.quantity - item.refundedQuantity,
          };
        } else if (type === OrderConstant.SET_LIST_SELECTED_ITEMS_TYPE.MAX_QTY) {
          const isProductGiftCard = item.product?.isGiftCard;
          const qtyRefund = isProductGiftCard ? 0 : item.quantity - item.refundedQuantity;
          return {
            ...item,
            qtyRefund,
            isReturnToStock: true,
            refundAmountAvailable: item.quantity - item.refundedQuantity,
          };
        }
      }
      return null;
    }).filter(Boolean);
  };


  useEffect(() => {
    if (listSelectedItems.length !== 0) {
      return;
    }

    const itemsCanRefundCurrent = setListSelectedItems(OrderConstant.SET_LIST_SELECTED_ITEMS_TYPE.DEFAULT);
    dispatch(OrderRefundAction.handleUpdateListSelectedItems(itemsCanRefundCurrent));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleListRefund = (orderProduct, dataUpdate) => {
    const listRefundClone = cloneDeep(listSelectedItems);
    for (const refundItem of listRefundClone) {
      if (refundItem.id === orderProduct.id) {
        for (const key of Object.keys(dataUpdate)) {
          refundItem[`${key}`] = dataUpdate[`${key}`];
        }
        break;
      }
    }

    dispatch(OrderRefundAction.handleUpdateListSelectedItems(listRefundClone));
  };
  let totalTax = 0;
  let totalDiscount = 0;
  let totalPrice = 0;
  let totalRefund = 0;
  let totalQtyLeft = 0;
  for (const refund of listSelectedItems) {
    totalTax += refund.qtyRefund * (OrderService.calcTaxLineItem(refund, refund) / refund.quantity);
    totalDiscount += refund.totalDiscountSet.shopMoney.amount * refund.qtyRefund;
    totalPrice += refund.originalUnitPriceSet.shopMoney.amount * refund.qtyRefund;
    totalRefund += refund.qtyRefund;
    totalQtyLeft += (refund.quantity - refund.refundedQuantity);
  }

  useEffect(() => {
    const totalAfterSelectItems = totalPrice + totalTax - totalDiscount;
    const total = totalPrice + totalTax - totalDiscount;
    dispatch(OrderRefundAction.setCurrentTotalWhenSelectItems(total, totalAfterSelectItems));
  }, [dispatch, totalDiscount, totalPrice, totalTax]);

  const handleSetIsMaxQtyRefund = (event) => {
    let itemsCanRefund;
    if (event.target.checked) {
      itemsCanRefund = setListSelectedItems(OrderConstant.SET_LIST_SELECTED_ITEMS_TYPE.MAX_QTY);
    } else {
      itemsCanRefund = setListSelectedItems(OrderConstant.SET_LIST_SELECTED_ITEMS_TYPE.DEFAULT);
    }
    dispatch(OrderRefundAction.setIsMaxQtyRefund(itemsCanRefund, event.target.checked));
  };

  const handleUpdateIsMaxQtyRefund = (val) => {
    dispatch(OrderRefundAction.updateIsMaxQtyRefund(val));
  };

  const handleOpenAdjustments = () => {
    const maxRefundAmount = order.totalReceivedSet.shopMoney.amount - order.totalRefundedSet.shopMoney.amount;
    const totalUpdate = Math.min(parseFloat(totalPrice + totalTax - totalDiscount), parseFloat(maxRefundAmount));
    dispatch(OrderRefundAction.handleUpdateRefundAdjustmentAmount(totalUpdate));
    dispatch(OrderRefundAction.handleOpenAdjustments());
  };

  return (
    <>
      <div className="block-search">
        <div className="box-check">
          <Form.Label className="label-checkbox">
            <span>{i18n.t('Use Max Qty to Refund')}</span>
            <Form.Control
              type="checkbox"
              checked={isMaxQtyRefund}
              value={isMaxQtyRefund}
              onChange={(event) => {
                handleSetIsMaxQtyRefund(event);
              }}
            />
            <span>&nbsp;</span>
          </Form.Label>
        </div>
      </div>
      <div className="block-content">
        <table className="table table-order">
          <thead>
            <tr>
              <th className="t-col">&nbsp;</th>
              <th className="t-product">{i18n.t('Product')}</th>
              <th className="t-qty">{i18n.t('Qty Left')}</th>
              <th className="t-qtyrefund">{i18n.t('Qty to Refund')}</th>
              <th className="t-return">{i18n.t('Restock Item')}</th>
              <th className="t-price">{i18n.t('Price')}</th>
              <th className="t-tax">{i18n.t('Tax')}</th>
              <th className="t-discount">{i18n.t('Discount')}</th>
              <th className="t-rowtotal">{i18n.t('Row Total')}</th>
              <th className="t-col">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {listSelectedItems.length > 0 && (
              listSelectedItems.map((product) => {
                return (
                  <RefundTableItem
                    handleListRefund={handleListRefund}
                    key={product.id}
                    orderProduct={product}
                    handleUpdateIsMaxQtyRefund={handleUpdateIsMaxQtyRefund}
                    isMaxQtyRefund={isMaxQtyRefund}
                  />
                );
              })
          )}
          </tbody>
          <tfoot>
            <tr>
              <td className="t-col">&nbsp;</td>
              <td className="t-product">
                <p><b>{i18n.t('Total')}</b></p>
              </td>
              <td className="t-qty">
                <b>{totalQtyLeft}</b>
              </td>
              <td className="t-qtyrefund">
                <b>{totalRefund}</b>
              </td>
              <td className="t-return" />
              <td className="t-price" />
              <td className="t-tax">{OrderService.formatPrice(totalTax)}</td>
              <td className="t-discount">{OrderService.formatPrice(totalDiscount)}</td>
              <td className="t-rowtotal">
                <div className="hidden-mobile"><p>{OrderService.formatPrice(totalPrice + totalTax - totalDiscount)}</p></div>
              </td>
              <td className="t-col">&nbsp;</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="block-bottom">
        <div className="actions-accept">
          <button
            className="btn btn-default " type="button"
            onClick={() => {
              handleOpenAdjustments();
            }}
          >
            {i18n.t('Next')}
          </button>
        </div>
      </div>
    </>
  );
}

RefundTable.className = 'RefundTable';
RefundTable.propTypes = {
  lineItems: PropTypes.object,
  order: PropTypes.object,
};
export default FunctionComponentFactory.get(RefundTable);
