import StockResourceModel from '../../resource-model/stock/StockResourceModel';
import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';

/**
 * Shop Service Class
 */
export class StockServiceClass extends CoreService {
  static className = 'StockServiceClass';
  resourceModel = StockResourceModel;

  /**
   * Get Stock of one variant product
   * @returns {*}
   */
  getStockOneVariantProduct(id) {
    return this.getResourceModel().getStockOneVariantProduct(id);
  }

  /**
   * Get Stock of product options
   * @param id
   * @param productOptions
   * @returns {*}
   */
  getStockByProductOptions(id, productOptions) {
    return this.getResourceModel().getStockByProductOptions(id, productOptions);
  }
}


/**
 * @type {StockServiceClass}
 */
const stockService = ServiceFactory.get(StockServiceClass);

export default stockService;
