const PermissionConstant = {
  PERMISSION_TYPE_ALL: 'all',
  PERMISSION_TYPE_SPECIFIC: 'specific',

  PERMISSION_ADD_CUSTOM_PRICE: 'add_custom_price',
  PERMISSION_ADD_CUSTOM_SALES: 'add_custom_sales',

  PERMISSION_SESSION_OPEN_AND_CLOSED: 'session_open_and_closing',
  PERMISSION_SESSION_MAKE_ADJUSTMENT: 'session_make_adjustment',

  PERMISSION_CHECK_EXTERNAL_STOCK: 'check_external_stock',

  PERMISSION_ORDER_CREATE_FROM_ALL_LOCATION: 'order_create_from_all_location',
  PERMISSION_ORDER_CREATE_FROM_STAFF_LOCATION: 'order_create_from_staff_location',
  PERMISSION_ALL_ORDERS: 'all_orders',

  PERMISSION_REFUND_AND_EXCHANGE: 'refund_and_exchange',
};
export default PermissionConstant;
