import React, {createRef, useState} from 'react';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';
import layout from '../../../../../framework/Layout';
import ProductService from '../../../../../service/product/ProductService';
import DeviceService from '../../../../../service/DeviceService';
import StockAction from '../../../../action/StockAction';

function Product(props) {
  const {product, addProduct} = props;
  const {t} = useTranslation();
  const [productPressTimer, setProductPressTimer] = useState(null);
  const dispatch = useDispatch();

  const elements = {
    product_name: createRef(),
    name: createRef(),
    product_price: createRef(),
    price: createRef(),
  };

  const isAvailable = ProductService.isProductAvailable(product);
  const additionalAttributesToShow = ProductService.getAdditionalAttributesInList().filter((attribute) => {
    return product[attribute];
  });

  const showExternalStock = () => {
    if (window.navigator.onLine) {
      dispatch(StockAction.showExternalStock(product));
    } else {
      toast.error(t("You must connect to a Wi-Fi or cellular data network to check external stock"));
    }
  };

  const getCheckExternalStock = () => {
    if (!ProductService.isAllowCheckExternalStock()) {
      return null;
    }
    const isShowExternalStock = ProductService.isShowExternalStock(product);
    const className = isShowExternalStock ? "product-check-avail" : "hidden";
    return (
      <div
        className={className}
        onClick={() => showExternalStock()}
        aria-hidden="true"
      />
    );
  };

  /**
   * handle long press on product
   */
  const handleProductLongPress = () => {
    if (!ProductService.isAllowCheckExternalStock()) {
      return false;
    }
    if (DeviceService.isMobile()) {
      const newProductPressTimer = setTimeout(() => {
        showExternalStock();
      }, 1000);
      setProductPressTimer(newProductPressTimer);
    }
  };

  /**
   * handle long press release on product
   */
  const handleProductLongPressRelease = () => {
    if (!DeviceService.isMobile()) {
      return;
    }
    clearTimeout(productPressTimer);
    setProductPressTimer(null);
  };

  /**
   * Handle add product
   */
  const handleAddProduct = () => {
    if (!isAvailable) {
      return;
    }

    const isGiftCard = product.isGiftCard;
    if (isGiftCard) {
      toast.error(t('You cannot sell giftcard products on POS!'),
        {
          className: 'wrapper-messages messages-warning',
          closeOnClick: true,
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });

      return;
    }

    addProduct(product);
  };

  return (
    <li className={`product-item ${isAvailable ? '' : 'stock-unavailable'}`}>
      {layout('product')('product_item_layout')('product_item_info_before')()(props)}
      <div
        className="product-item-info"
        onClick={handleAddProduct}
        onTouchStart={handleProductLongPress}
        onTouchEnd={handleProductLongPressRelease}
        onMouseDown={handleProductLongPress}
        onMouseUp={handleProductLongPressRelease}
        aria-hidden
      >
        {layout('product')('product_item_layout')('product_image_before')()(props)}
        <div className={`product-item-photo subtract-additional-attribute-${additionalAttributesToShow.length}`}>
          <img
            loading="lazy"
            src={ProductService.getProductImage(product)}
            alt=""
          />
        </div>
        {layout('product')('product_item_layout')('product_name_before')()(props)}
        <h3 className="product-item-name" ref={elements.product_name}>
          <span ref={elements.name} dangerouslySetInnerHTML={{__html: ProductService.getProductName(product)}} />
        </h3>
        {layout('product')('product_item_layout')('product_additional_attributes_before')()(props)}
        {
          additionalAttributesToShow.map((attribute) =>
            (
              <h3 className="product-item-attribute" key={product.id + attribute}>
                <span dangerouslySetInnerHTML={{__html: `[${product[attribute]}]`}} />
              </h3>
            ))
        }
        {layout('product')('product_item_layout')('product_price_before')()(props)}
        {layout('product')('product_item_layout')('product_price_before')()(props)}
        <div className="product-item-price">
          <span className="price" ref={elements.product_price}>
            <span ref={elements.price}>
              {ProductService.getDisplayPrice(product)}
            </span>
          </span>
        </div>
      </div>
      {layout('product')('product_item_layout')('product_external_stock_before')()(props)}
      {getCheckExternalStock()}
      {layout('product')('product_item_layout')('external_stock_after')()(props)}
    </li>
  );
}

Product.className = 'Product';
Product.propTypes = {
  product: PropTypes.object.isRequired,
  addProduct: PropTypes.func.isRequired,
};

export default FunctionComponentFactory.get(Product);
