import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';

function CustomerComboBoxComponent(props) {
  const {label, maxLength, oneRow, code, isRequired, isDisabled, setFieldValue, field} = props;
  const isOptional = !isRequired;
  const {t} = useTranslation();
  const classNameRow = oneRow ? "col-sm-12" : "col-sm-6";
  const labelContent =
    isOptional ? t('{{label}} ', {label}) : <span className="asterisk-required">{t(label)}</span>;
  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };
  const handleOnBlur = (event) => {
    if (!event.target.value) {
      return;
    }
    setFieldValue(field, event.target.value, true);
    event.target.value = '';
  };
  return (
    <>
      <div id={`${code}_root`} className={classNameRow}>
        <Form.Label><span>{labelContent}</span></Form.Label>
        <Form.Control
          onBlur={(event) => { handleOnBlur(event); }}
          onKeyUp={(event) => { handleKeyUp(event); }}
          id={code}
          type="text"
          className="form-control"
          disabled={isDisabled}
          maxLength={maxLength}
          autoComplete="false"
        />
      </div>
    </>
  );
}

CustomerComboBoxComponent.className = 'CustomerComboBoxComponent';
CustomerComboBoxComponent.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number,
  oneRow: PropTypes.bool,
  code: PropTypes.string,
  isRequired: PropTypes.bool,
  setFieldValue: PropTypes.func,
  isDisabled: PropTypes.bool,
  field: PropTypes.object,
};

export default React.memo(FunctionComponentFactory.get(CustomerComboBoxComponent));
