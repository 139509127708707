import React, {Fragment, useContext} from "react";
import jQuery from "jquery";
import {Form} from "react-bootstrap";
import PropTypes from "prop-types";

import i18n from "../../../config/i18n";
import OrderService from "../../../service/order/OrderService";
import PaymentConstant from "../../constant/PaymentConstant";
import FunctionComponentFactory from "../../../framework/factory/FunctionComponentFactory";
import NumPad from '../lib/react-numpad/index';
import {EditPaymentCheckoutContext} from "../checkout/payment/EditPayment.jsx";

function EditPaymentItem(props) {

  const heightPopup = () => {
    return jQuery(window).height();
  };
  const [amount, setAmount, referenceNo, setReferenceNo] = useContext(EditPaymentCheckoutContext);
  const {remaining, grandTotal, selectedPayment, currentIndex, listPaid} = props;
  const selectedImg = selectedPayment.icon_path
    ? <span className="payment-logo"><img alt={selectedPayment.code} className="img payment-offline-icon" src={`${process.env.REACT_APP_API_URL}${selectedPayment.icon_path}`} /></span>
    : <span className={`payment-logo image-default image-${selectedPayment.code}`} />;

  let totalAmountLeft = parseFloat(grandTotal);
  listPaid.forEach((paid, index) => {
    if (!currentIndex || currentIndex !== index) {
      totalAmountLeft -= parseFloat(paid.amount);
    }
  });


  const getLabel = () => {
    let title;
    let price;
    if (remaining < grandTotal) {
      if (remaining < 0) {
        title = 'Change';
        price = OrderService.formatPrice(remaining * -1);
      } else {
        title = 'Remaining';
        price = OrderService.formatPrice(remaining);
      }
    } else {
      title = 'Total';
      price = OrderService.formatPrice(grandTotal);
    }

    return (
      <li>
        <span className="label">{i18n.t(`${title}`)}</span>
        <span className="value">{price}</span>
      </li>
    );
  };
  const label = getLabel();

  const belowNumpad = (selectedPayment.use_reference_number === "1" || selectedPayment.code === PaymentConstant.CREDIT_CARD)
    ? (
      <div className="payment-reference">
        <span className="label">{i18n.t("Reference No")}</span>
        <Form.Control
          className="value form-control"
          name="referenceNo"
          maxLength={255}
          type="text" value={referenceNo}
          onChange={(event) => {
            setReferenceNo(event.target.value);
          }}
          autoComplete="off"
        />
      </div>
    ) : (<></>);

  const handleChangePaymentAmount = (val) => {
    if (selectedPayment.code === PaymentConstant.CASH_PAYMENT_CODE) {
      setAmount(parseFloat(val));
    } else {
      setAmount(Math.min(parseFloat(val), totalAmountLeft));
    }
  };
  return (
    <>
      <div className="block-content block-content1" data-scrollbar>
        <div className="scroll-content" id="wrapper-payment2" style={{height: heightPopup()}}>
          {selectedImg}
          <ul className="payment-total">
            {label}
          </ul>
          <NumPad.CustomNumber
            onChange={(val) => {
              handleChangePaymentAmount(val);
            }}
            position="centerLeft"
            sync={false}
            arrow="left"
            isShowAction
            value={amount}
          >
            <div className="payment-amount">
              <span className="label">
                {i18n.t("Amount")}
              </span>
              <span className="value">{OrderService.formatPrice(amount)}</span>
            </div>
          </NumPad.CustomNumber>
          { belowNumpad }
        </div>
      </div>
    </>
  );
}

EditPaymentItem.className = 'EditPaymentItem';
EditPaymentItem.propTypes = {
  remaining: PropTypes.number,
  grandTotal: PropTypes.number,
  selectedPayment: PropTypes.object,
  currentIndex: PropTypes.number,
  listPaid: PropTypes.arrayOf(PropTypes.object),
};

export default FunctionComponentFactory.get(EditPaymentItem);
