import DataResourceFactory from "../../framework/factory/DataResourceFactory";
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';

import OmcAbstract from "./OmcAbstract";
import GraphqlDraftOrder from './graphql/GraphqlDraftOrder';

export class OmcDraftOrder extends OmcAbstract {
  static className = 'OmcDraftOrder';

  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlDraftOrder,
  };

  /**
   * Draft Order Calculate
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  draftOrderCalculate(input) {
    return this.getApiResource().draftOrderCalculate(input);
  }
}

/**
 * @type {OmcDraftOrder}
 */
export default DataResourceFactory.get(OmcDraftOrder);
