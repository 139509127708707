const allPermissionPlan = {
  base: {
    staff_management: "staff_management",
    role_management: "role_management",
    location_and_POS_machine_management: "location_and_POS_machine_management",
    session_management: "session_management",
    denomination_management: "denomination_management",
    simple_checkout_process: "simple_checkout_process",
    receipt: "receipt",
    permissions: "permissions",
    automatic_discount: "automatic_discount",
  },
  integration: {
    loyalty_programs_backlog: "loyalty_programs_backlog",
    payment_methods_only_offline: "payment_methods_only_offline",
    shipping_methods_backlog: "shipping_methods_backlog",
  },
  other_transactions: {
    return_order_backlog: "return_order_backlog",
    refund_order: "refund_order",
    exchange_order_backlog: "exchange_order_backlog",
    offline_mode_backlog: "offline_mode_backlog",
    report_backlog: "report_backlog",
  },
};

export const PLAN_LITE = 'Lite';
export const PLAN_STANDARD = 'Standard';

export const PLAN_LITE_CODE = 'lite';
export const PLAN_STANDARD_CODE = 'standard';

const PlanConstant = {
  ALL_PERMISSION_PLAN: allPermissionPlan,
  PLANS: {
    [PLAN_LITE_CODE]: {
      title: PLAN_LITE,
      code: PLAN_LITE_CODE,
      currency: "$",
      currencyCode: "USD",
      price: "15",
      permissions: [allPermissionPlan.base.staff_management,
        allPermissionPlan.base.role_management, allPermissionPlan.base.location_and_POS_machine_management,
        allPermissionPlan.base.simple_checkout_process, allPermissionPlan.base.receipt, allPermissionPlan.base.permissions,
        allPermissionPlan.integration.payment_methods_only_offline, allPermissionPlan.other_transactions.refund_order],
    },
    [PLAN_STANDARD_CODE]: {
      title: PLAN_STANDARD,
      code: PLAN_STANDARD_CODE,
      currency: "$",
      currencyCode: "USD",
      price: "50",
      permissions: [allPermissionPlan.base.staff_management,
        allPermissionPlan.base.role_management, allPermissionPlan.base.location_and_POS_machine_management,
        allPermissionPlan.base.simple_checkout_process, allPermissionPlan.base.receipt, allPermissionPlan.base.permissions,
        allPermissionPlan.base.session_management, allPermissionPlan.base.denomination_management,
        allPermissionPlan.base.automatic_discount, allPermissionPlan.integration.loyalty_programs_backlog,
        allPermissionPlan.integration.shipping_methods_backlog, allPermissionPlan.integration.payment_methods_only_offline,
        allPermissionPlan.other_transactions.refund_order, allPermissionPlan.other_transactions.offline_mode_backlog],
    },
  },

  FREE_TRIAL: "[PLAN] FREE_TRIAL",


  NUMBER_TRIAL_DAY: 90,
  ALL_LOCATION_ASSIGN: 'all',
  PLAN_STATUS_ACTIVE: "active",
  PLAN_STATUS_CANCEL: "cancel",

};

export default PlanConstant;
