import CheckoutConstant from "../../constant/checkout/CheckoutConstant";

const initialState = {
  canFulfillAllItems: true,
};

const fulfillReducer = (state = initialState, action) => {
  switch (action.type) {
    case CheckoutConstant.VERIFY_CAN_FULFILL_ALL_ITEMS_SUCCESS:
      return {
        ...state,
        canFulfillAllItems: action.canFulfillAllItems,
      };
    default:
      return state;
  }
};

export default fulfillReducer;
