import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';
import ProductService from '../../../../../service/product/ProductService';
import ProductAction from '../../../../action/ProductAction';

import ConfigurableProduct from './view/ConfigurableProduct.jsx';

import '../../../../../assets/Option.css';

function ViewProduct() {
  const dispatch = useDispatch();

  const modalAddToCartElement = useRef();
  const modalDialogElement = useRef();

  const {product, productOptions} = useSelector((state) => state.core.product.viewProduct);
  const {isShowExternalStock} = useSelector(
    (state) => state.core.stock.externalStockReducer,
  );

  useEffect(() => {
    if (!product || !product.id || !productOptions || !modalAddToCartElement.current || !modalDialogElement.current) {
      return;
    }
    const height = window.innerHeight;
    modalDialogElement.current.style.height = `${height}px`;
  }, [modalAddToCartElement, modalDialogElement, product, productOptions]);

  const closePopup = () => {
    dispatch(ProductAction.viewProduct());
  };

  const popupId = product && ProductService.isComposite(product)
    ? 'popup-configurable-product'
    : 'popup-custom-product';
  let modalClass = `modal fade popup-addtocart ${popupId}`;
  const dialogContentClass = isShowExternalStock ? " hidden" : "";
  let fadeClass = 'popup-catalog modal-backdrop fade';
  if (product && product.id) {
    modalClass += ' in show';
    fadeClass += ' in show';
  }
  return (
    <>
      <div
        className={modalClass} data-backdrop="static" id={popupId} tabIndex="-1"
        ref={modalAddToCartElement}
        role="dialog"
      >
        <div className="modal-dialog" role="document" ref={modalDialogElement}>
          <div className={dialogContentClass} style={{height: '100%'}}>
            <ConfigurableProduct
              closePopup={closePopup}
            />
          </div>
        </div>
      </div>
      <div className={fadeClass} />
    </>
  );
}

ViewProduct.className = 'ViewProduct';
ViewProduct.propTypes = {};

export default FunctionComponentFactory.get(ViewProduct);
