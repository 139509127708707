import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from './GraphqlAbstract';

export class GraphqlCountryClass extends GraphqlAbstract {
  static className = 'GraphqlCountryClass';

  /**
   * Get Country
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getAllCountriesOnline() {
    return this.queryWithGQLBuilder(
      "countries",
      {},
      [
        'code',
        'name',
        'postalCodeRequired',
        {
          labels: [
            'firstName',
            'lastName',
            'company',
            'address1',
            'address2',
            'city',
            'country',
            'zone',
            'postalCode',
            'phone',
          ],
        },
        {
          zones: [
            'code',
            'name',
          ],
        },
      ],
      {
        operationName: 'getCountries',
      },
    );
  }
}

/**
 * @type {GraphqlCountryClass}
 */
export default DataResourceFactory.get(GraphqlCountryClass);
