import CustomerConstant from '../../constant/CustomerConstant';

const initialState = {
  customers: [],
  pageInfo: null,
  isLoading: false,
  isShowList: false,
};

/**
 * Receive action from Customer Action
 *
 * @param state = {customers: []}
 * @param action
 * @returns {*}
 */
const customerListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CustomerConstant.GET_CUSTOMERS: {
      const newState = {
        ...state,
        isLoading: true,
        isShowList: true,
      };
      if (action.input.pageAction === CustomerConstant.ACTION_FIRST_PAGE) {
        newState.customers = [];
        newState.pageInfo = null;
      }
      return newState;
    }
    case CustomerConstant.GET_CUSTOMERS_SUCCESS: {
      const {
        items,
        pageInfo,
      } = action;
      return {
        ...state,
        customers: items,
        pageInfo,
        isLoading: false,
      };
    }
    case CustomerConstant.GET_CUSTOMERS_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case CustomerConstant.SHOW_CUSTOMER_LIST_POPUP:
      return {
        ...state,
        isShowList: true,
      };

    case CustomerConstant.HIDE_CUSTOMER_LIST_POPUP:
      return {
        ...state,
        isShowList: false,
        customers: [],
        pageInfo: null,
      };

    case CustomerConstant.RESET_CUSTOMER_LIST:
      return {
        ...state,
        customers: [],
        pageInfo: null,
      };
    default:
      return state;
  }
};

export default customerListReducer;
