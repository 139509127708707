import ServiceFactory from "../framework/factory/ServiceFactory";
import CurrencyConstant from '../view/constant/CurrencyConstant';
import i18n from '../config/i18n';

import CoreService from './CoreService';
import ConfigService from './config/ConfigService';

class CurrencyServiceClass extends CoreService {
  className = 'CurrencyServiceClass';

  getCurrencyFormat() {
    const shopConfig = ConfigService.getShopConfig();
    if (!shopConfig) {
      return null;
    }
    return shopConfig.currencyFormats ? shopConfig.currencyFormats.moneyFormat : null;
  }

  /**
   * Get Shop Currency Code
   * @returns {*|null}
   */
  getShopCurrencyCode() {
    const shopConfig = ConfigService.getShopConfig();
    if (!shopConfig) {
      return null;
    }
    return shopConfig.currencyCode;
  }

  /**
   * Format
   *
   * @param price
   * @param currency
   * @param precision
   * @returns {*|string}
   */
  format(price, currency = null, precision = CurrencyConstant.DEFAULT_DISPLAY_PRECISION) {
    let currencyCode = currency;
    if (!currencyCode) {
      currencyCode = this.getShopCurrencyCode();
    }
    return i18n.t(
      "{{amount, currency}}",
      {
        amount: Number(price).toFixed(precision),
        currency: currencyCode,
        minimumFractionDigits: precision,
      },
    );
  }

  /**
   * Round price by precision
   *
   * @param {number} price
   * @param {number} precision
   * @return {string}
   */
  round(price = 0, precision = CurrencyConstant.DEFAULT_STORE_PRECISION) {
    const multiplicator = 10 ** precision;
    const roundPrice = parseFloat((price * multiplicator).toFixed(Math.min(precision, 20)));
    return (Math.round(roundPrice) / multiplicator).toFixed(Math.min(precision, 20));
  }

  /**
   * Round price and parse result to float
   *
   * @param {number} price
   * @param {number} precision
   * @return {number}
   */
  roundToFloat(price, precision = CurrencyConstant.DEFAULT_STORE_PRECISION) {
    return parseFloat(this.round(price, precision));
  }

  /**
   * Ceil Round price by precision
   *
   * @param {number} price
   * @param {number} precision
   * @return {string}
   */
  ceil(price = 0, precision = CurrencyConstant.DEFAULT_STORE_PRECISION) {
    const multiplicator = 10 ** precision;
    const roundPrice = parseFloat(Math.ceil(price * multiplicator).toFixed(Math.min(precision, 20)));
    return (Math.round(roundPrice) / multiplicator).toFixed(Math.min(precision, 20));
  }

  /**
   * Ceil Round price and parse result to float
   *
   * @param {number} price
   * @param {number} precision
   * @return {number}
   */
  ceilRoundToFloat(price, precision = CurrencyConstant.DEFAULT_STORE_PRECISION) {
    return parseFloat(this.ceil(price, precision));
  }

  /**
   * Get Currency Symbol
   * @returns {*|string}
   */
  getCurrencySymbol() {
    const format = this.getCurrencyFormat();
    if (!format) {
      return '';
    }
    const firstIndex = format.indexOf("{{");
    const lastIndex = format.lastIndexOf("}}");
    if (firstIndex !== -1 && lastIndex !== -1 && firstIndex < lastIndex && lastIndex + 2 < format.length) {
      return format.slice(lastIndex + 2);
    }
    return '';
  }
}

/**
 * @type {CurrencyServiceClass}
 */
const CurrencyService = ServiceFactory.get(CurrencyServiceClass);
export default CurrencyService;
