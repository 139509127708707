import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from './GraphqlAbstract';


export class GraphqlSubscriptionClass extends GraphqlAbstract {
  static className = 'GraphqlSubscriptionClass';

  /**
   * create record refund
   * @return {Promise}
   * @param listPermissionRequire
   * @param shop
   */
  validatePermissionPlan(listPermissionRequire, shop) {
    const input = {
      listPermissionRequire, shop,
    };
    return this.mutationWithGQLBuilder(
      'validatePermissions',
      {
        input: {
          name: 'input',
          type: 'ValidatePermissionsInput!',
          value: input,
        },
      },
      ['result', 'error'],
      {
        operationName: 'validatePermissions',
      },
    );
  }
}

/**
 * @type {GraphqlSubscriptionClass}
 */
export default DataResourceFactory.get(GraphqlSubscriptionClass);
