import {Button, Modal} from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import FunctionComponentFactory
  from '../../../framework/factory/FunctionComponentFactory';

export function ErrorModal(props) {
  const {t} = useTranslation();
  return (
    <Modal
      size="sm"
      className="popup-messages"
      show={props.isOpenModal} onHide={() => props.confirm()}
    >
      <Modal.Body>
        <h3 className="title">{props.title}</h3>
        <p>{props.modalContent}</p>
      </Modal.Body>
      <Modal.Footer className="close-modal">
        <Button onClick={() => props.confirm()}>{t('OK')}</Button>
      </Modal.Footer>
    </Modal>
  );
}
ErrorModal.className = 'ErrorModal';
ErrorModal.propTypes = {
  isOpenModal: PropTypes.bool,
  confirm: PropTypes.func,
  title: PropTypes.string,
  modalContent: PropTypes.string,
};
export default FunctionComponentFactory.get(ErrorModal);
