import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../../../config/i18n';
import functionComponentFactory from '../../../../../framework/factory/FunctionComponentFactory';
import orderConstant from '../../../../constant/OrderConstant';
import OrderService from '../../../../../service/order/OrderService';

const OrderItem = ({item, order}) => {
  const canShowPriceInfo = () => {
    return true;
  };

  const isShowFulfilledQuantity = () => {
    return item.quantity - item.unfulfilledQuantity > 0;
  };

  const canShowRefundedQuantity = () => {
    return item &&
    ([orderConstant.FINANCIAL_STATUS_REFUNDED, orderConstant.FINANCIAL_STATUS_PARTIALLY_REFUNDED].includes(order.displayFinancialStatus)) && (item.refundedQuantity > 0);
  };

  const itemSkuDisplay = item.sku ? `[${item.sku}]` : null;
  return (
    <div className="item-ordered">
      <div className="item-detail">
        <div className="name">{item?.name}</div>
        {itemSkuDisplay && <div className="name">{itemSkuDisplay}</div>}
        <div className="option">{item.variantTitle?.replaceAll(" /", ";")}
        </div>
        <div className="item-status">
          <span>{i18n.t("Ordered: {{orderedQty}}", {orderedQty: item.quantity})}</span>
          {
            isShowFulfilledQuantity() &&
            <span>{i18n.t("Fulfilled: {{fulfilledQty}}", {fulfilledQty: item.quantity - item.unfulfilledQuantity})}</span>
          }
          {
            canShowRefundedQuantity() &&
            <span>{i18n.t("Refunded: {{refundedQty}}", {refundedQty: item.refundedQuantity})}</span>
          }
        </div>
      </div>
      {
        canShowPriceInfo() &&
        <div className="item-order">
          <div><b>{i18n.t(OrderService.formatPrice(OrderService.calcRowTotalLineItem(order, item)))}</b></div>
          <div className="origin-price">
            {i18n.t("Price: {{price}}", {price: OrderService.formatPrice(OrderService.calcPriceLineItem(order, item))})}
          </div>
          {OrderService.calcTaxLineItem(order, item) > 0 &&
            <div className="total-tax">
              {i18n.t("Tax: {{tax}}", {tax: OrderService.formatPrice(OrderService.calcTaxLineItem(order, item))})}
            </div>
          }
          {
            OrderService.calcDiscountLineItem(order, item) > 0 &&
            <div className="total-discount">
              {i18n.t("Discount: {{discount}}", {discount: OrderService.formatPrice(OrderService.calcDiscountLineItem(order, item))})}
            </div>
          }
        </div>
    }
    </div>
  );
};

OrderItem.propTypes = {
  item: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};

export default functionComponentFactory.get(OrderItem);
