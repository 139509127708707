import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Tab, Tabs} from "react-bootstrap";
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Form from 'react-bootstrap/Form';

import {Content} from "../../lib/react-numpad/elements/index";
import NumPad from '../../lib/react-numpad/index';
import Popover from '../../lib/react-popover/index';
import '../../../../assets/CustomDiscountPrice.css';
import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import QuoteAction from '../../../action/checkout/QuoteAction';
import EditPriceAction from '../../../action/checkout/EditPriceAction';
import QuoteItemService from '../../../../service/checkout/quote/QuoteItemService';
import CustomDiscountConstant from '../../../constant/checkout/quote/CustomDiscountConstant';
import QuoteConstant from '../../../constant/checkout/QuoteConstant';

function EditPrice(props) {
  const {t} = useTranslation();
  const width = CustomDiscountConstant.EDIT_PRICE_POPUP_WIDTH;
  const {quote, item} = props;
  const {customLineItems} = useSelector((state) => state.core.checkout.editPrice);
  const customLineItemDefault = customLineItems[item.id];
  const maxDiscountPercent = QuoteItemService.getMaxDiscountPercent();

  const [reason, setReason] = useState(customLineItemDefault ? customLineItemDefault.reason : "");
  const [customPrice, setCustomPrice] = useState(customLineItemDefault ? customLineItemDefault.customPrice : "");
  const [customPriceType, setCustomPriceType] = useState(customLineItemDefault ? customLineItemDefault.customPriceType : CustomDiscountConstant.CUSTOM_DISCOUNT_TYPE);
  const [showXButton, setShowXButton] = useState(customLineItemDefault ? customLineItemDefault.showXButton : false);
  const [customPriceDiscountType, setCustomPriceDiscountType] = useState(customLineItemDefault ? customLineItemDefault.customPriceDiscountType : CustomDiscountConstant.DISCOUNT_TYPE_PERCENT);
  const [customPriceDiscountValue, setCustomPriceDiscountValue] = useState(customLineItemDefault ? customLineItemDefault.customPriceDiscountValue : "0");
  const [unitCustomPriceDiscount, setUnitCustomPriceDiscount] = useState(customLineItemDefault ? customLineItemDefault.unitCustomPriceDiscount : "0");
  const [customDiscountNumpadActive, setCustomDiscountNumpadActive] = useState(customPriceType === CustomDiscountConstant.CUSTOM_DISCOUNT_TYPE);
  const [customPriceNumpadActive, setCustomPriceNumpadActive] = useState(customPriceType === CustomDiscountConstant.CUSTOM_PRICE_TYPE);
  const originalUnitPriceItem = QuoteItemService.getPriceItemByType(item, QuoteConstant.PRICE_ITEM_TYPE_ORIGINAL_UNIT_PRICE);
  const maxCustomDiscountNumPadValue = customPriceDiscountType === CustomDiscountConstant.DISCOUNT_TYPE_PERCENT ? maxDiscountPercent * 100 : String(originalUnitPriceItem * maxDiscountPercent);
  const minCustomPriceNumPadValue = Number(originalUnitPriceItem * (100 - maxDiscountPercent));
  const reasonBoxElement = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (customPriceType === CustomDiscountConstant.CUSTOM_DISCOUNT_TYPE) {
      setCustomDiscountNumpadActive(true);
      setCustomPriceNumpadActive(false);
    } else {
      setCustomPriceNumpadActive(true);
      setCustomDiscountNumpadActive(false);
    }
  }, [customPriceType]);

  function cancel() {
    props.cancel();
  }

  function confirm() {
    let customPriceDiscountValueToSave = customPriceType === CustomDiscountConstant.CUSTOM_DISCOUNT_TYPE ? customPriceDiscountValue : 0;
    if (customPriceDiscountType === CustomDiscountConstant.DISCOUNT_TYPE_PERCENT) {
      customPriceDiscountValueToSave = Math.min(customPriceDiscountValueToSave, Number(maxDiscountPercent));
    } else {
      customPriceDiscountValueToSave = Math.min(customPriceDiscountValueToSave, Number(originalUnitPriceItem));
    }

    let customPriceToSave = originalUnitPriceItem;
    if (customPriceType === CustomDiscountConstant.CUSTOM_PRICE_TYPE) {
      customPriceToSave = Math.max(customPrice === "" ? originalUnitPriceItem : customPrice, minCustomPriceNumPadValue / 100);
    } else if (customPriceDiscountType === CustomDiscountConstant.DISCOUNT_TYPE_PERCENT) {
      customPriceToSave = originalUnitPriceItem * (100 - customPriceDiscountValueToSave) / 100;
    } else {
      customPriceToSave = originalUnitPriceItem - customPriceDiscountValueToSave;
    }
    setCustomPriceDiscountValue(customPriceDiscountValueToSave);
    setCustomPrice(customPriceToSave);

    dispatch(EditPriceAction.saveCustomLineItemDefault({
      id: item.id,
      reason: (customPrice > 0 || customPriceDiscountValue > 0) ? reason : "",
      customPrice: customPriceToSave,
      customPriceType: customPrice === 0 ? CustomDiscountConstant.CUSTOM_DISCOUNT_TYPE : customPriceType,
      showXButton,
      customPriceDiscountType,
      customPriceDiscountValue: customPriceDiscountValueToSave || 0,
      unitCustomPriceDiscount,
    }));
    if (customPriceType === CustomDiscountConstant.CUSTOM_DISCOUNT_TYPE) {
      dispatch(QuoteAction.setCustomDiscountItem(quote, item, customPriceDiscountType, customPriceDiscountValueToSave, reason));
    } else {
      dispatch(QuoteAction.setCustomPriceItem(quote, item, customPriceToSave, reason));
    }
    props.confirm();
  }

  function handleChangeCustomDiscountPrice(newCustomPrice, isEmpty, newCustomPriceDiscountType, newUnitCustomPriceDiscount) {
    setShowXButton(false);
    setCustomDiscountNumpadActive(true);
    setCustomPriceNumpadActive(false);
    setCustomPriceDiscountType(newCustomPriceDiscountType);
    setCustomPriceDiscountValue((isEmpty) ? 0 : parseFloat(newCustomPrice || 0));
    setUnitCustomPriceDiscount((isEmpty) ? 0 : parseFloat(newUnitCustomPriceDiscount || 0));
  }
  function handleChangeCustomPrice(newCustomPrice, isEmpty) {
    setCustomPriceNumpadActive(true);
    setCustomDiscountNumpadActive(false);
    setShowXButton(false);
    setCustomPrice((isEmpty) ? "" : parseFloat(newCustomPrice || ""));
  }

  let customPriceDisplay = (parseFloat(customPrice).toFixed(2) * 100).toFixed(0);
  customPriceDisplay = Number(customPriceDisplay);

  let customPriceDiscountDisplay = parseFloat(customPriceDiscountValue) * 100;
  customPriceDiscountDisplay = Number(customPriceDiscountDisplay.toFixed(2));


  function CustomPriceDiscountTemplate() {
    return (
      <NumPad.CustomPriceNumPad
        sync
        cancel={cancel}
        finish={confirm}
        onChange={handleChangeCustomDiscountPrice}
        active={customDiscountNumpadActive}
        value={customPriceDiscountDisplay}
        customPriceDiscountType={customPriceDiscountType}
        unitCustomPriceDiscount={unitCustomPriceDiscount}
        max={maxCustomDiscountNumPadValue}
      />
    );
  }

  function CustomPriceTemplate() {
    return (
      <NumPad.ContentNumber
        sync
        cancel={cancel}
        finish={confirm}
        onChange={handleChangeCustomPrice}
        active={customPriceNumpadActive}
        value={customPriceDisplay}
        max={originalUnitPriceItem * 100}
      />
    );
  }


  function handleSelectCustomPriceType(key) {
    let type = "";
    switch (key) {
      case CustomDiscountConstant.CUSTOM_PRICE_TYPE:
        type = CustomDiscountConstant.CUSTOM_PRICE_TYPE;
        break;
      case CustomDiscountConstant.CUSTOM_DISCOUNT_TYPE:
        type = CustomDiscountConstant.CUSTOM_DISCOUNT_TYPE;
        break;
      default:
        break;
    }
    setCustomPriceType(type);
  }
  function changeReason(event) {
    const newReason = event.target.value;
    setReason(newReason);
    setShowXButton(Boolean(newReason));
    setTimeout(() => {
      if (reasonBoxElement && reasonBoxElement.current) {
        reasonBoxElement.current.focus();
      }
    }, 100);

  }

  function onReasonFocus(event) {
    setShowXButton(Boolean(event.target.value));
    setCustomDiscountNumpadActive(false);
    setCustomPriceNumpadActive(false);
    setTimeout(() => {
      if (reasonBoxElement && reasonBoxElement.current) {
        reasonBoxElement.current.focus();
      }
    }, 100);
  }

  function clearReasonBox() {
    setReason("");
    setTimeout(() => {
      if (reasonBoxElement && reasonBoxElement.current) {
        reasonBoxElement.current.focus();
      }
    }, 100);
  }

  const getReasonInput = () => {
    return (
      <Form.Control
        autoComplete="false"
        type="text" className="input-reason form-control"
        value={reason}
        ref={reasonBoxElement}
        placeholder={t("Reason")}
        onChange={(event) => changeReason(event)}
        onFocus={(event) => onReasonFocus(event)}
        maxLength="127"
      />
    );
  };

  return (
    <Content width={width} className="set-custom-price-popup">
      <Tabs
        defaultActiveKey={customPriceType}
        animation={false}
        onSelect={(type) => handleSelectCustomPriceType(type)}
        bsStyle="pills"
        className="custom-price-container"
        id="custom-price-container"
      >
        <Tab
          eventKey={CustomDiscountConstant.CUSTOM_DISCOUNT_TYPE}
          title={t('Discount')}
        >
          <CustomPriceDiscountTemplate />
        </Tab>
        <Tab
          eventKey={CustomDiscountConstant.CUSTOM_PRICE_TYPE}
          title={t('Custom Price')}
        >
          <CustomPriceTemplate />
        </Tab>
      </Tabs>
      <div className="custom-price-reason-wrapper">
        {getReasonInput()}

        {
          showXButton &&
          (
            <button
              className="btn-remove" type="button"
              onClick={() => clearReasonBox()}
            >
              <span>{t("remove")}</span>
            </button>
          )
        }
      </div>
      <ul className="list-action">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
        <li className="cancel" onClick={cancel}>
          <div><span>{t('Cancel')}</span></div>
        </li>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
        <li className="confirm" onClick={confirm}>
          <div><span>{t('Confirm')}</span></div>
        </li>
      </ul>
    </Content>
  );
}


EditPrice.className = 'EditPrice';
EditPrice.propTypes = {
  quote: PropTypes.object,
  item: PropTypes.object,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
};

const EditPriceContent = FunctionComponentFactory.get(EditPrice);

const defaultProps = {
  element: EditPriceContent,
  arrow: "left",
};
export default Popover(defaultProps);
