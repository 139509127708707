import React from 'react';

import FunctionComponentFactory
  from '../../../framework/factory/FunctionComponentFactory';
import layout from '../../../framework/Layout';

import '../../../assets/CartLeftAction.css';
import RemoveCart from './cart/RemoveCart.jsx';
import MultipleCart from "./cart/MultipleCart.jsx";
import AddNote from './cart/left-action/AddNote.jsx';


function LeftAction() {
  return (
    <div className="wrapper-action-left">
      {layout('checkout')('left_action')('add_comment_before')()(this)}
      <AddNote />
      {layout('checkout')('left_action')('remove_cart_before')()(this)}
      <RemoveCart />
      {layout('checkout')('left_action')('remove_cart_after')()(this)}
      <MultipleCart />
    </div>
  );
}

LeftAction.className = 'LeftAction';
LeftAction.propTypes = {};

export default FunctionComponentFactory.get(LeftAction);
