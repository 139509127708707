import React from "react";
import PropTypes from "prop-types";

import '../../../assets/SelectPayment.css';
import FunctionComponentFactory from "../../../framework/factory/FunctionComponentFactory";
import i18n from "../../../config/i18n";

function PaymentMethodItem(props) {
  const {payment, paymentItemKey, onClick} = props;
  const paymentIcon = payment.icon_path ? (
    <span className="img"><img alt={payment.icon_path} className="img payment-offline-icon" src={`${process.env.REACT_APP_API_URL}${payment.icon_path}`} /></span>
  ) : (
    <span className={`img image-default image-${payment.code}`} />
  );
  return (
    <li id={paymentItemKey} role="presentation" onClick={() => onClick(payment)}>
      {paymentIcon}
      <span className="text">
        { i18n.t(payment.title) }
      </span>
    </li>
  );
}

PaymentMethodItem.className = 'PaymentMethodItem';
PaymentMethodItem.propTypes = {
  payment: PropTypes.object,
  paymentItemKey: PropTypes.string,
  onClick: PropTypes.func,
};
export default FunctionComponentFactory.get(PaymentMethodItem);
