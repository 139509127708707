import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import CustomerResourceModel
  from '../../resource-model/customer/CustomerResourceModel';

/**
 * Customer Service Class
 */
export class CustomerServiceClass extends CoreService {
  static className = 'CustomerServiceClass';
  resourceModel = CustomerResourceModel;

  /**
   * Get Customers
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getCustomers(input) {
    return this.getResourceModel().getCustomers(input);
  }

  /**
   * Save Customer
   * @param customer
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  saveCustomer(customer) {
    return this.getResourceModel().saveCustomer(customer);
  }

  /**
   * Get customer
   * @param customerId
   * @returns {*|null}
   */
  getCustomer(customerId) {
    return this.getResourceModel().getCustomer(customerId);
  }
}


/**
 * @type {CustomerServiceClass}
 */
const customerService = ServiceFactory.get(CustomerServiceClass);

export default customerService;
