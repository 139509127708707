import {Button, Form, Modal} from "react-bootstrap";
import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";

import i18n from "../../../../../config/i18n";
import FunctionComponentFactory from "../../../../../framework/factory/FunctionComponentFactory";
import OrderRefundAction from "../../../../action/order/refund/OrderRefundAction";

function ConfirmRefundModal(props) {
  const dispatch = useDispatch();
  const {reason, setReason, cancelPopup, listRefundPayment, order} = props;
  const {listSelectedItems, adjustments} = useSelector((state) => state.core.order.orderRefund);
  const stripHtmlTags = (str) => {
    let result;
    if ((str === null) || (str === '')) {
      return '';
    } else {
      result = str.toString();
    }
    return result.replace(/<[^>]*>/g, '');
  };
  const handleSaveRefund = () => {
    cancelPopup();
    dispatch(OrderRefundAction.createRefundRecord({listSelectedItems, adjustments, listRefundPayment, note: reason, order}));
  };
  return (
    <>
      <div
        className={`modal fade in popup-messages popup-messages-refund `} style={{display: 'block'}}
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="title"><b>{i18n.t('Refund Confirmation')}</b></h3>
            </div>
            <div className="modal-body">
              <p>{i18n.t('Are you sure you want to process this refund?')}</p>
              <Form.Control
                as="textarea"
                className="form-control refund-confirmation-text"
                placeholder={i18n.t('Reason to refund (Optional)')}
                defaultValue={reason}
                onChange={(event) => setReason(stripHtmlTags(event.target.value))}
              />
            </div>
            <Modal.Footer className="modal-footer actions-2column">
              <Button className="modal-button-payment" onClick={() => cancelPopup()}>{i18n.t('No')}</Button>
              <Button className="modal-button-payment" onClick={() => { handleSaveRefund(); }}>{i18n.t('Yes')}</Button>
            </Modal.Footer>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in popover-backdrop" />
    </>
  );
}
ConfirmRefundModal.className = 'ConfirmRefundModal';
ConfirmRefundModal.propTypes = {
  reason: PropTypes.string,
  setReason: PropTypes.func,
  cancelPopup: PropTypes.func,
  listRefundPayment: PropTypes.arrayOf(PropTypes.object),
  order: PropTypes.object,
};
export default FunctionComponentFactory.get(ConfirmRefundModal);
