import React, {useEffect} from 'react';
import {BrowserRouter, Routes as ReactRouterRoutes, Route} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ToastContainer, toast} from 'react-toastify';

import FunctionComponentFactory
  from '../framework/factory/FunctionComponentFactory';
import MenuConfig from "../config/MenuConfig";
import Config from '../config/Config';
import ConfigService from '../service/config/ConfigService';
import LocalStorageService from '../service/LocalStorageService';
import StaffService from '../service/staff/StaffService';
import DenominationService from '../service/denomination/DenominationService';
import i18n from '../config/i18n';
import SessionService from '../service/session/SessionService';

import LoginWithParams from './pages/login.jsx';
import SelectPosLocation from './pages/SelectPosLocation.jsx';
import HomePage from './pages/index.jsx';
import NotFound from './pages/NotFound.jsx';
import LoadingPage from './pages/loading/index.jsx';
import StaffAction from './action/StaffAction';
import ConfigAction from './action/ConfigAction';
import Menu from './component/menu/Menu.jsx';
import Scan from './component/scan/Scan.jsx';

import "../assets/app.css";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'react-toastify/dist/ReactToastify.css';

export function App() {

  const dispatch = useDispatch();
  const {message} = useSelector((state) => { return state.core.signout; });

  useEffect(() => {
    if (!message || toast.isActive('force-sign-out')) {
      return;
    }
    dispatch(StaffAction.setSignoutMessage(''));
    toast.error(
      i18n.t(message),
      {
        className: 'wrapper-messages messages-warning',
        autoClose: 6000,
        toastId: 'force-sign-out',
      },
    );
  }, [message]); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    Config.config = ConfigService.getConfigFromLocalStorage();
    Config.denominations = DenominationService.getDenominationsFromLocalStorage();
    Config.permissions = StaffService.getPermissionsFromLocalStorage();

    if (LocalStorageService.getToken() && !window.location.pathname.includes('login') && !window.location.pathname.includes('loading') && !window.location.pathname.includes('locations')) {
      dispatch(ConfigAction.getConfig());
      dispatch(StaffAction.getPermissions());
    }
    if (!LocalStorageService.getToken() && !window.location.pathname.includes('login')) {
      window.location.replace('/');
    }

    if (!SessionService.isEnableSessionManagement() && window.location.pathname.includes('session')) {
      window.location.replace('/');
    }

    if (LocalStorageService.getToken() &&
    SessionService.isEnableSessionManagement() &&
    SessionService.needOpenSession() && !window.location.pathname.includes('session')) {
      window.location.replace('/session');
    }
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  const singlePages = MenuConfig.getMenuItem().filter((menu) => {
    return !menu.isEmbedded;
  });

  return (
    <BrowserRouter>
      <ReactRouterRoutes>
        <Route path="/" element={<HomePage />} />
        <Route path="/index.html" element={<HomePage />} />
        <Route path="/loading" element={<LoadingPage />} />
        <Route path="/login/:shop" element={<LoginWithParams />} />
        <Route path="/login" element={<LoginWithParams />} />
        <Route path="/locations" element={<SelectPosLocation />} />
        {
          singlePages.map((item) => {
            const Element = item.component;
            return (
              <Route key={item.id} path={item.path} element={<Element />} />
            );
          })
         }
        <Route path="*" element={<NotFound />} />
      </ReactRouterRoutes>
      <Scan />
      <ToastContainer
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        position={toast.POSITION.TOP_CENTER}
        theme="colored"
      />
      <Menu />
    </BrowserRouter>
  );
}

App.className = 'App';
export default FunctionComponentFactory.get(App);
