import React, {Fragment, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";

import i18n from "../../../../../config/i18n";
import FunctionComponentFactory from "../../../../../framework/factory/FunctionComponentFactory";
import '../../../../../assets/OrderRefund.css';
import orderRefundConstant from "../../../../constant/order/refund/OrderRefundConstant";
import OrderService from "../../../../../service/order/OrderService";
import OrderRefundAction from "../../../../action/order/refund/OrderRefundAction";

import RefundTable from "./RefundTable.jsx";
import RefundAdjustments from "./RefundAdjustments.jsx";
import CompleteRefund from "./CompleteRefund.jsx";
import RefundSuccess from "./RefundSuccess.jsx";

function OrderRefund(props) {
  const {order} = props;
  const [isOpenModal, setIsOpenModel] = useState(false);
  const dispatch = useDispatch();
  const cancelPopup = () => {
    setIsOpenModel(false);
  };
  const modalContent = 'Are you sure you want to cancel this refund?';

  const {step, total} = useSelector((state) => state.core.order.orderRefund);
  const priceClass = step === orderRefundConstant.REFUND_STEP.SUCCESS ? 'hidden' : 'price';
  const handleCloseRefund = () => {
    dispatch(OrderRefundAction.handleCloseRefund());
  };
  return (
    <>
      <div className="wrapper-refund-order full-width active" id="wrapper-payment1">
        <div className="block-title">
          <button className="btn-cancel" type="button" onClick={() => setIsOpenModel(true)}>
            {i18n.t('Cancel')}
          </button>
          <strong className="title">
            <b>
              {i18n.t('Refund {{step}} - Order {{orderName}}', {orderName: order.name, step})}
            </b>
          </strong>
          <div className={priceClass}>{OrderService.formatPrice(total)}</div>
        </div>
        {step === orderRefundConstant.REFUND_STEP.ITEMS && <RefundTable lineItems={order.lineItems} order={order} />}
        {step === orderRefundConstant.REFUND_STEP.ADJUSTMENTS && <RefundAdjustments order={order} />}
        {step === orderRefundConstant.REFUND_STEP.COMPLETE && <CompleteRefund order={order} />}
        {step === orderRefundConstant.REFUND_STEP.SUCCESS && <RefundSuccess order={order} />}
      </div>
      <>
        <Modal
          size="sm"
          className="popup-messages"
          show={isOpenModal} onHide={() => cancelPopup()}
        >
          <Modal.Body>
            <h3 className="title">{i18n.t('Cancel Refund')}</h3>
            <p>{modalContent}</p>
          </Modal.Body>
          <Modal.Footer className="modal-footer actions-2column">
            <Button className="modal-button-payment" onClick={() => cancelPopup()}>{i18n.t('No')}</Button>
            <Button className="modal-button-payment" onClick={() => handleCloseRefund()}>{i18n.t('Yes')}</Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
}

OrderRefund.className = 'OrderRefund';
OrderRefund.propTypes = {
  order: PropTypes.object,
};
export default FunctionComponentFactory.get(OrderRefund);
