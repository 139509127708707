import {toast} from 'react-toastify';

import ActionFactory from '../../framework/factory/ActionFactory';
import AddressConstant from '../constant/AddressConstant';
import AddressService from '../../service/customer/AddressService';
import i18n from '../../config/i18n';
import CustomerService from '../../service/customer/CustomerService';

import CustomerAction from './CustomerAction';

export class AddressActionClass {

  static className = 'AddressActionClass';

  /**
   * Show Create address popup
   * @returns {{type: string}}
   */
  showCreateAddressPopup() {
    return {
      type: AddressConstant.SHOW_ADD_ADDRESS_POPUP,
    };
  }

  /**
   * Show Edit address popup
   * @returns {{type: string}}
   */
  showEditAddressPopup(address) {
    return {
      type: AddressConstant.SHOW_EDIT_ADDRESS_POPUP,
      address: AddressService.convertAddressInAddressListingToAddressInForm(address),
    };
  }

  /**
   * Hide Create address popup
   * @returns {{type: string}}
   */
  hideCreateAddressPopup() {
    return {
      type: AddressConstant.HIDE_ADD_ADDRESS_POPUP,
    };
  }

  /**
   * Save address customer
   * @param address
   * @param customer
   * @returns {{address, type: string, customer}}
   */
  saveAddressPopup(address, customer) {
    return async (dispatch) => {
      dispatch({
        type: AddressConstant.SAVE_ADDRESS_POPUP,
        address,
        customer,
      });
      const newCustomer = customer;
      let newAddress = address;
      if (!newAddress.id) {
        newAddress.id = AddressService.generateUnsavedId();
      }
      newAddress = AddressService.convertAddressInFormToAddressListing(newAddress);
      if (newAddress.is_default_address) {
        newCustomer.defaultAddress = newAddress;
      }
      if (AddressService.isAddressExist(newAddress, newCustomer.addresses)) {
        dispatch(this.saveAddressPopupFail('This address has existed'));
      } else {
        if (AddressService.isAddNewAddress(newCustomer.addresses, newAddress)) {
          if (newCustomer.addresses && newCustomer.addresses.length) {
            newCustomer.addresses.push(newAddress);
          } else {
            newCustomer.addresses = [newAddress];
          }
        } else {
          newCustomer.addresses = AddressService.updateAddress(newCustomer.addresses, newAddress);
        }
        if (newCustomer.id) {
          try {
            const response = await CustomerService.saveCustomer(
              {
                id: newCustomer.id,
                addresses: newCustomer.addresses,
                defaultAddress: newCustomer.defaultAddress,
              },
            );
            if (response && response.data && response.data.saveCustomer && response.data.saveCustomer.userErrors && response.data.saveCustomer.userErrors.length) {
              const errorMessages = [];
              response.data.saveCustomer.userErrors.forEach(
                (error) => {
                  if (error.field === null) {
                    errorMessages.push(error.message);
                  }
                },
              );
              if (errorMessages.length) {
                dispatch(this.saveAddressPopupFail(errorMessages.join(', ')));
              }
            } else {
              dispatch(CustomerAction.updateCurrentCustomer(response.data.saveCustomer.customer));
              dispatch(this.saveAddressPopupSuccess());
            }
          } catch (error) {
            dispatch(this.saveAddressPopupFail(error.message));
          }
        } else {
          dispatch(CustomerAction.updateCurrentCustomer(newCustomer));
          dispatch(this.saveAddressPopupSuccess());
        }
      }
    };
  }

  /**
   * Save address popup success
   * @returns {{type: string}}
   */
  saveAddressPopupSuccess() {
    return {
      type: AddressConstant.SAVE_ADDRESS_POPUP_SUCCESS,
    };
  }

  /**
   * Save address fail
   * @param message
   * @returns {{type: string}}
   */
  saveAddressPopupFail(message) {
    toast.error(
      i18n.t(message),
    );
    return {
      type: AddressConstant.SAVE_ADDRESS_POPUP_FAIL,
    };
  }
}

const AddressAction = ActionFactory.get(AddressActionClass);
export default AddressAction;
