import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import Select from 'react-select';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import LocalStorageService from '../../../service/LocalStorageService';
import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import StaffService from '../../../service/staff/StaffService';
import LocationService from '../../../service/location/LocationService';
import ShopAction from '../../action/ShopAction';
import PosAction from '../../action/PosAction';
import StaffAction from '../../action/StaffAction';

import ErrorModal from './ErrorModal.jsx';

export function SelectPosLocationComponent(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [locations, setLocations] = useState([]);

  const [pos, setPos] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [enterToPosTitle, setEnterToPosTitle] = useState(t('Enter To POS'));
  const [selectedPos, setSelectedPos] = useState(null);
  const [logo, setLogo] = useState('');

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');

  const {shopPublicInfo} = useSelector((state) => { return state.core.shopReducer; });
  const {posList, error, isLoading, isSuccess} = useSelector((state) => { return state.core.posReducer; });

  /**
   * sort array by alphabet
   * @param array
   * @param fieldSort
   * @return {*}
   */
  const sortByAlphabet = (array, fieldSort) => {
    return LocationService.sortByAlphabet(array, fieldSort);
  };

  useEffect(() => {
    const localLogoUrl = StaffService.getLocalLogo();
    if (shopPublicInfo && shopPublicInfo.logo && !localLogoUrl) {
      setLogo(shopPublicInfo.logo);
    } else {
      setLogo(localLogoUrl);
    }
  }, [shopPublicInfo]);

  useEffect(() => {
    if (!error) {
      return;
    }
    setIsOpenModal(true);
    setModalTitle(t('Error'));
    setModalContent(t(error));
  }, [error, props, t]);

  useEffect(() => {
    if (isLoading) {
      setEnterToPosTitle(t('please wait...'));
    } else {
      setEnterToPosTitle(t('Enter to POS'));
    }
  }, [isLoading, props, t]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    dispatch(PosAction.resetEnterToPosState());
    navigate('/loading');
  }, [isSuccess]); /* eslint-disable-line react-hooks/exhaustive-deps */


  /**
   * change value location selected
   * @param location
   */
  const handleChangeLocation = (location) => {
    setSelectedLocation(location);
    setSelectedPos(null);
    const posLocation = LocationService.getPosOptionsByLocation(location);
    setPos(posLocation);
  };

  const handleChangePos = (posValue) => {
    setSelectedPos(posValue);
  };

  useEffect(() => {
    const localLogoUrl = StaffService.getLocalLogo();
    const locationsString = LocalStorageService.getLocations();
    const locationIds = [];
    if (!localLogoUrl) {
      const shopUrl = StaffService.getShopUrl();
      dispatch(ShopAction.getShopPublicInfo(shopUrl));
    }
    if (locationsString && locationsString !== "") {
      const locationArray = JSON.parse(locationsString);
      locationArray.forEach(
        (location) => {
          locationIds.push(location.id);
        },
      );
      dispatch(PosAction.getListPosByLocationIds(locationIds));
    }
  }, [dispatch]);

  useEffect(() => {
    const locationsString = LocalStorageService.getLocations();
    if (locationsString && locationsString !== "") {
      let locationArray = JSON.parse(locationsString);

      /* sort location by alphabet */
      locationArray = sortByAlphabet(locationArray, "name");
      locationArray.map((item) => {
        item.value = item.id;
        item.label = item.name;
        item.pos = posList.filter((posItem) => posItem.location_id === item.id);
        return item;
      });
      setLocations(locationArray);
    }
  }, [posList]);
  const handleLogout = () => {
    dispatch(StaffAction.logout());
  };
  const handleEnterPos = () => {
    dispatch(PosAction.enterToPos(selectedPos.id, selectedLocation.id));
  };
  const confirm = () => {
    setIsOpenModal(false);
    window.location.reload();
  };
  const logoUrl = logo ? `${process.env.REACT_APP_API_URL}${logo}` : "/images/256x256.png";
  return (
    <>
      <div id="selection_location_pos" className="selection_location_pos">
        <div className="wrapper-login">
          <div className="form-login">
            <strong className="logo">
              <img src={logoUrl} alt="" />
            </strong>
            <div className="form-group">
              <Select
                placeholder={t("Choose a Location")}
                options={locations}
                onChange={handleChangeLocation}
                value={selectedLocation}
                isClearable={false}
                isSearchable={false}
              />
            </div>
            <div className="form-group">
              <Select
                isSearchable={false}
                placeholder={t("Choose a POS")}
                options={pos}
                onChange={handleChangePos}
                value={selectedPos}
                isClearable={false}
                isDisabled={!(selectedLocation && selectedLocation.id)}
              />
            </div>
            <div className="form-group text-center">
              <Button
                variant="default"
                disabled={!(selectedLocation && selectedLocation.id && selectedPos && selectedPos.id)}
                onClick={handleEnterPos}
              >
                {enterToPosTitle}
              </Button>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <div className="btn-link-logout" onClick={handleLogout}>
                <span>{t('Logout')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorModal isOpenModal={isOpenModal} title={modalTitle} modalContent={modalContent} confirm={confirm} />
    </>
  );
}

SelectPosLocationComponent.className = 'SelectPosLocationComponent';
SelectPosLocationComponent.propTypes = {
};
export default FunctionComponentFactory.get(SelectPosLocationComponent);
