import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import React from "react";

import FunctionComponentFactory from "../../../../../framework/factory/FunctionComponentFactory";
import i18n from "../../../../../config/i18n";

function ErrorModalComponent(props) {
  const sizeModal = props.size ? props.size : "sm";
  return (
    <Modal
      size={sizeModal}
      className="popup-messages"
      show={props.isOpenModal} onHide={() => props.hide()}
    >
      <Modal.Body>
        <h3 className="title">{i18n.t(props.title)}</h3>
        <p>{i18n.t(props.modalContent)}</p>
      </Modal.Body>
      <Modal.Footer className="close-modal">
        <Button onClick={() => props.hide()}>{i18n.t('OK')}</Button>
      </Modal.Footer>
    </Modal>
  );
}

ErrorModalComponent.className = 'ErrorModalComponent';
ErrorModalComponent.propTypes = {
  size: PropTypes.string,
  isOpenModal: PropTypes.bool,
  hide: PropTypes.func,
  title: PropTypes.string,
  modalContent: PropTypes.string,
};
export default FunctionComponentFactory.get(ErrorModalComponent);
