import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Card} from 'react-bootstrap';

import i18n from '../../../../../config/i18n';
import functionComponentFactory from '../../../../../framework/factory/FunctionComponentFactory';
import OrderService from '../../../../../service/order/OrderService';
import orderConstant from '../../../../constant/OrderConstant';
import PaymentService from "../../../../../service/payment/PaymentService";

import OrderItem from './OrderItem.jsx';
import ContentPaymentItem from './ContentPaymentItem.jsx';
import ShippingMethod from './ShippingMethod.jsx';
import ShippingAddress from './ShippingAddress.jsx';
import BillingAddress from './BillingAddress.jsx';

const OrderInformation = ({orderDetail}) => {

  const getPriceLabelDue = () => {
    const dueAmount = OrderService.calcTotalDue(orderDetail);
    if (dueAmount) {
      return OrderService.getPriceLabel(orderDetail).value;
    }
  };


  const location = OrderService.getCustomAttributeByKey(orderDetail, orderConstant.CUSTOM_ATTRIBUTE_KEY_LOCATION);
  const staff = OrderService.getCustomAttributeByKey(orderDetail, orderConstant.CUSTOM_ATTRIBUTE_KEY_CASHIER);
  const customerNameDisplay = orderDetail?.customer?.displayName || orderConstant.GUEST_DISPLAY;

  function PaymentMethod() {
    if (orderDetail.displayFinancialStatus === orderConstant.FINANCIAL_STATUS_PENDING) {
      const kindList = [orderConstant.ORDER_TRANSACTION_KIND_SALE];
      const statusList = [orderConstant.ORDER_TRANSACTION_STATUS_PENDING];
      const pendingTransations = OrderService.getTransactionsByKindsAndStatus(orderDetail.transactions, kindList, statusList);
      const pendingTransation = pendingTransations && pendingTransations.length > 0 ? pendingTransations[0] : [];
      if (pendingTransations && pendingTransations.length > 0) {
        return (
          <ContentPaymentItem
            key={pendingTransation.id}
            transaction={pendingTransation}
            order={orderDetail}
            isPending
          />
        );
      } else {
        return null;
      }
    }
    const kindList = [orderConstant.ORDER_TRANSACTION_KIND_SALE, orderConstant.ORDER_TRANSACTION_KIND_CAPTURE];
    const statusList = [orderConstant.ORDER_TRANSACTION_STATUS_SUCCESS];
    const payments = OrderService.getTransactionsByKindsAndStatus(orderDetail.transactions, kindList, statusList);
    const changePayment = OrderService.calcChangePayment(orderDetail);
    const transactionsMapping = PaymentService.mapFromCodeToTitle(payments);
    return (
      <>
        {
          transactionsMapping && transactionsMapping.map((transaction, index) => {
            return (
              <ContentPaymentItem
                key={transaction.id}
                transaction={transaction}
                order={orderDetail}
                changePayment={changePayment}
                isLastPayment={index === payments.length - 1}
              />
            );
          })
        }
      </>
    );
  }

  function RefundAmount() {
    const totalRefundTransactionAmount = OrderService.calcTotalRefundedValue(orderDetail);
    return totalRefundTransactionAmount ? (
      <li>
        <span className="title">{i18n.t('Total Refunded')}</span>
        <span className="value">
          {OrderService.getPrice(-totalRefundTransactionAmount)}
        </span>
      </li>
    ) : <></>;
  }
  function ShippingAmount() {
    const shippingAmount = OrderService.calcShippingOrder(orderDetail);
    return (
      <li>
        <span className="title">{i18n.t('Shipping')}</span>
        <span className="value">{OrderService.formatPrice(shippingAmount)}</span>
      </li>
    );

  }

  function TaxAmount() {
    return (
      <li>
        <span className="title">{i18n.t('Tax')}</span>
        <span className="value">{OrderService.formatPrice(OrderService.calcTaxOrder(orderDetail))}</span>
      </li>
    );

  }

  function DiscountAmount() {
    const discountAmount = OrderService.calcDiscountOrder(orderDetail) || 0;
    return (
      <li>
        <span className="title">
          {i18n.t("Discount")}
        </span>
        <span className="value">{OrderService.formatPrice(-discountAmount)}</span>
      </li>
    );
  }

  function GrandTotalAmount() {
    const grandTotalAmount = OrderService.calcGrandTotalOrder(orderDetail);
    if (grandTotalAmount) {
      return (
        <li>
          <span className="title">{i18n.t('Grand Total')}</span>
          <span className="value">
            {OrderService.formatPrice(grandTotalAmount)}
          </span>
        </li>
      );
    }
    return <></>;
  }
  return (
    <>
      <div className="order-info">
        <div className="order-price">
          <div className="price">{OrderService.formatPrice(OrderService.calcGrandTotalOrder(orderDetail))}</div>
          <>
            <span className="price-label due">
              {getPriceLabelDue()}
            </span>
          </>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="order-detail">
              <div>{i18n.t("Order Date: ") + moment(orderDetail.createdAt).format('L LT')}</div>
              <div>{location && i18n.t("Location: {{location}}", {location})}</div>

              <div>{i18n.t("Customer: {{customer}}", {customer: customerNameDisplay})}</div>
              <>
                {orderDetail.customer?.phone && <>{i18n.t("Customer Phone: {{phone}}", {phone: orderDetail.customer.phone})}</>}
              </>
              <div className="old">
                {staff && <>{i18n.t("Staff: {{staff}}", {staff})}</>}
              </div>
              <div className="order-status">
                {
                  orderDetail.cancelledAt &&
                  <span className="status cancel">
                    {i18n.t("Cancelled")}
                  </span>
                }
                <span className={`status ${OrderService.getOrderStatus(orderDetail).className}`}>
                  {i18n.t(OrderService.getOrderStatus(orderDetail).status)}
                </span>
                <span className={`status ${OrderService.getPriceOrderDetailLabel(orderDetail, true).className}`}>
                  {i18n.t(OrderService.getPriceOrderDetailLabel(orderDetail, true)?.value)}
                </span>
                {
                  orderDetail.closedAt &&
                  <span className="status archived">
                    {i18n.t("Archived")}
                  </span>
                }
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="order-total">
              <ul>
                <li>
                  <span className="title">{i18n.t('Subtotal')}</span>
                  <span className="value">
                    {OrderService.formatPrice(OrderService.calcSubtotalOrder(orderDetail))}
                  </span>
                </li>
                <DiscountAmount />
                <ShippingAmount />
                <TaxAmount />

                <GrandTotalAmount />
                <li>
                  <span className="title">{i18n.t('Total Paid')}</span>
                  <span className="value">
                    {OrderService.getTotalReceivedDisplay(orderDetail)}
                  </span>
                </li>
                <RefundAmount />
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="accordion" className="Card-group" role="tablist" aria-multiselectable="true">
        <Card >
          <Card.Header>{i18n.t("Items Ordered")}</Card.Header>
          <Card.Body>
            {orderDetail.lineItems && orderDetail.lineItems.items.map((item) => (
              <OrderItem key={String(item.id) + Math.random()} item={item} order={orderDetail} />
            ))}
          </Card.Body>
        </Card>

        <div className="panel-flex">
          <Card>
            <Card.Header>{i18n.t('Payment Method')}</Card.Header>
            <Card.Body>
              <div className="text-right">
                <span className={`status ${OrderService.getPriceOrderDetailLabel(orderDetail, true).className}`}>
                  {OrderService.getPriceOrderDetailLabel(orderDetail, true)?.value}
                </span>
              </div>
              <ul className="payment-method">
                <PaymentMethod />
              </ul>
            </Card.Body>
          </Card>
          {
            orderDetail.requiresShipping &&
            <Card>
              <Card.Header>{i18n.t('Shipping Method')}</Card.Header>
              <Card.Body>
                {orderDetail.shippingLine && <ShippingMethod shippingLine={orderDetail.shippingLine} />}
              </Card.Body>
            </Card>
          }
          {
            orderDetail.requiresShipping &&
            <Card>
              <Card.Header>{i18n.t('Shipping Address')}</Card.Header>
              <Card.Body>
                {orderDetail.shippingAddress && <ShippingAddress shippingAddress={orderDetail.shippingAddress} />}
              </Card.Body>
            </Card>
          }
          <Card>
            <Card.Header>{i18n.t('Billing Address')}</Card.Header>
            <Card.Body>
              {orderDetail.billingAddress && <BillingAddress billingAddress={orderDetail.billingAddress} />}
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

OrderInformation.propTypes = {
  orderDetail: PropTypes.object.isRequired,
};
export default functionComponentFactory.get(OrderInformation);

