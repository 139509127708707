import 'bootstrap/dist/css/bootstrap.min.css';

import {useTranslation} from 'react-i18next';

import FunctionComponentFactory from '../../framework/factory/FunctionComponentFactory';

export function NotFoundComponent() {
  const {t} = useTranslation();
  return t('Page Not Found !');
}

NotFoundComponent.className = 'NotFoundComponent';
export default FunctionComponentFactory.get(NotFoundComponent);
