import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {cloneDeep} from 'lodash';
import moment from 'moment';

import OrderConstant from '../../constant/OrderConstant';
import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import OrderAction from '../../action/OrderAction';
import "../../../assets/Order.css";
import OrderService from '../../../service/order/OrderService';

import OrderSearch from './order-list/OrderSearch.jsx';
import Order from './order-list/Order.jsx';

const OrderList = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {orders, pageInfo, isLoading, currentOrder} = useSelector((state) => state.core.order.orderList);

  const {dataAfterFulfill} = useSelector((state) => state.core.order.orderDetail);

  const [items, setItems] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [startCursor, setStartCursor] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const changeSearchKey = (textSearch) => {
    setSearchKey(textSearch);
  };

  const canLoadMore = () => {
    return !pageInfo || (pageInfo && pageInfo.hasNextPage);
  };
  const loadOrder = (pageAction = OrderConstant.ACTION_FIRST_PAGE) => {
    if (pageAction !== OrderConstant.ACTION_FIRST_PAGE && !canLoadMore()) {
      return;
    }

    const variables = {};
    switch (pageAction) {
      case OrderConstant.ACTION_NEXT_PAGE: {
        variables.pageSize = OrderConstant.PAGE_SIZE;
        variables.current = pageInfo.endCursor;
        variables.pageAction = OrderConstant.ACTION_NEXT_PAGE;
        break;
      }
      default: {
        variables.pageSize = OrderConstant.PAGE_SIZE;
        variables.pageAction = OrderConstant.ACTION_NEXT_PAGE;
      }
    }
    variables.sortOrder = OrderConstant.SORT_ORDER_DESC;

    if (pageAction === OrderConstant.ACTION_FIRST_PAGE) {
      setStartCursor(null);
      setItems([]);
      dispatch(OrderAction.resetState());
      variables.current = null;
    }

    const queryPermission = OrderService.buildQueryPermission();
    if (searchKey) {
      variables.query = `${searchKey} AND (${queryPermission})`;
    } else {
      variables.query = `${queryPermission}`;
    }

    dispatch(OrderAction.getOrders(variables));
  };

  const handleScrollList = (event) => {
    const element = event.target;
    if (!isLoading && (element.scrollHeight - (element.scrollTop + element.clientHeight)) < 50) {
      loadOrder(OrderConstant.ACTION_NEXT_PAGE);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(OrderAction.resetState());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadOrder();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  useEffect(() => {
    if (dataAfterFulfill?.type !== OrderConstant.SUCCESS) {
      return;
    }

    const status = dataAfterFulfill.statusOrder;

    const newItems = items.map((item) => {
      if (currentOrder?.id === item.id) {
        return {
          ...item,
          displayFulfillmentStatus: status,
        };
      }

      return item;
    });

    setItems(cloneDeep(newItems));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAfterFulfill]);

  useEffect(() => {
    if (!pageInfo || (pageInfo.startCursor === startCursor)) {
      return;
    }

    if (orders.length > 0 && (!currentOrder)) {
      dispatch(OrderAction.setCurrentOrder(orders[0]));
    } else if (orders.length === 0) {
      dispatch(OrderAction.setCurrentOrder(null));
    }

    const newItems = [
      ...items,
      ...cloneDeep(orders),
    ];

    setItems(newItems);
    const newStartCursor = pageInfo ? pageInfo.startCursor : null;
    setStartCursor(newStartCursor);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, pageInfo]);

  const cancelSearching = () => {
    setIsSearching(false);
    setSearchKey('');
  };

  const blurSearchBox = () => {
    if (!searchKey) {
      setIsSearching(false);
    }
  };

  const getDisplayList = () => {
    const groupDays = [];
    const displayList = [];
    items.map((item) => {
      const date = moment(item.createdAt).format('L');
      const index = groupDays.indexOf(date);
      if (index === -1) {
        groupDays.push(date);
        displayList[groupDays.length - 1] = {
          date,
          orderItems: [],
        };
        displayList[groupDays.length - 1].orderItems.push(item);
      } else {
        displayList[index].orderItems.push(item);
      }
      return null;
    });
    return displayList;
  };

  const handleSelectOrder = (order) => {
    dispatch(OrderAction.setCurrentOrder(order));
  };

  const isActive = (id) => {
    return currentOrder?.id === id;
  };

  const renderOrders = () => {
    const displayList = getDisplayList();
    return displayList.map((item) => {
      return (
        <div className="items" key={item.date}>
          <div className="item-title">{item.date}</div>
          <ul className="item-list">
            {
              item.orderItems.map((order) => {
                return (
                  <Order
                    key={order.id} order={order}
                    isActive={isActive(order.id)}
                    handleSelectOrder={handleSelectOrder}
                  />
                );
              })
            }
          </ul>
        </div>
      );
    });
  };
  return (
    <div className={`wrapper-order-left ${(isSearching) ? 'search-focus' : ''}`}>
      <div className="block-title">{t("Orders")}</div>
      <OrderSearch
        setIsSearching={setIsSearching}
        isSearching={isSearching}
        searchKey={searchKey}
        changeSearchKey={changeSearchKey}
        cancelSearching={cancelSearching}
        blurSearchBox={blurSearchBox}
      />
      <div className="block-order-list" onScroll={handleScrollList}>
        { renderOrders() }
        <div
          className="loader-product"
          style={{display: (isLoading ? 'block' : 'none')}}
        />
      </div>
    </div>
  );
};

OrderList.className = "OrderList";
export default FunctionComponentFactory.get(OrderList);
