import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from './GraphqlAbstract';

export class GraphqlPosClass extends GraphqlAbstract {
  static className = 'GraphqlPosClass';

  /**
   * Get Pos Public Info
   * @returns {*}
   */
  getListPosByLocationIds(locations = '') {
    return this.queryWithGQLBuilder(
      'getAllPosByLocationIds',
      {
        locations: {
          name: 'locations',
          type: '[String!]!',
          value: locations || [],
        },
      },
      [
        'id',
        'name',
        'status',
        'staff_id',
        'staff_name',
        'location_id',
      ],
      {
        operationName: 'getAllPosByLocationIds',
      },
    );
  }

  /**
   * Enter to POS Id
   * @param posId
   * @param locationId
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  enterToPos(posId, locationId) {
    const input = {
      pos_id: posId,
      location_id: locationId,
    };
    return this.mutationWithGQLBuilder(
      'enterToPos',
      {
        input: {
          name: 'input',
          type: 'EnterToPosInput!',
          value: input,
        },
      },
      [
        'token',
        'pos_id',
        'pos_name',
      ],
      {
        operationName: 'enterToPos',
      },
    );
  }
}

/**
 * @type {GraphqlPosClass}
 */
export default DataResourceFactory.get(GraphqlPosClass);
