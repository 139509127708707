import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';
import '../../../../assets/Collection.css';
import {useTranslation} from 'react-i18next';
import {cloneDeep} from 'lodash';

import CollectionConstant from '../../../constant/CollectionConstant';
import CollectionAction from '../../../action/CollectionAction';
import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';

function CollectionListPopup(props) {
  const {isOpenPopup, setIsOpenPopup, selectedCollection, setSelectedCollection} = props;
  const {t} = useTranslation();
  const {collections, pageInfo, isLoading} = useSelector((state) => state.core.collection.collectionList);
  const [items, setItems] = useState([]);
  const [startCursor, setStartCursor] = useState(null);
  const dispatch = useDispatch();
  const canLoadMore = () => {
    return !pageInfo || (pageInfo && pageInfo.hasNextPage);
  };
  function showProduct(category = null) {
    setIsOpenPopup(false);
    setSelectedCollection(category);
  }
  const loadCollection = (pageAction = CollectionConstant.ACTION_FIRST_PAGE) => {
    if (pageAction !== CollectionConstant.ACTION_FIRST_PAGE && !canLoadMore()) {
      return;
    }
    if (pageAction === CollectionConstant.ACTION_FIRST_PAGE) {
      setStartCursor(null);
      setItems([]);
    }
    const variables = {};
    switch (pageAction) {
      case CollectionConstant.ACTION_NEXT_PAGE: {
        variables.pageSize = CollectionConstant.PAGE_SIZE;
        variables.sortKey = CollectionConstant.SORT_KEY;
        variables.current = pageInfo.endCursor;
        variables.pageAction = CollectionConstant.ACTION_NEXT_PAGE;
        break;
      }
      case CollectionConstant.ACTION_PREVIOUS_PAGE: {
        variables.pageSize = CollectionConstant.PAGE_SIZE;
        variables.sortKey = CollectionConstant.SORT_KEY;
        variables.current = pageInfo.startCursor;
        variables.pageAction = CollectionConstant.ACTION_PREVIOUS_PAGE;
        break;
      }
      default: {
        variables.pageSize = CollectionConstant.PAGE_SIZE;
        variables.sortKey = CollectionConstant.SORT_KEY;
        variables.pageAction = CollectionConstant.ACTION_FIRST_PAGE;
      }
    }
    dispatch(CollectionAction.getCollections(variables));
  };
  useEffect(() => {
    if (!pageInfo) {
      setItems([]);
      setStartCursor(null);
      return;
    }
    if (pageInfo && (pageInfo.startCursor === startCursor)) {
      return;
    }
    const newItems = [
      ...items,
      ...cloneDeep(collections),
    ];
    setItems(newItems);
    const newStartCursor = pageInfo ? pageInfo.startCursor : null;
    setStartCursor(newStartCursor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections, pageInfo]);
  const handleScrollList = (event) => {
    const element = event.target;
    if (!isLoading && (element.scrollHeight - (element.scrollTop + element.clientHeight)) < 50) {
      loadCollection(CollectionConstant.ACTION_NEXT_PAGE);
    }
  };
  useEffect(() => {
    loadCollection();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalProps = {
    className: isOpenPopup ? "popup-drop-category" : "popup-drop-category hidden",
    backdropClassName: isOpenPopup ? "" : "hidden",
    dialogClassName: isOpenPopup ? "" : "hidden",
  };

  return (
    <>
      <Modal
        size="sm"
        className={modalProps.className}
        backdropClassName={modalProps.backdropClassName}
        dialogClassName={modalProps.dialogClassName}
        show
        onHide={() => { setIsOpenPopup(false); }}
      >
        <div className="category-drop" >
          <div className="category-top">
            <div aria-hidden="true" onClick={() => showProduct()}>{t(`All Products`)}</div>
          </div>
        </div>
        <div id="dl-menu" className="dl-menuwrapper">
          <div className="dl-menu dl-menu-toggle dl-menuopen" onScroll={handleScrollList}>
            <ul>
              <li className="menu-label">
                <div className="menu-label-anchor">{t("Select Category")}</div>
              </li>
              {
                    items && items.length > 0 && items.map((category) => {
                      return (
                        <li className="dl-subview" key={category.id}>

                          {
                            category.id === selectedCollection?.id && (
                            <div
                              aria-hidden="true"
                              onClick={() => showProduct(category)}
                              key={category.id}
                              className="selected-menu-label-anchor"
                            >{category.title}
                            </div>
                            )
                          }
                          {
                            category.id !== selectedCollection?.id && (
                            <div
                              aria-hidden="true"
                              onClick={() => showProduct(category)}
                              key={category.id}
                            >{category.title}
                            </div>
                            )
                          }
                        </li>
                      );
                    })

                  }
              <div
                className="loader-coupon"
                style={{display: isLoading ? 'block' : 'none'}}
              >
                <div className="loader-product" />
              </div>
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );

}

CollectionListPopup.className = 'CollectionListPopup';
CollectionListPopup.propTypes = {
  isOpenPopup: PropTypes.bool.isRequired,
  setIsOpenPopup: PropTypes.func.isRequired,
  selectedCollection: PropTypes.object,
  setSelectedCollection: PropTypes.func.isRequired,
};

export default FunctionComponentFactory.get(CollectionListPopup);
