const syncConstantObject = {
  SET_DEFAULT_SYNC_DB: '[SYNC] SET_DEFAULT_SYNC_DB',
  SET_DEFAULT_SYNC_DB_SUCCESS: '[SYNC] SET_DEFAULT_SYNC_DB_SUCCESS',
  SYNC_DATA: '[SYNC] SYNC_DATA',
  SYNC_DATA_WITH_TYPE: '[SYNC] SYNC_DATA_WITH_TYPE',
  CHECK_SYNC_DATA_FINISH: '[SYNC] CHECK_SYNC_DATA_FINISH',
  CHECK_SYNC_DATA_FINISH_RESULT: '[SYNC] CHECK_SYNC_DATA_FINISH_RESULT',
  DEFAULT_TOTAL: -1,

  CHANGE_MODE: '[SYNC] CHANGE_MODE',
  CHANGE_DATA_TYPE_MODE: '[SYNC] CHANGE_DATA_TYPE_MODE',
  SET_DATA_TYPE_MODE: '[SYNC] SET_DATA_TYPE_MODE',

  SYNC_ACTION_LOG: '[action_log] SYNC_ACTION_LOG',
  SYNC_ACTION_LOG_SUCCESS: '[action_log] SYNC_ACTION_LOG_SUCCESS',
  SYNCING_ACTION_LOG: 'SYNCing',
  NOT_SYNCING_ACTION_LOG: 'not_SYNCing',
  STATUS_PENDING: 'pending',
  STATUS_REQUESTING: 'requesting',
  STATUS_ERROR: 'error',

  UPDATE_DATA: '[SYNC] UPDATE_DATA',
  EXECUTE_UPDATE_DATA: '[SYNC] EXECUTE_UPDATE_DATA',
  UPDATE_DATA_WITH_TYPE: '[SYNC] UPDATE_DATA_WITH_TYPE',
  UPDATE_DATA_FINISH: '[SYNC] UPDATE_DATA_FINISH',
  DELETE_DATA_FINISH: '[SYNC] DELETE_DATA_FINISH',
  UPDATE_DATA_FINISH_RESULT: '[SYNC] UPDATE_DATA_FINISH_RESULT',
  ONLINE_MODE: 'online',
  OFFLINE_MODE: 'offline',

  TYPE_REQUEST_GET: 'request_get',

  METHOD_POST: "POST",
  METHOD_PUT: "PUT",
  METHOD_DELETE: "DELETE",
  METHOD_GET: "GET",
  MAX_COUNT_ERROR_REQUEST: 20,

  NUMBER_OF_ORDERS_FOR_RELOADING_PAGE: 50,
  RELOAD_PAGE: '[SYNC] RELOAD PAGE',
  CAN_NOT_RELOAD: 0,
  CAN_RELOAD: 1,
  NEED_TO_RELOAD_BUT_USER_IS_DOING_SOMETHING: 2,
};

export default syncConstantObject;
