import _ from "lodash";

import MultiCartConstant from "../constant/MultiCartConstant";

const initialState = {
  carts: [],
  activeCartId: '',
  error: '',
};

/**
 * receive action from Multiple Cart Action
 *
 * @param state
 * @param action
 * @return {{carts: ([]|*), activeCartId: string, error: string}}
 */

const MultiCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case MultiCartConstant.GET_LIST_CART:
      return {...state, carts: action.carts};
    case MultiCartConstant.GET_LIST_CART_FAILED:
      return {...state, error: action.error};
    case MultiCartConstant.SELECT_CART:
      return {...state, activeCartId: action.cartId};
    case MultiCartConstant.ADD_NEW_CART:
      return {...state, carts: [action.cart, ...state.carts], activeCartId: action.cart.id};
    case MultiCartConstant.DELETE_CART:
      return {
        ...state,
        carts: _.remove(state.carts, (cart) => {
          return cart.id !== action.cartId;
        }),
      };
    case MultiCartConstant.UPDATE_CART:
      return {
        ...state,
        carts: state.carts?.length > 0 ? state.carts.map((cart) => {
          if (action.cart.id === cart.id) { return {...cart, ...action.cart}; }
          return cart;
        }) : [action.cart, ...state.carts],
        activeCartId: state.carts?.length > 0 ? state.activeCartId : action.cart.id,
      };
    case MultiCartConstant.RESET_ALL_MULTI_CART:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default MultiCartReducer;
