
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  Image,
  ListGroup,
  ListGroupItem,
  Offcanvas, OffcanvasBody,
  OffcanvasHeader,
  Stack,
} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import FunctionComponentFactory
    from '../../../framework/factory/FunctionComponentFactory';
import MenuAction from '../../action/MenuAction';
import MenuService from '../../../service/menu/MenuService';
import {CheckoutIcon, LogoutIcon} from '../../../assets/images';
import '../../../assets/Menu.css';
import SessionService from '../../../service/session/SessionService';
import MenuConfig from '../../../config/MenuConfig';
import StaffService from '../../../service/staff/StaffService';
import PosService from '../../../service/pos/PosService';
import LocationService from '../../../service/location/LocationService';
import Logout from "../logout";
import SessionAction from '../../action/SessionAction';
import PermissionService from '../../../service/permission/PermissionService';
import PermissionConstant from '../../constant/PermissionConstant';
import SessionConstant from '../../constant/SessionConstant';

function Menu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();

  const {isOpen, needUpdateMenuItems} = useSelector((state) => state.core.menu);

  const [items, setItems] = useState(MenuConfig.getMenuItem());

  const [isLogOut, setIsLogOut] = useState(false);

  useEffect(() => {
    if (!needUpdateMenuItems) {
      return;
    }
    setItems(MenuConfig.getMenuItem());
    dispatch(MenuAction.setNeedUpdateMenuItems(false));
  }, [dispatch, needUpdateMenuItems]);

  /**
   *  toggle action menu button
   *  @return void
   */
  const close = () => {
    dispatch(MenuAction.close());
  };

  /**
   * Get Selected Item Id
   *
   * @returns {*|null}
   */
  const getSelectedItemId = () => {
    const selectedItem = items.find(
      (item) => location.pathname.indexOf(item.path) !== -1,
    );
    return selectedItem ? selectedItem.id : null;
  };

  /**
   * Close menu, call action whenever user click some menu item
   *
   * @param {Object} item
   * @return void
   */
  const changeRoute = (item) => {
    if (location.pathname.indexOf(item.path) !== -1) {
      return;
    }

    if (SessionService.isEnableSessionManagement() && SessionService.needOpenSession()) {

      if (PermissionService.isAllowed(PermissionConstant.PERMISSION_SESSION_OPEN_AND_CLOSED)) {
        dispatch(SessionAction.setTypeSessionPopup());
      } else {
        dispatch(SessionAction.setTypeSessionPopup(SessionConstant.POPUP_NO_OPEN_CLOSED_PERMISSION));
      }
      navigate('/session');
      return;
    }

    close();

    if (item.path) {
      navigate(item.path);
    }
  };

  /**
   * On Click Menu Item
   *
   * @param event
   * @param item
   */
  const onClickMenuItem = (event, item) => {
    event.preventDefault();
    changeRoute(item);
  };

  /**
   * Get Item
   *
   * @param item
   * @returns {JSX.Element|string}
   */
  const getItem = (item) => {
    if (!MenuService.isItemAllowed(item)) {
      return null;
    }
    if (item.id === "session" && !SessionService.isEnableSessionManagement()) {
      return null;
    }

    let icon = CheckoutIcon;
    if (item.icon) {
      icon = item.icon;
    }

    const isActive = item.id && getSelectedItemId() && (item.id === getSelectedItemId());

    return (
      <ListGroupItem
        className={`menu-item ${item.className}`}
        active={isActive}
        key={item.id}
        onClick={(event) => onClickMenuItem(event, item)}
      >
        <Stack direction="horizontal" gap={3}>
          <Image src={icon} />
          <div>{t(item.title)}</div>
        </Stack>
      </ListGroupItem>
    );
  };

  /**
   *  menu item DOM expression
   *  validate permission
   *
   *  @return string
   *
   * */
  const getMenuItems = () => {
    return items.map((item) => {
      return getItem(item);
    });
  };

  /**
   * Handle click logout
   * @returns {null}
   */
  const onClickLogout = () => {
    setIsLogOut(true);
  };

  /**
   *  logout button DOM expression
   *  @return string
   *
   * */
  const logoutButton = () => {
    return (
      <ListGroupItem
        className="menu-item item-logout"
        onClick={onClickLogout}
      >
        <Stack direction="horizontal" gap={3}>
          <Image src={LogoutIcon} />
          <div>{t("Logout")}</div>
        </Stack>
      </ListGroupItem>
    );
  };

  const getSubtitle = () => {
    return `${LocationService.getCurrentLocationName()} / ${PosService.getCurrentPosName()}`;
  };

  return (
    <>
      <Offcanvas show={isOpen} onHide={close} className="wrapper-menu">
        <OffcanvasHeader>
          <Stack>
            <div className="title">{StaffService.getStaffName()}</div>
            <div className="subtitle">{getSubtitle()}</div>
          </Stack>
        </OffcanvasHeader>
        <OffcanvasBody>
          <ListGroup className="item-menu">
            {getMenuItems()}
            {logoutButton()}
          </ListGroup>
        </OffcanvasBody>
      </Offcanvas>
      <Logout isLogOut={isLogOut} setIsLogOut={setIsLogOut} />
    </>
  );
}

Menu.className = 'Menu';
Menu.propTypes = {};

export default FunctionComponentFactory.get(Menu);
