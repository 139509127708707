

import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';
import NumPad from '../../../lib/react-numpad';
import NumberService from '../../../../../service/NumberService';

function CustomSaleQuantityComponent(props) {

  /**
   * Change input value
   *
   * @param value
   */
  function changeInputValue(value) {
    if (props.inputFieldOnChange && Number(value)) {
      props.inputFieldOnChange(props.Code, value);
    }
  }

  /**
   * Render template
   *
   * @return {*}
   */
  function template() {
    const {Code, Label, OneRow} = props;
    const id = `custom-sale-${Code}`;
    const colClassName = OneRow ? "col-sm-12" : "col-sm-6";
    return (
      <div className={colClassName}>
        <Form.Label htmlFor={id}>{Label}</Form.Label>
        <NumPad.PopoverInteger
          key={id}
          onChange={changeInputValue.bind()}
          position="centerRight"
          arrow="right"
          value={props.value}
          isDecimal
          decimalSeparator={NumberService.getDecimalSeparator()}
          min={0}
          isShowAction
        >
          <span className="form-control">{props.value}</span>
        </NumPad.PopoverInteger>
      </div>
    );
  }
  return template();

}

CustomSaleQuantityComponent.className = 'CustomSaleQuantityComponent';
CustomSaleQuantityComponent.propTypes = {
  Code: PropTypes.string,
  Label: PropTypes.string,
  DefaultValue: PropTypes.string,
  MaxLength: PropTypes.number,
  OneRow: PropTypes.bool,
  inputFieldOnChange: PropTypes.func,
  value: PropTypes.string,
};

export default React.memo(FunctionComponentFactory.get(CustomSaleQuantityComponent));
