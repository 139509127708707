import cloneDeep from 'lodash/cloneDeep';

import QuoteConstant from "../../constant/checkout/QuoteConstant";
import QuoteService from "../../../service/checkout/QuoteService";

/**
 * Receive action from Quote, Payment, Checkout  Action
 *
 * @param state
 * @param action
 * @returns {*}
 */
const quoteReducer = (state = cloneDeep(QuoteService.initialQuoteReducerState), action) => {
  switch (action.type) {
    case QuoteConstant.SET_QUOTE:
      return {...action.quote};
    case QuoteConstant.RESET_QUOTE:
      return {...QuoteService.initialQuoteReducerState};
    default:
      return state;
  }
};

export default quoteReducer;

