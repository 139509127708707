import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {cloneDeep} from 'lodash';

import FunctionComponentFactory
  from '../../../framework/factory/FunctionComponentFactory';
import SessionAction from '../../action/SessionAction';
import SessionConstant from '../../constant/SessionConstant';
import SessionService from '../../../service/session/SessionService';
import PermissionService from '../../../service/permission/PermissionService';
import PermissionConstant from '../../constant/PermissionConstant';

import Session from './session-list/Session.jsx';

const SessionList = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {sessions, pageInfo, isLoading, sessionSelected, isCreateSession} = useSelector((state) => state.core.session.sessionList);

  const [items, setItems] = useState([]);

  const canLoadMore = () => {
    return (pageInfo && (pageInfo.current_page < pageInfo.total_pages));
  };

  const loadSessions = (pageAction = SessionConstant.ACTION_FIRST_PAGE) => {
    if (pageAction !== SessionConstant.ACTION_FIRST_PAGE && !canLoadMore()) {
      return;
    }
    const variables = {
      pageSize: SessionConstant.PAGE_SIZE,
      posId: Number(localStorage.getItem('pos_id')),
      currentPage: pageInfo?.current_page + 1 || 1,
    };

    if (pageAction === SessionConstant.ACTION_FIRST_PAGE) {
      setItems(() => []);
      variables.currentPage = 1;
    }

    dispatch(SessionAction.getSessions(variables));
  };

  const handleScrollList = (event) => {
    const element = event.target;
    if (!isLoading && (element.scrollHeight - (element.scrollTop + element.clientHeight)) < 50) {
      loadSessions(SessionConstant.ACTION_NEXT_PAGE);
    }
  };

  useEffect(() => {
    dispatch(SessionAction.resetState());

    loadSessions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sessions.length > 0 && !sessionSelected) {
      dispatch(SessionAction.getSessionDetail({id: sessions[0]?.id}));
    }

    let newItems = null;
    if (isCreateSession) {
      newItems = [
        ...cloneDeep(sessions),
        ...items,
      ];
    } else {
      newItems = [
        ...items,
        ...cloneDeep(sessions),
      ];
    }

    setItems(newItems);

    dispatch(SessionAction.resetIsCreateSession());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions, pageInfo]);


  const isSessionActive = (id) => {
    return sessionSelected?.id === id;
  };

  const handleSelectedSession = (session) => {
    dispatch(SessionAction.getSessionDetail({id: session?.id}));
    dispatch(SessionAction.setLoadingSessionDetailPage(true));
  };

  const handleClickAddButton = () => {
    dispatch(SessionAction.setTypeSessionPopup());
  };

  const renderSession = () => {
    return items.map((item) => {
      const session = SessionService.getSessionRender(item);

      if (!item.closed_at && session.closed_at) {
        item.closed_at = session.closed_at;
      }

      return (
        <Session
          key={item.id} session={session}
          isActive={isSessionActive(session.id)}
          handleSelectedSession={handleSelectedSession}
        />
      );
    });
  };

  const getAddSessionButton = () => {
    if (SessionService.needOpenSession() && PermissionService.isAllowed(PermissionConstant.PERMISSION_SESSION_OPEN_AND_CLOSED)) {
      return (<button className="btn btn-add" type="button" aria-label={t("Add Session Button")} onClick={handleClickAddButton} />);
    }
    return null;
  };

  return (
    <div className="session-left">
      <div className="block-title">
        {t("Session Management")}
        {getAddSessionButton()}
      </div>
      <div className="block-session-list" onScroll={handleScrollList}>
        <div className="session-list">
          <ul className="items" >
            {renderSession()}
          </ul>
        </div>
        <div
          className="loader-product"
          style={{display: (isLoading ? 'block' : 'none')}}
        />
      </div>
    </div>
  );
};

SessionList.className = "SessionList";
export default FunctionComponentFactory.get(SessionList);
