import ApiResourceConstant from "../../view/constant/data-resource/ApiResourceConstant";
import ObjectManager from "../../framework/ObjectManager";

export default class OmcAbstract {

  /**
   * Api resources
   *
   * Format: {
   *   'rest' => RestResource,
   *   'graphql' => GraphqlResource,
   *   ...
   * }
   * @type {{}}
   */
  apiResources = {};

  /**
   * Default resource type
   * @type {string}
   */
  defaultResource = ApiResourceConstant.GRAPHQL;

  /**
   * Get api resources
   *
   * @returns {{}}
   */
  getApiResources() {
    return this.apiResources;
  }

  /**
   * Get Default Resource
   *
   * @returns {string}
   */
  getDefaultResource() {
    return this.defaultResource;
  }

  /**
   * Get Rest Resource
   *
   * @returns {*}
   */
  getRestResource() {
    return this.getApiResource(ApiResourceConstant.REST);
  }

  /**
   * Get Graphql Resource
   *
   * @returns {*}
   */
  getGraphqlResource() {
    return this.getApiResource(ApiResourceConstant.GRAPHQL);
  }

  /**
   * Get Api Resource
   *
   * @param resourceType
   * @returns {*}
   */
  getApiResource(resourceType = this.getDefaultResource()) {
    return ObjectManager.get(this.getApiResources()[resourceType]);
  }
}
