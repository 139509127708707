import {cloneDeep} from "lodash";

import ServiceFactory from "../../../../framework/factory/ServiceFactory";
import QuoteService from '../../QuoteService';
import AbstractQuoteService from "../AbstractService";
import DiscountConstant from "../../../../view/constant/checkout/quote/DiscountConstant";


export class ApplyCouponDiscountServiceClass extends AbstractQuoteService {
  static className = 'ApplyCouponDiscountServiceClass';

  /**
   * Apply Discount By Coupon Code
   *
   * @param quote
   * @param couponCode
   * @returns {Promise<{quote: *}>}
   */
  async applyCouponCode(quote, couponCode) {
    const quoteData = cloneDeep(quote);
    quoteData.couponCode = couponCode;
    return {
      quote: await QuoteService.calculateTotalsOnline(quoteData, false, [], true),
    };
  }

  /**
   * Remove Discount By Coupon Code
   *
   * @param quote
   * @param couponCode
   * @returns {Promise<{quote: *}>}
   */

  async removeCouponCode(quote, couponCode) {
    const quoteData = cloneDeep(quote);
    if (quoteData.couponCode && quoteData.couponCode === couponCode) {
      delete quoteData.couponCode;
      quoteData.lineItems = quoteData.lineItems.map(
        (lineItem) => {
          if (lineItem.appliedDiscount && !QuoteService.isAppliedCustomDiscountLineItem(lineItem)) {
            lineItem.appliedDiscount = null;
          }
          return lineItem;
        },
      );
      if (quoteData.appliedDiscount && !quoteData?.appliedDiscount?.description?.includes(DiscountConstant.POS_CUSTOM_DISCOUNT_CODE)) {
        quoteData.appliedDiscount = null;
      }
    }

    QuoteService.reMergeDivineItem(quoteData);

    return {
      quote: await QuoteService.calculateTotalsOnline(quoteData, false, [], true),
    };
  }
}

/** @type ApplyCouponDiscountServiceClass */
const ApplyCouponDiscountService = ServiceFactory.get(ApplyCouponDiscountServiceClass);

export default ApplyCouponDiscountService;
