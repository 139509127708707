import StaffConstant from "../../constant/StaffConstant";

const initialState = {
  isLogoutSuccess: false,
  isLogoutLoading: false,
  logoutError: '',
};

/**
 * Logout reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */
const logoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case StaffConstant.STAFF_LOGOUT:
      return {
        ...state,
        isLogoutSuccess: false,
        isLogoutLoading: true,
        logoutError: '',
      };
    case StaffConstant.STAFF_LOGOUT_SUCCESS:
      return {
        ...state,
        isLogoutSuccess: true,
        isLogoutLoading: false,
      };
    case StaffConstant.STAFF_LOGOUT_FAILED:
      return {
        ...state,
        isLogoutSuccess: false,
        isLogoutLoading: false,
        logoutError: action.error,
      };
    case StaffConstant.RESET_LOGOUT_STATE:
      return {
        ...state,
        isLogoutSuccess: false,
        isLogoutLoading: false,
        logoutError: '',
      };
    default:
      return state;
  }
};

export default logoutReducer;
