const QuoteConstant = {
  ADD_PRODUCT_TO_CURRENT_QUOTE: '[QUOTE] ADD_PRODUCT_TO_CURRENT_QUOTE',
  METHOD_GUEST: 'guest',
  METHOD_REGISTER: 'register',
  METHOD_CUSTOMER: 'customer',
  SET_QUOTE: '[QUOTE] SET_QUOTE',
  RESET_QUOTE: '[QUOTE] RESET_QUOTE',
  ADD_PRODUCT: '[QUOTE] ADD_PRODUCT',
  ADD_PRODUCT_AFTER: '[QUOTE] ADD_PRODUCT_AFTER',
  ADD_PRODUCT_SUCCESS: '[QUOTE] ADD_PRODUCT_SUCCESS',
  ADD_PRODUCT_FAIL: '[QUOTE] ADD_PRODUCT_FAIL',
  RESET_CANNOT_ADD_PRODUCT_LIST: '[QUOTE] RESET_CANNOT_ADD_PRODUCT_LIST',
  REMOVE_CART: '[QUOTE] REMOVE_CART',
  REMOVE_CART_ITEM: '[QUOTE] REMOVE_CART_ITEM',
  REMOVE_CART_ITEM_AFTER: '[QUOTE] REMOVE_CART_ITEM_AFTER',
  REMOVE_CART_ITEM_SUCCESS: '[QUOTE] REMOVE_CART_ITEM_SUCCESS',
  REMOVE_CART_ITEM_FAIL: '[QUOTE] REMOVE_CART_ITEM_FAIL',
  UPDATE_QTY_CART_ITEM: '[QUOTE] UPDATE_QTY_CART_ITEM',
  UPDATE_QTY_CART_ITEM_AFTER: '[QUOTE] UPDATE_QTY_CART_ITEM_AFTER',
  UPDATE_QTY_CART_ITEM_SUCCESS: '[QUOTE] UPDATE_QTY_CART_ITEM_SUCCESS',
  UPDATE_QTY_CART_ITEM_FAIL: '[QUOTE] UPDATE_QTY_CART_ITEM_FAIL',
  SET_PAYMENTS: '[QUOTE] SET_PAYMENTS',
  REMOVE_PAYMENT: '[QUOTE] REMOVE_PAYMENT',
  ADD_PAYMENT: '[QUOTE] ADD_PAYMENT',
  SET_CUSTOMER: '[QUOTE] SET_CUSTOMER',
  CHANGE_CUSTOMER_AFTER: '[QUOTE] CHANGE_CUSTOMER_AFTER',
  ADD_COUPON_CODE_AFTER: '[QUOTE] ADD_COUPON_CODE_AFTER',
  REMOVE_COUPON_CODE: '[QUOTE] REMOVE_COUPON_CODE',
  VALIDATE_QUOTE_SALES_RULE: '[QUOTE] VALIDATE_QUOTE_SALES_RULE',
  PRODUCT_ADD_QUOTE: '[QUOTE] PRODUCT_ADD_QUOTE',
  PRODUCT_UPDATED_QUOTE: '[QUOTE] PRODUCT_UPDATED_QUOTE',
  PLACE_ORDER_BEFORE: '[QUOTE] PLACE_ORDER_BEFORE',
  UPDATE_CUSTOM_PRICE_CART_ITEM: '[QUOTE] UPDATE_CUSTOM_PRICE_CART_ITEM',
  UPDATE_CUSTOM_PRICE_CART_ITEM_FAIL: '[QUOTE] UPDATE_CUSTOM_PRICE_CART_ITEM_FAIL',
  SET_CUSTOM_DISCOUNT: '[QUOTE] SET_CUSTOM_DISCOUNT',
  SET_CUSTOM_DISCOUNT_SUCCESS: '[QUOTE] SET_CUSTOM_DISCOUNT_SUCCESS',
  SET_CUSTOM_DISCOUNT_FAILED: '[QUOTE] SET_CUSTOM_DISCOUNT_FAILED',
  REMOVE_CUSTOM_DISCOUNT: '[QUOTE] REMOVE_CUSTOM_DISCOUNT',
  REMOVE_CUSTOM_DISCOUNT_SUCCESS: '[QUOTE] REMOVE_CUSTOM_DISCOUNT_SUCCESS',
  REMOVE_CUSTOM_DISCOUNT_FAILED: '[QUOTE] REMOVE_CUSTOM_DISCOUNT_FAILED',
  SET_IS_APPLYING_DISCOUNT: '[QUOTE] SET_IS_APPLYING_DISCOUNT',
  SET_IS_APPLIED_DISCOUNT: '[QUOTE] SET_IS_APPLIED_DISCOUNT',
  ADD_CUSTOM_SALE: '[QUOTE] ADD_CUSTOM_SALE',
  ADD_CUSTOM_SALE_AFTER: '[QUOTE] ADD_CUSTOM_SALE_AFTER',
  ADD_CUSTOM_SALE_SUCCESS: '[QUOTE] ADD_CUSTOM_SALE_SUCCESS',
  ADD_CUSTOM_SALE_FAIL: '[QUOTE] ADD_CUSTOM_SALE_FAIL',

  SET_CUSTOM_CHARGE_TAX: '[QUOTE] SET_CUSTOM_CHARGE_TAX',
  SET_CUSTOM_CHARGE_TAX_SUCCESS: '[QUOTE] SET_CUSTOM_CHARGE_TAX_SUCCESS',
  SET_CUSTOM_CHARGE_TAX_FAILED: '[QUOTE] SET_CUSTOM_CHARGE_TAX_FAILED',

  CHECKOUT_QUOTE: '[QUOTE] CHECKOUT_QUOTE',
  CHECKOUT_QUOTE_SUCCESS: '[QUOTE] CHECKOUT_QUOTE_SUCCESS',
  CHECKOUT_QUOTE_FAILED: '[QUOTE] CHECKOUT_QUOTE_FAILED',

  SET_APPLIED_DISCOUNT_MESSAGE: "[QUOTE] SET_APPLIED_DISCOUNT_MESSAGE",
  SET_IS_APPLYING_DISCOUNT_MESSAGE: "[QUOTE] SET_IS_APPLYING_DISCOUNT_MESSAGE",
  HANDLE_APPLY_COUPON: "[QUOTE] HANDLE_APPLY_COUPON",
  HANDLE_APPLY_COUPON_SUCCESS: "[QUOTE] HANDLE_APPLY_COUPON_SUCCESS",
  HANDLE_APPLY_COUPON_FAILED: "[QUOTE] HANDLE_APPLY_COUPON_FAILED",
  HANDLE_REMOVE_COUPON: "[QUOTE] HANDLE_REMOVE_COUPON",
  HANDLE_REMOVE_COUPON_SUCCESS: "[QUOTE] HANDLE_REMOVE_COUPON_SUCCESS",
  HANDLE_REMOVE_COUPON_FAILED: "[QUOTE] HANDLE_REMOVE_COUPON_FAILED",
  SET_IS_APPLYING_COUPON: "[QUOTE] SET_IS_APPLYING_COUPON",
  SET_IS_APPLIED_COUPON: "[QUOTE] SET_IS_APPLIED_COUPON",
  SET_CONFLICT_COUPON_SUBMITTED: "[QUOTE] SET_CONFLICT_COUPON_SUBMITTED",
  SET_SHOW_CONFLICT_COUPON_SUBMITTED_POPUP: "[QUOTE] SET_SHOW_CONFLICT_COUPON_SUBMITTED_POPUP",

  DISCOUNT_CODE_EXISTED_CODE: 'EXISTED_DISCOUNT_RULE',
  ALREADY_HAVE_BETTER_COMBINATION_CODE: 'ALREADY_HAVE_BETTER_COMBINATION',
  INVALID_SHIPPING_RATE_DISCOUNT_CODE: 'INVALID_SHIPPING_RATE_DISCOUNT',
  CONFLICT_CUSTOM_PRODUCT_PRICE_AND_AUTO_PRODUCT_DISCOUNT_CODE: 'CONFLICT_CUSTOM_PRODUCT_PRICE_AND_AUTO_PRODUCT_DISCOUNT',
  CONFLICT_CUSTOM_PRODUCT_PRICE_AND_COUPON_PRODUCT_DISCOUNT_CODE: 'CONFLICT_CUSTOM_PRODUCT_PRICE_AND_COUPON_PRODUCT_DISCOUNT',
  CONFLICT_CUSTOM_ORDER_DISCOUNT_AND_COUPON_DISCOUNT_CODE: 'CONFLICT_CUSTOM_ORDER_DISCOUNT_AND_COUPON_DISCOUNT',
  DISCOUNT_CODE_INVALID_MESSAGE: 'Coupon code is invalid!',
  DISCOUNT_CODE_EXISTED_MESSAGE: 'Already have a better combination.',
  INVALID_SHIPPING_RATE_DISCOUNT_MESSAGE: "The shipping fee is higher than the amount this coupon can applied",

  PRICE_ITEM_TYPE_DISCOUNTED_TOTAL: 'discountedTotal',
  PRICE_ITEM_TYPE_ORIGINAL_UNIT_PRICE: 'originalUnitPrice',

  TMP_ITEM_ID_CUSTOM_ATTRIBUTE_KEY: 'magestorePosTmpItemId',
  NOTE_ITEM_CUSTOM_ATTRIBUTE_KEY: 'Note',
  CUSTOM_SALE_SKU: 'magestore-custom-sale',
  CUSTOM_SALE_HANDLE: 'magestore-custom-product',
  DEFAULT_CUSTOM_SALE_TITLE: 'Custom Product - Default Title',
};

export default QuoteConstant;
