import {toast} from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';

import {fire} from '../../event-bus';
import ConfigConstant from '../constant/ConfigConstant';
import ConfigService from '../../service/config/ConfigService';
import Config from '../../config/Config';
import i18n from '../../config/i18n';
import LocalStorageService from '../../service/LocalStorageService';
import ActionFactory from '../../framework/factory/ActionFactory';
import ApiResponseConstant from '../constant/ApiResponseConstant';
import SubscriptionService from "../../service/subscription/SubscriptionService";
import PlanConstant from "../constant/PlanConstants";
import StaffService from "../../service/staff/StaffService";

import LoadingAction from './LoadingAction';
import DenominationAction from './DenominationAction';
import MenuAction from './MenuAction';

export class ConfigActionClass {

  /**
   * Get Config
   * @param atLoadingPage
   * @returns {(function(*): Promise<void>)|*}
   */
  getConfig(atLoadingPage = false) {
    return async (dispatch) => {
      dispatch({
        type: ConfigConstant.GET_CONFIG,
        atLoadingPage,
      });
      try {
        const result = await ConfigService.getConfigOnline();
        if (result && result.data && result.data.getPosConfig) {
          const configs = result.data.getPosConfig;
          const shop = StaffService.getShopUrl();
          const validateSessions = await SubscriptionService.validatePermission([PlanConstant.ALL_PERMISSION_PLAN.base.session_management], shop);
          if (!validateSessions.data.validatePermissions.result && configs.settings && configs.settings?.length) {
            for (let index = 0; index <= configs.settings.length - 1; index++) {
              if (configs.settings[index].path === ConfigConstant.ENABLE_SESSION_MANAGEMENT_PATH) {
                configs.settings[index].value = '0';
                break;
              }
            }
          }
          await ConfigService.saveToLocalStorage(configs);
          Config.config = configs;
          const locale = ConfigService.getLocale();
          if (locale) {
            ConfigService.changeLanguage(locale);
            moment.locale(locale.toLowerCase());
          }
          fire('config_action_get_config_after', {configs});
          if (atLoadingPage) {
            dispatch(LoadingAction.updateFinishedList(ConfigConstant.TYPE_GET_CONFIG));
          }
          dispatch(this.getConfigResult(configs));
          dispatch(MenuAction.setNeedUpdateMenuItems(true));
          if (Config.config && Config.config.shopConfig && Config.config.shopConfig.currencyCode) {
            dispatch(
              DenominationAction.getDenominations(
                Config.config.shopConfig.currencyCode,
                atLoadingPage,
              ),
            );
          }
          return;
        }
        toast.error(
          i18n.t("Failed to get configurable data. Please contact technical support."),
        );
        dispatch(this.getConfigError(result));
      } catch (error) {
        let isContinueToLogin = false;
        if (LocalStorageService.getToken()) {
          if (_.isArray(error) && error.length) {
            if (error[0].message === ApiResponseConstant.STATUS_CODE_CONTINUE_LOGIN || error[0].message === ApiResponseConstant.STATUS_CODE_NOT_SELECT_POS) {
              isContinueToLogin = true;
            }
          }
          if (!isContinueToLogin) {
            toast.error(
              i18n.t(
                'Failed to get configurable data. Please contact technical support.',
              ),
            );
          }
        }
        dispatch(this.getConfigError(error.message));
      }
    };
  }

  /**
   * Get Config Result
   * @param configs
   * @returns {{configs: {}, type: string}}
   */
  getConfigResult(configs = {}) {
    return {
      type: ConfigConstant.GET_CONFIG_RESULT,
      configs,
    };
  }

  /**
   * Get Config Error
   * @param error
   * @returns {{type: string, error}}
   */
  getConfigError(error) {
    return {
      type: ConfigConstant.GET_CONFIG_ERROR,
      error,
    };
  }
}

const ConfigAction = ActionFactory.get(ConfigActionClass);
export default ConfigAction;
