import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';
import CurrencyService from '../../../../../service/CurrencyService';
import ShippingPopup from '../../shipping/ShippingPopup.jsx';
import ShippingAction from '../../../../action/ShippingAction';

function CartTotalShipping(props) {
  const dispatch = useDispatch();

  const {
    total,
    quote,
  } = props;
  const shippingMethod = quote.shipping_method;
  const classNameAmount = shippingMethod ? "amount" : "add-discount";
  const discountAmount = total.value;
  const displayValue = shippingMethod ? CurrencyService.format(discountAmount) : "";
  const className = "totals-action";

  const showShippingPopup = () => {
    dispatch(ShippingAction.showShippingPopup());
    if (quote.customer && quote.customer.id) {
      dispatch(ShippingAction.getAddressListing(quote.customer.id));
    } else {
      dispatch(ShippingAction.getAddressListingGuest());
    }
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <li className={className} onClick={() => { showShippingPopup(); }}>
        <span className="mark">{total.title}</span>
        <span className={classNameAmount}>{displayValue}</span>
      </li>
      <ShippingPopup />
    </>
  );
}

CartTotalShipping.className = 'CartTotalShipping';
CartTotalShipping.propTypes = {
  quote: PropTypes.object.isRequired,
  total: PropTypes.object.isRequired,
};

export default React.memo(FunctionComponentFactory.get(CartTotalShipping));
