import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import CustomSaleConstant from '../../../constant/custom-sale/CustomSaleConstant';
import QuoteAction from '../../../action/checkout/QuoteAction';

import CustomSaleInputComponent from './field/CustomSaleInputComponent.jsx';
import CustomSaleNumberComponent from './field/CustomSaleNumberComponent.jsx';
import CustomSaleQuantityComponent from './field/CustomSaleQuantityComponent.jsx';
import CustomSaleCheckboxComponent from './field/CustomSaleCheckboxComponent.jsx';
import CustomSaleTextareaComponent from './field/CustomSaleTextareaComponent.jsx';

function CustomSale(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const backdrop = useRef();
  const hideBackdrop = () => {
    backdrop.current.style.display = "none";
  };

  const showBackdrop = () => {
    backdrop.current.style.display = "block";
  };

  const initialCustomProduct = {
    name: "",
    price: 0.00,
    quantity: 1,
    taxable_item: 1,
    note: null,
  };

  useEffect(() => {
    if (backdrop.current) {
      document.body.removeChild(backdrop.current);
    }
    backdrop.current = document.createElement("div");
    backdrop.current.className = "modal-backdrop fade in show popover-backdrop";
    backdrop.current.style.display = "none";
    backdrop.current.onclick = () => hideBackdrop();
    document.body.appendChild(backdrop.current);
  }, []);

  useEffect(() => {
    if (!props.isOpenCustomSalePopup) {
      return;
    }
    showBackdrop();
  }, [props.isOpenCustomSalePopup]);

  const customSaleField = {
    name: {
      ref: "name",
    },
    price: {
      ref: "price",
    },
    quantity: {
      ref: "quantity",
    },
    note: {
      ref: "note",
    },
    taxable_item: {
      ref: "taxable_item",
      defaultValue: 1,
    },
  };
  const [customProduct, setCustomProduct] = useState(initialCustomProduct);

  function resetCustomProduct() {
    setCustomProduct(initialCustomProduct);
  }

  /**
   *  Hide popup custom sale
   */
  function hidePopupCustomSale() {
    props.hidePopupCustomSale();
    resetCustomProduct();
    hideBackdrop();
  }

  /**
  *  Add custom sale product to cart
  */
  function addCustomProduct() {
    dispatch(QuoteAction.addCustomProduct(customProduct));
    resetCustomProduct();
    hidePopupCustomSale();
  }

  /**
   * Add input to custom product
   *
   * @param code
   * @param value
   */
  function inputFieldOnChange(code, value) {
    const newCustomProduct = {
      ...customProduct,
      [code]: value,
    };
    setCustomProduct(newCustomProduct);
    props.setIsNewCustomSale(false);
  }

  /**
   * Render template
   * @return {*}
   */
  function template() {
    let modalClass = 'modal popup-edit-customer';
    let fadeClass = 'popup-catalog modal-backdrop fade';
    if (props.isOpenCustomSalePopup) {
      modalClass += ' in';
      fadeClass += ' in';
    }

    const cancelText = t('Cancel');
    return (
      <>
        <div className={modalClass} data-backdrop="static" id="popup-custom-sale" tabIndex="-1" role="dialog">
          <div className="modal-dialog popup-create-customer in" id="custom-sale-modal" role="document">
            <div className="modal-content ">
              <div className="modal-header">
                <button
                  type="button" className="cancel" data-dismiss="modal"
                  onClick={() => hidePopupCustomSale()}
                >{cancelText}
                </button>
                <h4 className="modal-title">{t('Custom Sale')}</h4>
                <button
                  type="submit" className="save"
                  onClick={addCustomProduct}
                >{t('Add to Cart')}
                </button>
              </div>
              <div data-scrollbar className="modal-body">
                <div className="box-group">
                  <div className="row form-group">
                    <CustomSaleInputComponent
                      Code="name"
                      Type={CustomSaleConstant.TYPE_FIELD_INPUT}
                      Label={t("Name")}
                      ref={(node) => {
                        customSaleField.name.ref = node;
                      }}
                      isNew={props.isNew}
                      Placeholder={t("Custom Product")}
                      DefaultValue={customProduct.name}
                      OneRow
                      MaxLength={255}
                      inputFieldOnChange={inputFieldOnChange}
                      value={customProduct.name}
                    />
                  </div>
                  <div className="row form-group">
                    <CustomSaleNumberComponent
                      Code="price"
                      Label={t("Price")}
                      ref={(node) => {
                        customSaleField.price.ref = node;
                      }}
                      isNew={props.isNew}
                      DefaultValue={0.00}
                      OneRow={false}
                      inputFieldOnChange={inputFieldOnChange}
                      value={customProduct.price}
                    />
                    <CustomSaleQuantityComponent
                      Code="quantity"
                      Label={t("Quantity")}
                      ref={(node) => {
                        customSaleField.quantity.ref = node;
                      }}
                      isNew={props.isNew}
                      DefaultValue={customProduct.quantity}
                      OneRow={false}
                      inputFieldOnChange={inputFieldOnChange}
                      value={customProduct.quantity}
                    />
                  </div>

                  <div className="row">
                    <CustomSaleCheckboxComponent
                      Code="taxable_item"
                      Type={CustomSaleConstant.TYPE_FIELD_CHECKBOX}
                      isNew={props.isNew}
                      Label={t("Item is taxable")}
                      ref={(node) => {
                        customSaleField.taxable_item.ref = node;
                      }}
                      DefaultValue={customSaleField.taxable_item.defaultValue}
                      OneRow
                      disable={false}
                      inputFieldOnChange={inputFieldOnChange}
                      value={customProduct.taxable_item}
                    />
                  </div>
                  <div className="textarea-cus">
                    <div className="row form-group disable-box-shadow">
                      <CustomSaleTextareaComponent
                        Code="note"
                        Type={CustomSaleConstant.TYPE_FIELD_TEXTAREA}
                        Label={t("Note")}
                        ref={(node) => {
                          customSaleField.note.ref = node;
                        }}
                        DefaultValue={customProduct.note}
                        isNew={props.isNew}
                        OneRow
                        maxLength={255}
                        inputFieldOnChange={inputFieldOnChange}
                        value={customProduct.note}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={fadeClass} />
      </>
    );
  }
  return template();
}

CustomSale.className = 'CustomSale';
CustomSale.propTypes = {
  isOpenCustomSalePopup: PropTypes.bool,
  isNew: PropTypes.bool,
  hidePopupCustomSale: PropTypes.func,
  setIsNewCustomSale: PropTypes.func,
};

export default React.memo(FunctionComponentFactory.get(CustomSale));
