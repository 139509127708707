import _ from "lodash";
import {toast} from "react-toastify";

import OrderConstant from "../../../constant/OrderConstant";
import i18n from "../../../../config/i18n";

const initialState = {
  listPayment: [],
  isLoading: false,
  isOpenTakePayment: false,
  isOpenListPaymentMethod: true,
  isOpenEditPayment: false,
  isOpenCompletePayment: false,
  isAddPaymentMethod: false,
  listPaid: [],
  remaining: 0,
  currentAmount: null,
  currentIndex: null,
  saveRecordStatusFinish: false,
};

const orderPaymentReducer = (state = _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case OrderConstant.GET_LIST_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        listPayment: action.listPayment,
      };
    case OrderConstant.HANDLE_OPEN_TAKE_PAYMENT:
      return {
        ...state,
        isOpenTakePayment: action.isOpenTakePayment,
        isOpenListPaymentMethod: Boolean(action.isOpenTakePayment),
        isOpenEditPayment: false,
        isOpenCompletePayment: false,
        listPaid: [],
        isAddPaymentMethod: false,
        currentAmount: null,
        currentIndex: null,
        saveRecordStatusFinish: false,
      };
    case OrderConstant.HANDLE_OPEN_EDIT_PAYMENT:
      return {
        ...state,
        isOpenListPaymentMethod: false,
        isOpenCompletePayment: false,
        isOpenEditPayment: action.isOpenEditPayment,
        selectedPayment: action.selectedPayment,
        currentAmount: action?.currentAmount,
        currentIndex: action?.index,
      };
    case OrderConstant.BACK_FROM_EDIT_PAYMENT:
      return {
        ...state,
        isOpenEditPayment: false,
        isOpenCompletePayment: false,
        selectedPayment: null,
        isOpenListPaymentMethod: true,
      };
    case OrderConstant.ACCEPT_PAYMENT:
      return {
        ...state,
        listPaid: [...state.listPaid, action.paid],
        isOpenCompletePayment: true,
        isOpenEditPayment: false,
        isOpenListPaymentMethod: false,
        currentIndex: null,
        currentAmount: null,
      };
    case OrderConstant.HANDLE_OPEN_COMPLETE_PAYMENT:
      return {
        ...state,
        isOpenCompletePayment: action.isOpenCompletePayment,
        isOpenEditPayment: false,
        isOpenListPaymentMethod: false,
        isAddPaymentMethod: false,
      };
    case OrderConstant.SET_REMAINING:
      return {
        ...state,
        remaining: action.remaining,
      };
    case OrderConstant.REMOVE_PAID_ITEM:
      return {
        ...state,
        listPaid: [...action.listPaidAfterRemove],
        isOpenCompletePayment: action.listPaidAfterRemove.length === 0 ? false : state.isOpenCompletePayment,
        isOpenEditPayment: false,
        isOpenListPaymentMethod: action.listPaidAfterRemove.length === 0,
        remaining: action.listPaidAfterRemove.length === 0
                    ? (action.order.totalPriceSet.shopMoney.amount - action.order.totalReceivedSet.shopMoney.amount)
                    : state.remaining,
        isAddPaymentMethod: action.listPaidAfterRemove.length !== 0,
      };
    case OrderConstant.UPDATE_PAID_ITEM:
      return {
        ...state,
        listPaid: [...action.listPaidUpdate],
        isOpenCompletePayment: true,
        isOpenEditPayment: false,
        isOpenListPaymentMethod: false,
        currentAmount: null,
        currentIndex: null,
      };
    case OrderConstant.HANDLE_OPEN_LIST_PAYMENT:
      return {
        ...state,
        isOpenListPaymentMethod: action.isOpenListPaymentMethod,
        isOpenCompletePayment: false,
        isOpenTakePayment: true,
        isAddPaymentMethod: action.isAddPaymentMethod,
      };
    case OrderConstant.CREATE_RECORD_PAYMENT_SUCCESSFUL:
      toast.success(i18n.t('Payment has been received successfully.'));
      return {
        ...state,
        ...initialState,
      };

    case OrderConstant.CREATE_RECORD_PAYMENT:
      return {
        ...state,
        isLoading: true,
        saveRecordStatusFinish: true,
      };
    case OrderConstant.CREATE_RECORD_PAYMENT_FAILED:
      toast.error(i18n.t('Payment has been received failed!'));
      return {
        ...state,
        ...initialState,
        saveRecordStatusFinish: true,
      };
    case OrderConstant.RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default orderPaymentReducer;
