import CollectionConstant from '../constant/CollectionConstant';
import CollectionService from '../../service/collection/CollectionService';
import ActionFactory from "../../framework/factory/ActionFactory";


export class CollectionActionClass {

  /**
   * Get Collections
   *
   * @param input
   * @returns {(function(*): Promise<void>)|*}
   */
  getCollections(input) {
    return async (dispatch) => {
      dispatch({
        type: CollectionConstant.GET_COLLECTIONS,
        input,
      });
      try {
        const response = await CollectionService.getCollections(input);
        dispatch(this.getCollectionsSuccess(response.data.getCollections));
      } catch (error) {
        dispatch(this.getCollectionsFailed(error.message));
      }
    };
  }

  /**
   * Get Collections Success
   *
   * @param result
   * @returns {{result, type: string}}
   */
  getCollectionsSuccess(result) {
    return {
      type: CollectionConstant.GET_COLLECTIONS_SUCCESS,
      items: result.items,
      pageInfo: result.pageInfo,
    };
  }

  /**
   * Get Collections Failed
   *
   * @param error
   * @returns {{type: string, error}}
   */
  getCollectionsFailed(error) {
    return {
      type: CollectionConstant.GET_COLLECTIONS_FAILED,
      error,
    };
  }
}

/**
 * @type {CollectionActionClass}
 */
const CollectionAction = ActionFactory.get(CollectionActionClass);
export default CollectionAction;
