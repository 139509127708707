import React, {Fragment, useState} from "react";
import jQuery from 'jquery';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {Button, Form} from "react-bootstrap";

import FunctionComponentFactory from "../../../../../framework/factory/FunctionComponentFactory";
import OrderService from "../../../../../service/order/OrderService";
import i18n from "../../../../../config/i18n";
import NumPad from '../../../lib/react-numpad/index';
import '../../../../../assets/EditPayment.css';
import OrderPaymentAction from "../../../../action/order/payment/OrderPaymentAction";
import PaymentConstant from "../../../../constant/PaymentConstant";

function EditPayment(props) {
  const dispatch = useDispatch();
  const [referenceNo, setReferenceNo] = useState('');
  const heightPopup = () => {
    return jQuery(window).height();
  };
  const {remaining, grandTotal, orderRemaining, currentIndex} = props;
  const [accept, setAccept] = useState(true);
  const {selectedPayment, currentAmount, listPaid} = useSelector((state) => state.core.order.orderPayment);
  const [amount, setAmount] = useState(currentAmount ? currentAmount : remaining);
  const selectedImg = selectedPayment.icon_path
    ? <span className="payment-logo"><img alt={selectedPayment.code} className="img payment-offline-icon" src={`${process.env.REACT_APP_API_URL}${selectedPayment.icon_path}`} /></span>
    : <span className={`payment-logo image-default image-${selectedPayment.code}`} />;
  const text = `${i18n.t("Accept")} ${i18n.t(OrderService.formatPrice(amount))}`;
  const buttonAcceptClass = `btn btn-default btn-accept ${accept ? '' : 'disabled'}`;

  let totalAmountLeft = parseFloat(orderRemaining);
  listPaid.forEach((paid, index) => {
    if (!currentIndex || currentIndex !== index) {
      totalAmountLeft -= parseFloat(paid.amount);
    }
  });

  const handleChangePaymentAmount = (val) => {
    if (selectedPayment.code === PaymentConstant.CASH_PAYMENT_CODE) {
      setAmount(parseFloat(val));
    } else {
      setAmount(Math.min(parseFloat(val), totalAmountLeft));
    }
    let acceptValue = true;
    if (parseFloat(val) === 0) { acceptValue = false; }
    setAccept(acceptValue);
  };

  const handleBackAction = () => {
    if (currentAmount) {
      dispatch(OrderPaymentAction.handleOpenCompletePayment());
    } else {
      dispatch(OrderPaymentAction.handleBackFromEditPayment());
    }
  };

  const handleAcceptPayment = () => {
    if (currentAmount) {
      dispatch(OrderPaymentAction.handleUpdatePaidItem(amount));
    } else {
      dispatch(OrderPaymentAction.handleAcceptPayment(selectedPayment, amount, referenceNo));
    }
  };

  const getLabel = () => {
    let title;
    let price;
    if (remaining < grandTotal) {
      if (remaining < 0) {
        title = 'Change';
        price = OrderService.formatPrice(remaining * -1);
      } else {
        title = 'Remaining';
        price = OrderService.formatPrice(remaining);
      }
    } else {
      title = 'Total';
      price = OrderService.formatPrice(grandTotal);
    }

    return (
      <li>
        <span className="label">{i18n.t(`${title}`)}</span>
        <span className="value">{price}</span>
      </li>
    );
  };
  const label = getLabel();

  const belowNumpad = (selectedPayment.use_reference_number === "1" || selectedPayment.code === PaymentConstant.CREDIT_CARD)
    ? (
      <div className="payment-reference">
        <span className="label">{i18n.t("Reference No")}</span>
        <Form.Control
          className="value form-control"
          name="referenceNo"
          maxLength={255}
          type="text" value={referenceNo}
          onChange={(event) => {
            setReferenceNo(event.target.value);
          }}
          autoComplete="off"
        />
      </div>
    ) : (<></>);

  return (
    <>
      <div className="block-content block-content1" data-scrollbar>
        <div className="scroll-content" id="wrapper-payment2" style={{height: heightPopup()}}>
          {selectedImg}
          <ul className="payment-total">
            {label}
          </ul>
          <NumPad.CustomNumber
            onChange={(val) => {
              handleChangePaymentAmount(val);
            }}
            position="centerLeft"
            sync={false}
            arrow="left"
            isShowAction
            value={amount}
          >
            <div className="payment-amount">
              <span className="label">
                {i18n.t("Amount")}
              </span>
              <span className="value">{OrderService.formatPrice(amount)}</span>
            </div>
          </NumPad.CustomNumber>
          { belowNumpad }
        </div>
      </div>
      <div className="block-bottom">
        <div className="actions-accept">
          <Button className="btn btn-default btn-cancel" type="button" onClick={() => handleBackAction()}>
            {i18n.t("Back")}
          </Button>
          <Button
            className={buttonAcceptClass} type="button" onClick={() => {
              if (accept) {
                handleAcceptPayment();
              }
            }}
          >
            {text}
          </Button>
        </div>
      </div>
    </>
  );
}

EditPayment.className = 'EditPaymentOrder';
EditPayment.propTypes = {
  remaining: PropTypes.number,
  grandTotal: PropTypes.number,
  orderRemaining: PropTypes.number,
  currentIndex: PropTypes.number,
};
export default FunctionComponentFactory.get(EditPayment);
