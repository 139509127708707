import ShippingConstant from '../../constant/ShippingConstant';

const initialState = {
  isShowShippingAddressPopup: false,
  isLoadingSave: false,
};

/**
 * Receive actions and process
 * @param state
 * @param action
 * @returns {{}}
 */
const shippingAddressPopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case ShippingConstant.SHOW_SHIPPING_ADDRESS_POPUP:
      return {
        ...state,
        isShowShippingAddressPopup: true,
      };
    case ShippingConstant.HIDE_SHIPPING_ADDRESS_POPUP:
      return {
        ...state,
        isShowShippingAddressPopup: false,
      };
    case ShippingConstant.SAVE_NEW_ADDRESS_AS_GUEST:
      return {
        ...state,
        isShowShippingAddressPopup: false,
      };
    case ShippingConstant.SAVE_NEW_ADDRESS_WITH_CUSTOMER:
      return {
        ...state,
        isShowShippingAddressPopup: true,
        isLoadingSave: true,
      };
    case ShippingConstant.SAVE_NEW_ADDRESS_WITH_CUSTOMER_SUCCESS:
      return {
        ...state,
        isShowShippingAddressPopup: false,
        isLoadingSave: false,
      };
    case ShippingConstant.SAVE_NEW_ADDRESS_WITH_CUSTOMER_FAIL:
      return {
        ...state,
        isShowShippingAddressPopup: true,
        isLoadingSave: false,
      };
    default:
      return state;
  }
};

export default shippingAddressPopupReducer;
