import DataResourceFactory from "../../framework/factory/DataResourceFactory";

import Abstract from './IndexedDbAbstract';

class IndexedDbCart extends Abstract {
  static className = 'IndexedDbCart';
  main_table = 'cart';
  primary_key = 'id';

  /**
   * Search by pos Id
   *
   * @param posId
   * @returns {Promise<any>}
   */
  searchByPosId(posId) {
    return new Promise((resolve, reject) => {
      this.db[this.main_table]
        .where('pos_id')
        .equalsIgnoreCase(posId)
        .reverse()
        .sortBy('id')
        .then((items) => resolve(items))
        .catch((error) => reject(error));
    });
  }

  /**
   * add new record
   * @param data
   * @param requestTime
   */
  add(data, requestTime = 1) {
    return new Promise((resolve, reject) => {
      if (requestTime > 10) {
        resolve(0);
      }
      this.db[this.main_table].put(data).then((response) => resolve(response)).catch((error) => reject(error));
    });
  }
}

export default DataResourceFactory.get(IndexedDbCart);
