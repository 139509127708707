import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from './GraphqlAbstract';

export class GraphqlShippingClass extends GraphqlAbstract {
  static className = 'GraphqlShippingClass';

  /**
   * Get addresses
   * @param customerId
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getAddressListingByCustomerId(customerId) {
    return this.queryWithGQLBuilder(
      'getCustomer',
      {
        id: {
          name: 'id',
          type: 'String!',
          value: customerId,
        },
      },
      [
        {
          addresses: [
            'id',
            'address1',
            'address2',
            'city',
            'country',
            'countryCode',
            'firstName',
            'lastName',
            'phone',
            'province',
            'provinceCode',
            'zip',
            'company',
          ],
        },
        {
          defaultAddress: [
            'id',
            'address1',
            'address2',
            'city',
            'country',
            'countryCode',
            'firstName',
            'lastName',
            'phone',
            'province',
            'provinceCode',
            'zip',
            'company',
          ],
        },
      ],
      {
        operationName: 'getAddressListingByCustomerId',
      },
    );
  }
}

/**
 * @type {GraphqlShippingClass}
 */
export default DataResourceFactory.get(GraphqlShippingClass);
