import ShippingConstant from "../../constant/ShippingConstant";

const initialState = {
  isLoadingShippingMethod: false,
};

const shippingMethodReducer = (state = initialState, action) => {
  switch (action.type) {
    case ShippingConstant.SHOW_LOADING_SHIPPING_METHOD:
      return {
        ...state,
        isLoadingShippingMethod: true,
      };
    case ShippingConstant.HIDE_LOADING_SHIPPING_METHOD:
      return {
        ...state,
        isLoadingShippingMethod: false,
      };
    default:
      return state;
  }
};

export default shippingMethodReducer;

