import React, {useEffect, useState} from 'react';
import SmoothScrollbar from 'smooth-scrollbar';
import $ from 'jquery';
import _ from 'lodash';
import {Modal} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import CustomerPopupConstant
  from '../../../constant/customer/CustomerPopupConstant';
import CustomerSelectComponent from '../customer/field/CustomerSelectComponent.jsx';
import CustomerInputComponent from '../customer/field/CustomerInputComponent.jsx';
import CustomerCheckBoxComponent from '../customer/field/CustomerCheckBoxComponent.jsx';
import CustomerComboBoxComponent from '../customer/field/CustomerComboBoxComponent.jsx';
import CustomerComboBoxSuggestComponent
  from '../customer/field/CustomerComboBoxSuggestComponent.jsx';
import CustomerAddressFieldService
  from '../../../../service/customer/CustomerAddressFieldService';
import CountryService from '../../../../service/CountryService';
import AddressCustomerHeader from '../customer/address/AddressCustomerHeader.jsx';
import ShippingAction from '../../../action/ShippingAction';
import AddressService from '../../../../service/customer/AddressService';

function ShippingAddressPopup() {
  const {t} = useTranslation();
  const {isShowShippingAddressPopup, isLoadingSave} = useSelector((state) => { return state.core.shipping.shippingAddressPopup; });
  const {addresses} = useSelector((state) => { return state.core.shipping.shippingPopup; });

  const dispatch = useDispatch();

  const [currentAddress, setCurrentAddress] = useState({
    country: AddressService.getDefaultCountry(),
  });
  const [addressFields, setAddressFields] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});

  const isForceEnableDefaultAddress = () => {
    if (!addresses || !addresses.length) {
      return true;
    }
    return Boolean(addresses && addresses.length ===
      1 && addresses[0].id === currentAddress.id);
  };

  useEffect(() => {
    const newCurrentAddress = _.cloneDeep(currentAddress);
    if (isForceEnableDefaultAddress()) {
      newCurrentAddress.is_default_address = true;
    }
    setCurrentAddress(newCurrentAddress);
    setErrorMessages({});
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    if (!(currentAddress && currentAddress.country)) {
      return;
    }
    setAddressFields(CustomerAddressFieldService.loadFieldByCountry(currentAddress.country));
  }, [currentAddress]);


  const validateForm = () => {
    let isValidate = true;
    const validateErrorMessages = {};
    addressFields.forEach(
      (fieldArray) => {
        fieldArray.forEach(
          (field) => {
            if (!field.required || currentAddress[field.code]) {
              return;
            }
            validateErrorMessages[field.code] = [t('This is a required field')];
            isValidate = false;
          },
        );
      },
    );
    if (!isValidate) {
      setErrorMessages(validateErrorMessages);
    }
    return isValidate;
  };

  const saveAddress = () => {
    const addressToSave = _.cloneDeep(currentAddress);
    const displayFields = [];
    addressFields.forEach(
      (fieldArray) => {
        fieldArray.forEach(
          (field) => {
            displayFields.push(field.code);
          },
        );
      },
    );
    Object.keys(addressToSave).forEach((key) => {
      if (!displayFields.includes(key) && key !== 'id') {
        delete addressToSave[key];
      }
    });
    const isValidate = validateForm();
    if (isValidate) {
      dispatch(ShippingAction.saveShippingAddressPopup(addressToSave));
    }
  };
  const cancelPopup = () => {
    dispatch(ShippingAction.hideShippingAddressPopup());
  };

  const validateByField = (field, value) => {
    if (field.required && !value) {
      setErrorMessages(
        {
          ...errorMessages,
          [field.code]: ['This is a required field'],
        },
      );
    } else {
      setErrorMessages(
        {
          ...errorMessages,
          [field.code]: [],
        },
      );
    }
  };

  const handleOnblur = (field, value) => {
    validateByField(field, value);
  };

  const setFieldValueByCode = (code, value, isMultiValue = false) => {
    const newCurrentAddress = _.cloneDeep(currentAddress);
    let currentValue = newCurrentAddress[code];
    if (isMultiValue) {
      if (currentValue && currentValue.length) {
        if (!currentValue.includes(value)) {
          currentValue.push(value);
        }
      } else {
        currentValue = [value];
      }
    } else {
      currentValue = value;
    }
    setCurrentAddress(
      {
        ...currentAddress,
        [code]: currentValue,
      },
    );
  };
  const setFieldValue = (field, value, isMultiValue = false) => {
    if (!(field && field.code)) {
      return;
    }
    validateByField(field, value);
    if (field.code === 'country' && currentAddress.country !== value) {
      setErrorMessages({});
      const countryData = CountryService.loadCountryData(value);
      if (countryData.zones) {
        const firstZone = countryData.zones[0];
        const newCurrentAddress = _.cloneDeep(currentAddress);
        setCurrentAddress(
          {
            ...newCurrentAddress,
            country: value,
            zone: (firstZone && firstZone.code) ? firstZone.code : '',
          },
        );
        return;
      }
    }
    setFieldValueByCode(field.code, value, isMultiValue);
  };

  const getFieldError = (field) => {
    if (errorMessages[field]) {
      return errorMessages[field].join(', ');
    } else {
      return '';
    }
  };

  const renderField = (field, oneRow) => {
    const disabled = false;
    const currentFieldValue = currentAddress[field.code];
    switch (field.type) {
      case CustomerPopupConstant.TYPE_FIELD_INPUT:
        return (
          <CustomerInputComponent
            field={field}
            code={field.code}
            label={field.label}
            key={`${field.code}_input`}
            oneRow={oneRow}
            maxLength={field.maxLength}
            required={field.required}
            setFieldValue={setFieldValue}
            setFieldValueByCode={setFieldValueByCode}
            value={currentFieldValue}
            error={getFieldError(field.code)}
            handleOnblur={handleOnblur}
          />
        );
      case CustomerPopupConstant.TYPE_FIELD_SELECT:
        return (
          <CustomerSelectComponent
            field={field}
            code={field.code}
            label={field.label}
            key={`${field.code}_select`}
            oneRow={oneRow}
            value={currentFieldValue}
            maxLength={field.maxLength}
            required={field.required}
            setFieldValue={setFieldValue}
            setFieldValueByCode={setFieldValueByCode}
            error={getFieldError(field.code)}
            options={field.options}
          />
        );
      case CustomerPopupConstant.TYPE_FIELD_CHECKBOX:
        return (
          <CustomerCheckBoxComponent
            field={field}
            code={field.code}
            label={field.label}
            key={`${field.code}_checkbox`}
            oneRow={oneRow}
            disabled={disabled}
            setFieldValue={setFieldValue}
            setFieldValueByCode={setFieldValueByCode}
            value={currentFieldValue}
            error={getFieldError(field.code)}
          />
        );
      case CustomerPopupConstant.TYPE_FIELD_COMBOBOX:
        return (
          <CustomerComboBoxComponent
            field={field}
            code={field.code}
            label={field.label}
            key={`${field.code}_input`}
            oneRow={oneRow}
            value={currentFieldValue}
            maxLength={field.maxLength}
            required={field.required}
            setFieldValue={setFieldValue}
            setFieldValueByCode={setFieldValueByCode}
            error={getFieldError(field.code)}
          />
        );
      case CustomerPopupConstant.TYPE_FIELD_COMBOBOX_SUGGEST:
        return (
          <CustomerComboBoxSuggestComponent
            field={field}
            code={field.code}
            label={field.label}
            key={`${field.code}_input`}
            oneRow={oneRow}
            value={currentAddress[field.code.replace('_suggest', '')]}
            maxLength={field.maxLength}
            required={field.required}
            options={field.options}
            setFieldValue={setFieldValue}
            setFieldValueByCode={setFieldValueByCode}
            error={getFieldError(field.code)}
          />
        );
      default:
        return '';
    }
  };

  /**
   * Set height popup
   * @param element
   */
  const heightPopup = (element) => {
    if (!element) {
      return;
    }
    const height = $(window).height();
    $(element).css('height', `${height}px`);
  };
  const setPopupShippingAddressElement = (element) => {
    if (!element) {
      return;
    }
    SmoothScrollbar.init(element);
    setTimeout(() => {
      heightPopup('.popup-edit-customer .modal-dialog');
    }, 500);
  };

  return (
    <Modal
      size="lg"
      className="popup-edit-customer"
      dialogClassName="popup-addBillingAddress in"
      show={isShowShippingAddressPopup}
    >
      <AddressCustomerHeader isNewAddress={1} saveAddress={saveAddress} cancelPopup={cancelPopup} isLoadingSave={isLoadingSave} />
      <div data-scrollbar ref={setPopupShippingAddressElement} className="modal-body">
        <div className="box-group">
          {
            addressFields.map((arrField, index) => {
              const isCheckBox = Boolean((arrField.length === 1) &&
                arrField[0].type === CustomerPopupConstant.TYPE_FIELD_CHECKBOX);
              const className = isCheckBox ? "row form-group form-checkbox" : "row form-group";
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={className} key={`group_${index}`}>
                  {
                    arrField.map((field) => {
                      return ((arrField.length > 1)
                        ? renderField(field, false)
                        : renderField(field, true));
                    })
                  }
                </div>
              );
            })
          }
        </div>
      </div>
    </Modal>
  );
}

ShippingAddressPopup.className = 'ShippingAddressPopup';
ShippingAddressPopup.propTypes = {
};

export default React.memo(FunctionComponentFactory.get(ShippingAddressPopup));
