import CoreService from "../CoreService";
import ServiceFactory from "../../framework/factory/ServiceFactory";

export class CustomerPopupServiceClass extends CoreService {
  static className = 'CustomerPopupService';

    /**
     * set row customer field
     * @param arrField
     * @returns {Array}
     */
  setRowCustomerField(arrField) {
    const allRow = [];
    for (const field of arrField) {
      const key = this.checkOneRowField(allRow);
      if (key >= 0 && field.oneRow === false) {
        allRow[key].push(field);
      } else {
        allRow.push([field]);
      }
    }
    return allRow;
  }

    /**
     * check one row field
     * @param allRow
     * @returns {number}
     */
  checkOneRowField(allRow) {
    if (allRow.length > 0) {
      const row = allRow[allRow.length - 1];
      if (row.length === 1 && row[0].oneRow === false) {
        return allRow.length - 1;
      }
    }
    return -1;
  }

  /**
   * Create field input
   * @param code
   * @param ref
   * @param type
   * @param label
   * @param required
   * @param requiredEmail
   * @param maxLength
   * @param oneRow
   * @returns {{ref, code, oneRow, label, type, required, requiredEmail, maxLength}}
   */
  createCustomerFieldInput(code, ref, type, label, required, requiredEmail, maxLength, oneRow) {
    return {
      code,
      ref,
      type,
      label,
      required,
      requiredEmail,
      maxLength,
      oneRow,
    };
  }

  /**
   * Create field select
   * @param code
   * @param ref
   * @param type
   * @param label
   * @param required
   * @param options
   * @param oneRow
   * @returns {{ref, code, oneRow, options, optional, label, type, required}}
   */

  createCustomerFieldSelect(code, ref, type, label, required, options, oneRow) {
    return {
      code,
      ref,
      type,
      label,
      required,
      options,
      oneRow,
    };
  }

    /**
     * create field check
     * @param code
     * @param ref
     * @param type
     * @param label
     * @param check
     * @param disabled
     * @param oneRow
     * @return {{code: *, ref: *, type: *, label: *, check: *, disabled: *, oneRow: *}}
     */
  createCustomerFieldCheckBox(code, ref, type, label, check, disabled, oneRow) {
    return {
      code,
      ref,
      type,
      label,
      check,
      disabled,
      oneRow,
    };
  }

  /**
   * Create field combo box
   * @param code
   * @param ref
   * @param type
   * @param label
   * @param required
   * @param options
   * @param oneRow
   * @returns {{ref, code, oneRow, options, label, type, required}}
   */
  createCustomerFieldCombobox(code, ref, type, label, required, options, oneRow) {
    return {
      code,
      ref,
      type,
      label,
      required,
      options,
      oneRow,
    };
  }

  /**
   * create field combobox suggest
   * @param code
   * @param ref
   * @param type
   * @param label
   * @param required
   * @param options
   * @param oneRow
   * @return {{code: *, ref: *, type: *, label: *, required: *, options: *, oneRow: *. optional: *}}
   */
  createCustomerFieldComboboxSuggest(code, ref, type, label, required, options, oneRow) {
    return {
      code,
      ref,
      type,
      label,
      required,
      options,
      oneRow,
    };
  }

    /**
     * Get field
     * @param data
     * @param code
     */
  getField(data, code) {
    return data.find((item) => item.code === code);
  }

    /**
     * Get field's ref
     * @param field
     * @returns {null}
     */
  getFieldRef(field) {
    return field && field.ref ? field.ref.getWrappedInstance() : null;
  }

    /**
     * get multiline field's data
     * @param displayFormField
     * @param multilineCount
     * @param data
     * @return {Array}
     */
  getMultilineFieldData(displayFormField, multilineCount, data) {
    const fieldData = [];
    fieldData.push(this.getFieldRef(displayFormField).input.value);
    for (let i = 2; i <= multilineCount; i++) {
      const code = `${displayFormField.code}_${i}`;
      const fieldRef = this.getFieldRef(this.getField(data, code));
      if (fieldRef && fieldRef.input.value) {
        fieldData.push(fieldRef.input.value);
      }
    }
    return fieldData;
  }
}

/** @type CustomerPopupServiceClass */
const customerPopupService = ServiceFactory.get(CustomerPopupServiceClass);

export default customerPopupService;
