import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import ConfigResourceModel
  from '../../resource-model/config/ConfigResourceModel';
import LocalStorageService from '../LocalStorageService';
import LocalStorageConstant from '../../view/constant/LocalStorageConstant';
import Config from '../../config/Config';
import ConfigConstant from '../../view/constant/ConfigConstant';
import {fire} from '../../event-bus';
import i18n from '../../config/i18n';
import CountryConstant from "../../view/constant/CountryConstant";

/**
 * Staff Service Class
 */
export class ConfigServiceClass extends CoreService {
  static className = 'ConfigServiceClass';
  resourceModel = ConfigResourceModel;

  /**
   * Get Config Online
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getConfigOnline() {
    return this.getResourceModel().getConfig();
  }

  /**
   * Save all config to Local storage
   * @param data
   */
  saveToLocalStorage(data) {
    LocalStorageService.set(LocalStorageConstant.CONFIG, JSON.stringify(data));
  }

  /**
   * Get Config From Local Storage
   * @returns {any|null}
   */
  getConfigFromLocalStorage() {
    const config = LocalStorageService.get(LocalStorageConstant.CONFIG);
    return config ? JSON.parse(config) : null;
  }

  /**
   * Get config by path
   *
   * @param path
   * @returns {null}
   */
  getConfig(path) {
    let config = null;
    if (Config.config && Config.config.settings !== undefined) {
      config = Config.config.settings.find((item) => item.path === path);
    }
    return config ? config.value : null;
  }

  /**
   * Get Shop Config
   * @returns {[string]|{}}
   */
  getShopConfig() {
    return (Config && Config.config && Config.config.shopConfig)
      ? Config.config.shopConfig : {};
  }

  /**
   * Get Currency Code
   * @returns {*|null}
   */
  getCurrencyCode() {
    const shopConfig = this.getShopConfig();
    if (!shopConfig) {
      return null;
    }
    return shopConfig.currencyCode;
  }

  /**
   * Get Currency Code Mapping
   * @returns {*|null}
   */
  getCountryCodeMapping() {
    const countryCodeMapping = {};
    countryCodeMapping[CountryConstant.KWD_CURRENCY_CODE] = CountryConstant.KW_COUNTRY_CODE;
    return countryCodeMapping;
  }

  /**
   * Get Locale
   * @returns {*|null}
   */
  getLocale() {
    const shopConfig = this.getShopConfig();
    if (!shopConfig) {
      return null;
    }
    return shopConfig.primaryDomain.localization.defaultLocale;
  }

  /**
   * Change language
   * @param locale
   */
  changeLanguage(locale) {
    let localeCode = locale;
    const clientUrl = process.env.REACT_APP_CLIENT_APP_URL;
    if (!localeCode) {
      localeCode = this.getLocale();
    }
    i18n.init({
      fallbackLng: false,
      lng: localeCode.toLowerCase(),

      // have a common namespace used around the full app
      ns: ['translations'],
      defaultNS: 'translations',
      nsSeparator: false,
      keySeparator: false,

      debug: false,

      interpolation: {
        escapeValue: false,
      },

      backend: {
        loadPath: `${clientUrl}/locales/{{lng}}/{{ns}}.json`,
      },

      react: {
        withRef: false,
        useSuspense: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
      },
    });
  }

  /**
   * Check is show delivery date
   *
   * @return {boolean}
   */
  isAllowToAddOutOfStockProduct() {
    const isAllowToAddOutOfStockProduct = Boolean(Number(this.getConfig(ConfigConstant.CHECKOUT_ADD_OUT_OF_STOCK_PRODUCT_PATH)));
    const eventData = {
      isAllowToAddOutOfStockProduct,
    };
    fire('service_config_is_allow_add_out_of_stock_product_after', eventData);

    return eventData.isAllowToAddOutOfStockProduct;
  }
}

/**
 * @type {ConfigServiceClass}
 */
const ConfigService = ServiceFactory.get(ConfigServiceClass);

export default ConfigService;
