import React from 'react';

import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import SessionManagement from '../../component/session/SessionManagement.jsx';

const SessionManagementPage = () => {
  return (
    <SessionManagement />
  );
};

SessionManagementPage.className = 'SessionManagementPage';
export default FunctionComponentFactory.get(SessionManagementPage);
