import {cloneDeep} from 'lodash';

import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import DraftOrderResourceModel
  from '../../resource-model/draft-order/DraftOrderResourceModel';
import CurrencyService from '../CurrencyService';
import ConfigService from '../config/ConfigService';

/**
 * Draft Order Service Class
 */
export class DraftOrderServiceClass extends CoreService {
  static className = 'DraftOrderServiceClass';
  resourceModel = DraftOrderResourceModel;

  /**
   * Draft Order Calculate
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  draftOrderCalculate(input) {
    return this.getResourceModel().draftOrderCalculate(input);
  }

  /**
   * Convert Quote Item To Draft Order Item Input
   * @param item
   * @returns {{}}
   */
  convertQuoteItemToDraftOrderItemInput(item) {
    const productId = item.product?.id;
    const collectionIds = item.product?.collectionIds || [];
    const itemInput = {
      customAttributes: item.customAttributes,
      quantity: item.quantity,
      requiresShipping: item.requiresShipping,
      sku: item.sku,
      taxable: item.taxable,
      title: item.title,
      variantId: item.variantId,
      weight: item.weight,
      productId,
      collectionIds,
    };
    let originalUnitPrice = item.originalUnitPrice || '0';
    if (typeof originalUnitPrice === 'object') {
      originalUnitPrice = originalUnitPrice.amount.toString();
    }
    itemInput.originalUnitPrice = originalUnitPrice;
    if (item.appliedDiscount) {
      itemInput.appliedDiscount = {
        description: item.appliedDiscount.description,
        title: item.appliedDiscount.title,
        value: item.appliedDiscount.value,
        valueType: item.appliedDiscount.valueType,
      };
    }
    return itemInput;
  }

  /**
   * Convert Quote To Draft Order Input
   * @param quote
   * @returns {{}}
   */
  convertQuoteToDraftOrderInput(quote) {
    const draftOrderInput = {
      customAttributes: quote.customAttributes,
      email: quote.email,
      localizationExtensions: quote.localizationExtensions,
      note: quote.note,
      phone: quote.phone,
      tags: quote.tags,
      taxExempt: quote.taxExempt,
      visibleToCustomer: quote.visibleToCustomer,
      presentmentCurrencyCode: CurrencyService.getShopCurrencyCode(),
    };
    const countryCodeMapping = ConfigService.getCountryCodeMapping();
    if (countryCodeMapping && countryCodeMapping[CurrencyService.getShopCurrencyCode()]) {
      draftOrderInput.marketRegionCountryCode = countryCodeMapping[CurrencyService.getShopCurrencyCode()];
    }
    if (quote.customerId) {
      draftOrderInput.purchasingEntity = {
        customerId: quote.customerId,
      };
    }
    if (quote.shippingLine) {
      draftOrderInput.shippingLine = {
        price: quote.shippingLine.price,
        shippingRateHandle: quote.shippingLine.shippingRateHandle,
        title: quote.shippingLine.title,
      };
    }
    if (quote.billingAddress) {
      const billingAddress = cloneDeep(quote.billingAddress);
      delete billingAddress.id;
      delete billingAddress.country;
      delete billingAddress.province;
      draftOrderInput.billingAddress = billingAddress;
    }
    if (quote.shippingAddress) {
      const shippingAddress = cloneDeep(quote.shippingAddress);
      delete shippingAddress.id;
      delete shippingAddress.country;
      delete shippingAddress.province;
      draftOrderInput.shippingAddress = shippingAddress;
    }
    if (quote.appliedDiscount) {
      draftOrderInput.appliedDiscount = {
        description: quote.appliedDiscount.description,
        title: quote.appliedDiscount.title,
        value: quote.appliedDiscount.value,
        valueType: quote.appliedDiscount.valueType,
      };
    }
    if (quote.couponCode) {
      draftOrderInput.couponCode = quote.couponCode;
    }
    if (quote.ignoreDiscounts) {
      draftOrderInput.ignoreDiscounts = quote.ignoreDiscounts;
    }
    draftOrderInput.lineItems = quote.lineItems.map((item) => this.convertQuoteItemToDraftOrderItemInput(item));
    return draftOrderInput;
  }
}

/**
 * @type {DraftOrderServiceClass}
 */
const DraftOrderService = ServiceFactory.get(DraftOrderServiceClass);

export default DraftOrderService;
