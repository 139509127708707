import PropTypes from "prop-types";
import React, {Fragment, useEffect, useRef} from "react";
import {useReactToPrint} from "react-to-print";

import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';


import StylePrintComponent2x from "./StylePrintComponent2x.jsx";
import ToPrintRefundComponent from "./ToPrintRefundComponent.jsx";

function RefundPrintComponent(props) {
  const {orderData, storeInfo, setIsReprint, adjustments} = props;
  const toPrintRefundRef = useRef();

  const onAfterPrint = () => {
    setIsReprint(false);
  };

  const onPrintError = () => {
    setIsReprint(false);
  };

  const handlePrint = useReactToPrint({
    content: () => toPrintRefundRef.current,
    documentTitle: 'refund',
    onAfterPrint,
    onPrintError,
  });

  useEffect(() => {
    handlePrint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <StylePrintComponent2x />
      <div className="" ref={toPrintRefundRef}>
        <ToPrintRefundComponent orderData={orderData} storeInfo={storeInfo} adjustments={adjustments} />
      </div>
    </>
  );
}

RefundPrintComponent.propTypes = {
  orderData: PropTypes.object,
  storeInfo: PropTypes.object,
  setIsReprint: PropTypes.func,
  adjustments: PropTypes.object,
};


RefundPrintComponent.className = "RefundPrintComponent";
export default FunctionComponentFactory.get(RefundPrintComponent);
