import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import StaffAction from '../../action/StaffAction';
import {listen} from '../../../event-bus';
import StaffService from '../../../service/staff/StaffService';
import ApiResponseConstant from '../../constant/ApiResponseConstant';
import "../../../assets/Logout.css";
import MenuAction from '../../action/MenuAction';
import MultiCartAction from '../../action/MultiCartAction';

export function Logout(props) {
  const {t} = useTranslation();

  const {isLogOut, setIsLogOut} = props;

  const {isLogoutSuccess} = useSelector((state) => { return state.core.staffReducer.logoutReducer; });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setIsLogOut(false);
  };

  useEffect(() => {
    if (!isLogoutSuccess) {
      return;
    }
    dispatch(MultiCartAction.removeAllMultiCart());
    dispatch(StaffAction.resetLogoutState());
    dispatch(MenuAction.close());
    handleCloseModal();
    navigate('/login');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLogoutSuccess, navigate]);

  useEffect(() => {
    listen('omc_check_force_sign_out', (data) => {
      if (data.responseCode === ApiResponseConstant.STATUS_CODE_UNAUTHORIZED) {
        dispatch(StaffAction.setSignoutMessage('Oops! Access denied. Recent action has not been saved yet'));
        // Temp to force sign out
        StaffService.resetAllData();
        navigate('/login');
      }
      if (data.responseCode === ApiResponseConstant.STATUS_CODE_CONTINUE_LOGIN) {
        StaffService.setSharingAccount(1);
        navigate('/login');
      }
      if (data.responseCode === ApiResponseConstant.STATUS_CODE_NOT_SELECT_POS) {
        navigate('/locations');
      }
    });
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */


  const handleLogOut = () => {
    dispatch(StaffAction.logout());

  };

  const wrapperModalClass = isLogOut ? "logout_modal" : '';

  return (
    <div className={wrapperModalClass}>
      <Modal
        size="sm"
        className="popup-messages"
        show={isLogOut}
        onHide={handleCloseModal}
      >
        <Modal.Body>
          <h3 className="title">{t('Logout') }</h3>
          <p> { t('Are you sure want to logout?') }</p>
        </Modal.Body>
        <Modal.Footer className="logout-actions">
          <button type="button" onClick={handleCloseModal} >
            {t('No')}
          </button>
          <button type="button" onClick={handleLogOut}>
            {t('Yes')}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

Logout.className = 'Logout';
Logout.propTypes = {
  isLogOut: PropTypes.bool.isRequired,
  setIsLogOut: PropTypes.func.isRequired,
};

export default FunctionComponentFactory.get(Logout);
