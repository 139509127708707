import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";

import FunctionComponentFactory from "../../../../../framework/factory/FunctionComponentFactory";
import i18n from "../../../../../config/i18n";
import OrderService from "../../../../../service/order/OrderService";
import PaymentService from "../../../../../service/payment/PaymentService";
import ContentPaymentItem from "../detail-content/ContentPaymentItem.jsx";
import OrderRefundAction from "../../../../action/order/refund/OrderRefundAction";
import PaymentConstant from "../../../../constant/PaymentConstant";

import AddPaymentRefund from "./AddPayment.jsx";
import ConfirmRefundModal from "./ConfirmRefundModal.jsx";

function CompleteRefund(props) {
  const dispatch = useDispatch();
  const {order} = props;
  const {total, isLoading} = useSelector((state) => state.core.order.orderRefund);
  const [remaining, setRemaining] = useState(total);
  const [reason, setReason] = useState();
  const [collapse, setCollapse] = useState(false);
  const boxTitleClassName = collapse ? "box-title" : "box-title collapsed";
  const listPayment = PaymentService.getListPaymentNotPayLater();
  const listPaymentCodeUsed = [];
  const listRefundPaymentDefault = {};
  const orderTransaction = OrderService.getTransactionsByKindsAndStatus(cloneDeep(order.transactions));
  const listTransaction = orderTransaction.map((transaction) => {
    for (const payment of listPayment) {
      if (transaction.gateway === payment.code) {
        return {
          ...transaction,
          formattedGateway: payment.title,
        };
      }
    }

    return transaction;
  });
  const allOrderTransaction = OrderService.getTransactionsByKindsAndStatus(
    cloneDeep(order.transactions),
    [PaymentConstant.TRANSACTION_PAYMENT_TYPE.SALE, PaymentConstant.TRANSACTION_PAYMENT_TYPE.CAPTURE, PaymentConstant.TRANSACTION_PAYMENT_TYPE.REFUND],
  );

  for (const transaction of allOrderTransaction) {
    if (transaction.kind === PaymentConstant.TRANSACTION_PAYMENT_TYPE.SALE ||
      transaction.kind === PaymentConstant.TRANSACTION_PAYMENT_TYPE.CAPTURE) {
      listPaymentCodeUsed.push(transaction.gateway);
    }
  }

  for (const transaction of allOrderTransaction) {
    let isDefaultPayment = false;
    for (const payment of listPayment) {
      if (transaction.gateway === payment.code) {
        if (listPaymentCodeUsed.includes(payment.code)) {
          if (!listRefundPaymentDefault[`${payment.code}`]) {
            listRefundPaymentDefault[`${payment.code}`] = {
              ...payment,
              amount: 0,
              parentId: transaction.id,
              shopifyPaymentMethod: false,
              maxAmount: 0,
            };
          }
          if (transaction.kind === PaymentConstant.TRANSACTION_PAYMENT_TYPE.REFUND) {
            listRefundPaymentDefault[`${payment.code}`].maxAmount -= transaction.amountSet.shopMoney.amount;
          } else {
            listRefundPaymentDefault[`${payment.code}`].maxAmount += transaction.amountSet.shopMoney.amount;
          }
        } else {
          listRefundPaymentDefault[`${payment.code}`] = {
            ...payment,
            maxAmount: transaction.amountSet.shopMoney.amount,
            amount: 0,
            parentId: transaction.id,
            shopifyPaymentMethod: false,
          };
        }
        isDefaultPayment = true;

        break;
      }
    }

    if (!isDefaultPayment) {
      if (listPaymentCodeUsed.includes(transaction.gateway)) {
        if (listRefundPaymentDefault[`${transaction.gateway}`]) {
          if (transaction.kind === PaymentConstant.TRANSACTION_PAYMENT_TYPE.REFUND) {
            listRefundPaymentDefault[`${transaction.gateway}`].maxAmount -= transaction.amountSet.shopMoney.amount;
          } else if (transaction.kind === PaymentConstant.TRANSACTION_PAYMENT_TYPE.SALE || transaction.kind === PaymentConstant.TRANSACTION_PAYMENT_TYPE.CAPTURE) {
            listRefundPaymentDefault[`${transaction.gateway}`].maxAmount += transaction.amountSet.shopMoney.amount;
          }
        }
      } else if (transaction.kind === PaymentConstant.TRANSACTION_PAYMENT_TYPE.SALE || transaction.kind === PaymentConstant.TRANSACTION_PAYMENT_TYPE.CAPTURE) {
        listRefundPaymentDefault[`${transaction.gateway}`] = {
          active: "1",
          code: transaction.gateway,
          title: transaction.formattedGateway,
          maxAmount: transaction.amountSet.shopMoney.amount,
          parentId: transaction.id,
          icon_path: null,
          amount: 0,
          shopifyPaymentMethod: true,
        };
      }
    }
  }

  const [listRefundPayment, setListRefundPayment] = useState(Object.values(listRefundPaymentDefault));
  const handleListRefundPayment = (index, dataUpdate) => {
    const {val, referenceNo} = dataUpdate;
    const listRefundPaymentClone = cloneDeep(listRefundPayment);
    let remainingUpdate = total;
    listRefundPaymentClone.forEach((refundPayment, indexRefund) => {
      if (indexRefund !== index) {
        remainingUpdate -= parseFloat(refundPayment.amount);
      }
    });

    if (referenceNo) {
      listRefundPaymentClone[index].referenceNo = referenceNo;
    }

    if (val) {
      const valUpdate = Math.min(val, listRefundPaymentClone[index].maxAmount);
      if (valUpdate > remainingUpdate) {
        listRefundPaymentClone[index].amount = remainingUpdate;
        listRefundPaymentClone[index].error = i18n.t(`The maximum value allowed to refund by this method is {{remaining}}`, {remaining: OrderService.getPrice(remainingUpdate)});
        remainingUpdate = 0;
      } else {
        listRefundPaymentClone[index].error = null;
        listRefundPaymentClone[index].amount = valUpdate;
        remainingUpdate -= valUpdate;
      }
    }

    setListRefundPayment(listRefundPaymentClone);
    setRemaining(remainingUpdate);
  };

  const handleRemoveItemRefundPayment = (index) => {
    let remainingUpdate = parseFloat(remaining);
    const listRefundPaymentClone = cloneDeep(listRefundPayment);
    remainingUpdate += parseFloat(listRefundPaymentClone[index].amount);
    listRefundPaymentClone.splice(index, 1);

    setListRefundPayment(listRefundPaymentClone);
    setRemaining(remainingUpdate);
  };

  const handleAddItemRefundPayment = (item) => {
    setListRefundPayment((prevState) => {
      return [...prevState, item];
    });
  };

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

  const cancelPopup = () => {
    setIsOpenConfirmModal(false);
  };

  return (
    <>
      <div
        className="loader-coupon"
        style={{display: isLoading ? 'block' : 'none'}}
      >
        <div className="loader-product" />
      </div>
      <div className="block-content" >
        <div className="block-refund-orderpayment">
          <div className="box">
            <div
              role="presentation" className={boxTitleClassName} onClick={() => setCollapse((prevState) => {
                return !prevState;
              })}
            >
              {i18n.t('Order Payment Method')}
            </div>
            <div className={`box-content collapse${collapse ? "" : " in"}`}>
              <ul>
                <li>
                  <span className="label">{i18n.t('Payment Status')}</span>
                  <span className="value">
                    <span className={`status ${OrderService.getPriceOrderDetailLabel(order).className}`}>
                      {i18n.t(OrderService.getPriceOrderDetailLabel(order)?.label || OrderService.getPriceOrderDetailLabel(order)?.value)}
                    </span>
                  </span>
                </li>
                <>
                  {
                    listTransaction && listTransaction.map((transaction) => {
                      return (
                        <ContentPaymentItem
                          key={transaction.id}
                          transaction={transaction}
                          order={order}
                        />
                      );
                    })
                  }
                </>
              </ul>
            </div>
          </div>
        </div>
        <div className="block-refund-payment">
          <AddPaymentRefund
            remaining={remaining}
            listPayment={listPayment}
            listRefundPayment={listRefundPayment}
            handleListRefundPayment={handleListRefundPayment}
            handleAddItemRefundPayment={handleAddItemRefundPayment}
            handleRemoveItemRefundPayment={handleRemoveItemRefundPayment}
          />
        </div>
      </div>
      <div className="block-bottom">
        <div className="actions-accept">
          <button
            className="btn btn-default btn-cancel"
            type="button"
            onClick={() => { dispatch(OrderRefundAction.handleOpenAdjustments()); }}
          >
            {i18n.t('Back')}
          </button>
          <button
            className={`btn btn-default ${(remaining > 0 || parseFloat(total) === 0) ? "disabled" : ""}`}
            type="button"
            onClick={() => { setIsOpenConfirmModal(true); }}
          >
            {
              i18n.t('Refund {{amount}}', {
                amount: OrderService.formatPrice(
                    total,
                  ),
              })
            }
          </button>
        </div>
      </div>
      {isOpenConfirmModal && <ConfirmRefundModal reason={reason} setReason={setReason} cancelPopup={cancelPopup} listRefundPayment={listRefundPayment} order={order} />}
    </>
  );
}

CompleteRefund.className = 'CompleteRefund';
CompleteRefund.propTypes = {
  order: PropTypes.object,
};
export default FunctionComponentFactory.get(CompleteRefund);
