const CheckoutConstant = {
  CHECK_OUT_PLACE_ORDER: '[CHECKOUT] CHECK_OUT_PLACE_ORDER',
  CHECK_OUT_PLACE_ORDER_RESULT: '[CHECKOUT] CHECK_OUT_PLACE_ORDER_RESULT',
  CHECK_OUT_PLACE_ORDER_ERROR: '[CHECKOUT] CHECK_OUT_PLACE_ORDER_ERROR',
  CHECKOUT_TO_SELECT_PAYMENTS: '[QUOTE] CHECKOUT_TO_SELECT_PAYMENTS',
  CHECKOUT_TO_CATALOG: '[QUOTE] CHECKOUT_TO_CATALOG',
  CHECKOUT_SWITCH_PAGE: '[CHECKOUT] CHECKOUT_SWITCH_PAGE',
  CHECKOUT_INIT_PAYMENTS: '[QUOTE] INIT_PAYMENTS',
  CHECKOUT_PROCESS_PAYMENT: '[CHECKOUT] CHECKOUT_PROCESS_PAYMENT',
  CHECKOUT_PROCESS_SINGLE_PAYMENT_RESULT: '[CHECKOUT] CHECKOUT_PROCESS_SINGLE_PAYMENT_RESULT',
  CHECKOUT_PROCESS_SINGLE_PAYMENT_ERROR: '[CHECKOUT] CHECKOUT_PROCESS_SINGLE_PAYMENT_ERROR',
  GET_LIST_PAYMENT_METHOD_SUCCESS: '[CHECKOUT] GET_LIST_PAYMENT_METHOD_SUCCESS',
  SELECT_PAYMENT_METHOD: '[CHECKOUT] SELECT_PAYMENT_METHOD',
  SET_REMAINING: '[CHECKOUT] SET_REMAINING',
  SET_DEFAULT_PAYMENT_SCREEN: '[CHECKOUT] SET_DEFAULT_PAYMENT_SCREEN',
  CLOSE_EDIT_PAYMENT: '[CHECKOUT] CLOSE_EDIT_PAYMENT',
  ACCEPT_PAYMENT: '[CHECKOUT] ACCEPT_PAYMENT',
  HANDLE_OPEN_LIST_PAYMENT: '[CHECKOUT] HANDLE_OPEN_LIST_PAYMENT',
  UPDATE_PAID_ITEM: '[CHECKOUT] UPDATE_PAID_ITEM',
  HANDLE_UPDATE_PAYMENT: '[CHECKOUT] HANDLE_UPDATE_PAYMENT',
  HANDLE_OPEN_COMPLETE_PAYMENT: '[CHECKOUT] HANDLE_OPEN_COMPLETE_PAYMENT',
  HANDLE_COMPLETED_ORDER_PAYMENT: '[CHECKOUT] HANDLE_COMPLETED_ORDER_PAYMENT',


  SET_CAN_FULFILL_ALL_ITEMS: '[CHECKOUT] SET_CAN_FULFILL_ALL_ITEMS',
  VERIFY_CAN_FULFILL_ALL_ITEMS: '[CHECKOUT] VERIFY_CAN_FULFILL_ALL_ITEMS',
  VERIFY_CAN_FULFILL_ALL_ITEMS_SUCCESS: '[CHECKOUT] VERIFY_CAN_FULFILL_ALL_ITEMS_SUCCESS',
  VERIFY_CAN_FULFILL_ALL_ITEMS_FAILED: '[CHECKOUT] VERIFY_CAN_FULFILL_ALL_ITEMS_FAILED',

  OPEN_CONFLICT_PRODUCT_AUTO_DISCOUNT_POPUP: '[CHECKOUT] OPEN_CONFLICT_PRODUCT_AUTO_DISCOUNT_POPUP',
  CLOSE_CONFLICT_PRODUCT_AUTO_DISCOUNT_POPUP: '[CHECKOUT] CLOSE_CONFLICT_PRODUCT_AUTO_DISCOUNT_POPUP',
  OPEN_CONFLICT_PRODUCT_COUPON_DISCOUNT_POPUP: '[CHECKOUT] OPEN_CONFLICT_PRODUCT_COUPON_DISCOUNT_POPUP',
  CLOSE_CONFLICT_PRODUCT_COUPON_DISCOUNT_POPUP: '[CHECKOUT] CLOSE_CONFLICT_PRODUCT_COUPON_DISCOUNT_POPUP',

  OPEN_CONFLICT_CUSTOM_ORDER_DISCOUNT_AND_COUPON_POPUP: '[CHECKOUT] OPEN_CONFLICT_CUSTOM_ORDER_DISCOUNT_AND_COUPON_POPUP',
  CLOSE_CONFLICT_CUSTOM_ORDER_DISCOUNT_AND_COUPON_POPUP: '[CHECKOUT] CLOSE_CONFLICT_CUSTOM_ORDER_DISCOUNT_AND_COUPON_POPUP',


};

export default CheckoutConstant;
