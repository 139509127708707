import AbstractResourceModel from "../AbstractResourceModel";

export default class ShippingResourceModel extends AbstractResourceModel {
  static className = 'ShippingResourceModel';

  resourceName = 'Shipping';

  /**
   * Get address listing by customer id
   * @param customerId
   * @returns {*|null}
   */
  getAddressListingByCustomerId(customerId) {
    return this.getResourceOnline().getAddressListingByCustomerId(customerId);
  }
}
