import OrderConstant from '../../constant/OrderConstant';

const initialState = {
  orders: [],
  pageInfo: null,
  isLoading: false,
  currentOrder: null,
};

const orderListReducer = (state = initialState, action) => {
  switch (action.type) {
    case OrderConstant.GET_ORDERS:
      return {
        ...state,
        isLoading: true,
      };
    case OrderConstant.GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.items,
        pageInfo: action.pageInfo,
        isLoading: false,
      };
    case OrderConstant.GET_ORDERS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case OrderConstant.SET_CURRENT_ORDER:
      return {
        ...state,
        currentOrder: action.currentOrder,
      };
    case OrderConstant.RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default orderListReducer;
