
import React, {useState} from 'react';
import {Form, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import SmoothScrollbar from "smooth-scrollbar";
import {useDispatch} from 'react-redux';

import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import SessionAction from '../../../action/SessionAction';
import SessionConstant from '../../../constant/SessionConstant';
import SessionService from '../../../../service/session/SessionService';

const ValidateSession = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const setModalSessionElement = (element) => {
    if (!element) {
      return;
    }
    SmoothScrollbar.init(element);
  };
  const session = SessionService.getSessionInProcess();

  const theoreticalClosingBalance = session.theoreticalClosingBalance || 0;
  const closedAmount = SessionService.formatAmount(session?.closed_amount);
  const differentAmount = SessionService.getDifferentAmount(session?.closed_amount, theoreticalClosingBalance);
  const titleDifferent = differentAmount > 0 ? "Overage" : "Shortage";

  const [textNote, setTextNote] = useState();

  const handleConfirm = () => {

    const input = {
      id: session?.id,
      closed_note: textNote?.trim(),
      closed_amount: session?.closed_amount,
    };

    dispatch(SessionAction.validateCloseSession(input));
  };

  const handleCancel = () => {
    dispatch(SessionAction.setTypeSessionPopup(SessionConstant.CLOSE_ALL_POPUP));
  };

  const handleChangeTextNote = (event) => {
    setTextNote(event.target.value);
  };

  const getButtonModal = () => {
    const btnClass = textNote?.trim() ? "btn btn-default" : "btn btn-default disabled";
    return (
      <button
        className={btnClass}
        type="button"
        onClick={handleConfirm}
      >{t('Confirm')}
      </button>
    );
  };

  return (
    <Modal
      className="fade in popup-session"
      dialogClassName="modal-fixheight"
      show
    >
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="cancel"
            data-dismiss="modal"
            aria-label={t("Close")}
            onClick={handleCancel}
          >
            {t('Cancel')}
          </button>
          <h4 className="modal-title" >
            {t('Close Session')}
          </h4>
        </div>
        <div data-scrollbar ref={setModalSessionElement} className="modal-body">
          <p className="color-gray">
            {t('Theoretical balance is not the same as real balance. Please enter reason for loss/profit ' +
                    'if you want to continue closing session.')}
          </p>
          <ul className="balance">
            <li>
              <span className="title">{t('Theoretical Closing Balance')}</span>
              <span className="value">
                {SessionService.formatAmount(theoreticalClosingBalance)}
              </span>
            </li>
            <li>
              <span className="title">{t('Real Closing Balance')}</span>
              <span className="value">
                {closedAmount}
              </span>
            </li>
            <li>
              <span className="title">{titleDifferent}</span>
              <span className="value">
                {SessionService.formatAmount(Math.abs(differentAmount))}
              </span>
            </li>
          </ul>

          <div className="form-textarea">
            <Form.Control
              as="textarea"
              placeholder={t('Enter reason for loss/profit (required)')}
              onChange={handleChangeTextNote}
            />
          </div>
        </div>
        <div className="modal-footer ">
          {getButtonModal()}
        </div>
      </div>
    </Modal>
  );
};

ValidateSession.className = 'ValidateSessionComponent';
export default FunctionComponentFactory.get(ValidateSession);
