const DiscountConstant = {
  STATE_COUPON: 'coupon',
  STATE_PERCENT: 'percent',
  STATE_FIXED: 'fixed',

  DISCOUNT_CLASS_PRODUCT: 'product',
  DISCOUNT_CLASS_ORDER: 'order',
  DISCOUNT_CLASS_SHIPPING: 'shipping',

  DISCOUNT_FREE_SHIPPING_AUTO: 'DISCOUNT_FREE_SHIPPING_AUTO',
  DISCOUNT_BASIC_AUTO: 'BASIC_AUTO',
  DISCOUNT_BUY_X_GET_Y_AUTO: 'BUY_X_GET_Y_AUTO',
  DISCOUNT_COUPON_CODE: 'DISCOUNT_COUPON_CODE',

  POS_CUSTOM_DISCOUNT_LINE_ITEM_CODE: 'POS_CUSTOM_DISCOUNT_LINE_ITEM',
  POS_CUSTOM_DISCOUNT_CODE: 'POS_CUSTOM_DISCOUNT',
};
export default DiscountConstant;
