import DataResourceFactory from "../../framework/factory/DataResourceFactory";
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';

import OmcAbstract from "./OmcAbstract";
import GraphqlCountry from './graphql/GraphqlCountry';

export class OmcCountry extends OmcAbstract {
  static className = 'OmcCountry';

  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlCountry,
  };

  /**
   * Get All countries
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getAllCountriesOnline() {
    return this.getApiResource().getAllCountriesOnline();
  }
}

/**
 * @type {OmcCountry}
 */
export default DataResourceFactory.get(OmcCountry);
