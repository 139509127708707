import DataResourceFactory from "../../framework/factory/DataResourceFactory";
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';

import OmcAbstract from "./OmcAbstract";
import GraphqlStaff from './graphql/GraphqlStaff';

export class OmcStaff extends OmcAbstract {
  static className = 'OmcStaff';

  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlStaff,
  };

  /**
   * Get Brand List
   * @returns {*}
   */
  login(username, password, shop = '') {
    const resource = this.getApiResource();
    return resource.login(username, password, shop);
  }

  /**
   * Logout
   * @returns {*}
   */
  logout() {
    const resource = this.getApiResource();
    return resource.logout();
  }

  /**
   * Continue to login
   * @returns {*}
   */
  continueLogin() {
    const resource = this.getApiResource();
    return resource.continueLogin();
  }

  /**
   * Get Permissions
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getPermissions() {
    return this.getApiResource().getPermissions();
  }

  /**
   * change password
   *
   * @param username
   * @param currentPassword
   * @param newPassword
   * @param shop
   *
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  changePassword(username, currentPassword, newPassword, shop = '') {
    return this.getApiResource().changePassword(username, currentPassword, newPassword, shop);
  }
}

/**
 * @type {OmcStaff}
 */
export default DataResourceFactory.get(OmcStaff);
