import ServiceFactory from "../../../../framework/factory/ServiceFactory";
import QuoteItemService from "../QuoteItemService";

import AbstractTotalService from "./AbstractTotalService";

export class QuoteTotalSubtotalServiceClass extends AbstractTotalService {
  static className = 'QuoteTotalSubtotalServiceClass';

  code = 'subtotalPrice';

  /**
   * Collect address subtotal
   *
   * @param {object} quote
   * @param {object} total
   * @return this
   */
  collect(quote, total) {
    super.collect(quote, total);

    quote.lineItems.forEach((item) => {
      this._initItem(quote, item);
    });
    return this;
  }

  /**
   * Address item initialization & collect subtotal
   *
   * @param {object} quote
   * @param {object} item
   * @return {boolean}
   * @private
   */
  _initItem(quote, item) {
    const product = item.product;
    if (!product) {
      return false;
    }

    this._calculateItemTotal(quote, item);
    this._addAmount(item.discountedTotal);
    return true;
  }

  /**
   * Processing calculation of row price for address item
   *
   * @param {object} quote
   * @param {object} item
   */
  _calculateItemTotal(quote, item) {
    QuoteItemService.getOriginalUnitPrice(item);
    QuoteItemService.calcOriginalTotal(quote, item);

    QuoteItemService.getDiscountedUnitPrice(item);
    QuoteItemService.calcDiscountedTotal(quote, item);
    return this;
  }
}

const quoteTotalSubtotalService = ServiceFactory.get(QuoteTotalSubtotalServiceClass);

export default quoteTotalSubtotalService;
