import _ from 'lodash';

import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import ShippingResourceModel
  from '../../resource-model/shipping/ShippingResourceModel';
import QuoteService from "../checkout/QuoteService";
import AddressService from '../checkout/quote/AddressService';

/**
 * Shipping Service Class
 */
export class ShippingServiceClass extends CoreService {
  static className = 'ShippingServiceClass';
  resourceModel = ShippingResourceModel;

  /**
   * Get address listing by customer id
   * @param customerId
   * @returns {*|null}
   */
  getAddressListingByCustomerId(customerId) {
    return this.getResourceModel().getAddressListingByCustomerId(customerId);
  }

  /**
   * Get Shipping Method
   * @param {*} quote
   * @returns {[object]}
   */
  async getShippingMethod(quote) {
    const fieldsDisable = ['shippingLine', 'availableShippingRates'];

    const newQuote = _.clone(quote);
    if (newQuote.billingAddress) {
      delete newQuote.billingAddress?.is_default_address;
    }

    if (newQuote.shippingAddress) {
      delete newQuote.shippingAddress?.is_default_address;
    }

    const quoteTemp = await QuoteService.calculateTotalsOnline(newQuote, true, fieldsDisable, true);

    const {availableShippingRates = []} = quoteTemp || {};

    return availableShippingRates || [];
  }

  /**
   * Is Guest Default shipping Address
   * @param {*} quote
   * @returns {boolean}
   */
  isGuestDefaultShippingAddress(quote) {
    const tempAddress = AddressService.createTempAddress({});
    const shippingAddressTemp = tempAddress.shippingAddress || {};
    const shippingAddressCurrent = quote.shippingAddress;

    return JSON.stringify(shippingAddressTemp) === JSON.stringify(shippingAddressCurrent);
  }

  /**
   * Is Customer Default Shipping Address
   * @param {*} quote
   * @returns {boolean}
   */
  isCustomerDefaultShippingAddress(quote) {
    const customer = quote.customer || {};
    const shippingAddressTemp = AddressService.createAddress(null, customer);
    const shippingAddressCurrent = quote.shippingAddress;

    return JSON.stringify(shippingAddressTemp) === JSON.stringify(shippingAddressCurrent);
  }
}


/**
 * @type {ShippingServiceClass}
 */
const shippingService = ServiceFactory.get(ShippingServiceClass);

export default shippingService;
