import ServiceFactory from "../framework/factory/ServiceFactory";

export class QueryServiceClass {
  params = [];
  filterParams = [];
  orFilterParams = [];
  queryString = null;
  orderParams = [];
  pageSize = null;
  currentPage = 1;
  DEFAULT_ORDER_DIRECTION = 'ASC';
  DEFAULT_FILTER_CONDITION = '=';

  className = 'QueryServiceClass';

    /**
     * Reset query params
     */
  reset() {
    this.params = [];
    this.filterParams = [];
    this.orFilterParams = [];
    this.queryString = null;
    this.orderParams = [];
    this.pageSize = null;
    this.currentPage = 1;
    this.DEFAULT_ORDER_DIRECTION = 'ASC';
    this.DEFAULT_FILTER_CONDITION = '=';
    return this;
  }

    /**
     * add parameters
     *
     * @param key
     * @param value
     * @returns {this}
     */
  addParams(key, value) {
    this.params.push({
      key,
      value,
    });
    return this;
  }

    /**
     * add field to filter
     *
     * @param field
     * @param value
     * @param condition
     * @returns {addFieldToFilter}
     */
  addFieldToFilter(field, value, condition) {
    if (Array.isArray(field)) {
      const orFilter = [];
      field.map((item) =>
        orFilter.push({
          field: item[0],
          value: item[1],
          condition: item[2] || this.DEFAULT_FILTER_CONDITION,
        }));
      this.orFilterParams.push(orFilter);
    } else {
      this.filterParams.push({
        field,
        value,
        condition,
      });
    }
    return this;
  }

    /**
     * Add query string filter
     */
  addQueryString(string) {
    this.queryString = string.trim();
  }

    /**
     * set order
     *
     * @param field
     * @param direction
     * @returns {this}
     */
  setOrder(field, direction) {
    this.orderParams.push({
      field,
      direction: direction || this.DEFAULT_ORDER_DIRECTION,
    });
    return this;
  }

    /**
     * set pages size
     *
     * @param pageSize
     * @returns {this}
     */
  setPageSize(pageSize) {
    this.pageSize = pageSize;
    return this;
  }

    /**
     * set current pages
     *
     * @param currentPage
     */
  setCurrentPage(currentPage) {
    this.currentPage = currentPage;
  }

    /**
     * Add searchCriteria param
     * @param key
     * @param value
     */
  addSearchCriteriaParam(key, value) {
    this.addParams(`searchCriteria[${key}]`, value);
  }
}

/**
 * @type {QueryServiceClass}
 */
const queryService = ServiceFactory.get(QueryServiceClass);
export default queryService;
