import {isMobile, isIOS, isAndroid} from 'react-device-detect';

import ServiceFactory from '../framework/factory/ServiceFactory';

export class DeviceServiceClass {
  className = 'DeviceServiceClass';

  /**
   * Check is using mobile device
   * @returns {boolean}
   */
  isMobile() {
    return isMobile || this.isIOS();
  }

  /**
   * Check is using ios device
   * @returns {boolean}
   */
  isIOS() {
    return isIOS || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  }

  /**
   * Check is using android device
   * @returns {boolean}
   */
  isAndroid() {
    return isAndroid;
  }
}

const DeviceService = ServiceFactory.get(DeviceServiceClass);
export default DeviceService;
