import {cloneDeep} from 'lodash';
import {toast} from 'react-toastify';
import {t} from 'i18next';

import ActionFactory from '../../../framework/factory/ActionFactory';
import QuoteConstant from '../../constant/checkout/QuoteConstant';
import QuoteService from '../../../service/checkout/QuoteService';
import ProductService from '../../../service/product/ProductService';
import {fire} from '../../../event-bus';
import CustomerAction from '../CustomerAction';
import i18n from '../../../config/i18n';
import ScanAction from '../ScanAction';
import PaymentAction from '../PaymentAction';
import PaymentConstant from '../../constant/PaymentConstant';
import QuoteCustomDiscountService from "../../../service/checkout/quote/QuoteCustomDiscountService";
import QuoteItemService from '../../../service/checkout/quote/QuoteItemService';
import QuoteCustomChargeTaxService from '../../../service/checkout/quote/QuoteCustomChargeTax';
import ApplyCouponDiscount from '../../../service/checkout/quote/discount/ApplyCouponDiscount';

import CheckoutAction from './CheckoutAction';
import ConflictProductDiscountAction from './conflict-discount/ConflictProductDiscountAction';
import EditPriceAction from './EditPriceAction';
import ConflictOrderDiscountAction from './conflict-discount/ConflictOrderDiscountAction';

export class QuoteActionClass {

  /**
   * Add ProductTo Current Quote
   * @param product
   * @returns {(function(*): void)|*}
   */
  addProductToCurrentQuote(product) {
    return async (dispatch) => {
      dispatch({
        type: QuoteConstant.ADD_PRODUCT_TO_CURRENT_QUOTE,
        product,
      });
      if (ProductService.isComposite(product)) {
        const ProductAction = require('../ProductAction').default;
        dispatch(ProductAction.viewProduct(product));
        return;
      }

      /* add product_options */
      const data = await QuoteService.addProductWithoutOptions(product);
      dispatch(this.addProduct(data));
    };
  }

  addProduct(data, isShowErrorToastMessage = true) {
    return async (dispatch, getState) => {
      dispatch({
        type: QuoteConstant.ADD_PRODUCT,
        data,
      });
      try {
        const quote = cloneDeep(getState().core.checkout.quote);

        const product = data.product || {};
        if (product.isGiftCard) {
          const invalidProduct = {
            data,
            cannotAddQty: data.quantity,
          };
          dispatch(this.addProductFail("Product is gift card", invalidProduct));
          return;
        }

        const result = await QuoteService.addProduct(quote, data, isShowErrorToastMessage);
        if (!result.success) {
          const invalidProduct = {
            data,
            cannotAddQty: result.cannotAddQty,
          };
          dispatch(this.addProductFail(result, invalidProduct));
          return;
        }
        dispatch(this.addProductSuccess(result.quote));
        dispatch(this.addedItemIdInQuote(result.added_item_id));
        dispatch(this.setQuote(result.quote));
      } catch (error) {
        dispatch(this.addProductFail(error.message));
      }
    };
  }

  /**
   * item_id new product add quote
   *
   * @param addedItemId
   * @returns {{type: string, product: *}}
   */
  addedItemIdInQuote(addedItemId) {
    return {
      type: QuoteConstant.PRODUCT_ADD_QUOTE,
      addedItemId,
    };
  }

  /**
   * item_id updated
   *
   * @param updatedItemId
   * @returns {{type: string, product: *}}
   */
  updatedItemIdInQuote(updatedItemId) {
    return {
      type: QuoteConstant.PRODUCT_UPDATED_QUOTE,
      updatedItemId,
    };
  }

  /**
   * Add Product Success
   *
   * @param quote
   * @returns {{type: string, quote: *}}
   */
  addProductSuccess(quote) {
    return {
      type: QuoteConstant.ADD_PRODUCT_SUCCESS,
      quote,
    };
  }

  /**
   * Add Product Failed
   *
   * @param error
   * @param invalidProduct
   * @returns {{type: string, error: *, invalidProduct: *}}
   */
  addProductFail(error, invalidProduct) {
    return {
      type: QuoteConstant.ADD_PRODUCT_FAIL,
      error,
      invalidProduct,
    };
  }

  /**
   * Reset Cannot Add Product List
   *
   * @returns {{type: string}}
   */
  resetCannotAddProductList() {
    return {
      type: QuoteConstant.RESET_CANNOT_ADD_PRODUCT_LIST,
    };
  }

  setQuote(quote) {
    return {
      type: QuoteConstant.SET_QUOTE,
      quote,
    };
  }

  /**
   * removeCartItem action, when user click x button on cart
   *
   * @param item
   * @return {{type: string, item: *}}
   */
  removeCartItem(item) {
    return async (dispatch, getState) => {
      dispatch({
        type: QuoteConstant.REMOVE_CART_ITEM,
        item,
      });
      try {
        const quote = cloneDeep(getState().core.checkout.quote);

        const result = await QuoteService.removeItem(quote, item);
        if (!result.success) {
          dispatch(this.removeCartItemFail(result));
          return;
        }
        dispatch(this.removeCartItemSuccess(result.quote));
        dispatch(this.setQuote(result.quote));
      } catch (error) {
        dispatch(this.removeCartItemFail(error.message));
      }
    };
  }

  /**
   * Remove Cart Item Success
   * @param quote
   * @returns {{quote, type: string}}
   */
  removeCartItemSuccess(quote) {
    return {
      type: QuoteConstant.REMOVE_CART_ITEM_SUCCESS,
      quote,
    };
  }

  /**
   * Remove Cart Item Fail
   * @param error
   * @returns {{type: string, error}}
   */
  removeCartItemFail(error) {
    return {
      type: QuoteConstant.REMOVE_CART_ITEM_FAIL,
      error,
    };
  }

  /**
   * Set customer for quote
   *
   * @param customer
   * @return {{type: string, customer: *}}
   */
  setCustomer(customer) {
    return (dispatch, getState) => {
      dispatch({
        type: QuoteConstant.SET_CUSTOMER,
        customer,
      });
      const oldQuote = cloneDeep(getState().core.checkout.quote);
      const oldCustomer = oldQuote.customer;
      const quote = QuoteService.changeCustomer(oldQuote, customer);
      fire('epic_change_customer_after', {oldCustomer, quote});
      dispatch(this.setQuote(quote));
      dispatch(CustomerAction.hideCustomerListPopup());
    };
  }

  /**
   * Update Qty Cart Item
   * @param item
   * @param qty
   * @returns {(function(*, *): Promise<void>)|*}
   */
  updateQtyCartItem(item, qty) {
    return async (dispatch, getState) => {
      dispatch({
        type: QuoteConstant.UPDATE_QTY_CART_ITEM,
        item,
        qty,
      });
      try {
        const quote = cloneDeep(getState().core.checkout.quote);

        const result = await QuoteService.updateQtyCartItem(quote, item, qty);
        if (!result.success) {
          toast.error(i18n.t(result.message));
          dispatch(this.updateQtyCartItemFail(result.message));
          return;
        }
        dispatch(this.updatedItemIdInQuote(item.id));
        dispatch(this.setQuote(result.quote));
        dispatch(this.updateQtyCartItemSuccess(result.quote));
      } catch (error) {
        toast.error(i18n.t(error.message));
        dispatch(this.updateQtyCartItemFail(error.message));
      }
    };
  }

  /**
   * Update Qty Cart Item Success
   * @param quote
   * @return {{type: string, quote: *}}
   */
  updateQtyCartItemSuccess(quote) {
    return {
      type: QuoteConstant.UPDATE_QTY_CART_ITEM_SUCCESS,
      quote,
    };
  }

  /**
   * Update Qty Cart Item Fail
   * @param error
   * @return {{type: string, response: *}}
   */
  updateQtyCartItemFail(error) {
    return {
      type: QuoteConstant.UPDATE_QTY_CART_ITEM_FAIL,
      error,
    };
  }

  /**
   * Checkout Quote
   *
   * @param quote
   * @returns {(function(*): Promise<void>)|*}
   */
  checkoutQuote(quote) {
    return async (dispatch) => {
      dispatch({
        type: QuoteConstant.CHECKOUT_QUOTE,
        quote,
      });
      try {
        const newQuoteData = await QuoteService.calculateTotalsOnline(quote);
        dispatch(this.setQuote(newQuoteData));
        dispatch(CheckoutAction.checkoutToSelectPayments(quote));
        dispatch(PaymentAction.switchPage(PaymentConstant.PAYMENT_PAGE_SELECT_PAYMENT));
        dispatch(ScanAction.setScanPage());
        dispatch(this.checkoutQuoteSuccess(quote));
      } catch (error) {
        if (error.message === QuoteConstant.CONFLICT_CUSTOM_PRODUCT_PRICE_AND_AUTO_PRODUCT_DISCOUNT_CODE) {
          const appliedCustomDiscountLineItems = quote.lineItems.filter((lineItem) => {
            return lineItem.appliedDiscount && QuoteService.isAppliedCustomDiscountLineItem(lineItem);
          });
          dispatch(ConflictProductDiscountAction.openConflictAutoDiscountPopup(appliedCustomDiscountLineItems));
        } else {
          toast.error(error.message);
        }
        dispatch(this.checkoutQuoteFailed(error.message));
      }
    };
  }

  /**
   * Checkout Quote Success
   * @param quote
   * @returns {{quote, type: string}}
   */
  checkoutQuoteSuccess(quote) {
    return {
      type: QuoteConstant.CHECKOUT_QUOTE_SUCCESS,
      quote,
    };
  }

  /**
   * Checkout Quote Failed
   * @param error
   * @returns {{type: string, error}}
   */
  checkoutQuoteFailed(error) {
    return {
      type: QuoteConstant.CHECKOUT_QUOTE_FAILED,
      error,
    };
  }

  /**
   * Set custom discount to quote
   * @param quote
   * @param discountType
   * @param discountAmount
   * @param discountReason
   * @returns {{type: string, quote: *, discountType: *, discountAmount: *, discountReason: *}}
   */
  setCustomDiscount(quote, discountType, discountAmount, discountReason) {
    return async (dispatch) => {
      dispatch({
        type: QuoteConstant.SET_CUSTOM_DISCOUNT,
        quote,
        discountType,
        discountAmount,
        discountReason,
      });
      try {
        const result = await QuoteCustomDiscountService.applyCustomDiscount(quote, discountType, discountAmount, discountReason);
        if (result.quote) {
          dispatch(this.setQuote(result.quote));
        }
        dispatch(EditPriceAction.resetCustomPriceLineItem());
        dispatch(this.setCustomDiscountSuccess(result));
      } catch (error) {
        toast.error(i18n.t("Apply custom discount failed!"));
        dispatch(this.setCustomDiscountFailed(error.message));
      }
    };
  }

  /**
   * Set Custom Discount Success
   * @param result
   * @returns {{quote, type: string}}
   */
  setCustomDiscountSuccess(result) {
    return {
      type: QuoteConstant.SET_CUSTOM_DISCOUNT_SUCCESS,
      result,
    };
  }

  /**
   * Set Custom Discount Failed
   * @param error
   * @returns {{type: string, error}}
   */
  setCustomDiscountFailed(error) {
    return {
      type: QuoteConstant.SET_CUSTOM_DISCOUNT_FAILED,
      error,
    };
  }

  /**
   * Set Is Applying Discount
   * @param isApplyingDiscount
   * @returns {{isApplyingDiscount, type: string}}
   */
  setIsApplyingDiscount(isApplyingDiscount = false) {
    return {
      type: QuoteConstant.SET_IS_APPLYING_DISCOUNT,
      isApplyingDiscount,
    };
  }

  /**
   * Set Is Applying Discount
   * @param isAppliedDiscount
   * @returns {{isApplyingDiscount, type: string}}
   */
  setIsAppliedDiscount(isAppliedDiscount = false) {
    return {
      type: QuoteConstant.SET_IS_APPLIED_DISCOUNT,
      isAppliedDiscount,
    };
  }

  /**
   * Set Custom Discount Item
   * @param quote
   * @param item
   * @param discountType
   * @param discountAmount
   * @param reason
   * @returns {(function(*): void)|*}
   */
  setCustomDiscountItem(quote, item, discountType, discountAmount, reason) {
    return (dispatch) => {
      const result = QuoteItemService.applyCustomDiscountItem(quote, item, discountType, discountAmount, reason);
      dispatch(this.setQuote(result.quote));
    };
  }

  /**
   * Set Custom Price Item
   * @param quote
   * @param item
   * @param customPrice
   * @param reason
   * @returns {(function(*): void)|*}
   */
  setCustomPriceItem(quote, item, customPrice, reason) {
    return (dispatch) => {
      const result = QuoteItemService.applyCustomPriceItem(quote, item, customPrice, reason);
      dispatch(this.setQuote(result.quote));
    };
  }

  /**
   * Set Custom Charge Tax
   * @param quote
   * @param isChargeTax
   * @param reason
   * @returns {(function(*): void)|*}
   */
  setCustomChargeTax(quote, isChargeTax) {
    const taxExempt = !isChargeTax;
    return async (dispatch) => {
      dispatch({
        type: QuoteConstant.SET_CUSTOM_CHARGE_TAX,
        quote,
        taxExempt,
      });
      try {
        const result = await QuoteCustomChargeTaxService.applyCustomChargeTax(quote, taxExempt);
        if (result.quote) {
          dispatch(this.setQuote(result.quote));
        }
        dispatch(this.setCustomChargeTaxSuccess(result));
      } catch (error) {
        toast.error(i18n.t("Set charge tax failed!"));
        dispatch(this.setCustomChargeTaxFailed(error.message));
      }
    };
  }


  /**
   * Set Custom Charge Tax Success
   * @param result
   * @returns {{quote, type: string}}
   */
  setCustomChargeTaxSuccess(result) {
    return {
      type: QuoteConstant.SET_CUSTOM_CHARGE_TAX_SUCCESS,
      result,
    };
  }

  /**
   * Set Custom Charge Tax Failed
   * @param error
   * @returns {{type: string, error}}
   */
  setCustomChargeTaxFailed(error) {
    return {
      type: QuoteConstant.SET_CUSTOM_CHARGE_TAX_FAILED,
      error,
    };
  }

  /**
   * Handle Apply Coupon
   * @param {*} quote
   * @param {*} coupon
   * @returns {object}
   */
  handleApplyCoupon(quote, couponCode) {
    return async (dispatch) => {
      dispatch({
        type: QuoteConstant.HANDLE_APPLY_COUPON,
        quote,
        couponCode,
      });
      try {
        const quoteData = cloneDeep(quote);

        const result = await ApplyCouponDiscount.applyCouponCode(quoteData, couponCode);
        if (result.quote) {
          dispatch(this.setQuote(result.quote));
        }
        dispatch(this.handleApplyCouponSuccess(result));
        dispatch(this.setConflictCouponSubmitted(null));
      } catch (error) {
        if (error.message === QuoteConstant.CONFLICT_CUSTOM_PRODUCT_PRICE_AND_COUPON_PRODUCT_DISCOUNT_CODE) {
          const appliedCustomDiscountLineItems = quote.lineItems.filter((lineItem) => {
            return lineItem.appliedDiscount && QuoteService.isAppliedCustomDiscountLineItem(lineItem);
          });
          dispatch(this.setConflictCouponSubmitted(couponCode));
          dispatch(ConflictProductDiscountAction.openConflictCouponDiscountPopup(appliedCustomDiscountLineItems));

          /* Not show this error message in apply coupon popup */
          error.message = "";
        } else if (error.message === QuoteConstant.CONFLICT_CUSTOM_ORDER_DISCOUNT_AND_COUPON_DISCOUNT_CODE) {
          dispatch(this.setConflictCouponSubmitted(couponCode));
          dispatch(ConflictOrderDiscountAction.openConflictCustomOrderDiscountAndCoupon());

          /* Not show this error message in apply coupon popup */
          error.message = "";
        }
        dispatch(this.handleApplyCouponFailed(error, couponCode));
      }
    };
  }

  /**
   * Handle Apply Coupon Success
   * @param {*} result
   * @returns {type: string, result}
   */
  handleApplyCouponSuccess(result) {
    return {
      type: QuoteConstant.HANDLE_APPLY_COUPON_SUCCESS,
      result,
    };
  }

  /**
   * Handle to apply coupon code fail
   * @param error
   * @param couponCode
   * @returns {{type: string, applyCouponErrorMessage: string}}
   */
  handleApplyCouponFailed(error, couponCode) {
    let message = error.message;
    switch (error.message) {
      case QuoteConstant.DISCOUNT_CODE_EXISTED_CODE:
      case QuoteConstant.ALREADY_HAVE_BETTER_COMBINATION_CODE:
        message = i18n.t("{{couponCode}} couldn't be used with your existing discounts. You already have a better combination.", {couponCode});
        break;
      case QuoteConstant.DISCOUNT_CODE_INVALID_MESSAGE:
        message = i18n.t("Invalid coupon code");
        break;
      case QuoteConstant.INVALID_SHIPPING_RATE_DISCOUNT_CODE:
        message = i18n.t(QuoteConstant.INVALID_SHIPPING_RATE_DISCOUNT_MESSAGE);
        break;
      default:
        message = error.message;
    }
    return {
      type: QuoteConstant.HANDLE_APPLY_COUPON_FAILED,
      applyCouponErrorMessage: message,
    };
  }

  /**
   * Set Is Applying Coupon
   * @param isApplyingCoupon
   * @returns {{isApplyingCoupon, type: string}}
   */
  setIsApplyingCoupon(isApplyingCoupon = false) {
    return {
      type: QuoteConstant.SET_IS_APPLYING_COUPON,
      isApplyingCoupon,
    };
  }

  /**
   * Set Is Applying Coupon
   * @param isAppliedCoupon
   * @returns {{isApplyingCoupon, type: string}}
   */
  setIsAppliedCoupon(isAppliedCoupon = false) {
    return {
      type: QuoteConstant.SET_IS_APPLIED_COUPON,
      isAppliedCoupon,
    };
  }

  /**
   * Handle Remove Coupon
   * @param {*} quote
   * @param {*} coupon
   * @returns {object}
   */
  handleRemoveCoupon(quote, couponCode) {
    return async (dispatch) => {
      dispatch({
        type: QuoteConstant.HANDLE_REMOVE_COUPON,
        quote,
        couponCode,
      });
      try {
        const result = await ApplyCouponDiscount.removeCouponCode(quote, couponCode);
        if (result && result.quote) {
          dispatch(this.setQuote(result.quote));
        }
        dispatch(this.handleRemoveCouponSuccess(result));
      } catch (error) {
        toast.error(i18n.t("Remove coupon failed!"));
        dispatch(this.handleRemoveCouponFailed(error.message));
      }
    };
  }

  /**
   * Handle Remove Coupon Success
   * @param {*} result
   * @returns {type: string, result}
   */
  handleRemoveCouponSuccess(result) {
    return {
      type: QuoteConstant.HANDLE_REMOVE_COUPON_SUCCESS,
      result,
    };
  }

  /**
   * Handle Remove Coupon Failed
   * @param {*} error
   * @returns {type: string, error}
   */
  handleRemoveCouponFailed(error) {
    return {
      type: QuoteConstant.HANDLE_REMOVE_COUPON_FAILED,
      error,
    };
  }

  /**
   * Handle Remove Quote Discount Except Custom Discount
   * @param {*} quote
   */
  removeQuoteDiscountExceptCustomDiscount(quote) {
    return (dispatch) => {
      const result = QuoteService.removeQuoteDiscountExceptCustomDiscount(quote);
      if (result && result.quote) {
        dispatch(this.setQuote(result.quote));
      }
    };
  }

  /**
   * Set Conflict Coupon Submitted
   * @param {*} conflictCouponSubmitted
   * @returns {type: string, conflictCouponSubmitted: *}
   */
  setConflictCouponSubmitted(conflictCouponSubmitted) {
    return {
      type: QuoteConstant.SET_CONFLICT_COUPON_SUBMITTED,
      conflictCouponSubmitted,
    };
  }

  /**
   * Set Show Conflict Coupon Submitted Popup
   * @param {*} showConflictCouponSubmittedPopup
   * @returns {type: string, showConflictCouponSubmittedPopup: *}
   */
  setShowConflictCouponSubmittedPopup(showConflictCouponSubmittedPopup) {
    return {
      type: QuoteConstant.SET_SHOW_CONFLICT_COUPON_SUBMITTED_POPUP,
      showConflictCouponSubmittedPopup,
    };
  }

  /**
   * Add Custom Product
   * @param {*} customProduct
   * @returns {(function(*): void)|*}
   */
  addCustomProduct(customProduct) {
    const customProductData = QuoteItemService.createCustomSaleItem(customProduct);
    return (dispatch, getState) => {
      dispatch({
        type: QuoteConstant.ADD_CUSTOM_SALE,
        customProductData,
      });
      try {
        const quote = cloneDeep(getState().core.checkout.quote);
        const customItem = {
          ...QuoteItemService.createItem(customProductData, parseFloat(customProductData.quantity), quote),
          custom: true,
        };
        if (customProduct.note && customProduct.note.length > 0) {
          customItem.customAttributes.push({
            key: t(QuoteConstant.NOTE_ITEM_CUSTOM_ATTRIBUTE_KEY),
            value: customProduct.note,
          });
        }
        quote.lineItems.push(customItem);
        dispatch(this.setQuote(QuoteService.collectTotals(quote)));
        dispatch(this.addProductSuccess(quote));
      } catch (error) {
        dispatch(this.addProductFail("Can not add custom sale"));
      }
    };
  }
}

/**
 * @type {QuoteActionClass}
 */
const QuoteAction = ActionFactory.get(QuoteActionClass);
export default QuoteAction;
