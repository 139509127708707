import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import SmoothScrollbar from "smooth-scrollbar";
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

import NumPad from '../../lib/react-numpad';
import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import SessionService from '../../../../service/session/SessionService';
import SessionAction from '../../../action/SessionAction';
import SessionConstant from '../../../constant/SessionConstant';

const ModalSession = (props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const setModalSessionElement = (element) => {
    if (!element) {
      return;
    }
    SmoothScrollbar.init(element);
  };

  const {handleAction, titleAction, title, titleNumPad} = props;


  const [openAmount, setOpenAmount] = useState(0);

  const handleClickCancelButton = () => {
    dispatch(SessionAction.setTypeSessionPopup(SessionConstant.CLOSE_ALL_POPUP));
  };

  const handleChangeAmount = (value) => {
    setOpenAmount(Number(value));
  };

  const handleActionModal = () => {
    handleAction(openAmount);
  };

  return (
    <div className="wrapper-modal">
      <Modal
        className="fade in popup-session"
        dialogClassName="modal-fixheight"
        show
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="cancel"
              data-dismiss="modal"
              aria-label={t("Close")}
              onClick={handleClickCancelButton}
            >
              {t('Cancel')}
            </button>
            <h4 className="modal-title" >{title}</h4>
          </div>
          <div data-scrollbar ref={setModalSessionElement} className="modal-body">

            <div className="opening-balance-title">
              <div className="pull-left">
                <b>{t('POS')}</b>  <span>{SessionService.getPosName()}</span>
              </div>
            </div>
            <NumPad.CustomNumber
              onChange={(value) => handleChangeAmount(value)}
              position="centerRight"
              sync={false}
              isShowAction
              arrow="right"
              value={openAmount}
            >
              <div
                className="opening-balance"
                data-container="body"
                data-toggle="popover"
                data-placement="right"
              >
                <span className="title">{titleNumPad}</span>
                <span className="price">{SessionService.formatAmount(openAmount)}</span>
              </div>
            </NumPad.CustomNumber>
          </div>
          <div className="modal-footer ">
            <button
              className="btn btn-default"
              type="button"
              aria-label={t("Open")}
              onClick={handleActionModal}
            >
              {titleAction}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ModalSession.propTypes = {
  handleAction: PropTypes.func.isRequired,
  titleAction: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleNumPad: PropTypes.string.isRequired,

};


ModalSession.className = 'ModalSessionComponent';
export default FunctionComponentFactory.get(ModalSession);
