import ModuleConfigAbstract from "../../ModuleConfigAbstract";
import OmcStaffPlugin from "../plugin/data/omc/OmcStaffPlugin";
import CustomerActionPlugin from "../plugin/action/CustomerActionPlugin";

class ConfigClass extends ModuleConfigAbstract {
  module = ['shopify'];
  menu = {};
  observer = (() => {
    return null;
  })();

  reducer = {

  };

  rewrite = {
    function_component: {

    },
  };

  plugin = {
    data_resource: {
      OmcStaff: OmcStaffPlugin,
    },
    action: {
      CustomerActionClass: CustomerActionPlugin,
    },
  };

  layout = {
  };
}

const config = new ConfigClass();
export default config;
