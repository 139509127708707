import ActionFactory from "../../../../framework/factory/ActionFactory";
import OrderConstant from "../../../constant/OrderConstant";
import OrderRefundConstant from "../../../constant/order/refund/OrderRefundConstant";
import LocationService from "../../../../service/location/LocationService";
import OrderService from "../../../../service/order/OrderService";
import StaffService from "../../../../service/staff/StaffService";

export class OrderRefundActionClass {

  /**
   * open refund component
   *
   * @returns {{type: string}}
   */
  handleOpenRefund() {
    return {
      type: OrderConstant.HANDLE_OPEN_REFUND,
    };
  }

  /**
   * close refund component
   *
   * @returns {{type: string}}
   */
  handleCloseRefund() {
    return {
      type: OrderConstant.HANDLE_CLOSE_REFUND,
    };
  }

  /**
   *  open refund Adjustments component
   *
   * @returns {{type: string}}
   */
  handleOpenAdjustments() {
    return {
      type: OrderConstant.HANDLE_OPEN_ADJUSTMENTS,
    };
  }

  /**
   *  set current total when select items refund
   *
   * @returns {{type: string}}
   */
  setCurrentTotalWhenSelectItems(total, totalAfterSelectedItems) {
    return {
      type: OrderConstant.SET_CURRENT_TOTAL_WHEN_SELECT_ITEMS,
      total,
      totalAfterSelectedItems,
    };
  }

  /**
   *  set current total
   *
   * @returns {{type: string}}
   */
  setCurrentTotal(total) {
    return {
      type: OrderConstant.SET_CURRENT_TOTAL,
      total,
    };
  }

  /**
   *  back from edit adjustment component
   *
   * @returns {{type: string}}
   */
  handleBackFromAdjustment() {
    return {
      type: OrderConstant.HANDLE_OPEN_SELECT_ITEM_REFUND,
    };
  }

  /**
   *  update list selected Items
   *
   * @returns {{type: string}}
   */
  handleUpdateListSelectedItems(listUpdated) {
    return {
      type: OrderConstant.UPDATE_LIST_SELECTED_ITEMS,
      listUpdated,
    };
  }

  /**
   *  update list adjustments
   *
   * @returns {{type: string}}
   */
  updateAdjustments(adjustments) {
    return {
      type: OrderConstant.UPDATE_LIST_ADJUSTMENTS,
      adjustments,
    };
  }

  /**
   *  update field isMaxQtyRefund
   *
   * @returns {{type: string}}
   */
  setIsMaxQtyRefund(itemsCanRefund, isMaxQtyRefund) {
    return {
      type: OrderConstant.SET_IS_MAX_QTY_REFUND,
      isMaxQtyRefund,
      itemsCanRefund,
    };
  }

  /**
   *  update field isMaxQtyRefund
   *
   * @returns {{type: string}}
   */
  updateIsMaxQtyRefund(isMaxQtyRefund) {
    return {
      type: OrderConstant.UPDATE_IS_MAX_QTY_REFUND,
      isMaxQtyRefund,
    };
  }

  /**
   *  open complete refund component
   *
   * @returns {{type: string}}
   */
  handleOpenCompleteRefund() {
    return {
      type: OrderConstant.HANDLE_OPEN_COMPLETE_REFUND,
    };
  }

  /**
   *  update refund adjustment total amount
   *
   * @returns {{type: string}}
   */
  handleUpdateRefundAdjustmentAmount(amount) {
    return {
      type: OrderConstant.HANDLE_UPDATE_ADJUSTMENT_REFUND_AMOUNT,
      amount,
    };
  }

  /**
   *  create refund record for order
   *
   * @param {object}
   *
   * @returns {{type: string}}
   */
  createRefundRecord({listSelectedItems, adjustments, listRefundPayment, note, order}) {
    return async (dispatch) => {
      dispatch(this.setIsLoading());
      try {
        const shop = StaffService.getShopUrl();
        const refundLineItems = listSelectedItems.length > 0 ? listSelectedItems.map((item) => {
          if (item.qtyRefund === 0) { return null; }
          const lastIndex = item.id.lastIndexOf('/');
          const itemIdNumeric = item.id.slice(lastIndex + 1);
          const result = {
            quantity: item.qtyRefund,
            line_item_id: itemIdNumeric,
          };
          if (item.isReturnToStock === false) {
            result.restock_type = OrderRefundConstant.RESTOCK_TYPE.NO_RESTOCK;
          }

          if (item.isReturnToStock) {
            result.location_id = LocationService.getCurrentLocationId().slice(LocationService.getCurrentLocationId().lastIndexOf('/') + 1);
            if (item.qtyRefund > item.unfulfilledQuantity) {
              result.restock_type = OrderRefundConstant.RESTOCK_TYPE.RETURN;
            } else {
              result.restock_type = OrderRefundConstant.RESTOCK_TYPE.CANCEL;
            }
          }

          return result;
        }).filter(Boolean) : [];

        const transactions = listRefundPayment.map((refundPaymentItem) => {
          return {
            amount: parseFloat(refundPaymentItem.amount),
            kind: 'refund',
            title: refundPaymentItem.title,
            gateway: refundPaymentItem.code,
            parent_id: refundPaymentItem.parentId.slice(refundPaymentItem.parentId.lastIndexOf('/') + 1),
          };
        });

        const shipping = {
          amount: parseFloat(adjustments.Shipping.amount),
        };

        const session = OrderService.buildInputSessionAddTransaction();

        const response = await OrderService.createRecordRefund(refundLineItems, transactions, shipping, note, {id: order.id, currencyCode: order.totalPriceSet.shopMoney.currencyCode, name: order?.name}, shop, session);

        const createRecordRefundResult = response.data.createRecordRefund.result;

        if (createRecordRefundResult) {
          dispatch(this.createRecordPaymentSuccess(listRefundPayment));
        } else {
          dispatch(this.createRecordPaymentFailed(response.data.createRecordRefund.error));
        }

        // #toDo create reducer to loading and show screen when save success (PM-261)
      } catch (error) {
        dispatch(this.createRecordPaymentFailed(error.message));
      }
    };
  }

  /**
   *  create record payment failed
   *
   * @returns {{type: string}}
   */
  createRecordPaymentFailed(error) {
    return {
      type: OrderConstant.CREATE_REFUND_FAILED,
      error,
    };
  }


  /**
   *  create record payment success
   *
   * @returns {{type: string}}
   */
  createRecordPaymentSuccess(listRefundPayment) {
    return {
      type: OrderConstant.CREATE_REFUND_SUCCESS,
      listRefundPayment,
    };
  }

  /**
   *  set is loading
   *
   * @returns {{type: string}}
   */
  setIsLoading() {
    return {
      type: OrderConstant.SET_IS_LOADING_REFUND,
    };
  }
}

/**
 * @type {OrderRefundActionClass}
 */
const OrderRefundAction = ActionFactory.get(OrderRefundActionClass);
export default OrderRefundAction;
