import AddressConstant from '../../constant/AddressConstant';
import AddressService from '../../../service/customer/AddressService';

const initialState = {
  isShowAddressPopup: false,
  editingAddress: {},
  isLoadingSave: false,
};

/**
 * Reducer to process address
 * @param state
 * @param action
 * @returns {{isShowAddressPopup: boolean}}
 */
const customerAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case AddressConstant.SHOW_ADD_ADDRESS_POPUP:
      return {
        ...state,
        isShowAddressPopup: true,
        editingAddress: {
          country: AddressService.getDefaultCountry(),
        },
      };
    case AddressConstant.SHOW_EDIT_ADDRESS_POPUP:
      return {
        ...state,
        isShowAddressPopup: true,
        editingAddress: {
          ...action.address,
          country: (action.address && action.address.country) ? action.address.country : AddressService.getDefaultCountry(),
        },
      };
    case AddressConstant.HIDE_ADD_ADDRESS_POPUP:
      return {
        ...state,
        isShowAddressPopup: false,
        editingAddress: {},
      };
    case AddressConstant.SAVE_ADDRESS_POPUP:
      return {
        ...state,
        isLoadingSave: true,
      };
    case AddressConstant.SAVE_ADDRESS_POPUP_SUCCESS:
      return {
        ...state,
        editingAddress: {},
        isShowAddressPopup: false,
        isLoadingSave: false,
      };
    case AddressConstant.SAVE_ADDRESS_POPUP_FAIL:
      return {
        ...state,
        isShowAddressPopup: true,
        isLoadingSave: false,
      };
    default:
      return state;
  }
};

export default customerAddressReducer;
