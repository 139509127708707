import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

import FunctionComponentFactory from "../../../../../framework/factory/FunctionComponentFactory";
import i18n from "../../../../../config/i18n";
import OrderService from "../../../../../service/order/OrderService";
import OrderPaymentAction from "../../../../action/order/payment/OrderPaymentAction";
import '../../../../../assets/Payment.css';
import StaffService from "../../../../../service/staff/StaffService";

import CompletePaymentItem from "./CompletePaymentItem.jsx";

function CompletePayment(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {listPaid, isLoading, saveRecordStatusFinish} = useSelector((state) => state.core.order.orderPayment);
  const shop = StaffService.getShopUrl();
  const {order} = useSelector((state) => state.core.order.orderDetail);
  const [isOpenCompletePaymentPopup, setIsOpenCompletePaymentPopup] = useState(false);
  let title;
  let balance = props.grandTotal;
  let totalPaid = 0;
  listPaid.forEach((paid) => {
    balance -= parseFloat(paid.amount);
    totalPaid += parseFloat(paid.amount);
  });

  if (balance >= 0) {
    title = 'Remaining';
  } else {
    title = 'Change';
  }

  useEffect(() => {
    if (!saveRecordStatusFinish) {
      return;
    }
    navigate('/order');
  }, [navigate, saveRecordStatusFinish]);

  const buttonTitle = i18n.t(balance > 0 ? 'Mark as Partial' : 'Complete Payment');
  const modalContent = `${OrderService.formatPrice(totalPaid)} has been received`;

  const listPaidView = listPaid.length > 0
      ? listPaid.map((paid, index) => {
        return (<CompletePaymentItem key={`${paid.selectedPayment.code}-${paid.amount}`} paid={paid} balance={balance} index={index} order={order} />);
      }) : null;

  const addPayment = balance > 0 ? (
    <div
      role="presentation" className="payment-full-amount add-payment" onClick={() => {
        dispatch(OrderPaymentAction.setRemaining(balance));
        dispatch(OrderPaymentAction.handleOpenListPayment(true, true));
      }}
    >
      <div className="info">
        <span className="label">{i18n.t('Add Payment')}</span>
      </div>
      <span className="add-cash" />
    </div>
  ) : null;

  const buttonAcceptClassName = `btn btn-default btn-complete ${isLoading ? 'disabled' : ''}`;

  const saveRecord = () => {
    dispatch(OrderPaymentAction.saveRecordPayment(listPaid, order, shop, balance, totalPaid));
  };
  const handleCompletePayment = () => {
    if (balance > 0) { setIsOpenCompletePaymentPopup(true); } else {
      saveRecord();
    }
  };

  /**
   * Cancel popup
   */
  const cancelPopup = () => {
    setIsOpenCompletePaymentPopup(false);
  };

  return (
    <>
      <div className="block-content" data-scrollbar>
        <ul className="payment-total">
          <li className="total">
            <span className="label">{i18n.t(`${title}`)}</span>
            <span className="value">{OrderService.formatPrice(balance < 0 ? balance * -1 : balance)}</span>
          </li>
        </ul>
        <>
          {listPaidView}
          {addPayment}
        </>
      </div>
      <div className="block-bottom">
        <div className="actions-accept">
          <button
            className={buttonAcceptClassName}
            type="button"
            data-toggle="modal"
            data-target="#popup-completeOrder"
            onClick={() => {
              handleCompletePayment();
            }}
          >
            {buttonTitle}
          </button>
        </div>
      </div>
      <>
        <Modal
          size="sm"
          className="popup-messages"
          show={isOpenCompletePaymentPopup} onHide={() => cancelPopup()}
        >
          <Modal.Body>
            <h3 className="title">{i18n.t('Confirm Partial Payment')}</h3>
            <p>{modalContent}</p>
          </Modal.Body>
          <Modal.Footer className="modal-footer actions-2column">
            <Button className="modal-button-payment" onClick={() => cancelPopup()}>{i18n.t('Cancel')}</Button>
            <Button className="modal-button-payment" onClick={() => saveRecord()}>{i18n.t('Mark as Partial')}</Button>
            {/**/}
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
}

CompletePayment.className = 'CompletePaymentOrder';
CompletePayment.propTypes = {
  grandTotal: PropTypes.number,
};
export default FunctionComponentFactory.get(CompletePayment);
