import CustomerConstant from '../../constant/CustomerConstant';

const initialState = {
  currentCustomer: null,
};

/**
 * Select customer on cart
 * @param state
 * @param action
 * @returns {{currentCustomer: null}|{currentCustomer: (null|Reducer<CombinedState<any>>|*)}}
 */
const customerOnCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CustomerConstant.SELECT_CUSTOMER:
      return {
        ...state,
        currentCustomer: action.customer,
      };
    default:
      return state;
  }
};

export default customerOnCartReducer;
