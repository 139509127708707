import AbstractResourceModel from "../AbstractResourceModel";

export default class ShopResourceModel extends AbstractResourceModel {
  static className = 'ShopResourceModel';

  resourceName = 'Shop';

  /**
   * Get public shop info
   * @param shop
   * @returns {promise}
   */
  getShopPublicInfo(shop = '') {
    return this.getResourceOnline().getShopPublicInfo(shop);
  }
}
