const ShippingConstant = {
  GET_SHIPPING_ONLINE: '[SHIPPING] GET_SHIPPING_ONLINE',

  TYPE_GET_SHIPPING: 'get_shipping',

  GET_LIST_SHIPPING: '[SHIPPING] GET_LIST_SHIPPING',
  GET_LIST_SHIPPING_RESULT: '[SHIPPING] GET_LIST_SHIPPING_RESULT',
  POPUP_TYPE_SHIPPING: '[SHIPPING] POPUP',
  POPUP_TYPE_SHIPPING_ADDRESS: '[SHIPPING] POPUP_ADDRESS',
  GET_SHIPPING_METHODS: '[SHIPPING] GET_SHIPPING_METHODS',
  SAVE_SHIPPING: "[SHIPPING] SAVE_SHIPPING",
  SAVE_SHIPPING_AFTER: "[SHIPPING] SAVE_SHIPPING_AFTER",
  STORE_PICKUP_SHIPPING_METHOD_CODE: "webpos_shipping_storepickup",
  STORE_PICKUP_SHIPPING_METHOD_DESCRIPTION: "Pickup-at-store",

  SHOW_SHIPPING_POPUP: '[SHIPPING] SHOW_SHIPPING_POPUP',
  HIDE_SHIPPING_POPUP: '[SHIPPING] HIDE_SHIPPING_POPUP',
  GET_ADDRESS_LISTING: '[SHIPPING] GET_ADDRESS_LISTING',
  GET_ADDRESS_LISTING_SUCCESS: '[SHIPPING] GET_ADDRESS_LISTING_SUCCESS',
  GET_ADDRESS_LISTING_ERROR: '[SHIPPING] GET_ADDRESS_LISTING_ERROR',

  SELECT_ADDRESS: '[SHIPPING] SELECT_ADDRESS',
  SHOW_SHIPPING_ADDRESS_POPUP: '[SHIPPING] SHOW_SHIPPING_ADDRESS_POPUP',
  HIDE_SHIPPING_ADDRESS_POPUP: '[SHIPPING] HIDE_SHIPPING_ADDRESS_POPUP',

  SAVE_NEW_ADDRESS_AS_GUEST: '[SHIPPING] SAVE_NEW_ADDRESS_AS_GUEST',
  SAVE_NEW_ADDRESS_WITH_CUSTOMER: '[SHIPPING] SAVE_NEW_ADDRESS_WITH_CUSTOMER',
  SAVE_NEW_ADDRESS_WITH_CUSTOMER_SUCCESS: '[SHIPPING] SAVE_NEW_ADDRESS_WITH_CUSTOMER_SUCCESS',
  SAVE_NEW_ADDRESS_WITH_CUSTOMER_FAIL: '[SHIPPING] SAVE_NEW_ADDRESS_WITH_CUSTOMER_FAIL',

  SET_SHIPPING_METHOD: '[SHIPPING] SET_SHIPPING_METHOD',
  SET_SHIPPING_METHOD_SUCCESS: '[SHIPPING] SET_SHIPPING_METHOD_SUCCESS',

  SHOW_LOADING_SHIPPING_METHOD: '[SHIPPING] SHOW_LOADING_SHIPPING_METHOD',
  HIDE_LOADING_SHIPPING_METHOD: '[SHIPPING] HIDE_LOADING_SHIPPING_METHOD',
  SET_SELECTED_SHIPPING_METHOD: '[SHIPPING] SET_SELECTED_SHIPPING_METHOD',
};
export default ShippingConstant;
