

import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';
import NumPad from '../../../lib/react-numpad';
import NumberService from '../../../../../service/NumberService';

function CustomSaleNumberComponent(props) {

  function changeInputValue(value) {
    if (props.inputFieldOnChange) {
      props.inputFieldOnChange(props.Code, value);
    }
  }

  /**
   * Render template
   *
   * @return {*}
   */
  function template() {
    const {Code, Label, OneRow} = props;
    const id = `custom-sale-${Code}`;
    const colClassName = OneRow ? "col-sm-12" : "col-sm-6";
    return (
      <div className={colClassName}>
        <Form.Label htmlFor={id}>{Label}</Form.Label>
        <NumPad.CustomNumber
          key={id}
          onChange={changeInputValue}
          position="centerLeft"
          isDecimal
          decimalSeparator={NumberService.getDecimalSeparator()}
          min={0}
          isShowAction
          arrow="left"
          value={props.value}
        >
          <span className="form-control">
            {props.value}
          </span>
        </NumPad.CustomNumber>
      </div>
    );
  }
  return template();

}

CustomSaleNumberComponent.className = 'CustomSaleNumberComponent';
CustomSaleNumberComponent.propTypes = {
  Code: PropTypes.string,
  Label: PropTypes.string,
  DefaultValue: PropTypes.string,
  MaxLength: PropTypes.number,
  OneRow: PropTypes.bool,
  inputFieldOnChange: PropTypes.func,
  value: PropTypes.string,
};

export default React.memo(FunctionComponentFactory.get(CustomSaleNumberComponent));
