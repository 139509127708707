import {cloneDeep} from "lodash";
import {t} from "i18next";

import ServiceFactory from "../../../framework/factory/ServiceFactory";
import ProductStockService from '../../product/stock/ProductStockService';
import NumberService from '../../NumberService';
import CurrencyService from '../../CurrencyService';
import {ProductPlaceHolder} from '../../../assets/images';
import QuoteConstant from '../../../view/constant/checkout/QuoteConstant';
import CustomDiscountConstant from "../../../view/constant/checkout/quote/CustomDiscountConstant";
import Config from '../../../config/Config';
import CurrencyConstant from "../../../view/constant/CurrencyConstant";
import DiscountConstant from "../../../view/constant/checkout/quote/DiscountConstant";
import i18n from "../../../config/i18n";

import AbstractQuoteService from "./AbstractService";

export class QuoteItemServiceClass extends AbstractQuoteService {
  static className = 'QuoteItemServiceClass';


  /**
   * Create quote item
   *
   * @param data
   * @param qty
   * @param quote
   * @returns {{}}
   */
  createItem(data, qty, quote) {
    const {product, variant} = data;
    const weight = variant
        ? {
          unit: variant.weightUnit,
          value: variant.weight,
        }
        : null;
    const tmpId = `tmp-item-${Math.round(new Date().getTime() / 100) + ((quote && quote.lineItems) ? quote.lineItems.length : 0)}`;
    return {
      id: tmpId,
      product: {...product},
      variant: {...variant},
      quantity: qty,
      variantId: variant.id,
      custom: false,
      appliedDiscount: null,
      discountedTotal: 0,
      discountedUnitPrice: 0,
      image: variant.image || product.featuredImage,
      isGiftCard: false,
      name: product.title,
      originalTotal: 0,
      originalUnitPrice: 0,
      requiresShipping: ProductStockService.isRequiresShipping(variant),
      sku: variant.sku,
      taxable: variant.taxable,
      title: product.title,
      totalDiscount: 0,
      variantTitle: variant.title,
      vendor: product.vendor,
      customAttributes: [
        {
          key: QuoteConstant.TMP_ITEM_ID_CUSTOM_ATTRIBUTE_KEY,
          value: tmpId,
        },
      ],
      weight,
    };
  }

  /**
   * Get Item Id
   * @param item
   * @returns {*}
   */
  getItemId(item) {
    return item.id;
  }

  /**
   * Get total item quantity (include parent item relation)
   *
   * @return  int|float
   */
  getTotalQty(item) {
    return item.quantity;
  }

  /**
   * Get Original Unit Price
   * @param item
   * @returns {*}
   */
  getOriginalUnitPrice(item) {
    if (!item.originalUnitPrice) {
      item.originalUnitPrice = item.variant.price;
    }
    return item.originalUnitPrice;
  }

  /**
   * Calculate Original Total
   * @param quote
   * @param item
   */
  calcOriginalTotal(quote, item) {
    const quantity = this.getTotalQty(item);
    const originalTotal = NumberService.multipleNumber(this.getOriginalUnitPrice(item), quantity);
    item.originalTotal = CurrencyService.roundToFloat(originalTotal);
  }

  /**
   * Get Discounted Unit Price
   * @param item
   * @returns {*}
   */
  getDiscountedUnitPrice(item) {
    if (item.discountedUnitPrice) {
      return item.discountedUnitPrice;
    }
    if (item.appliedDiscount) {
      const discountType = item.appliedDiscount.valueType;
      if (discountType === CustomDiscountConstant.SHOPIFY_DISCOUNT_TYPE_FIXED) {
        item.discountedUnitPrice = item.originalUnitPrice - item.appliedDiscount.value;
      } else {
        item.discountedUnitPrice = item.originalUnitPrice - (item.appliedDiscount.value * item.originalUnitPrice / 100);
      }
    } else {
      item.discountedUnitPrice = item.originalUnitPrice;
    }
    return item.discountedUnitPrice;
  }

  /**
   * Calculate Discounted Total
   * @param quote
   * @param item
   */
  calcDiscountedTotal(quote, item) {
    const discountedTotal = this.calcDiscountedPriceItem(item);
    item.discountedTotal = discountedTotal;
  }

  /**
   * Get Item Options Label As Array
   * @param quote
   * @param item
   * @returns {*[]}
   */
  getItemOptionsLabelAsArray(quote, item) {
    const {product, variant} = item;
    const result = [];
    if (product.hasOnlyDefaultVariant) {
      return [];
    }
    const selectedOptions = variant.selectedOptions || [];
    selectedOptions.forEach((option) => {
      result.push(`${option.name}: ${option.value}`);
    });
    return result;
  }

  /**
   * Get Item Image
   * @param item
   * @returns {*|string}
   */
  getItemImage(item) {
    if (item.image && item.image.url) {
      return item.image.url;
    }
    return ProductPlaceHolder;
  }

  /**
   * Get Item Name
   * @param item
   * @returns {*}
   */
  getItemName(item) {
    return item.name;
  }

  /**
   * Get Item Custom Attribute By Key
   * @param item
   * @param key
   * @returns {*}
   */
  getItemCustomAttributeByKey(item, key) {
    if (item.customAttributes) {
      const foundAttribute = item.customAttributes.find((attribute) => attribute.key === key);
      return foundAttribute;
    }
    return null;
  }

  /**
   * Get Item Note
   * @param item
   * @returns {*}
   */
  getItemNote(item) {
    const noteAttribute = this.getItemCustomAttributeByKey(item, t(QuoteConstant.NOTE_ITEM_CUSTOM_ATTRIBUTE_KEY));
    return noteAttribute ? noteAttribute.value : null;
  }

  /**
   * Get Display Price
   * @param quote
   * @param item
   * @returns {string}
   */
  getDisplayPrice(quote, item) {
    if (item.appliedDiscount) {
      return this.getDiscountedPriceItem(item);
    }
    let displayPrice = 0;
    if (item.originalTotal) {
      if (typeof item.originalTotal === 'object') {
        displayPrice = item.originalTotal.amount;
      } else {
        displayPrice = item.originalTotal;
      }
    }
    return CurrencyService.format(displayPrice);
  }

  /**
   * Get Display Original Price
   * @param quote
   * @param item
   * @returns {string}
   */
  getDisplayOriginalPrice(quote, item) {
    let displayPrice = 0;
    if (item.originalTotal) {
      if (typeof item.originalTotal === 'object') {
        displayPrice = item.originalTotal.amount;
      } else {
        displayPrice = item.originalTotal;
      }
    }
    return CurrencyService.format(displayPrice);
  }

  /**
   * Maximum discount percent
   * @returns {number}
   */
  getMaxDiscountPercent() {
    const maxDiscountPercent = Config.config[CustomDiscountConstant.MAX_DISCOUNT_CONFIG_PATH];
    return (maxDiscountPercent) ? maxDiscountPercent : 0;
  }

  /**
   * Get Discount Item Data
   * @param item
   * @param discountType
   * @param discountAmount
   * @returns {{percent: number, amount: number}}
   */
  getDiscountItemData(item, discountType, discountAmount) {
    const maxDiscountPercent = this.getMaxDiscountPercent();
    let discountPercent = 0;
    let discountValue = parseFloat(discountAmount);
    if (discountType === CustomDiscountConstant.DISCOUNT_TYPE_PERCENT) {
      discountPercent = discountValue;
    } else {
      discountPercent = (discountValue / item.originalUnitPrice) * 100;
      discountPercent = parseFloat(discountPercent.toFixed(2));
    }
    // Make sure discount is not greater than max percent
    if (discountPercent > maxDiscountPercent) {
      discountPercent = maxDiscountPercent;
      if (discountType === CustomDiscountConstant.DISCOUNT_TYPE_PERCENT) {
        discountValue = discountPercent;
      } else {
        discountValue = discountPercent * item.originalUnitPrice / 100;
        discountValue = parseFloat(discountValue.toFixed(2));
      }
    }
    return {
      percent: discountPercent,
      amount: discountValue,
    };
  }

  /**
   * Apply Custom Discount Item
   * @param quote
   * @param item
   * @param discountType
   * @param discountAmount
   * @param reason
   * @returns {quote: *}
   */
  applyCustomDiscountItem(quote, item, discountType, discountAmount, reason) {
    const discountData = this.getDiscountItemData(item, discountType, discountAmount);
    const quoteData = cloneDeep(quote);
    const lineItemToDiscount = quoteData?.lineItems.find((lineItem) => lineItem.id === item.id);
    const valueType = discountType === CustomDiscountConstant.DISCOUNT_TYPE_PERCENT ? CustomDiscountConstant.SHOPIFY_DISCOUNT_TYPE_PERCENT : CustomDiscountConstant.SHOPIFY_DISCOUNT_TYPE_FIXED;
    const value = discountData.amount;
    const QuoteService = require("../QuoteService").default;
    if (value) {
      lineItemToDiscount.appliedDiscount = {
        description: DiscountConstant.POS_CUSTOM_DISCOUNT_LINE_ITEM_CODE,
        title: reason || i18n.t("POS Custom Discount Line Item"),
        value,
        valueType,
      };
    } else {
      lineItemToDiscount.appliedDiscount = null;
    }
    return {
      quote: QuoteService.collectTotals(quoteData),
    };
  }

  /**
   * Get Original Total Item
   * @param item
   * @returns {number}
   */
  getOriginalTotalItem(item) {
    let displayPrice = 0;
    if (item.originalTotal) {
      if (typeof item.originalTotal === 'object') {
        displayPrice = item.originalTotal.amount;
      } else {
        displayPrice = item.originalTotal;
      }
    }
    return displayPrice;
  }

  /**
   * Get Price Item By Type
   * @param item
   * @param type
   * @returns {number}
   */
  getPriceItemByType(item, type) {
    let displayPrice = 0;
    if (item[type]) {
      if (typeof item[type] === 'object') {
        displayPrice = item[type].amount;
      } else {
        displayPrice = item[type];
      }
    }
    return displayPrice;
  }

  /**
   * Get Discount Item Data
   * @param item
   * @returns {number}
   */
  calcDiscountedPriceItem(item) {
    if (!item.appliedDiscount) {
      return item.originalUnitPrice * item.quantity;
    }
    const format = CurrencyService.getCurrencyFormat();
    const precision = format && format.includes(CurrencyConstant.AMOUNT_NO_DECIMALS) ? 0 : CurrencyConstant.DEFAULT_STORE_PRECISION;
    const discountType = item.appliedDiscount.valueType === CustomDiscountConstant.SHOPIFY_DISCOUNT_TYPE_PERCENT ? CustomDiscountConstant.DISCOUNT_TYPE_PERCENT : CustomDiscountConstant.DISCOUNT_TYPE_FIXED;
    const discountData = this.getDiscountItemData(item, discountType, item.appliedDiscount.value);
    let decreaseTotalAmount = 0;
    const originalTotalItem = this.getOriginalTotalItem(item);
    if (discountType === CustomDiscountConstant.DISCOUNT_TYPE_PERCENT) {
      decreaseTotalAmount = (discountData.amount * originalTotalItem / 100);
      const discountedTotal = originalTotalItem - decreaseTotalAmount;
      return CurrencyService.ceilRoundToFloat(discountedTotal, precision);
    } else {
      decreaseTotalAmount = CurrencyService.roundToFloat(discountData.amount, precision) * item.quantity;
      const discountedTotal = originalTotalItem - decreaseTotalAmount;
      return discountedTotal;
    }
  }

  /**
   * Get Discounted Price Item To Display
   * @param item
   * @returns {*}
   */
  getDiscountedPriceItem(item) {
    if (!item.appliedDiscount) { return null; }
    const result = this.calcDiscountedPriceItem(item);
    return CurrencyService.format(result);
  }

  /**
   * Apply Custom Price Item
   * @param quote
   * @param item
   * @param customPrice
   * @param reason
   * @returns {quote: *}
   */
  applyCustomPriceItem(quote, item, customPrice, reason) {
    const quoteData = cloneDeep(quote);
    const lineItemToCustomPrice = quoteData?.lineItems.find((lineItem) => lineItem.id === item.id);
    const value = item.originalUnitPrice - customPrice > 0 ? item.originalUnitPrice - customPrice : 0;
    const QuoteService = require("../QuoteService").default;
    if (value) {
      lineItemToCustomPrice.appliedDiscount = {
        description: DiscountConstant.POS_CUSTOM_DISCOUNT_LINE_ITEM_CODE,
        title: reason || i18n.t("POS Custom Discount Line Item"),
        value: item.originalUnitPrice - customPrice > 0 ? item.originalUnitPrice - customPrice : 0,
        valueType: CustomDiscountConstant.SHOPIFY_DISCOUNT_TYPE_FIXED,
      };
    } else {
      lineItemToCustomPrice.appliedDiscount = null;
    }
    return {
      quote: QuoteService.collectTotals(quoteData),
    };
  }

  /**
   * Create Custom Sale Item
   * @param customProduct
   * @returns {customProductData: *}
   */
  createCustomSaleItem(customProduct) {
    const customProductData = {
      product: {
        collectionIds: [],
        handle: QuoteConstant.CUSTOM_SALE_HANDLE,
        hasOnlyDefaultVariant: true,
        hasOutOfStockVariants: false,
        isGiftCard: false,
        status: "ACTIVE",
        title: customProduct.name || t("Custom Product"),
        totalVariants: 1,
        tracksInventory: false,
      },
      variant: {
        availableForSale: true,
        displayName: QuoteConstant.DEFAULT_CUSTOM_SALE_TITLE,
        inventoryItem: {
          tracked: false,
        },
        price: customProduct.price,
        taxable: Boolean(customProduct.taxable_item),
        title: QuoteConstant.DEFAULT_CUSTOM_SALE_TITLE,
        weight: 0,
        weightUnit: "KILOGRAMS",
        sku: QuoteConstant.CUSTOM_SALE_SKU,
      },
      quantity: Number(customProduct.quantity),
    };
    return customProductData;
  }

  /**
   * Check if line item is custom sale
   * @param item
   * @returns {boolean}
   */
  isCustomSaleLineItem(item) {
    return item.sku && item.sku === QuoteConstant.CUSTOM_SALE_SKU && !item.product && !item.variant;
  }
}

/** @type QuoteItemServiceClass */
const QuoteItemService = ServiceFactory.get(QuoteItemServiceClass);

export default QuoteItemService;
