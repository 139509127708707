const CustomDiscountConstant = {
  DISCOUNT_TYPE_FIXED: "$",
  DISCOUNT_TYPE_PERCENT: "%",
  DISCOUNT_TYPE_COUPON: 'coupon',
  MAX_DISCOUNT_CONFIG_PATH: 'maxDiscountPercent',

  SHOPIFY_DISCOUNT_TYPE_PERCENT: 'PERCENTAGE',
  SHOPIFY_DISCOUNT_TYPE_FIXED: 'FIXED_AMOUNT',

  CUSTOM_DISCOUNT_TYPE: 'CUSTOM_DISCOUNT_TYPE',
  CUSTOM_PRICE_TYPE: 'CUSTOM_PRICE_TYPE',

  EDIT_PRICE_POPUP_WIDTH: "272",
};
export default CustomDiscountConstant;
