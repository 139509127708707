import DataResourceFactory from "../../../../../framework/factory/DataResourceFactory";
import GraphqlStaff from '../../../../../data/omc/graphql/GraphqlStaff';

export class GraphqlStaffShopifyClass extends GraphqlStaff {
  static className = 'GraphqlStaffShopifyClass';

}

/**
 * @type {GraphqlStaffShopifyClass}
 */
export default DataResourceFactory.get(GraphqlStaffShopifyClass);
