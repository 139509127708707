import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import FunctionComponentFactory
    from '../../../framework/factory/FunctionComponentFactory';
import MenuAction from '../../action/MenuAction';
import '../../../assets/Menu.css';

function OpenMenuButton() {
  const dispatch = useDispatch();
  const {isOpen} = useSelector((state) => state.core.menu);

  const openMenu = () => {
    dispatch(MenuAction.open());
  };

  const className = isOpen ? "toggle-menu active" : "toggle-menu";

  return (
    <div className="fixed-wrapper-header">
      <div className="header-left">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          onClick={() => openMenu()}
          className={className}
        />
      </div>
    </div>
  );
}

OpenMenuButton.className = 'OpenMenuButton';
OpenMenuButton.propTypes = {};

export default FunctionComponentFactory.get(OpenMenuButton);
