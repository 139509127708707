import DataResourceFactory from "../../framework/factory/DataResourceFactory";
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';

import OmcAbstract from "./OmcAbstract";
import GraphqlCollection from './graphql/GraphqlCollection';
// import GraphqlProduct from './graphql/GraphqlProduct';

export class OmcCollection extends OmcAbstract {
  static className = 'OmcCollection';

  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlCollection,
  };

  /**
   * Get Collections
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getCollections(input) {
    return this.getApiResource().getCollections(input);
  }

}

/**
 * @type {OmcCollection}
 */
export default DataResourceFactory.get(OmcCollection);
