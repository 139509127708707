import SessionConstant from "../../constant/SessionConstant";

const initialState = {
  isLoadingSessionDetailPage: true,
};

const sessionDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SessionConstant.SET_LOADING_DETAIL_PAGE:
      return {
        ...state,
        isLoadingSessionDetailPage: action.isLoadingSessionDetailPage,
      };
    default:
      return state;
  }
};

export default sessionDetailReducer;
