import DataResourceFactory from "../../framework/factory/DataResourceFactory";
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';

import OmcAbstract from "./OmcAbstract";
import GraphqlSubscription from "./graphql/ApolloSubscription";

export class OmcSubscription extends OmcAbstract {
  static className = 'OmcSubscription';

  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlSubscription,
  };

  /**
   * validate current plans with list permission requires
   *
   * @param listPermissionRequire
   * @param shop
   * @return: promise
   */
  validatePermissionPlan(listPermissionRequire, shop) {
    return this.getApiResource().validatePermissionPlan(listPermissionRequire, shop);
  }
}

/**
 * @type {OmcSubscription}
 */
export default DataResourceFactory.get(OmcSubscription);
