import LoadingConstant from '../constant/LoadingConstant';
import {fire} from "../../event-bus";
import ConfigConstant from '../constant/ConfigConstant';
import DenominationConstant from '../constant/DenominationConstant';
import StaffConstant from '../constant/StaffConstant';
import CountryConstant from '../constant/CountryConstant';

const initialState = {
  finishedList: [],
  count: 0,
  total: 2,
  dataList: [
    ConfigConstant.TYPE_GET_CONFIG,
    DenominationConstant.TYPE_GET_DENOMINATIONS,
    StaffConstant.TYPE_GET_PERMISSIONS,
    CountryConstant.TYPE_GET_ALL_COUNTRIES,
  ],
};
// event to modify initial state
fire('reducer_loading_define_initial_state_after', {initialState});
initialState.total = initialState.dataList.length;

/**
 * receive action from Loading Action
 *
 * @param state = initialState
 * @param action
 * @returns {*}
 */
const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LoadingConstant.UPDATE_FINISHED_LIST: {
      const finishedList = [...state.finishedList];
      if (finishedList.indexOf(action.dataType) < 0) {
        finishedList.push(action.dataType);
      }
      const count = finishedList.length;
      return {...state, finishedList, count};
    }
    case LoadingConstant.RESET_STATE: {
      return {
        ...state,
        count: 0,
        finishedList: [],
      };
    }
    default: {
      return state;
    }
  }
};

export default loadingReducer;
