import React from 'react';
import PropTypes from 'prop-types';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import LocationService from '../../../../service/location/LocationService';

function StockLocation(props) {
  const currentLocationId = LocationService.getCurrentLocationId();
  const {stockLocation} = props;
  const classNameLocation = stockLocation.id === currentLocationId ? "active" : "";
  const classNameQty = stockLocation.available > 0 ? "qty" : "qty not-available";
  return (
    <>
      <li className={classNameLocation}>
        <div className="info">
          <div className="name">{stockLocation.name}</div>
          <div className="detail">
            {props.stockLocation.address}
          </div>
          <div className={classNameQty}>
            {stockLocation.available}
          </div>
        </div>
      </li>
    </>
  );
}

StockLocation.className = 'StockLocation';
StockLocation.propTypes = {
  stockLocation: PropTypes.object,
};

export default FunctionComponentFactory.get(StockLocation);
