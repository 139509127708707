import React from 'react';
import {Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import FunctionComponentFactory from '../../../../../../framework/factory/FunctionComponentFactory';
import OrderConstant from '../../../../../constant/OrderConstant';

const CancelCreateShipment = ({handleAction}) => {
  const {t} = useTranslation();

  const handleClickBtnNo = () => {
    handleAction(OrderConstant.NO);
  };

  const handleClickBtnYes = () => {
    handleAction(OrderConstant.YES);
  };

  return (
    <Modal
      size="sm"
      className="popup-messages"
      show
    >
      <Modal.Body>
        <h3 className="title">{t('Cancel Create Shipment') }</h3>
        <p> { t('Are you sure want to cancel this step?') }</p>
      </Modal.Body>
      <Modal.Footer className="logout-actions">
        <button type="button" onClick={handleClickBtnNo} >
          {t('No')}
        </button>
        <button type="button" onClick={handleClickBtnYes}>
          {t('Yes')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};


CancelCreateShipment.propTypes = {
  handleAction: PropTypes.func.isRequired,
};

CancelCreateShipment.className = "CancelCreateShipmentComponent";
export default FunctionComponentFactory.get(CancelCreateShipment);
