import CheckoutConstant from "../../constant/checkout/CheckoutConstant";

const initialState = {
  isOpenConflictProductAutoDiscountPopup: false,
  isOpenConflictProductCouponDiscountPopup: false,
  appliedCustomDiscountLineItems: null,
  isOpenConflictCustomOrderDiscount: false,
};

const conflictDiscountReducer = (state = initialState, action) => {
  switch (action.type) {
    case CheckoutConstant.OPEN_CONFLICT_PRODUCT_AUTO_DISCOUNT_POPUP:
      return {
        ...state,
        isOpenConflictProductAutoDiscountPopup: true,
        appliedCustomDiscountLineItems: action.appliedCustomDiscountLineItems,
      };
    case CheckoutConstant.CLOSE_CONFLICT_PRODUCT_AUTO_DISCOUNT_POPUP:
      return {
        ...state,
        isOpenConflictProductAutoDiscountPopup: false,
        appliedCustomDiscountLineItems: null,
      };
    case CheckoutConstant.OPEN_CONFLICT_PRODUCT_COUPON_DISCOUNT_POPUP:
      return {
        ...state,
        isOpenConflictProductCouponDiscountPopup: true,
        appliedCustomDiscountLineItems: action.appliedCustomDiscountLineItems,
      };
    case CheckoutConstant.CLOSE_CONFLICT_PRODUCT_COUPON_DISCOUNT_POPUP:
      return {
        ...state,
        isOpenConflictProductCouponDiscountPopup: false,
        appliedCustomDiscountLineItems: null,
      };
    case CheckoutConstant.OPEN_CONFLICT_CUSTOM_ORDER_DISCOUNT_AND_COUPON_POPUP:
      return {
        ...state,
        isOpenConflictCustomOrderDiscount: true,
      };
    case CheckoutConstant.CLOSE_CONFLICT_CUSTOM_ORDER_DISCOUNT_AND_COUPON_POPUP:
      return {
        ...state,
        isOpenConflictCustomOrderDiscount: false,
      };
    default:
      return state;
  }
};

export default conflictDiscountReducer;
