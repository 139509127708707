import $ from 'jquery';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SmoothScrollbar from 'smooth-scrollbar';
import {Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import '../../../../assets/Shipping.css';
import {toast} from "react-toastify";
import _ from 'lodash';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import ShippingAction from '../../../action/ShippingAction';
import QuoteAction from '../../../action/checkout/QuoteAction';

import ShippingAddressListing from './ShippingAddressListing.jsx';
import ShippingAddressPopup from './ShippingAddressPopup.jsx';
import ShippingMethodListing from './ShippingMethodListing.jsx';

function ShippingPopup() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {isShowShippingPopup, addresses, defaultAddress, isLoadingShippingPopup, selectedShippingMethod, selectedAddress} = useSelector((state) => state.core.shipping.shippingPopup);
  const {isShowShippingAddressPopup} = useSelector((state) => { return state.core.shipping.shippingAddressPopup; });
  const {quote} = useSelector((state) => state.core.checkout);

  const cancelPopup = () => {
    dispatch(ShippingAction.hideShippingPopup());
  };

  const saveShipping = () => {
    if (!selectedShippingMethod.handle || !selectedAddress) {
      toast.error(
        t('Please select address and shipping method to proceed!'),
        {
          className: 'wrapper-messages messages-warning',
          autoClose: 2000,
        },
      );
    } else {
      dispatch(ShippingAction.saveShipping());
    }
  };

  const handleRemoveShipping = () => {
    const newQuote = _.clone(quote);
    newQuote.shippingLine = {};
    newQuote.shipping_method = null;

    dispatch(QuoteAction.checkoutQuote(newQuote));
    dispatch(ShippingAction.hideShippingPopup());

  };

  const showPopUpAddress = () => {
    dispatch(ShippingAction.showShippingAddressPopup());
  };

  /**
   * Set height popup
   * @param element
   */
  const heightPopup = (element) => {
    if (!element) {
      return;
    }
    const height = $(window).height();
    $(element).css('height', `${height}px`);
  };

  const setPopupShippingElement = (element) => {
    if (!element) {
      return;
    }
    SmoothScrollbar.init(element);
    heightPopup('.popup-shippingadd .modal-dialog');
  };

  const getButtonRemoveShipping = () => {
    if (!isLoadingShippingPopup && quote.shippingLine && !quote.shippingLine.custom && quote.shippingLine.shippingRateHandle) {
      return (
        <div className="actions-remove-shipping">
          <button className="btn btn-default" type="button" onClick={handleRemoveShipping}>
            {t('Remove Shipping')}
          </button>
        </div>
      );
    }

    return null;
  };

  const ShippingAddressPopupComponent = isShowShippingAddressPopup ? <ShippingAddressPopup /> : null;

  const shippingPopupDetailContent = isLoadingShippingPopup ? <div className="loader-product" />
    : (
      <div data-scrollbar ref={setPopupShippingElement} className="modal-body">

        <div className="shipping-address">
          <div className="block-title" onClick={() => showPopUpAddress()} aria-hidden="true">
            {t('Shipping Address')}
          </div>
          <div className="address-content">
            <div className="row">
              <ShippingAddressListing addresses={addresses} defaultAddress={defaultAddress} />
            </div>
          </div>
        </div>
        <div className="shipping-method">
          <div className="block-title">{t('Shipping Method')}</div>
          <ShippingMethodListing />
        </div>
      </div>
    );
  return (
    <>
      <Modal
        size="lg"
        className="popup-edit-customer popup-shippingadd"
        dialogClassName="popup-create-customer in"
        show={isShowShippingPopup}
      >
        <div className="modal-header">
          <button
            type="button"
            className="cancel"
            data-dismiss="modal"
            aria-label={t('Close')}
            onClick={() => cancelPopup()}
          >
            {t('Cancel')}
          </button>
          <h4 className="modal-title">{t('Shipping')}</h4>
          <button
            type="button"
            className="save"
            onClick={() => saveShipping()}
          >{t('Save')}
          </button>
        </div>
        <div data-scrollbar ref={setPopupShippingElement} className="modal-body">
          {shippingPopupDetailContent}
        </div>
        {getButtonRemoveShipping()}
      </Modal>
      {ShippingAddressPopupComponent}
    </>
  );
}

ShippingPopup.className = 'ShippingPopup';
ShippingPopup.propTypes = {

};

export default React.memo(FunctionComponentFactory.get(ShippingPopup));
