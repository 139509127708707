import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {toast} from 'react-toastify';
import {useNavigate} from "react-router-dom";

import FunctionComponentFactory from "../../../../framework/factory/FunctionComponentFactory";
import StaffService from "../../../../service/staff/StaffService";
import SettingAction from "../../../action/SettingAction";

function AccountInformation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isLoading, changePasswordStatus} = useSelector((state) => state.core.setting);
  const {t} = useTranslation();
  const connection = navigator.onLine;
  const [activeButton, setActiveButton] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const staffName = StaffService.getStaffName();
  const staffEmail = StaffService.getStaffEmail();
  const staffUserName = StaffService.getStaffUsername();
  const shop = StaffService.getShopUrl();
  useEffect(() => {
    if (newPassword.length > 0 && confirmPassword.length > 0 && currentPassword.length > 0 && !isLoading) {
      setActiveButton(true);
    } else {
      setActiveButton(false);
    }
  }, [newPassword, confirmPassword, currentPassword, isLoading]);

  useEffect(() => {
    if (!changePasswordStatus) {
      return;
    }

    toast.success(t('Your password has been changed successfully.'));
    dispatch(SettingAction.reset());
    navigate('/checkout');
  }, [changePasswordStatus, dispatch, navigate, t]);
  const handleChangePassword = () => {
    if (!connection) {
      return;
    }
    dispatch(SettingAction.changePassword(staffUserName, currentPassword, newPassword, confirmPassword, shop));
    setNewPassword('');
    setConfirmPassword('');
    setCurrentPassword('');
    setActiveButton(false);
  };

  return (
    <>
      <div className="settings-right">
        <div className="block-title">
          <strong className="title" />
        </div>
        <div className="block-content">
          <ul className="list-lv1">
            <li>
              <span className="title">
                {t('Name')}
              </span>
              <span className="value">{staffName}</span>
            </li>
            <li>
              <span className="title">
                {t('Email')}
              </span>
              <span className="value">{staffEmail}</span>
            </li>
          </ul>
          <Form className="wrapper-form-change-pass" onSubmit={(event) => event.preventDefault()}>
            <Form.Group className="row-form-change-pass">
              <Form.Label><span>{t('New Password')}</span></Form.Label>
              <Form.Control
                name="newPassword"
                maxLength={255}
                type="password" value={newPassword}
                onChange={(event) => {
                  setNewPassword(event.target.value);
                }}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="row-form-change-pass">
              <Form.Label><span>{t('Password Confirmation')}</span></Form.Label>
              <Form.Control
                name="confirmation"
                maxLength={255}
                type="password" value={confirmPassword}
                onChange={(event) => {
                  setConfirmPassword(event.target.value);
                }}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="row-form-change-pass">
              <Form.Label><span>{t('Current Password')}</span></Form.Label>
              <Form.Control
                name="currentPassword"
                maxLength={255}
                type="password" value={currentPassword}
                onChange={(event) => {
                  setCurrentPassword(event.target.value);
                }}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="button-change-pass">
              <Button
                variant="default"
                type="submit"
                className={`btn btn-default${activeButton ? '' : ' disabled'}`}
                disabled={!activeButton}
                onClick={() => handleChangePassword()}
              >
                <span>{t(`${isLoading ? 'Please wait...' : 'Change Password'}`)}</span>
              </Button>
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  );
}

AccountInformation.className = 'AccountInformation';

export default FunctionComponentFactory.get(AccountInformation);
