import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form} from 'react-bootstrap';
import {useDispatch} from 'react-redux';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import DeviceService from '../../../../service/DeviceService';
import SearchConstant from '../../../constant/SearchConstant';
import CustomerAction from '../../../action/CustomerAction';

function CustomerSearch(props) {
  const dispatch = useDispatch();
  const {
    isSearching, clickSearchBox, blurSearchBox,
    changeSearchKey, cancelSearching, searchKey, searchBoxRef,
  } = props;

  const [searchBoxValue, setSearchBoxValue] = useState(searchKey);
  const isOffline = false;

  useEffect(() => {
    const newSearchBoxValue = isSearching ? searchKey : '';
    setSearchBoxValue(newSearchBoxValue);
  }, [isSearching, searchKey]);

  const handleOnChange = (event) => {
    setSearchBoxValue(event.target.value);
  };

  const clearSearchBox = () => {
    searchBoxRef.current.focus();
    setSearchBoxValue('');
    changeSearchKey('');
    dispatch(CustomerAction.resetCustomerList());
    cancelSearching();
  };


  const handleOnKeyUp = (event) => {
    if (event.key === SearchConstant.ENTER_KEY && searchKey !== searchBoxValue) {
      changeSearchKey(searchBoxValue);
    }
  };

  const handleBlurSearchBox = () => {
    if (searchBoxValue) {
      return;
    }
    if (searchKey) {
      cancelSearching();
    } else {
      blurSearchBox();
    }
  };

  const RemoveSearchKeyComponent = searchBoxValue ? <Button variant="remove" onClick={clearSearchBox} /> : null;

  return (
    <>
      <div className={`search-customer${DeviceService.isMobile() ? ' mobile' : ''}${isOffline ? '' : ' online'}`}>
        <div className="box-search">
          <Button variant="search" />
          <Form.Control
            className="input-search form-control"
            maxLength={255}
            autoComplete="false"
            value={searchBoxValue}
            ref={searchBoxRef}
            onClick={clickSearchBox}
            onBlur={handleBlurSearchBox}
            onKeyUp={handleOnKeyUp}
            onChange={handleOnChange}
            type="text"
          />
          {RemoveSearchKeyComponent}
        </div>
      </div>
    </>
  );
}

CustomerSearch.className = 'CustomerSearch';
CustomerSearch.propTypes = {
  clickSearchBox: PropTypes.func.isRequired,
  blurSearchBox: PropTypes.func.isRequired,
  changeSearchKey: PropTypes.func.isRequired,
  cancelSearching: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  searchKey: PropTypes.string,
  searchBoxRef: PropTypes.object,
};

export default FunctionComponentFactory.get(CustomerSearch);
