import Config from '../config/Config';
import SyncConstant from "../view/constant/SyncConstant";

import Singleton from "./Singleton";

export default class AbstractResourceModel {

  resourceName = '';
  dataType = '';

    /**
     * get resource depend on mode
     *
     * @return: object
     */
  getResource() {
    if (
      Config.dataTypeMode &&
      this.dataType &&
      Config.dataTypeMode[this.dataType] === SyncConstant.OFFLINE_MODE
    ) {
      return this.getResourceOffline();
    }
    return this.getResourceOnline();
  }

    /**
     * get omc resource
     *
     * @return: object
     */
  getResourceOnline() {
    return Singleton.getOnline(this.resourceName);
  }

    /**
     * get offline resource
     *
     * @return: object
     */
  getResourceOffline() {
    return Singleton.getOffline(this.resourceName);
  }

    /**
     * Call API request get list product
     * @param searchKey
     * @returns {Object}
     */
  getList(searchKey) {
    return this.getResource().getList(searchKey);
  }

    /**
     * get data by id
     * @param id
     * @return {*|Promise<any>}
     */
  getById(id) {
    return this.getResource().getById(id);
  }
}
