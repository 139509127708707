import ServiceFactory from "../framework/factory/ServiceFactory";

export class PlatformServiceClass {
  className = 'PlatformServiceClass';

  isSaasPlatform() {
    return parseInt(process.env.REACT_APP_IS_SAAS, 10);
  }

  getPlatformName() {
    return process.env.REACT_APP_PLATFORM;
  }
}

/**
 * @type {PlatformServiceClass}
 */
const platformService = ServiceFactory.get(PlatformServiceClass);
export default platformService;
