import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from './GraphqlAbstract';

export class GraphqlConfigClass extends GraphqlAbstract {
  static className = 'GraphqlConfigClass';

  /**
   * Get Config
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getConfig() {
    return this.queryWithGQLBuilder(
      "getPosConfig",
      {},
      [
        {
          settings: [
            'path',
            'value',
          ],
        },
        {
          shopConfig: [
            'name',
            'contactEmail',
            'currencyCode',
            {
              currencyFormats: [
                'moneyFormat',
                'moneyWithCurrencyFormat',
                'moneyInEmailsFormat',
                'moneyWithCurrencyInEmailsFormat',
              ],
            },
            'shipsToCountries',
            'taxShipping',
            'taxesIncluded',
            'ianaTimezone',
            'timezoneAbbreviation',
            'timezoneOffset',
            'timezoneOffsetMinutes',
            {
              primaryDomain: [
                {
                  localization: [
                    'defaultLocale',
                  ],
                },
              ],
            },
            'defaultCountryCode',
          ],
        },
        {
          customerAttributeFormMetadata: [
            'attributeCode',
            'frontendInput',
            'frontendLabel',
            'required',
            {
              options: [
                'value',
                'label',
              ],
            },
            'sortOrder',
            'visible',
            'maxLength',
          ],
        },
        {
          customerAddressFormMetadata: [
            'attributeCode',
            'frontendInput',
            'frontendLabel',
            'required',
            {
              options: [
                'value',
                'label',
              ],
            },
            'sortOrder',
            'visible',
            'maxLength',
          ],
        },
        'maxDiscountPercent',
      ],
      {
        operationName: 'getPosConfig',
      },
    );
  }
}

/**
 * @type {GraphqlConfigClass}
 */
export default DataResourceFactory.get(GraphqlConfigClass);
