import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from './GraphqlAbstract';

export class GraphqlDenominationClass extends GraphqlAbstract {
  static className = 'GraphqlDenominationClass';

  /**
   * Get Denominations By Currency Code
   *
   * @param currencyCode
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getDenominationsByCurrencyCode(currencyCode) {
    return this.queryWithGQLBuilder(
      "getDenominationsByCurrencyCode",
      {
        currencyCode: {
          name: 'currencyCode',
          type: 'String',
          value: currencyCode,
          required: true,
        },
      },
      [
        'id',
        'name',
        'value',
        'currency_code',
        'sort_order',
      ],
      {
        operationName: 'getDenominationsByCurrencyCode',
      },
    );
  }
}

/**
 * @type {GraphqlDenominationClass}
 */
export default DataResourceFactory.get(GraphqlDenominationClass);
