import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../../config/i18n';
import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import OrderService from '../../../../service/order/OrderService';

function Order({order, isActive, handleSelectOrder}) {

  const priceLabel = OrderService.getPriceLabel(order);
  const orderStatus = OrderService.getOrderStatus(order);

  return (
    <li className={`item ${isActive && 'active'}`} onClick={() => handleSelectOrder(order)} role="presentation" >
      <div className="item-info">
        <div className="name">
          <span className="value">{order.name}</span>
          <span className={`status ${orderStatus.className}`}>
            {
              i18n.t(orderStatus.status)
            }
          </span>
        </div>
        <div className="price">
          <span className="value">{OrderService.getPrice(order.totalPriceSet.shopMoney.amount)}</span>
          <span className={`price-label ${priceLabel.className}`}>{priceLabel.value}</span>
        </div>
      </div>
    </li>
  );
}

Order.className = 'OrderComponent';
Order.propTypes = {
  order: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleSelectOrder: PropTypes.func.isRequired,
};

export default FunctionComponentFactory.get(Order);
