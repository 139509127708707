import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Accordion} from 'react-bootstrap';
import SmoothScrollbar from "smooth-scrollbar";
import {useDispatch, useSelector} from 'react-redux';
import ReactToPrint from 'react-to-print';
import _ from 'lodash';

import FunctionComponentFactory
  from '../../../framework/factory/FunctionComponentFactory';
import SessionService from '../../../service/session/SessionService';
import SessionConstant from '../../constant/SessionConstant';
import SessionAction from '../../action/SessionAction';
import TemplateReport from '../print/TemplateReport.jsx';
import PaymentConstant from '../../constant/PaymentConstant';
import PermissionService from '../../../service/permission/PermissionService';
import PermissionConstant from '../../constant/PermissionConstant';

import ActivitiesHistoryItem from './activities-history/ActivitiesHistoryItem.jsx';

const SessionDetail = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const setScrollbarElement = (element) => {
    if (!element) {
      return;
    }
    SmoothScrollbar.init(element);
  };

  const {sessionSelected, isLoading} = useSelector((state) => state.core.session.sessionList);
  const {isLoadingSessionDetailPage} = useSelector((state) => state.core.session.sessionDetail);
  const componentRef = React.useRef();

  const [accordionKey, setAccordionKey] = useState([]);

  const session = SessionService.getSessionRender(sessionSelected);
  const isClosedSession = (session?.status === SessionConstant.SESSION_CLOSE);
  const isSetClosingBalance = session?.closingBalance;

  const title = SessionService.formatDate(session?.opened_at);
  const posName = SessionService.getPosName();
  const openedAt = SessionService.formatDate(session?.opened_at, 'MMMM DD, yyyy hh:mm A');
  const closedAt = SessionService.formatDate(session?.closed_at, 'MMMM DD, yyyy hh:mm A');
  const openingBalance = SessionService.formatAmount(session?.opening_amount);
  const closedAmount = SessionService.formatAmount(session?.closed_amount);
  const closedNote = session?.closed_note;

  const realBalanceClassName = (isSetClosingBalance || isClosedSession) ? "item" : "hidden";

  const [isHiddenAccordionContent, setIsHiddenAccordionContent] = useState(true);

  const accordionItemClass = isHiddenAccordionContent ? 'hide-content-accordion' : '';

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsHiddenAccordionContent(false);
    }, 100);

    return () => {
      setAccordionKey([]);
      setIsHiddenAccordionContent(true);
      clearTimeout(timeoutId);
    };
  }, [sessionSelected]);

  let totalAmount = 0;
  let totalRefunded = 0;
  const getTransaction = () => {
    const cashResult = {};

    const addTransaction = sessionSelected?.transaction || [];
    cashResult[SessionConstant.TRANSACTION_PAID_CASH] = [];
    cashResult[`${SessionConstant.TRANSACTION_PAID_CASH}Total`] = 0;
    cashResult[SessionConstant.TRANSACTION_REFUND_CASH] = [];
    cashResult[`${SessionConstant.TRANSACTION_REFUND_CASH}Total`] = 0;

    addTransaction.forEach((ct) => {
      if (!cashResult[ct.type]) {
        cashResult[ct.type] = [];
        cashResult[`${ct.type}Total`] = 0;
      }

      switch (ct.type) {
        // cash transaction (no have: put_in, take_out)
        case PaymentConstant.CASH_PAYMENT_CODE:
          if (Number(ct.value) > 0) {
            cashResult[SessionConstant.TRANSACTION_PAID_CASH].push(ct);
            cashResult[`${SessionConstant.TRANSACTION_PAID_CASH}Total`] += (ct.value || 0);

            totalAmount += ct.value;
          } else {
            cashResult[SessionConstant.TRANSACTION_REFUND_CASH].push(ct);
            cashResult[`${SessionConstant.TRANSACTION_REFUND_CASH}Total`] += (ct.value || 0);

            totalRefunded += ct.value;
          }
          break;

        case SessionConstant.TRANSACTION_PUT_IN:
          cashResult[ct.type].push(ct);
          cashResult[`${ct.type}Total`] += (ct.value || 0);
          break;
        case SessionConstant.TRANSACTION_TAKE_OUT:
          cashResult[ct.type].push(ct);
          cashResult[`${ct.type}Total`] += (ct.value || 0);
          break;

        default:
          cashResult[ct.type].push(ct);
          cashResult[`${ct.type}Total`] += (ct.value || 0);

          if (ct.value > 0) {
            totalAmount += ct.value;
          } else {
            totalRefunded += ct.value;
          }
      }
    });

    return cashResult;
  };

  const addTransaction = getTransaction();

  const totalPutMoneyIn = addTransaction[`${SessionConstant.TRANSACTION_PUT_IN}Total`] || 0;
  const totalTakeMoneyOut = addTransaction[`${SessionConstant.TRANSACTION_TAKE_OUT}Total`] || 0;

  const totalCustomerPaidCash = addTransaction[`${SessionConstant.TRANSACTION_PAID_CASH}Total`] || 0;
  const totalRefundCash = addTransaction[`${SessionConstant.TRANSACTION_REFUND_CASH}Total`] || 0;

  const totalCashIn = Number(totalPutMoneyIn) + Number(totalCustomerPaidCash);
  const totalCashOut = Number(totalTakeMoneyOut) + Number(totalRefundCash);

  const subLabel = totalCashOut === 0 ? '-' : '';
  const theoreticalClosingBalance = (session?.opening_amount + totalCashIn + totalCashOut) || 0;

  const differentAmount = SessionService.getDifferentAmount(session?.closed_amount, theoreticalClosingBalance);

  const handelClickAccordion = (key) => {
    const index = accordionKey.indexOf(key);

    const temp = _.clone(accordionKey);
    if (index === -1) {
      temp.push(key);
    } else {
      temp.splice(index, 1);
    }

    setAccordionKey(temp);
  };

  const handleCloseSession = () => {
    dispatch(SessionAction.setTypeSessionPopup(SessionConstant.SESSION_CLOSE));
  };

  const handleValidateSession = () => {
    if (differentAmount) {
      dispatch(SessionAction.setTypeSessionPopup(SessionConstant.POPUP_VALIDATE_SESSION));
      SessionService.updateSessionInProcessInLocalStorage({theoreticalClosingBalance});
    } else {

      const input = {
        id: session?.id,
        closed_amount: session?.closed_amount,
      };

      dispatch(SessionAction.validateCloseSession(input));
    }
  };

  const handleClickModalPutMoneyIn = () => {
    setAccordionKey([]);
    dispatch(SessionAction.setTypeSessionPopup(SessionConstant.POPUP_PUT_MONEY_IN));
  };

  const handleClickModalTakeMoneyOut = () => {
    setAccordionKey([]);
    dispatch(SessionAction.setTypeSessionPopup(SessionConstant.POPUP_TAKE_MONEY_OUT));
  };

  const buttonPutMoneyIn = PermissionService.isAllowed(PermissionConstant.PERMISSION_SESSION_MAKE_ADJUSTMENT)
    ? (
      <button
        className="btn btn-second"
        type="button"
        onClick={handleClickModalPutMoneyIn}
      >
        {t('Put Money In')}
      </button>
    ) : null;

  const buttonPutMoneyOut = PermissionService.isAllowed(PermissionConstant.PERMISSION_SESSION_MAKE_ADJUSTMENT)
    ? (
      <button
        className="btn btn-second"
        type="button"
        onClick={handleClickModalTakeMoneyOut}
      >
        {t('Take Money Out')}
      </button>
    ) : null;

  const getGroupButtonAction = () => {
    const btnPutMoneyInClass = isClosedSession ? "hidden" : "actions";

    const btnEditCLosingBalance = (isSetClosingBalance && PermissionService.isAllowed(PermissionConstant.PERMISSION_SESSION_OPEN_AND_CLOSED)) ? (
      <button className="btn btn-second" type="button" onClick={handleCloseSession}>
        {t("Edit Closing Balance")}
      </button>
    ) : null;

    return (
      <div className={btnPutMoneyInClass}>
        {buttonPutMoneyIn}
        {buttonPutMoneyOut}
        {btnEditCLosingBalance}
      </div>
    );
  };

  const getItemDifferentContent = () => {
    const differentClassName = ((isSetClosingBalance || (isClosedSession && !closedNote)) ? 'item' : "item hidden");
    return (
      <li className={differentClassName} >
        <div className="item-title">
          <span className="title">{t('Difference')}</span>
          <span className="value">
            {SessionService.formatAmount(differentAmount)}
          </span>
        </div>
      </li>
    );
  };

  const getItemDifferentAccordion = () => {
    const itemDifferentClass = (!isSetClosingBalance && isClosedSession && closedNote) ? "item item-collapse" : "hidden";
    return (
      <li
        className={itemDifferentClass}
      >
        <Accordion defaultActiveKey={[]} activeKey={accordionKey} alwaysOpen>
          <Accordion.Item eventKey="3" className={accordionItemClass}>
            <Accordion.Header className="item-title" onClick={() => handelClickAccordion("3")}>
              <span className="title">{t('Difference')}</span>
              <span className="value">
                {SessionService.formatAmount(differentAmount)}
              </span>
            </Accordion.Header>
            <Accordion.Body className="item-content">
              <div className="subitem subitem-different">
                <span className="datetime pull-left">{closedNote}</span>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </li>
    );
  };

  const getNotFoundSessionDetail = () => {
    if (isLoading) {
      return null;
    }
    return (
      <>
        <div className="session-right">
          <div className="block-title" />
          <div className="page-notfound">
            {t('Create a session to start selling.')}
          </div>
        </div>
      </>
    );
  };

  const getGroupButtonBottom = () => {
    const btnXReportClass = isClosedSession ? "hidden" : "btn btn-default btn-cancel";
    const btnCloseSessionClass = isClosedSession ? "hidden" : "btn btn-default";
    const btnZReportClass = isClosedSession ? "btn btn-default btn-cannel" : "hidden";

    const btnCloseSession = (!isSetClosingBalance && PermissionService.isAllowed(PermissionConstant.PERMISSION_SESSION_OPEN_AND_CLOSED)) ? (
      <button
        className={btnCloseSessionClass}
        type="button"
        onClick={handleCloseSession}
      >
        {t("Close Session")}
      </button>
    ) : null;

    const btnValidate = (isSetClosingBalance && PermissionService.isAllowed(PermissionConstant.PERMISSION_SESSION_OPEN_AND_CLOSED)) ? (
      <button
        className={btnCloseSessionClass}
        type="button"
        onClick={handleValidateSession}
      >
        {t("Validate")}
      </button>
    ) : null;

    return (
      <>
        <div className="block-bottom">
          <ReactToPrint
            trigger={() => (
              <button
                className={btnXReportClass}
                type="button"
              >
                {t('X-Report')}
              </button>
            )}
            content={() => componentRef.current}
          />

          {btnCloseSession}
          {btnValidate}

          <ReactToPrint
            trigger={() => (
              <button
                className={btnZReportClass}
                type="button"
              >
                {t('Z-Report')}
              </button>
            )}
            content={() => componentRef.current}
          />

        </div>
      </>
    );
  };

  const getActivitiesHistoryItem = (types) => {
    const cashTransactionAdd = types.reduce((transactions, type) => {
      if (!addTransaction[type]) {
        return transactions;
      }
      transactions.push(...addTransaction[type]);
      return transactions;
    }, []);

    return cashTransactionAdd.map((transaction) => {
      return (
        <ActivitiesHistoryItem
          key={transaction.id}
          transaction={transaction}
        />
      );
    });
  };

  const getContent = () => {
    const hiddenClass = isClosedSession ? null : "hidden";

    return (
      <>
        <div className="session-right">
          <div className="block-title">
            <strong className="title">{title}</strong>
          </div>
          <div data-scrollbar ref={setScrollbarElement} className="block-content">
            <div className="session-content-top">
              <div className="detail">
                <div className="pull-left">
                  <p>
                    <b>{t('POS')}</b>
                    <span>{posName}</span>
                  </p>
                </div>
                <div className="pull-right text-right">
                  <p>
                    <b>{t('Opened')}</b>
                    <span>{openedAt}</span>
                  </p>
                  <p className={hiddenClass}>
                    <b>{t('Closed')}</b>
                    <span>{closedAt}</span>
                  </p>
                </div>
              </div>
              {getGroupButtonAction()}
            </div>
            <ul className="session-content-list">
              <li className="item">
                <div className="item-title">
                  <span className="title">{t('Opening Balance')}</span>
                  <span className="value">{openingBalance}</span>
                </div>
              </li>
              <li className="item">
                <Accordion defaultActiveKey={[]} alwaysOpen activeKey={accordionKey} >
                  <Accordion.Item eventKey="1" className={accordionItemClass}>
                    <Accordion.Header className="item-title" onClick={() => handelClickAccordion("1")}>
                      <span className="title">{t('Cash in')}</span>
                      <span className="value">{t('+') + SessionService.formatAmount(totalCashIn)}</span>
                    </Accordion.Header>
                    <Accordion.Body className="item-content">
                      {
                        getActivitiesHistoryItem([SessionConstant.TRANSACTION_PUT_IN, SessionConstant.TRANSACTION_PAID_CASH])
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className={accordionItemClass}>
                    <Accordion.Header className="item-title" onClick={() => handelClickAccordion("2")}>
                      <span className="title">{t('Cash out')}</span>
                      <span className="value">{subLabel}{SessionService.formatAmount(totalCashOut)}</span>
                    </Accordion.Header>
                    <Accordion.Body className="item-content">
                      {
                        getActivitiesHistoryItem([SessionConstant.TRANSACTION_TAKE_OUT, SessionConstant.TRANSACTION_REFUND_CASH])
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </li>
              <li className="item">
                <div className="item-title">
                  <span className="title">{t('Theoretical Closing Balance')}</span>
                  <span className="value">
                    {SessionService.formatAmount(theoreticalClosingBalance)}
                  </span>
                </div>
              </li>
              <li className={realBalanceClassName}>
                <div className="item-title">
                  <span className="title">{t('Real Closing Balance')}</span>
                  <span className="value">
                    {closedAmount}
                  </span>
                </div>
              </li>
              {getItemDifferentContent()}
              {getItemDifferentAccordion()}
            </ul>
          </div>
          {getGroupButtonBottom()}
        </div>
      </>
    );
  };

  if (isLoadingSessionDetailPage) {
    return (
      <div className="session-right">
        <div className="page-detail loader-product" />
      </div>
    );
  }

  return (
    <>
      {session && getContent()}
      {!session && getNotFoundSessionDetail()}
      <div className="" style={{display: 'none'}}>
        <TemplateReport
          ref={componentRef}
          totalPutMoneyIn={totalPutMoneyIn}
          totalTakeMoneyOut={totalTakeMoneyOut}
          totalCustomerPaidCash={totalCustomerPaidCash}
          totalRefundCash={totalRefundCash}
          totalCashIn={totalCashIn}
          totalCashOut={totalCashOut}
          totalAmount={totalAmount}
          totalRefunded={totalRefunded}
          addTransaction={addTransaction}
        />
      </div>
    </>
  );
};

SessionDetail.className = "SessionDetail";
export default FunctionComponentFactory.get(SessionDetail);
