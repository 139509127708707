import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import "../../assets/Location.css";
import FunctionComponentFactory from '../../framework/factory/FunctionComponentFactory';
import SelectPosLocation from "../component/staff/SelectPosLocation.jsx";
import LocalStorageService from '../../service/LocalStorageService';

export function SelectPosLocationPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (LocalStorageService.getToken()) {
      return;
    }
    navigate('/login');
  }, [navigate]);
  return <SelectPosLocation />;
}

SelectPosLocationPage.className = 'SelectPosLocationPage';
export default FunctionComponentFactory.get(SelectPosLocationPage);
