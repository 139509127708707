const AddressConstant = {
  SHOW_ADD_ADDRESS_POPUP: '[CUSTOMER] SHOW_ADD_ADDRESS_POPUP',
  HIDE_ADD_ADDRESS_POPUP: '[CUSTOMER] HIDE_ADD_ADDRESS_POPUP',
  SHOW_EDIT_ADDRESS_POPUP: '[CUSTOMER] SHOW_EDIT_ADDRESS_POPUP',

  SAVE_ADDRESS_POPUP: '[CUSTOMER] SAVE_ADDRESS_POPUP',
  SAVE_ADDRESS_POPUP_SUCCESS: '[CUSTOMER] SAVE_ADDRESS_POPUP_SUCCESS',
  SAVE_ADDRESS_POPUP_FAIL: '[CUSTOMER] SAVE_ADDRESS_POPUP_FAIL',
};

export default AddressConstant;
