import SessionService from '../../service/session/SessionService';
import SessionConstant from '../constant/SessionConstant';
import ActionFactory from "../../framework/factory/ActionFactory";
import CurrencyService from '../../service/CurrencyService';
import i18n from '../../config/i18n';

export class SessionActionClass {

  /**
   * Get Sessions
   * @param {*} input
   * @returns {(function(*): Promise<void>)|*}
   */
  getSessions(input) {
    return async (dispatch) => {
      dispatch({
        type: SessionConstant.GET_SESSIONS,
      });

      try {
        const response = await SessionService.getSessions(input);
        dispatch(this.getSessionsSuccess(response.data.getSessions));

        if (!response.data.getSessions.items || response.data.getSessions.items.length === 0) {
          dispatch(this.setLoadingSessionDetailPage(false));
        }
      } catch (error) {
        dispatch(this.getSessionsFailed(error.message));
      }
    };
  }

  /**
   * Get Session Success
   * @param {*} result
   * @returns {object}
   */
  getSessionsSuccess(result) {
    return {
      type: SessionConstant.GET_SESSIONS_SUCCESS,
      items: result.items,
      pageInfo: result.page_info,
      totalCount: result.total_count,
    };
  }

  /**
   * Get Products Failed
   *
   * @param error
   * @returns {{type: string, error}}
   */
  getSessionsFailed(error) {
    return {
      type: SessionConstant.GET_SESSIONS_FAILED,
      error,
    };
  }

  /**
   * Get Session Detail
   * @param {*} input
   * @returns {(function(*): Promise<void>)|*}
   */
  getSessionDetail(input) {
    return async (dispatch) => {

      try {
        const response = await SessionService.getSessionDetail(input);
        dispatch(this.getSessionDetailSuccess(response));
        dispatch(this.setLoadingSessionDetailPage(false));
      } catch (error) {
        throw new Error(i18n.t('Error Get Session Detail: {{error}}', {error}));
      }
    };
  }

  /**
   * Get Session Detail Success
   * @param {*} result
   * @returns {object}
   */
  getSessionDetailSuccess(result) {
    return {
      type: SessionConstant.GET_SESSION_DETAIL_SUCCESS,
      session: result.data.getSessionDetail.session,
    };
  }

  /**
   * Get Session Detail Failed
   * @param {*} error
   * @returns {object}
   */
  getSessionDetailFailed(error) {
    return {
      type: SessionConstant.GET_SESSIONS_FAILED,
      error,
    };
  }

  /**
   * Set Current Session
   * @param {*} session
   * @returns {object}
   */
  setCurrentSession(session) {
    return {
      type: SessionConstant.SET_CURRENT_SESSION,
      sessionSelected: session,
    };
  }

  /**
   * Reset Is Create Session
   * @returns {object}
   */
  resetIsCreateSession() {
    return {
      type: SessionConstant.RESET_IS_CREATE_SESSION,
    };
  }

  /**
   * Open Session
   * @param {*} input
   * @returns {(function(*): Promise<void>)|*}
   */
  openSession(input) {
    return async (dispatch) => {
      try {
        const params = {
          staff_id: Number(localStorage.getItem('staff_id')),
          pos_id: Number(localStorage.getItem('pos_id')),
          location_id: localStorage.getItem('location_id'),
          opening_amount: Number(input),
          base_currency_code: CurrencyService.getShopCurrencyCode(),
        };

        const response = await SessionService.openSession(params);

        dispatch(this.openSessionSuccess(response));
      } catch (error) {
        throw new Error(i18n.t('Error Open Session: {{error}}', {error}));
      }
    };
  }

  /**
   * Open Session Success
   * @param {*} response
   * @returns {object}
   */
  openSessionSuccess(response) {
    const session = response.data.openSession.session;
    session.collected_revenue = 0;
    if (session) {
      localStorage.setItem('current_session', JSON.stringify(session));
    }

    if (session.status) {
      session.status = null;
    }
    return {
      type: SessionConstant.OPEN_SESSION_SUCCESS,
      session,
    };
  }

  /**
   * Validate Close Session
   * @param {*} input
   * @returns
   */
  validateCloseSession(input) {
    return async (dispatch, getState) => {
      const {id, closed_note = "", closed_amount} = input;
      try {
        const params = {
          id,
          closed_note,
          closed_amount,
        };

        const response = await SessionService.validateCloseSession(params);
        const {sessionSelected} = getState().core.session.sessionList;

        const session = response.data.closeSession.session;
        const sessionTemp = {
          ...session,
          transaction: sessionSelected.transaction,
        };

        dispatch(this.validateCloseSessionSuccess(sessionTemp));

      } catch (error) {
        throw new Error(i18n.t(`Error Close Session: {{error}}`, {error}));
      }

    };
  }

  /**
   * Validate Close Session Success
   * @param {*} response
   * @returns
   */

  validateCloseSessionSuccess(session) {
    if (session) {
      localStorage.setItem('current_session', JSON.stringify(session));
    }

    return {
      type: SessionConstant.CLOSE_SESSION_SUCCESS,
      session,
    };
  }

  /**
   * Add Transaction
   * @param {*} input
   * @returns {object}
   */
  addTransaction(input) {
    return async (dispatch) => {
      const {
        transaction_increment_id,
        session_id,
        location_id,
        value,
        note,
        type,
        name_payment_method,
       } = input;

      const params = {
        transaction_increment_id,
        session_id,
        location_id,
        value,
        note,
        type,
        base_currency_code: CurrencyService.getShopCurrencyCode(),
        name_payment_method,
      };

      try {
        const response = await SessionService.addTransaction(params);

        if (!response.data?.addTransaction?.success) {
          throw new Error(`${response.data?.addTransaction?.message}`);
        }

        dispatch(this.addTransactionSuccess(response));
      } catch (error) {
        throw new Error(i18n.t(`Error Put Money In: {{error}}`, {error}));
      }
    };
  }

  /**
   * Put Money In Success
   * @param {*} response
   * @returns
   */
  addTransactionSuccess(response) {
    return {
      type: SessionConstant.ADD_TRANSACTION_SUCCESS,
      transaction: response.data.addTransaction.transaction,
    };
  }

  /**
   * Set Type Session Popup
   * @param {*} type
   * @returns {object}
   */
  setTypeSessionPopup(type = SessionConstant.SESSION_OPEN) {
    const isEnableCashControl = SessionService.isEnableCashControl();

    let typePopup = SessionConstant.POPUP_OPEN_SESSION;
    switch (type) {
      case SessionConstant.SESSION_OPEN:
        typePopup = isEnableCashControl ? SessionConstant.POPUP_OPEN_SESSION_CASH_CONTROL : SessionConstant.POPUP_OPEN_SESSION;
        break;

      case SessionConstant.SESSION_CLOSE:
        typePopup = isEnableCashControl ? SessionConstant.POPUP_CLOSE_SESSION_CASH_CONTROL : SessionConstant.POPUP_CLOSE_SESSION;
        break;

      default:
        typePopup = type;
    }

    return {
      type: SessionConstant.SET_TYPE_SESSION_POPUP,
      typePopup,
    };
  }

  /**
   * Reset State
   * @returns {object}
   */
  resetState() {
    return {
      type: SessionConstant.RESET_STATE,
    };
  }

  /**
   * Set Loading Session Detail Pages
   * @param {*} isLoading
   * @returns {object}
   */
  setLoadingSessionDetailPage(isLoading) {
    return {
      type: SessionConstant.SET_LOADING_DETAIL_PAGE,
      isLoadingSessionDetailPage: isLoading,
    };
  }
}


/**
 * @type {SessionActionClass}
 */
const SessionAction = ActionFactory.get(SessionActionClass);
export default SessionAction;
