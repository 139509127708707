import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import FunctionComponentFactory from "../../../../../framework/factory/FunctionComponentFactory";
import OrderPaymentAction from "../../../../action/order/payment/OrderPaymentAction";
import i18n from "../../../../../config/i18n";

import ListPayment from "./ListPayment.jsx";
import EditPayment from "./EditPayment.jsx";
import CompletePayment from "./CompletePayment.jsx";

function OrderTakePayment() {
  const dispatch = useDispatch();
  const {isOpenListPaymentMethod, isOpenEditPayment, isOpenCompletePayment, remaining, currentIndex} = useSelector((state) => state.core.order.orderPayment);
  const {order} = useSelector((state) => state.core.order.orderDetail);
  const total = order.totalPriceSet.shopMoney.amount;
  const receivedSet = order.totalReceivedSet.shopMoney.amount;
  const totalCapturable = order.totalCapturableSet.shopMoney.amount;
  const orderRemaining = total - receivedSet - totalCapturable;
  const handleCancelTakePayment = () => {
    dispatch(OrderPaymentAction.handleOpenTakePayment(false));
  };

  useEffect(() => {
    dispatch(OrderPaymentAction.setRemaining(orderRemaining));
  }, [dispatch, orderRemaining]);

  return (
    <>
      <div className="wrapper-payment full-width active" id="wrapper-payment1">
        <div className="block-title">
          <button className="btn-cancel" type="button" onClick={() => handleCancelTakePayment()}>
            {i18n.t('Cancel')}
          </button>
          <strong className="title">
            {i18n.t('Take Payment Order {{orderName}}', {orderName: order.name})}
          </strong>
        </div>

        {isOpenListPaymentMethod && <ListPayment remaining={remaining} grandTotal={total} order={order} />}
        {isOpenEditPayment && <EditPayment remaining={remaining} grandTotal={total} orderRemaining={orderRemaining} currentIndex={currentIndex} />}
        {isOpenCompletePayment && <CompletePayment grandTotal={orderRemaining} />}
      </div>
    </>
  );
}

OrderTakePayment.className = 'OrderTakePayment';
export default FunctionComponentFactory.get(OrderTakePayment);
