import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import LocalStorageService from '../LocalStorageService';
import LocalStorageConstant from '../../view/constant/LocalStorageConstant';
import DenominationResourceModel
  from '../../resource-model/denomination/DenominationResourceModel';

/**
 * Staff Service Class
 */
export class DenominationServiceClass extends CoreService {
  static className = 'DenominationServiceClass';
  resourceModel = DenominationResourceModel;

  /**
   * Get Denominations By Currency Code
   *
   * @param currencyCode
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getDenominationsByCurrencyCode(currencyCode) {
    return this.getResourceModel().getDenominationsByCurrencyCode(currencyCode);
  }

  /**
   * Save all config to Local storage
   * @param data
   */
  saveToLocalStorage(data) {
    LocalStorageService.set(LocalStorageConstant.DENOMINATIONS, JSON.stringify(data));
  }

  /**
   * Get Denominations From Local Storage
   * @returns {any|null}
   */
  getDenominationsFromLocalStorage() {
    const denominations = LocalStorageService.get(LocalStorageConstant.DENOMINATIONS);
    return denominations ? JSON.parse(denominations) : [];
  }
}

/**
 * @type {DenominationServiceClass}
 */
const DenominationService = ServiceFactory.get(DenominationServiceClass);

export default DenominationService;
