import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import QuoteService from '../../../../service/checkout/QuoteService';
import CustomSale from '../custom-sale/CustomSale.jsx';
import PermissionService from '../../../../service/permission/PermissionService';
import PermissionConstant from '../../../constant/PermissionConstant';
import ProductList from '../catalog/ProductList.jsx';

function CartHeader() {
  const {t} = useTranslation();
  const {quote} = useSelector((state) => state.core.checkout);

  const itemsQty = QuoteService.getTotalItemsQty(quote);
  const title = t("Cart") + (itemsQty ? ` (${itemsQty})` : '');

  const [isOpenCustomSalePopup, setIsOpenCustomSalePopup] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const {currentPage} = useSelector((state) => state.core.checkout.index);
  const isDisableEditItem = (currentPage !== ProductList.className);

  const showPopupCustomSale = () => {
    setIsOpenCustomSalePopup(true);
  };


/**
 *  Hide popup custom sale
 */
  function hidePopupCustomSale() {
    setIsOpenCustomSalePopup(false);
    setIsNew(true);
  }

/**
 * Set is new custom sale
 * @param {boolean} isNew
 */
  function setIsNewCustomSale(isNewValue = true) {
    setIsNew(isNewValue);
  }

  const canAddCustomSale = () => {
    const canAddCustomSaleStaffPermission = PermissionService.isAllowed(PermissionConstant.PERMISSION_ADD_CUSTOM_SALES);
    return !isDisableEditItem && canAddCustomSaleStaffPermission;
  };

  const getCustomSaleButton = () => {
    if (!canAddCustomSale()) {
      return null;
    }
    return (
      <button
        className="btn-customesale"
        type="button"
        data-toggle="modal"
        data-target="#popup-custom-sale"
        onClick={() => showPopupCustomSale()}
      ><span>{t('Custom Sale')}</span>
      </button>
    );
  };
  const getCustomSalePopup = () => {
    if (!canAddCustomSale()) {
      return null;
    }
    return (
      <CustomSale
        isOpenCustomSalePopup={isOpenCustomSalePopup}
        isNew={isNew}
        hidePopupCustomSale={() => hidePopupCustomSale()}
        setIsNewCustomSale={(isNewValue) => setIsNewCustomSale(isNewValue)}
      />
    );
  };

  return (
    <>
      <div className="cart-header wrapper-header">
        <div className="header-left">
          <div className="header-customer">
            <strong className="title">
              {title}
            </strong>
            {getCustomSaleButton()}
          </div>
        </div>
      </div>
      {getCustomSalePopup()}
    </>
  );
}

CartHeader.className = 'CartHeader';
CartHeader.propTypes = {};

export default FunctionComponentFactory.get(CartHeader);
