import {toast} from 'react-toastify';

import CountryConstant from '../constant/CountryConstant';
import CountryService from '../../service/CountryService';
import Config from '../../config/Config';
import i18n from '../../config/i18n';
import LocalStorageService from '../../service/LocalStorageService';
import ActionFactory from '../../framework/factory/ActionFactory';

import LoadingAction from './LoadingAction';

export class CountryActionClass {

  /**
   * Get Config
   * @param atLoadingPage
   * @returns {(function(*): Promise<void>)|*}
   */
  getAllCountries(atLoadingPage = false) {
    return async (dispatch) => {
      dispatch({
        type: CountryConstant.GET_ALL_COUNTRIES,
        atLoadingPage,
      });
      try {
        const result = await CountryService.getAllCountriesOnline();
        if (result && result.data && result.data.countries) {
          const countries = result.data.countries;
          await CountryService.saveToLocalStorage(countries);
          Config.countries = countries;
          if (atLoadingPage) {
            dispatch(LoadingAction.updateFinishedList(CountryConstant.TYPE_GET_ALL_COUNTRIES));
          }
          dispatch(this.getAllCountriesResult(countries));
          return;
        }
        toast.error(
          i18n.t("Failed to get country data. Please contact technical support."),
        );
        dispatch(this.getAllCountriesError(result));
      } catch (error) {
        if (LocalStorageService.getToken()) {
          toast.error(
            i18n.t(
              'Failed to get country data. Please contact technical support.',
            ),
          );
        }
        dispatch(this.getAllCountriesError(error.message));
      }
    };
  }

  /**
   * Get countries Result
   * @param countries
   * @returns {{configs: {}, type: string}}
   */
  getAllCountriesResult(countries = {}) {
    return {
      type: CountryConstant.GET_ALL_COUNTRIES_RESULT,
      countries,
    };
  }

  /**
   * Get countries Error
   * @param error
   * @returns {{type: string, error}}
   */
  getAllCountriesError(error) {
    return {
      type: CountryConstant.GET_ALL_COUNTRIES_ERROR,
      error,
    };
  }
}

const CountryAction = ActionFactory.get(CountryActionClass);
export default CountryAction;
