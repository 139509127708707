import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from './GraphqlAbstract';


export class GraphqlProductClass extends GraphqlAbstract {
  static className = 'GraphqlProductClass';

  /**
   * Get Products
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProducts(input) {
    return this.queryWithGQLBuilder(
      'getProducts',
      {
        input: {
          name: 'input',
          type: 'GetProductsInput',
          value: input,
          required: true,
        },
      },
      [
        {
          items: [
            'id',
            'title',
            {
              featuredImage: [
                'altText',
                'height',
                'id',
                'url',
                'width',
              ],
            },
            'handle',
            'hasOnlyDefaultVariant',
            'hasOutOfStockVariants',
            'isGiftCard',
            {
              priceRangeV2: [
                {
                  minVariantPrice: [
                    'amount',
                    'currencyCode',
                  ],
                },
                {
                  maxVariantPrice: [
                    'amount',
                    'currencyCode',
                  ],
                },
              ],
            },
            'productType',
            'status',
            'tags',
            'title',
            'totalVariants',
            'tracksInventory',
            'totalInventory',
            'updatedAt',
            'createdAt',
          ],
        },
        {
          pageInfo: [
            'hasNextPage',
            'endCursor',
            'hasPreviousPage',
            'startCursor',
          ],
        },
      ],
      {
        operationName: 'getProducts',
      },
    );
  }

  /**
   * Get product options
   * @param productId
   * @param isIncludeCollection
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProductOptions(productId, isIncludeCollection = false) {
    const fields = [
      {
        productOptions: [
          'id',
          'name',
          'values',
          'position',
        ],
      },
    ];
    if (isIncludeCollection) {
      fields.push('collectionIds');
    }
    return this.queryWithGQLBuilder(
      'getProductOptions',
      {
        id: {
          name: 'id',
          type: 'String',
          value: productId,
          required: true,
        },
      },
      fields,
      {
        operationName: 'getProductOptions',
      },
    );
  }

  /**
   * Get Product With One Variant
   *
   * @param id
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProductWithOneVariant(id) {
    return this.queryWithGQLBuilder(
      'getProductWithOneVariant',
      {
        id: {
          name: 'id',
          type: 'String',
          value: id,
          required: true,
        },
      },
      [
        'id',
        'status',
        'createdAt',
        'collectionIds',
        {
          featuredImage: [
            'altText',
            'url',
          ],
        },
        'hasOnlyDefaultVariant',
        'hasOutOfStockVariants',
        'isGiftCard',
        {
          priceRangeV2: [
            {
              minVariantPrice: [
                'amount',
                'currencyCode',
              ],
            },
            {
              maxVariantPrice: [
                'amount',
                'currencyCode',
              ],
            },
          ],
        },
        'productType',
        'tags',
        'title',
        'totalInventory',
        'totalVariants',
        'tracksInventory',
        'updatedAt',
        'vendor',
        {
          variants: [
            'availableForSale',
            'barcode',
            'displayName',
            'id',
            {
              image: [
                'altText',
                'url',
              ],
            },
            'inventoryQuantity',
            {
              selectedOptions: [
                'name',
                'value',
              ],
            },
            'position',
            'price',
            'sku',
            'taxCode',
            'taxable',
            'title',
            'updatedAt',
            'weight',
            'weightUnit',
            {
              inventoryItem: [
                'id',
                'harmonizedSystemCode',
                'provinceCodeOfOrigin',
                'requiresShipping',
                'tracked',
                'sku',
                {
                  inventoryLevel: [
                    'id',
                    {
                      quantities: [
                        'name',
                        'quantity',
                        'updatedAt',
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      {
        operationName: 'getProductWithOneVariant',
      },
    );
  }

  /**
   * Search By Barcode
   * @param query
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  searchByBarcode(query) {
    return this.queryWithGQLBuilder(
        'searchByBarcode',
      {
        query: {
          name: 'query',
          type: 'String',
          value: query,
          required: true,
        },
      },
      [
        {
          items: [
            'availableForSale',
            'barcode',
            'displayName',
            'id',
            {
              image: [
                'altText',
                'url',
              ],
            },
            'inventoryQuantity',
            {
              selectedOptions: [
                'name',
                'value',
              ],
            },
            'position',
            'price',
            'sku',
            'taxCode',
            'taxable',
            'title',
            'updatedAt',
            'weight',
            'weightUnit',
            {
              inventoryItem: [
                'id',
                'harmonizedSystemCode',
                'provinceCodeOfOrigin',
                'requiresShipping',
                'tracked',
                'sku',
                {
                  inventoryLevel: [
                    'id',
                    {
                      quantities: [
                        'name',
                        'quantity',
                        'updatedAt',
                      ],
                    },
                  ],
                },
              ],
            },
            {
              product: [
                'id',
                'status',
                'createdAt',
                {
                  featuredImage: [
                    'altText',
                    'url',
                  ],
                },
                'hasOnlyDefaultVariant',
                'hasOutOfStockVariants',
                'isGiftCard',
                {
                  priceRangeV2: [
                    {
                      minVariantPrice: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                    {
                      maxVariantPrice: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                'productType',
                'tags',
                'title',
                'totalInventory',
                'totalVariants',
                'tracksInventory',
                'updatedAt',
                'vendor',
                'collectionIds',
              ],
            },
          ],
        },
        {
          pageInfo: [
            'hasNextPage',
            'endCursor',
            'hasPreviousPage',
            'startCursor',
          ],
        },
      ],
      {
        operationName: 'searchByBarcode',
      },
    );
  }

  /**
   * Get Product By Collection id
   * @param query
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProductsByCollectionId(collectionId, productInput) {
    return this.queryWithGQLBuilder(
      'getProductsByCollectionId',
      {
        id: {
          name: 'id',
          type: 'String',
          value: collectionId,
          required: true,
        },
        input: {
          name: 'input',
          type: 'GetProductsInput',
          value: productInput,
          required: true,
        },
      },
      [
        'id',
        'title',
        {
          products: [
            {
              items: [
                'id',
                'title',
                {
                  featuredImage: [
                    'altText',
                    'height',
                    'id',
                    'url',
                    'width',
                  ],
                },
                'handle',
                'hasOnlyDefaultVariant',
                'hasOutOfStockVariants',
                'isGiftCard',
                {
                  priceRangeV2: [
                    {
                      minVariantPrice: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                    {
                      maxVariantPrice: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                'productType',
                'status',
                'tags',
                'title',
                'totalVariants',
                'tracksInventory',
                'totalInventory',
                'updatedAt',
                'createdAt',
              ],
            },
            {
              pageInfo: [
                'hasNextPage',
                'endCursor',
                'hasPreviousPage',
                'startCursor',
              ],
            },
          ],
        },
      ],
      {
        operationName: 'getProductsByCollectionId',
      },
    );
  }

  /**
   * Get Product Variant By Product Options
   * @param productId
   * @param selectedOptions
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProductVariantByProductOptions(productId, selectedOptions) {
    const input = {
      productId,
      selectedOptions,
    };
    return this.queryWithGQLBuilder(
      'getProductVariantByProductOptions',
      {
        input: {
          name: 'input',
          type: 'GetProductVariantByProductOptionsInput',
          value: input,
          required: true,
        },
      },
      [
        'availableForSale',
        'barcode',
        'displayName',
        'id',
        {
          image: [
            'altText',
            'url',
          ],
        },
        'inventoryQuantity',
        {
          selectedOptions: [
            'name',
            'value',
          ],
        },
        'position',
        'price',
        'sku',
        'taxCode',
        'taxable',
        'title',
        'updatedAt',
        'weight',
        'weightUnit',
        {
          inventoryItem: [
            'id',
            'harmonizedSystemCode',
            'provinceCodeOfOrigin',
            'requiresShipping',
            'tracked',
            'sku',
            {
              inventoryLevel: [
                'id',
                {
                  quantities: [
                    'name',
                    'quantity',
                    'updatedAt',
                  ],
                },
              ],
            },
          ],
        },
      ],
      {
        operationName: 'getProductVariantByProductOptions',
      },
    );
  }

  /**
   * Get Product Variant By Their Ids
   * @param variantIds
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProductVariants(variantIds) {
    return this.queryWithGQLBuilder(
      'getProductVariantsByTheirIds',
      {
        input: {
          name: 'input',
          type: '[String]',
          value: variantIds,
          required: true,
        },
      },
      [
        'availableForSale',
        'barcode',
        'displayName',
        'id',
        {
          image: [
            'altText',
            'url',
          ],
        },
        'inventoryQuantity',
        {
          selectedOptions: [
            'name',
            'value',
          ],
        },
        'position',
        'price',
        'sku',
        'taxCode',
        'taxable',
        'title',
        'updatedAt',
        'weight',
        'weightUnit',
        {
          inventoryItem: [
            'id',
            'harmonizedSystemCode',
            'provinceCodeOfOrigin',
            'requiresShipping',
            'tracked',
            'sku',
            {
              inventoryLevel: [
                'id',
                {
                  quantities: [
                    'name',
                    'quantity',
                    'updatedAt',
                  ],
                },
              ],
            },
          ],
        },
        {
          product: [
            'id',
            'status',
            'createdAt',
            'collectionIds',
            {
              featuredImage: [
                'altText',
                'url',
              ],
            },
            'hasOnlyDefaultVariant',
            'hasOutOfStockVariants',
            'isGiftCard',
            {
              priceRangeV2: [
                {
                  minVariantPrice: [
                    'amount',
                    'currencyCode',
                  ],
                },
                {
                  maxVariantPrice: [
                    'amount',
                    'currencyCode',
                  ],
                },
              ],
            },
            'productType',
            'tags',
            'title',
            'totalInventory',
            'totalVariants',
            'tracksInventory',
            'updatedAt',
            'vendor',
          ],
        },
      ],
      {
        operationName: 'getProductVariantsByTheirIds',
      },
    );
  }

  /**
   * Get Product Variant By Ids Variant
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getQuantityProductVariant(input) {
    return this.queryWithGQLBuilder(
      'getQuantityProductVariant',
      {
        input: {
          name: 'input',
          type: 'GetQuantityProductVariantInput',
          value: input,
        },
      },
      [
        {
          quantities: ['id', 'quantity', 'isTracked'],
        },
      ],
      {
        operationName: 'getQuantityProductVariant',
      },
    );
  }
}

/**
 * @type {GraphqlProductClass}
 */
export default DataResourceFactory.get(GraphqlProductClass);
