import ServiceFactory from "../../../framework/factory/ServiceFactory";
import QuoteConstant from "../../../view/constant/checkout/QuoteConstant";
import {fire} from "../../../event-bus";
import GuestCustomerService from '../../customer/GuestCustomerService';
import ShippingConstant from "../../../view/constant/ShippingConstant";

import QuoteAddressService from './AddressService';
import AbstractQuoteService from "./AbstractService";

export class ChangeCustomerServiceClass extends AbstractQuoteService {
  static className = 'ChangeCustomerService';

  /**
   * Change customer for quote
   *
   * @param {object} quote
   * @param {object} customer
   * @return {object}
   */
  changeCustomer(quote, customer = null) {
    let newQuote = quote;
    newQuote.customer = customer;
    newQuote = {...newQuote, ...this.prepareQuoteCustomerData(customer)};
    newQuote = this.changeQuoteAddress(newQuote, customer);
    newQuote.shipping_method = null;

    newQuote.shippingLine = {
      price: '0',
      shippingRateHandle: null,
      title: ShippingConstant.STORE_PICKUP_SHIPPING_METHOD_DESCRIPTION,
      custom: true,
    };

    newQuote.current_shipping_method = null;
    fire('service_quote_change_customer_after', {newQuote});
    return newQuote;
  }

  /**
   * Prepare customer data for quote
   *
   * @param customer
   * @return {{checkout_method: string, customer_id: null, customer_email: *|string, customer_firstname: *|string, customer_lastname: *|string, customer_middlename: *|string, customer_prefix: *|string, customer_suffix: *|string, customer_taxvat: *|string, customer_dob: *|string, customer_gender: *|string, customer_group_id: number, customer_is_guest: number}}
   */
  prepareQuoteCustomerData(customer = null) {
    const isGuest = !customer || !customer.id;
    return {
      checkout_method: isGuest ? QuoteConstant.METHOD_GUEST : QuoteConstant.METHOD_CUSTOMER,
      customerId: isGuest ? null : customer.id,
      email: isGuest ? GuestCustomerService.getEmail() : customer.email,
      phone: isGuest ? GuestCustomerService.getPhone() : customer.phone,
      taxExempt: customer ? customer.taxExempt : false,
    };
  }

  /**
   * Change quote address for customer
   *
   * @param quote
   * @param customer
   * @return {*}
   */
  changeQuoteAddress(quote, customer) {
    let customerBillingAddress = null;

    if (customer && customer.defaultAddress) {
      customerBillingAddress = customer.defaultAddress;
    }

    quote.billingAddress = QuoteAddressService.createAddress(customerBillingAddress, customer);
    quote.shippingAddress = QuoteAddressService.createAddress(null, customer);
    return quote;
  }
}

/** @type ChangeCustomerServiceClass */
const changeCustomerService = ServiceFactory.get(ChangeCustomerServiceClass);

export default changeCustomerService;
