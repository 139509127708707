const CollectionConstant = {
  GET_COLLECTIONS: '[COLLECTION] GET_COLLECTIONS',
  GET_COLLECTIONS_SUCCESS: '[COLLECTION] GET_COLLECTIONS_SUCCESS',
  GET_COLLECTIONS_FAILED: '[COLLECTION] GET_COLLECTIONS_FAILED',

  PAGE_SIZE: 16,
  SORT_KEY: "TITLE",
  ACTION_FIRST_PAGE: 'FIRST',
  ACTION_NEXT_PAGE: 'NEXT',
  ACTION_PREVIOUS_PAGE: 'PREVIOUS',

};

export default CollectionConstant;

