const staffConstant = {
  STAFF_LOGIN: '[STAFF] LOGIN',
  STAFF_LOGIN_SUCCESS: '[STAFF] STAFF_LOGIN_SUCCESS',
  STAFF_LOGIN_FAILED: '[STAFF] STAFF_LOGIN_FAILED',
  RESET_LOGIN_STATE: '[STAFF] RESET_LOGIN_STATE',
  STAFF_CLOSE_POPUP: '[STAFF] STAFF_CLOSE_POPUP',
  STAFF_CHANGE_PASSWORD: '[STAFF] STAFF_CHANGE_PASSWORD',
  STAFF_CHANGE_PASSWORD_SUCCESS: '[STAFF] STAFF_CHANGE_PASSWORD_SUCCESS',
  STAFF_CHANGE_PASSWORD_FAILED: '[STAFF] STAFF_CHANGE_PASSWORD_FAILED',

  GET_PERMISSIONS: '[STAFF] GET_PERMISSIONS',
  GET_PERMISSIONS_SUCCESS: '[STAFF] GET_PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_FAILED: '[STAFF] GET_PERMISSIONS_FAILED',
  TYPE_GET_PERMISSIONS: 'get_permissions',

  STAFF_LOGOUT: '[STAFF] STAFF_LOGOUT',
  STAFF_LOGOUT_SUCCESS: '[STAFF] STAFF_LOGOUT_SUCCESS',
  STAFF_LOGOUT_FAILED: '[STAFF] STAFF_LOGOUT_FAILED',
  RESET_LOGOUT_STATE: '[STAFF] RESET_LOGOUT_STATE',

  STAFF_SET_SIGN_OUT_MESSAGE: '[STAFF] STAFF_SET_SIGN_OUT_MESSAGE',

  USER_CONTINUE_LOGIN: '[STAFF] CONTINUE_LOGIN',
  USER_CONTINUE_LOGIN_SUCCESS: '[STAFF] USER_CONTINUE_LOGIN_SUCCESS',
  USER_CONTINUE_LOGIN_FAIL: '[STAFF] USER_CONTINUE_LOGIN_FAIL',
  RESET_CONTINUE_TO_LOGIN_STATE: '[STAFF] RESET_CONTINUE_TO_LOGIN_STATE',
};

export default staffConstant;
