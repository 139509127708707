import AbstractQuoteService from '../AbstractService';
import NumberService from '../../../NumberService';

export default class AbstractTotalService extends AbstractQuoteService {
  static className = 'AbstractTotalService';

  code;
  total;
  _canSetAddressAmount = true;
  _canAddAmountToAddress = true;

  /**
   * Collect address total
   *
   * @param {object} quote
   * @param {object} total
   * @return {AbstractTotalService}
   */
  collect(quote, total) {
    this._setTotal(total);
    this._setAmount(0);
    this._setBaseAmount(0);
    return this;
  }

  /**
   * Set total code
   *
   * @param {string} code
   * @return {AbstractTotalService}
   */
  setCode(code) {
    this.code = code;
    return this;
  }


  /**
   * Retrieve total code name
   *
   * @param {string|null} code
   * @return {string}
   */
  getCode(code = null) {
    return code || this.code;
  }


  /**
   * Get total code
   *
   * @param {string|null} code
   * @return {string}
   */
  getTotalCode(code = null) {
    return code || this.code;
  }


  /**
   * Get base total code
   *
   * @param {string|null} code
   * @return {string}
   */
  getBaseTotalCode(code = null) {
    const totalCode = code || this.code;
    return `base_${totalCode}`;
  }


  /**
   *
   * @return {object}
   * @private
   */
  _getTotal() {
    return this.total;
  }


  /**
   *
   * @param {object} total
   * @return {AbstractTotalService}
   * @private
   */
  _setTotal(total) {
    this.total = total;
    return this;
  }


  /**
   * Set total model amount value to address
   *
   * @param {number} amount
   * @param {string|null} code
   * @return {AbstractTotalService}
   * @private
   */
  _setAmount(amount, code = null) {
    if (this._canSetAddressAmount) {
      this._getTotal()[this.getTotalCode(code)] = amount;
      if (!this._getTotal().totalAmounts) {
        this._getTotal().totalAmounts = {};
      }
      this._getTotal().totalAmounts[this.getCode(code)] = amount;
    }
    return this;
  }

  /**
   * Set total model base amount value to address
   *
   * @param {number} baseAmount
   * @param {string|null} code
   * @return {AbstractTotalService}
   * @private
   */
  _setBaseAmount(baseAmount, code = null) {
    if (this._canSetAddressAmount) {
      this._getTotal()[this.getBaseTotalCode(code)] = baseAmount;
      if (!this._getTotal().baseTotalAmounts) {
        this._getTotal().baseTotalAmounts = {};
      }
      this._getTotal().baseTotalAmounts[this.getCode(code)] = baseAmount;
    }
    return this;
  }

  /**
   * Add total model amount value to address
   *
   * @param {number} amount
   * @param {string|null} code
   * @return {AbstractTotalService}
   */
  _addAmount(amount, code = null) {
    if (this._canAddAmountToAddress) {
      const total = this._getTotal()[this.getTotalCode(code)];
      this._getTotal()[this.getTotalCode(code)] = total ? NumberService.addNumber(total, amount) : amount;
      if (!this._getTotal().totalAmounts) {
        this._getTotal().totalAmounts = {};
      }
      this._getTotal().totalAmounts[this.getCode(code)] = this._getTotal()[this.getTotalCode(code)];
    }
    return this;
  }

  /**
   * Add total model base amount value to address
   *
   * @param {number} baseAmount
   * @param {string|null} code
   * @return {AbstractTotalService}
   */
  _addBaseAmount(baseAmount, code = null) {
    if (this._canAddAmountToAddress) {
      const total = this._getTotal()[this.getBaseTotalCode(code)];
      this._getTotal()[this.getBaseTotalCode(code)] = total
              ? NumberService.addNumber(total, baseAmount) : baseAmount;
      if (!this._getTotal().baseTotalAmounts) {
        this._getTotal().baseTotalAmounts = {};
      }
      this._getTotal().baseTotalAmounts[this.getCode(code)] = this._getTotal()[this.getBaseTotalCode(code)];
    }
    return this;
  }
}
