import {cloneDeep} from "lodash";

import ServiceFactory from "../../../framework/factory/ServiceFactory";
import CustomDiscountConstant
  from '../../../view/constant/checkout/quote/CustomDiscountConstant';
import Config from '../../../config/Config';
import QuoteService from '../QuoteService';
import i18n from "../../../config/i18n";
import DiscountConstant from "../../../view/constant/checkout/quote/DiscountConstant";

import AbstractQuoteService from "./AbstractService";


export class QuoteCustomDiscountServiceClass extends AbstractQuoteService {
  static className = 'QuoteCustomDiscountServiceClass';

  /**
   * Reset custom data
   * @param quote
   * @returns {*}
   */
  reset(quote) {
    quote.os_pos_custom_discount_reason = '';
    quote.os_pos_custom_discount_type = '';
    quote.os_pos_custom_discount_amount = 0;
    return quote;
  }

  /**
   * Maximum discount percent
   * @returns {number}
   */
  getMaxDiscountPercent() {
    const maxDiscountPercent = Config.config[CustomDiscountConstant.MAX_DISCOUNT_CONFIG_PATH];
    return (maxDiscountPercent) ? maxDiscountPercent : 0;
  }

  /**
   * Get useable discount data
   * @param quote
   * @param discountType
   * @param discountAmount
   * @returns {{percent: number, amount: number}}
   */
  getDiscountData(quote, discountType, discountAmount) {
    const maxDiscountPercent = this.getMaxDiscountPercent();
    let discountPercent = 0;
    let discountValue = parseFloat(discountAmount);
    if (discountType === CustomDiscountConstant.DISCOUNT_TYPE_PERCENT) {
      discountPercent = discountValue;
    } else {
      discountPercent = (discountValue / QuoteService.getSubtotalWithoutDiscount(quote)) * 100;
      discountPercent = parseFloat(discountPercent.toFixed(2));
    }

    // Make sure discount is not greater than max percent
    if (discountPercent > maxDiscountPercent) {
      discountPercent = maxDiscountPercent;
      if (discountType === CustomDiscountConstant.DISCOUNT_TYPE_PERCENT) {
        discountValue = discountPercent;
      } else {
        discountValue = discountPercent * QuoteService.getSubtotalWithoutDiscount(quote) / 100;
        discountValue = parseFloat(discountValue.toFixed(2));
      }
    }
    return {
      percent: discountPercent,
      amount: discountValue,
    };
  }

  /**
   * Get Discount Type Mapping
   *
   * @returns {{[CustomDiscountConstant.DISCOUNT_TYPE_FIXED]: string, [CustomDiscountConstant.DISCOUNT_TYPE_PERCENT]: string}}
   */
  getDiscountTypeMapping() {
    return {
      [CustomDiscountConstant.DISCOUNT_TYPE_PERCENT]: CustomDiscountConstant.SHOPIFY_DISCOUNT_TYPE_PERCENT,
      [CustomDiscountConstant.DISCOUNT_TYPE_FIXED]: CustomDiscountConstant.SHOPIFY_DISCOUNT_TYPE_FIXED,
    };
  }

  /**
   * Apply Custom Discount
   *
   * @param quote
   * @param discountType
   * @param discountAmount
   * @param reason
   * @returns {Promise<{quote: *}>}
   */
  async applyCustomDiscount(quote, discountType, discountAmount, reason) {
    let quoteData = cloneDeep(quote);
    let quoteDiscountType = discountType;
    let quoteDiscountAmount = discountAmount;
    let quoteDiscountReason = reason;
    const discountData = this.getDiscountData(quoteData, quoteDiscountType, quoteDiscountAmount);

    const discountValue = discountData.amount;
    if (discountValue > 0) {
      quoteDiscountReason = quoteDiscountReason || i18n.t("POS Custom Discount");
      quoteData.appliedDiscount = {
        description: DiscountConstant.POS_CUSTOM_DISCOUNT_CODE,
        title: quoteDiscountReason,
        value: discountValue,
        valueType: this.getDiscountTypeMapping()[quoteDiscountType],
      };
      quoteDiscountAmount = discountValue;

      quoteData.ignoreDiscounts = [
        {
          ignoreDiscountClass: DiscountConstant.DISCOUNT_CLASS_ORDER,
          ignoreRuleTypes: [DiscountConstant.DISCOUNT_BASIC_AUTO],
        },
      ];
      quoteData.couponCode = null;
      const result = QuoteService.removeQuoteCustomDiscountLineItem(quoteData);
      if (result && result.quote) {
        quoteData = result.quote;
      }
    } else {
      quoteData.appliedDiscount = null;
      quoteDiscountReason = '';
      quoteDiscountType = '';
      quoteDiscountAmount = 0;
      quoteData.ignoreDiscounts = null;
    }

    quoteData.os_pos_custom_discount_reason = quoteDiscountReason;
    quoteData.os_pos_custom_discount_type = quoteDiscountType;
    quoteData.os_pos_custom_discount_amount = quoteDiscountAmount;
    const customAttributes = quoteData.customAttributes || [];
    const customReasonAttribute = customAttributes.find((attr) => attr.key === 'os_pos_custom_discount_reason');
    if (customReasonAttribute) {
      customReasonAttribute.value = quoteDiscountReason;
    } else {
      customAttributes.push({
        key: 'os_pos_custom_discount_reason',
        value: quoteDiscountReason,
      });
    }

    const customDiscountTypeAttribute = customAttributes.find((attr) => attr.key === 'os_pos_custom_discount_type');
    if (customDiscountTypeAttribute) {
      customDiscountTypeAttribute.value = quoteDiscountType;
    } else {
      customAttributes.push({
        key: 'os_pos_custom_discount_type',
        value: quoteDiscountType,
      });
    }

    const customDiscountAmountAttribute = customAttributes.find((attr) => attr.key === 'os_pos_custom_discount_amount');
    if (customDiscountAmountAttribute) {
      customDiscountAmountAttribute.value = quoteDiscountAmount.toString();
    } else {
      customAttributes.push({
        key: 'os_pos_custom_discount_amount',
        value: quoteDiscountAmount.toString(),
      });
    }
    quoteData.customAttributes = customAttributes;
    return {
      quote: await QuoteService.calculateTotalsOnline(quoteData),
    };
  }

  /**
   * Remove Custom Discount
   * @param quote
   * @returns {Promise<{quote: *}>}
   */
  async removeCustomDiscount(quote) {
    const quoteData = cloneDeep(quote);
    quoteData.appliedDiscount = null;
    const quoteDiscountReason = '';
    const quoteDiscountType = '';
    const quoteDiscountAmount = 0;
    quoteData.os_pos_custom_discount_reason = quoteDiscountReason;
    quoteData.os_pos_custom_discount_type = quoteDiscountType;
    quoteData.os_pos_custom_discount_amount = quoteDiscountAmount;
    const customAttributes = quoteData.customAttributes || [];
    const customReasonAttribute = customAttributes.find((attr) => attr.key === 'os_pos_custom_discount_reason');
    if (customReasonAttribute) {
      customReasonAttribute.value = quoteDiscountReason;
    } else {
      customAttributes.push({
        key: 'os_pos_custom_discount_reason',
        value: quoteDiscountReason,
      });
    }

    const customDiscountTypeAttribute = customAttributes.find((attr) => attr.key === 'os_pos_custom_discount_type');
    if (customDiscountTypeAttribute) {
      customDiscountTypeAttribute.value = quoteDiscountType;
    } else {
      customAttributes.push({
        key: 'os_pos_custom_discount_type',
        value: quoteDiscountType,
      });
    }

    const customDiscountAmountAttribute = customAttributes.find((attr) => attr.key === 'os_pos_custom_discount_amount');
    if (customDiscountAmountAttribute) {
      customDiscountAmountAttribute.value = quoteDiscountAmount.toString();
    } else {
      customAttributes.push({
        key: 'os_pos_custom_discount_amount',
        value: quoteDiscountAmount.toString(),
      });
    }

    quoteData.customAttributes = customAttributes;
    return {
      quote: await QuoteService.calculateTotalsOnline(quoteData),
    };
  }
}

/** @type QuoteCustomDiscountServiceClass */
const QuoteCustomDiscountService = ServiceFactory.get(QuoteCustomDiscountServiceClass);

export default QuoteCustomDiscountService;
