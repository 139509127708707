import React, {useEffect, useState} from 'react';
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import FunctionComponentFactory
  from '../../../framework/factory/FunctionComponentFactory';
import '../../../assets/Loading.css';
import LoadingAction from '../../action/LoadingAction';
import ConfigAction from '../../action/ConfigAction';
import StaffAction from '../../action/StaffAction';
import CountryAction from '../../action/CountryAction';
import SessionService from '../../../service/session/SessionService';

export function Loading() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {count, total} = useSelector((state) => state.core.loading);

  const [percent, setPercent] = useState(0);
  useEffect(() => {
    let newPercent = (count / total) * 100;
    newPercent = Number(newPercent.toFixed(0));
    setPercent(newPercent);

    if (count === total) {
      setTimeout(async () => {
        const isRedirectSessionPage = await SessionService.hasRedirectSessionPage();
        if (!isRedirectSessionPage) {
          navigate("/checkout");
        }
      }, 1000);
    }
  }, [count, navigate, total]);

  useEffect(() => {
    dispatch(LoadingAction.resetState());
    dispatch(ConfigAction.getConfig(true));
    dispatch(StaffAction.getPermissions(true));
    dispatch(CountryAction.getAllCountries(true));
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <div className="wrapper-loading">
      <div className="wrapper-circular">
        <CircularProgressbar value={percent} text={`${percent}%`} />
      </div>
    </div>
  );
}

Loading.className = 'Loading';
Loading.propTypes = {};

export default FunctionComponentFactory.get(Loading);
