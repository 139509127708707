import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";

import '../../../../../assets/SelectPayment.css';
import FunctionComponentFactory from "../../../../../framework/factory/FunctionComponentFactory";
import i18n from "../../../../../config/i18n";
import OrderPaymentAction from "../../../../action/order/payment/OrderPaymentAction";

function PaymentItem(props) {
  const dispatch = useDispatch();
  const handleOnClickPaymentItem = (payment) => {
    dispatch(OrderPaymentAction.handleOpenEditPayment(true, payment));
  };
  const {payment, paymentItemKey} = props;
  const paymentIcon = payment.icon_path ? (
    <span className="img"><img alt={payment.icon_path} className="img payment-offline-icon" src={`${process.env.REACT_APP_API_URL}${payment.icon_path}`} /></span>
  ) : (
    <span className={`img image-default image-${payment.code}`} />
  );
  return (
    <>
      <li
        id={paymentItemKey} role="presentation" onClick={() => {
          handleOnClickPaymentItem(payment);
        }}
      >
        {paymentIcon}
        <span className="text">
          { i18n.t(payment.title) }
        </span>
      </li>
    </>
  );
}

PaymentItem.className = 'PaymentItem';
PaymentItem.propTypes = {
  payment: PropTypes.object,
  paymentItemKey: PropTypes.string,
};
export default FunctionComponentFactory.get(PaymentItem);
