import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";

import FunctionComponentFactory from "../../../../../framework/factory/FunctionComponentFactory";
import OrderService from "../../../../../service/order/OrderService";
import i18n from "../../../../../config/i18n";
import OrderPaymentAction from "../../../../action/order/payment/OrderPaymentAction";

function CompletePaymentItem(props) {
  const dispatch = useDispatch();
  const {paid, index, balance, order} = props;
  const {listPaid} = useSelector((state) => state.core.order.orderPayment);
  const payment = paid.selectedPayment;
  const icon = payment.icon_path
    ? <span className="img"><img alt={payment.code} className="img payment-offline-icon" src={`${process.env.REACT_APP_API_URL}${payment.icon_path}`} /></span>
    : <span className={`img image-default image-${payment.code}`} />;
  const referenceNo = paid.referenceNo
      ? (
        <div className="box reference">
          <span className="label">{ i18n.t('Reference No') }</span>
          <span className="value">{ paid.referenceNo }</span>
        </div>
      ) : '';
  const handleRemovePaidItem = () => {
    dispatch(OrderPaymentAction.handleRemovePaidItem(index, listPaid, order));
  };

  const handleUpdatePayment = () => {
    dispatch(OrderPaymentAction.setRemaining(balance));
    dispatch(OrderPaymentAction.handleUpdatePayment(true, payment, paid.amount, index));
  };

  return (
    <>
      <div className="payment-full-amount">
        <div
          className="info" role="presentation" onClick={() => {
            handleUpdatePayment();
          }}
        >
          {
            icon
          }
          <div className="price">
            <div className="box">
              <span className="label">{ payment.title }</span>
              <span className="value">{OrderService.formatPrice(paid.amount)}</span>
            </div>
            {
              referenceNo
            }
          </div>
        </div>
        <span
          role="presentation"
          className="remove-cash" onClick={() => {
            handleRemovePaidItem();
          }}
        />
      </div>
    </>
  );
}

CompletePaymentItem.className = 'CompletePaymentItemOrder';
CompletePaymentItem.propTypes = {
  paid: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  balance: PropTypes.number,
  order: PropTypes.object,
};
export default FunctionComponentFactory.get(CompletePaymentItem);
