import {cloneDeep, isEmpty} from "lodash";

import ActionFactory from "../../framework/factory/ActionFactory";
import multiCartService from "../../service/cart/MultiCartService";
import MultiCartConstant from "../constant/MultiCartConstant";
import QuoteService from "../../service/checkout/QuoteService";
import localStorageService from "../../service/LocalStorageService";
import Payment from "../component/checkout/Payment.jsx";
import QuoteConstant from "../constant/checkout/QuoteConstant";

import QuoteAction from "./checkout/QuoteAction";

export class MultiCartActionClass {

  /**
   * delete selecting card
   *
   * @param carts list carts
   * @param activeCart this card is selecting => deleted
   * @returns  {(function(*): Promise<void>)|*}
   */
  deleteCart(carts, activeCart) {
    return async (dispatch) => {
      try {
        if (!activeCart) { return; }
        if (carts.length === 1) {
          await multiCartService.delete(activeCart);
          const quote = {...QuoteService.initialQuoteReducerState, id: `tmp-quote-${new Date().getTime()}`};
          await multiCartService.resetCountAndAddCart(quote);
        } else {
          await multiCartService.delete(activeCart);
        }
        dispatch(this.getListCart());
      } catch (error) {
        return error;
      }
    };
  }

  /**
   * Get remove cart success
   *
   * @param activeCart
   * @returns {{type: string}}
   */
  removeCart(activeCart) {
    return {
      type: MultiCartConstant.DELETE_CART,
      cartId: activeCart.id,
    };
  }

  /**
   * add new cart to indexdb
   *
   * @returns  {(function(*): Promise<void>)|*}
   */
  addCart() {
    return async (dispatch) => {
      try {
        const newQuote = {...QuoteService.initialQuoteReducerState, id: `tmp-quote-${new Date().getTime()}`};
        const cart = await multiCartService.addCart(newQuote);
        dispatch(QuoteAction.setQuote(newQuote));
        localStorageService.setActiveCartId(cart.id);
        dispatch(this.addNewCart(cart));
      } catch (error) {
        return error;
      }
    };
  }

  /**
   * add new cart with quote data to indexdb
   * @param quote
   * @returns  {(function(*): Promise<void>)|*}
   */
  addCartWithQuoteData(quote) {
    return async (dispatch) => {
      try {
        const cart = await multiCartService.addCart(quote);
        dispatch(QuoteAction.setQuote(quote));
        dispatch(this.addNewCart(cart));
      } catch (error) {
        return error;
      }
    };
  }

  /**
   * updateActiveCart
   *
   * @param quote data of products, customer,... in cart
   * @returns  {(function(*): Promise<void>)|*}
   */
  updateCart(quote) {
    return async (dispatch) => {
      try {
        if (isEmpty(quote)) {
          return;
        }
        const cart = await multiCartService.updateActiveCart(quote);
        dispatch(this.updateCartSuccess(cart));
      } catch (error) {
        return error;
      }
    };
  }

  /**
   * update cart success
   *
   * @param cart
   * @returns {{type: string}}
   */
  updateCartSuccess(cart) {
    return {
      type: MultiCartConstant.UPDATE_CART,
      cart,
    };
  }

  /**
   * Add new cart success
   *
   * @param cart
   * @returns {{type: string}}
   */
  addNewCart(cart) {
    return {
      type: MultiCartConstant.ADD_NEW_CART,
      cart,
    };
  }

  /**
   * get list current cart
   *
   * @returns  {(function(*): Promise<void>)|*}
   */
  getListCart() {
    return async (dispatch) => {
      try {
        const carts = await multiCartService.searchByCurrentPos();
        if (carts?.length > 0) {
          dispatch(this.getCartsSuccess(carts));
          const defaultActiveCartId = localStorageService.getActiveCartId();
          if (defaultActiveCartId) {
            const activeCart = carts.find((cart) => cart.id === defaultActiveCartId);
            dispatch(this.selectCart(activeCart || carts[0]));
          } else {
            dispatch(this.selectCart(carts[0]));
          }
        }
      } catch (error) {
        dispatch(this.getCartsFailed(error));
      }
    };
  }

  /**
   * Get list carts success
   *
   * @param carts list carts
   * @returns {{type: string}}
   */
  getCartsSuccess(carts) {
    return {
      type: MultiCartConstant.GET_LIST_CART,
      carts,
    };
  }

  /**
   * Get list carts failed
   *
   * @param error
   * @returns {{type: string}}
   */
  getCartsFailed(error) {
    return {
      type: MultiCartConstant.GET_LIST_CART_FAILED,
      error,
    };
  }

  /**
   * Select another cart
   *
   * @param cart
   * @param currentPage
   * @returns {{type: string}}
   */
  selectCart(cart, currentPage) {
    return (dispatch) => {
      try {
        if (currentPage && currentPage === Payment.className) {
          dispatch(QuoteAction.checkoutQuote(cloneDeep((cart))));
        } else {
          dispatch(QuoteAction.setQuote(cart));
        }
        dispatch(this.setActiveCart(cart));
      } catch (error) {
        return error;
      }
    };
  }

  /**
   * set active cart
   *
   * @param cart
   * @returns {{type: string}}
   */
  setActiveCart(cart) {
    localStorageService.setActiveCartId(cart.id);
    return {
      type: MultiCartConstant.SELECT_CART,
      cartId: cart.id,
    };
  }

  /**
   * Remove All Multi Cart
   *
   * @returns {{type: string}}
   */
  removeAllMultiCart() {
    return async (dispatch, getState) => {
      dispatch({
        type: MultiCartConstant.REMOVE_ALL_MULTI_CART,
      });
      try {
        const {carts} = getState().core.multiCart;
        for (const cart of carts) {
          await multiCartService.delete(cart);
        }
        dispatch({
          type: QuoteConstant.RESET_QUOTE,
        });
        dispatch({
          type: MultiCartConstant.RESET_ALL_MULTI_CART,
        });
      } catch (error) {
        return error;
      }
    };
  }
}

const MultiCartAction = ActionFactory.get(MultiCartActionClass);
export default MultiCartAction;
