import {toast} from 'react-toastify';

import ActionFactory from '../../framework/factory/ActionFactory';
import CustomerConstant from '../constant/CustomerConstant';
import CustomerService from '../../service/customer/CustomerService';
import i18n from '../../config/i18n';
import OrderService from "../../service/order/OrderService";
import StaffService from "../../service/staff/StaffService";

export class CustomerActionClass {

  static className = 'CustomerActionClass';

  /**
   * Get Customers
   *
   * @param input
   * @returns {(function(*): Promise<void>)|*}
   */
  getCustomers(input) {
    return async (dispatch) => {
      dispatch({
        type: CustomerConstant.GET_CUSTOMERS,
        input,
      });
      try {
        const response = await CustomerService.getCustomers(input);
        dispatch(this.getCustomersSuccess(response.data.getCustomers));
      } catch (error) {
        dispatch(this.getCustomersFailed(error.message));
      }
    };
  }

  /**
   * Get Customers Success
   *
   * @param result
   * @returns {{result, type: string}}
   */
  getCustomersSuccess(result) {
    return {
      type: CustomerConstant.GET_CUSTOMERS_SUCCESS,
      items: result.items,
      pageInfo: result.pageInfo,
    };
  }

  /**
   * Get Customers Failed
   *
   * @param error
   * @returns {{type: string, error}}
   */
  getCustomersFailed(error) {
    toast.error(
      i18n.t(error),
    );
    return {
      type: CustomerConstant.GET_CUSTOMERS_FAILED,
      error,
    };
  }

  /**
   * Show Customer edit popup
   * @returns {{type: string}}
   */
  showEditCustomerPopup(customer) {
    return async (dispatch) => {
      dispatch({
        type: CustomerConstant.SHOW_EDIT_CUSTOMER_POPUP,
        customer,
      });
      try {
        const response = await CustomerService.getCustomer(customer.id);
        if (response && response.data && response.data.getCustomer) {
          dispatch(this.showEditCustomerPopupSuccess(response.data.getCustomer));
        }
      } catch (error) {
        dispatch(this.showEditCustomerPopupError(error));
      }
    };
  }

  /**
   * show edit customer popup success
   * @returns {{type: string}}
   */
  showEditCustomerPopupSuccess(customer) {
    return {
      type: CustomerConstant.SHOW_EDIT_CUSTOMER_POPUP_SUCCESS,
      customer,
    };
  }

  /**
   * show edit customer error
   * @returns {{type: string}}
   */
  showEditCustomerPopupError(error) {
    return {
      type: CustomerConstant.SHOW_EDIT_CUSTOMER_POPUP_FAIL,
      error,
    };
  }

  /**
   * Show Customer list
   * @returns {{type: string}}
   */
  showCustomerListPopup() {
    return {
      type: CustomerConstant.SHOW_CUSTOMER_LIST_POPUP,
    };
  }

  /**
   * Show Create customer popup
   * @returns {{type: string}}
   */
  showCreateCustomerPopup(updateForOrder = false) {
    return {
      type: CustomerConstant.SHOW_CREATE_CUSTOMER_POPUP,
      updateForOrder,
    };
  }

  /**
   * Hide Create customer popup
   * @returns {{type: string}}
   */
  hideCreateCustomerPopup() {
    return {
      type: CustomerConstant.HIDE_CREATE_CUSTOMER_POPUP,
    };
  }

  /**
   * Hide customer list
   * @returns {{type: string}}
   */
  hideCustomerListPopup() {
    return {
      type: CustomerConstant.HIDE_CUSTOMER_LIST_POPUP,
    };
  }

  /**
   * Reset customer list
   * @returns {{type: string}}
   */
  resetCustomerList() {
    return {
      type: CustomerConstant.RESET_CUSTOMER_LIST,
    };
  }

  /**
   * Save customer popup
   * @param customer
   * @returns {{type: string, customer}}
   */
  saveCustomerPopup(customer, updateForOrder = false) {
    return async (dispatch, getState) => {
      dispatch({
        type: CustomerConstant.SAVE_CUSTOMER_POPUP,
        customer,
      });
      try {
        const response = await CustomerService.saveCustomer(customer);
        if (response && response.data && response.data.saveCustomer && response.data.saveCustomer.userErrors && response.data.saveCustomer.userErrors.length) {
          const errorMessages = [];
          response.data.saveCustomer.userErrors.forEach(
            (error) => {
              if (error.field === null) {
                errorMessages.push(error.message);
              }
            },
          );
          if (errorMessages.length) {
            dispatch(this.saveCustomerPopupFail(errorMessages.join(', ')));
          } else {
            dispatch(this.saveCustomerPopupValidateFail(response.data.saveCustomer.userErrors));
          }
        } else {
          if (updateForOrder) {
            const order = getState().core.order.orderDetail;
            const shop = StaffService.getShopUrl();
            await OrderService.updateOrderCustomer({id: order.order.id}, response.data.saveCustomer.customer, shop);
          }
          dispatch(this.saveCustomerPopupSuccess(response.data.saveCustomer.customer));
          const QuoteAction = require('./checkout/QuoteAction').default;
          dispatch(QuoteAction.setCustomer(response.data.saveCustomer.customer));
        }
      } catch (error) {
        dispatch(this.saveCustomerPopupFail(error.message));
      }
    };
  }

  /**
   * Save customer popup success
   * @returns {{type: string}}
   */
  saveCustomerPopupSuccess(customer) {
    toast.success(
      i18n.t('Customer information has been updated successfully.'),
    );
    return {
      type: CustomerConstant.SAVE_CUSTOMER_POPUP_SUCCESS,
      customer,
    };
  }

  /**
   * Validate customer fail
   * @param userErrors
   * @returns {{type: string}}
   */
  saveCustomerPopupValidateFail(userErrors) {
    return {
      type: CustomerConstant.SAVE_CUSTOMER_POPUP_VALIDATE_FAIL,
      userErrors,
    };
  }

  /**
   * Update current customer in popup
   * @param currentCustomer
   * @returns {{type: string}}
   */
  updateCurrentCustomer(currentCustomer) {
    return {
      type: CustomerConstant.UPDATE_CURRENT_CUSTOMER_IN_POPUP,
      currentCustomer,
    };
  }

  /**
   * Save customer popup fail
   * @returns {{type: string}}
   */
  saveCustomerPopupFail(error) {
    toast.error(
      i18n.t(error),
    );
    return {
      type: CustomerConstant.SAVE_CUSTOMER_POPUP_FAIL,
      error,
    };
  }
}

const CustomerAction = ActionFactory.get(CustomerActionClass);
export default CustomerAction;
