import React from 'react';
import PropType from "prop-types";


const BillingAddress = ({billingAddress}) => {
  if (!billingAddress) {
    return <></>;
  }
  const {firstName, lastName, address1, address2, province, city, zip, country} = billingAddress;
  const recipientName = {
    firstName,
    lastName,
  };
  const address = {
    address1,
    address2,
    province,
    city,
    zip,
    country,
  };
  function concatStrings(obj, seperator = " ") {
    const filterObj = Object.keys(obj).reduce((filterObjResult, key) => {
      if (obj[key]) {
        filterObjResult[key] = obj[key];
      }
      return filterObjResult;
    }, {});
    let concatResult = "";
    Object.values(filterObj).forEach((value, index) => {
      if (!value) {
        return;
      }
      concatResult += `${value}`;
      if (index < Object.values(filterObj).length - 1) {
        concatResult += `${seperator}`;
      }
    });
    return concatResult;
  }
  return (
    <ul className="shipping-address">
      <li>
        <div className="title">{concatStrings(recipientName, " ")}</div>
      </li>
      <li>
        <div className="title">{concatStrings(address, ", ")}</div>
      </li>
    </ul>
  );
};

BillingAddress.propTypes = {
  billingAddress: PropType.object.isRequired,
};
export default BillingAddress;
