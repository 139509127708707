
import '../../../../../assets/OrderFulfill.css';

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import SmoothScrollbar from 'smooth-scrollbar';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import FunctionComponentFactory from '../../../../../framework/factory/FunctionComponentFactory';
import OrderConstant from '../../../../constant/OrderConstant';
import OrderAction from '../../../../action/OrderAction';
import CurrencyService from '../../../../../service/CurrencyService';
import OrderService from '../../../../../service/order/OrderService';

import OrderFulfillItem from './OrderFulfillItem.jsx';
import FulfillmentConfirmation from './modal/FulfillmentConfirmation.jsx';
import CancelCreateShipment from './modal/CancelCreateShipment.jsx';


const OrderFulfill = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {order, isLoadingBtnFulfill} = useSelector((state) => state.core.order.orderDetail);

  const totalPrice = order?.totalPriceSet?.shopMoney?.amount || 0;
  const [typeOpenModal, setTypeOpenModal] = useState(null);

  const setScrollbarElement = (element) => {
    if (!element) {
      return;
    }
    SmoothScrollbar.init(element);
  };

  const [items, setItems] = useState([]);
  useEffect(() => {
    const listItem = order?.lineItems?.items || [];

    if (!listItem.length) {
      return;
    }

    const temp = listItem.map((item) => {
      const product = item.product || {};
      if (product.isGiftCard) {
        return null;
      }

      return {
        ...item,
        qtyFulfill: 0,
      };
    }).filter(Boolean);

    if (temp) {
      setItems(temp);
    }
  }, [order]);

  const handleUpdateQty = (id, type, value) => {
    const item = items.find((im) => im?.id === id);
    const qtyLeft = Number(item.qtyLeft);
    const qtyOnHand = Number(item.qtyOnHand);
    item.errorMessage = '';
    item.disabled = '';

    if (!OrderService.isAssignedToCurrentLocation(item)) {
      item.errorMessage = t('This item is not assigned to this location');
      setItems([...items]);

      if (type === OrderConstant.BUTTON_PLUS) {
        item.disabled = OrderConstant.BUTTON_PLUS;
      } else if (type === OrderConstant.BUTTON_MINUS) {
        item.disabled = OrderConstant.BUTTON_MINUS;
      } else if (type === OrderConstant.CHANGE_NUMPAD) {
        item.disabled = OrderConstant.BUTTON_PLUS;
      }

      return;
    }

    switch (type) {
      case OrderConstant.BUTTON_PLUS:
        if (qtyOnHand < qtyLeft && Number(item.qtyFulfill) >= qtyOnHand) {
          item.errorMessage = t(`The maximum quantity to ship is {{qtyOnHand}}`, {qtyOnHand});
          item.disabled = OrderConstant.BUTTON_PLUS;
          item.qtyFulfill = qtyOnHand;
        } else if (item.qtyFulfill >= qtyLeft) {
          item.errorMessage = t('Qty to Fulfill cannot be greater than Qty Left.');
          item.disabled = OrderConstant.BUTTON_PLUS;
          item.qtyFulfill = qtyLeft;
        } else if (item.qtyFulfill < qtyLeft) {
          item.qtyFulfill++;
        }
        break;
      case OrderConstant.BUTTON_MINUS:
        if (item.qtyFulfill <= 0) {
          item.errorMessage = t('Qty to Fulfill cannot be smaller than 0.');
          item.disabled = OrderConstant.BUTTON_MINUS;
          item.qtyFulfill = 0;
        } else if (item.qtyFulfill > 0) {
          item.qtyFulfill--;
        }
        break;
      case OrderConstant.CHANGE_NUMPAD:
        if (qtyOnHand < qtyLeft && Number(value) > qtyOnHand) {
          item.errorMessage = t(`The maximum quantity to ship is {{qtyOnHand}}`, {qtyOnHand});
          item.disabled = OrderConstant.BUTTON_PLUS;
          item.qtyFulfill = qtyOnHand;
        } else if (value > qtyLeft) {
          item.errorMessage = t('Qty to Fulfill cannot be greater than Qty Left.');
          item.disabled = OrderConstant.BUTTON_PLUS;
          item.qtyFulfill = qtyLeft;
        } else {
          item.qtyFulfill = value;
        }
        break;
      default:
        item.qtyFulfill = 0;
    }

    setItems([...items]);
  };

  const handleClickCancel = () => {
    setTypeOpenModal(OrderConstant.OPEN_CANCEL_CREATE_SHIPMENT);
  };

  const handleChangeMaxQty = (event) => {
    const products = order?.lineItems?.items || [];

    if (!products.length) {
      return;
    }

    const temp = items.map((product) => {
      const qtyLeft = product.qtyLeft || 0;
      const qtyOnHand = product.qtyOnHand || 0;
      const maxQty = Math.min(qtyLeft, qtyOnHand);

      if (event.target.checked) {
        return {
          ...product,
          qtyFulfill: maxQty < 0 ? 0 : maxQty,
        };
      }

      return {
        ...product,
        qtyFulfill: 0,
      };
    });

    if (temp) {
      setItems(temp);
    }
  };

  const isDisableBtnFulfill = () => {
    if (!items.length) {
      return;
    }

    return items.every((product) => {
      return Number(product?.qtyFulfill) === 0;
    });
  };

  const handleClickBtnFulfill = () => {
    setTypeOpenModal(OrderConstant.OPEN_FULFILLMENT_CONFIRM);
  };

  const handleClickSaveFulfillmentConfirmation = () => {
    dispatch(OrderAction.createFulfillmentOrder(items));
    navigate('/order');
  };

  const handleActionFulfillmentConfirm = (type) => {
    switch (type) {
      case OrderConstant.CLOSE:
        setTypeOpenModal(null);
        break;
      case OrderConstant.SAVE:
        handleClickSaveFulfillmentConfirmation();
        setTypeOpenModal(null);
        break;
      default:
        setTypeOpenModal(null);
    }
  };

  const getFulfillmentConfirm = () => {
    if (!(typeOpenModal === OrderConstant.OPEN_FULFILLMENT_CONFIRM)) {
      return null;
    }

    return <FulfillmentConfirmation handleAction={handleActionFulfillmentConfirm} />;
  };

  const handleActionCancelCreateShipment = (type) => {
    switch (type) {
      case OrderConstant.NO:
        setTypeOpenModal(null);
        break;
      case OrderConstant.YES:
        setTypeOpenModal(null);
        dispatch(OrderAction.setIsOpenFulfillItemPage(false));
        break;
    }

    return true;
  };

  const getCancelCreateShipment = () => {
    if (!(typeOpenModal === OrderConstant.OPEN_CANCEL_CREATE_SHIPMENT)) {
      return null;
    }

    return <CancelCreateShipment handleAction={handleActionCancelCreateShipment} />;
  };

  const getBtnFulfil = () => {
    const loaderClass = isLoadingBtnFulfill ? "loader" : null;
    const isBtnDisable = isLoadingBtnFulfill ? true : isDisableBtnFulfill();
    return (
      <button
        className={`btn btn-default ${loaderClass}`}
        type="button"
        disabled={isBtnDisable}
        onClick={handleClickBtnFulfill}
      >
        {t('Fulfill')}
      </button>
    );
  };

  return (
    <div className="wrapper-fulfill-order">
      <div className="block-title">
        <button
          className="btn-cannel"
          type="button"
          onClick={handleClickCancel}
        >
          {t('Cancel')}
        </button>
        <strong className="title">
          <b>{t('Fulfill Items - Order {{id}}', {id: order?.name})}</b>
        </strong>
        <div className="price">
          {CurrencyService.format(totalPrice)}
        </div>
      </div>
      <>
        <div className="block-search">
          <div className="box-check">
            <label className="label-checkbox" htmlFor="max-qty">
              <span>{t('Select max Qty to Fulfill ')}</span>
              <input
                id="max-qty"
                type="checkbox"
                aria-label={t("Max Qty")}
                onChange={handleChangeMaxQty}
              />
              <span>&nbsp;</span>
            </label>
          </div>
        </div>
        <div data-scrollbar ref={setScrollbarElement} className="block-content">
          <table className="table table-order">
            <thead>
              <tr>
                <th className="t-col">&nbsp;</th>
                <th className="t-product">{t('Product')}</th>
                <th className="t-qty-on-hand">{t('Qty on Hand')}</th>
                <th className="t-qty-left">{t('Qty Left')}</th>
                <th className="t-qty-fulfill">{t('Qty to Fulfill')}</th>
              </tr>
            </thead>
            <tbody>
              {
                items.map((item) => {
                  return (
                    <OrderFulfillItem
                      key={item.id}
                      item={item}
                      handleUpdateQty={handleUpdateQty}
                    />
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <div className="block-bottom">
          <div className="actions-accept">
            {getBtnFulfil()}
          </div>
        </div>
      </>
      {getFulfillmentConfirm()}
      {getCancelCreateShipment()}
    </div>
  );
};

OrderFulfill.className = 'OrderFulfillComponent';
export default FunctionComponentFactory.get(OrderFulfill);
