import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import SessionService from '../../../../service/session/SessionService';
import SessionConstant from '../../../constant/SessionConstant';
import PaymentConstant from '../../../constant/PaymentConstant';

const ActivitiesHistoryItem = (props) => {
  const {t} = useTranslation();
  const {transaction, key} = props;

  const date_time = SessionService.formatDate(transaction?.created_at, 'MMMM DD, yyyy hh:mm A');
  const value = Number(transaction.value);

  let type = '';
  const typeAdd = SessionConstant.TYPE_ADD_CASH;
  const typeRemove = SessionConstant.TYPE_REMOVE_CASH;

  if (typeAdd.includes(transaction.type)) {
    type = '+';
  } else if (typeRemove.includes(transaction.type) && value === 0) {
    type = '-';
  }

  const getTitle = () => {
    if (transaction.type !== PaymentConstant.CASH_PAYMENT_CODE) {
      return transaction.note || "";
    }

    const orderName = transaction?.order_increment_id?.split('-')[0] || '';
    if (Number(transaction?.value) > 0) {
      return t('Receive cash from order {{orderName}}', {orderName});
    } else if (Number(transaction?.value) < 0) {
      return t('Refund by cash for order {{orderName}}', {orderName});
    }
  };

  return (
    <div className="subitem" key={key}>
      <span className="datetime pull-left">{date_time}</span>
      <span className="price pull-right">{type + SessionService.formatAmount(value)}</span>
      <span className="title">{getTitle()}</span>
    </div>
  );
};

ActivitiesHistoryItem.propTypes = {
  transaction: PropTypes.object.isRequired,
  key: PropTypes.string.isRequired,
};

ActivitiesHistoryItem.className = 'ActivitiesHistoryItemComponent';
export default FunctionComponentFactory.get(ActivitiesHistoryItem);
