import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {cloneDeep} from 'lodash';
// import {Button} from 'react-bootstrap';


import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import '../../../../assets/Catalog.css';
import '../../../../assets/Collection.css';
import '../../../../assets/CatalogSearch.css';
import layout from '../../../../framework/Layout';
import ProductConstant from '../../../constant/ProductConstant';
import ProductAction from '../../../action/ProductAction';
import ExternalStock from '../stock/ExternalStock.jsx';
import ProductService from '../../../../service/product/ProductService';
import QuoteAction from '../../../action/checkout/QuoteAction';

import Product from './product/Product.jsx';
import ProductSearch from './ProductSearch.jsx';
import CollectionListPopup from './CollectionListPopup.jsx';
import ViewProduct from './product/ViewProduct.jsx';

function ProductList() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {products, pageInfo, isLoading} = useSelector((state) => state.core.product.productList);
  const [items, setItems] = useState([]);
  const [startCursor, setStartCursor] = useState(null);

  const [searchKey, setSearchKey] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const searchBoxRef = useRef();

  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);
  function showPopup() {
    setIsOpenPopup(true);
  }

  const canLoadMore = () => {
    return !pageInfo || (pageInfo && pageInfo.hasNextPage);
  };

  const loadProduct = (pageAction = ProductConstant.ACTION_FIRST_PAGE) => {
    if (pageAction !== ProductConstant.ACTION_FIRST_PAGE && !canLoadMore()) {
      return;
    }
    if (pageAction === ProductConstant.ACTION_FIRST_PAGE) {
      setStartCursor(null);
      setItems([]);
    }
    const variables = {};
    switch (pageAction) {
      case ProductConstant.ACTION_NEXT_PAGE: {
        variables.pageSize = ProductConstant.PAGE_SIZE;
        variables.current = pageInfo.endCursor;
        variables.pageAction = ProductConstant.ACTION_NEXT_PAGE;
        break;
      }
      case ProductConstant.ACTION_PREVIOUS_PAGE: {
        variables.pageSize = ProductConstant.PAGE_SIZE;
        variables.current = pageInfo.startCursor;
        variables.pageAction = ProductConstant.ACTION_PREVIOUS_PAGE;
        break;
      }
      default: {
        variables.pageSize = ProductConstant.PAGE_SIZE;
        variables.pageAction = ProductConstant.ACTION_FIRST_PAGE;
      }
    }

    if (selectedCollection && selectedCollection?.id) {
      setSearchKey("");
      dispatch(ProductAction.getProductsByCollectionId(selectedCollection.id, variables));
      // "products" field in collections query doesn't have "query" agrument
    } else {
      let query = `gift_card:false`;
      if (searchKey) {
        query += ` AND ${searchKey}`;
      }
      variables.query = query;
      dispatch(ProductAction.getProducts(variables));
    }

  };

  useEffect(() => {
    loadProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, selectedCollection]);

  useEffect(() => {
    setIsSearching(false);
  }, [selectedCollection]);

  useEffect(() => {
    if (!pageInfo) {
      setItems([]);
      setStartCursor(null);
      return;
    }
    if (pageInfo && (pageInfo.startCursor === startCursor)) {
      return;
    }
    const newItems = [
      ...items,
      ...cloneDeep(products),
    ];
    setItems(newItems);
    const newStartCursor = pageInfo ? pageInfo.startCursor : null;
    setStartCursor(newStartCursor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, pageInfo]);

  /**
   * Check product list items is empty and is not loading products
   *
   * @return {boolean}
   */
  const isEmptyItems = useCallback(() => {
    return !items.length && !isLoading;
  }, [items, isLoading]);

  /**
   * Get product key when render product list
   *
   * @param product
   * @return {*}
   */
  const getProductKey = (product) => {
    return product.id + product.updatedAt;
  };

  const handleScrollList = (event) => {
    const element = event.target;
    if (!isLoading && (element.scrollHeight - (element.scrollTop + element.clientHeight)) < 50) {
      loadProduct(ProductConstant.ACTION_NEXT_PAGE);
    }
  };

  const clickSearchBox = () => {
    if (!isSearching) {
      setIsSearching(true);
    }
  };

  /**
   * Blur search product input
   */
  const blurSearchBox = () => {
    if (!searchKey) {
      setIsSearching(false);
    }
  };

  const changeSearchKey = (searchText) => {
    setSelectedCollection(null);
    setSearchKey(searchText);
  };

  /**
   * Cancel Searching
   */
  const cancelSearching = () => {
    setIsSearching(false);
    changeSearchKey('');
  };

  /**
   * Add product to cart
   *
   * @param product
   * @return {boolean}
   */
  const addProduct = (product) => {
    if (!product || !product.id) {
      return;
    }
    searchBoxRef.current.blur();
    if (!ProductService.isSalable(product)) {
      return false;
    }
    dispatch(QuoteAction.addProductToCurrentQuote(product));
  };


  const collectionPopupTriggerClassName = isOpenPopup ? "category-results dl-trigger dl-active" : "category-results dl-trigger";
  return (
    <>
      <div className="wrapper-header product-list-wrapper-header">
        <div className="header-right">
          <div className="category-product-container">
            <div
              className={collectionPopupTriggerClassName}
              data-toggle="modal" data-target="#popup-drop-category" onClick={showPopup} onKeyDown={() => showPopup()}
            >
              <span className="text">{t(selectedCollection?.title ? selectedCollection.title : "All Products")}</span>
            </div>
          </div>
          <CollectionListPopup
            isOpenPopup={isOpenPopup}
            setIsOpenPopup={setIsOpenPopup}
            setSelectedCollection={setSelectedCollection}
            selectedCollection={selectedCollection}
          />

          <ProductSearch
            clickSearchBox={clickSearchBox}
            blurSearchBox={blurSearchBox}
            changeSearchKey={changeSearchKey}
            cancelSearching={cancelSearching}
            isSearching={isSearching}
            setIsSearching={setIsSearching}
            searchKey={searchKey}
            searchBoxRef={searchBoxRef}
          />
        </div>
        {layout('checkout')('catalog')('catalog_product_list')('product_list_header_right_after')()()}
      </div>
      <div className="wrapper-content" onScroll={handleScrollList}>
        <div className="catalog-list">
          <div className="search-no-result" style={{display: (isEmptyItems() ? '' : 'none')}}>
            {t("We couldn't find any records.")}
          </div>
          <ul className="product-items" style={{display: (isEmptyItems() ? 'none' : '')}}>
            {
              items.map((product) => {
                return (
                  <Product
                    key={getProductKey(product)}
                    product={product}
                    addProduct={addProduct}
                  />
                );
              })
            }
          </ul>
          <div
            className="product-items loader-product"
            style={{display: (isLoading ? 'block' : 'none')}}
          />
        </div>
      </div>
      <ExternalStock />
      <ViewProduct />
    </>
  );
}

ProductList.className = 'ProductList';
ProductList.propTypes = {};

export default FunctionComponentFactory.get(ProductList);
