import ServiceFactory from "../../../framework/factory/ServiceFactory";
import CoreService from '../../CoreService';
import InventoryConstant
  from '../../../view/constant/product/InventoryConstant';

/**
 * Staff Service Class
 */
export class ProductStockServiceClass extends CoreService {
  static className = 'ProductStockServiceClass';

  /**
   * Get Max Sale Qty
   *
   * @returns {number}
   */
  getMaxSaleQty() {
    return 100000000000000000;
  }

  /**
   * Get Min Sale Qty
   * @returns {number}
   */
  getMinSaleQty() {
    return 1;
  }

  /**
   * Is Manage Stock
   * @param product
   * @returns {*}
   */
  isManageStock(product) {
    return product.tracksInventory;
  }

  /**
   * Get Inventory Item Of The Variant
   * @param variant
   * @returns {{inventoryLevel: [string,{quantities: string[]}]}}
   */
  getInventoryItem(variant) {
    return variant.inventoryItem;
  }

  /**
   * Get Inventory Level Of The Variant
   * @param variant
   * @returns {[string,{quantities: string[]}]}
   */
  getInventoryLevel(variant) {
    const inventoryItem = this.getInventoryItem(variant);
    return inventoryItem.inventoryLevel;
  }

  /**
   * Get Available Qty
   *
   * @param variant
   * @returns {number}
   */
  getAvailableQty(variant) {
    const inventoryLevel = this.getInventoryLevel(variant);
    if (!inventoryLevel) {
      return 0;
    }
    let availableQty = 0;
    const availableQuantity = inventoryLevel.quantities.find((item) => item.name === InventoryConstant.AVAILABLE_QUANTITY);
    if (availableQuantity) {
      availableQty = availableQuantity.quantity;
    }
    return availableQty;
  }

  /**
   * Get On Hand Qty
   *
   * @param variant
   * @returns {number}
   */
  getOnHandQty(variant) {
    const inventoryLevel = this.getInventoryLevel(variant);
    if (!inventoryLevel) {
      return 0;
    }
    let onHandQty = 0;
    const onHandQuantity = inventoryLevel.quantities.find((item) => item.name === InventoryConstant.ON_HAND_QUANTITY);
    if (onHandQuantity) {
      onHandQty = onHandQuantity.quantity;
    }
    return onHandQty;
  }


  /**
   * Is Requires Shipping
   * @param variant
   * @returns {*}
   */
  isRequiresShipping(variant) {
    const inventoryItem = this.getInventoryItem(variant);
    return inventoryItem.requiresShipping;
  }

  /**
   * Get Qty increment of product
   *
   * @return {*}
   */
  getQtyIncrement() {
    return 1;
  }

  /**
   * Is Qty Decimal
   * @returns {boolean}
   */
  isQtyDecimal() {
    return false;
  }
}


/**
 * @type {ProductStockServiceClass}
 */
const ProductStockService = ServiceFactory.get(ProductStockServiceClass);

export default ProductStockService;
