import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Portal} from 'react-portal';
import styled, {createGlobalStyle, ThemeProvider} from 'styled-components';
import Slide from '@mui/material/Slide';

import {PopoverInputField, Wrapper} from '../elements';
import globalCSS from '../styles/global-css';
import styles from '../styles';
import updateCoords, {HEIGHT, MAX_BOTTOM, MIN_TOP, toCss, WIDTH} from '../helper';

const GlobalStyle = createGlobalStyle`${globalCSS}`;

export const Faded = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  display: block;
  z-index: 9998;
  background-color: #000;
  position: fixed;
  box-sizing: border-box;
  line-height: 21.4286px;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  transition-delay: 0s;
  transition-duration: 0.15s;
  transition-property: opacity;
  transition-timing-function: linear;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

export const getTransition = (show, position) => {
  let transition = Slide;
  let transitionProps = {
    in: show,
    direction: 'up',
    mountOnEnter: true,
    unmountOnExit: true,
  };
  if (position === 'flex-start') {
    transitionProps.direction = 'down';
  }
  if (position !== 'flex-start' && position !== 'flex-end') {
    transition = 'span';
    transitionProps = {};
  }
  return {transition, transitionProps};
};

export default ({
                    element,
                    validation,
                    formatInputValue,
                    displayRule,
                    inputButtonContent,
                    keyValid,
                }) => {
  class PopoverNumPad extends Component {
    constructor(props) {
      super(props);
      this.state = {
        show: false,
                value: formatInputValue(props.value), //eslint-disable-line
      };
      this.toggleKeyPad = this.toggleKeyPad.bind(this);
      this.confirm = this.confirm.bind(this);
      this.update = this.update.bind(this);
    }

      componentDidUpdate(prevProps, prevState, snapshot) {
          if (prevProps.value !== this.props.value) {
              this.setState({
                  value: formatInputValue(this.props.value), //eslint-disable-line
              });
          }
      }

    toggleKeyPad(coords = {}, parent) {
      const {position, minTop, maxBottom, useParentCoords} = this.props;

      if (useParentCoords && parent) {
        coords = parent;
      }

      const inputCoords =
                !this.state.show && updateCoords[position]
                    ? toCss(coords, position, minTop || (parent ? parent.y : 0), maxBottom, this.props)
                    : undefined;
      this.setState((prevState) => ({show: !prevState.show, inputCoords, coords}));
    }

    update(value) {
      const {onChange} = this.props;
      onChange(value);
    }

    confirm(value) {
      let updateValue = {};
      if (this.state.show && validation(value)) {
        updateValue = {value};
        this.update(value);
      }
      this.setState((prevState) => ({show: !prevState.show, ...updateValue}));
    }

    render() {
      const {show, inputCoords, coords} = this.state;

      const {placeholder, label, theme, position, width, height, value} = this.props;
      const customTheme = typeof theme === 'object' ? theme : styles(theme);
      customTheme.position = position;
      customTheme.coords = inputCoords;

      const display = position !== 'flex-start' && position !== 'flex-end' ? show : true;
      const {transition, transitionProps} = getTransition(show, position);

      return (
        <>
          <GlobalStyle />
          <ThemeProvider theme={customTheme}>
            <PopoverInputField
              placeholder={placeholder}
              showKeyPad={this.toggleKeyPad}
              inputValue={`${value}`}
              displayRule={displayRule}
              label={label}
              disabled={this.state.show}
              buttonContent={inputButtonContent}
            >
              {this.props.children}
            </PopoverInputField>
          </ThemeProvider>
          <Portal>
            {display && <Faded onClick={this.toggleKeyPad}/>}
            {display &&
                        React.createElement(
                            transition,
                            transitionProps,
                          <ThemeProvider theme={customTheme}>
                            <Wrapper show width={width} height={height}>
                              {React.createElement(
                                        element,
                                {
                                  ...this.props,
                                  cancel: this.toggleKeyPad,
                                  confirm: this.confirm,
                                  update: this.update,
                                  eventTypes: ['click', 'touchend'],
                                  value: `${value}`,
                                  coords,
                                  displayRule,
                                  validation,
                                  keyValid,
                                },
                                        null,
                                    )}
                            </Wrapper>
                          </ThemeProvider>,
                        )}
          </Portal>
        </>
      );
    }
    }

  PopoverNumPad.defaultProps = {
    children: undefined,
    placeholder: undefined,
    position: 'flex-end',
    label: undefined,
    theme: undefined,
    locale: 'en',
    value: '',
    min: false,
    max: 99999999.9999,
    sync: false,
    isDecimal: false,
    isSimpleMode: false,
    qtyIncrement: false,
    smartIncrement: false,
    arrow: false,
    terminalAlign: 'center',
    width: WIDTH,
    height: HEIGHT,
    minTop: MIN_TOP,
    maxBottom: MAX_BOTTOM,
    onChangeOverMax: () => {
    },
    onChangeOverMin: () => {
    },
  };
  PopoverNumPad.propTypes = {
    onChange: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.element)]),
    placeholder: PropTypes.string,
    position: PropTypes.string,
    label: PropTypes.string /* eslint-disable */,
        locale: PropTypes.string,
        theme: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        min: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
        max: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
        sync: PropTypes.bool,
        decimalSeparator: PropTypes.string,
        isDecimal: PropTypes.bool,
        isSimpleMode: PropTypes.bool,
        qtyIncrement: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
        smartIncrement: PropTypes.bool,
        arrow: PropTypes.oneOf(['left', 'right', false]),
        terminalAlign: PropTypes.oneOf(['left', 'right', 'center']),
        width: PropTypes.number,
        height: PropTypes.number,
        minTop: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
        maxBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
        onChangeOverMax: PropTypes.func,
        onChangeOverMin: PropTypes.func /* eslint-enable */,
  };

  return PopoverNumPad;
};
