import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from './GraphqlAbstract';

export class GraphqlCustomerClass extends GraphqlAbstract {
  static className = 'GraphqlCustomerClass';

  /**
   * Get Customers
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getCustomers(input) {
    return this.queryWithGQLBuilder(
      'getCustomers',
      {
        input: {
          name: 'input',
          type: 'GetCustomersInput',
          value: input,
          required: true,
        },
      },
      [
        {
          items: [
            'id',
            'displayName',
            'phone',
            'firstName',
            'lastName',
            'tags',
            'email',
            {
              defaultAddress: [
                'id',
                'address1',
                'address2',
                'city',
                'country',
                'countryCode',
                'firstName',
                'lastName',
                'phone',
                'province',
                'provinceCode',
                'zip',
                'company',
              ],
            },
          ],
        },
        {
          pageInfo: [
            'hasNextPage',
            'endCursor',
            'hasPreviousPage',
            'startCursor',
          ],
        },
      ],
      {
        operationName: 'getCustomers',
      },
    );
  }

  /**
   * Save customer
   * @param customer
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  saveCustomer(customer) {
    return this.mutationWithGQLBuilder(
      'saveCustomer',
      {
        input: {
          name: 'input',
          type: 'SaveCustomerInput!',
          value: customer,
        },
      },
      [
        {
          customer: [
            'id',
            'email',
            'displayName',
            'phone',
            'firstName',
            'lastName',
            'tags',
            {
              defaultAddress: [
                'id',
                'address1',
                'address2',
                'city',
                'country',
                'countryCode',
                'firstName',
                'lastName',
                'phone',
                'province',
                'provinceCode',
                'zip',
                'company',
              ],
            },
            {
              addresses: [
                'id',
                'address1',
                'address2',
                'city',
                'country',
                'countryCode',
                'firstName',
                'lastName',
                'phone',
                'province',
                'provinceCode',
                'zip',
                'company',
              ],
            },
          ],
        },
        {
          userErrors: [
            'field',
            'message',
          ],
        },
      ],
      {
        operationName: 'saveCustomer',
      },
    );
  }


  /**
   * Get customer by id
   * @param customerId
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getCustomer(customerId) {
    return this.queryWithGQLBuilder(
      'getCustomer',
      {
        id: {
          name: 'id',
          type: 'String!',
          value: customerId,
        },
      },
      [
        'id',
        'email',
        'displayName',
        'phone',
        'firstName',
        'lastName',
        'tags',
        {
          addresses: [
            'id',
            'address1',
            'address2',
            'city',
            'country',
            'countryCode',
            'firstName',
            'lastName',
            'phone',
            'province',
            'provinceCode',
            'zip',
            'company',
          ],
        },
        {
          defaultAddress: [
            'id',
            'address1',
            'address2',
            'city',
            'country',
            'countryCode',
            'firstName',
            'lastName',
            'phone',
            'province',
            'provinceCode',
            'zip',
            'company',
          ],
        },
      ],
      {
        operationName: 'getCustomer',
      },
    );
  }
}

/**
 * @type {GraphqlCustomerClass}
 */
export default DataResourceFactory.get(GraphqlCustomerClass);
