import _ from 'lodash';

import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import StaffService from '../staff/StaffService';
import LocalStorageService from '../LocalStorageService';

/**
 * Location Service Class
 */
export class LocationServiceClass extends CoreService {
  static className = 'LocationServiceClass';

  /**
   * sort array by alphabet
   * @param array
   * @param fieldSort
   * @return {*}
   */
  sortByAlphabet(array, fieldSort) {
    if (array && array.length > 0) {
      let result = [...array];
      if (fieldSort) {
        result = result.sort((param1, param2) => {
          if (typeof param1.status !== 'undefined') {
            if (param1.status < param2.status) { return -1; }
            if (param1.status > param2.status) { return 1; }

          }
          if (param1[fieldSort] < param2[fieldSort]) { return -1; }
          if (param1[fieldSort] > param2[fieldSort]) { return 1; }
          return 0;
        });
      }
      return result;
    }
    return [];
  }

  /**
   * Get pos options by location
   * @param location
   * @return array
   */
  getPosOptionsByLocation(location) {
    let posLocation = _.cloneDeep(location.pos);

    /* sort pos by alphabet*/
    if (posLocation && posLocation.length > 0) {
      posLocation = this.sortByAlphabet(posLocation, "name");
      const enablePos = posLocation.filter((item) => !item.staff_id);
      const posCurrentStaff = posLocation.filter((item) => (item.staff_id > 0 && item.staff_id.toString() === StaffService.getStaffId()));
      const posOtherStaff = posLocation.filter((item) => (item.staff_id > 0 && item.staff_id.toString() !== StaffService.getStaffId()));
      posLocation = [...posCurrentStaff, ...enablePos, ...posOtherStaff];
      posLocation.map((item) => {
        item.value = item.id;
        const staffName = item.staff_id ? ` (${item.staff_name})` : '';
        item.label = `${item.name}${staffName}`;
        if (item.staff_id > 0 && item.staff_id.toString() !== StaffService.getStaffId()) {
          item.isDisabled = Boolean(item.staff_id);
        }
        return item;
      });
    }
    return posLocation;
  }

  /**
   * save location and pos to local storage
   *
   * @param locations
   */
  saveLocations(locations) {
    LocalStorageService.saveLocations(locations);
  }

  /**
   * Get locations from local storage
   * @returns {string | null}
   */
  getLocations() {
    return LocalStorageService.getLocations();
  }

  /**
   * Save current location
   *
   * @param locationId
   */
  saveCurrentLocation(locationId) {
    LocalStorageService.set(LocalStorageService.LOCATION_ID, locationId);
    const locationList = this.getLocations();
    if (locationList) {
      const locationListArray = JSON.parse(locationList);
      const result = locationListArray.find((location) => location.id === locationId);
      if (result) {
        LocalStorageService.set(LocalStorageService.LOCATION_NAME, result.name);
      }
    }
  }

  /**
   * Get Current Location Id
   *
   * @returns {string}
   */
  getCurrentLocationId() {
    return LocalStorageService.get(LocalStorageService.LOCATION_ID);
  }

  /**
   * Get Current Location Name
   *
   * @returns {string}
   */
  getCurrentLocationName() {
    return LocalStorageService.get(LocalStorageService.LOCATION_NAME);
  }

  /**
   * Get current address of this location
   */
  getAddressCurrentLocation() {
    const currentLocationId = this.getCurrentLocationId();
    const locationList = this.getLocations();
    if (locationList) {
      const locationListArray = JSON.parse(locationList);
      const result = locationListArray.find((location) => location.id === currentLocationId);
      if (result) {
        return result.address;
      }
    }
    return null;
  }

  /**
   * Get Tail Current Location Id
   * @returns {String}
   */
  getTailCurrentLocationId() {
    const location_id = this.getCurrentLocationId();
    const lastIndex = location_id.lastIndexOf('/');
    return location_id.slice(lastIndex + 1);
  }

  /**
   * Check current location has fulfill online orders
   * @returns {boolean}
   */
  isCurrentLocationFulfillOnlineOrder() {
    const currentLocationId = this.getCurrentLocationId();
    const locationList = this.getLocations();
    if (locationList) {
      const locationListArray = JSON.parse(locationList);
      const result = locationListArray.find((location) => location?.id === currentLocationId);
      if (result) {
        return result?.fulfillsOnlineOrders;
      }
    }
    return null;
  }

}


/**
 * @type {LocationServiceClass}
 */
const locationService = ServiceFactory.get(LocationServiceClass);

export default locationService;
