import DataResourceFactory from "../../framework/factory/DataResourceFactory";
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';

import OmcAbstract from "./OmcAbstract";
import GraphqlProduct from './graphql/GraphqlProduct';

export class OmcProduct extends OmcAbstract {
  static className = 'OmcProduct';

  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlProduct,
  };

  /**
   * Get Products
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProducts(input) {
    return this.getApiResource().getProducts(input);
  }

  /**
   * Get product options
   * @param id
   * @param isIncludeCollection
   * @returns {*}
   */
  getProductOptions(id, isIncludeCollection = false) {
    const resource = this.getApiResource();
    return resource.getProductOptions(id, isIncludeCollection);
  }

  /**
   * Get Product With One Variant
   *
   * @param id
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProductWithOneVariant(id) {
    return this.getApiResource().getProductWithOneVariant(id);
  }

  /**
   * Search By Barcode
   * @param query
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  searchByBarcode(query) {
    return this.getApiResource().searchByBarcode(query);
  }

  /**
   * get products by collection id
   * @param collectionId
   * @param productInput
   * @returns {Promise<ApolloQueryResult<*>>}
   */

  getProductsByCollectionId(collectionId, productInput) {
    return this.getApiResource().getProductsByCollectionId(collectionId, productInput);
  }

  /**
   * Get Product Variant By Product Options
   * @param productId
   * @param selectedOptions
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProductVariantByProductOptions(productId, selectedOptions) {
    return this.getApiResource().getProductVariantByProductOptions(productId, selectedOptions);
  }

  /**
   * Get Product Variant By Their Ids
   * @param variantIds
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProductVariants(variantIds) {
    return this.getApiResource().getProductVariants(variantIds);
  }

  /**
   * Get Product Variant By Ids Variant
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getQuantityProductVariant(input) {
    return this.getApiResource().getQuantityProductVariant(input);
  }
}

/**
 * @type {OmcProduct}
 */
export default DataResourceFactory.get(OmcProduct);
