import {toast} from "react-toastify";

import OrderConstant from "../../../constant/OrderConstant";
import OrderRefundConstant from "../../../constant/order/refund/OrderRefundConstant";
import CustomDiscountConstant from "../../../constant/checkout/quote/CustomDiscountConstant";
import i18n from "../../../../config/i18n";

const initialState = {
  isOpenRefund: false,
  step: OrderRefundConstant.REFUND_STEP.ITEMS,
  total: 0,
  totalAfterSelectItems: 0,
  isMaxQtyRefund: false,
  listSelectedItems: [],
  listRefundPayment: [],
  adjustments: {
    Refund: {key: 'Refund', label: 'Refund Amount', labelInTotalCart: 'Adjustment Refund', amount: 0, type: CustomDiscountConstant.SHOPIFY_DISCOUNT_TYPE_FIXED},
    Shipping: {key: 'Shipping', label: 'Refund Shipping', labelInTotalCart: 'Refund Shipping', amount: 0, type: CustomDiscountConstant.SHOPIFY_DISCOUNT_TYPE_FIXED},
  },
  isLoading: false,
};
const orderRefundReducer = (state = initialState, action) => {
  switch (action.type) {
    case OrderConstant.HANDLE_OPEN_REFUND:
      return {
        ...state,
        isOpenRefund: true,
        isLoading: false,
      };
    case OrderConstant.HANDLE_CLOSE_REFUND:
      return {
        ...initialState,
        isOpenRefund: false,
      };
    case OrderConstant.HANDLE_OPEN_ADJUSTMENTS:
      return {
        ...state,
        step: OrderRefundConstant.REFUND_STEP.ADJUSTMENTS,
      };
    case OrderConstant.SET_CURRENT_TOTAL_WHEN_SELECT_ITEMS:
      return {
        ...state,
        total: parseFloat(action.total),
        totalAfterSelectItems: parseFloat(action.totalAfterSelectedItems),
      };
    case OrderConstant.SET_CURRENT_TOTAL:
      return {
        ...state,
        total: parseFloat(action.total),
      };
    case OrderConstant.HANDLE_OPEN_SELECT_ITEM_REFUND:
      return {
        ...state,
        step: OrderRefundConstant.REFUND_STEP.ITEMS,
      };
    case OrderConstant.UPDATE_LIST_SELECTED_ITEMS:
      return {
        ...state,
        listSelectedItems: action.listUpdated,
      };
    case OrderConstant.UPDATE_LIST_ADJUSTMENTS:
      return {
        ...state,
        adjustments: action.adjustments,
        total: parseFloat(action.adjustments.Refund.amount),
      };
    case OrderConstant.SET_IS_MAX_QTY_REFUND:
      return {
        ...state,
        isMaxQtyRefund: action.isMaxQtyRefund,
        listSelectedItems: action.itemsCanRefund,
      };
    case OrderConstant.UPDATE_IS_MAX_QTY_REFUND:
      return {
        ...state,
        isMaxQtyRefund: action.isMaxQtyRefund,
      };
    case OrderConstant.HANDLE_OPEN_COMPLETE_REFUND:
      return {
        ...state,
        step: OrderRefundConstant.REFUND_STEP.COMPLETE,
      };
    case OrderConstant.HANDLE_UPDATE_ADJUSTMENT_REFUND_AMOUNT:
      return {
        ...state,
        adjustments: {
          ...state.adjustments,
          Refund: {
            ...state.adjustments.Refund,
            amount: action.amount,
          },
        },
        total: action.amount,
      };
    case OrderConstant.CREATE_REFUND_FAILED:
      if (action.error.length > 0) { toast.error(action.error); }
      return {
        ...initialState,
      };
    case OrderConstant.CREATE_REFUND_SUCCESS:
      toast.success(i18n.t('Refund order successfully'));
      return {
        ...state,
        step: OrderRefundConstant.REFUND_STEP.SUCCESS,
        listRefundPayment: action.listRefundPayment,
        isLoading: false,
      };
    case OrderConstant.SET_IS_LOADING_REFUND:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};

export default orderRefundReducer;
