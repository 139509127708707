import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';

function CustomerComboBoxSuggestComponent(props) {
  const {oneRow, code, options, setFieldValueByCode, value, label} = props;
  const {t} = useTranslation();
  const className = oneRow ? "col-sm-12" : "col-sm-6";
  const removeTag = (item) => {
    const newValue = _.cloneDeep(value);
    const index = newValue.indexOf(item);
    if (index > -1) {
      newValue.splice(index, 1);
      setFieldValueByCode(code.replace('_suggest', ''), newValue);
    }
  };
  const isSelectedTag = (tag) => {
    if (value && value.includes(tag)) {
      return 'combobox-item combobox-item-selected';
    } else {
      return 'combobox-item';
    }
  };
  return (
    <>
      <div id={`${code}_option_root`} className={className}>
        <ul className="list-combobox">
          {
            value && value.map(
              (item) => {
                return (
                  <li key={`tag_${item}`} className="combobox-item">
                    {item}
                    {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                    <a className="combobox-item-delete" onClick={() => { removeTag(item); }}>×</a>
                  </li>
                );
              },
            )
          }
        </ul>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label>{ t('Add existing {{label}}:', {label: label.toLowerCase()})}</label>
        <ul className="list-combobox">
          {
            options.map(
              (option) => {
                return (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                  <li
                    className={isSelectedTag(option.value)}
                    key={`exist-tag-${option.value}`}
                    onClick={() => { setFieldValueByCode(code.replace('_suggest', ''), option.value, true); }}
                  >
                    {option.label}
                  </li>
                );
              },
            )
          }
        </ul>
      </div>
    </>

  );
}

CustomerComboBoxSuggestComponent.className = 'CustomerComboBoxSuggestComponent';
CustomerComboBoxSuggestComponent.propTypes = {
  oneRow: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  code: PropTypes.string,
  setFieldValueByCode: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  label: PropTypes.string,
};

export default React.memo(FunctionComponentFactory.get(CustomerComboBoxSuggestComponent));
