import {toast} from 'react-toastify';
import _ from 'lodash';

import StaffConstant from "../constant/StaffConstant";
import StaffService from '../../service/staff/StaffService';
import LocalStorageService from '../../service/LocalStorageService';
import Config from '../../config/Config';
import i18n from '../../config/i18n';
import ActionFactory from '../../framework/factory/ActionFactory';
import ApiResponseConstant from '../constant/ApiResponseConstant';

import LoadingAction from './LoadingAction';

export class StaffActionClass {

  /**
   * Login
   *
   * @param username
   * @param password
   * @param shop
   * @returns {(function(*): Promise<void>)|*}
   */
  login(username, password, shop = '') {
    return async (dispatch) => {
      dispatch({
        type: StaffConstant.STAFF_LOGIN,
        username,
        password,
        shop,
      });
      try {
        const response = await StaffService.login(username, password, shop);
        if (response && response.data) {
          dispatch(this.getLoginSuccess(response));
          LocalStorageService.setToken(response.data.loginToPos.token);
          LocalStorageService.saveLocations(JSON.stringify(response.data.loginToPos.sources));
          StaffService.saveStaff(
            response.data.loginToPos.staff_id,
            response.data.loginToPos.name,
            response.data.loginToPos.email,
            username,
            shop,
          );
        } else {
          dispatch(this.getLoginFailed(response.error));
        }
      } catch (error) {
        dispatch(this.getLoginFailed(error.message));
      }
    };
  }

  /**
   * Get login success
   *
   * @param response
   * @returns {{type: string}}
   */
  getLoginSuccess(response) {
    return {
      type: StaffConstant.STAFF_LOGIN_SUCCESS,
      response,
    };
  }

  /**
   * Reset login state
   *
   * @returns {{type: string}}
   */
  resetLoginState() {
    return {
      type: StaffConstant.RESET_LOGIN_STATE,
    };
  }

  /**
   * Get login Failed
   *
   * @param error
   * @returns {{type: string, error}}
   */
  getLoginFailed(error) {
    return {
      type: StaffConstant.STAFF_LOGIN_FAILED,
      error,
    };
  }

  closePopup() {
    return {
      type: StaffConstant.STAFF_CLOSE_POPUP,
    };
  }

  /**
   * Get Permissions
   * @param atLoadingPage
   * @returns {(function(*): Promise<void>)|*}
   */
  getPermissions(atLoadingPage = false) {
    return async (dispatch) => {
      dispatch({
        type: StaffConstant.GET_PERMISSIONS,
        atLoadingPage,
      });
      try {
        const result = await StaffService.getPermissions();
        if (result && result.data && result.data.getPermissions) {
          const permissions = result.data.getPermissions;
          await StaffService.savePermissionsToLocalStorage(permissions);
          Config.permissions = permissions;
          if (atLoadingPage) {
            dispatch(LoadingAction.updateFinishedList(StaffConstant.TYPE_GET_PERMISSIONS));
          }
          dispatch(this.getPermissionsSuccess(permissions));
          return;
        }
        toast.error(
          i18n.t("Failed to get permissions data. Please contact technical support."),
        );
        dispatch(this.getPermissionsFailed(result));
      } catch (error) {
        let isContinueToLogin = false;
        if (LocalStorageService.getToken()) {
          if (_.isArray(error) && error.length) {
            if (error[0].message === ApiResponseConstant.STATUS_CODE_CONTINUE_LOGIN || error[0].message === ApiResponseConstant.STATUS_CODE_NOT_SELECT_POS) {
              isContinueToLogin = true;
            }
          }
          if (!isContinueToLogin) {
            toast.error(
              i18n.t("Failed to get permissions data. Please contact technical support."),
            );
          }
        }
        dispatch(this.getPermissionsFailed(error.message));
      }
    };
  }

  /**
   * Get Permissions Success
   * @param permissions
   * @returns {{permissions: *[], type: string}}
   */
  getPermissionsSuccess(permissions = []) {
    return {
      type: StaffConstant.GET_PERMISSIONS_SUCCESS,
      permissions,
    };
  }

  /**
   * Get Denominations Failed
   * @param error
   * @returns {{type: string, error}}
   */
  getPermissionsFailed(error) {
    return {
      type: StaffConstant.GET_PERMISSIONS_FAILED,
      error,
    };
  }

  /**
   * Logout
   *
   * @returns {(function(*): Promise<void>)|*}
   */
  logout() {
    return async (dispatch) => {
      dispatch({
        type: StaffConstant.STAFF_LOGOUT,
      });
      try {
        const response = await StaffService.logout();
        dispatch(this.getLogoutSuccess(response));
      } catch (error) {
        dispatch(this.getLogoutFailed(error.message));
      }
    };
  }

  /**
   * Get logout success
   *
   * @param response
   * @returns {{type: string}}
   */
  getLogoutSuccess(response) {
    return {
      type: StaffConstant.STAFF_LOGOUT_SUCCESS,
      response,
    };
  }

  /**
   * Get logout Failed
   *
   * @param error
   * @returns {{type: string, error}}
   */
  getLogoutFailed(error) {
    return {
      type: StaffConstant.STAFF_LOGOUT_FAILED,
      error,
    };
  }

  /**
   * Reset logout state
   *
   * @returns {{type: string}}
   */
  resetLogoutState() {
    return {
      type: StaffConstant.RESET_LOGOUT_STATE,
    };
  }

  /**
   * Set signout message
   *
   * @returns {{type: string}}
   */
  setSignoutMessage(message) {
    return {
      type: StaffConstant.STAFF_SET_SIGN_OUT_MESSAGE,
      message,
    };
  }

  /**
   * Continue to login action
   * @returns {(function(*): Promise<void>)|*}
   */
  continueLogin() {
    return async (dispatch) => {
      dispatch({
        type: StaffConstant.USER_CONTINUE_LOGIN,
      });
      try {
        const response = await StaffService.continueLogin();
        dispatch(this.getContinueLoginSuccess(response));
      } catch (error) {
        dispatch(this.getContinueLoginFailed(error.message));
      }
    };
  }

  /**
   * Get continue to login success
   *
   * @param response
   * @returns {{type: string}}
   */
  getContinueLoginSuccess(response) {
    return {
      type: StaffConstant.USER_CONTINUE_LOGIN_SUCCESS,
      response,
    };
  }

  /**
   * Get continue to login failed
   *
   * @param error
   * @returns {{type: string, error}}
   */
  getContinueLoginFailed(error) {
    return {
      type: StaffConstant.USER_CONTINUE_LOGIN_FAIL,
      error,
    };
  }

  /**
   * Reset continue to login state
   *
   * @returns {{type: string}}
   */
  resetContinueLogin() {
    return {
      type: StaffConstant.RESET_CONTINUE_TO_LOGIN_STATE,
    };
  }
}

const StaffAction = ActionFactory.get(StaffActionClass);
export default StaffAction;
