import React, {useEffect} from 'react';
import "../../../assets/Session.css";
import {useDispatch, useSelector} from 'react-redux';

import FunctionComponentFactory
  from '../../../framework/factory/FunctionComponentFactory';
import OpenMenuButton from '../menu/OpenMenuButton.jsx';
import SessionService from '../../../service/session/SessionService';
import SessionConstant from '../../constant/SessionConstant';
import SessionAction from '../../action/SessionAction';
import PermissionService from '../../../service/permission/PermissionService';
import PermissionConstant from '../../constant/PermissionConstant';

import SessionList from './SessionList.jsx';
import OpenSession from './open-session/OpenSession.jsx';
import OpenSessionCashControl from './open-session/OpenSessionCashControl.jsx';
import SessionDetail from './SessionDetail.jsx';
import CloseSessionCashControl from './close-session/CloseSessionCashControl.jsx';
import CloseSession from './close-session/CloseSession.jsx';
import ValidateSession from './close-session/ValidateSession.jsx';
import PutMoneyIn from './session-detail/PutMoneyIn.jsx';
import TakeMoneyOut from './session-detail/TakeMoneyOut.jsx';
import ModalNoPermission from './modal-session/ModalNoPermission.jsx';

const SessionManagement = () => {
  const dispatch = useDispatch();

  const {typeSessionPopup} = useSelector((state) => state.core.session.sessionList);

  useEffect(() => {

    const needOpenSession = SessionService.needOpenSession();

    if (needOpenSession) {
      dispatch(SessionAction.setTypeSessionPopup());
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSessionModal = () => {
    const needOpenSession = SessionService.needOpenSession();

    switch (typeSessionPopup) {
      case SessionConstant.POPUP_OPEN_SESSION_CASH_CONTROL:
        if (!needOpenSession || !PermissionService.isAllowed(PermissionConstant.PERMISSION_SESSION_OPEN_AND_CLOSED)) {
          return;
        }
        return (<OpenSessionCashControl />);

      case SessionConstant.POPUP_OPEN_SESSION:
        if (!needOpenSession || !PermissionService.isAllowed(PermissionConstant.PERMISSION_SESSION_OPEN_AND_CLOSED)) {
          return;
        }
        return (<OpenSession />);

      case SessionConstant.POPUP_CLOSE_SESSION_CASH_CONTROL:
        return (<CloseSessionCashControl />);

      case SessionConstant.POPUP_CLOSE_SESSION:
        return <CloseSession />;

      case SessionConstant.POPUP_VALIDATE_SESSION:
        return <ValidateSession />;

      case SessionConstant.POPUP_PUT_MONEY_IN:
        return <PutMoneyIn />;

      case SessionConstant.POPUP_TAKE_MONEY_OUT:
        return <TakeMoneyOut />;

      case SessionConstant.POPUP_NO_OPEN_CLOSED_PERMISSION:
        return <ModalNoPermission />;

      default:
        return null;
    }
  };

  return (
    <>
      <div className="wrapper-session">
        <OpenMenuButton />
        <SessionList />
        <SessionDetail />
      </div>
      <div className="wrapper-modal">
        {getSessionModal()}
      </div>
    </>
  );
};

SessionManagement.className = "SessionManagement";
export default FunctionComponentFactory.get(SessionManagement);
