import CollectionConstant from '../../constant/CollectionConstant';

const initialState = {
  collections: [],
  pageInfo: null,
  isLoading: false,
};

/**
 * Receive action from Product Action
 *
 * @param state = {collections: []}
 * @param action
 * @returns {*}
 */
const collectionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CollectionConstant.GET_COLLECTIONS: {
      const newState = {
        ...state,
        isLoading: true,
      };
      if (action.input.pageAction === CollectionConstant.ACTION_FIRST_PAGE) {
        newState.collections = [];
        newState.pageInfo = null;
      }
      return newState;
    }
    case CollectionConstant.GET_COLLECTIONS_SUCCESS: {
      const {
        items,
        pageInfo,
      } = action;
      return {
        ...state,
        collections: items,
        pageInfo,
        isLoading: false,
      };
    }
    case CollectionConstant.GET_COLLECTIONS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default collectionListReducer;
