import Config from '../config/Config';
import {fire} from "../event-bus";
import ObjectManager from "../framework/ObjectManager";
import {IndexedDbErrorLog} from '../data/indexeddb/index';
import {OmcStaff, OmcShop, OmcPos, OmcConfig, OmcDenomination, OmcProduct, OmcStock, OmcCustomer, OmcCollection, OmcDraftOrder, OmcCountry, OmcOrder, OmcSession, OmcShipping, OmcSubscription} from '../data/omc/index';
import IndexedDbCart from "../data/indexeddb/IndexedDbCart";

let resources = {
  IndexedDbErrorLog,
  IndexedDbCart,

  OmcStaff,
  OmcConfig,
  OmcShop,
  OmcDenomination,
  OmcPos,
  OmcProduct,
  OmcStock,
  OmcCustomer,
  OmcCollection,
  OmcDraftOrder,
  OmcCountry,
  OmcOrder,
  OmcSession,
  OmcShipping,
  OmcSubscription,
};

export class SingletonClass {

    /**
     * constructor
     */
  constructor() {
    const eventDataBefore = {
      resources,
    };

    fire('constructor_singleton_before', eventDataBefore);
    resources = eventDataBefore.resources;
  }

    /**
    {
    return new (this.getObject(`Service * get  omc resource by name
     * @param: string
     *
     * @return: object
     */
  getOnline(name) {
    return this.get(Config.platform, name);
  }

    /**
     * get  offline resource by name
     * @param: string
     *
     * @return: object
     */
  getOffline(name) {
    return this.get(Config.db, name);
  }

    /**
     * get object form name and prefix
     * @param: string
     * @param: string
     *
     * @return: object
     *
     */
  get(prefix, name) {
    return ObjectManager.get(resources[prefix + name]);
  }
}

const singleton = new SingletonClass();

export default singleton;
