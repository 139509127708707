import SessionConstant from '../../constant/SessionConstant';

const initialState = {
  sessions: [],
  pageInfo: null,
  totalCount: 0,
  isLoading: false,
  sessionSelected: null,
  isCreateSession: null,
  typeSessionPopup: '',
};

const sessionListReducer = (state = initialState, action) => {
  const subCash = state.sessionSelected?.transaction || [];

  switch (action.type) {
    case SessionConstant.GET_SESSIONS:
      return {
        ...state,
        isLoading: true,
      };
    case SessionConstant.GET_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: action.items,
        pageInfo: action.pageInfo,
        isLoading: false,
        totalCount: action.totalCount,
      };
    case SessionConstant.GET_SESSIONS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SessionConstant.SET_CURRENT_SESSION:
      return {
        ...state,
        sessionSelected: action.sessionSelected,
      };
    case SessionConstant.OPEN_SESSION_SUCCESS:
      return {
        ...state,
        sessions: [action.session],
        isCreateSession: true,
        sessionSelected: action.session,
      };
    case SessionConstant.RESET_IS_CREATE_SESSION:
      return {
        ...state,
        isCreateSession: false,
      };
    case SessionConstant.SET_TYPE_SESSION_POPUP:
      return {
        ...state,
        typeSessionPopup: action.typePopup,
      };
    case SessionConstant.CLOSE_SESSION_SUCCESS:
      return {
        ...state,
        typeSessionPopup: SessionConstant.CLOSE_ALL_POPUP,
        sessionSelected: action.session,
      };
    case SessionConstant.RESET_STATE: {
      return {...initialState};
    }
    case SessionConstant.GET_SESSION_DETAIL_SUCCESS:
      return {
        ...state,
        sessionSelected: action.session,
      };
    case SessionConstant.ADD_TRANSACTION_SUCCESS:
      return {
        ...state,
        typeSessionPopup: SessionConstant.CLOSE_ALL_POPUP,
        sessionSelected: {
          ...state.sessionSelected,
          transaction: [...subCash, action.transaction],
        },
      };
    default:
      return state;
  }
};

export default sessionListReducer;
