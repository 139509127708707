import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import OpenMenuButton from '../menu/OpenMenuButton.jsx';
import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import OrderPaymentAction from "../../action/order/payment/OrderPaymentAction";

import OrderList from './OrderList.jsx';
import OrderDetail from './OrderDetail.jsx';
import OrderCancel from './OrderCancel.jsx';
import OrderFulfill from './order-detail/fulfill/OrderFulfill.jsx';
import OrderTakePayment from "./order-detail/take-payment/OrderTakePayment.jsx";
import OrderRefund from "./order-detail/refund/OrderRefund.jsx";

const OrderHistory = () => {
  const dispatch = useDispatch();
  const {isOpenTakePayment} = useSelector((state) => state.core.order.orderPayment);
  const {isOpenRefund} = useSelector((state) => state.core.order.orderRefund);
  const {order, isOpenFulfillItemPage} = useSelector((state) => state.core.order.orderDetail);
  useEffect(() => {
    dispatch(OrderPaymentAction.setRemaining);
  }, [dispatch]);
  const orderTakePayment = isOpenTakePayment
    ? <OrderTakePayment order={order} />
    : null;

  const orderFulfill = isOpenFulfillItemPage ? <OrderFulfill /> : null;

  const orderRefund = isOpenRefund ? <OrderRefund order={order} /> : null;
  return (
    <>
      <OpenMenuButton />
      <OrderList />
      <OrderDetail />
      <OrderCancel />
      {orderTakePayment}
      {orderFulfill}
      {orderRefund}
    </>
  );
};

OrderHistory.className = "OrderHistory";
export default FunctionComponentFactory.get(OrderHistory);
