import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import DeviceService from '../../../../service/DeviceService';
import SearchConstant from '../../../constant/SearchConstant';
import ScanAction from '../../../action/ScanAction';
import ScanConstant from '../../../constant/ScanConstant';
import ProductAction from "../../../action/ProductAction";

function ProductSearch(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {
    isSearching, setIsSearching, clickSearchBox, blurSearchBox,
    changeSearchKey, cancelSearching, searchKey, searchBoxRef,
  } = props;

  const [searchBoxValue, setSearchBoxValue] = useState(searchKey);
  const {textToSearch} = useSelector((state) => state.core.product.productList);

  const {
    barcodeString: globalBarcodeString,
    scanPage: currentScanPage,
  } = useSelector((state) => state.core.scan);

  const isOffline = false;

  /**
   * check if modal is active or not
   *
   * @return boolean
   */
  const isEnableScanBarcode = () => {
    let result = true;
    const dialogs = document.getElementsByClassName('modal-dialog');
    for (let i = 0; i < dialogs.length; i++) {
      const isVisible = dialogs[i].checkVisibility({
        checkOpacity: true,
        checkVisibilityCSS: true,
      });
      if (isVisible) {
        result = false;
      }
    }
    const catalogInputSearchs = document.getElementsByClassName('catalog-input-search');
    if (catalogInputSearchs.length) {
      const inputSearchVisible = catalogInputSearchs[0].checkVisibility({
        checkOpacity: true,
        checkVisibilityCSS: true,
      });
      if (!inputSearchVisible) {
        result = false;
      }
    }
    return result;
  };

  useEffect(() => {
    if (!globalBarcodeString || currentScanPage !== ScanConstant.SCAN_PAGES.PRODUCT || !isEnableScanBarcode()) {
      return;
    }
    // Do NOT show search string when scanning barcode
    setSearchBoxValue('');
    setIsSearching(false);
    dispatch(ProductAction.searchByBarcode(globalBarcodeString));
    dispatch(ScanAction.setBarcodeString());
  }, [currentScanPage, dispatch, globalBarcodeString, setIsSearching]);

  useEffect(() => {
    if (!textToSearch) {
      return;
    }
    changeSearchKey(textToSearch);
    setIsSearching(true);
    dispatch(ProductAction.setTextToSearch(''));
  }, [changeSearchKey, dispatch, setIsSearching, textToSearch]);

  useEffect(() => {
    dispatch(ScanAction.setScanPage(ScanConstant.SCAN_PAGES.PRODUCT));
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    const newSearchBoxValue = isSearching ? searchKey : '';
    setSearchBoxValue(newSearchBoxValue);
  }, [isSearching, searchKey]);

  const handleOnChange = (event) => {
    setSearchBoxValue(event.target.value);
  };

  const handleOnKeyUp = (event) => {
    if (event.key === SearchConstant.ENTER_KEY && searchKey !== searchBoxValue) {
      changeSearchKey(searchBoxValue);
    }
  };

  const clearSearchBox = () => {
    setSearchBoxValue('');
    searchBoxRef.current.focus();
  };

  const getRemoveButton = () => {
    if (!searchBoxValue) {
      return null;
    }
    return (
      <button
        className="btn-remove"
        type="button"
        onClick={clearSearchBox}
      >
        {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
        <span>remove</span>
      </button>
    );
  };

  const handleCancelSearch = () => {
    setSearchBoxValue('');
    cancelSearching();
  };

  const getCancelButton = () => {
    if (searchKey || isSearching) {
      return (
        <button
          className="btn-cannel" type="button"
          onClick={handleCancelSearch}
        >
          {t('Cancel')}
        </button>
      );
    }
    return null;
  };

  const handleBlurSearchBox = () => {
    if (searchBoxValue) {
      return;
    }
    if (searchKey) {
      cancelSearching();
    } else {
      blurSearchBox();
    }
  };

  const getSearchInput = () => {
    return (
      <input
        type="text"
        className="input-search form-control catalog-input-search"
        aria-label={t("Product search")}
        ref={searchBoxRef}
        onClick={clickSearchBox}
        onBlur={handleBlurSearchBox}
        onKeyUp={handleOnKeyUp}
        onChange={handleOnChange}
        autoComplete="false"
        value={searchBoxValue}
      />
    );
  };

  return (
    <>
      <div className={`catalog-search${DeviceService.isMobile() ? ' mobile' : ''}${isOffline ? '' : ' online'}`}>
        {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
        <div className="toggle-search"><span>search</span></div>
        <div className={`form-search ${isSearching ? 'active' : ''}`}>
          <div className="box-search">
            {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
            <button className="btn-search" type="button"><span>search</span></button>
            {getSearchInput()}
            {getRemoveButton()}
          </div>
          {getCancelButton()}
        </div>
      </div>
    </>
  );
}

ProductSearch.className = 'ProductSearch';
ProductSearch.propTypes = {
  clickSearchBox: PropTypes.func.isRequired,
  blurSearchBox: PropTypes.func.isRequired,
  changeSearchKey: PropTypes.func.isRequired,
  cancelSearching: PropTypes.func.isRequired,
  setIsSearching: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  searchKey: PropTypes.string,
  searchBoxRef: PropTypes.object,
};

export default FunctionComponentFactory.get(ProductSearch);
