const CustomerConstant = {
  GET_CUSTOMERS: '[CUSTOMER] GET_CUSTOMERS',
  GET_CUSTOMERS_SUCCESS: '[CUSTOMER] GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAILED: '[CUSTOMER] GET_CUSTOMERS_FAILED',

  SHOW_EDIT_CUSTOMER_POPUP: '[CUSTOMER] SHOW_EDIT_CUSTOMER_POPUP',
  SHOW_EDIT_CUSTOMER_POPUP_SUCCESS: '[CUSTOMER] SHOW_EDIT_CUSTOMER_POPUP_SUCCESS',
  SHOW_EDIT_CUSTOMER_POPUP_FAIL: '[CUSTOMER] SHOW_EDIT_CUSTOMER_POPUP_FAIL',

  SHOW_CREATE_CUSTOMER_POPUP: '[CUSTOMER] SHOW_CREATE_CUSTOMER_POPUP',
  HIDE_CREATE_CUSTOMER_POPUP: '[CUSTOMER] HIDE_CREATE_CUSTOMER_POPUP',
  SHOW_CUSTOMER_LIST_POPUP: '[CUSTOMER] SHOW_CUSTOMER_LIST_POPUP',
  HIDE_CUSTOMER_LIST_POPUP: '[CUSTOMER] HIDE_CUSTOMER_LIST_POPUP',
  RESET_CUSTOMER_LIST: '[CUSTOMER] RESET_CUSTOMER_LIST',

  SELECT_CUSTOMER: '[CUSTOMER] SELECT_CUSTOMER',

  SAVE_CUSTOMER_POPUP: '[CUSTOMER] SAVE_CUSTOMER_POPUP',
  SAVE_CUSTOMER_POPUP_FAIL: '[CUSTOMER] SAVE_CUSTOMER_POPUP_FAIL',
  SAVE_CUSTOMER_POPUP_SUCCESS: '[CUSTOMER] SAVE_CUSTOMER_POPUP_SUCCESS',
  SAVE_CUSTOMER_POPUP_VALIDATE_FAIL: '[CUSTOMER] SAVE_CUSTOMER_POPUP_VALIDATE_FAIL',

  UPDATE_CURRENT_CUSTOMER_IN_POPUP: '[CUSTOMER] UPDATE_CURRENT_CUSTOMER_IN_POPUP',

  PAGE_SIZE: 16,
  ACTION_FIRST_PAGE: 'FIRST',
  ACTION_NEXT_PAGE: 'NEXT',
  ACTION_PREVIOUS_PAGE: 'PREVIOUS',
};

export default CustomerConstant;
