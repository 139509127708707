import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import FunctionComponentFactory from "../../../../framework/factory/FunctionComponentFactory";
import MultiCartAction from "../../../action/MultiCartAction";
import DateTimeService from "../../../../service/DateTimeService";

function MultipleCart() {
  const dispatch = useDispatch();
  const {quote} = useSelector((state) => state.core.checkout);
  const {currentPage} = useSelector((state) => state.core.checkout.index);
  const {carts, activeCartId} = useSelector((state) => state.core.multiCart);
  useEffect(() => {
    dispatch(MultiCartAction.getListCart());
  }, [dispatch]);

  useEffect(() => {
    dispatch(MultiCartAction.updateCart(quote));
  }, [dispatch, quote]);


  const handleAddCart = () => {
    dispatch(MultiCartAction.addCart());
  };

  /**
   * check current cart is active or not
   * @param cart
   * @returns {boolean}
   */
  const isActiveCart = (cart) => {
    return cart.id === activeCartId;
  };

  /**
   * check allowable use multi cart or not
   * @returns {boolean}
   */
  const canUseMultiCart = () => {
    return currentPage === 'ProductList';
  };

  /**
   * check allowable add multi cart or not
   * @returns {boolean}
   */
  const canAddNewMultiCart = () => {
    return canUseMultiCart() && (quote?.lineItems?.length || !carts.length);
  };

  const buttonAddText = 'add';

  return (
    <>
      <button className="btn btn-add" type="button" onClick={handleAddCart} disabled={!canAddNewMultiCart()}>
        <span>{buttonAddText}</span>
      </button>
      <ul className="multi-order" data-scrollbar>
        {carts.length > 0 &&
            carts.map((cart) => {
              if (!cart) { return null; }
              const isCartActive = isActiveCart(cart);
              const cartClassName = isCartActive ? 'active' : '';
              return (
                <li
                  key={cart.id}
                  className={cartClassName}
                  role="presentation"
                  onClick={() => {
                    dispatch(MultiCartAction.selectCart(cart, currentPage));
                  }}
                >
                  <span className="box">
                    <span className="count">{ cart.count }</span>
                    <span className="time">
                      { DateTimeService.convertTimeStampCart(parseInt(cart.id.substring(10), 10)) }
                    </span>
                  </span>
                </li>
              );
            })
          }
      </ul>
    </>
  );
}

MultipleCart.className = 'MultipleCart';
MultipleCart.propTypes = {};

export default FunctionComponentFactory.get(MultipleCart);
