import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

const clientUrl = process.env.REACT_APP_CLIENT_APP_URL;

let locale = window.navigator.language.toLowerCase();
locale = locale.replace('-', '_');

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: false,
    lng: locale,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    nsSeparator: false,
    keySeparator: false,

    debug: false,

    interpolation: {
      escapeValue: false,
    },

    backend: {
      loadPath: `${clientUrl}locales/${locale}/{{ns}}.json`,
    },
  });


export default i18n;
