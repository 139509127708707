import StaffConstant from "../../constant/StaffConstant";

const initialState = {
  isSuccess: false,
  isLoading: false,
  error: '',
};

/**
 * Login reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case StaffConstant.STAFF_LOGIN:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        error: '',
      };
    case StaffConstant.STAFF_LOGIN_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        error: '',
      };
    case StaffConstant.STAFF_LOGIN_FAILED:
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        error: action.error,
      };
    case StaffConstant.RESET_LOGIN_STATE:
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        error: '',
      };
    case StaffConstant.STAFF_CLOSE_POPUP:
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
};

export default loginReducer;
