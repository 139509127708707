import {cloneDeep, filter} from "lodash";

import ActionFactory from "../../../../framework/factory/ActionFactory";
import PaymentService from "../../../../service/payment/PaymentService";
import OrderConstant from "../../../constant/OrderConstant";
import OrderService from "../../../../service/order/OrderService";

export class OrderPaymentActionClass {

  /**
   * get list payment
   *
   * @returns  {(function(*): Promise<void>)|*}
   */
  getListPayment() {
    return (dispatch) => {
      const listPayment = PaymentService.getListPayment();
      const listPaymentFilter = filter(listPayment, ((payment) => {
        if ((payment.use_pay_later && parseFloat(payment.use_pay_later) !== 0) || (parseFloat(payment.active) !== 1)) {
          return;
        }

        return payment;
      }));
      dispatch(this.getListPaymentSuccess(listPaymentFilter));
    };
  }

  /**
   * accept payment
   *
   * @param selectedPayment
   * @param amount
   * @param referenceNo
   * @returns  {(function(*): Promise<void>)|*}
   */
  handleAcceptPayment(selectedPayment, amount, referenceNo) {
    const paid = {
      selectedPayment,
      amount: parseFloat(amount),
    };
    if (referenceNo && referenceNo.length > 0) { paid.referenceNo = referenceNo; }
    return {
      type: OrderConstant.ACCEPT_PAYMENT,
      paid,
    };
  }

  /**
   * update paid item
   *
   * @returns  {(function(*): Promise<void>)|*}
   * @param amountUpdate
   */
  handleUpdatePaidItem(amountUpdate) {
    return (dispatch, getState) => {
      const {listPaid, currentIndex} = getState().core.order.orderPayment;
      const listPaidTemp = cloneDeep(listPaid);
      listPaidTemp[currentIndex].amount = parseFloat(amountUpdate);
      dispatch(this.updatePaidItem(listPaidTemp));
    };
  }

  /**
   * update paid item
   * @param listPaidUpdate
   *
   * @returns {{type: string}}
   */
  updatePaidItem(listPaidUpdate) {
    return {
      type: OrderConstant.UPDATE_PAID_ITEM,
      listPaidUpdate,
    };
  }

  /**
   * open complete payment component
   *
   * @returns {{type: string}}
   */
  handleOpenCompletePayment() {
    return {
      type: OrderConstant.HANDLE_OPEN_COMPLETE_PAYMENT,
      isOpenCompletePayment: true,
    };
  }

  /**
   * Get list payment success
   *
   * @returns {{type: string}}
   * @param listPayment
   */
  getListPaymentSuccess(listPayment) {
    return {
      type: OrderConstant.GET_LIST_PAYMENT_METHOD_SUCCESS,
      listPayment,
    };
  }

  /**
   * handle open take payment list component
   *
   * @returns {{type: string}}
   * @param isOpenTakePayment
   */
  handleOpenTakePayment(isOpenTakePayment) {
    return {
      type: OrderConstant.HANDLE_OPEN_TAKE_PAYMENT,
      isOpenTakePayment,
    };
  }

  /**
   * handle open take payment list component
   *
   * @returns {{type: string}}
   * @param isOpenListPaymentMethod
   * @param isAddPaymentMethod
   */
  handleOpenListPayment(isOpenListPaymentMethod, isAddPaymentMethod) {
    return {
      type: OrderConstant.HANDLE_OPEN_LIST_PAYMENT,
      isOpenListPaymentMethod,
      isAddPaymentMethod,
    };
  }

  /**
   * handle open edit payment component
   *
   * @returns {{type: string}}
   * @param isOpenEditPayment
   * @param selectedPayment
   */
  handleOpenEditPayment(isOpenEditPayment, selectedPayment) {
    return {
      type: OrderConstant.HANDLE_OPEN_EDIT_PAYMENT,
      isOpenEditPayment,
      selectedPayment,
    };
  }

  /**
   * handle open edit payment component when update payment in complete payment
   *
   * @returns {{type: string}}
   * @param isOpenEditPayment
   * @param selectedPayment
   * @param currentAmount
   * @param index
   */
  handleUpdatePayment(isOpenEditPayment, selectedPayment, currentAmount, index) {
    return {
      type: OrderConstant.HANDLE_OPEN_EDIT_PAYMENT,
      isOpenEditPayment,
      selectedPayment,
      currentAmount,
      index,
    };
  }

  /**
   * handle when back from edit payment
   *
   * @returns {{type: string}}
   */
  handleBackFromEditPayment() {
    return {
      type: OrderConstant.BACK_FROM_EDIT_PAYMENT,
    };
  }

  /**
   * set remaining when go to page
   *
   * @returns {{type: string}}
   */
  setRemaining(remaining) {
    return {
      type: OrderConstant.SET_REMAINING,
      remaining,
    };
  }

  /**
   * delete paid item
   *
   * @returns {function(*): {index: *, type: string}}
   */
  handleRemovePaidItem(indexRemove, listPaid, order) {
    return (dispatch) => {
      const listPaidTemp = cloneDeep(listPaid);
      listPaidTemp.splice(indexRemove, 1);
      dispatch(this.removePaidItem(listPaidTemp, order));
    };
  }

  /**
   * set list paid after remove
   *
   * @returns {{type: string}}
   */
  removePaidItem(listPaidAfterRemove, order) {
    return {
      type: OrderConstant.REMOVE_PAID_ITEM,
      listPaidAfterRemove,
      order,
    };
  }

  /**
   * set record payment
   * @param listPaid
   * @param order
   * @param shop
   * @returns {{type: string}}
   */
  saveRecordPayment(listPaid, order, shop, balance, totalPaid) {
    return async (dispatch) => {
      try {
        dispatch({
          type: OrderConstant.CREATE_RECORD_PAYMENT,
        });

        const session = OrderService.buildInputSessionAddTransaction();

        const response = await OrderService.createRecordPayment(listPaid, {id: order.id, currencyCode: order.totalPriceSet.shopMoney.currencyCode, name: order.name}, shop, session);
        if (response.data.createRecordPayment.result) {
          dispatch(this.saveRecordPaymentSuccessful());
          const updateData = {
            displayFinancialStatus: balance > 0 ? OrderConstant.FINANCIAL_STATUS_PARTIALLY_PAID : OrderConstant.FINANCIAL_STATUS_PAID,
            totalReceivedSet: {
              shopMoney: {
                ...order.totalReceivedSet.shopMoney,
                amount: order.totalReceivedSet.shopMoney.amount + totalPaid,
              },
            },
          };
          dispatch(this.updateOrderData(updateData));
        } else {
          dispatch(this.saveRecordPaymentFailed(response.data.createRecordPayment.error));
        }
      } catch (error) {
        dispatch(this.saveRecordPaymentFailed(error));
      }
    };
  }

  /**
   * save record payment successful
   *
   * @returns {{type: string}}
   */
  saveRecordPaymentSuccessful() {
    return {
      type: OrderConstant.CREATE_RECORD_PAYMENT_SUCCESSFUL,
    };
  }

  /**
   * save record payment failed
   *
   * @returns {{type: string}}
   */
  saveRecordPaymentFailed(error) {
    return {
      type: OrderConstant.CREATE_RECORD_PAYMENT_FAILED,
      error: error.message,
    };
  }

  /**
   * update order in order detail
   *
   * @returns {{type: string}}
   */
  updateOrderData(updateData) {
    return {
      type: OrderConstant.UPDATE_ORDER,
      updateData,
    };
  }

  /**
   * reset state of order take payment component
   *
   * @returns {{type: string}}
   */
  resetState() {
    return {
      type: OrderConstant.RESET_STATE,
    };
  }
}

/**
 * @type {OrderPaymentActionClass}
 */
const OrderPaymentAction = ActionFactory.get(OrderPaymentActionClass);
export default OrderPaymentAction;
