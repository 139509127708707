import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from './GraphqlAbstract';

export class GraphqlStaffClass extends GraphqlAbstract {
  static className = 'GraphqlStaffClass';

  /**
   * Login with username and password
   *
   * @param username
   * @param password
   * @param shop
   *
   * @return promise
   */
  login(username, password, shop = '') {
    const input = {
      username,
      password,
      shop,
    };
    return this.mutationWithGQLBuilder(
      'loginToPos',
      {
        input: {
          name: 'input',
          type: 'LoginToPosInput!',
          value: input,
        },
      },
      [
        'token',
        'staff_id',
        'name',
        'email',
        {
          sources: [
            'id',
            'name',
            'enabled',
            'fulfillsOnlineOrders',
            {
              address: [
                'address1',
                'address2',
                'city',
                'country',
                'countryCode',
                'phone',
                'province',
                'provinceCode',
                'zip',
              ],
            },
            {
              pos: [
                'id',
                'name',
                'status',
                'staff_id',
                'staff_name',
              ],
            },
          ],
        },
      ],
      {
        operationName: 'loginToPos',
      },
    );
  }

  /**
   * Get Permissions
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getPermissions() {
    return this.queryWithGQLBuilder(
      'getPermissions',
      {},
      [],
      {
        operationName: 'getPermissions',
      },
    );
  }

  /**
   * Logout
   *
   * @return promise
   */
  logout() {
    return this.mutationWithGQLBuilder(
      'logout',
      {},
      [
        'token',
      ],
      {
        operationName: 'logout',
      },
    );
  }

  /**
   * Logout
   *
   * @return promise
   */
  continueLogin() {
    return this.mutationWithGQLBuilder(
      'continueLogin',
      {},
      [
        'token',
      ],
      {
        operationName: 'continueLogin',
      },
    );
  }

  /**
   * change password
   *
   * @param username
   * @param currentPassword
   * @param newPassword
   * @param shop
   *
   * @return Promise<ApolloQueryResult<*>>
   */
  changePassword(username, currentPassword, newPassword, shop = '') {
    const input = {
      username,
      currentPassword,
      newPassword,
      shop,
    };

    return this.mutationWithGQLBuilder(
      'changePasswordStaff',
      {
        input: {
          name: 'input',
          type: 'ChangePasswordStaffInput!',
          value: input,
        },
      },
      ['result', 'error'],
      {
        operationName: 'changePasswordStaff',
      },
    );
  }
}

/**
 * @type {GraphqlStaffClass}
 */
export default DataResourceFactory.get(GraphqlStaffClass);
