import ActionFactory from '../../../framework/factory/ActionFactory';
import EditPriceConstant from "../../constant/checkout/EditPriceConstant";

export class EditPriceActionClass {
  saveCustomLineItemDefault(customLineItemDefault) {
    return {
      type: EditPriceConstant.SAVE_CUSTOM_LINE_ITEM,
      customLineItemDefault,
    };
  }

  resetCustomPriceLineItem() {
    return {
      type: EditPriceConstant.RESET_CUSTOM_LINE_ITEM,
    };
  }
}

const EditPriceAction = ActionFactory.get(EditPriceActionClass);
export default EditPriceAction;
