import React from "react";

import FunctionComponentFactory from "../../../framework/factory/FunctionComponentFactory";
import Settings from "../../component/settings/Settings.jsx";

function SettingsPage() {
  return (
    <Settings />
  );
}

SettingsPage.className = 'SettingsPage';
export default FunctionComponentFactory.get(SettingsPage);
