import PaymentConstant from "../../constant/PaymentConstant";
import CheckoutConstant from "../../constant/checkout/CheckoutConstant";

const initialState = {
  payments: [],
  paymentPage: PaymentConstant.PAYMENT_PAGE_SELECT_PAYMENT,
  isAddPaymentMethod: false,
  listPaid: [],
  isUpdate: false,
  isOpenListPayment: true,
  isOpenEditPayment: false,
  isOpenCompletePayment: false,
  remaining: null,
  currentAmount: null,
  currentIndex: null,
};

/**
 * Receive action from Payment Action
 * @param state
 * @param action
 * @returns {*}
 */
const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PaymentConstant.SWITCH_PAGE:
      return {
        ...state,
        paymentPage: action.paymentPage,
      };
    case PaymentConstant.RESET_STATE:
      return initialState;
    case CheckoutConstant.GET_LIST_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        payments: action.listPayment,
      };
    case CheckoutConstant.SELECT_PAYMENT_METHOD:
      return {
        ...state,
        isOpenListPayment: false,
        isOpenEditPayment: true,
        selectedPayment: action.selectedPayment,
      };
    case CheckoutConstant.SET_REMAINING:
      return {
        ...state,
        remaining: action.remaining,
      };
    case CheckoutConstant.SET_DEFAULT_PAYMENT_SCREEN:
      return {
        ...state,
        isOpenListPayment: true,
        isOpenEditPayment: false,
        isOpenCompletePayment: false,
        isAddPaymentMethod: false,
        listPaid: [],
      };
    case CheckoutConstant.HANDLE_OPEN_LIST_PAYMENT:
      return {
        ...state,
        isOpenListPayment: true,
        isOpenEditPayment: false,
        isOpenCompletePayment: false,
        isAddPaymentMethod: true,
      };
    case CheckoutConstant.CLOSE_EDIT_PAYMENT:
      return {
        ...state,
        isOpenEditPayment: false,
        isOpenListPayment: true,
      };
    case CheckoutConstant.ACCEPT_PAYMENT:
      return {
        ...state,
        listPaid: [...state.listPaid, action.paid],
        isOpenEditPayment: false,
        isOpenCompletePayment: true,
        isOpenListPayment: false,
        currentIndex: null,
        currentAmount: null,
      };
    case CheckoutConstant.UPDATE_PAID_ITEM:
      return {
        ...state,
        listPaid: [...action.listPaidUpdate],
        isOpenListPayment: action.listPaidUpdate.length === 0,
        isOpenEditPayment: false,
        isOpenCompletePayment: action.listPaidUpdate.length !== 0,
        remaining: action.listPaidUpdate.length === 0 ? action.quote.totalPrice : state.remaining,
        currentIndex: null,
        currentAmount: null,
        isAddPaymentMethod: action.listPaidUpdate.length !== 0,
      };
    case CheckoutConstant.HANDLE_UPDATE_PAYMENT:
      return {
        ...state,
        isOpenEditPayment: true,
        isOpenListPayment: false,
        isOpenCompletePayment: false,
        selectedPayment: action.selectedPayment,
        currentAmount: action.currentAmount,
        currentIndex: action.index,
      };
    case CheckoutConstant.HANDLE_OPEN_COMPLETE_PAYMENT:
      return {
        ...state,
        isOpenEditPayment: false,
        isOpenListPayment: false,
        isOpenCompletePayment: true,
      };
    case CheckoutConstant.HANDLE_COMPLETED_ORDER_PAYMENT:
      return {
        ...initialState,
        paymentPage: PaymentConstant.PAYMENT_PAGE_COMPLETE_ORDER,
      };
    default:
      return state;
  }
};

export default paymentReducer;
