import React, {useEffect} from 'react';
import SmoothScrollbar from 'smooth-scrollbar';
import {useDispatch, useSelector} from 'react-redux';

import i18n from '../../../config/i18n';
import FunctionComponentFactory
  from '../../../framework/factory/FunctionComponentFactory';
import OrderAction from '../../action/OrderAction';

import OrderInformation from './order-detail/detail-content/OrderInformation.jsx';
import BlockActions from './order-detail/detail-content/BlockActions.jsx';

const OrderDetail = () => {
  const setScrollbarElement = (element) => {
    if (!element) {
      return;
    }
    SmoothScrollbar.init(element);
  };

  const {order: orderDetail, isLoading} = useSelector((state) => state.core.order.orderDetail);
  const {currentOrder, isLoading: isLoadingOrderList} = useSelector((state) => state.core.order.orderList);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentOrder) {
      dispatch(OrderAction.getOrder(currentOrder.id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder]);


  const getPageNotFound = () => {
    if (isLoadingOrderList) {
      return null;
    }

    return (
      <div className="page-notfound">
        <div className="icon" />
        {i18n.t('No order is found.')}
      </div>
    );
  };

  const bodyContent = currentOrder ? <div><OrderInformation orderDetail={orderDetail} /></div> : <div>{getPageNotFound()}</div>;

  if (isLoading) {
    return (
      <div className="wrapper-order-right">
        <div
          className="page-detail loader-product"
        />
      </div>
    );
  }
  const orderName = currentOrder && orderDetail ? orderDetail.name : '';
  return (
    <>
      <div className="wrapper-order-right">
        <div className="block-title">{orderName}</div>
        <div data-scrollbar ref={setScrollbarElement} className="block-content">
          {bodyContent}
        </div>
        {currentOrder && <BlockActions />}
      </div>
    </>
  );
};

OrderDetail.className = 'OrderDetail';
OrderDetail.propTypes = {
};

export default FunctionComponentFactory.get(OrderDetail);
