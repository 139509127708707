import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {Form, Overlay, Popover} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';
import CurrencyService from '../../../../../service/CurrencyService';
import QuoteAction from '../../../../action/checkout/QuoteAction';

function CartTotalTax(props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [isShowTaxPopup, setIsShowTaxPopup] = useState(false);
  const {total, quote, showBackdrop, hideBackdrop, isShowBackdrop} = props;
  const chargeTaxQuoteDefault = !quote.taxExempt;
  const [isChargeTax, setIsChargeTax] = useState(chargeTaxQuoteDefault);
  const taxAmount = total.value;
  const displayValue = quote.taxExempt ? t("Not collected") : CurrencyService.format(taxAmount);
  const taxElementRef = useRef();


  const showTaxPopup = () => {
    setIsShowTaxPopup(!isShowTaxPopup);
    showBackdrop();
  };

  const changeIsChargeTax = (event) => {
    setIsChargeTax(!event.target.checked);
  };

  const handleCloseTaxPopup = () => {
    setIsShowTaxPopup(false);
    hideBackdrop();
  };

  const handleCancelChargeTax = () => {
    handleCloseTaxPopup();
    setIsChargeTax(chargeTaxQuoteDefault);
  };

  useEffect(() => {
    if (isShowBackdrop) {
      setIsChargeTax(chargeTaxQuoteDefault);
    } else {
      handleCloseTaxPopup();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowBackdrop]);

  const handleSaveChargeTax = () => {
    if (isChargeTax !== chargeTaxQuoteDefault) {
      dispatch(QuoteAction.setCustomChargeTax(quote, isChargeTax));
    }
    handleCloseTaxPopup();
  };

  function getPopover() {
    return (
      <>
        <Overlay
          show={isShowTaxPopup}
          target={taxElementRef.current}
          placement="right"
        >
          <Popover id="tax popover">
            <div className="popup-totals-tax">
              <div className="tax-title">
                <div className="modal-header">
                  <button
                    type="button"
                    className="cancel"
                    data-dismiss="modal"
                    aria-label={t('Close')}
                    onClick={handleCancelChargeTax}
                  >
                    {t('Cancel')}
                  </button>
                  <button
                    type="button"
                    className="save"
                    onClick={handleSaveChargeTax}
                  >{t('Save')}
                  </button>
                </div>
              </div>
              <div className="tax-content">
                <div className="charge-tax-title">
                  {t("Taxes are automatically calculated.")}
                </div>
                <div className="charge-tax">
                  <div className="charge-tax-description-button">{t("Charge taxes")}</div>
                  <Form.Check
                    type="switch"
                    id="tax-switch"
                    checked={isChargeTax}
                    onInput={(event) => changeIsChargeTax(event)}
                  />
                </div>
              </div>
            </div>
          </Popover>
        </Overlay>
      </>
    );
  }

  return (
    <>
      <li ref={taxElementRef} className={`totals-tax${taxAmount ? " totals-action" : ""}`} onClick={showTaxPopup} aria-hidden="true">
        <span className="mark">{total.title}</span>
        <span className="amount">{displayValue}</span>
      </li>
      {getPopover()}
    </>
  );
}

CartTotalTax.className = 'CartTotalTax';
CartTotalTax.propTypes = {
  quote: PropTypes.object.isRequired,
  total: PropTypes.object.isRequired,
  showBackdrop: PropTypes.func.isRequired,
  hideBackdrop: PropTypes.func.isRequired,
  isShowBackdrop: PropTypes.bool.isRequired,
};

export default FunctionComponentFactory.get(CartTotalTax);
