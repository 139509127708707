import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {cloneDeep} from 'lodash';
import {useTranslation} from 'react-i18next';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import CustomerConstant from '../../../constant/CustomerConstant';
import CustomerAction from '../../../action/CustomerAction';
import QuoteAction from '../../../action/checkout/QuoteAction';

import CustomerSearch from './CustomerSearch.jsx';

function CustomerList() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {customers, pageInfo, isLoading} = useSelector((state) => state.core.customer.customerList);
  const [items, setItems] = useState([]);
  const [startCursor, setStartCursor] = useState(null);

  const [searchKey, setSearchKey] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const searchBoxRef = useRef();

  const canLoadMore = () => {
    return !pageInfo || (pageInfo && pageInfo.hasNextPage);
  };

  const loadCustomer = (pageAction = CustomerConstant.ACTION_FIRST_PAGE) => {
    if (!searchKey) {
      setItems([]);
      return;
    }
    if (pageAction !== CustomerConstant.ACTION_FIRST_PAGE && !canLoadMore()) {
      return;
    }
    if (pageAction === CustomerConstant.ACTION_FIRST_PAGE) {
      setStartCursor(null);
      setItems([]);
    }
    const variables = {};
    switch (pageAction) {
      case CustomerConstant.ACTION_NEXT_PAGE: {
        variables.pageSize = CustomerConstant.PAGE_SIZE;
        variables.current = pageInfo ? pageInfo.endCursor : null;
        variables.pageAction = CustomerConstant.ACTION_NEXT_PAGE;
        break;
      }
      case CustomerConstant.ACTION_PREVIOUS_PAGE: {
        variables.pageSize = CustomerConstant.PAGE_SIZE;
        variables.current = pageInfo ? pageInfo.startCursor : null;
        variables.pageAction = CustomerConstant.ACTION_PREVIOUS_PAGE;
        break;
      }
      default: {
        variables.pageSize = CustomerConstant.PAGE_SIZE;
        variables.pageAction = CustomerConstant.ACTION_FIRST_PAGE;
      }
    }
    let query = '';
    if (searchKey) {
      query += ` AND ${searchKey}`;
    }
    variables.query = query;
    dispatch(CustomerAction.getCustomers(variables));
  };

  useEffect(() => {
    loadCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  useEffect(() => {
    if (!pageInfo) {
      setItems([]);
      setStartCursor(null);
      return;
    }
    if (pageInfo && (pageInfo.startCursor === startCursor)) {
      return;
    }
    const newItems = [
      ...items,
      ...cloneDeep(customers),
    ];
    setItems(newItems);
    const newStartCursor = pageInfo ? pageInfo.startCursor : null;
    setStartCursor(newStartCursor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, pageInfo]);

  const handleScrollList = (event) => {
    const element = event.target;
    if (!isLoading && (element.scrollHeight - (element.scrollTop + element.clientHeight)) < 50) {
      loadCustomer(CustomerConstant.ACTION_NEXT_PAGE);
    }
  };

  const clickSearchBox = () => {
    if (!isSearching) {
      setIsSearching(true);
    }
  };

  /**
   * Blur search product input
   */
  const blurSearchBox = () => {
    if (!searchKey) {
      setIsSearching(false);
    }
  };

  const changeSearchKey = (searchText) => {
    setSearchKey(searchText);
  };

  /**
   * Select customer to cart
   * @param customer
   */
  const selectCustomer = (customer) => {
    setSearchKey('');
    dispatch(QuoteAction.setCustomer(customer));
  };

  const noResultText = !isLoading && searchKey && !items.length ? 'Sorry, we couldn\'t find any records.' : '';
  const contentTextResult = !isLoading && !searchKey
    ? 'Please enter the name, phone number or email to search for the customer.' : noResultText;

  /**
   * Cancel Searching
   */
  const cancelSearching = () => {
    setIsSearching(false);
    changeSearchKey('');
    setItems([]);
  };
  return (
    <>
      <CustomerSearch
        clickSearchBox={clickSearchBox}
        blurSearchBox={blurSearchBox}
        changeSearchKey={changeSearchKey}
        cancelSearching={cancelSearching}
        isSearching={isSearching}
        searchKey={searchKey}
        searchBoxRef={searchBoxRef}
      />
      <div className="list-customer" style={{overflow: 'scroll'}} onScroll={handleScrollList}>
        <ul>
          {
            items.map((customer) => {
              return (
                <div key={`${customer.displayName}_${Math.random()}`} onClick={() => selectCustomer(customer)} role="button" aria-hidden>
                  <span className="name">{customer.displayName}</span>
                  <span className="phone">{customer.phone}</span>
                </div>
              );
            })
          }
        </ul>
        <div
          className="text-center list-customer-norecords"
          style={{display: (items.length === 0 && !isLoading ? "block" : "none")}}
        >
          {t(contentTextResult)}
        </div>
        <div
          className="loader-product"
          style={{display: (isLoading ? 'block' : 'none')}}
        />
      </div>
    </>
  );
}

CustomerList.className = 'CustomerList';
CustomerList.propTypes = {};

export default React.memo(FunctionComponentFactory.get(CustomerList));
