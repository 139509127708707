import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import SmoothScrollbar from "smooth-scrollbar";
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import Denomination from '../denomination/Denomination.jsx';
import SessionAction from '../../../action/SessionAction';
import SessionConstant from '../../../constant/SessionConstant';
import SessionService from '../../../../service/session/SessionService';

const ModalSessionCashControl = (props) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const setModalSessionElement = (element) => {
    if (!element) {
      return;
    }
    SmoothScrollbar.init(element);
  };

  const {handleAction, titleAction, title, titleNumPad} = props;

  const [denominations, setDenominations] = useState([]);

  useEffect(() => {
    setDenominations(SessionService.rebuildDenominations());

  }, []);


  const handleClickCancelButton = () => {
    dispatch(SessionAction.setTypeSessionPopup(SessionConstant.CLOSE_ALL_POPUP));
  };

  const getSubtotalAllDenomination = () => {
    const total = denominations.reduce((subTotal, denomination) => {
      return subTotal + denomination.subTotal;
    }, 0);

    return total;
  };

  const updateDenomination = (id, type, value) => {
    const denomination = denominations.find((deno) => { return deno.id === id; });

    switch (type) {
      case SessionConstant.BUTTON_PLUS:
        denomination.number++;
        break;
      case SessionConstant.BUTTON_MINUS:
        if (denomination.number !== 0) {
          denomination.number--;
        }
        break;
      case SessionConstant.CHANGE_NUMPAD:
        denomination.number = value;
        break;
      default:
        denomination.number = 0;
    }

    denomination.subTotal = denomination.number * denomination.value;

    setDenominations([...denominations]);
  };


  const handleActionModal = () => {
    handleAction(getSubtotalAllDenomination());
  };

  return (
    <Modal
      className="fade in popup-session"
      dialogClassName="modal-fixheight"
      show
    >
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="cancel"
            data-dismiss="modal"
            aria-label={t("Close")}
            onClick={handleClickCancelButton}
          >
            {t('Cancel')}
          </button>
          <h4 className="modal-title">{title}</h4>
        </div>

        <div className="session-title">
          <div className="detail">
            <div className="pull-left">
              <b>{t('POS')}</b>  <span>{SessionService.getPosName()}</span>
            </div>
          </div>
          <div className="box" />
        </div>
        <div data-scrollbar ref={setModalSessionElement} className="session-content">
          <ul>
            <li>
              <div className="title"><b>{t('Coin/Bill Value')}</b></div>
              <div className="number"><b>{t('Number of Coins/Bills')}</b></div>
              <div className="price"><b>{t('Subtotal')}</b></div>
            </li>
            {
              denominations.map((denomination) => {
                return (
                  <Denomination
                    key={denomination.id}
                    denomination={denomination}
                    updateDenomination={updateDenomination}
                  />
                );
              })
            }
          </ul>
        </div>
        <div className="session-bottom">
          <div className="box">
            <span className="title">{titleNumPad}</span>
            <span className="price">
              {SessionService.formatAmount(getSubtotalAllDenomination())}
            </span>
          </div>
        </div>

        <div className="modal-footer ">
          <button
            className="btn btn-default"
            type="button"
            aria-label={t("Open")}
            onClick={handleActionModal}
          >
            {titleAction}
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalSessionCashControl.propTypes = {
  handleAction: PropTypes.func.isRequired,
  titleAction: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleNumPad: PropTypes.string.isRequired,
};

ModalSessionCashControl.className = 'ModalSessionCashControlComponent';
export default FunctionComponentFactory.get(ModalSessionCashControl);
