

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {Spinner} from 'react-bootstrap';

import i18n from '../../../../../config/i18n';
import functionComponentFactory from '../../../../../framework/factory/FunctionComponentFactory';
import PermissionConstant from '../../../../constant/PermissionConstant';
import OrderAction from '../../../../action/OrderAction';
import ReorderConfirmation from '../../reorder/ReorderConfirmation.jsx';
import PrintReceiptComponent from '../../../print/PrintReceiptComponent.jsx';
import OrderConstant from '../../../../constant/OrderConstant';
import OrderSendEmail from '../../OrderSendEmail.jsx';
import OrderPaymentAction from "../../../../action/order/payment/OrderPaymentAction";
import OrderRefundAction from "../../../../action/order/refund/OrderRefundAction";
import OrderService from '../../../../../service/order/OrderService';
import PermissionService
  from '../../../../../service/permission/PermissionService';

const BlockActions = () => {
  const {order, isRedirectToCheckoutPage, isLoadingBtnFulfill, isLoadingReorder} = useSelector((state) => state.core.order.orderDetail);
  const {isReprint} = useSelector((state) => state.core.order.orderDetail);
  const printBtnClassName = isReprint ? "btn btn-default disabled" : "btn btn-default";
  const dispatch = useDispatch();
  function canCancelOrder() {
    return order && order.displayFulfillmentStatus !== OrderConstant.FULFILLMENT_STATUS_FULFILLED &&
      order.displayFinancialStatus !== OrderConstant.FINANCIAL_STATUS_PAID &&
      order.displayFinancialStatus !== OrderConstant.FINANCIAL_STATUS_PARTIALLY_PAID &&
      order?.totalReceivedSet?.shopMoney?.amount === 0 &&
      !order.cancelledAt;
  }

  const canTakePayment = () => {
    if (!order) {
      return false;
    }
    const total = order.totalPriceSet.shopMoney.amount;
    const receivedSet = order.totalReceivedSet.shopMoney.amount;
    const totalCapturable = order?.totalCapturableSet?.shopMoney?.amount || 0;
    const orderRemaining = total - receivedSet - totalCapturable;
    if (orderRemaining <= 0) { return false; }
    return (order.displayFinancialStatus === OrderConstant.FINANCIAL_STATUS_PARTIALLY_PAID ||
      order.displayFinancialStatus === OrderConstant.FINANCIAL_STATUS_PENDING);
  };

  const [canCancel, setCanCel] = useState(canCancelOrder());
  useEffect(() => {
    setCanCel(canCancelOrder());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleTakePayment = () => {
    dispatch(OrderPaymentAction.handleOpenTakePayment(true));
  };

  function handleReprintOrder() {
    dispatch(OrderAction.reprintOrder());
  }

  const canSendEmail = () => {
    if (!order && !order.totalReceivedSet && !order.totalPriceSet) {
      return false;
    }
    const receivedOrderAmount = order.totalReceivedSet.shopMoney.amount;
    const totalAmount = order.totalPriceSet.shopMoney.amount;
    return receivedOrderAmount < totalAmount;
  };
  const handleOpenSendEmailPopup = () => {
    dispatch(OrderAction.setIsOpenSendEmailPopup(true));
  };
  const handleOpenOrderCancelPopup = () => {
    dispatch(OrderAction.setIsOpenOrderCancelPopup(true));
  };

  function handleReprintSuccess() {
    dispatch(OrderAction.reprintOrderSuccess());
  }
  function handleReprintFailed() {
    dispatch(OrderAction.reprintOrderFailed());
  }
  const isClickOnBtnFulfill = () => {
    if (!OrderService.isDisableBtnFulfillInBlockAction(order)) {
      return true;
    }

    return false;
  };

  const handleClickBtnFulfill = () => {
    dispatch(OrderAction.getFulfillItemsPage());
  };

  const getBtnFulfil = () => {
    const loaderClass = isLoadingBtnFulfill ? "loader" : null;
    const isBtnDisable = isLoadingBtnFulfill ? true : !isClickOnBtnFulfill();
    return (
      <button
        className={`btn btn-default ${loaderClass}`}
        type="button"
        disabled={isBtnDisable}
        onClick={handleClickBtnFulfill}
      >
        {i18n.t('Fulfill')}
      </button>
    );
  };

  const navigate = useNavigate();
  const handleReorder = () => {
    if (isLoadingReorder) {
      return;
    }
    dispatch(OrderAction.handleReorder(order));
  };

  useEffect(() => {
    if (!isRedirectToCheckoutPage) {
      return;
    }
    navigate("/checkout");
    dispatch(OrderAction.setIsRedirectToCheckoutPage(false));
  }, [isRedirectToCheckoutPage, navigate, dispatch]);

  const canRefund = () => {
    return order.refundable;
  };

  const handleOpenRefund = () => {
    dispatch(OrderRefundAction.handleOpenRefund());
  };

  const reorderButtonDisplay = () => {
    const reorderBtnTitle = i18n.t('Reorder');
    return isLoadingReorder ? <Spinner animation="border" variant="light" /> : reorderBtnTitle;
  };

  const reprintButtonDisplay = () => {
    const reprintTitle = i18n.t('Print');
    return isReprint ? <Spinner animation="border" variant="light" /> : reprintTitle;
  };

  const refundButton = PermissionService.isAllowed(PermissionConstant.PERMISSION_REFUND_AND_EXCHANGE)
      ? (
        <li>
          <button
            className={`btn btn-default refund-button ${canRefund() ? '' : 'disabled'}`}
            type="button"
            onClick={() => handleOpenRefund()}
          >{i18n.t('Refund')}
          </button>
        </li>
      ) : null;
  return (
    <div className="block-actions">

      <OrderSendEmail />
      <ul className="actions">
        <li>
          <button
            className={`btn btn-default take-payment-button ${canTakePayment() ? '' : 'disabled'}`}
            type="button"
            onClick={() => handleTakePayment()}
          >{i18n.t('Take Payment')}
          </button>
        </li>
        {refundButton}
        <li>
          <button
            className={`btn btn-default cancel-button ${canCancel ? '' : 'disabled'}`}
            type="button"
            onClick={handleOpenOrderCancelPopup}
          >
            {i18n.t('Cancel')}
          </button>
        </li>
        <li>
          {getBtnFulfil()}
        </li>
        <li>
          <button
            className={printBtnClassName}
            type="button"
            onClick={handleReprintOrder}
          >
            {reprintButtonDisplay()}
            <div style={{display: 'none'}} >
              {isReprint && <PrintReceiptComponent onAfterPrint={handleReprintSuccess} onPrintError={handleReprintFailed} isReprint />}
            </div>
          </button>
        </li>
        <li>
          <button
            className={`btn btn-default email-button ${canSendEmail() ? '' : 'disabled'}`}
            type="button"
            onClick={handleOpenSendEmailPopup}
          >
            {i18n.t('Email')}
          </button>
        </li>
        <li>
          <ReorderConfirmation />
          <button
            className={`btn btn-default reorder-button ${isLoadingReorder ? 'disabled' : ''}`}
            type="button"
            onClick={handleReorder}
          >
            {reorderButtonDisplay()}
          </button>
        </li>
      </ul>
    </div>
  );
};

BlockActions.propTypes = {
};
export default functionComponentFactory.get(BlockActions);
