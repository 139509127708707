import React from 'react';
import {useTranslation} from 'react-i18next';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';

import FunctionComponentFactory from '../../../../../../framework/factory/FunctionComponentFactory';
import OrderConstant from '../../../../../constant/OrderConstant';

const FulfillmentConfirmation = ({handleAction}) => {
  const {t} = useTranslation();

  const handleCancelFulfillmentConfirmation = () => {
    handleAction(OrderConstant.CLOSE);
  };

  const handleSaveFulfillmentConfirmation = () => {
    handleAction(OrderConstant.SAVE);
  };

  return (
    <Modal
      size="sm"
      className="fade in popup-session fulfillment-confirmation"
      dialogClassName="modal-fixheight"
      show
    >
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title" >
            {t('Fulfillment Confirmation')}
          </h4>
        </div>
        <div className="modal-body">
          <div className="scroll-content">
            <p className="color-gray">
              {t('Are you sure you want to fulfill the selected items?')}
            </p>
          </div>
        </div>
        <div className="group-actions modal-footer">
          <button type="button" onClick={handleCancelFulfillmentConfirmation}>
            {t('No')}
          </button>
          <button type="button" onClick={handleSaveFulfillmentConfirmation}>
            {t('Yes')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

FulfillmentConfirmation.propTypes = {
  handleAction: PropTypes.func.isRequired,
};

FulfillmentConfirmation.className = 'FulfillmentConfirmationComponent';
export default FunctionComponentFactory.get(FulfillmentConfirmation);
