import {cloneDeep, filter} from "lodash";

import ActionFactory from "../../../../framework/factory/ActionFactory";
import PaymentService from "../../../../service/payment/PaymentService";
import CheckoutConstant from "../../../constant/checkout/CheckoutConstant";

export class CheckoutPaymentActionClass {

  /**
   * get list payment
   *
   * @returns  {(function(*): Promise<void>)|*}
   */
  getListPayment(isAddPaymentMethod) {
    return (dispatch) => {
      const listPayment = PaymentService.getListPayment();
      const listPaymentFilter = filter(listPayment, ((payment) => {
        if (isAddPaymentMethod) {
          if ((payment.use_pay_later && parseFloat(payment.use_pay_later) !== 0) || (parseFloat(payment.active) !== 1)) {
            return;
          }
        } else if (parseFloat(payment.active) !== 1) {
          return;
        }

        return payment;
      }));
      dispatch(this.getListPaymentSuccess(listPaymentFilter));
    };
  }

  /**
   * Get list payment success
   *
   * @returns {{type: string}}
   * @param listPayment
   */
  getListPaymentSuccess(listPayment) {
    return {
      type: CheckoutConstant.GET_LIST_PAYMENT_METHOD_SUCCESS,
      listPayment,
    };
  }

  /**
   * select payment
   *
   * @returns {{type: string}}
   * @param selectedPayment
   */
  selectPayment(selectedPayment) {
    return {
      type: CheckoutConstant.SELECT_PAYMENT_METHOD,
      selectedPayment,
    };
  }

  /**
   * set remaining amount of money
   *
   * @returns {{type: string}}
   * @param remaining
   */
  setRemaining(remaining) {
    return {
      type: CheckoutConstant.SET_REMAINING,
      remaining,
    };
  }

  /**
   * set default payment screen will show when checkout
   *
   * @returns {{type: string}}
   */
  setDefaultPaymentScreen(remaining) {
    return {
      type: CheckoutConstant.SET_DEFAULT_PAYMENT_SCREEN,
      remaining,
    };
  }

  /**
   * close edit payment component
   *
   * @returns {{type: string}}
   */
  closeEditPayment() {
    return {
      type: CheckoutConstant.CLOSE_EDIT_PAYMENT,
    };
  }

  /**
   * handle accept payment
   * @param selectedPayment
   * @param amount
   * @param referenceNo
   * @returns {{type: string}}
   */
  handleAcceptPayment(selectedPayment, amount, referenceNo) {
    const paid = {
      selectedPayment,
      amount: parseFloat(amount),
    };
    if (referenceNo && referenceNo.length > 0) { paid.referenceNo = referenceNo; }
    return {
      type: CheckoutConstant.ACCEPT_PAYMENT,
      paid,
    };
  }

  /**
   * open list payment
   *
   * @returns {{type: string}}
   */
  handleOpenListPayment() {
    return {
      type: CheckoutConstant.HANDLE_OPEN_LIST_PAYMENT,
    };
  }

  /**
   * remove item from list paid by index
   * @param index
   * @param listPaid
   */
  handleRemovePaidItem(index, listPaid, quote) {
    return (dispatch) => {
      const listPaidTemp = cloneDeep(listPaid);
      listPaidTemp.splice(index, 1);
      dispatch(this.updateListPaid(listPaidTemp, quote));
    };
  }

  /**
   * update paid item
   *
   * @returns  {(function(*): Promise<void>)|*}
   * @param amountUpdate
   */
  handleUpdatePaidItem(amountUpdate) {
    return (dispatch, getState) => {
      const {listPaid, currentIndex} = getState().core.checkout.payment;
      const listPaidTemp = cloneDeep(listPaid);
      listPaidTemp[currentIndex].amount = parseFloat(amountUpdate);
      dispatch(this.updateListPaid(listPaidTemp, null));
    };
  }

  /**
   * set list paid after remove
   *
   * @returns {{type: string}}
   */
  updateListPaid(listPaidUpdate, quote) {
    return {
      type: CheckoutConstant.UPDATE_PAID_ITEM,
      listPaidUpdate,
      quote,
    };
  }

  /**
   * open edit payment component when update payment in complete payment
   *
   * @returns {{type: string}}
   * @param selectedPayment
   * @param currentAmount
   * @param index
   */
  handleUpdatePayment(selectedPayment, currentAmount, index) {
    return {
      type: CheckoutConstant.HANDLE_UPDATE_PAYMENT,
      selectedPayment,
      currentAmount,
      index,
    };
  }

  /**
   * open complete payment component
   *
   * @returns {{type: string}}
   */
  handleOpenCompletePayment() {
    return {
      type: CheckoutConstant.HANDLE_OPEN_COMPLETE_PAYMENT,
    };
  }

  /**
   * completed order payment
   *
   * @returns {{type: string}}
   */
  handleCompletedOrerPayment() {
    return {
      type: CheckoutConstant.HANDLE_COMPLETED_ORDER_PAYMENT,
    };
  }
}

/**
 * @type {CheckoutPaymentActionClass}
 */
const CheckoutPaymentAction = ActionFactory.get(CheckoutPaymentActionClass);
export default CheckoutPaymentAction;
