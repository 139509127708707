import moment from "moment/moment";

import ServiceFactory from "../framework/factory/ServiceFactory";

export class DateTimeServiceClass {
  className = 'DateTimeService';

  /**
   * get time (HH:MM format) from timestamp
   *
   * @param timestamp
   * @returns {string}
   */
  convertTimeStampCart(timestamp) {
    const momentObj = moment(timestamp);

    // 24-hour format
    const hour = momentObj.format('HH');
    const minute = momentObj.format('mm');

    return `${hour}:${minute}`;
  }
}

/**
 * @type {DateTimeService}
 */
const DateTimeService = ServiceFactory.get(DateTimeServiceClass);
export default DateTimeService;
