import _, {cloneDeep} from 'lodash';

import CoreService from '../CoreService';
import OrderResourceModel from '../../resource-model/order/OrderResourceModel';
import ServiceFactory from '../../framework/factory/ServiceFactory';
import OrderConstant from '../../view/constant/OrderConstant';
import CurrencyService from '../CurrencyService';
import PermissionConstant from '../../view/constant/PermissionConstant';
import ConfigService from '../config/ConfigService';
import ConfigConstant from '../../view/constant/ConfigConstant';
import ProductService from '../product/ProductService';
import staffService from '../staff/StaffService';
import ProductStockService from '../product/stock/ProductStockService';
import LocationService from '../location/LocationService';
import SessionService from '../session/SessionService';
import i18n from '../../config/i18n';
import posService from '../pos/PosService';

export class OrderServiceClass extends CoreService {
  static className = 'OrderServiceClass';
  resourceModel = OrderResourceModel;

  getCustomAttributeByKey(order, prefix) {
    if (order && order.customAttributes) {
      const foundAttribute = order.customAttributes.find((attribute) => {
        return attribute.key.startsWith(prefix);
      });
      return foundAttribute ? foundAttribute.value : null;
    }
    return null;
  }

  /**
   * Get Orders
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getOrders(input) {
    return this.getResourceModel().getOrders(input);
  }

  /**
   * Get Price Label
   * @param {*} order
   * @returns {object}
   */
  getPriceLabel(order = {}) {
    let due;
    switch (order.displayFinancialStatus) {
      case OrderConstant.FINANCIAL_STATUS_AUTHORIZED:
      case OrderConstant.FINANCIAL_STATUS_PENDING:
      case OrderConstant.FINANCIAL_STATUS_PARTIALLY_PAID:
        due = order.totalPriceSet.shopMoney.amount - order.totalReceivedSet.shopMoney.amount;
        return {
          className: 'due',
          value: i18n.t("Due {{due}}", {due: this.getPrice(due)}),
        };
      case OrderConstant.FINANCIAL_STATUS_PAID:
        return {
          className: 'paid',
          value: i18n.t('Paid'),
        };
      case OrderConstant.FINANCIAL_STATUS_PARTIALLY_REFUNDED:
        return {
          className: 'partially-refunded',
          value: i18n.t('Partially Refunded'),
        };
      case OrderConstant.FINANCIAL_STATUS_REFUNDED:
        return {
          className: 'refunded',
          value: i18n.t('Refunded'),
        };
      case OrderConstant.FINANCIAL_STATUS_VOIDED:
        return {
          className: 'voided',
          value: i18n.t('Voided'),
        };
    }

    return {
      className: order.displayFinancialStatus?.toLowerCase(),
      value: (order.displayFinancialStatus?.toLowerCase()),
    };
  }

  /**
   * Get Price Label
   * @param {*} order
   * @returns {object}
   */
  getPriceOrderDetailLabel(order = {}, isDiffientInOrderDetail = false) {
    let due;
    switch (order.displayFinancialStatus) {
      case OrderConstant.FINANCIAL_STATUS_PENDING:
        return {
          className: 'pending',
          value: i18n.t('Pending'),
        };
      case OrderConstant.FINANCIAL_STATUS_AUTHORIZED:
        if (isDiffientInOrderDetail) {
          return {
            className: 'authorized',
            value: i18n.t('Authorized'),
            label: 'Authorized',
          };
        }
        due = order.totalPriceSet.shopMoney.amount - order.totalReceivedSet.shopMoney.amount;
        return {
          className: 'due',
          value: i18n.t("Due {{due}}", {due: this.getPrice(due)}),
          label: 'Authorized',
        };
      case OrderConstant.FINANCIAL_STATUS_PARTIALLY_PAID:
        if (isDiffientInOrderDetail) {
          return {
            className: 'partially-paid',
            value: 'Partially Paid',
          };
        }
        due = order.totalPriceSet.shopMoney.amount - order.totalReceivedSet.shopMoney.amount;
        return {
          className: 'due',
          value: i18n.t("Due {{due}}", {due: this.getPrice(due)}),
          label: `Partially Paid`,
        };
      case OrderConstant.FINANCIAL_STATUS_PAID:
        return {
          className: 'paid',
          value: i18n.t('Paid'),
        };
      case OrderConstant.FINANCIAL_STATUS_PARTIALLY_REFUNDED:
        return {
          className: 'partially-refunded',
          value: i18n.t('Partially Refunded'),
        };
      case OrderConstant.FINANCIAL_STATUS_REFUNDED:
        return {
          className: 'refunded',
          value: i18n.t('Refunded'),
        };
      case OrderConstant.FINANCIAL_STATUS_VOIDED:
        return {
          className: 'voided',
          value: i18n.t('Voided'),
        };
    }

    return {
      className: order.displayFinancialStatus?.toLowerCase(),
      value: (order.displayFinancialStatus?.toLowerCase()),
    };
  }


  /**
   * Get Price
   * @param {*} totalPrice
   * @returns {*|string}
   */
  getPrice(totalPrice) {
    return CurrencyService.format(Number(totalPrice));
  }

  /**
   * Get Order Status
   * @param {*} order
   * @returns {object}
   */
  getOrderStatus(order) {
    switch (order.displayFulfillmentStatus) {
      case OrderConstant.FULFILLMENT_STATUS_UNFULFILLED:
        return {
          className: 'unfulfilled',
          status: i18n.t('Unfulfilled'),
        };
      case OrderConstant.FULFILLMENT_STATUS_FULFILLED:
        return {
          className: 'fulfilled',
          status: i18n.t('Fulfilled'),
        };
      case OrderConstant.FULFILLMENT_STATUS_PARTIALLY_FULFILLED:
        return {
          className: 'partially-fulfilled',
          status: i18n.t('Partially fulfilled'),
        };
      case OrderConstant.FULFILLMENT_STATUS_ON_HOLD:
        return {
          className: 'on-hold',
          status: i18n.t('On hold'),
        };
      case OrderConstant.FULFILLMENT_STATUS_RESTOCKED:
        return {
          className: 'closed',
          status: i18n.t('Closed'),
        };
      default:
        return {
          className: 'fulfilled',
          status: i18n.t('Fulfilled'),
        };
    }
  }

  /**
   * Get Order Id
   * @param {*} order
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getOrderId(order) {
    const parts = order.id.split('/');

    const orderId = parts[parts.length - 1];

    return orderId;
  }

  /**
   * Get Order
   * @param {*} id
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getOrder(id) {
    return this.getResourceModel().getOrder(id);
  }

  /**
   * Cancel Order
   * @param {*} cancelOrderInfo
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  cancelOrder(cancelOrderInfo) {
    return this.getResourceModel().cancelOrder(cancelOrderInfo);
  }

  /**
   * Get Fulfillment Order
   * @param {*} orderId
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getFulfillmentOrder(orderId) {
    return this.getResourceModel().getFulfillmentOrder(orderId);
  }

  /**
   * Build Query Permission
   * @returns string
   */
  buildQueryPermission() {
    const permissions = JSON.parse(localStorage.getItem('permissions'));

    if (!permissions) {
      return '';
    }

    const hasPermissionAll = permissions.includes(PermissionConstant.PERMISSION_TYPE_ALL);
    const hasPermissionAllOrders =
      permissions.includes(PermissionConstant.PERMISSION_ALL_ORDERS);

    if (hasPermissionAllOrders || hasPermissionAll) {
      return '';
    }

    const hasPermissionOrderCreateFromAllLocation =
      permissions.includes(PermissionConstant.PERMISSION_ORDER_CREATE_FROM_ALL_LOCATION);

    if (hasPermissionOrderCreateFromAllLocation) {
      const app_id = ConfigService.getConfig(ConfigConstant.POS_APP_HANDLE_ID) || '';
      const partsId = app_id?.split('/');
      const appId = partsId[partsId.length - 1];
      return `sales_channel:${appId}`;
    }

    const hasPermissionOrderCreateFromStaffLocation =
      (permissions.includes(PermissionConstant.PERMISSION_ORDER_CREATE_FROM_STAFF_LOCATION));

    if (hasPermissionOrderCreateFromStaffLocation) {
      const location_id = localStorage.getItem('location_id') || '';
      const parts = location_id.split('/');

      const locationId = parts[parts.length - 1] || '';
      return `location_id:'${locationId}'`;
    }
  }

  /**
   * Format Price
   * @param {*} price
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  formatPrice(price) {
    return CurrencyService.format(Number(price));
  }

  /**
   * Format Price: return amount multipled by times
   * @param {*} priceSet
   * @param {number} times
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  formatShopMoneyAmount(priceSet, times = 1) {
    return CurrencyService.format(Number(times * priceSet?.shopMoney?.amount));
  }

  /**
   * Get Transactions By Kind
   * @param {*} transactions
   * @param {string} kind
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getTransactionsByKind(transactions, kind = OrderConstant.ORDER_TRANSACTION_KIND_SALE, status = OrderConstant.ORDER_TRANSACTION_STATUS_SUCCESS) {
    const filter = (transactions) ? transactions.filter((transaction) => transaction.kind === kind && transaction.status === status) : null;
    return filter;
  }

  /**
   * Get Transactions By Kinds And Status
   * @param {*} transactions
   * @param {string} kindList
   * @param {string} statusList
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getTransactionsByKindsAndStatus(transactions, kindList = [OrderConstant.ORDER_TRANSACTION_KIND_SALE], statusList = [OrderConstant.ORDER_TRANSACTION_STATUS_SUCCESS]) {
    const filter = (transactions) ? transactions.filter((transaction) => kindList.includes(transaction.kind) && statusList.includes(transaction.status.toUpperCase())) : null;
    return filter;
  }

  /**
   * Calc Change Payment
   * @param {*} order
   * @returns {number}
   */
  calcChangePayment(order) {
    const grandTotalAmount = order?.totalPriceSet?.shopMoney?.amount;
    const totalPaidAmount = order?.totalReceivedSet?.shopMoney?.amount;
    const changeAmount = Math.max(totalPaidAmount - grandTotalAmount, 0);
    if (changeAmount > 0) {
      return changeAmount;
    }
    return 0;
  }

  /**
   * Get Taxes Included Value Of Order
   * @param {*} order
   * @returns {number}
   */
  getTaxesIncludedOrder(order) {
    return order.taxesIncluded;
  }

  /**
   * Calc Tax LineItem
   * @param {*} order
   * @param {*} item
   * @returns {number}
   */
  calcTaxLineItem(order, item) {
    return item?.taxLines?.reduce((result, taxLine) => result + taxLine?.priceSet?.shopMoney?.amount, 0) || 0;
  }

    /**
   * Is Completed Order On POS
   * @param {*} order
   * @returns {boolean}
   */
  isCompletedOrderOnPos(order) {
    const staff = this.getCustomAttributeByKey(order, OrderConstant.CUSTOM_ATTRIBUTE_KEY_CASHIER);
    const location = this.getCustomAttributeByKey(order, OrderConstant.CUSTOM_ATTRIBUTE_KEY_LOCATION);
    return staff && location;
  }


  /**
   * Calc Discount LineItem
   * @param {*} order
   * @param {*} item
   * @returns {number}
   */
  calcDiscountLineItem(order, item) {
    if (item.originalTotalSet && item.discountedTotalSet) {
      return item.originalTotalSet?.shopMoney?.amount - item.discountedTotalSet?.shopMoney?.amount;
    }
    const entitledDiscountAllocations = item?.discountAllocations?.filter((discountAllocation) => {
      return [OrderConstant.DISCOUNT_APPLICATION_ALLOCATION_ENTITLED, OrderConstant.DISCOUNT_APPLICATION_ALLOCATION_EXPLICIT].includes(discountAllocation?.discountApplication?.targetSelection);

    });
    return entitledDiscountAllocations?.reduce((totalDiscountAllocation, discountAllocation) => {
      return totalDiscountAllocation + discountAllocation.allocatedAmountSet?.shopMoney?.amount;
    }, 0) || 0;
  }

  /**
   * Calc Price LineItem
   * @param {*} order
   * @param {*} item
   * @returns {number}
   */
  calcPriceLineItem(order, item) {
    const price = item.originalTotalSet.shopMoney.amount;
    return price;
  }

  /**
   * Calc Row Total LineItem
   * @param {*} order
   * @param {*} item
   * @returns {number}
   */
  calcRowTotalLineItem(order, item) {
    const taxesIncluded = this.getTaxesIncludedOrder(order);
    const rowTotal = taxesIncluded
        ? this.calcPriceLineItem(order, item) - this.calcDiscountLineItem(order, item)
        : this.calcPriceLineItem(order, item) - this.calcDiscountLineItem(order, item) + this.calcTaxLineItem(order, item);
    return rowTotal;
  }

  /**
   * Calc Subtotal Order
   * @param {*} order
   * @returns {number}
   */
  calcSubtotalOrder(order) {
    return order.lineItems.items.reduce((subtotalOrder, item) => {
      return subtotalOrder + this.calcPriceLineItem(order, item) - this.calcDiscountLineItem(order, item);
    }, 0);
  }

  /**
   * Calc Discount Order
   * @param {*} order
   * @returns {number}
   */
  calcDiscountOrder(order) {
    const totalDiscountOrderAndProduct = order?.totalDiscountsSet?.shopMoney?.amount;
    const totalDiscountProduct = order?.lineItems?.items?.reduce((total, item) => {
      return total + this.calcDiscountLineItem(order, item);
    }, 0);
    const totalDiscountOrder = totalDiscountOrderAndProduct - totalDiscountProduct;
    return totalDiscountOrder;
  }

  /**
   * Calc Tax Order
   * @param {*} order
   * @returns {number}
   */
  calcTaxOrder(order) {
    const totalTaxOrder = order.totalTaxSet.shopMoney.amount;
    return totalTaxOrder;
  }

  /**
   * Calc Shipping Order
   * @param {*} order
   * @returns {number}
   */
  calcShippingOrder(order) {
    const shippingOrder = order.totalShippingPriceSet.shopMoney.amount;
    return shippingOrder;
  }

  /**
   * Calc Grand Total Order
   * @param {*} order
   * @returns {number}
   */
  calcGrandTotalOrder(order) {
    const subtotalOrder = this.calcSubtotalOrder(order);
    const taxOrder = this.calcTaxOrder(order);
    const discountOrder = this.calcDiscountOrder(order);
    const shippingOrder = this.calcShippingOrder(order);
    const taxesIncluded = this.getTaxesIncludedOrder(order);
    const grandTotalOrder = taxesIncluded
        ? subtotalOrder - discountOrder + shippingOrder
        : subtotalOrder - discountOrder + shippingOrder + taxOrder;
    return grandTotalOrder;
  }

  /**
   * Calculate Total Refunded Value
   * @param {*} order
   * @returns {number}
   */
  calcTotalRefundedValue(order) {
    const totalRefundTransactionAmount = this.getTransactionsByKind(order.transactions, OrderConstant.ORDER_TRANSACTION_KIND_REFUND)?.reduce((totalRefundAmount, refundTransaction) => totalRefundAmount + refundTransaction.amountSet.shopMoney.amount, 0);
    return totalRefundTransactionAmount;
  }

  /**
   * Get Total Received Display
   * @param {*} order
   * @returns {number}
   */
  getTotalReceivedDisplay(order) {
    return order.totalReceivedSet && this.formatPrice(order.totalReceivedSet.shopMoney.amount);
  }

  /**
   * Calc Total Due
   * @param {*} order
   * @returns {number}
   */
  calcTotalDue(order) {
    const grandTotalAmount = order?.totalPriceSet?.shopMoney?.amount;
    const totalPaidAmount = order?.totalReceivedSet?.shopMoney?.amount;
    const due = Math.max(grandTotalAmount - totalPaidAmount, 0);
    return due;
  }

  /**
   * Calc Total Change
   * @param {*} order
   * @returns {number}
   */
  calcTotalChange(order) {
    const grandTotalAmount = order?.totalPriceSet?.shopMoney?.amount;
    const totalPaidAmount = order?.totalReceivedSet?.shopMoney?.amount;
    const change = Math.max(totalPaidAmount - grandTotalAmount, 0);
    return change;
  }

  /**
   * Calc Discounted Total Line Item
   * @param {*} order
   * @param {*} item
   * @returns {number}
   */
  calcDiscountedTotalLineItem(order, item) {
    if (item.discountedTotalSet) {
      return item.discountedTotalSet.shopMoney.amount;
    }
    return this.calcPriceLineItem(order, item) - this.calcDiscountLineItem(order, item);
  }

  /**
   * Calc Discounted Unit Line Item
   * @param {*} order
   * @param {*} item
   * @returns {number}
   */
  calcDiscountedUnitLineItem(order, item) {
    if (item.discountedUnitPriceSet) {
      return item.discountedUnitPriceSet.shopMoney.amount;
    }
    return this.calcDiscountedTotalLineItem(order, item) / item.quantity;
  }

  /**
   * Send Email
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  sendEmail(input) {
    return this.getResourceModel().sendEmail(input);
  }

  /**
   * create record payment
   * @param listPaid
   * @param order
   * @param shop
   * @return {Promise}
   */
  createRecordPayment(listPaid, order, shop, session) {
    return this.getResourceModel().createRecordPayment(listPaid, order, shop, session);
  }

  /**
   * create record payment
   * @param product
   * @param totalQty
   * @param onHandQty
   * @return {object}
   */
  validateQtyCreateOrder(product, totalQty, onHandQty) {
    if (!ProductService.isActive(product)) {
      return {
        success: false,
        insufficientQty: totalQty,
      };
    }
    if (!onHandQty && ProductStockService.isManageStock(product)) {
      return {
        success: false,
        insufficientQty: totalQty,
      };
    }
    const minSaleQty = ProductStockService.getMinSaleQty();
    if (minSaleQty > totalQty) {
      return {
        success: false,
        insufficientQty: totalQty,
      };
    }
    let maxSaleQty = ProductStockService.getMaxSaleQty();

    if (ProductStockService.isManageStock(product)) {
      const productQty = onHandQty;
      maxSaleQty = Math.min(maxSaleQty, productQty);
    } else {
      maxSaleQty = Math.max(maxSaleQty, 0);
    }

    if (totalQty > maxSaleQty) {
      return {
        success: false,
        insufficientQty: maxSaleQty >= 0 ? totalQty - maxSaleQty : totalQty,
      };
    }
    return {success: true};
  }

  /**
   * Verify Can Fulfill All Items
   * @param quote
   * @return {object}
   */
  async verifyCanFulfillAllItems(quote) {
    const quoteData = cloneDeep(quote);
    const quoteVariantQuantity = {};
    const productVariants = [];
    quoteData.lineItems.forEach((item) => {
      const variantId = item.variant.id;
      if (variantId) {
        if (quoteVariantQuantity[variantId]) {
          quoteVariantQuantity[variantId] += item.quantity;
        } else {
          quoteVariantQuantity[variantId] = item.quantity;
          productVariants.push(item);
        }
      }
    });
    const variantIds = Object.keys(quoteVariantQuantity);

    const locationId = localStorage.getItem('location_id');
    const input = {
      ids: variantIds,
      locationId,
      typeNameQuantities: ['on_hand'],
    };

    // Get qty on hand
    const response = await ProductService.getQuantityProductVariant(input);
    const quantityProductVariant = response?.data?.getQuantityProductVariant?.quantities || [];
    const variantsOnHandQty = quantityProductVariant.reduce((result, item) => {
      result[item.id] = item.quantity;
      return result;
    }, {});

    let isSuccess = true;
    const errorCompleteProductList = [];
    for (const productVariant of productVariants) {
      const product = cloneDeep(productVariant.product);
      const variant = cloneDeep(productVariant.variant);
      const quantity = quoteVariantQuantity[variant.id];
      const totalQuantity = parseFloat(quantity);
      const onHandQty = variantsOnHandQty[variant.id];
      const validateQtyResult = this.validateQtyCreateOrder(product, totalQuantity, onHandQty);
      if (!validateQtyResult.success) {
        errorCompleteProductList.push({
          product,
          variant,
          quantity,
          insufficientQty: validateQtyResult.insufficientQty,
        });
        isSuccess = false;
      }
    }
    return {
      isSuccess,
      errorCompleteProductList,
    };
  }

  /**
   * Add Custome Attribute Create Order
   * @param quoteData
   * @return {object}
   */
  addCustomAttributeCreateOrder(quoteData) {
    if (!quoteData.customAttributes) {
      quoteData.customAttributes = [];
    }
    quoteData.customAttributes.push({
      key: `${OrderConstant.CUSTOM_ATTRIBUTE_KEY_CASHIER} (username: ${staffService.getStaffUsername()})`,
      value: staffService.getStaffName(),
    });

    const currentLocationId = LocationService.getCurrentLocationId();
    const lastIndex = currentLocationId.lastIndexOf('/');
    const currentLocationIdNumeric = lastIndex >= 0 ? currentLocationId.slice(lastIndex + 1) : currentLocationId;
    quoteData.customAttributes.push({
      key: `${OrderConstant.CUSTOM_ATTRIBUTE_KEY_LOCATION} (id: ${currentLocationIdNumeric})`,
      value: LocationService.getCurrentLocationName(),
    });
    quoteData.customAttributes.push({
      key: `${OrderConstant.CUSTOM_ATTRIBUTE_KEY_POS} (id: ${posService.getCurrentPosId()})`,
      value: posService.getCurrentPosName(),
    });
    return quoteData;
  }

  /**
   * Remove Custom Attribute Line item
   * @param removeKeys // key list want to remove
   * @param quoteData
   * @return {object}
   */
  removeCustomAttributeLineItem(quoteData, removeKeys = []) {
    if (quoteData && quoteData.lineItems && quoteData.lineItems) {
      quoteData.lineItems.forEach((lineItem) => {
        if (!lineItem.customAttributes) {
          return;
        }
        const newCustomAttributes = [];
        lineItem.customAttributes.forEach((customAttribute) => {
          if (!removeKeys.includes(customAttribute.key)) {
            newCustomAttributes.push(customAttribute);
          }
        });
        lineItem.customAttributes = newCustomAttributes;
      });
    }
  }

  /**
   * Complete Order
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  orderComplete(input) {
    return this.getResourceModel().orderComplete(input);
  }

  /**
   * Update order after click button fulfill
   * @param {*} order
   * @returns
   */
  async updateOrderAfterClickBtnFulfill(order = {}, fulfillmentOrderLineItems) {
    const items = order?.lineItems?.items || [];

    const variantIds = items.map((item) => {
      return item.variant?.id || null;
    }).filter(Boolean);

    const locationId = localStorage.getItem('location_id');
    const input = {
      ids: variantIds,
      locationId,
      typeNameQuantities: ['on_hand'],
    };

    // Get qty on hand
    const response = await ProductService.getQuantityProductVariant(input);
    const quantities = response?.data?.getQuantityProductVariant?.quantities || [];
    const quantitiesById = _.keyBy(quantities, 'id');

    // Get qty left
    const rebuildFulfillmentOrderById = _.mapValues(fulfillmentOrderLineItems, (itemFulfillment) => {
      const groupQtyLeft = _.map(itemFulfillment, 'fulfillable_quantity');
      const qtyLeft = _.sum(groupQtyLeft);

      return {
        fulfillable_quantity: qtyLeft,
      };
    });

    if (order?.lineItems) {
      items.forEach((item) => {
        const partItemId = item?.id.split('/');
        const itemId = partItemId[partItemId.length - 1];

        item.qtyLeft = rebuildFulfillmentOrderById[itemId]?.fulfillable_quantity;

        const variantId = item.variant?.id;

        const isTracked = quantitiesById[variantId]?.isTracked;
        item.isTrackedQuantity = isTracked;
        item.qtyOnHand = isTracked ? quantitiesById[variantId]?.quantity : item.qtyLeft;
      });
    }

    return {
      ...order,
    };
  }

  /**
   * Can Create Fulfillment Order
   * @param {*} supported_actions
   * @returns {boolean}
   */
  canCreateFulfillmentOrder(supported_actions) {
    return supported_actions.includes(OrderConstant.STATUS_CREATE_FULFILLMENT);
  }

  /**
   * Build List Fulfillment Order Line Items
   * @param {*} items
   * @returns {[object]}
   */
  buildListFulfillmentOrderLineItems(items, fulfillmentOrderLineItems) {
    const data = items.reduce((result = {}, item) => {

      const partItemId = item?.id.split('/');
      const itemId = partItemId[partItemId.length - 1];

      const lineItems = fulfillmentOrderLineItems[itemId] || [];
      let quantity = Number(item.qtyFulfill);

      for (const lineItem of lineItems) {
        const line_item_by_fulfillment_order_id = String(lineItem?.id);
        const fulfillment_order_id = String(lineItem?.fulfillment_order_id);

        if (!result[fulfillment_order_id]) {
          result[fulfillment_order_id] = [];
        }
        const qtyFulfillable = Number(lineItem.fulfillable_quantity) || 0;
        const qtyToFulfill = Math.min(quantity, qtyFulfillable);
        if (qtyFulfillable < quantity) {
          quantity -= qtyFulfillable;
        }
        const status = lineItem.status;
        const supported_actions = lineItem.supported_actions || [];
        if (!quantity || status === 'closed' || !this.canCreateFulfillmentOrder(supported_actions)) {
          continue;
        }

        const subLineItem = {
          id: `gid://shopify/FulfillmentOrderLineItem/${line_item_by_fulfillment_order_id}`,
          quantity: qtyToFulfill,
          assigned_location_id: lineItem.assigned_location_id || '',
        };

        result[fulfillment_order_id].push(subLineItem);

      }

      return result;
    }, {});

    return data;
  }

  /**
   * Get Origin Address To Fultill Order
   * @returns {*}
   */
  getOriginAddressToFulfillOrder() {
    const address = LocationService.getAddressCurrentLocation();
    const infoOriginAddress = {
      address1: address.address1 || "",
      address2: address.address2 || "",
      city: address.city || "",
      countryCode: address.countryCode,
      provinceCode: address.provinceCode || "",
      zip: address.zip || "",
    };
    return infoOriginAddress;
  }

  /**
   * Build Input Create Fulfillment Order
   * @param {*} items
   * @returns {[object]}
   */
  async buildInputCreateFulfillmentOrder(items, fulfillmentOrderLineItems) {
    const listFulfillmentOrderLineItem = this.buildListFulfillmentOrderLineItems(items, fulfillmentOrderLineItems);

    const address = LocationService.getAddressCurrentLocation();

    const locationId = LocationService.getTailCurrentLocationId();

    const infoOriginAddress = {
      address1: address.address1 || "",
      address2: address.address2 || "",
      city: address.city || "",
      countryCode: address.countryCode,
      provinceCode: address.provinceCode || "",
      zip: address.zip || "",
    };

    const input = [];
    const idsOrderMoveSet = new Set();
    for (const [key, value] of Object.entries(listFulfillmentOrderLineItem)) {
      if (!value.length) {
        continue;
      }

      const assigned_location_id = value[0].assigned_location_id;

      if (Number(assigned_location_id) !== Number(locationId)) {
        idsOrderMoveSet.add(key);
      }
    }

    const inputOrderMove = [...idsOrderMoveSet].map((id) => {
      return {
        id: `gid://shopify/FulfillmentOrder/${id}`,
        newLocationId: `gid://shopify/Location/${locationId}`,
      };
    });

    let idFulfillOrder = null;
    if (inputOrderMove?.length) {
      const res = await this.fulfillmentOrderMove(inputOrderMove);

      const data = res.data.fulfillmentOrderMove;

      if (data.error) {
        throw new Error(`${res.error}`);
      }

      idFulfillOrder = data.idFulfillOrder;
    }

    const tempValuesForFulfillOrderOtherLocation = [];
    for (const [key, value] of Object.entries(listFulfillmentOrderLineItem)) {
      if (!value.length) {
        continue;
      }

      const tempValue = value.map((val) => {
        delete val?.assigned_location_id;
        if (!val.quantity) {
          return null;
        }
        return val;
      }).filter(Boolean);

      if (idFulfillOrder) {
        tempValuesForFulfillOrderOtherLocation.push(...tempValue);
      } else {
        input.push(
          {
            originAddress: infoOriginAddress,
            lineItemsByFulfillmentOrder: [
              {
                fulfillmentOrderId: `gid://shopify/FulfillmentOrder/${key}`,
                fulfillmentOrderLineItems: tempValue,
              },
            ],
          },
          );
      }
    }

    if (idFulfillOrder) {
      input.push(
        {
          originAddress: infoOriginAddress,
          lineItemsByFulfillmentOrder: [
            {
              fulfillmentOrderId: idFulfillOrder,
              fulfillmentOrderLineItems: tempValuesForFulfillOrderOtherLocation,
            },
          ],
        },
      );
    }

    return input;
  }

  /**
   * Create fulfillment order
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  createFulfillmentOrder(input) {
    return this.getResourceModel().createFulfillmentOrder(input);
  }

  /**
   * Fulfillment Order Move
   * @param {*} input
   * @returns {Promise}
   */
  fulfillmentOrderMove(input) {
    return this.getResourceModel().fulfillmentOrderMove(input);
  }

  /**
   * Rebuild Fulfillment Order Line Items
   * @param {*} fulfillmentOrder
   * @returns {[object]}
   */
  rebuildFulfillmentOrderLineItems(fulfillmentOrder) {
    const fulfillmentOrderLineItems = fulfillmentOrder.reduce((result = [], fo) => {
      const assigned_location_id = fo.assigned_location_id;
      const status = fo.status;
      const order_id = fo.order_id;
      const lineItems = fo?.line_items || [];
      const supported_actions = fo?.supported_actions || [];
      const itemsTemp = lineItems.map((li) => {
        return {
          ...li,
          assigned_location_id,
          status,
          order_id,
          supported_actions,
        };
      });

      return result.concat(itemsTemp);
    }, []);


    const rebuildLineItems = _.groupBy(fulfillmentOrderLineItems, 'line_item_id');

    return rebuildLineItems || {};
  }

  /**
   * Build Input Add Cash Transaction
   * @returns {object | null}
   */
  buildInputSessionAddTransaction() {
    let session = null;
    const current_session = SessionService.getSessionInProcess();

    if (!current_session) {
      return null;
    }

    if (SessionService.isEnableSessionManagement()) {
      session = {
        session_id: current_session.id,
        location_id: current_session.location_id,
        base_currency_code: current_session.base_currency_code,
      };
    }

    return session;
  }

   /** Is assigned to current location
   * @param {*} item
   * @returns {boolean}
   */
  isAssignedToCurrentLocation(item) {
    const qtyOnHand = item.qtyOnHand;

    if (qtyOnHand !== null && qtyOnHand !== undefined) {
      return true;
    }
    return false;
  }

  /**
   * create record refund
   * @param refundLineItems
   * @param transactions
   * @param shipping
   * @param note
   * @param order
   * @param shop
   * @return {Promise}
   */
  createRecordRefund(refundLineItems, transactions, shipping, note, order, shop, session) {
    return this.getResourceModel().createRecordRefund(refundLineItems, transactions, shipping, note, order, shop, session);
  }

  /**
   *
   * @param order
   * @param customer
   * @param shop
   */
  updateOrderCustomer(order, customer, shop) {
    return this.getResourceModel().updateOrderCustomer(order, customer, shop);
  }

  /**
   * Is Disable Btn Fulfill
   * @param {*} order
   * @returns {boolean}
   */
  isDisableBtnFulfillInBlockAction(order) {
    const items = order.lineItems?.items || [];
    let isProductNoGiftValid = false;

    for (const item of items) {
      const unfulfilledQuantity = item.unfulfilledQuantity || 0;

      if (unfulfilledQuantity !== null && Number(unfulfilledQuantity) === 0) {
        continue;
      }

      if (!item.product?.isGiftCard) {
        isProductNoGiftValid = true;
      }
    }

    if ((order.displayFulfillmentStatus === OrderConstant.FULFILLMENT_STATUS_UNFULFILLED ||
      order.displayFulfillmentStatus === OrderConstant.FULFILLMENT_STATUS_PARTIALLY_FULFILLED) && isProductNoGiftValid) {
      return false;
    }

    return true;
  }
}

/**
 * @type {OrderServiceClass}
 */
const OrderService = ServiceFactory.get(OrderServiceClass);
export default OrderService;
