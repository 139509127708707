import {combineReducers} from 'redux';

import OrderListReducer from './order/OrderListReducer';
import OrderDetailReducer from './order/OrderDetailReducer';
import OrderPaymentReducer from "./order/take-payment/OrderPaymentReducer";
import OrderCompleteReducer from "./order/OrderCompleteReducer";
import OrderRefundReducer from "./order/refund/OrderRefundReducer";

export default combineReducers({
  orderList: OrderListReducer,
  orderDetail: OrderDetailReducer,
  orderPayment: OrderPaymentReducer,
  orderComplete: OrderCompleteReducer,
  orderRefund: OrderRefundReducer,
});
