import React, {createContext, Fragment, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";
import '../../../../assets/EditPayment.css';
import {useDispatch, useSelector} from "react-redux";

import i18n from "../../../../config/i18n";
import FunctionComponentFactory from "../../../../framework/factory/FunctionComponentFactory";
import EditPaymentItem from "../../payment/EditPaymentItem.jsx";
import CheckoutPaymentAction from "../../../action/checkout/payment/CheckoutPaymentAction";
import OrderService from "../../../../service/order/OrderService";

export const EditPaymentCheckoutContext = createContext();

function EditPaymentCheckout(props) {
  const dispatch = useDispatch();
  const {grandTotal, remaining} = props;
  const [referenceNo, setReferenceNo] = useState('');
  const {selectedPayment, currentAmount, currentIndex, listPaid} = useSelector((state) => state.core.checkout.payment);
  const [amount, setAmount] = useState(currentAmount ? currentAmount : remaining);
  const handleBackAction = () => {
    if (currentAmount) {
      dispatch(CheckoutPaymentAction.handleOpenCompletePayment());
    } else {
      dispatch(CheckoutPaymentAction.closeEditPayment());
    }
  };

  const buttonAcceptClass = `btn btn-default btn-accept ${amount > 0 ? '' : 'disabled'}`;
  const buttonAcceptText = `${i18n.t("Accept")} ${i18n.t(OrderService.formatPrice(amount))}`;
  const handleAcceptPayment = () => {
    if (currentAmount) {
      dispatch(CheckoutPaymentAction.handleUpdatePaidItem(amount));
    } else {
      dispatch(CheckoutPaymentAction.handleAcceptPayment(selectedPayment, amount, referenceNo));
    }
  };

  const contextValue = useMemo(() => [amount, setAmount, referenceNo, setReferenceNo], [amount, setAmount, referenceNo, setReferenceNo]);

  return (
    <>
      <EditPaymentCheckoutContext.Provider value={contextValue}>
        <EditPaymentItem remaining={remaining} grandTotal={grandTotal} selectedPayment={selectedPayment} currentIndex={currentIndex} listPaid={listPaid} />
        <div className="block-bottom">
          <div className="actions-accept">
            <Button className="btn btn-default btn-cancel" type="button" onClick={() => handleBackAction()}>
              {i18n.t("Back")}
            </Button>
            <Button
              className={buttonAcceptClass} type="button" onClick={() => {
                if (amount > 0) {
                  handleAcceptPayment();
                }
              }}
            >
              {buttonAcceptText}
            </Button>
          </div>
        </div>
      </EditPaymentCheckoutContext.Provider>
    </>
  );
}

EditPaymentCheckout.className = 'EditPaymentCheckout';
EditPaymentCheckout.propTypes = {
  remaining: PropTypes.number,
  grandTotal: PropTypes.number,
};
export default FunctionComponentFactory.get(EditPaymentCheckout);
