import React from 'react';
import PropType from "prop-types";
import moment from 'moment';

import i18n from '../../../../../config/i18n';
import OrderService from '../../../../../service/order/OrderService';

const ContentPaymentItem = ({transaction, changePayment, isLastPayment, isPending}) => {
  if (!transaction) {
    return <></>;
  }
  const date = moment(transaction.createdAt).format("L");
  const dateDisplay = `(${date})`;
  const amountPaid = transaction?.amountSet?.shopMoney?.amount;

  if (isPending) {
    return (
      <li>
        <div className="title">{transaction?.formattedGateway} <span>{dateDisplay}</span>
        </div>
        <div className="value">
          <strong>{i18n.t('Pay Later')}</strong>
        </div>
      </li>
    );
  }

  if (isLastPayment && changePayment > 0) {
    const afterChangedAmount = Number(amountPaid) - Number(changePayment);
    const paidValueDisplay = i18n.t("Paid: {{paid}}", {paid: OrderService.formatPrice(amountPaid)});
    const changeValueDisplay = i18n.t("Change: {{change}}", {change: OrderService.formatPrice(changePayment)});
    return (
      <>
        <li>
          <div className="title">{transaction?.formattedGateway} <span>{dateDisplay}</span>
          </div>
          <div className="value">
            {OrderService.formatPrice(afterChangedAmount)}
          </div>
        </li>
        <li>
          <div className="title" />
          <div className="sub-value">
            {paidValueDisplay}
          </div>
        </li>
        <li>
          <div className="title" />
          <div className="sub-value">
            {changeValueDisplay}
          </div>
        </li>
      </>
    );
  }

  return (
    <>
      <li>
        <div className="title">{transaction?.formattedGateway} <span>{dateDisplay}</span>
        </div>
        <div className="value">
          {OrderService.formatPrice(amountPaid)}
        </div>
      </li>
    </>
  );
};

ContentPaymentItem.propTypes = {
  transaction: PropType.object.isRequired,
  changePayment: PropType.number,
  isLastPayment: PropType.bool,
  isPending: PropType.bool,
};

export default ContentPaymentItem;
