import _, {cloneDeep, merge} from 'lodash';

import ServiceFactory from '../../framework/factory/ServiceFactory';
import ProductService from '../product/ProductService';
import {fire} from '../../event-bus';
import ProductStockService from '../product/stock/ProductStockService';
import NumberService from '../NumberService';
import i18n from '../../config/i18n';
import DraftOrderService from '../draft-order/DraftOrderService';
import QuoteConstant from '../../view/constant/checkout/QuoteConstant';
import ShippingConstant from '../../view/constant/ShippingConstant';
import DiscountConstant from '../../view/constant/checkout/quote/DiscountConstant';

import AbstractQuoteService from './quote/AbstractService';
import AddProductService from './quote/AddProductService';
import QuoteTotalService from './quote/QuoteTotalService';
import QuoteItemService from './quote/QuoteItemService';
import ChangeCustomerService from './quote/ChangeCustomerService';
import QuoteUpdateProductService from './quote/QuoteUpdateProductService';
import AddressService from './quote/AddressService';

/**
 * Staff Service Class
 */
export class QuoteServiceClass extends AbstractQuoteService {
  static className = 'QuoteServiceClass';

  initialQuoteReducerState = {
    id: this.generateTmpId(),
    lineItems: [],
    customer: null,
    email: null,
    hasTimelineComment: false,
    billingAddress: null,
    shippingAddress: null,
    subtotalPrice: 0,
    totalPrice: 0,
    totalShippingPrice: 0,
    totalTax: 0,
    totalWeight: 0,
    payments: [],
    appliedDiscount: null,
    visibleToCustomer: false,
    customAttributes: [],
    localizationExtensions: null,
    note: null,
    phone: null,
    taxExempt: false,
    tags: [],
    shippingLine: {
      price: '0.00',
      shippingRateHandle: null,
      title: ShippingConstant.STORE_PICKUP_SHIPPING_METHOD_DESCRIPTION,
      custom: true,
    },
  };

  /**
   * Generate Tmp Id
   *
   * @returns {string}
   */
  generateTmpId() {
    return `tmp-quote-${new Date().getTime()}`;
  }

  /**
   * Reset quote
   *
   * @return {{}}
   */
  resetQuote() {
    return {
      ...this.changeCustomer(
        {
          ...cloneDeep(this.initialQuoteReducerState),
          id: this.generateTmpId(),
        },
      ),
    };
  }

  /**
   * Create default quote data
   *
   * @param quote
   */
  createDefaultQuoteData(quote) {
    AddressService.createTempAddress(quote);
  }

  /**
   * Update Quote totals
   *
   * @param quote
   * @param total
   * @returns {*}
   */
  updateQuoteTotals(quote, total) {
    for (const [key, value] of Object.entries(total)) {
      switch (key) {
        default:
          quote[key] = value;
      }
    }
  }

  /**
   * Collect Totals
   * @param quote
   * @returns {*}
   */
  collectTotals(quote) {
    const total = QuoteTotalService.collectTotals(quote);
    this.updateQuoteTotals(quote, total);
    return quote;
  }

  /**
   * add product to quote
   * @param quote
   * @param data
   * @returns {*}
   */
  addProduct(quote, data, isShowErrorToastMessage = true) {
    this.createDefaultQuoteData(quote);
    const addProductResult = AddProductService.addProduct(quote, data, isShowErrorToastMessage);

    if (addProductResult.success === false) {
      return addProductResult;
    }

    fire('service_quote_add_product_after', {quote});

    return {
      success: true,
      quote: this.collectTotals(quote),
      added_item_id: addProductResult.added_item_id,
    };
  }

  /**
   *
   * @param product
   * @returns {boolean}
   */
  isLoadFullData(product) {
    return (typeof product.variants !== 'undefined');
  }

  /**
   * Add Product Without Options
   * @param product
   * @returns {Promise<{product, quantity: number, variant: *}>}
   */
  async addProductWithoutOptions(product) {
    const loaderCarts = document.getElementsByClassName('loader-cart');
    const loadingCart = loaderCarts && loaderCarts.length ? loaderCarts[0] : null;
    if (!this.isLoadFullData(product)) {
      window.pendingAddNoOptionRequest = window.pendingAddNoOptionRequest ? window.pendingAddNoOptionRequest + 1 : 1;
      if (loadingCart) {
        loadingCart.style.display = 'block';
      }
      try {
        const response = await ProductService.getProductWithOneVariant(product.id);
        Object.assign(product, response.data.getProductWithOneVariant);
        window.pendingAddNoOptionRequest--;
      } catch (error) {
        window.pendingAddNoOptionRequest--;
      }
      if (!window.pendingAddNoOptionRequest && loadingCart) {
        loadingCart.style.display = 'none';
      }
    }

    const variant = product.variants[0];
    return {
      product,
      variant,
      quantity: ProductStockService.getQtyIncrement(),
    };
  }

  /**
   * Get Total Items Qty
   * @param quote
   * @returns {number}
   */
  getTotalItemsQty(quote) {
    let totalQty = 0;
    if (quote.lineItems && quote.lineItems.length) {
      quote.lineItems.forEach((item) => {
        totalQty = NumberService.addNumber(
          totalQty,
          QuoteItemService.getTotalQty(item),
        );
      });
    }
    return totalQty;
  }

  /**
   * Get Items
   * @param quote
   * @returns {[]}
   */
  getItems(quote) {
    return quote.lineItems;
  }

  /**
   * Get customer
   * @param quote
   * @returns {null|*}
   */
  getCustomer(quote) {
    return quote.customer;
  }

  /**
   * Get Customer Name
   * @param quote
   * @returns {string}
   */
  getCustomerName(quote) {
    let customerName = i18n.t('Guest');
    if (quote && quote.customer && quote.customer.displayName) {
      customerName = quote.customer.displayName;
    }
    return customerName;
  }

  /**
   * Get Line Items Subtotal
   * @param quote
   * @returns {number}
   */
  getLineItemsSubtotal(quote) {
    return quote?.lineItemsSubtotalPrice?.shopMoney?.amount ? Number(quote.lineItemsSubtotalPrice.shopMoney.amount) : 0;
  }


  /**
   * Get Subtotal
   * @param quote
   * @returns {number}
   */
  getSubtotal(quote) {
    return quote.subtotalPrice ? Number(quote.subtotalPrice) : 0;
  }

  /**
   * Get Subtotal
   * @param quote
   * @returns {number}
   */
  getSubtotalWithoutDiscount(quote) {
    return NumberService.addNumber(this.getSubtotal(quote), this.getTotalDiscountAmount(quote));
  }

  /**
   * Get Total Shipping Amount
   * @param quote
   * @returns {number|number}
   */
  getTotalShippingAmount(quote) {
    return quote.totalShippingPrice ? Number(quote.totalShippingPrice) : 0;
  }

  /**
   * Get Total Discount Amount
   * @param quote
   * @returns {number|number}
   */
  getTotalDiscountAmount(quote) {
    let totalDiscount = 0;
    if (quote.totalDiscountsSet) {
      totalDiscount = quote.totalDiscountsSet.shopMoney.amount;
    }
    return totalDiscount;
  }

  /**
   * Get  Order Discount Amount
   * @param quote
   * @returns {number|number}
   */
  getOrderDiscountAmount(quote) {
    let totalDiscount = 0;
    if (quote.appliedDiscount) {
      totalDiscount = quote.appliedDiscount.amountSet.shopMoney.amount;
    }
    return totalDiscount;
  }

  /**
   *  remove cart item
   * @param quote
   * @param item
   * @return {*}
   */
  removeItem(quote, item) {
    quote.lineItems = quote.lineItems.filter(
      (lineItem) => lineItem.id !== item.id,
    );
    AddressService.createTempAddress(quote);

    fire('service_quote_remove_cart_item_after', {quote});

    return {
      success: true,
      quote: this.collectTotals(quote),
    };
  }

  /**
   * Customer for quote
   *
   * @param {object} quote
   * @param {object} customer
   */
  changeCustomer(quote, customer = null) {
    return ChangeCustomerService.changeCustomer(quote, customer);
  }

  /**
   * update qty after change on number pad
   *
   * @param quote
   * @param item
   * @param qty
   * @returns {*}
   */
  updateQtyCartItem(quote, item, qty) {
    AddressService.createTempAddress(quote);
    const updateProductServiceResult = QuoteUpdateProductService.updateQty(quote, item, qty);
    if (updateProductServiceResult.success === false) {
      return updateProductServiceResult;
    }
    fire('service_quote_update_qty_cart_item_after', {quote: updateProductServiceResult.quote});
    return {
      success: true,
      quote: this.collectTotals(updateProductServiceResult.quote),
    };
  }

  /**
   * Validate Is Applied Discount Except Custom Discount
   * @param {*} quote
   * @returns {boolean}
   */
  isAppliedDiscountExceptCustomDiscountLineItem(quote) {
    if (quote.couponCode) {
      return true;
    }
    if (quote.lineItems && quote.lineItems.length > 0) {
      for (const lineItem of quote.lineItems) {
        if (lineItem.appliedDiscount && !this.isAppliedCustomDiscountLineItem(lineItem)) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Validate Is Applied Custom Discount Line Item
   * @param {*} lineItem
   * @returns {boolean}
   */
  isAppliedCustomDiscountLineItem(lineItem) {
    return lineItem && lineItem.appliedDiscount && lineItem.appliedDiscount?.description?.includes(DiscountConstant.POS_CUSTOM_DISCOUNT_LINE_ITEM_CODE);
  }

  /**
   * Validate Is Applied Custom Discount Line Items In Quote
   * @param {*} quote
   * @returns {boolean}
   */
  isAppliedCustomDiscountLineItemInQuote(quote) {
    if (quote.lineItems && quote.lineItems.length > 0) {
      for (const lineItem of quote.lineItems) {
        if (lineItem.appliedDiscount && this.isAppliedCustomDiscountLineItem(lineItem)) {
          return true;
        }
      }
    }
    return false;
  }


  /**
   * Handle Remove Quote Discount Except Custom Discount
   * @param {*} quote
   * @returns {quote: *}
   */
  removeQuoteDiscountExceptCustomDiscount(quote) {
    const quoteData = cloneDeep(quote);
    delete quoteData.ignoreDiscounts;
    delete quoteData.couponCode;
    delete quoteData.os_pos_custom_discount_reason;
    delete quoteData.os_pos_custom_discount_type;
    delete quoteData.os_pos_custom_discount_amount;

    let isHaveListItemDiscount = false;
    quoteData.lineItems = quoteData.lineItems.map(
      (lineItem) => {
        if (lineItem.appliedDiscount && !this.isAppliedCustomDiscountLineItem(lineItem)) {
          lineItem.appliedDiscount = null;
          isHaveListItemDiscount = true;
        }
        return lineItem;
      },
    );
    if (quoteData.appliedDiscount) {
      quoteData.appliedDiscount = null;
    }
    if (isHaveListItemDiscount) {
      this.reMergeDivineItem(quoteData);
    }
    return {
      quote: this.collectTotals(quoteData),
    };
  }

  /**
   * Re-merge divine item
   * @param quote
   */
  reMergeDivineItem(quote) {
    for (let i = 0; i < quote.lineItems.length; i++) {
      if (quote.lineItems[i]) {
        const item = quote.lineItems[i];
        if (item.customAttributes) {
          const lineItemKeyId = item.customAttributes.find((attribute) => {
            return attribute.key === QuoteConstant.TMP_ITEM_ID_CUSTOM_ATTRIBUTE_KEY;
          });
          if (lineItemKeyId) {
            const itemId = lineItemKeyId.value;
            if (itemId.includes('discountxy')) {
              const found = quote.lineItems.findIndex(
                (element) => {
                  const lineItemKeyParentId = element.customAttributes.find((attribute) => {
                    return attribute.key === QuoteConstant.TMP_ITEM_ID_CUSTOM_ATTRIBUTE_KEY;
                  });
                  return Boolean(lineItemKeyParentId && lineItemKeyParentId.value &&
                    itemId.includes(lineItemKeyParentId.value));
                },
              );
              if (found > -1) {
                const lineItemParent = quote.lineItems[found];
                lineItemParent.quantity += item.quantity;
              }
            }
          }
        }
      }
    }
    quote.lineItems = quote.lineItems.filter(
      (item) => {
        if (!item.customAttributes) {
          return true;
        }
        const lineItemKeyId = item.customAttributes.find((attribute) => {
          return attribute.key === QuoteConstant.TMP_ITEM_ID_CUSTOM_ATTRIBUTE_KEY;
        });
        if (lineItemKeyId) {
          return !lineItemKeyId.value.includes('discountxy');
        } else {
          return false;
        }
      },
    );
    return quote;
  }

  /**
   * Handle Remove Quote Custom Discount Line Items
   * @param {*} quote
   * @param {boolean} isReMergeLineItems
   * @returns {quote: *}
   */
  removeQuoteCustomDiscountLineItem(quote, isReMergeLineItems = false) {
    const quoteData = cloneDeep(quote);
    quoteData.lineItems = quoteData.lineItems.map(
        (lineItem) => {
          if (lineItem.appliedDiscount && this.isAppliedCustomDiscountLineItem(lineItem)) {
            lineItem.appliedDiscount = null;
          }
          return lineItem;
        },
      );
    if (isReMergeLineItems) {
      this.reMergeLineItemsSameProductVariant(quoteData);
    }
    return {
      quote: this.collectTotals(quoteData),
    };
  }

  /**
   * Re-merge line items have same product variant
   * @param quote
   */
  reMergeLineItemsSameProductVariant(quote) {
    const parentLineItems = [];
    for (const lineItem of quote.lineItems) {
      const foundParentLineItem = parentLineItems.find((parentLineItem) => parentLineItem.variantId === lineItem.variantId);
      if (foundParentLineItem) {
        foundParentLineItem.quantity += lineItem.quantity;
      } else {
        parentLineItems.push(cloneDeep(lineItem));
      }
    }
    quote.lineItems = parentLineItems;
  }


  /**
   * Merge Quote And Calculated Draft Order
   * @param quote
   * @param calculatedDraftOrder
   * @returns {*}
   */
  mergeQuoteAndCalculatedDraftOrder(quote, calculatedDraftOrder) {
    const calculatedLineItemsMapping = {};
    calculatedDraftOrder.lineItems.forEach((item) => {
      let tmpItemIdAttribute = null;
      if (item.customAttributes) {
        tmpItemIdAttribute = item.customAttributes.find((attribute) => {
          return attribute.key === QuoteConstant.TMP_ITEM_ID_CUSTOM_ATTRIBUTE_KEY;
        });
      }
      if (tmpItemIdAttribute) {
        calculatedLineItemsMapping[tmpItemIdAttribute.value] = item;
      }
    });

    delete calculatedDraftOrder.lineItems;

    const lineItemIds = this.getAllTmpItemIds(quote);

    const quoteData = merge(quote, calculatedDraftOrder);
    quoteData.lineItems = quoteData.lineItems?.map((lineItem) => {
      const calculatedItem = calculatedLineItemsMapping[lineItem.id];
      if (calculatedItem) {
        return merge(lineItem, calculatedItem);
      }
      return lineItem;
    });

    // Subtotal price to display in Catalog page is quote's lineItemsSubtotalPrice
    quoteData.subtotalPrice = quote.lineItemsSubtotalPrice && quote.lineItemsSubtotalPrice.shopMoney
                              ? quote.lineItemsSubtotalPrice.shopMoney.amount
                              : quote.subtotalPrice;

    /* Add discounted item (buy x get y rule) */
    const addedItem = [];
    for (const [key, value] of Object.entries(calculatedLineItemsMapping)) {
      quoteData.lineItems.forEach(
        (lineItem) => {
          if (!key.includes(lineItem.id) || (lineItemIds.includes(key))) {
            return;
          }
          const newLineItem = _.cloneDeep(lineItem);
          newLineItem.id = key;
          merge(newLineItem, value);
          addedItem.push(newLineItem);
        },
      );
    }

    /* End Add discounted item (buy x get y rule) */
    quoteData.lineItems = quoteData.lineItems.concat(addedItem);
    quoteData.lineItems = quoteData.lineItems.filter(
      (item) => {
        if (item.customAttributes) {
          const tmpItemIdAttribute = item.customAttributes.find((attribute) => {
            return attribute.key === QuoteConstant.TMP_ITEM_ID_CUSTOM_ATTRIBUTE_KEY;
          });
          if (tmpItemIdAttribute?.value && calculatedLineItemsMapping[tmpItemIdAttribute.value]) {
            return true;
          }
        }
        return false;
      },
    );
    return quoteData;
  }

  /**
   * Get all tmp items ids
   * @param quote
   * @returns {unknown[] | undefined}
   */
  getAllTmpItemIds(quote) {
    return quote.lineItems?.map((lineItem) => {
      let tmpItemIdAttribute = null;
      if (lineItem.customAttributes) {
        tmpItemIdAttribute = lineItem.customAttributes.find((attribute) => {
          return attribute.key === QuoteConstant.TMP_ITEM_ID_CUSTOM_ATTRIBUTE_KEY;
        });
      }
      if (tmpItemIdAttribute) {
        return lineItem.id;
      } else {
        return null;
      }
    });
  }

  /**
   * Calculate Totals Online
   * @param {*} quote
   * @param {boolean} isDisableField
   * @param {[string]} fieldsDisable
   * @returns {Promise<*>}
   */
  async calculateTotalsOnline(quote, isDisableField, fieldsDisable = [], isNoLoadingCart) {
    const loaderCarts = document.getElementsByClassName('loader-cart');
    const loadingCart = loaderCarts && loaderCarts.length ? loaderCarts[0] : null;
    if (loadingCart && !isNoLoadingCart) {
      loadingCart.style.display = 'block';
    }
    let quoteData = cloneDeep(quote);

    if (isDisableField) {
      fieldsDisable.forEach((field) => {
        quoteData[field] = null;
      });
    }

    try {
      const draftOrderInput = DraftOrderService.convertQuoteToDraftOrderInput(quoteData);
      const response = await DraftOrderService.draftOrderCalculate(draftOrderInput);

      if (loadingCart) {
        loadingCart.style.display = 'none';
      }

      if (response.data.draftOrderCalculate && response.data.draftOrderCalculate.calculatedDraftOrder) {
        const calculatedDraftOrder = response.data.draftOrderCalculate.calculatedDraftOrder;
        quoteData = this.mergeQuoteAndCalculatedDraftOrder(quoteData, calculatedDraftOrder);
      } else {
        throw new Error(i18n.t("Calculate totals failed!"));
      }
      return quoteData;
    } catch (error) {
      if (loadingCart) {
        loadingCart.style.display = 'none';
      }
      throw new Error(i18n.t(error.message));
    }

  }
}

/**
 * @type {QuoteServiceClass}
 */
const QuoteService = ServiceFactory.get(QuoteServiceClass);
export default QuoteService;
