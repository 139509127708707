/**
 * Object manager
 */
export class ObjectManagerClass {
  instances = {};

    /**
     * get object
     * @param Class
     * @returns {*}
     */
  get(Class) {
    const className = Class.className;
    if (!className) {
      return new Class();
    }

    if (!this.instances[className]) {
      this.instances[className] = new Class();
    }
    return this.instances[className];
  }
}

/**
 *
 * @type {ObjectManagerClass}
 */
const objectManager = new ObjectManagerClass();
export default objectManager;
