import StaffResourceModel from '../../resource-model/staff/StaffResourceModel';
import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import LocalStorageService from '../LocalStorageService';
import LocalStorageConstant from '../../view/constant/LocalStorageConstant';
import PosService from '../pos/PosService';
import Config from '../../config/Config';

/**
 * Staff Service Class
 */
export class StaffServiceClass extends CoreService {
  static className = 'StaffServiceClass';
  resourceModel = StaffResourceModel;

  /**
   * login by username and password
   * @param username
   * @param password
   * @param shop
   * @returns {promise}
   */
  login(username, password, shop = '') {
    return this.getResourceModel().login(username, password, shop);
  }

  /**
   * check has pending sync request yet
   * call api log out and remove session from storage
   *
   * @return {Promise<{ok: boolean}>}
   */
  async logout() {
    try {
      await this.getResourceModel().logout();
    } catch (error) {
      // handle error
    } finally {
      await this.resetAllData();
    }

    return {ok: true};
  }

  resetAllData() {
    this.removeStaff();
    this.removeToken();
    PosService.removeCurrentPos();
    this.setSharingAccount('');
    return this;
  }

  /**
   *
   * @param sharingAccount
   */
  setSharingAccount(sharingAccount) {
    LocalStorageService.set(LocalStorageService.SHARING_ACCOUNT, sharingAccount);
    return this;
  }

  /**
   * get sharing account local storage
   *
   * @return {string}
   */
  getSharingAccount() {
    return LocalStorageService.get(LocalStorageService.SHARING_ACCOUNT);
  }

  /**
   * remove token from localStorage
   */
  removeToken() {
    LocalStorageService.removeToken();
  }

  /**
   * remove staff id and staff name local storage
   *
   * @return void
   */
  removeStaff() {
    LocalStorageService.remove(LocalStorageService.STAFF_ID);
    LocalStorageService.remove(LocalStorageService.STAFF_NAME);
    Config[LocalStorageService.STAFF_ID] = '';
    Config[LocalStorageService.STAFF_NAME] = '';
  }

  /**
   * Get Permissions
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getPermissions() {
    return this.getResourceModel().getPermissions();
  }

  /**
   *
   * @returns {promise}
   */
  continueLogin() {
    return this.getResourceModel().continueLogin();
  }

  /**
   * Save all config to Local storage
   * @param data
   */
  savePermissionsToLocalStorage(data) {
    LocalStorageService.set(LocalStorageConstant.PERMISSIONS, JSON.stringify(data));
  }

  /**
   * Get Denominations From Local Storage
   * @returns {any|null}
   */
  getPermissionsFromLocalStorage() {
    const permissions = LocalStorageService.get(LocalStorageConstant.PERMISSIONS);
    return permissions ? JSON.parse(permissions) : [];
  }

  getStaffId() {
    return LocalStorageService.get(LocalStorageService.STAFF_ID);
  }

  /**
   * save staff id and staff name local storage
   *
   * @param staffId
   * @param staffName
   * @param staffEmail
   * @param staffUsername
   * @param shop
   */
  saveStaff(staffId, staffName, staffEmail, staffUsername, shop) {
    LocalStorageService.set(LocalStorageService.STAFF_ID, staffId);
    LocalStorageService.set(LocalStorageService.OLD_STAFF_ID, staffId);
    LocalStorageService.set(LocalStorageService.STAFF_NAME, staffName);
    LocalStorageService.set(LocalStorageService.STAFF_EMAIL, staffEmail);
    LocalStorageService.set(LocalStorageService.STAFF_USERNAME, staffUsername);
    LocalStorageService.set(LocalStorageService.SHOP, shop);
    Config[LocalStorageService.STAFF_ID] = staffId;
    Config[LocalStorageService.OLD_STAFF_ID] = staffId;
    Config[LocalStorageService.STAFF_NAME] = staffName;
    Config[LocalStorageService.STAFF_EMAIL] = staffEmail;
    Config[LocalStorageService.STAFF_USERNAME] = staffUsername;
    Config[LocalStorageService.SHOP] = shop;
  }

  /**
   * get logo from local storage
   */
  getLocalLogo() {
    return LocalStorageService.get(LocalStorageService.LOGO_URL);
  }

  /**
   * get shop url from local storage
   */
  getShopUrl() {
    return LocalStorageService.get(LocalStorageService.SHOP);
  }

  /**
   * save logo to local storage
   * @param logoUrl
   */
  saveLocalLogo(logoUrl) {
    LocalStorageService.set(LocalStorageService.LOGO_URL, logoUrl);
  }

  /**
   * get old staff id from local storage
   * @return {*|string}
   */
  getOldStaffId() {
    return LocalStorageService.get(LocalStorageService.OLD_STAFF_ID);
  }

  /**
   * save token to local storage
   *
   * @param token
   */
  saveToken(token) {
    LocalStorageService.setToken(token);
    Config.token = token;
  }

  /**
   * get staff name local storage
   *
   * @return {string}
   */
  getStaffName() {
    return LocalStorageService.get(LocalStorageService.STAFF_NAME);
  }

  /**
   * get staff username local storage
   *
   * @return {string}
   */
  getStaffUsername() {
    return LocalStorageService.get(LocalStorageService.STAFF_USERNAME);
  }

  /**
   * get staff email local storage
   *
   * @return {string}
   */
  getStaffEmail() {
    return LocalStorageService.get(LocalStorageService.STAFF_EMAIL);
  }

  /**
   * user change password
   * @param username
   * @param currentPassword
   * @param newPassword
   * @param shop
   */
  changePassword(username, currentPassword, newPassword, shop) {
    return this.getResourceModel().changePassword(username, currentPassword, newPassword, shop);
  }


  /**
   * validate password
   * @param password
   *
   * @return boolean
   */
  validatePassword(password) {
    const alphabeticRegExp = /(?=.*?[a-zA-Z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const alphabeticPassword = alphabeticRegExp.test(password);
    const digitsPassword = digitsRegExp.test(password);
    if (!alphabeticPassword || !digitsPassword) {
      return false;
    }

    return true;
  }
}


/**
 * @type {StaffServiceClass}
 */
const staffService = ServiceFactory.get(StaffServiceClass);

export default staffService;
