const CountryConstant = {
  GET_ALL_COUNTRIES: '[COUNTRY] GET_ALL_COUNTRIES',
  GET_ALL_COUNTRIES_RESULT: '[CONFIG] GET_ALL_COUNTRIES_RESULT',
  GET_ALL_COUNTRIES_ERROR: '[CONFIG] GET_ALL_COUNTRIES_ERROR',
  TYPE_GET_ALL_COUNTRIES: 'get_all_countries',

  KW_COUNTRY_CODE: "KW",
  KWD_CURRENCY_CODE: "KWD",
};

export default CountryConstant;
