import React, {Fragment, useState} from 'react';
import SmoothScrollbar from 'smooth-scrollbar';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";

import FunctionComponentFactory from "../../../../../framework/factory/FunctionComponentFactory";
import CustomerPopup from "../../../checkout/customer/CustomerPopup.jsx";
import i18n from "../../../../../config/i18n";
import OrderConstant from "../../../../constant/OrderConstant";
import OrderRefundAction from "../../../../action/order/refund/OrderRefundAction";
import RefundPrintComponent from "../../../print/RefundPrintComponent.jsx";
import CustomerAction from "../../../../action/CustomerAction";
import OrderAction from '../../../../action/OrderAction';
import OrderService from '../../../../../service/order/OrderService';

const RefundSuccess = (props) => {
  const {order} = props;
  const storeInfo = useSelector((state) => state.core.config.configs.shopConfig);
  const {adjustments} = useSelector((state) => state.core.order.orderRefund);

  const setScrollbarElement = (element) => {
    if (!element) {
      return;
    }
    SmoothScrollbar.init(element);
  };

  const dispatch = useDispatch();
  const [emailToCreateCustomer, setEmailToCreateCustomer] = useState('');
  const [validateEmailCreateCustomer, setValidateEmailCreateCustomer] = useState(false);

  const validateCreateAccount = false;
  const classValidateCreateAccount = validateCreateAccount ? 'hidden' : 'validation-text';
  const regexEmail = OrderConstant.REGEX_EMAIL;
  const [isReprint, setIsReprint] = useState(false);

  const handleClickCreateCustomer = (email) => {
    if (regexEmail.test(email)) {
      dispatch(CustomerAction.hideCustomerListPopup());
      dispatch(CustomerAction.showCreateCustomerPopup(true));
    } else {
      setValidateEmailCreateCustomer(true);
    }
  };
  return (
    <>
      <div className="block-content" data-scrollbar ref={setScrollbarElement}>
        <div className="block-refund-success">
          <div className="icon" />
          <div className="">
            {i18n.t("Order {{name}} has been refunded ", {name: order.name})}
            <br />
            {i18n.t("{{price}} successfully.", {price: OrderService.getPrice(adjustments.Refund.amount)})}
          </div>
          <div className="box-email">
            {!order.customer &&
              <>
                <div className="form-group">
                  {i18n.t("Create Customer Account")}
                  <div className="note">
                    {i18n.t("Order will be added to this account after being created.")}
                  </div>
                </div>
                <div className="control">
                  <input
                    type="text"
                    className="form-control"
                    aria-label={i18n.t("Create Customer Account")}
                    autoComplete="none"
                    placeholder={i18n.t("Enter customer's email address, e.g. johndoe@domain.com.")}
                    value={emailToCreateCustomer}
                    onChange={(event) => {
                      setEmailToCreateCustomer(event.target.value);
                      if (validateEmailCreateCustomer) {
                        setValidateEmailCreateCustomer(false);
                      }
                    }}
                  />
                  <button
                    className={`btn btn-default ${emailToCreateCustomer.length > 0 ? ' ' : ' disabled'}`}
                    type="button"
                    onClick={() => {
                      handleClickCreateCustomer(emailToCreateCustomer);
                    }}
                  >
                    {i18n.t("Create")}
                  </button>
                  {
                    validateEmailCreateCustomer &&
                    <div className={classValidateCreateAccount}>
                      {i18n.t("Please enter a valid email address. For example johndoe@domain.com.")}
                    </div>
                  }
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <div className="block-bottom">
        <div className="actions-accept">
          <button
            className="btn btn-cannel "
            type="button"
            onClick={() => {
              dispatch(OrderRefundAction.handleCloseRefund());
              dispatch(OrderAction.getOrder(order.id));
            }}
          >
            {i18n.t("Return to Order")}
          </button>
          <button
            className="btn btn-cancel"
            type="button"
            onClick={() => { setIsReprint(true); }}
          >
            {i18n.t("Print Receipt")}
            {
              isReprint &&
              <div style={{display: "none"}}>
                <RefundPrintComponent isReprint setIsReprint={setIsReprint} refundPrint orderData={order} storeInfo={storeInfo} adjustments={adjustments} />
              </div>
            }
          </button>
        </div>
      </div>;
      <CustomerPopup />
    </>
  );
};

RefundSuccess.propTypes = {
  order: PropTypes.object,
};

RefundSuccess.className = 'RefundSuccessComponent';
export default FunctionComponentFactory.get(RefundSuccess);
