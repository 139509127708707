import AbstractResourceModel from "../AbstractResourceModel";

export default class StaffResourceModel extends AbstractResourceModel {
  static className = 'StaffResourceModel';

  resourceName = 'Staff';

  /**
   * login by username and password
   * @param username
   * @param password
   * @param shop
   * @returns {promise}
   */
  login(username, password, shop = '') {
    return this.getResourceOnline().login(username, password, shop);
  }

  /**
   * logout
   *
   * @return: Promise
   */
  logout() {
    return this.getResourceOnline().logout();
  }

  /**
   * Get Permissions
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getPermissions() {
    return this.getResourceOnline().getPermissions();
  }

  /**
   *
   * @returns {*|promise|{type}}
   */
  continueLogin() {
    return this.getResourceOnline().continueLogin();
  }

  /**
   * Change Password by username, currentPassword and newPassword
   *
   * @param username
   * @param currentPassword
   * @param newPassword
   * @param shop
   * @return: promise
   */
  changePassword(username, currentPassword, newPassword, shop) {
    return this.getResourceOnline().changePassword(username, currentPassword, newPassword, shop);
  }
}
