import AbstractFactory from "./AbstractFactory";

export class FunctionComponentFactoryClass extends AbstractFactory {

    /**
     * Get function Component to use
     *
     * @param object
     * @return {*}
     */
  get(object) {
    return this.getObject(`function_component`, object);
  }

}

const functionComponentFactory = new FunctionComponentFactoryClass();

export default functionComponentFactory;
