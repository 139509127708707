import ObjectManager from "../ObjectManager";

import AbstractFactory from "./AbstractFactory";

export class ActionFactoryClass extends AbstractFactory {

  /**
   * get target service to use
   *
   * @param object
   * @return {*}
   */
  get(object) {
    return ObjectManager.get(this.getObject(`Action`, object));
  }
}

const actionFactory = new ActionFactoryClass();

export default actionFactory;
