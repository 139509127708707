import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from './GraphqlAbstract';

export class GraphqlCollectionClass extends GraphqlAbstract {
  static className = 'GraphqlCollectionClass';

  /**
   * Get Products
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getCollections(input) {
    return this.queryWithGQLBuilder(
      'getCollections',
      {
        input: {
          name: 'input',
          type: 'GetCollectionsInput',
          value: input,
          required: true,
        },
      },
      [
        {
          items: [
            'id',
            'title',
          ],
        },
        {
          pageInfo: [
            'hasNextPage',
            'endCursor',
            'hasPreviousPage',
            'startCursor',
          ],
        },
      ],
      {
        operationName: 'getCollections',
      },
    );
  }

}

/**
 * @type {GraphqlCollectionClass}
 */
export default DataResourceFactory.get(GraphqlCollectionClass);
