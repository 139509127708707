import React from 'react';
import ReactDOM from 'react-dom/client';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';

import registerServiceWorker from './registerServiceWorker';
import App from './view/App.jsx';
import i18n from './config/i18n';
import storeRedux from './view/store/store';


const root = ReactDOM.createRoot(document.getElementById('root'));
const title = 'Magestore POS';
const baseUrl = process.env.REACT_APP_CLIENT_APP_URL;

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <Provider store={storeRedux()}>
          <Helmet>
            <title>{title}</title>
            <link rel="icon" href={`${baseUrl}PwaPOS-favicon`} />
            <link rel="manifest" href={`${baseUrl}manifest.json`} />
            <link rel="shortcut icon" href={`${baseUrl}PwaPOS-favicon`} />
            <link rel="icon" type="image/png" sizes="512x512" href={`${baseUrl}images/512x512.png`} />
            <link rel="apple-touch-icon" type="image/png" sizes="512x512" href={`${baseUrl}images/512x512.png`} />
            <link rel="apple-touch-icon" type="image/png" sizes="152x152" href={`${baseUrl}images/touch-icon-ipad.png`} />
            <link rel="apple-touch-icon" type="image/png" sizes="180x180" href={`${baseUrl}images/touch-icon-iphone-retina.png`} />
            <link rel="apple-touch-icon" type="image/png" sizes="167x167" href={`${baseUrl}images/touch-icon-ipad-retina.png`} />
          </Helmet>
          <App />
        </Provider>
      </I18nextProvider>
    </HelmetProvider>
  </React.StrictMode>,
);

registerServiceWorker();
