import {cloneDeep} from "lodash";

import ServiceFactory from "../../../framework/factory/ServiceFactory";
import QuoteService from '../QuoteService';

import AbstractQuoteService from "./AbstractService";


export class QuoteCustomChargeTaxServiceClass extends AbstractQuoteService {
  static className = 'QuoteCustomChargeTaxServiceClass';

  /**
   * Apply Custom Charge Tax
   *
   * @param quote
   * @param taxExempt
   * @returns {Promise<{quote: *}>}
   */
  async applyCustomChargeTax(quote, taxExempt) {
    const quoteData = cloneDeep(quote);
    quoteData.taxExempt = taxExempt;
    return {
      quote: await QuoteService.calculateTotalsOnline(quoteData),
    };
  }

}

/** @type QuoteCustomChargeTaxServiceClass */
const QuoteCustomChargeTaxService = ServiceFactory.get(QuoteCustomChargeTaxServiceClass);

export default QuoteCustomChargeTaxService;
