import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import NumPad from '../../lib/react-numpad';
import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import CurrencyService from '../../../../service/CurrencyService';
import SessionConstant from '../../../constant/SessionConstant';

const Denomination = ({denomination, updateDenomination}) => {
  const {t} = useTranslation();

  const handelClickMinus = (id) => {
    updateDenomination(id, SessionConstant.BUTTON_MINUS);
  };

  const handleClickPlus = (id) => {
    updateDenomination(id, SessionConstant.BUTTON_PLUS);
  };

  const handleChangeNumPad = (id, value) => {
    updateDenomination(id, SessionConstant.CHANGE_NUMPAD, value);
  };

  return (
    <>
      <li>
        <div className="title">{denomination.name}</div>
        <div className="number">
          <div className="product-field-qty">
            <div className="box-field-qty" >
              <NumPad.CustomIntegerNumber
                position="centerRight"
                sync={false}
                isShowAction
                arrow="right"
                value={denomination.number}
                onChange={(value) => handleChangeNumPad(denomination.id, value)}
              >
                <span className="form-control qty">{denomination.number || 0}</span>
              </NumPad.CustomIntegerNumber>
              <button
                className="btn-number qtyminus"
                data-type="minus"
                data-field="qty"
                type="button"
                onClick={() => handelClickMinus(denomination.id)}
              > {t("-")}
              </button>
              <button
                className="btn-number qtyplus"
                data-type="plus"
                data-field="qty"
                type="button"
                onClick={() => handleClickPlus(denomination.id)}
              >{t("+")}
              </button>
            </div>
          </div>
        </div>
        <div className="price">{CurrencyService.format(denomination.subTotal, denomination.currency_code)}</div>
      </li>
    </>
  );
};

Denomination.propTypes = {
  denomination: PropTypes.object.isRequired,
  updateDenomination: PropTypes.func.isRequired,
};

Denomination.className = "DenominationComponent";
export default FunctionComponentFactory.get(Denomination);
