import {combineReducers} from 'redux';

import ProductListReducer from './product/ProductListReducer';
import ViewProductReducer from './product/ViewProductReducer';


export default combineReducers({
  productList: ProductListReducer,
  viewProduct: ViewProductReducer,
});
