const LocalStorageConstant = {
  CONFIG: 'config',
  DENOMINATIONS: 'denominations',
  PERMISSIONS: 'permissions',
  COUNTRIES: 'countries',
  SHOP: 'shop',
  LOCATIONS: 'locations',
  LOCATION_NAME: 'location_name',
  STAFF_NAME: 'staff_name',
};

export default LocalStorageConstant;
