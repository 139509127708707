import DataResourceFactory from '../../framework/factory/DataResourceFactory';
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';

import OmcAbstract from "./OmcAbstract";
import GraphqlDenomination from './graphql/GraphqlDenomination';


export class OmcDenomination extends OmcAbstract {
  static className = 'OmcDenomination';
  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlDenomination,
  };

  /**
   * Get Denominations By Currency Code
   *
   * @param currencyCode
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getDenominationsByCurrencyCode(currencyCode) {
    return this.getApiResource().getDenominationsByCurrencyCode(currencyCode);
  }
}

export default DataResourceFactory.get(OmcDenomination);
