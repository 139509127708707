import _ from 'lodash';

import ConfigConstant from '../constant/ConfigConstant';

const initialState = {
  configs: {},
};

/**
 * receive action from Loading Action
 *
 * @param state = initialState
 * @param action
 * @returns {*}
 */
const configReducer = (state = _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case ConfigConstant.GET_CONFIG_RESULT:
      return {...state, configs: action.configs};
    default: {
      return state;
    }
  }
};

export default configReducer;
