import React from 'react';
import {MdEdit} from 'react-icons/md';

import {CustomPriceKeyPad} from '../elements';
import NumberService from '../../../../../service/NumberService';

import ContentCustomPriceNumPad from './ContentCustomPriceNumPad';

/**
 * Default validation
 * @type {{float: function(): boolean, negative: function(): boolean}}
 */
const DefaultValidation = {
  float: () => true,
  negative: () => true,
};

/**
 * init default prop validation
 * @param Validation
 * @returns {{element, validation: function(*): boolean, formatInputValue: function(*): string, keyValid: keyValid, displayRule: function(*): string, inputButtonContent: *}}
 */
const defaultProps = (Validation) => ({
  element: CustomPriceKeyPad,
  validation: (value) => value.length >= 0,
  formatInputValue: (value) => value.toString().replace(/\D+/g, ''),
  keyValid: (value = '', key) => {
    let next;
    if (key === '.') {
      return false;
    }
    if (key === '-') {
      next = value.charAt(0) === '-' ? value.substr(1) : key + value;
    } else {
      next = value + key;
    }

    return !isNaN(next) && Validation.float(next) && Validation.negative(next);
  },
  setValue: (value) => NumberService.convertNumberToPriceHasPrecision(value),
  displayRule: (value) => {
    const next = value.toString().replace(/\D+/g, '');
    if (next === '') { return next; }
    return NumberService.convertNumberToPriceHasPrecision(next);
  },
  inputButtonContent: <MdEdit />,
});

const CustomPriceNumPad = ContentCustomPriceNumPad(defaultProps(DefaultValidation));

export {CustomPriceNumPad};
