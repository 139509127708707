import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {ButtonToolbar, Form, OverlayTrigger, Popover} from 'react-bootstrap';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';

function CustomerSelectComponent(props) {
  const {label, oneRow, code, required, error, value, options, field} = props;
  const isOptional = !required;

  const {t} = useTranslation();
  const popoverRight = (
    <Popover id="popover">
      <div className="popover-content">
        {t(error) }
      </div>
    </Popover>
  );

  const ref = useRef(null);
  const target = useRef(null);
  const wrapperClass = oneRow ? "col-sm-12" : "col-sm-6";
  const labelContent =
    isOptional ? t('{{label}} ', {label}) : <span className="asterisk-required">{t(label)}</span>;
  const errorTooltip = error ? (
    <ButtonToolbar className="validation-advice open" >
      <OverlayTrigger
        trigger={['click', 'hover', 'focus']}
        rootClose
        placement="bottom"
        overlay={popoverRight}
        container={ref}
        target={target}
      >
        <span className="dropdown-toggle" ref={target} />
      </OverlayTrigger>
    </ButtonToolbar>
  ) : null;
  return (
    <div id={`${code}_root`} className={wrapperClass} ref={ref}>
      <Form.Label><span>{labelContent}</span></Form.Label>
      <Form.Select
        className="form-control" onChange={(event) => {
          props.setFieldValue(field, event.target.value);
        }}
        value={value}
      >
        {
          options.map(
            (option) => {
              return <option key={option.value} value={option.value}>{option.label}</option>;
            },
          )
        }
      </Form.Select>
      {errorTooltip}
    </div>
  );
}

CustomerSelectComponent.className = 'CustomerSelectComponent';
CustomerSelectComponent.propTypes = {
  label: PropTypes.string,
  oneRow: PropTypes.bool,
  code: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  setFieldValue: PropTypes.func,
  error: PropTypes.string,
  field: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(FunctionComponentFactory.get(CustomerSelectComponent));
