import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import SessionAction from '../../../action/SessionAction';
import ModalSession from '../modal-session/ModalSession.jsx';

const OpenSession = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleOpenSession = (total) => {
    dispatch(SessionAction.openSession(total));
  };

  return (
    <ModalSession
      title={t("Open Session")}
      handleAction={handleOpenSession}
      titleAction={t("Open Session")}
      titleNumPad={t('Opening Balance')}
    />
  );
};

OpenSession.className = 'OpenSessionComponent';
export default FunctionComponentFactory.get(OpenSession);
