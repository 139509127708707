import ShopResourceModel from '../../resource-model/shop/ShopResourceModel';
import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';

/**
 * Shop Service Class
 */
export class ShopServiceClass extends CoreService {
  static className = 'ShopServiceClass';
  resourceModel = ShopResourceModel;

  /**
   * Get shop public info
   * @param shop
   * @returns {promise}
   */
  getShopPublicInfo(shop = '') {
    return this.getResourceModel().getShopPublicInfo(shop);
  }
}


/**
 * @type {ShopServiceClass}
 */
const shopService = ServiceFactory.get(ShopServiceClass);

export default shopService;
