import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";
import {cloneDeep, get} from "lodash";
import PropTypes from "prop-types";

import FunctionComponentFactory from "../../../../framework/factory/FunctionComponentFactory";
import CheckoutPaymentAction from "../../../action/checkout/payment/CheckoutPaymentAction";
import i18n from "../../../../config/i18n";
import OrderService from "../../../../service/order/OrderService";
import PaymentMethodItem from "../../payment/PaymentMethodItem.jsx";
import OrderAction from "../../../action/OrderAction";


function ListPayment(props) {
  const {payments, isAddPaymentMethod} = useSelector((state) => state.core.checkout.payment);
  const dispatch = useDispatch();
  const {remaining, grandTotal} = props;

  const {quote} = useSelector((state) => state.core.checkout);
  useEffect(() => {
    dispatch(OrderAction.verifyCanFulfillAllItems(cloneDeep(quote)));
  }, [dispatch, quote]);

  useEffect(() => {
    dispatch(CheckoutPaymentAction.getListPayment(isAddPaymentMethod));
  }, [dispatch, isAddPaymentMethod]);
  const label = remaining < grandTotal ? 'Remaining' : 'Total';

  const handleSelectPayment = (payment) => {
    if (parseInt(payment.use_pay_later, 10) === 1) {
      dispatch(CheckoutPaymentAction.handleAcceptPayment(payment, 0, ''));
    } else {
      dispatch(CheckoutPaymentAction.selectPayment(payment));
    }
  };

  const listKey = Object.keys(payments);
  return (
    <>
      <div className="block-content block-content1" data-scrollbar>
        <ul className="payment-total">
          <li>
            <span className="label">{i18n.t(label)}</span>
            <span className="value">{OrderService.formatPrice(remaining)}</span>
          </li>
        </ul>
        <ul className="payment-list">
          {
            listKey.map((key) => {
              const payment = get(payments, `${key}`);
              return (
                <PaymentMethodItem key={key} payment={payment} paymentItemKey={key} onClick={handleSelectPayment} />
              );
            })
          }
        </ul>
      </div>
      {isAddPaymentMethod &&
        <div className="block-bottom">
          <div className="actions-accept">
            <Button className="btn btn-default btn-cancel" type="button" onClick={() => { dispatch(CheckoutPaymentAction.handleOpenCompletePayment()); }}>
              {i18n.t("Back")}
            </Button>
          </div>
        </div>
      }
    </>
  );
}

ListPayment.className = 'ListPaymentCheckout';
ListPayment.propTypes = {
  remaining: PropTypes.number,
  grandTotal: PropTypes.number,
};
export default FunctionComponentFactory.get(ListPayment);
