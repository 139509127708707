import React, {Fragment, useRef, useState} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import {useDispatch, useSelector} from 'react-redux';

import OrderAction from '../../action/OrderAction';
import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import "../../../assets/OrderCancel.css";
import "../../../assets/Order.css";
import OrderConstant from '../../constant/OrderConstant';

const OrderCancel = () => {
  const options = OrderConstant.ORDER_CANCEL_REASON_OPTIONS;
  const [cancelReason, setCancelReason] = useState(null);
  const [isRestock, setIsRestock] = useState(true);
  const {order, isOpenOrderCancelPopup} = useSelector((state) => state.core.order.orderDetail);
  const {t} = useTranslation();
  const ref = useRef();
  const dispatch = useDispatch();
  function actionCancelOrder() {
    if (!cancelReason) { return; }
    dispatch(OrderAction.setIsOpenOrderCancelPopup(false));
    const numberId = order.id.slice(order.id.lastIndexOf("/") + 1);
    dispatch(OrderAction.cancelOrder({
      id: numberId,
      cancelReason: cancelReason?.value,
      isRestock,
    }, order));
    resetOrderCancelState();
  }

  function resetOrderCancelState() {
    setCancelReason(null);
    setIsRestock(true);
  }
  function cancelActionCancelOrder() {
    dispatch(OrderAction.setIsOpenOrderCancelPopup(false));
    resetOrderCancelState();
  }

  /**
   * Coment change
   * @param event
   */
  function cancelReasonChange(newReason) {
    setCancelReason(newReason);
  }

  function focusReasonArea() {
    ref.current.focus();
  }

  function changeIsRestock(event) {
    setIsRestock(!event.target.checked);
  }

  const btnActionCancelClassName = cancelReason ? "btn" : "btn disable";

  return (
    <>
      <Modal
        size="lg"
        className="popup-edit-customer popup-cancel-order"
        dialogClassName="popup-create-customer in"
        show={isOpenOrderCancelPopup}
      >
        <div className="modal-header">
          <h4 className="modal-title" id="order-cancel-modal-title">{t('Cancel Order')}</h4>
        </div>
        <div data-scrollbar ref={ref} className="modal-body">
          <div className="text text-center order-cancel-confirm-text">
            {t('Are you sure you want to cancel this order?')}
          </div>
          <div aria-hidden="true" className="add-comment-order" onClick={() => focusReasonArea()}>
            <Select
              placeholder={t("Reason to cancel order")}
              options={options}
              onChange={cancelReasonChange}
              value={cancelReason}
              clearable={false}
              searchable={false}
              noResultsText=""
              className="reason-order-cancel-select"
            />
            <br />
            <div className="box-comfirm-switch">
              <div className="text order-cancel-restock-confirm-text">
                {t('Restock Item?')}
              </div>
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={isRestock}
                onInput={(event) => changeIsRestock(event)}
              />
            </div>
          </div>

          <div className="actions-bottom">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
            <a className="btn" onClick={() => cancelActionCancelOrder()}>{t('No')}</a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
            <a className={btnActionCancelClassName} onClick={() => actionCancelOrder()}>{t('Yes')}</a>
          </div>
        </div>
      </Modal>
    </>
  );
};

OrderCancel.className = 'OrderCancel';
OrderCancel.propTypes = {
};

export default FunctionComponentFactory.get(OrderCancel);
