import CoreService from '../CoreService';
import ServiceFactory from "../../framework/factory/ServiceFactory";
import MultiCartResourceModel from "../../resource-model/cart/MultiCartResourceModel";
import PosService from "../pos/PosService";
import {getCount, setCount} from "../../config/Config";

export class MultiCartService extends CoreService {
  static className = 'MultiCartService';
  resourceModel = MultiCartResourceModel;

  /**
   * Call MultiCartResourceModel search by cashier Id
   * @param posId
   * @return {Promise<*>}
   */
  async searchByPosId(posId) {
    const resourceModel = this.getResourceModel();
    const list = await resourceModel.searchByPosId(posId);
    setCount(list.length ? list[0].count : 0);
    return list;
  }

  /**
   * Call MultiCartResourceModel search by cashier Id
   * @return {Promise<*>}
   */
  async searchByCurrentPos() {
    if (PosService.getCurrentPosId()) {
      const list = await this.searchByPosId(PosService.getCurrentPosId());
      return list;
    } else {
      return [];
    }
  }

  async add(cart) {
    const currentCount = getCount();
    setCount(currentCount + 1);
    const cartData = {...cart, payments: [], valid_salesrule: null, coupon_code: null, count: currentCount + 1};
    const resourceModel = this.getResourceModel();
    await resourceModel.add(cartData);
    return cartData;
  }

  /**
   * add new cart from Store
   * @param quote
   */
  async addCart(quote) {
    const newCart = {
      pos_id: PosService.getCurrentPosId(),
      ...quote,
      payments: [],
      valid_salesrule: [],
    };
    const result = await this.add(newCart);
    return result;
  }

  /**
   * Call MultiCartResourceModel update cart
   * @return {*|{type: string, code: *}}
   * @param quote
   */
  async updateActiveCart(quote) {
    const resourceModel = this.getResourceModel();
    const listCart = await this.searchByCurrentPos();
    let result;
    if ((!listCart || listCart.length === 0)) {
      if ((quote.lineItems?.length > 0 || quote?.customerId)) {
        result = await this.addCart(quote);
      }
    } else {
      await resourceModel.update(quote);
      result = quote;
    }

    return result;
  }

  /**
   * Call MultiCartResourceModel delete cart
   * @param cart
   * @returns {*|{type: string, code: *}}
   */
  delete(cartId) {
    const resourceModel = this.getResourceModel();
    return resourceModel.delete(cartId);
  }

  /**
   * reset count and add new cart from Store
   * @param store
   */
  resetCountAndAddCart(quote) {
    setCount(0);
    return this.addCart(quote);
  }
}

/** @type MultiCartService */
const multiCartService = ServiceFactory.get(MultiCartService);

export default multiCartService;
