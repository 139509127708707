import DataResourceFactory from "../../framework/factory/DataResourceFactory";
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';

import OmcAbstract from "./OmcAbstract";
import GraphqlPos from './graphql/GraphqlPos';

export class OmcPos extends OmcAbstract {
  static className = 'OmcPos';

  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlPos,
  };


  /**
   * Get list pos by location ids
   * @returns {*}
   */
  getListPosByLocationIds(locationIds) {
    const resource = this.getApiResource();
    return resource.getListPosByLocationIds(locationIds);
  }

  /**
   * Enter to POS
   * @returns {*}
   */
  enterToPos(id, locationId) {
    const resource = this.getApiResource();
    return resource.enterToPos(id, locationId);
  }
}

/**
 * @type {OmcStaff}
 */
export default DataResourceFactory.get(OmcPos);
