import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonToolbar, Popover, Tab, Tabs, Overlay, Spinner} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';
import Payment from '../../Payment.jsx';
import DiscountConstant
  from '../../../../constant/checkout/quote/DiscountConstant';
import CurrencyService from '../../../../../service/CurrencyService';
import CustomDiscountConstant
  from '../../../../constant/checkout/quote/CustomDiscountConstant';
import QuoteCustomDiscountService
  from '../../../../../service/checkout/quote/QuoteCustomDiscountService';
import NumPad from "../../../lib/react-numpad";
import QuoteAction from "../../../../action/checkout/QuoteAction";
import QuoteService from "../../../../../service/checkout/QuoteService";

function CartTotalDiscount(props) {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const {quote, total, showBackdrop, hideBackdrop, isShowBackdrop} = props;
  const {currentPage, isApplyingDiscount, isAppliedDiscount, isAppliedCoupon, isApplyingCoupon, applyCouponErrorMessage, isRemovingCoupon} = useSelector((state) => state.core.checkout.index);
  const {isOpenConflictProductCouponDiscountPopup, isOpenConflictCustomOrderDiscount} = useSelector((state) => state.core.checkout.conflictDiscount);
  const [showPopup, setShowPopup] = useState(false);
  const [currentState, setCurrentState] = useState(DiscountConstant.STATE_COUPON);
  const [customDiscountType, setCustomDiscountType] = useState('');
  const [customDiscountAmount, setCustomDiscountAmount] = useState(0);
  const [customDiscountReason, setCustomDiscountReason] = useState('');
  const [message, setMessage] = useState('');
  const [couponCodeApplied, setCouponCodeApplied] = useState('');
  const [couponCode, setCouponCode] = useState(quote.couponCode || "");
  const {conflictCouponSubmitted, showConflictCouponSubmittedPopup} = useSelector((state) => state.core.checkout.index);
  const discountTotalElement = useRef();
  const inputCoupon = useRef();

  const hasPaidOrWaitingGatewayPayment = false;

  useEffect(() => {
    setCouponCodeApplied(quote.couponCode || "");
    setCouponCode(quote.couponCode || "");
  }, [quote.couponCode]);

  /**
   *
   * @return {boolean}
   */
  const canShow = useMemo(() => {
    const showOnPages = [Payment.className];
    return showOnPages.includes(currentPage);
  }, [currentPage]);

  /**
   *  Reset State to default
   *
   * @return
   */
  const resetState = () => {
    setCurrentState(DiscountConstant.STATE_PERCENT);
    setCustomDiscountType('');
    setCustomDiscountAmount(0);
    setCustomDiscountReason('');
    dispatch(QuoteAction.setIsApplyingDiscount(false));
    dispatch(QuoteAction.setIsApplyingCoupon(false));
    setMessage('');
    setCouponCodeApplied('');
    setCouponCode('');
  };

  const showPopover = () => {
    const {
      os_pos_custom_discount_type,
      os_pos_custom_discount_amount,
      os_pos_custom_discount_reason,
    } = quote;
    let newCurrentState = DiscountConstant.STATE_COUPON;
    if (quote.couponCode) {
      setCouponCode(quote.couponCode);
    } else {
      if (os_pos_custom_discount_type) {
        setCustomDiscountType(os_pos_custom_discount_type);
        switch (os_pos_custom_discount_type) {
          case CustomDiscountConstant.DISCOUNT_TYPE_PERCENT:
            newCurrentState = DiscountConstant.STATE_PERCENT;
            break;
          case CustomDiscountConstant.DISCOUNT_TYPE_FIXED:
            newCurrentState = DiscountConstant.STATE_FIXED;
            break;
          default:
            break;
        }
      } else {
        setCustomDiscountType('');
      }
      if (os_pos_custom_discount_amount) {
        setCustomDiscountAmount(os_pos_custom_discount_amount);
      } else {
        setCustomDiscountAmount(0);
      }
      if (os_pos_custom_discount_reason) {
        setCustomDiscountReason(os_pos_custom_discount_reason);
      } else {
        setCustomDiscountReason('');
      }
    }
    setCurrentState(newCurrentState);
    dispatch(QuoteAction.setIsApplyingDiscount(false));
    showBackdrop();
    setShowPopup(true);
    setMessage('');
    return this;
  };

  useEffect(() => {
    if (!showConflictCouponSubmittedPopup) {
      return;
    }
    setCouponCode(conflictCouponSubmitted);
    showPopover();
    dispatch(QuoteAction.setShowConflictCouponSubmittedPopup(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConflictCouponSubmittedPopup]);

  useEffect(() => {
    if (isShowBackdrop) {
      return;
    }
    setShowPopup(false);
  }, [isShowBackdrop]);

  /**
   * Close popup after applied discount
   */
  useEffect(() => {
    if (!isAppliedDiscount) {
      return;
    }
    hideBackdrop();
    setShowPopup(false);
    dispatch(QuoteAction.setIsAppliedDiscount(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppliedDiscount]);

  const getDiscountElement = () => {
    const discountAmount = total.value;
    let classNameAmount = (discountAmount === 0) ? "add-discount" : "amount";
    const displayValue = (discountAmount === 0) ? "" : `${CurrencyService.format(discountAmount)}`;
    let className = ["totals-discount", "totals-action"];

    // If has any gateway payment is error or processing payment => user cannot use discount function
    if (hasPaidOrWaitingGatewayPayment) {
      classNameAmount = '';
      className = ["totals-discount"];
    }
    let title = total.title;
    const {os_pos_custom_discount_type, os_pos_custom_discount_amount} = quote;
    if (quote.couponCode) {
      title = t(
        'Discount ({{couponCodeApplied}})',
        {
          couponCodeApplied: quote.couponCode,
        },
      );
    } else if (os_pos_custom_discount_type && displayValue.length > 0) {
      switch (os_pos_custom_discount_type) {
        case CustomDiscountConstant.DISCOUNT_TYPE_PERCENT:
          title = t(
            'Custom Discount ({{max}})',
            {
              max: `${os_pos_custom_discount_amount}%`,
            },
          );
          break;
        case CustomDiscountConstant.DISCOUNT_TYPE_FIXED:
          title = t(
            'Custom Discount ({{max}})',
            {
              max: `${CurrencyService.format(parseFloat(os_pos_custom_discount_amount))}`,
            },
          );
          break;
        default:
          break;
      }
    } else if (couponCodeApplied) {
      title = t(`Discount ({{couponCodeApplied}})`, {couponCodeApplied});
    }


    return (
      <li className={className.join(" ")} ref={discountTotalElement} onClick={showPopover} aria-hidden="true">
        <span className="mark">{title}</span>
        <span className={classNameAmount}>{displayValue}</span>
      </li>
    );
  };

  const canUseCustomDiscount = () => {
    const maxDiscountPercent = QuoteCustomDiscountService.getMaxDiscountPercent();
    return Boolean(maxDiscountPercent && (maxDiscountPercent > 0));
  };

  /**
   * Handle select discount type
   * @param key
   */
  const handleSelectDiscountType = (key) => {
    let type = "";
    switch (key) {
      case DiscountConstant.STATE_PERCENT:
        type = CustomDiscountConstant.DISCOUNT_TYPE_PERCENT;
        break;
      case DiscountConstant.STATE_FIXED:
        type = CustomDiscountConstant.DISCOUNT_TYPE_FIXED;
        break;
      case DiscountConstant.STATE_COUPON:
        type = CustomDiscountConstant.DISCOUNT_TYPE_COUPON;
        break;
      default:
        break;
    }
    setCustomDiscountType(type);
    setCustomDiscountAmount(0);
    setMessage('');
  };

  /**
   *  Change coupon code when type input field
   *
   * @return rules
   */
  const couponCodeChange = (event) => {
    setCurrentState(DiscountConstant.STATE_COUPON);
    setCouponCode(event.target.value);
    setMessage('');
  };

  useEffect(() => {
    if (!isAppliedCoupon) {
      return;
    }
    setCustomDiscountAmount(0);
    setCustomDiscountReason("");
    setCurrentState(DiscountConstant.STATE_COUPON);
    setCouponCodeApplied(quote.couponCode);
    hideBackdrop();
    setMessage('');
    dispatch(QuoteAction.setIsAppliedCoupon(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppliedCoupon]);

  useEffect(() => {
    if (applyCouponErrorMessage) {
      setMessage(applyCouponErrorMessage);
    }
  }, [applyCouponErrorMessage]);

  useEffect(() => {
    if (!isOpenConflictProductCouponDiscountPopup) {
      return;
    }
    hideBackdrop();
  }, [hideBackdrop, isOpenConflictProductCouponDiscountPopup]);

  useEffect(() => {
    if (!isOpenConflictCustomOrderDiscount) {
      return;
    }
    hideBackdrop();
  }, [hideBackdrop, isOpenConflictCustomOrderDiscount]);

  /**
   *  Submit coupon code to check promotion
   *
   * @return rules
   */
  const submitCouponCode = () => {
    dispatch(QuoteAction.handleApplyCoupon(quote, couponCode));
  };

  /**
   *  Remove coupon code in quote
   *
   * @return rules
   */
  const removeCouponCode = async () => {
    await dispatch(QuoteAction.handleRemoveCoupon(quote, couponCode));
    resetState();
  };

  /**
   * Get coupon component template
   * @returns {*}
   */
  const getApplyCouponBtnComponent = (classNameButtonApply, disabledButtonApply) => {
    const applyConponBtnContent = isApplyingCoupon
    ? (
      <Spinner className="loading" animation="border" role="status">
        <span className="visually-hidden" />
      </Spinner>
    )
    : t('Apply');

    return (
      <button
        className={classNameButtonApply} type="button"
        onClick={submitCouponCode}
        disabled={disabledButtonApply}
      >
        {applyConponBtnContent}
      </button>
    );
  };

  const getRemoveCouponBtnComponent = (classNameButtonRemove) => {
    const removeCouponBtnContent = isRemovingCoupon
    ? (
      <Spinner className="loading" animation="border" role="status">
        <span className="visually-hidden" />
      </Spinner>
    )
    : t('Remove');
    return (
      <button
        className={classNameButtonRemove} type="button"
        onClick={removeCouponCode}
        disabled={isRemovingCoupon}
      >
        {removeCouponBtnContent}
      </button>
    );
  };
  const getCouponTemplate = () => {
    const classNameInput = (couponCodeApplied === "")
      ? "form-control input-coupon" : "form-control input-coupon label-coupon";
    let classNameButtonApply = "";
    if (couponCodeApplied === '') {
      classNameButtonApply = (couponCode === '')
        ? 'btn btn-default btn-coupon disabled' : 'btn btn-default btn-coupon';
    } else {
      classNameButtonApply = 'hidden';
    }
    const classNameButtonRemove = (couponCodeApplied === "") ? "hidden" : "btn btn-default btn-coupon";
    let classBtnRemoveInput = "hidden";
    if ((couponCodeApplied === "") && (couponCode !== "")) {
      classBtnRemoveInput = "btn-remove";
    }
    const disabledButtonApply = ((couponCode === "") || isApplyingCoupon);
    const classNameMessage = (message === "") ? "invalid-coupon hidden" : `invalid-coupon ${message.length > 45 ? 'multi-line-invalid-text' : ''}`;
    const disabledInputCoupon = (couponCodeApplied !== "");

    return (
      <div className="discount-content">
        <div className="img-discount" />
        <div className="form-coupon">
          <input
            type="text" className={classNameInput}
            placeholder={t("Enter code here")}
            onChange={couponCodeChange}
            disabled={disabledInputCoupon}
            value={couponCode}
            ref={inputCoupon}
            aria-label={t("Coupon Input")}
            autoComplete="false"
          />
          <button
            className={classBtnRemoveInput}
            type="button"
            aria-label={t("Remove Coupon Button")}
            onClick={resetState}
          />
          {getApplyCouponBtnComponent(classNameButtonApply, disabledButtonApply)}
          {getRemoveCouponBtnComponent(classNameButtonRemove)}
          <div className={classNameMessage}>
            {message}
          </div>
        </div>
      </div>
    );
  };

  /**
   * On Discount Amount Change
   *
   * @param discountAmount
   */
  const onDiscountAmountChange = (discountAmount) => {
    const discountType = customDiscountType;
    const discountData = QuoteCustomDiscountService.getDiscountData(quote, discountType, discountAmount);
    let errorMessage = "";
    let newDiscountAmount = parseFloat(discountAmount);
    switch (discountType) {
      case CustomDiscountConstant.DISCOUNT_TYPE_PERCENT:
        if (discountData.percent !== newDiscountAmount) {
          errorMessage = t('You can only discount up to {{max}}', {max: `${discountData.percent}%`});
          newDiscountAmount = discountData.percent;
        }
        break;
      case CustomDiscountConstant.DISCOUNT_TYPE_FIXED:
        if (discountData.amount !== newDiscountAmount) {
          const max = CurrencyService.format(discountData.amount);
          errorMessage = t('You can only discount up to {{max}}', {max});
          newDiscountAmount = discountData.amount;
        }
        break;
      default:
        break;
    }
    setCustomDiscountAmount(newDiscountAmount);
    setMessage(errorMessage);
  };

  /**
   * On Discount Reason Change
   * @param event
   */
  const onDiscountReasonChange = (event) => {
    setCustomDiscountReason(event.target.value);
  };

  const handleApplyCustomDiscount = () => {
    const discountType = customDiscountType;
    const discountAmount = customDiscountAmount;
    const discountReason = customDiscountReason;

    if (discountType && (discountAmount > 0)) {
      setCouponCode('');
      setCouponCodeApplied('');
      dispatch(QuoteAction.setCustomDiscount(quote, discountType, discountAmount, discountReason));
    } else if (!couponCodeApplied) {
      dispatch(QuoteAction.setCustomDiscount(quote, discountType, 0, ''));
    }
    hideBackdrop();
  };

  /**
   * Get custom discount component template
   * @param type
   * @param maxValue
   * @returns {*}
   */
  const getCustomDiscountTemplate = (type, maxValue) => {
    const currentDiscountType = customDiscountType;
    let currentDiscountAmount = customDiscountAmount;

    switch (currentDiscountType) {
      case CustomDiscountConstant.DISCOUNT_TYPE_PERCENT:
        currentDiscountAmount = `${currentDiscountAmount}%`;
        break;
      case CustomDiscountConstant.DISCOUNT_TYPE_FIXED:
        currentDiscountAmount = CurrencyService.format(currentDiscountAmount);
        break;
      default:
        break;
    }

    const classNameMessage = (message === "") ? "invalid-coupon hidden" : "invalid-coupon";
    return (
      <div className={`discount-content discount-content-${type}`}>
        <div className="form-coupon">
          <textarea
            className="form-control"
            aria-label={t('Custom discount reason input')}
            placeholder={t("Reason")}
            value={customDiscountReason}
            style={{resize: 'none'}}
            onChange={onDiscountReasonChange}
          />
        </div>
        <div className="form-coupon">

          <NumPad.CustomNumber
            onChange={onDiscountAmountChange}
            position="centerLeft"
            arrow="left"
            rightAdd={40}
            isShowAction
            max={maxValue}
          >
            <span className="form-control discount-amount">
              {currentDiscountAmount}
            </span>
          </NumPad.CustomNumber>
          <button
            className="btn btn-default btn-coupon"
            onClick={handleApplyCustomDiscount}
            type="button"
          >
            {t('Apply')}
          </button>
          <div className={classNameMessage}>
            {message}
          </div>
        </div>
      </div>
    );
  };

  const getPopoverContent = () => {
    if (canUseCustomDiscount()) {
      const customDiscountTemplatePercent = getCustomDiscountTemplate(DiscountConstant.STATE_PERCENT, 100 * 100);
      const customDiscountTemplatePrice = getCustomDiscountTemplate(DiscountConstant.STATE_FIXED, QuoteService.getSubtotalWithoutDiscount(quote) * 100);
      return (
        <div className="discount-container" id="discount-container">
          <Tabs
            defaultActiveKey={currentState}
            animation="false"
            onSelect={handleSelectDiscountType}
            bsstyle="pills"
          >
            <Tab
              eventKey={DiscountConstant.STATE_COUPON}
              title={t('Coupon Code')}
            >
              {getCouponTemplate()}
            </Tab>
            <Tab
              eventKey={DiscountConstant.STATE_PERCENT} title="%"
            >
              {customDiscountTemplatePercent}
            </Tab>
            <Tab
              eventKey={DiscountConstant.STATE_FIXED}
              title={CurrencyService.getCurrencySymbol()}
            >
              {customDiscountTemplatePrice}
            </Tab>
          </Tabs>
        </div>
      );
    }
    return (
      <>
        <div className="discount-title">{t('Coupon Code')}</div>
        {getCouponTemplate()}
      </>
    );
  };

  const getPopoverCoupon = () => {
    return (
      <Overlay
        show={showPopup}
        target={discountTotalElement.current}
        placement="right"
      >
        <Popover id="coupon_popover">
          <div className="popup-add-discount">
            {getPopoverContent()}
          </div>
          <div
            className="loader-couponcode"
            style={{display: (isApplyingDiscount ? 'block' : 'none')}}
          >
            <div className="loader-product" />
          </div>
        </Popover>
      </Overlay>
    );
  };

  const buttonToolbarClassName = canShow ? "" : "hidden";

  return (
    <>
      <ButtonToolbar className={buttonToolbarClassName}>
        {getDiscountElement()}
        {getPopoverCoupon()}
      </ButtonToolbar>
    </>
  );
}

CartTotalDiscount.className = 'CartTotalDiscount';
CartTotalDiscount.propTypes = {
  quote: PropTypes.object.isRequired,
  total: PropTypes.object.isRequired,
  showBackdrop: PropTypes.func.isRequired,
  hideBackdrop: PropTypes.func.isRequired,
  isShowBackdrop: PropTypes.bool.isRequired,
};

export default FunctionComponentFactory.get(CartTotalDiscount);
