import AbstractResourceModel from "../AbstractResourceModel";

export default class PosResourceModel extends AbstractResourceModel {
  static className = 'PosResourceModel';

  resourceName = 'Pos';

  /**
   * Get list pos by location ids
   * @param locationIds
   * @returns {promise}
   */
  getListPosByLocationIds(locationIds) {
    return this.getResourceOnline().getListPosByLocationIds(locationIds);
  }

  /**
   * Enter to POS id
   * @param id
   * @param locationId
   * @returns {promise}
   */
  enterToPos(id, locationId) {
    return this.getResourceOnline().enterToPos(id, locationId);
  }
}
