import React from 'react';

import FunctionComponentFactory
  from '../../../framework/factory/FunctionComponentFactory';
import '../../../assets/Cart.css';
import layout from '../../../framework/Layout';

import CartItems from './cart/CartItems.jsx';
import CustomerButton from './customer/CustomerButton.jsx';
import CartFooter from './cart/CartFooter.jsx';
import CartTotals from './cart/CartTotals.jsx';

function Cart() {
  return (
    <div className="wrapper-content-customer">
      <CustomerButton />
      {layout('checkout')('cart')('cart_items_before')()(this)}
      <CartItems />
      <CartTotals />
      <CartFooter />
    </div>
  );
}

Cart.className = 'Cart';
Cart.propTypes = {};

export default FunctionComponentFactory.get(Cart);
