import AbstractResourceModel from "../AbstractResourceModel";

export default class OrderResourceModel extends AbstractResourceModel {
  static className = 'OrderResourceModel';

  resourceName = 'Order';

  /**
   * Get Orders
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getOrders(input) {
    return this.getResourceOnline().getOrders(input);
  }

  /**
   * Get Order
   * @param {*} id
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getOrder(id) {
    return this.getResourceOnline().getOrder(id);
  }

  /**
   * Send Email
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  sendEmail(input) {
    return this.getResourceOnline().sendEmail(input);
  }

  /**
   * Cancel Order
   * @param {*} cancelOrderInfo
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  cancelOrder(cancelOrderInfo) {
    return this.getResourceOnline().cancelOrder(cancelOrderInfo);
  }

  /**
   * Get Fulfillment Order
   * @param {*} orderId
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getFulfillmentOrder(orderId) {
    return this.getResourceOnline().getFulfillmentOrder(orderId);
  }

  /**
   *
   * create record payment
   * @param listPaid
   * @param order
   * @param shop
   * @return {Promise}
   */
  createRecordPayment(listPaid, order, shop, session) {
    return this.getResourceOnline().createRecordPayment(listPaid, order, shop, session);
  }

  /**
   * Complete Order
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  orderComplete(input) {
    return this.getResourceOnline().orderComplete(input);
  }

  /**
   * Create Fulfillment Order
   * @param {*} input
   * @returns {Promise}
   */
  createFulfillmentOrder(input) {
    return this.getResourceOnline().createFulfillmentOrder(input);
  }

  /**
   * Fulfillment Order Move
   * @param {*} input
   * @returns {Promise}
   */
  fulfillmentOrderMove(input) {
    return this.getResourceOnline().fulfillmentOrderMove(input);
  }

  /**
   * create record refund
   * @param refundLineItems
   * @param transactions
   * @param shipping
   * @param note
   * @param order
   * @param shop
   * @return {Promise}
   */
  createRecordRefund(refundLineItems, transactions, shipping, note, order, shop, session) {
    return this.getResourceOnline().createRecordRefund(refundLineItems, transactions, shipping, note, order, shop, session);
  }

  /**
   *
   * @param order
   * @param customer
   * @param shop
   */
  updateOrderCustomer(order, customer, shop) {
    return this.getResourceOnline().updateOrderCustomer(order, customer, shop);
  }
}
