import ShopConstant from "../constant/ShopConstant";
import ShopService from '../../service/shop/ShopService';
import StaffService from '../../service/staff/StaffService';
import ActionFactory from '../../framework/factory/ActionFactory';

export class ShopActionClass {

  /**
   * Get shop public info
   *
   * @param shop
   * @returns {(function(*): Promise<void>)|*}
   */
  getShopPublicInfo(shop = '') {

    return async (dispatch) => {
      dispatch({
        type: ShopConstant.GET_PUBLIC_INFO,
        shop,
      });
      try {
        const response = await ShopService.getShopPublicInfo(shop);
        if (response && response.data && response.data.shop) {
          if (response.data.shop.logo) {
            StaffService.saveLocalLogo(response.data.shop.logo);
          }
          dispatch(this.getShopPublicInfoSuccess(response.data.shop));
        } else {
          dispatch(this.getShopPublicInfoFailed(response.error));
        }
      } catch (error) {
        dispatch(this.getShopPublicInfoFailed(error.message));
      }
    };
  }

  /**
   * Get shop public info success
   *
   * @param shop
   * @returns {{type: string}}
   */
  getShopPublicInfoSuccess(shop) {
    return {
      type: ShopConstant.GET_PUBLIC_INFO_SUCCESS,
      shop,
    };
  }

  /**
   * Get shop public info Failed
   *
   * @param error
   * @returns {{type: string, error}}
   */
  getShopPublicInfoFailed(error) {
    return {
      type: ShopConstant.GET_PUBLIC_INFO_FAILED,
      error,
    };
  }
}

const ShopAction = ActionFactory.get(ShopActionClass);
export default ShopAction;
