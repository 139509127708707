import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import i18n from "../../config/i18n";

/**
 * Guest Customer Service Class
 */
export class GuestCustomerServiceClass extends CoreService {
  static className = 'GuestCustomerService';

  /**
   * Get guest customer firstname
   * @return {string}
   */
  getFirstName() {
    return i18n.t('Guest');
  }

  /**
   * Get guest customer lastname
   * @return {string}
   */
  getLastName() {
    return i18n.t('POS');
  }

  /**
   * Get guest customer email
   * @return {string}
   */
  getEmail() {
    return '';
  }

  /**
   * Get guest customer telephone
   * @return {string}
   */
  getPhone() {
    return '';
  }
}


/**
 * @type {GuestCustomerServiceClass}
 */
const guestCustomerService = ServiceFactory.get(GuestCustomerServiceClass);

export default guestCustomerService;
