import PosConstant from "../constant/PosConstant";
import StaffConstant from '../constant/StaffConstant';

const initialState = {
  error: '',
  posList: [],

  isLoading: false,
  isSuccess: false,
};

/**
 * receive action from Shop Action
 *
 * @param state
 * @param action
 * @returns {*}
 */
const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case PosConstant.GET_POS_LIST_BY_LOCATION_IDS:
      return {
        ...state,
        error: '',
      };
    case PosConstant.GET_POS_LIST_BY_LOCATION_IDS_SUCCESS:
      return {
        ...state,
        posList: action.pos,
      };
    case PosConstant.GET_POS_LIST_BY_LOCATION_IDS_FAILED:
      return {
        ...state,
        error: action.error,
      };
    case StaffConstant.USER_CONTINUE_LOGIN_SUCCESS:
      return {
        ...state,
        error: '',
      };
    case StaffConstant.RESET_LOGIN_STATE:
      return {
        ...state,
        error: '',
      };
    case PosConstant.ENTER_TO_POS:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        error: '',
      };
    case PosConstant.ENTER_TO_POS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    case PosConstant.ENTER_TO_POS_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: action.error,
      };
    case PosConstant.RESET_ENTER_TO_POS_STATE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: '',
      };
    default:
      return state;
  }
};

export default shopReducer;
