import React, {useState} from "react";
import {useSelector} from "react-redux";

import FunctionComponentFactory from "../../../framework/factory/FunctionComponentFactory";
import SettingsListConfig from "../../../config/SettingsListConfig";

import '../../../assets/Setting.css';


function SettingsList() {
  const [items] = useState(SettingsListConfig.getItems());
  const {selectedId} = useSelector((state) => state.core.setting);
  const title = 'Settings';
  let SelectedComponent;
  return (
    <div className="wrapper-settings">
      <div className="settings-left">
        <div className="block-title">
          <strong className="title">{title}</strong>
        </div>
        <div className="block-content" >
          <div className="scroll-content">
            <ul className="list">
              {
                items.map((item) => {
                  let className = item.className;
                  SelectedComponent = item.component;
                  if (item.id === selectedId) { className += ' selected'; }
                  return (
                    <li key={item.id} className={className}>
                      <p>{item.name}</p>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </div>
      </div>
      {SelectedComponent && <SelectedComponent key={selectedId} />}
    </div>
  );
}

SettingsList.className = 'SettingsList';

export default FunctionComponentFactory.get(SettingsList);
