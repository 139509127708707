

import React from 'react';
import PropTypes from 'prop-types';
import ReactTextareaAutosize from 'react-textarea-autosize';
import {Form} from 'react-bootstrap';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';

function CustomSaleTextareaComponent(props) {

  const onChange = (event) => {
    const newValue = event.target.value;
    props.inputFieldOnChange(props.Code, newValue);
  };

  function template() {
    const {Code, Label, OneRow} = props;
    const id = `custom-sale-${Code}`;
    const colClassName = OneRow ? "col-sm-12" : "col-sm-6";

    return (
      <div className={colClassName}>
        <Form.Label htmlFor={id}>{Label}</Form.Label>
        <ReactTextareaAutosize
          id={id}
          value={props.value || ""}
          className="form-control custom-sale-note"
          onChange={(event) => onChange(event)}
          maxRows={3}
        />
      </div>
    );
  }

  return template();
}

CustomSaleTextareaComponent.className = 'CustomSaleTextareaComponent';
CustomSaleTextareaComponent.propTypes = {
  Label: PropTypes.string,
  Options: PropTypes.object,
  KeyValue: PropTypes.string,
  KeyTitle: PropTypes.string,
  OneRow: PropTypes.bool,
  Code: PropTypes.string,
  DefaultValue: PropTypes.string,
  inputFieldOnChange: PropTypes.func,
  value: PropTypes.string,
};

export default React.memo(FunctionComponentFactory.get(CustomSaleTextareaComponent));
