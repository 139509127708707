import React, {Fragment, useState} from 'react';
import {Modal} from "react-bootstrap";
import $ from 'jquery';
import SmoothScrollbar from "smooth-scrollbar";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Form from 'react-bootstrap/Form';

import OrderAction from '../../action/OrderAction';
import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import OrderConstant from '../../constant/OrderConstant';

function OrderSendEmail() {

  const regexEmail = OrderConstant.REGEX_EMAIL;

  const {order, isOpenSendEmailPopup} = useSelector((state) => state.core.order.orderDetail);
  const storeInfo = useSelector((state) => state.core.config.configs.shopConfig);

  const [customerEmail, setCustomerEmail] = useState(order?.customer?.email || "");
  const [errorCustomerEmail, setErrorCustomerEmail] = useState("To can't be blank");
  const [subject, setSubject] = useState(`Invoice ${order.name}`);
  // const btnSendClassName = order && order.customer && order.customer.email ? "btn" : "btn disabled";
  const [btnSendClassName, setBtnSendClassName] = useState(order && order.customer && order.customer.email ? "btn" : "btn disabled");
  const [validCustomerEmailClassName, setValidCustomerEmailClassName] = useState("hidden");
  const [validSubjectClassName, setValidSubjectClassName] = useState("hidden");
  const [scrollbarOrderSendEmail, setScrollbarOrderSendEmail] = useState(null);
  const {t} = useTranslation();
  const staffEmail = `${storeInfo?.name} <${storeInfo?.contactEmail}>`;
  const [customMessage, setCustomMessage] = useState("");
  const dispatch = useDispatch();

  if (!order) {
    return <></>;
  }
  function setBlockContentElement(element) {
    const block_content = element;
    if (!scrollbarOrderSendEmail && block_content) {
      setScrollbarOrderSendEmail(SmoothScrollbar.init(block_content));
      heightPopup('.popup-edit-customer.popup-send-email .modal-dialog');
    }
  }

    /**
     * get height popup
     * @param elm
     */
  function heightPopup(elm) {
    const height = $(window).height();
    $(elm).css('height', `${height}px`);
  }


    /**
     * send email
     */
  function sendEmail() {
    if (customerEmail) {
      if (regexEmail.test(customerEmail)) {
        const messageSuccess = 'Send email invoice successfully.';
        dispatch(OrderAction.sendEmail({
          orderId: order.id,
          email: {
            from: staffEmail,
            to: customerEmail,
            subject,
            customMessage,
          },
        }, messageSuccess));
        cancelSendEmail();
      } else {
        setValidCustomerEmailClassName("");
        setErrorCustomerEmail("Invalid email address");
      }
    }
  }


    /**
     * cancel send email
     */
  function cancelSendEmail() {
    dispatch(OrderAction.setIsOpenSendEmailPopup(false));
  }
  function customerEmailChange(event) {
    setCustomerEmail(event.target.value);
    if (event.target.value) {
      setValidCustomerEmailClassName("hidden");
      setBtnSendClassName("btn");
    } else {
      setValidCustomerEmailClassName("");
      setBtnSendClassName("btn disabled");
      setErrorCustomerEmail("To can't be blank");
    }
  }
  function subjectChange(event) {
    setSubject(event.target.value);
    setValidSubjectClassName(event.target.value ? "hidden" : "");
  }
  function customMessageChange(event) {
    setCustomMessage(event.target.value);
  }


  /**
   * template to render
   * @returns {*}
   */
  return (
    <>
      <Modal
        size="lg"
        className="popup-edit-customer popup-send-email"
        dialogClassName="popup-create-customer in"
        show={isOpenSendEmailPopup}
      >
        <div className="modal-header">
          <button
            type="button" className="cancel" data-dismiss="modal"
            onClick={() => cancelSendEmail()}
          >{t('Cancel')}
          </button>
          <h4 className="modal-title">{t('Send Invoice Email')}</h4>
          <button
            type="button" className={`save ${btnSendClassName}`} data-dismiss="modal"
            onClick={() => sendEmail()}
          >{t('Send')}
          </button>
        </div>
        <div ref={setBlockContentElement} className="modal-body" data-scrollbar>
          <div className="send-email">
            <Form.Label>{t('To')}</Form.Label>
            <div className="control">
              <Form.Control
                autoComplete="true"
                type="email" className="form-control"
                onChange={(event) => customerEmailChange(event)}
                value={customerEmail}
              />
            </div>
            <span className={validCustomerEmailClassName}>
              {t(errorCustomerEmail)}
            </span>
          </div>
          <div className="send-email">
            <Form.Label>{t('From')}</Form.Label>
            <div className="control">
              <Form.Control
                autoComplete="true"
                type="email" className="form-control not-allowed"
                value={staffEmail}
                readOnly
              />
            </div>
          </div>
          <div className="send-email">
            <Form.Label>{t('Subject')}</Form.Label>
            <div className="control">
              <Form.Control
                autoComplete="true"
                type="email" className="form-control"
                onChange={(event) => subjectChange(event)}
                value={subject}
              />
            </div>
            <span className={validSubjectClassName}>
              {t("Subject can't be blank")}
            </span>
          </div>
          <div className="send-email">
            <Form.Label>{t('Custom message (optional)')}</Form.Label>
            <div className="control">
              <Form.Control
                as="textarea"
                type="email" className="form-control"
                onChange={(event) => customMessageChange(event)}
                value={customMessage}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
OrderSendEmail.className = 'OrderSendEmail';
OrderSendEmail.propTypes = {
};
export default FunctionComponentFactory.get(OrderSendEmail);
