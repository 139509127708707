import PosConstant from "../constant/PosConstant";
import PosService from '../../service/pos/PosService';
import LocationService from '../../service/location/LocationService';
import StaffService from '../../service/staff/StaffService';
import ActionFactory from '../../framework/factory/ActionFactory';

export class PosActionClass {

  /**
   * Get Pos
   *
   * @param locationIds
   * @returns {(function(*): Promise<void>)|*}
   */
  getListPosByLocationIds(locationIds) {
    return async (dispatch) => {
      dispatch({
        type: PosConstant.GET_POS_LIST_BY_LOCATION_IDS,
        locationIds,
      });
      try {
        const response = await PosService.getListPosByLocationIds(locationIds);
        if (response && response.data && response.data.getAllPosByLocationIds) {
          dispatch(this.getListPosByLocationIdsSuccess(response.data.getAllPosByLocationIds));
        } else {
          dispatch(this.getListPosByLocationIdsFailed(response.error));
        }
      } catch (error) {
        dispatch(this.getListPosByLocationIdsFailed(error.message));
      }
    };
  }

  /**
   * Get pos success
   *
   * @param pos
   * @returns {{type: string}}
   */
  getListPosByLocationIdsSuccess(pos) {
    return {
      type: PosConstant.GET_POS_LIST_BY_LOCATION_IDS_SUCCESS,
      pos,
    };
  }

  /**
   * Get pos failed
   *
   * @param error
   * @returns {{type: string, error}}
   */
  getListPosByLocationIdsFailed(error) {
    return {
      type: PosConstant.GET_POS_LIST_BY_LOCATION_IDS_FAILED,
      error,
    };
  }

  /**
   * Enter Pos
   *
   * @param id
   * @param locationId
   * @returns {(function(*): Promise<void>)|*}
   */
  enterToPos(id, locationId) {
    return async (dispatch) => {
      dispatch({
        type: PosConstant.ENTER_TO_POS,
        id,
        locationId,
      });
      try {
        const response = await PosService.enterToPos(id, locationId);
        if (response && response.data && response.data.enterToPos) {
          PosService.saveCurrentPos(response.data.enterToPos.pos_id, response.data.enterToPos.pos_name);
          LocationService.saveCurrentLocation(locationId);
          StaffService.saveToken(response.data.enterToPos.token);
          dispatch(this.enterToPosSuccess(response.data.enterToPos));
        } else {
          dispatch(this.enterToPosFailed(response.error));
        }
      } catch (error) {
        dispatch(this.enterToPosFailed(error.message));
      }
    };
  }

  /**
   * Enter pos success
   *
   * @param pos
   * @returns {{type: string}}
   */
  enterToPosSuccess(pos) {
    return {
      type: PosConstant.ENTER_TO_POS_SUCCESS,
      pos,
    };
  }

  /**
   * Enter pos failed
   *
   * @param error
   * @returns {{type: string, error}}
   */
  enterToPosFailed(error) {
    return {
      type: PosConstant.ENTER_TO_POS_FAILED,
      error,
    };
  }

  /**
   * Reset enter to pos state
   *
   * @returns {{type: string, error}}
   */
  resetEnterToPosState() {
    return {
      type: PosConstant.RESET_ENTER_TO_POS_STATE,
    };
  }
}

const posAction = ActionFactory.get(PosActionClass);
export default posAction;
