import AbstractFactory from "./AbstractFactory";

export class DataResourceFactoryClass extends AbstractFactory {

  /**
   * Get target resource model to use
   *
   * @param object
   * @return {*}
   */
  get(object) {
    return this.getObject(`data_resource`, object);
  }
}

const DataResourceFactory = new DataResourceFactoryClass();
export default DataResourceFactory;
