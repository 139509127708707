import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Modal} from "react-bootstrap";
import {useTranslation} from 'react-i18next';

import '../../../../assets/Customer.css';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import layout from '../../../../framework/Layout';
import ProductList from '../catalog/ProductList.jsx';
import QuoteService from '../../../../service/checkout/QuoteService';
import CustomerAction from '../../../action/CustomerAction';
import QuoteAction from '../../../action/checkout/QuoteAction';

import CustomerList from './CustomerList.jsx';
import CustomerPopup from "./CustomerPopup.jsx";

function CustomerButton() {
  const dispatch = useDispatch();
  const {currentPage} = useSelector((state) => state.core.checkout.index);
  const {quote} = useSelector((state) => state.core.checkout);
  const {isShowList} = useSelector((state) => state.core.customer.customerList);
  const {t} = useTranslation();

  const clickCustomerCart = () => {
    if (currentPage !== ProductList.className) {
      return false;
    }
    if (quote.customer && quote.customer.id) {
      dispatch(CustomerAction.showEditCustomerPopup(quote.customer));
    } else {
      dispatch(CustomerAction.showCustomerListPopup());
    }
  };

  const selectCustomer = (customer) => {
    dispatch(QuoteAction.setCustomer(customer));
  };

  const getRemoveButton = () => {
    if (
      (currentPage === ProductList.className) &&
      quote.customer && quote.customer.id
    ) {
      return (
        <div className="remove-user" onClick={() => selectCustomer()} role="button" aria-hidden>
          {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
          <span>remove</span>
        </div>
      );
    }
    return null;
  };

  const dropdownToggleClassname = (currentPage === ProductList.className) ? "dropdown-toggle" : "dropdown-toggle disabled";

  const showCreateCustomerForm = () => {
    dispatch(CustomerAction.hideCustomerListPopup());
    dispatch(CustomerAction.showCreateCustomerPopup());
  };

  return (
    <>
      <div className="customer-drop dropdown">
        <div
          className={dropdownToggleClassname}
          data-toggle="modal" data-target="#popup-drop-customer"
          onClick={clickCustomerCart}
          aria-hidden="true"
        >
          {QuoteService.getCustomerName(quote)} &nbsp;

          {layout('checkout')('customer')('customer_button_after')()(this)}

        </div>
        {getRemoveButton()}
      </div>
      <Modal
        size="sm"
        className="popup-drop-customer"
        backdropClassName=""
        dialogClassName=""
        show={isShowList}
        onHide={() => dispatch(CustomerAction.hideCustomerListPopup())}
      >
        <ul className="dropdown-menu-customer">
          <li>
            <div className="toggle-create-customer" onClick={showCreateCustomerForm} role="button" aria-hidden>
              {t('Create Customer')}
            </div>
          </li>
        </ul>
        <CustomerList />
      </Modal>
      <CustomerPopup />
    </>
  );
}

CustomerButton.className = 'CustomerButton';
CustomerButton.propTypes = {};

export default React.memo(FunctionComponentFactory.get(CustomerButton));
