
import 'bootstrap/dist/css/bootstrap.min.css';

import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import FunctionComponentFactory from '../../framework/factory/FunctionComponentFactory';
import LocalStorageService from '../../service/LocalStorageService';

export function HomePageComponent() {
  const navigate = useNavigate();
  useEffect(() => {
    if (LocalStorageService.getToken()) {
      navigate('/checkout');
      return;
    }
    navigate('/login');
  }, [navigate]);
}

HomePageComponent.className = 'HomePageComponent';
export default FunctionComponentFactory.get(HomePageComponent);
