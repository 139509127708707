import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from './GraphqlAbstract';

export class GraphqlShopClass extends GraphqlAbstract {
  static className = 'GraphqlShopClass';

  /**
   * Get Shop Public Info
   * @returns {*}
   */
  getShopPublicInfo(shop = '') {
    return this.queryWithGQLBuilder(
      'shop',
      {
        shop: {
          name: 'shop',
          type: 'String!',
          value: shop || null,
        },
      },
      ['logo'],
      {
        operationName: 'getShop',
      },
    );
  }
}

/**
 * @type {GraphqlShopClass}
 */
export default DataResourceFactory.get(GraphqlShopClass);
