import ServiceFactory from "../framework/factory/ServiceFactory";

export class LocalStorageServiceClass {
  className = 'LocalStorageServiceClass';
  STAFF_ID = 'staff_id';
  OLD_STAFF_ID = 'old_staff_id';
  STAFF_NAME = 'staff_name';
  STAFF_EMAIL = 'staff_email';
  STAFF_USERNAME = 'staff_username';
  LOGO_URL = 'logo_url';
  TOKEN = 'token';
  LOCATIONS = 'locations';
  SHOP = 'shop';
  POS_ID = 'pos_id';
  POS_NAME = 'pos_name';
  LOCATION_ID = 'location_id';
  LOCATION_NAME = 'location_name';
  SHARING_ACCOUNT = 'sharing_account';
  ACTIVE_CART_ID = 'active_cart_id';

  /**
   * get value from local storage
   * @param key
   * @return string|Array|Object
   */
  get(key) {
    return localStorage.getItem(key);
  }

  /**
   * set data to local storage
   * @param key
   * @param value
   */
  set(key, value) {
    return localStorage.setItem(key, value);
  }

  /**
   * save location and pos to local storage
   *
   * @param locations
   */
  saveLocations(locations) {
    localStorage.setItem(this.LOCATIONS, locations);
  }

  /**
   * Get locations from local storage
   * @returns {string | null}
   */
  getLocations() {
    return localStorage.getItem(this.LOCATIONS);
  }

  /**
   * remove data to local storage
   * @param key
   * @return void
   */
  remove(key) {
    return localStorage.removeItem(key);
  }

  /**
   * set token to local storage
   * @param token
   */
  setToken(token) {
    localStorage.setItem(this.TOKEN, token);
  }

  /**
   * get token to local storage
   * @returns {string | null}
   */
  getToken() {
    return localStorage.getItem(this.TOKEN);
  }

  /**
   * token session
   */
  removeToken() {
    localStorage.removeItem(this.TOKEN);
  }

  /**
   * get active cart id from local storage
   * @returns {string | null}
   */
  getActiveCartId() {
    return localStorage.getItem(this.ACTIVE_CART_ID);
  }

  /**
   * set active cart id to local storage
   * @param activeCartId
   */
  setActiveCartId(activeCartId) {
    localStorage.setItem(this.ACTIVE_CART_ID, activeCartId);
  }
}

/**
 * @type {LocalStorageServiceClass}
 */
const localStorageService = ServiceFactory.get(LocalStorageServiceClass);
export default localStorageService;
