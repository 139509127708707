import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Anchor, Form} from 'react-bootstrap';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';

function CustomSaleInputComponent(props) {
  const [canClear, setCanClear] = useState(false);

/**
 * On change input
 */
  function onChange(newValue) {
    setCanClear(newValue.length);
    if (props.inputFieldOnChange) {
      props.inputFieldOnChange(props.Code, newValue);
    }
  }


  function template() {
    const {Code, Label, Placeholder, MaxLength, OneRow} = props;
    const id = `custom-sale-${Code}`;
    const remove = 'remove';
    const colClassName = OneRow ? "col-sm-12" : "col-sm-6";
    const canClearClassName = canClear ? "btn-remove show" : "btn-remove hidden";
    return (
      <div className={colClassName}>
        <Form.Label htmlFor={id}>{Label}</Form.Label>
        <Form.Control
          value={props.value}
          id={id}
          className="form-control"
          type="text"
          placeholder={Placeholder}
          maxLength={MaxLength}
          onChange={(event) => onChange(event.target.value)}
        />
        <Anchor
          className={canClearClassName}
          onClick={() => onChange("")}
        >
          <span>{remove}</span>
        </Anchor>
      </div>
    );
  }
  return template();
}

CustomSaleInputComponent.className = 'CustomSaleInputComponent';
CustomSaleInputComponent.propTypes = {
  Code: PropTypes.string,
  Label: PropTypes.string,
  DefaultValue: PropTypes.string,
  MaxLength: PropTypes.number,
  OneRow: PropTypes.bool,
  inputFieldOnChange: PropTypes.func,
  value: PropTypes.string,
};

export default React.memo(FunctionComponentFactory.get(CustomSaleInputComponent));
