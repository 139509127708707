import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import {Form, Button} from 'react-bootstrap';
import SmoothScrollbar from 'smooth-scrollbar';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import layout from '../../../../framework/Layout';
import '../../../../assets/Option.css';
import '../../../../assets/ExternalStock.css';
import StockAction from '../../../action/StockAction';
import DropdownOption from '../catalog/product/option/Dropdown.jsx';

import StockLocation from './StockLocation.jsx';

function ExternalStock() {
  const [displayLocations, setDisplayLocations] = useState([]);
  const [configOptions, setConfigOptions] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const {
    isShowExternalStock, product, externalStock, isLoading,
    productOptions, preSelectOptions, canBack,
  } = useSelector(
    (state) => state.core.stock.externalStockReducer,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      productOptions &&
      productOptions.length &&
      preSelectOptions &&
      !Object.keys(configOptions).length
    ) {
      setConfigOptions(preSelectOptions);
    }
  }, [preSelectOptions, productOptions, configOptions]);

  /**
   *
   */
  const changeSearchKey = (event) => {
    setSearchKey(event.target.value);
  };

  useEffect(() => {
    if (!externalStock) {
      return;
    }
    const newDisplayLocations = _.cloneDeep(externalStock);
    newDisplayLocations.map(
      (displayLocation) => {
        displayLocation.address = `${displayLocation.address1 || ''}, ${displayLocation.address2 || ''}, ${displayLocation.province || ''}, ${displayLocation.city || ''}, ${displayLocation.zip || ''}, ${displayLocation.country || ''}`;
        return displayLocation;
      },
    );
    setDisplayLocations(newDisplayLocations);
  }, [externalStock]);

  const formatAddress = (displayLocation) => {
    const sortOrder = [
      'address1',
      'address2',
      'province',
      'city',
      'zip',
      'country',
    ];
    const addressArray = [];
    sortOrder.forEach(
      (property) => {
        if (displayLocation[property]) {
          addressArray.push(displayLocation[property]);
        }
      },
    );
    return addressArray.join(', ');
  };

  useEffect(() => {
    if (!externalStock) {
      return;
    }
    const externalStockCurrent = _.cloneDeep(externalStock);
    let newDisplayLocations = [];
    const searchKeyChange = searchKey.toLowerCase();
    newDisplayLocations = externalStockCurrent.filter((displayLocation) => {
      const address = formatAddress(displayLocation);
      return displayLocation.name.toLowerCase().indexOf(searchKeyChange) > -1 ||
        address.toLowerCase().indexOf(searchKeyChange) > -1;
    });
    newDisplayLocations.map(
      (displayLocation) => {
        displayLocation.address = formatAddress(displayLocation);
        return displayLocation;
      },
    );
    setDisplayLocations(newDisplayLocations);
  }, [externalStock, searchKey]);


  useEffect(() => {
    if (!configOptions || Object.keys(configOptions).length === 0) {
      return;
    }
    let isSelectAllOptions = true;
    const selectOptions = [];
    productOptions.forEach(
      (productOption) => {
        if (configOptions[productOption.id]) {
          selectOptions.push(
            {
              id: productOption.id,
              value: configOptions[productOption.id],
            },
          );
        } else {
          isSelectAllOptions = false;
        }
      },
    );
    if (isSelectAllOptions) {
      dispatch(StockAction.showExternalStockByOptions(product, selectOptions));
    }
  }, [configOptions, product]); /* eslint-disable-line react-hooks/exhaustive-deps */

  const cancelExternalStock = () => {
    setSearchKey('');
    setConfigOptions({});
    dispatch(StockAction.hidePopup());
  };

  const clearSearchBox = () => {
    setSearchKey('');
  };

  const setModalOptionElement = (element) => {
    if (element) { SmoothScrollbar.init(element); }
  };

  const clickConfigOption = (optionId, value) => {
    setConfigOptions(
      {
        ...configOptions,
        [optionId]: value,
      },
    );
  };

  const {t} = useTranslation();

  const popupId = product.hasOnlyDefaultVariant ? 'popup-custom-product' : 'popup-configurable-product';

  let modalClass = `modal fade popup-addtocart popup-external-stock ${popupId}`;
  let fadeClass = 'popup-catalog modal-backdrop fade';
  if (isShowExternalStock) {
    modalClass += ' in show';
    fadeClass += ' in show';
  }

  const showConfig = productOptions ? "check-options" : "check-options hidden";

  const ProductOptionsComponent = productOptions.length
    ? productOptions.map((option) => {
      return (
        <DropdownOption key={Math.random()} clickConfigOption={clickConfigOption} configOptions={configOptions} option={option} />
      );
    }) : <div className="loader-product" />;

  const ProductOptionsWrapper = productOptions ? ProductOptionsComponent : null;

  const VariantOptionsComponent = product.hasOnlyDefaultVariant ? null
    : (
      <div className={showConfig} ref={setModalOptionElement}>
        <div className="product-options-wrapper" >
          {ProductOptionsWrapper}
        </div>
      </div>
    );

  const DisplayLocationList = displayLocations.length > 0 ? displayLocations.map((location) => {
    return (
      <StockLocation key={Math.random()} stockLocation={location} />
    );
  }) : (
    <li>
      <div className="noresultsearch">
        { t('We couldn\'t find any records.') }
      </div>
    </li>
  );

  const ListSearch = isLoading ? null : DisplayLocationList;
  const ClearButton = searchKey
    ? (
      <Button variant="remove" type="button" onClick={clearSearchBox} />
    ) : null;

  const backButton = () => {
    if (!canBack) {
      return null;
    }
    return (
      <div className="back-product" onClick={() => cancelExternalStock()} aria-hidden="true" />
    );
  };

  const cancelButton = () => {
    if (canBack) {
      return null;
    }
    return (
      <button
        type="button"
        className="cancel"
        data-dismiss="modal"
        aria-label={t('Close')}
        onClick={() => { cancelExternalStock(); }}
      >
        {t('Cancel')}
      </button>
    );
  };

  const modelContentClass = product.hasOnlyDefaultVariant ? 'modal-content modal-content-check check-simple'
    : 'modal-content modal-content-check';
  return (
    <>
      <div
        className={modalClass}
        id={popupId}
        data-backdrop="static" tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div
            className={modelContentClass}
            id="check-stock"
          >
            <div className="modal-header">
              {backButton()}
              {cancelButton()}
              <h4 className="modal-title">{product.title}</h4>
            </div>
            <div className="modal-body">
              {VariantOptionsComponent}
              <Form className="box-search">
                <Form.Group>
                  <Button variant="search" type="button" />
                  <Form.Control
                    className="input-search form-control"
                    maxLength={255}
                    type="text" placeholder={t('Search locations')}
                    onChange={changeSearchKey}
                    value={searchKey}
                  />
                  {ClearButton}
                </Form.Group>
              </Form>
              {layout('stock')('external_stock')('search_box_after')()(this)}
              <div
                className="loader-product-items"
                style={{display: isLoading ? 'block' : 'none'}}
              >
                <div className="loader-product" />
              </div>
              <div data-scrollbar className="list-search">
                <ul>
                  {ListSearch}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={fadeClass} />
    </>
  );
}

ExternalStock.className = 'ExternalStock';
ExternalStock.propTypes = {};

export default React.memo(FunctionComponentFactory.get(ExternalStock));
