import React from 'react';
import {MdEdit} from 'react-icons/md';

import {CustomKeyPad} from '../elements';
import NumberService from '../../../../../service/NumberService';
import CurrencyConstant from '../../../../constant/CurrencyConstant';

import CustomNumPad from './CustomNumPad';

/**
 * Default validation
 * @type {{float: function(): boolean, negative: function(): boolean}}
 */
const DefaultValidation = {
  float: () => true,
  negative: () => true,
};

/**
 * Positive validation
 * @type {{float: function(): boolean, negative: function(*=): boolean}}
 */
const PositiveValidation = {
  float: () => true,
  negative: (value) => parseInt(value, 10) > 0,
};

/**
 * Integer validation
 * @type {{float: function(*=): boolean, negative: function(): boolean}}
 */
const IntegerValidation = {
  float: (value) => parseFloat(value) % 1 === 0,
  negative: () => true,
  displayRule: (value) => {
    const next = value.toString().replace(/\D+/g, '');
    return parseInt(next, 10) || 0;
  },
  isInteger: true,
};

/**
 * Positive integer validation
 * @type {{float: function(*=): boolean, negative: function(*=): boolean}}
 */
const PositiveIntegerValidation = {
  float: (value) => parseFloat(value) % 1 === 0,
  negative: (value) => parseInt(value, 10) > 0,
};

/**
 * init default prop validation
 * @param Validation
 * @returns {{element, validation: function(*): boolean, formatInputValue: function(*): string, keyValid: keyValid, displayRule: function(*): string, inputButtonContent: *}}
 */
const defaultProps = (Validation) => ({
  element: CustomKeyPad,
  validation: (value) => value.length > 0,
  formatInputValue: (value) => value.toString().replace(/\D+/g, ''),
  keyValid: (value = '', key) => {
    let next;
    if (key === '.') {
      return false;
    }
    if (key === '-') {
      next = value.charAt(0) === '-' ? value.substr(1) : key + value;
    } else {
      next = value + key;
    }

    return !isNaN(next) && Validation.float(next) && Validation.negative(next);
  },
  setValue: (value) => {
    let precision = CurrencyConstant.DEFAULT_DISPLAY_PRECISION;
    if (Validation.isInteger) {
      precision = 0;
    }
    return NumberService.convertNumberToPriceHasPrecision(value, precision);
  },
  displayRule: (value) => {
    const next = value.toString().replace(/\D+/g, '');
    if (next === '') { return next; }
    let precision = CurrencyConstant.DEFAULT_DISPLAY_PRECISION;
    if (Validation.isInteger) {
      precision = 0;
    }
    return NumberService.convertNumberToPriceHasPrecision(next, precision);
  },
  inputButtonContent: <MdEdit />,
});

const CustomNumber = CustomNumPad(defaultProps(DefaultValidation));
const CustomPositiveNumber = CustomNumPad(defaultProps(PositiveValidation));
const CustomIntegerNumber = CustomNumPad(defaultProps(IntegerValidation));
const CustomPositiveIntegerNumber = CustomNumPad(defaultProps(PositiveIntegerValidation));

export {CustomNumber, CustomPositiveNumber, CustomIntegerNumber, CustomPositiveIntegerNumber};
