import PropTypes from 'prop-types';
import React, {useState, useEffect, useCallback} from 'react';
import {Form, Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from 'react-i18next';

import '../../../assets/Login.css';

import StaffAction from '../../action/StaffAction';
import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import layout from "../../../framework/Layout";
import platformService from '../../../service/PlatformService';
import StaffService from '../../../service/staff/StaffService';
import ScanAction from '../../action/ScanAction';
import ScanConstant from '../../constant/ScanConstant';

import ErrorModal from './ErrorModal.jsx';
import SharingAccountPopup from './SharingAccountPopup.jsx';

export function LoginComponent(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const connection = navigator.onLine;
  const {error, isLoading, isSuccess} = useSelector((state) => { return state.core.staffReducer.loginReducer; });
  const {isContinueLoginSuccess} = useSelector((state) => { return state.core.staffReducer.continueLoginReducer; });
  const magestoreUrl = 'https://www.magestore.com';
  const [user, setUser] = useState({
    username: '',
    password: '',
    shop: '',
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loginTitle, setLoginTitle] = useState(t('login'));
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');

  useEffect(() => {
    dispatch(ScanAction.setScanPage(ScanConstant.SCAN_PAGES.LOGIN));
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    if (props.shop && user.shop !== props.shop) {
      setUser(
        {
          ...user,
          shop: props.shop,
        },
      );
    }
  }, [props, user]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    dispatch(StaffAction.resetLoginState());
    navigate('/locations');
  }, [dispatch, isSuccess, navigate]);

  useEffect(() => {
    if (!isContinueLoginSuccess) {
      return;
    }
    dispatch(StaffAction.resetContinueLogin());
    navigate('/locations');
  }, [isContinueLoginSuccess]); /* eslint-disable-line react-hooks/exhaustive-deps */

  /**
   * Open invalid account popup
   * @param message
   */
  const showInvalidAccountMessage = useCallback(
    (message) => {
      setLoginTitle(t('login'));
      setIsOpenModal(true);
      setModalTitle(t('Error'));
      setModalContent(message);
    },
    [t],
  );

  useEffect(() => {
    if (error) {
      showInvalidAccountMessage(error);
    } else {
      setIsOpenModal(false);
    }
  }, [error, showInvalidAccountMessage]);

  useEffect(() => {
    if (isLoading) {
      setLoginTitle(t('please wait...'));
    } else {
      setLoginTitle(t('login'));
    }
  }, [isLoading, t]);

  const handleOnChange = (field, value) => {
    let newValue = value;
    if (field === 'username' && value) {
      newValue = newValue.toLowerCase();
    }
    setUser(
      {
        ...user,
        [field]: newValue,
      },
    );
  };

  const isDisableLoginButton = () => {
    return (!(user.username && user.password && user.shop)) || isLoading;
  };

  const confirm = () => {
    if (connection) {
      dispatch(StaffAction.closePopup());
    } else {
      setIsOpenModal(false);
    }
  };

  const showInternetWarning = () => {
    setIsOpenModal(true);
    setModalTitle(t('Network Error'));
    setModalContent(t('You must connect to a Wi-Fi or cellular data network to access the POS'));
  };

  const handleLogin = () => {
    if (connection) {
      dispatch(StaffAction.login(user.username, user.password, user.shop));
    } else {
      showInternetWarning();
    }
  };

  const ShopFieldComponent = platformService.isSaasPlatform()
    ? (
      <Form.Group className="form-group group-shop" controlId="loginForm.ControlShop">
        <Form.Label><span>{t('Store key')}</span></Form.Label>
        <Form.Control
          disabled={Boolean(props.shop)}
          maxLength={255}
          type="text" placeholder={t('Store key')} value={user.shop}
          onChange={(event) => handleOnChange('shop', event.target.value)}
        />
      </Form.Group>
    ) : null;

  const logoUrl = (props.shopPublicInfo && props.shopPublicInfo.logo)
    ? `${process.env.REACT_APP_API_URL}${props.shopPublicInfo.logo}` : "/images/256x256.png";
  const isSharingAccount = parseInt(StaffService.getSharingAccount(), 10);

  const ContentComponent = isSharingAccount ? <SharingAccountPopup />
    : (
      <Form className="wrapper-login" onSubmit={(event) => { event.preventDefault(); handleLogin(); }}>
        <div className="form-login">
          <strong className="logo">
            <img src={logoUrl} alt={t('Magestore')} />
          </strong>
          <h2 className="page-title">{t('Login')}</h2>
          {ShopFieldComponent}
          <Form.Group className="form-group group-username" controlId="loginForm.ControlUserName">
            <Form.Label><span>{t('Username')}</span></Form.Label>
            <Form.Control
              maxLength={255}
              type="text" placeholder={t('Username')}
              value={user.username}
              onChange={(event) => handleOnChange('username', event.target.value)}
            />
          </Form.Group>
          <Form.Group className="form-group group-password" controlId="loginForm.ControlPassword">
            <Form.Label><span>{t('Password')}</span></Form.Label>
            <Form.Control
              maxLength={255}
              type="password" placeholder={t('Password')} value={user.password}
              onChange={(event) => handleOnChange('password', event.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Button variant="default" type="submit" onClick={() => handleLogin()} disabled={isDisableLoginButton()}>{loginTitle}</Button>
          </Form.Group>
          <p className="powered-by-title">
            {t("Powered by ")}
            <a className="magestore-link" href={magestoreUrl}>{t("Magestore.")}</a>
          </p>
        </div>
      </Form>
    );
  return (
    <>
      {layout('staff')('staff_login_layout')('staff_login_layout_body_start')()(props)}
      {ContentComponent}

      {layout('staff')('staff_login_layout')('staff_login_layout_body_after')()(props)}
      <ErrorModal isOpenModal={isOpenModal} title={modalTitle} modalContent={modalContent} confirm={confirm} />
    </>
  );
}

LoginComponent.className = 'LoginComponent';
LoginComponent.propTypes = {
  shopPublicInfo: PropTypes.object,
  shop: PropTypes.string,
};
export default FunctionComponentFactory.get(LoginComponent);
