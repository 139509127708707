import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import ShippingAction from '../../../action/ShippingAction';

function ShippingAddressListing(props) {
  const dispatch = useDispatch();
  const {selectedAddress} = useSelector((state) => state.core.shipping.shippingPopup);
  const {quote} = useSelector((state) => state.core.checkout);

  const getClassAddress = (address) => {
    if (selectedAddress && selectedAddress.id === address.id) {
      return "box-address active";
    } else {
      return "box-address";
    }
  };

  const selectAddress = (address) => {
    if (selectedAddress?.id === address?.id) {
      return null;
    }

    const quoteTemp = _.clone(quote);
    quoteTemp.shippingAddress = {...address};

    dispatch(ShippingAction.selectAddress(address));
    dispatch(ShippingAction.setShippingMethod(quoteTemp));
  };

  let addressList = [];
  if (props.defaultAddress && props.defaultAddress.id) {
    addressList.push(props.defaultAddress);
  }
  let addressListWithoutDefaultAddress = [];
  if (props.addresses) {
    addressListWithoutDefaultAddress =
      props.addresses.filter((element) => (props.defaultAddress && props.defaultAddress.id && element.id !== props?.defaultAddress?.id));
  }
  addressList = addressList.concat(addressListWithoutDefaultAddress).reverse();
  return (
    <>
      {
        addressList && addressList.map((address) => {
          const addressArray = [];
          const addressCountryRegion = [];
          const addressKey = address.id ? address.id : Math.random();
          ['address1', 'address2', 'city', 'zip'].forEach(
            (property) => {
              if (address[property]) {
                addressArray.push(address[property]);
              }
            },
          );
          ['country', 'province'].forEach(
            (property) => {
              if (address[property]) {
                addressCountryRegion.push(address[property]);
              }
            },
          );
          return (
            <div
              aria-hidden="true"
              key={addressKey}
              className="col-sm-6 "
              onClick={() => selectAddress(address)}
            >
              <div className={getClassAddress(address)}>
                <strong className="title">{address.firstName} {address.lastName}</strong>
                <p>{addressArray.join(', ')}</p>
                <p>{addressCountryRegion.join(', ')}</p>
                <p>{address.phone}</p>
              </div>
            </div>
          );
        })
      }
    </>
  );
}

ShippingAddressListing.className = 'ShippingAddressListing';
ShippingAddressListing.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.object),
  defaultAddress: PropTypes.object,
};

export default React.memo(FunctionComponentFactory.get(ShippingAddressListing));
