import AbstractResourceModel from "../AbstractResourceModel";

export default class ConfigResourceModel extends AbstractResourceModel {
  static className = 'ConfigResourceModel';

  resourceName = 'Config';

  /**
   * Get Config
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getConfig() {
    return this.getResourceOnline().getConfig();
  }
}
