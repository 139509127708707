import AbstractResourceModel from "../AbstractResourceModel";

export default class StockResourceModel extends AbstractResourceModel {
  static className = 'StockResourceModel';

  resourceName = 'Stock';

  /**
   * Get Stock of one variant product
   * @returns {*}
   */
  getStockOneVariantProduct(id) {
    return this.getResourceOnline().getStockOneVariantProduct(id);
  }

  /**
   * Get Stock by product options
   * @param id
   * @param productOptions
   * @returns {*}
   */
  getStockByProductOptions(id, productOptions) {
    return this.getResourceOnline().getStockByProductOptions(id, productOptions);
  }
}
