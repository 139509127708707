import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import ModalSession from '../modal-session/ModalSession.jsx';
import SessionService from '../../../../service/session/SessionService';
import SessionAction from '../../../action/SessionAction';
import SessionConstant from '../../../constant/SessionConstant';

const CloseSession = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleCloseSession = (total) => {
    SessionService.closeSession(total);
    dispatch(SessionAction.setTypeSessionPopup(SessionConstant.CLOSE_ALL_POPUP));
  };

  return (
    <ModalSession
      title={t("Set Closing Balance")}
      handleAction={handleCloseSession}
      titleAction={t("Confirm")}
      titleNumPad={t('Closing Balance')}

    />
  );
};

CloseSession.className = 'CloseSessionComponent';
export default FunctionComponentFactory.get(CloseSession);
