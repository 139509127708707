import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Spinner} from 'react-bootstrap';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';

function AddressCustomerHeader(props) {
  const {t} = useTranslation();
  const {isLoadingSave} = props;
  const title = props.isNewAddress ? 'Add New Address' : 'Edit Address';
  const SaveComponent = isLoadingSave ? (
    <Spinner className="loading" animation="border" role="status">
      <span className="visually-hidden" />
    </Spinner>
  ) : (
    <button type="button" className="save" onClick={() => props.saveAddress()}>
      {t('Save')}
    </button>
  );
  return (
    <div className="modal-header">
      <button
        type="button"
        className="cancel close-shipping-customer"
        onClick={() => props.cancelPopup()}
      >
        {t('Cancel')}
      </button>
      <h4 className="modal-title">{t(title)}</h4>
      {SaveComponent}
    </div>
  );
}

AddressCustomerHeader.className = 'AddressCustomerHeader';
AddressCustomerHeader.propTypes = {
  isNewAddress: PropTypes.number,
  cancelPopup: PropTypes.func,
  saveAddress: PropTypes.func,
  isLoadingSave: PropTypes.bool,
};

export default React.memo(FunctionComponentFactory.get(AddressCustomerHeader));
