import {combineReducers} from 'redux';

import ShippingPopupReducer from './shipping/ShippingPopupReducer';
import ShippingAddressPopupReducer from './shipping/ShippingAddressPopupReducer';
import ShippingMethodReducer from './shipping/ShippingMethodReducers';

export default combineReducers({
  shippingPopup: ShippingPopupReducer,
  shippingAddressPopup: ShippingAddressPopupReducer,
  shippingMethod: ShippingMethodReducer,
});
