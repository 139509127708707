import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import ModalSessionCashControl from '../modal-session/ModalSessionCashControl.jsx';
import SessionService from '../../../../service/session/SessionService';
import SessionAction from '../../../action/SessionAction';
import SessionConstant from '../../../constant/SessionConstant';

const CloseSessionCashControl = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleCloseSession = (total) => {
    SessionService.closeSession(total);
    dispatch(SessionAction.setTypeSessionPopup(SessionConstant.CLOSE_ALL_POPUP));
  };

  return (
    <ModalSessionCashControl
      title={t("Set Closing Balance")}
      handleAction={handleCloseSession}
      titleAction={t("Confirm")}
      titleNumPad={t('Closing Balance')}
    />
  );
};

CloseSessionCashControl.className = 'CloseSessionCashControlComponent';
export default FunctionComponentFactory.get(CloseSessionCashControl);
