import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import '../../../../../assets/Customer.css';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';
import AddressAction from '../../../../action/AddressAction';

function CustomerAddressListing(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const getClassAddress = (address) => {
    if (props.defaultAddress && props.defaultAddress.id === address.id) {
      return "box-address active";
    } else {
      return "box-address";
    }
  };

  const editAddress = (address) => {
    dispatch(AddressAction.showEditAddressPopup(address));
  };

  const getAddressTitle = (address) => ((props.defaultAddress && props.defaultAddress.id === address.id)
    ? (
      <p className="text-right text-theme">
        {t('Default Address') }
      </p>
    ) : null);
  let addressList = [];
  if (props.defaultAddress) {
    addressList.push(props.defaultAddress);
  }
  let addressListWithoutDefaultAddress = [];
  if (props.addresses) {
    addressListWithoutDefaultAddress =
      props.addresses.filter((element) => (props.defaultAddress && props.defaultAddress.id && element.id !== props.defaultAddress.id));
  }
  addressList = addressList.concat(addressListWithoutDefaultAddress);
  return (
    <>
      {
        addressList && addressList.map((address) => {
          const addressArray = [];
          const addressCountryRegion = [];
          const addressKey = address.id ? address.id : Math.random();
          ['address1', 'address2', 'city', 'zip'].forEach(
            (property) => {
              if (address[property]) {
                addressArray.push(address[property]);
              }
            },
          );
          ['country', 'province'].forEach(
            (property) => {
              if (address[property]) {
                addressCountryRegion.push(address[property]);
              }
            },
          );
          return (
            <div
              aria-hidden="true"
              key={addressKey}
              className="col-sm-6 "
              onClick={() => editAddress(address)}
            >
              <div className={getClassAddress(address)}>
                {getAddressTitle(address)}
                <strong className="title">{address.firstName} {address.lastName}</strong>
                <p>{addressArray.join(', ')}</p>
                <p>{addressCountryRegion.join(', ')}</p>
                <p>{address.phone}</p>
              </div>
            </div>
          );
        })
      }
    </>
  );
}

CustomerAddressListing.className = 'CustomerAddressListing';
CustomerAddressListing.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.object),
  defaultAddress: PropTypes.object,
};

export default React.memo(FunctionComponentFactory.get(CustomerAddressListing));
