import React from 'react';

import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import Checkout from '../../component/checkout/Checkout.jsx';

function CheckoutPage() {
  return (
    <Checkout />
  );
}

CheckoutPage.className = 'CheckoutPage';
export default FunctionComponentFactory.get(CheckoutPage);
