import CoreService from "../../CoreService";
import NumberService from '../../NumberService';

export default class AbstractQuoteService extends CoreService {
  static className = 'AbstractQuoteService';

  /**
   * Reset quote data before collect total
   *
   * @param quote
   */
  resetQuoteData(quote) {
    quote.lineItems.forEach((item) => {
      delete item.discountedTotal;
      delete item.discountedTotalSet;
      delete item.discountedUnitPrice;
      delete item.discountedUnitPriceSet;
      delete item.originalTotal;
      delete item.originalTotalSet;
      delete item.originalUnitPrice;
      delete item.originalUnitPriceSet;
      delete item.totalDiscount;
      delete item.totalDiscountSet;
    });
  }

  /**
   * Get Items By Variant Id
   *
   * @param quote
   * @param variantId
   * @returns {T[]}
   */
  getItemsByVariantId(quote, variantId) {
    return quote.lineItems.filter((item) => variantId === item.variant.id);
  }

  /**
   * Get all qty of product in cart
   *
   * @param items
   * @param quote
   * @param variantId
   * @return {number}
   */
  getProductTotalItemsQtyInCart(items = null, quote = null, variantId = null) {
    let qty = 0;
    let listItem = items;
    if (!listItem) {
      listItem = this.getItemsByVariantId(quote, variantId);
    }
    if (listItem && listItem.length > 0) {
      listItem.forEach((item) => {
        qty = NumberService.addNumber(qty, item.quantity);
      });
    }
    return qty;
  }
}
