import ActionFactory from '../../framework/factory/ActionFactory';
import PaymentConstant from '../constant/PaymentConstant';

export class PaymentActionClass {

    /**
     * Switch page
     * @param paymentPage
     * @returns {{type: string, paymentPage: *}}
     */
  switchPage(paymentPage) {
    return {
      type: PaymentConstant.SWITCH_PAGE,
      paymentPage,
    };
  }
}

/**
 * @type {PaymentActionClass}
 */
const PaymentAction = ActionFactory.get(PaymentActionClass);
export default PaymentAction;
