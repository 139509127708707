import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import {MdBackspace} from 'react-icons/md';

import {media} from '../styles/media-templates';
import {HEIGHT, PADDING, WIDTH} from '../helper';
import i18n from "../../../../../config/i18n";

import Display from './PopoverDisplay';
import Button from './KeypadButton';

const Backspace = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 1.6em;
  padding: 0px 2px 0px 0px;
  color: ${(props) => props.theme.header.primaryColor};
  width: 33%;
  &:hover ${this} {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.12);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width}px;
  ${media.mobile`width: 100%;`} height: ${(props) => props.height}px;
  background: ${(props) => props.theme.body.backgroundColor};
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 45px, rgba(0, 0, 0, 0.22) 0px 10px 18px;
  
  border-radius: 6px;
`;

const Keys = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  button {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  button:nth-child(3n) {
    border-right: none;
  }
  button:nth-child(-n + 3) {
    border-top: 1px solid #ddd;
  }
`;

const Arrow = styled.div`
  border-width: ${PADDING}px;
  position: fixed;
  z-index: 10000;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-right-color: ${(props) => {
    if (props.$arrow === 'left') {
      return 'transparent';
    }
    return '#fff';
  }};
  border-left-width: ${(props) => {
    if (props.$arrow === 'left') {
      return '11px';
    }
    return '0';
  }};
  border-left-color: ${(props) => {
    if (props.$arrow === 'left') {
      return '#fff';
    }
    return 'transparent';
  }};
  border-right-width: ${(props) => {
    if (props.$arrow === 'left') {
      return '0';
    }
    return '11px';
  }};
  left: ${(props) => {
    if (props.$arrow === 'left') {
      return props ? `${props.coords.left - PADDING - 5}px` : '50%';
    }
    return props ? `${props.coords.left + props.coords.width}px` : '50%';
  }};
  margin-top: -${PADDING}px;
  top: ${(props) => (props ? `${props.coords.top + props.coords.height / 2}px` : '50%')};
`;


const ActionRow = styled.ul`
    width: 100%;
    height: 57px;
    li {
        width: 50%;
        float: left;
        height: 100%;
        line-height: 56px;
        text-align: center;
        a {
            span {
              font-size: 18px;
              top : 0px;
              font-weight: 400;
            }
        }
        &:hover {
            opacity: 0.8 !important;
        }
    };
`;
const ActionCancel = styled.li`
    a {
        color : #3c82ff !important;
    };
`;
const ActionConfirm = styled.li`
    color: #1d1d1d;
    background-color: #1d63dc;
    border-top: 1px solid #1d63dc;
    border-right: 1px solid #1d63dc;
    border-bottom: 1px solid #1d63dc;
    border-bottom-right-radius: 6px;
    a {
        color : #ffffff !important;
    };
`;

class PopoverKeyPad extends Component {
  control = (isIncrement) => {
    this.setState((prevState) => ({
      input: this.getDisplayText(this.getValidValue(prevState.input, isIncrement)),
    }));
  };

  constructor(props) {
    super(props);
    this.state = {input: props.value};
    this.isDecimal = props.isDecimal || false;
    this.step = props.qtyIncrement ? props.qtyIncrement : 1;
    this.allowKey = this.isDecimal
      ? [7, 8, 9, 4, 5, 6, 1, 2, 3, this.props.decimalSeparator, 0]
      : [7, 8, 9, 4, 5, 6, 1, 2, 3, '', 0];
    props.isSimpleMode && (this.allowKey = [7, 8, 9, 4, 5, 6, 1, 2, 3, '00', 0]); // eslint-disable-line
    this.handleClick = this.handleClick.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.cancelLastInsert = this.cancelLastInsert.bind(this);
    this.numericKeys = [...Array(10).keys()];
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {input} = prevState;
    const {sync, validation, update} = prevProps;
    if (sync && this.state.input !== input && validation(this.state.input)) {
      update(this.state.input);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);
  }

  getValidValue(text, isIncrement) {
    const {min, max, smartIncrement} = this.props;
    const prevVal = this.getValueText(text);
    let temp = prevVal;
    if (isIncrement !== undefined) {
      temp = prevVal + (isIncrement ? this.step : -this.step);
    }
    let newVal = Math.max(temp, min);

    if (min && this.isOverMin(text)) {
      this.props.onChangeOverMin(temp);
    }

    if (max) {
      if (this.isOverMax(this.getDisplayText(temp))) {
        this.props.onChangeOverMax(newVal);
      }

      newVal = Math.min(newVal, max);
    }

    newVal = this.isDecimal ? _.toNumber(newVal.toFixed(4)) : _.toInteger(newVal);

    /** if has increment qty */
    const newValPerStep = newVal % this.step;
    if (this.step !== 1 && newValPerStep !== 0 && smartIncrement) {
      newVal =
        newVal >= prevVal
          ? (_.toInteger(newVal / this.step) + 1) * this.step
          : (_.toInteger(newVal / this.step) - 1) * this.step;
    }

    if (!newVal && _.isNumber(min)) {
      newVal = min;
    }

    return newVal;
  }

  getDisplayText(value) {
    const {decimalSeparator} = this.props;
    return `${value}`.replace('.', decimalSeparator);
  }

  getValueText(text) {
    const {decimalSeparator} = this.props;
    return Number(text.replace(decimalSeparator, '.'));
  }

  isOverMin(current) {
    const {decimalSeparator, min} = this.props;
    return Number(current.replace(decimalSeparator, '.')) < min;
  }

  isOverMax(current) {
    const {decimalSeparator, max} = this.props;
    return Number(current.replace(decimalSeparator, '.')) > max;
  }

  keyDown(event) {
    event.preventDefault();
    const {key} = event;
    const {input} = this.state;
    const {confirm, cancel, validation, decimalSeparator} = this.props;
    if (key === 'Enter' && validation(input)) {
      confirm(input);
    } else if (key === 'Backspace') {
      this.cancelLastInsert();
    } else if (key === 'Escape') {
      cancel();
    } else if (this.numericKeys.includes(parseInt(key, 10)) || key === decimalSeparator) {
      this.handleClick(key);
    }
  }

  cancelLastInsert() {
    this.setState((prevState) => ({input: prevState.input.slice(0, -1)}));
  }

  handleClickOutside(evt, isConfirm = false) {
    evt.preventDefault();
    evt.stopPropagation();
    const {isShowAction, validation, confirm, cancel} = this.props;
    if (isShowAction && (!isConfirm)) {
      return cancel();
    }
    const {input} = this.state;
    if (validation(input)) {
      confirm(input);
    } else {
      cancel();
    }
  }

  handleClick(key) {
    if (this.props.keyValid(this.state.input, key, this.isDecimal, this.props.decimalSeparator)) {
      if (key === '-') {
        return this.setState((prevState) => ({
          input:
            prevState.input.charAt(0) === '-' ? prevState.input.substr(1) : `-${prevState.input}`,
        }));
      }

      if (
          this.props.isDecimal &&
          (
              (key === this.props.decimalSeparator) ||
              (
                  this.state.input.includes(this.props.decimalSeparator) &&
                  this.state.input.split(this.props.decimalSeparator).length > 1 && // check number is decimal
                  (
                      !this.state.input.split(this.props.decimalSeparator)[1].length || // decimal = '' eg: 012.
                      _.toNumber(this.state.input.split(this.props.decimalSeparator)[1]) // eg: 123.20
                  ) &&
                  this.state.input.split(this.props.decimalSeparator)[1].length < 4 &&
                  `${key}` === '0'
              )
          )
      ) {
        return this.setState((prevState) => ({input: prevState.input + key}));
      }

      return this.setState((prevState) => ({
        input: this.getDisplayText(this.getValidValue(prevState.input + key)),
      }));
    }

    return false;
  }


  render() {
    const {
      isShowAction,
      displayRule,
      theme,
      keyValid,
      decimalSeparator,
      terminalAlign,
      width,
      height,
      arrow,
      coords,
      isSimpleMode,
    } = this.props;

    return (
      <Content width={width} height={height} className="popover-key-pad">
        <Display
          control={isSimpleMode ? false : this.control}
          value={this.state.input}
          displayRule={displayRule}
          terminalAlign={terminalAlign}
          decimalSeparator={decimalSeparator}
        />
        <div className="pop-key-pad-message">
          <p>{this.props.message}</p>
        </div>
        <Keys>
          {this.allowKey.map((key) => (
            <Button
              key={`button-${key}`}
              theme={theme}
              click={(clickedKey) => this.handleClick(clickedKey)}
              value={key}
              disabled={!keyValid(this.state.input, key, this.isDecimal, decimalSeparator)}
            />
          ))}
          <Backspace onClick={this.cancelLastInsert}>
            <MdBackspace />
          </Backspace>
        </Keys>
        { (isShowAction)
            ? <ActionRow>
              <ActionCancel
                onClick={() => {
                  const {cancel} = this.props;
                  return cancel();
                }}
              >
                <a><span>{i18n.translator.translate('Cancel')}</span></a>
              </ActionCancel>
              <ActionConfirm onClick={(event) => this.handleClickOutside(event, true)} >
                <a><span>{i18n.translator.translate('Confirm')}</span></a>
              </ActionConfirm>
              </ActionRow>
          : ""
        }
        {arrow && <Arrow coords={coords} $arrow={arrow} />}
      </Content>
    );
  }
}

PopoverKeyPad.displayName = 'PopoverKeyPad';

PopoverKeyPad.propTypes = {
  isShowAction: PropTypes.bool,
  theme: PropTypes.string,
  confirm: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  displayRule: PropTypes.func.isRequired,
  validation: PropTypes.func.isRequired,
  keyValid: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  sync: PropTypes.bool.isRequired,
  decimalSeparator: PropTypes.string,
  isDecimal: PropTypes.bool,
  isSimpleMode: PropTypes.bool,
  qtyIncrement: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  smartIncrement: PropTypes.bool,
  coords: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  arrow: PropTypes.oneOf(['left', 'right', false]),
  terminalAlign: PropTypes.oneOf(['left', 'right', 'center']),
  width: PropTypes.number,
  height: PropTypes.number,
  onChangeOverMax: PropTypes.func,
  onChangeOverMin: PropTypes.func,
};

PopoverKeyPad.defaultProps = {
  theme: undefined,
  value: '',
  max: 99999999.9999,
  min: 0,
  decimalSeparator: '.',
  isDecimal: false,
  isSimpleMode: false,
  qtyIncrement: false,
  smartIncrement: false,
  coords: false,
  arrow: false,
  terminalAlign: 'center',
  width: WIDTH,
  height: HEIGHT,
  onChangeOverMax: () => {},
  onChangeOverMin: () => {},
};

export default PopoverKeyPad;
