import React, {Fragment, useEffect} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {cloneDeep} from 'lodash';

import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import "../../../../assets/OrderCancel.css";
import "../../../../assets/Order.css";
import OrderAction from '../../../action/OrderAction';
import QuoteAction from '../../../action/checkout/QuoteAction';

const ReorderConfirmation = () => {
  const {isOpenReorderConfirmPopup} = useSelector((state) => state.core.order.orderDetail);
  const {cannotAddProductList} = useSelector((state) => state.core.checkout.index);
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleClosePopup = () => {
    dispatch(OrderAction.setIsOpenReorderConfirmPopup(false));
    dispatch(QuoteAction.resetCannotAddProductList());
    dispatch(OrderAction.handleReorderFailed());
  };

  const handleProcessReorder = () => {
    const confirmProductList = cloneDeep(cannotAddProductList);
    const confirmData = [];
    confirmProductList.forEach((item) => {
      const reorderQuantity = item.data && item.data.quantity ? item.data.quantity - item.cannotAddQty : 0;
      if (!reorderQuantity) {
        return;
      }
      confirmData.push({
        product: item.data.product,
        variant: item.data.variant,
        quantity: reorderQuantity,
      });
    });
    dispatch(OrderAction.confirmAddAvailableQtyReorder(confirmData));
    handleClosePopup();
  };

  useEffect(() => {
    if (!isOpenReorderConfirmPopup) {
      return;
    }
    dispatch(OrderAction.setIsLoadingReorder(false));
  }, [isOpenReorderConfirmPopup, dispatch]);

  const productDataDisplay = (productData) => {
    return (
      <>
        {productData.cannotAddQty} x {productData.data.product.title}
      </>
    );
  };
  return (
    <>
      <Modal
        size="lg"
        className="popup-edit-customer popup-reorder-confirm"
        dialogClassName="popup-create-customer in"
        show={isOpenReorderConfirmPopup}
      >
        <div className="modal-header">
          <h4 className="modal-title" id="order-cancel-modal-title">{t('Confirmation')}</h4>
        </div>
        <div data-scrollbar className="modal-body">
          <br />
          <div className="text reorder-confirm-text">
            {t('Some product(s) could not be added to cart:')}
          </div>
          {
            cannotAddProductList.length > 0 && cannotAddProductList.map((productData) => {
              return (
                <div className="text reorder-confirm-text" key={productData?.data?.variant?.id}>
                  {productDataDisplay(productData)}
                </div>
              );
            })
          }
          <div className="text reorder-confirm-text">
            {t('Are you sure you want to reorder?')}
          </div>
          <div className="actions-bottom">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
            <a className="btn" onClick={handleClosePopup}>{t('No')}</a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
            <a className="btn" onClick={handleProcessReorder} >{t('Yes')}</a>
          </div>
        </div>
      </Modal>
    </>
  );
};

ReorderConfirmation.className = 'ReorderConfirmation';
ReorderConfirmation.propTypes = {
};

export default FunctionComponentFactory.get(ReorderConfirmation);
