import React, {Component} from 'react';

export default class CoreComponent extends Component {
  beforeHTML;
  afterHTML;

  constructor(props) {
    super(props);
    this.fireEvent();
  }

    /**
     *  abstract render method
     * @return {string}
     */
  template() {
    return '';
  }

    /**
     *  dispatch event to add render before or after template
     *  @return {void}
     */
  fireEvent() {
    let payload = {
      name: this.constructor.className,
      beforeHTML: this.beforeHTML,
      component: this,
    };

    this.beforeHTML = payload.beforeHTML;

    payload = {
      name: this.constructor.className,
      afterHTML: this.afterHTML,
      component: this,
    };

    this.afterHTML = payload.afterHTML;
  }

    /**
     *  render component
     *
     * @return {*}
     */
  render() {
    return (
      <>
        {this.beforeHTML}
        {this.template()}
        {this.afterHTML}
      </>
    );
  }
}
