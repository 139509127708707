export default {
  header: {
    primaryColor: '#263238',
    secondaryColor: '#ECEFF1',
    highlightColor: '#FFC107',
    backgroundColor: '#546E7A',
  },
  body: {
    primaryColor: '#263238',
    secondaryColor: '#32a5f2',
    highlightColor: '#FFC107',
    backgroundColor: '#fff',
  },
  panel: {
    backgroundColor: '#CFD8DC',
  },
};
