import {gql} from "@apollo/client";
import * as gqlBuilder from 'gql-query-builder';

import LocalStorageService from '../../../service/LocalStorageService';

export default class GraphqlAbstract {
  // Use for graphql
  apolloClient = require('./ApolloClient').default;

  /**
   * GraphQL Query
   *
   * @param {string} query
   * @param {object} variables
   * @returns {Promise<ApolloQueryResult<any>>}
   */
  query(query, variables = {}) {
    const token = LocalStorageService.getToken();
    return new Promise((resolve, reject) => {
      this.apolloClient.query({
        context: {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
          },
        },
        query: gql(query),
        variables,
      }).then((response) => {
        if (response.errors && response.errors.length) {
          return reject(response.errors);
        } else {
          return resolve(response);
        }
      }).catch((error) => {
        return reject(error);
      });
    });
  }

  /**
   * Query with gql builder
   *
   * @param operation
   * @param variables
   * @param fields
   * @param config
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  queryWithGQLBuilder(operation, variables, fields, config) {
    const gqlQuery = gqlBuilder.query({
      operation,
      variables,
      fields,
    }, null, config);
    return this.query(gqlQuery.query, gqlQuery.variables);
  }

  /**
   * GraphQL Mutate
   *
   * @param {string} mutation
   * @param {object} variables
   * @returns {Promise<FetchResult<any>>}
   */
  mutate(mutation, variables = {}) {
    const token = LocalStorageService.getToken();
    return new Promise((resolve, reject) => {
      this.apolloClient.mutate({
        context: {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
          },
        },
        mutation: gql(mutation),
        variables,
      }).then((response) => {
        if (response.errors && response.errors.length) {
          return reject(response.errors);
        } else {
          return resolve(response);
        }
      }).catch((error) => {
        return reject(error);
      });
    });
  }

  /**
   * Mutation with gql builder
   *
   * @param operation
   * @param variables
   * @param fields
   * @param config
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  mutationWithGQLBuilder(operation, variables, fields, config) {
    const gqlQuery = gqlBuilder.mutation({
      operation,
      variables,
      fields,
    }, null, config);
    return this.mutate(gqlQuery.query, gqlQuery.variables);
  }

  /**
   * GraphQL subscribe
   *
   * @param {string} query
   * @param {object} variables
   * @returns {Observable<FetchResult<any>>}
   */
  subscribe(query, variables = {}) {
    const token = LocalStorageService.getToken();
    return this.apolloClient.subscribe({
      context: {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      },
      query: gql(query),
      variables,
    });
  }

  /**
   * Subscribe with gql builder
   * @param operation
   * @param variables
   * @param fields
   * @param config
   * @returns {Observable<FetchResult<*>>}
   */
  subscribeWithGQLBuilder(operation, variables, fields, config) {
    const gqlQuery = gqlBuilder.subscription({
      operation,
      variables,
      fields,
    }, null, config);
    return this.subscribe(gqlQuery.query, gqlQuery.variables);
  }
}

