import _ from 'lodash';

import ServiceFactory from "../../framework/factory/ServiceFactory";
import Config from '../../config/Config';
import CustomerPopupConstant
  from '../../view/constant/customer/CustomerPopupConstant';
import CountryService from '../CountryService';
import CoreService from '../CoreService';

import CustomerPopupService from './CustomerPopupService';


export class CustomerAddressFieldServiceClass extends CoreService {
  static className = 'CustomerAddressFieldService';

  getCustomerAddressConfig() {
    const customerAddressFields = [];
    if (!Config.config) {
      return [];
    }
    const customerAddressFormMetadata = _.cloneDeep(Config.config.customerAddressFormMetadata);
    customerAddressFormMetadata.sort((item1, item2) => item1.sortOrder - item2.sortOrder);
    customerAddressFormMetadata.forEach((element) => {
      if (!element.visible) {
        return;
      }
      if (element.frontendInput === CustomerPopupConstant.FRONTEND_INPUT_TYPE_TEXT) {
        let requiredEmail = false;
        if (element.attributeCode === CustomerPopupConstant.ATTRIBUTE_CODE_EMAIL) {
          requiredEmail = true;
        }
        customerAddressFields.push(
          CustomerPopupService.createCustomerFieldInput(
            element.attributeCode,
            element.attributeCode,
            CustomerPopupConstant.TYPE_FIELD_INPUT,
            element.frontendLabel,
            element.required,
            requiredEmail,
            element.maxLength,
            false,
          ),
        );
      } else if (element.frontendInput === CustomerPopupConstant.FRONTEND_INPUT_TYPE_SELECT) {
        const options = element.options;
        customerAddressFields.push(
          CustomerPopupService.createCustomerFieldSelect(
            element.attributeCode,
            element.attributeCode,
            CustomerPopupConstant.TYPE_FIELD_SELECT,
            element.frontendLabel,
            element.required,
            options,
            false,
          ),
        );
      } else if (element.frontendInput === CustomerPopupConstant.FRONTEND_INPUT_TYPE_COMBOBOX) {
        customerAddressFields.push(
          CustomerPopupService.createCustomerFieldCombobox(
            element.attributeCode,
            element.attributeCode,
            CustomerPopupConstant.TYPE_FIELD_COMBOBOX,
            element.frontendLabel,
            element.required,
            element.options,
            true,
          ),
        );
        customerAddressFields.push(
          CustomerPopupService.createCustomerFieldComboboxSuggest(
            `${element.attributeCode}_suggest`,
            `${element.attributeCode}_suggest`,
            CustomerPopupConstant.TYPE_FIELD_COMBOBOX_SUGGEST,
            element.frontendLabel,
            element.required,
            element.options,
            true,
          ),
        );
      }
    });

    customerAddressFields.push(
      CustomerPopupService.createCustomerFieldCheckBox(
        'is_default_address',
        'is_default_address',
        CustomerPopupConstant.TYPE_FIELD_CHECKBOX,
        "Use as default address",
        false,
        false,
        true,
      ),
    );
    return customerAddressFields;
  }

  /**
   * Load field by country
   * @param countryCode
   */
  loadFieldByCountry(countryCode) {
    if (!countryCode) {
      return [];
    }
    const countryData = CountryService.loadCountryData(countryCode);
    const labels = countryData.labels;
    const newZones = countryData.zones.map(
      (zone) => {
        return {
          value: zone.code,
          label: zone.name,
        };
      },
    );
    const customerAddressConfig = this.getCustomerAddressConfig();
    const newCustomerAddress = customerAddressConfig
    .filter(
      (fieldAttribute) => {
        if (fieldAttribute.code === 'is_default_address') {
          return true;
        }
        if (!labels[fieldAttribute.code]) {
          return false;
        }
        return !(fieldAttribute.code === 'zone' && !newZones.length);
      },
    ).map(
      (fieldAttribute) => {
        if (fieldAttribute.code === 'zone') {
          fieldAttribute.options = newZones;
        }
        if (fieldAttribute.code === 'postalCode') {
          fieldAttribute.optional = !countryData.postalCodeRequired;
          fieldAttribute.required = !countryData.postalCodeRequired;
        }
        if (labels[fieldAttribute.code]) {
          fieldAttribute.label = labels[fieldAttribute.code];
        }
        return fieldAttribute;
      },
    );
    return CustomerPopupService.setRowCustomerField(newCustomerAddress);
  }
}

/** @type CustomerAddressFieldServiceClass */
const customerAddressFieldService = ServiceFactory.get(CustomerAddressFieldServiceClass);
export default customerAddressFieldService;
