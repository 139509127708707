import React, {Fragment} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import FunctionComponentFactory from '../../../../../framework/factory/FunctionComponentFactory';
import "../../../../../assets/OrderCancel.css";
import "../../../../../assets/Order.css";
import ConflictProductDiscountAction from '../../../../action/checkout/conflict-discount/ConflictProductDiscountAction';

const ConflictProductAutoDiscountComponent = () => {
  const {isOpenConflictProductAutoDiscountPopup, appliedCustomDiscountLineItems} = useSelector((state) => state.core.checkout.conflictDiscount);
  const {quote} = useSelector((state) => state.core.checkout);

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleClosePopup = () => {
    dispatch(ConflictProductDiscountAction.closeConflictAutoDiscountPopup());
  };

  const handleCheckoutWithAutoProductDiscount = () => {
    dispatch(ConflictProductDiscountAction.handleCheckoutWithAutoProductDiscount(quote));
    handleClosePopup();
  };

  const handleCheckoutWithCustomPrice = () => {
    dispatch(ConflictProductDiscountAction.handleCheckoutWithCustomPrice(quote));
    handleClosePopup();
  };

  const productDataDisplay = (productData) => {
    return (
      <>
        {productData.quantity} x {productData.title}
      </>
    );
  };

  return (
    <>
      <Modal
        size="lg"
        className="popup-edit-customer popup-reorder-confirm"
        dialogClassName="popup-create-customer in"
        show={isOpenConflictProductAutoDiscountPopup}
      >
        <div className="modal-header">
          <h4 className="modal-title" id="order-cancel-modal-title">{t('Confirmation')}</h4>
        </div>
        <div data-scrollbar className="modal-body">
          <br />
          <div className="text reorder-confirm-text">
            {t('Custom price cannot be applied together with product discount. Some items has been applied custom price, and there are product discount(s) to be applied.')}
          </div>
          <div className="text reorder-confirm-text reorder-confirm-strong-text">
            {t('You must select which one to apply.')}
          </div>
          <div className="text reorder-confirm-text">
            {t('Note: Applying product discount will remove custom price on these items:')}
          </div>
          {
            appliedCustomDiscountLineItems?.length > 0 && appliedCustomDiscountLineItems.map((productData) => {
              return (
                <div className="text reorder-confirm-text" key={productData.id}>
                  {productDataDisplay(productData)}
                </div>
              );
            })
          }
          <div className="actions-bottom">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
            <a className="btn" onClick={handleCheckoutWithCustomPrice}>{t('Custom Price')}</a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
            <a className="btn" onClick={handleCheckoutWithAutoProductDiscount} >{t('Product Discount')}</a>
          </div>
        </div>
      </Modal>
    </>
  );
};

ConflictProductAutoDiscountComponent.className = 'ConflictProductAutoDiscountComponent';
ConflictProductAutoDiscountComponent.propTypes = {
};

export default FunctionComponentFactory.get(ConflictProductAutoDiscountComponent);
