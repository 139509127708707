import React, {Fragment, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import layout from '../../../../framework/Layout';
import QuoteItemService
  from '../../../../service/checkout/quote/QuoteItemService';
import ProductList from '../catalog/ProductList.jsx';
import QuoteAction from '../../../action/checkout/QuoteAction';
import NumPad from '../../lib/react-numpad';
import ProductStockService from '../../../../service/product/stock/ProductStockService';
import NumberService from '../../../../service/NumberService';
import EditPrice from '../items/EditPrice.jsx';
import CurrencyService from '../../../../service/CurrencyService';
import QuoteConstant from '../../../constant/checkout/QuoteConstant';
import PermissionService from '../../../../service/permission/PermissionService';
import PermissionConstant from '../../../constant/PermissionConstant';

function CartItem(props) {
  const dispatch = useDispatch();
  const {quote, item} = props;
  const {currentPage} = useSelector((state) => state.core.checkout.index);
  const {t} = useTranslation();

  const isDisableEdit = (currentPage !== ProductList.className);
  let className = "item-info";
  if (isDisableEdit) {
    className += ' on-payment-screen';
  }

  const displayOption = () => {
    const options = QuoteItemService.getItemOptionsLabelAsArray(quote, item);
    return options.map((option) => {
      return (
        <Fragment key={option}>
          <span className="item-option" key={option}>{option}</span>
        </Fragment>
      );
    });
  };

  const displayItemNote = () => {
    const itemNoteValue = QuoteItemService.getItemNote(item);
    if (itemNoteValue) {
      const itemNote = t('Note: {{itemNoteValue}}', {itemNoteValue});
      return (
        <>
          <span className="item-option">{itemNote}</span>
        </>
      );
    }
    return null;
  };

  const handleClickRemoveItem = (event) => {
    event.stopPropagation();
    dispatch(QuoteAction.removeCartItem(item));
  };

  const updateQtyCartItem = useCallback((newQty) => {
    if (Number(newQty) !== item.quantity) {
      dispatch(QuoteAction.updateQtyCartItem(item, Number(newQty)));
    }
  }, [dispatch, item]);

  const getItemDetailBlock = () => {
    const itemQty = Number(QuoteItemService.getTotalQty(item));
    const itemQtyClassname = itemQty === 1 ? 'hidden' : 'qty';
    if (isDisableEdit) {
      return (
        <>
          <div className="item-image">
            <div className="image">
              <img
                loading="lazy" src={QuoteItemService.getItemImage(item)}
                alt=""
              />
              <div className={itemQtyClassname}>
                {itemQty}
              </div>
            </div>
          </div>
          <div className="item-detail">
            <span
              className="item-name"
              dangerouslySetInnerHTML={{__html: QuoteItemService.getItemName(item)}}
            />
            {displayOption()}
            {displayItemNote()}
            {layout('checkout')('cart')('cart_item')('cart_item_itemdetail_after')()()}
          </div>
        </>
      );
    }

    return (
      <NumPad.Popover
        onChange={updateQtyCartItem}
        position="centerRight"
        arrow="right"
        value={itemQty}
        qtyIncrement={Number(ProductStockService.getQtyIncrement())}
        isDecimal={false}
        decimalSeparator={NumberService.getDecimalSeparator()}
        min={0}
        useParentCoords
        isShowAction
      >
        <div className="item-image">
          <div className="image">
            <img
              loading="lazy" src={QuoteItemService.getItemImage(item)}
              alt=""
            />
            <div className={itemQtyClassname}>
              {itemQty}
            </div>
          </div>
        </div>
        <div className="item-detail">
          <span
            className="item-name"
            dangerouslySetInnerHTML={{__html: QuoteItemService.getItemName(item)}}
          />
          {displayOption()}
          {displayItemNote()}
          {layout('checkout')('cart')('cart_item')('cart_item_itemdetail_after')()()}
        </div>
      </NumPad.Popover>
    );
  };

  function ItemPriceDisplay() {
    const priceAmount = QuoteItemService.getPriceItemByType(item, QuoteConstant.PRICE_ITEM_TYPE_DISCOUNTED_TOTAL);
    const displayPrice = CurrencyService.format(priceAmount);
    const displayOriginalPrice = QuoteItemService.getDisplayOriginalPrice(quote, item);

    return (
      <div className="item-price">
        <div className="price">{displayPrice}</div>
        {item.appliedDiscount && displayPrice !== displayOriginalPrice && <div className="original"> {displayOriginalPrice}</div>}
      </div>
    );
  }

  const canEditPrice = () => {
    const maxDiscountPercent = QuoteItemService.getMaxDiscountPercent();
    const canEditPriceStaffPermission = PermissionService.isAllowed(PermissionConstant.PERMISSION_ADD_CUSTOM_PRICE) && (maxDiscountPercent !== 0);
    return !isDisableEdit && canEditPriceStaffPermission;
  };
  const getItemPriceBlock = () => {
    if (!canEditPrice()) {
      return <ItemPriceDisplay />;
    }
    return (
      <>
        <EditPrice
          // eslint-disable-next-line no-empty-function
          onChange={() => { }}
          key={`${item.item_id}_custom_price`}
          useParentCoords
          position="centerRight"
          arrow="right"
          quote={quote}
          item={item}
        >
          <ItemPriceDisplay />
        </EditPrice>
      </>
    );
  };

  const getRemoveButton = () => {
    if (isDisableEdit) {
      return null;
    }
    return (
      <div
        className="swiper-slide item-actions"
        onClick={handleClickRemoveItem}
        aria-hidden="true"
      >
        <div
          className="action-remove"
          onClick={handleClickRemoveItem}
          aria-hidden="true"
        >
          {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
          <span>remove</span>
        </div>
      </div>
    );
  };

  return (
    <li className={`item ${QuoteItemService.getItemId(item)}`}>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className={className}>
              {getItemDetailBlock()}
              {getItemPriceBlock()}
            </div>
          </div>
          {getRemoveButton()}
        </div>
        <div className="item-detail-after">
          {layout('checkout')('cart')('cart_item')('cart_item_itemdetail_after')()(props, isDisableEdit)}
        </div>
      </div>
    </li>
  );
}

CartItem.className = 'CartItem';
CartItem.propTypes = {
  quote: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default FunctionComponentFactory.get(CartItem);
