import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import SessionService from '../../../../service/session/SessionService';
import SessionConstant from '../../../constant/SessionConstant';

const Session = (props) => {
  const {t} = useTranslation();

  const {isActive, session, handleSelectedSession} = props;
  const openedDate = SessionService.formatDate(session.opened_at);
  const closedDate = SessionService.getClosedDate(openedDate, session.closed_at);
  const collectedRevenue = SessionService.formatAmount(session.collected_revenue);
  const openedTime = SessionService.formatTime(session.opened_at);
  const closedTime = SessionService.formatTime(session.closed_at);

  return (
    <li
      className={`item ${isActive && 'active'}`} role="presentation"
      onClick={() => handleSelectedSession(session)}
    >
      <div className="item-info">
        <div className="name">
          <p className="value">
            {openedDate}{closedDate && ' - '}
          </p>
          <p className="value">
            {closedDate}
          </p>
          <span className="date">
            {openedTime}{closedTime && ` - ${closedTime}`}
          </span>
          <span className="status">{session.status === SessionConstant.SESSION_OPEN && t('In Progress')}</span>
        </div>
        <div className="price">
          <span className="value">{collectedRevenue}</span>
        </div>
      </div>
    </li>
  );
};

Session.propTypes = {
  isActive: PropTypes.bool.isRequired,
  session: PropTypes.object.isRequired,
  handleSelectedSession: PropTypes.func.isRequired,
};

Session.className = 'SessionComponent';
export default FunctionComponentFactory.get(Session);
