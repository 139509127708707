import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import FunctionComponentFactory
  from '../../../../../../framework/factory/FunctionComponentFactory';

function ProductDropdownOptionComponent(props) {
  const {t} = useTranslation();
  const {option} = props;
  const getClassAttribute = (value) => {
    if (props.configOptions[option.id] === value) {
      return 'selected';
    } else {
      return '';
    }
  };
  return (
    <>
      <div key={option.id} className="product-options-attribute">
        <div className="attribute-label">{t(option.name)}</div>
        <div className="attribute-options">
          {
            option.values.map(
              (value) => {
                return (
                  <div
                    key={`option-${value}`} className={`attribute-option ${getClassAttribute(value)}`}
                    aria-hidden="true"
                    onClick={() => props.clickConfigOption(option.id, value)}
                  >
                    {t(value)}
                  </div>
                );
              },
            )
          }
        </div>
      </div>
    </>
  );
}

ProductDropdownOptionComponent.className = 'ProductDropdownOptionComponent';
ProductDropdownOptionComponent.propTypes = {
  clickConfigOption: PropTypes.func,
  option: PropTypes.object,
  configOptions: PropTypes.object,
};

export default FunctionComponentFactory.get(ProductDropdownOptionComponent);
