/**
 * Init GLOBAL_VARIABLES to store pos config
 */
if (typeof window.pos === 'undefined') {
  window.pos = {config: {}};
}
const GLOBAL_VARIABLES = window.pos.config;

let countCart = 0;

export function getCount() {
  return countCart;
}

export function setCount(newCount) {
  countCart = newCount;
}
export default GLOBAL_VARIABLES;
