import DataResourceFactory from "../../framework/factory/DataResourceFactory";
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';
import AddressService from '../../service/customer/AddressService';

import OmcAbstract from "./OmcAbstract";
import GraphqlCustomer from './graphql/GraphqlCustomer';

export class OmcCustomer extends OmcAbstract {
  static className = 'OmcCustomer';

  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlCustomer,
  };

  /**
   * Get Customers
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getCustomers(input) {
    return this.getApiResource().getCustomers(input);
  }

  /**
   * Save Customer
   * @param customer
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  saveCustomer(customer) {

    const newCustomer = customer;

    delete newCustomer.__typename;
    delete newCustomer.displayName;
    newCustomer.addresses = (newCustomer.addresses && newCustomer.addresses.length) ? newCustomer.addresses.map(
      (address) => {
        delete address.postalCode;
        delete address.zone;
        delete address.country;
        delete address.is_default_address;
        if (AddressService.isUnsavedAddress(address.id)) {
          delete address.id;
        }
        return address;
      },
    ) : [];

    /* Temp wait for address */
    return this.getApiResource().saveCustomer(newCustomer);
  }

  /**
   * Get Customer
   * @param customerId
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getCustomer(customerId) {
    return this.getApiResource().getCustomer(customerId);
  }
}

/**
 * @type {OmcCustomer}
 */
export default DataResourceFactory.get(OmcCustomer);
