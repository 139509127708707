import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import PosResourceModel from '../../resource-model/pos/PosResourceModel';
import LocalStorageService from '../LocalStorageService';

/**
 * Pos Service Class
 */
export class PosServiceClass extends CoreService {
  static className = 'PosServiceClass';

  resourceModel = PosResourceModel;

  /**
   * Get list pos by location ids
   * @param locationIds
   * @returns {promise}
   */
  getListPosByLocationIds(locationIds) {
    return this.getResourceModel().getListPosByLocationIds(locationIds);
  }

  /**
   * Enter to pos
   * @param id
   * @param locationId
   * @returns {promise}
   */
  enterToPos(id, locationId) {
    return this.getResourceModel().enterToPos(id, locationId);
  }

  /**
   * Save current logined POS
   *
   * @param posId
   * @param posName
   */
  saveCurrentPos(posId, posName) {
    LocalStorageService.set(LocalStorageService.POS_ID, posId);
    LocalStorageService.set(LocalStorageService.POS_NAME, posName);
  }

  /**
   * Get Pos Id
   * @returns {string}
   */
  getCurrentPosId() {
    return LocalStorageService.get(LocalStorageService.POS_ID);
  }

  /**
   * Get Current Pos Name
   * @returns {string}
   */
  getCurrentPosName() {
    return LocalStorageService.get(LocalStorageService.POS_NAME);
  }

  /**
   * remove pos id and pos name local storage
   *
   * @return void
   */
  removeCurrentPos() {
    LocalStorageService.remove(LocalStorageService.POS_ID);
    LocalStorageService.remove(LocalStorageService.POS_NAME);
  }
}

/**
 * @type {PosServiceClass}
 */
const posService = ServiceFactory.get(PosServiceClass);

export default posService;
