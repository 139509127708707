import LoadingConstant from '../constant/LoadingConstant';
import ActionFactory from '../../framework/factory/ActionFactory';

export class LoadingActionClass {

  /**
   * action update finished list
   *
   * @param dataType
   * @returns {{dataType: *, type: string}}
   */
  updateFinishedList(dataType) {
    return {
      type: LoadingConstant.UPDATE_FINISHED_LIST,
      dataType,
    };
  }

  /**
   * Reset State
   * @returns {{type: string}}
   */
  resetState() {
    return {
      type: LoadingConstant.RESET_STATE,
    };
  }
}

const LoadingAction = ActionFactory.get(LoadingActionClass);
export default LoadingAction;
