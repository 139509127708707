const sessionConstant = {
  GET_SESSIONS: '[SESSION] GET_SESSIONS',
  GET_SESSIONS_SUCCESS: '[SESSION] GET_SESSIONS_SUCCESS',
  GET_SESSIONS_FAILED: '[SESSION] GET_SESSIONS_FAILED',

  GET_SESSION_DETAIL: '[SESSION] GET_SESSION_DETAIL',
  GET_SESSION_DETAIL_SUCCESS: '[SESSION] GET_SESSION_DETAIL_SUCCESS',
  GET_SESSION_DETAIL_FAILED: '[SESSION] GET_SESSION_DETAIL_FAILED',
  SET_LOADING_DETAIL_PAGE: '[SESSION] SET_LOADING_DETAIL_PAGE',

  POPUP_OPEN_SESSION_CASH_CONTROL: '[SESSION] POPUP_OPEN_SESSION_CASH_CONTROL',
  POPUP_OPEN_SESSION: '[SESSION] POPUP_OPEN_SESSION',
  POPUP_CLOSE_SESSION: '[SESSION] POPUP_CLOSE_SESSION',
  POPUP_CLOSE_SESSION_CASH_CONTROL: '[SESSION] POPUP_CLOSE_SESSION_CASH_CONTROL',
  POPUP_VALIDATE_SESSION: '[SESSION] POPUP_VALIDATE_SESSION',
  POPUP_PUT_MONEY_IN: '[SESSION] POPUP_PUT_MONEY_IN',
  POPUP_TAKE_MONEY_OUT: '[SESSION] POPUP_TAKE_MONEY_OUT',
  POPUP_NO_OPEN_CLOSED_PERMISSION: '[SESSION] POPUP_NO_OPEN_CLOSED_PERMISSION',
  CLOSE_ALL_POPUP: '[SESSION] CLOSE_ALL_POPUP',
  SET_TYPE_SESSION_POPUP: '[SESSION] SET_TYPE_SESSION_POPUP',

  RESET_STATE: '[SESSION] RESET_STATE',

  PAGE_SIZE: 16,
  ACTION_FIRST_PAGE: 'FIRST',
  ACTION_NEXT_PAGE: 'NEXT',
  ACTION_PREVIOUS_PAGE: 'PREVIOUS',

  SET_CURRENT_SESSION: '[SESSION] SET_CURRENT_SESSION',

  REQUEST_OPEN_SESSION: '[SESSION] REQUEST_OPEN_SESSION',
  REQUEST_CLOSE_SESSION: '[SESSION] REQUEST_CLOSE_SESSION',
  OPEN_SESSION_SUCCESS: '[SESSION] OPEN_SESSION_SUCCESS',
  CLOSE_SESSION_SUCCESS: '[SESSION] CLOSE_SESSION_SUCCESS',

  RESET_IS_CREATE_SESSION: '[SESSION] RESET_IS_CREATE_SESSION',

 // Session status
  SESSION_OPEN: 1,
  SESSION_CLOSE: 0,

  BUTTON_PLUS: 'PLUS',
  BUTTON_MINUS: 'BUTTON_MINUS',
  CHANGE_NUMPAD: 'CHANGE_NUMPAD',

  // Cash transaction
  ADD: 'add',
  REMOVE: 'remove',
  CASH_TITLE: 'Cash',
  REPLACE: 'replace',

  TRANSACTION_PUT_IN: 'put_in',
  TRANSACTION_PAID_CASH: 'paid_cash',
  TRANSACTION_PAID_CREDIT_CARD: 'paid_credit_card',
  TRANSACTION_TAKE_OUT: 'take_out',
  TRANSACTION_REFUND_CASH: 'refund_cash',
  TRANSACTION_REFUND_CREDIT_CARD: 'refund_credit_card',
  TYPE_ADD_CASH: ['put_in', 'paid_cash'],
  TYPE_REMOVE_CASH: ['take_out', 'refund_cash'],

  NAME_PAYMENT_METHOD_CASH: "Cash",
  ADD_TRANSACTION_SUCCESS: '[SESSION] ADD_TRANSACTION_SUCCESS',
};

export default sessionConstant;
