import ObjectManager from "../ObjectManager";

import AbstractFactory from "./AbstractFactory";

export class ServiceFactoryClass extends AbstractFactory {

  /**
   * get target service to use
   *
   * @param object
   * @return {*}
   */
  get(object) {
    return ObjectManager.get(this.getObject(`Service`, object));
  }
}

const serviceFactory = new ServiceFactoryClass();

export default serviceFactory;
