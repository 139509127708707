const posConstant = {
  GET_POS_LIST_BY_LOCATION_IDS: '[POS] GET_POS_LIST_BY_LOCATION_IDS',
  GET_POS_LIST_BY_LOCATION_IDS_SUCCESS: '[POS] GET_POS_LIST_BY_LOCATION_IDS_SUCCESS',
  GET_POS_LIST_BY_LOCATION_IDS_FAILED: '[POS] GET_POS_LIST_BY_LOCATION_IDS_FAILED',

  ENTER_TO_POS: '[POS] ENTER_TO_POS',
  ENTER_TO_POS_SUCCESS: '[POS] ENTER_TO_POS_SUCCESS',
  ENTER_TO_POS_FAILED: '[POS] ENTER_TO_POS_FAILED',
  RESET_ENTER_TO_POS_STATE: '[POS] RESET_ENTER_TO_POS_STATE',
};

export default posConstant;
