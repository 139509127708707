import ServiceFactory from "../../../../framework/factory/ServiceFactory";

import AbstractAddProductService from "./AbstractAddProductService";

export class AddSimpleProductServiceClass extends AbstractAddProductService {
  static className = 'AddSimpleProductServiceClass';
}

/** @type AddSimpleProductService */
const addSimpleProductService = ServiceFactory.get(AddSimpleProductServiceClass);

export default addSimpleProductService;
