import moment from "moment";

import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import ConfigService from '../config/ConfigService';
import ConfigConstant from '../../view/constant/ConfigConstant';
import SessionResourceModel from "../../resource-model/session/SessionResourceModel";
import CurrencyService from "../CurrencyService";
import SessionConstant from "../../view/constant/SessionConstant";
import LocalStorageService from "../LocalStorageService";
import SubscriptionService from "../subscription/SubscriptionService";
import PlanConstants from "../../view/constant/PlanConstants";
import StaffService from "../staff/StaffService";

/**
 * Staff Service Class
 */
export class SessionServiceClass extends CoreService {
  static className = 'SessionServiceClass';
  resourceModel = SessionResourceModel;

  /**
   * Is Enable Session Management
   *
   * @returns {boolean}
   */
  isEnableSessionManagement() {
    const isEnable = ConfigService.getConfig(ConfigConstant.ENABLE_SESSION_MANAGEMENT_PATH);
    return Boolean(isEnable && isEnable === ConfigConstant.YES);
  }

  /**
   * Validate Session Management permission
   */
  async validatePermission() {
    const shop = StaffService.getShopUrl();
    const validate = await SubscriptionService.validatePermission([PlanConstants.ALL_PERMISSION_PLAN.base.session_management], shop);

    return validate;
  }

  /**
   * Is Enable Cash Control
   * @returns {boolean}
   */
  isEnableCashControl() {
    const config = JSON.parse(localStorage.getItem('config'));

    if (!config) {
      return false;
    }

    const {settings = []} = config;

    const enableCashControlPath = settings.find((setting) => {
      return setting?.path === ConfigConstant.ENABLE_CASH_CONTROL_PATH;
    });

    const isEnable = enableCashControlPath?.value;

    return Boolean(isEnable && isEnable === ConfigConstant.YES);
  }

  /**
   * Need Open Session
   * @returns {boolean}
   */
  needOpenSession() {
    const currentSession = JSON.parse(localStorage.getItem('current_session'));
    if (!currentSession || currentSession.status === SessionConstant.SESSION_CLOSE) {
      return true;
    }
    return false;
  }

  /**
   * Format Date: Weekday, mm dd
   * @param {*} date
   * @returns {string} - Weekdays, mm dd
   */
  formatDate(timestamp, format = 'dddd, MMMM DD') {
    if (timestamp) {
      const momentTimestamp = moment.unix(Number(timestamp / 1000));
      return `${momentTimestamp.format(format)}`;
    }
    return '';
  }

  /**
   * Get closed date
   * @param {*} openedDate
   * @param {*} timestamp
   * @returns {string}
   */
  getClosedDate(openedDate, timestamp) {
    const closedDate = this.formatDate(timestamp);
    return closedDate === openedDate ? '' : closedDate;
  }

  /**
   * Get time have format HH:MM AM/PM
   * @param {*} timestamp
   * @returns {string} - HH:MM AM/PM | ''
   */
  formatTime(timestamp) {
    if (timestamp) {
      const momentTimestamp = moment.unix(Number(timestamp / 1000));
      return `${momentTimestamp.format('hh:mm A')}`;
    }
    return '';
  }

  /**
   * Get Sessions
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getSessions(input) {
    return this.getResourceModel().getSessions(input);
  }

  /**
   * Get Session Detail
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getSessionDetail(input) {
    return this.getResourceModel().getSessionDetail(input);
  }

  /**
   * Open Session
   * @param {float} input
   * @returns {Promise}
   */
  openSession(input) {
    return this.getResourceModel().openSession(input);
  }

  /**
   * Validate Close Session
   * @param {*} input
   * @returns {Promise}
   */
  validateCloseSession(input) {
    return this.getResourceModel().closeSession(input);
  }

  /**
   * Put Money In
   * @param {*} input
   * @returns {Promise}
   */
  addTransaction(input) {
    return this.getResourceModel().addTransaction(input);
  }


  /**
   * Get Denominations
   * @returns {object[]} - []
   */
  getDenominations() {
    return JSON.parse(localStorage.getItem("denominations"));
  }

  /**
   * Get Pos Name
   * @returns {string}
   */
  getPosName() {
    return localStorage.getItem("pos_name");
  }


  /**
   * Format Amount
   * @param {*} amount
   * @returns {*}
   */
  formatAmount(amount) {
    return CurrencyService.format(Number(amount));
  }

  /**
   * Rebuild Denominations
   * @returns {object[]}
   */
  rebuildDenominations() {
    const items = this.getDenominations();
    items.sort((itemA, itemB) => itemA.sort_order - itemB.sort_order);

    return items.map((item) => {
      return {
        ...item,
        subTotal: 0,
        number: 0,
      };
    });
  }

  /**
   * Get Different Amount Between theoretically and real closing balance
   * @param {*} closed_amount
   * @param {*} theoreticalClosingBalance
   * @returns {int}
   */
  getDifferentAmount(closed_amount, theoreticalClosingBalance) {
    const num = (Number(closed_amount) - Number(theoreticalClosingBalance)) || 0;

    return num;
  }

  /**
   * Close Session
   * @param {*} amount
   */
  closeSession(amount) {
    const currentSession = this.getSessionInProcess();
    currentSession.closed_amount = amount;
    currentSession.closingBalance = true;

    localStorage.setItem('current_session', JSON.stringify(currentSession));
  }

  /**
   * Has Redirect Session Page
   * @returns {boolean}
   */
  async hasRedirectSessionPage() {

    localStorage.removeItem('current_session');
    const validateResponse = await this.validatePermission();
    if (LocalStorageService.getToken() && this.isEnableSessionManagement() && validateResponse.data.validatePermissions.result) {
      const variables = {
        pos_id: Number(localStorage.getItem('pos_id')),
      };

      const response = await this.getSessionDetail(variables);

      const session = response.data.getSessionDetail.session;
      if (session) {
        localStorage.setItem('current_session', JSON.stringify(session));
      }
      if (!session || session?.status === SessionConstant.SESSION_CLOSE) {
        window.location.replace('/session');
        return true;
      }
    }
    return false;
  }

  /**
   * Get Session In Process
   * @returns {object | null}
   */
  getSessionInProcess() {
    return JSON.parse(localStorage.getItem('current_session'));
  }

  /**
   * Get Session Render
   * @param {*} item
   * @returns {object}
   */
  getSessionRender(item) {
    const sessionInProcess = this.getSessionInProcess();
    if (Number(item?.id) === Number(sessionInProcess?.id)) {
      sessionInProcess.collected_revenue = item.collected_revenue;
      localStorage.setItem('current_session', JSON.stringify(sessionInProcess));
      return sessionInProcess;
    }
    return item;
  }

  /**
   * Update session in process in local storage
   * @param {object} dataUpdate
   */
  updateSessionInProcessInLocalStorage(dataUpdate) {
    const sessionInProcess = this.getSessionInProcess();
    for (const [key, value] of Object.entries(dataUpdate)) {
      sessionInProcess[key] = value;
    }
    localStorage.setItem('current_session', JSON.stringify(sessionInProcess));
  }

  /**
   * Has Set Closing Balance
   * @returns {boolean}
   */
  hasSetClosingBalance() {
    return Boolean(this.getSessionInProcess()?.closingBalance);
  }

  /**
   * Get Current Location For Report
   * @returns
   */
  getCurrentLocationForReport() {
    const locations = JSON.parse(localStorage.getItem('locations'));

    const locationId = localStorage.getItem('location_id');

    const locationTemp = locations.find((location) => {
      return location.id === locationId;
    });

    if (!locationTemp) {
      return '';
    }

    const address = locationTemp.address;

    const temp = [];

    const fields = ['address1', 'address2', 'province', 'city', 'zip', 'country'];

    fields.forEach((field) => {
      if (address[field]) {
        temp.push(address[field]);
      }
    });

    if (!temp.length) {
      return '';
    }

    return temp.join(', ');
  }

}

/**
 * @type {SessionServiceClass}
 */
const SessionService = ServiceFactory.get(SessionServiceClass);

export default SessionService;
