import React from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import SessionAction from '../../../action/SessionAction';
import ModalSessionCashControl from '../modal-session/ModalSessionCashControl.jsx';

const OpenSessionCashControl = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleOpenSession = (total) => {
    dispatch(SessionAction.openSession(total));
  };

  return (
    <ModalSessionCashControl
      title={t("Open Session")}
      handleAction={handleOpenSession}
      titleAction={t("Open Session")}
      titleNumPad={t('Opening Balance')}
    />
  );
};

OpenSessionCashControl.className = 'OpenSessionCashControlComponent';
export default FunctionComponentFactory.get(OpenSessionCashControl);
