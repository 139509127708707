import React from 'react';
import PropType from "prop-types";

import OrderService from '../../../../../service/order/OrderService';

const ShippingMethod = ({shippingLine}) => {
  if (!shippingLine) {
    return (
      <></>
    );
  }
  return (
    <ul className="shipping-method">
      <li>
        <div className="title" dangerouslySetInnerHTML={{__html: shippingLine.title}} />
        <div className="value">
          {OrderService.formatPrice(shippingLine.discountedPriceSet.shopMoney.amount)}
        </div>
      </li>
    </ul>
  );
};

ShippingMethod.propTypes = {
  shippingLine: PropType.object.isRequired,
};
export default ShippingMethod;
