const ConfigConstant = {
  GET_CONFIG: '[CONFIG] GET_CONFIG',
  GET_CONFIG_RESULT: '[CONFIG] GET_CONFIG_RESULT',
  GET_CONFIG_ERROR: '[CONFIG] GET_CONFIG_ERROR',

  TYPE_GET_CONFIG: 'get_config',

  SHOPIFY_DEFAULT_DOMAIN_SUFFIX: ".myshopify.com",
  LOGO_PATH: "webpos/general/webpos_logo",
  STORE_KEY_PATH: "webpos/general/store_key",
  POS_URL_PATH: "webpos/general/pos_url",
  SESSION_TIMEOUT_PATH: "webpos/general/session_timeout",
  ENABLE_SESSION_MANAGEMENT_PATH: "webpos/session/enable",
  ENABLE_CASH_CONTROL_PATH: "webpos/session/enable_cash_control",
  SESSION_SINCE_PATH: "webpos/session/session_since",
  CHECKOUT_ADD_OUT_OF_STOCK_PRODUCT_PATH: "webpos/checkout/add_out_of_stock_product",
  CHECKOUT_AUTOMATICALLY_SEND_MAIL_PATH: "webpos/checkout/automatically_send_mail",
  POS_APP_HANDLE_PATH: "webpos/app/handle",

  POS_APP_HANDLE_ID: "webpos/app/id",

  NO: '0',
  YES: '1',
};

export default ConfigConstant;
