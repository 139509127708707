import React from 'react';
import {useSelector} from 'react-redux';

import '../../../assets/Payment.css';


import FunctionComponentFactory
  from '../../../framework/factory/FunctionComponentFactory';
import PaymentConstant from '../../constant/PaymentConstant';

import SelectPayment from './payment/SelectPayment.jsx';

function Payment() {
  const {paymentPage} = useSelector((state) => state.core.checkout.payment);

  const getSelectPaymentBlock = () => {
    if (paymentPage !== PaymentConstant.PAYMENT_PAGE_SELECT_PAYMENT) {
      return null;
    }
    return <SelectPayment />;
  };

  return (
    <>
      {getSelectPaymentBlock()}
    </>
  );
}

Payment.className = 'Payment';
Payment.propTypes = {};

export default FunctionComponentFactory.get(Payment);
