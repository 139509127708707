import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import CheckoutPaymentAction from "../../../action/checkout/payment/CheckoutPaymentAction";
import i18n from "../../../../config/i18n";

import ListPayment from "./ListPayment.jsx";
import EditPaymentCheckout from "./EditPayment.jsx";
import CompletePayment from "./CompletePayment.jsx";

function SelectPayment() {
  const {isOpenListPayment, isOpenEditPayment, isOpenCompletePayment, remaining} = useSelector((state) => state.core.checkout.payment);
  const {quote} = useSelector((state) => state.core.checkout);
  const wrapperPayment = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CheckoutPaymentAction.setRemaining(quote.totalPrice));
  }, [dispatch, quote.totalPrice]);

  useEffect(() => {
    return () => {
      dispatch(CheckoutPaymentAction.setDefaultPaymentScreen(quote.totalPrice));
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="wrapper-payment active" id="wrapper-payment1" ref={wrapperPayment}>
        <div className="block-title">
          <strong className="title">{i18n.t('Payment')}</strong>
        </div>
        {isOpenListPayment && <ListPayment remaining={parseFloat(remaining)} grandTotal={parseFloat(quote.totalPrice)} />}
        {isOpenEditPayment && <EditPaymentCheckout remaining={parseFloat(remaining)} grandTotal={parseFloat(quote.totalPrice)} />}
        {isOpenCompletePayment && <CompletePayment grandTotal={parseFloat(quote.totalPrice)} />}
      </div>
    </>
  );
}

SelectPayment.className = 'SelectPayment';
SelectPayment.propTypes = {};

export default FunctionComponentFactory.get(SelectPayment);
