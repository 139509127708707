import ProductConstant from '../../constant/ProductConstant';

const initialState = {
  products: [],
  pageInfo: null,
  isLoading: false,
  textToSearch: '',
};

/**
 * Receive action from Product Action
 *
 * @param state = {products: []}
 * @param action
 * @returns {*}
 */
const productListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProductConstant.GET_PRODUCTS: {
      const newState = {
        ...state,
        isLoading: true,
      };
      if (action.input.pageAction === ProductConstant.ACTION_FIRST_PAGE) {
        newState.products = [];
        newState.pageInfo = null;
      }
      return newState;
    }
    case ProductConstant.GET_PRODUCTS_SUCCESS: {
      const {
        items,
        pageInfo,
      } = action;
      return {
        ...state,
        products: items,
        pageInfo,
        isLoading: false,
      };
    }
    case ProductConstant.GET_PRODUCTS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ProductConstant.SET_TEXT_TO_SEARCH:
      return {
        ...state,
        textToSearch: action.text,
      };
    default:
      return state;
  }
};

export default productListReducer;
