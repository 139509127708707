import DataResourceFactory from "../../framework/factory/DataResourceFactory";
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';

import OmcAbstract from "./OmcAbstract";
import GraphqlShop from './graphql/GraphqlShop';

export class OmcShop extends OmcAbstract {
  static className = 'OmcShop';

  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlShop,
  };


  /**
   * Get Brand List
   * @returns {*}
   */
  getShopPublicInfo(shop = '') {
    const resource = this.getApiResource();
    return resource.getShopPublicInfo(shop);
  }
}

/**
 * @type {OmcStaff}
 */
export default DataResourceFactory.get(OmcShop);
