import OrderConstant from '../../constant/OrderConstant';

const initialState = {
  isProcessingOrder: false,
  errorCompleteProductList: [],
  isOpenErrorCompletePopup: false,
  isPrintReceipt: false,
};

const orderCompleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case OrderConstant.COMPLETE_ORDER:
      return {
        ...state,
        isProcessingOrder: true,
      };
    case OrderConstant.COMPLETE_ORDER_SUCCESS:
      return {
        ...state,
        isProcessingOrder: false,
      };
    case OrderConstant.COMPLETE_ORDER_FAILED:
      return {
        ...state,
        isProcessingOrder: false,
      };
    case OrderConstant.SET_IS_OPEN_ERROR_COMPLETE_POPUP:
      return {
        ...state,
        isOpenErrorCompletePopup: action.isOpenErrorCompletePopup,
      };
    case OrderConstant.SET_ERROR_COMPLETE_PRODUCT_LIST:
      return {
        ...state,
        errorCompleteProductList: action.errorCompleteProductList,
        isOpenErrorCompletePopup: action.isOpenErrorCompletePopup,
      };
    case OrderConstant.SET_IS_PROCESSING_ORDER:
      return {
        ...state,
        isProcessingOrder: action.isProcessingOrder,
      };
    case OrderConstant.PRINT_RECEIPT_AFTER_CREATE_ORDER:
      return {
        ...state,
        isPrintReceipt: true,
      };
    case OrderConstant.PRINT_RECEIPT_AFTER_CREATE_ORDER_SUCCESS:
      return {
        ...state,
        isPrintReceipt: false,
      };
    case OrderConstant.PRINT_RECEIPT_AFTER_CREATE_ORDER_FAILED:
      return {
        ...state,
        isPrintReceipt: false,
      };
    default:
      return state;
  }
};

export default orderCompleteReducer;
