import DataResourceFactory from "../../framework/factory/DataResourceFactory";
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';

import OmcAbstract from "./OmcAbstract";
import GraphqlOrder from './graphql/GraphqlOrder';

export class OmcOrder extends OmcAbstract {
  static className = 'OmcOrder';

  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlOrder,
  };

  /**
   * Get Orders
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getOrders(input) {
    return this.getApiResource().getOrders(input);
  }

  /**
   * Get Order
   * @param {*} id
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getOrder(id) {
    return this.getApiResource().getOrder(id);
  }

  /**
   * Send Email
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  sendEmail(input) {
    return this.getApiResource().sendEmail(input);
  }

  /**
   * Cancel order
   * @param {*} order
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  cancelOrder(id) {
    return this.getApiResource().cancelOrder(id);
  }

  /**
   * Get Fulfillment Orders
   * @param {*} orderId
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getFulfillmentOrder(orderId) {
    return this.getApiResource().getFulfillmentOrder(orderId);
  }

  /**
   *
   * create record payment
   * @param listPaid
   * @param order
   * @param shop
   * @return {Promise}
   */
  createRecordPayment(listPaid, order, shop = '', session) {
    return this.getApiResource().createRecordPayment(listPaid, order, shop, session);
  }

  /**
   * Complete Order
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  orderComplete(input) {
    return this.getApiResource().orderComplete(input);
  }

  /**
   * Create Fulfillment Order
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  createFulfillmentOrder(input) {
    return this.getApiResource().createFulfillmentOrder(input);
  }

  /**
   * Fulfillment Order Move
   * @param {*} input
   * @returns {Promise}
   */
  fulfillmentOrderMove(input) {
    return this.getApiResource().fulfillmentOrderMove(input);
  }


  /**
   * create record refund
   * @param refundLineItems
   * @param transactions
   * @param shipping
   * @param note
   * @param order
   * @param shop
   * @return {Promise}
   */
  createRecordRefund(refundLineItems, transactions, shipping, note, order, shop, session) {
    return this.getApiResource().createRecordRefund(refundLineItems, transactions, shipping, note, order, shop, session);
  }

  /**
   *
   * @param order
   * @param customer
   * @param shop
   */
  updateOrderCustomer(order, customer, shop) {
    return this.getApiResource().updateOrderCustomer(order, customer, shop);
  }
}

export default DataResourceFactory.get(OmcOrder);
