import StaffConstant from "../constant/StaffConstant";

const initialState = {
  message: '',
};

/**
 * receive action from sign out action
 *
 * @param state
 * @param action
 * @returns {*}
 */
const signoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case StaffConstant.STAFF_SET_SIGN_OUT_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    default:
      return state;
  }
};

export default signoutReducer;
