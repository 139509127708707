import {toast} from "react-toastify";
import _, {cloneDeep} from 'lodash';

import ActionFactory from "../../framework/factory/ActionFactory";
import OrderService from '../../service/order/OrderService';
import OrderConstant from "../constant/OrderConstant";
import i18n from '../../config/i18n';
import DraftOrderService from "../../service/draft-order/DraftOrderService";
import staffService from "../../service/staff/StaffService";
import CheckoutConstant from "../constant/checkout/CheckoutConstant";
import LocationService from "../../service/location/LocationService";
import ProductService from "../../service/product/ProductService";
import QuoteService from "../../service/checkout/QuoteService";
import multiCartService from "../../service/cart/MultiCartService";
import customerService from "../../service/customer/CustomerService";
import shippingService from "../../service/shipping/ShippingService";
import localStorageService from "../../service/LocalStorageService";
import QuoteConstant from "../constant/checkout/QuoteConstant";
import QuoteItemService from "../../service/checkout/quote/QuoteItemService";

import CheckoutPaymentAction from "./checkout/payment/CheckoutPaymentAction";
import CheckoutAction from "./checkout/CheckoutAction";
import MultiCartAction from "./MultiCartAction";
import QuoteAction from "./checkout/QuoteAction";

export class OrderActionClass {

  /**
   * Get Orders
   * @param {*} input
   * @returns {(function(*): Promise<void>)|*}
   */
  getOrders(input) {
    return async (dispatch) => {
      dispatch({
        type: OrderConstant.GET_ORDERS,
        input,
      });

      try {

        const response = await OrderService.getOrders(input);
        dispatch(this.getOrdersSuccess(response.data.getOrders));

        if (!response.data.getOrders?.items || response.data?.getOrders?.items?.length === 0) {
          dispatch({
            type: OrderConstant.SET_IS_LOADING_ORDER_DETAIL_PAGE,
            isLoading: false,
          });
        }
      } catch (error) {
        dispatch(this.getOrdersFailed(error));
      }
    };
  }

  /**
   * Get Order Success
   * @param {*} result
   * @returns {{result, type: string}}
   */
  getOrdersSuccess(result) {
    return {
      type: OrderConstant.GET_ORDERS_SUCCESS,
      items: result.items,
      pageInfo: result.pageInfo,
    };
  }

  /**
   * Get Order Failed
   * @param {*} error
   * @returns {{type: string, error}}
   */
  getOrdersFailed(error) {
    return {
      type: OrderConstant.GET_ORDERS_FAILED,
      error,
    };
  }

  /**
   * Set Current Order
   * @param {*} order
   * @returns  {{type: string, currentOrder}}
   */
  setCurrentOrder(order) {
    return {
      type: OrderConstant.SET_CURRENT_ORDER,
      currentOrder: order,
    };
  }

  /**
   * get Order detail
   * @param {*} id
   * @returns  {(function(*): Promise<void>)|*}
   */
  getOrder(id) {
    return async (dispatch, getState) => {
      dispatch({
        type: OrderConstant.GET_ORDER,
        id,
      });

      try {
        const response = await OrderService.getOrder(id);
        const currentOrder = getState()?.core?.order?.orderList?.currentOrder;
        // compare current order and order responsed. If it's equal, then set order to reducer
        if (!currentOrder || currentOrder?.id === id) {
          dispatch(this.getOrderSuccess(response.data.getOrder));
        }
      } catch (error) {
        dispatch(this.getOrderFailed(error));
      }

    };
  }

  /**
   * Get Order Success
   * @param {*} order
   * @returns {{type: string, result}}
   */
  getOrderSuccess(order) {
    return {
      type: OrderConstant.GET_ORDER_SUCCESS,
      order,
    };
  }

  /**
   * Get Order Failed
   * @param {*} error
   * @returns {{type: string, error}}
   */
  getOrderFailed(error) {
    return {
      type: OrderConstant.GET_ORDER_FAILED,
      error,
    };
  }

  /**
   * Cancel Order
   * @param {*} input
   * @returns {(function(*): Promise<void>)|*}
   */
  cancelOrder(cancelOrderInfo, order) {
    return async (dispatch) => {
      dispatch({
        type: OrderConstant.CANCEL,
        cancelOrderInfo,
      });

      try {
        const response = await OrderService.cancelOrder(cancelOrderInfo);
        dispatch(this.cancelOrderSuccess(response.data.cancelOrder));
        dispatch(this.getOrder(order.id));
      } catch (error) {
        dispatch(this.cancelOrderFailed(error));
      }
    };
  }

  /**
   * Cancel Order Success
   * @param {*} order
   * @returns {{type: string, order}}
   */
  cancelOrderSuccess(order) {
    const messageSuccess = `${i18n.t('This order has been canceled.')}`;
    toast.success(
        messageSuccess,
      {
        position: toast.POSITION.TOP_CENTER,
        className: 'wrapper-messages messages-success',
      },
    );
    return {
      type: OrderConstant.CANCEL_ORDER_SUCCESS,
      order,
    };
  }

  /**
   * Cancel Order Failed
   * @param {*} error
   * @returns {{type: string, error}}
   */
  cancelOrderFailed(error) {
    const messageError = `${i18n.t('Cancel order failed!')}`;
    toast.error(
        messageError,
      {
        position: toast.POSITION.TOP_CENTER,
        className: 'wrapper-messages messages-error',
      },
    );
    return {
      type: OrderConstant.CANCEL_ORDER_FAILED,
      error,
    };
  }

  /**
   * Set Is Open Order Cancel Popup
   * @param {*} isOpenOrderCancelPopup
   * @returns {{type: string, isOpenOrderCancelPopup}}
   */
  setIsOpenOrderCancelPopup(isOpenOrderCancelPopup) {
    return {
      type: OrderConstant.SET_IS_OPEN_ORDER_CANCEL_POPUP,
      isOpenOrderCancelPopup,
    };
  }

  /**
   * Handle Reorder
   * @param {*} order // the order want to reorder
   * @returns {(function(*): Promise<void>)|*}
   */
  handleReorder(order) {
    return async (dispatch, getState) => {
      try {
        dispatch({
          type: OrderConstant.HANDLE_REORDER,
        });

        dispatch(QuoteAction.resetCannotAddProductList());

        const orderVariantQuantity = {};
        const customLineItems = [];
        order.lineItems.items.forEach((item) => {
          if (item && item.variant && item.variant.id) {
            const variantId = item.variant.id;
            if (orderVariantQuantity[variantId]) {
              orderVariantQuantity[variantId] += item.quantity;
            } else {
              orderVariantQuantity[variantId] = item.quantity;
            }
          } else if (QuoteItemService.isCustomSaleLineItem(item)) {
            customLineItems.push(item);
          } else {
            const invalidProduct = {
              cannotAddQty: item.quantity,
              data: {
                product: {
                  title: item.name,
                },
                variant: item.variant,
                quantity: item.quantity,
              },
            };
            dispatch(QuoteAction.addProductFail("Not found this product", invalidProduct));
          }
        });
        const variantIds = Object.keys(orderVariantQuantity);
        let productVariants = await ProductService.getProductVariants(variantIds);
        productVariants = productVariants.data.getProductVariantsByTheirIds;


        const beforeReorderQuote = cloneDeep(getState().core.checkout.quote);
        const isEmptyCurrentQuote = ((beforeReorderQuote.lineItems.length === 0) && !beforeReorderQuote.customer);
        const reorderQuote = isEmptyCurrentQuote
              ? {...QuoteService.initialQuoteReducerState, id: beforeReorderQuote.id}
              : {...QuoteService.initialQuoteReducerState, id: `tmp-quote-${new Date().getTime()}`};

        dispatch(QuoteAction.setQuote(reorderQuote));

        // handle add customer information to reorder quote
        if (order.customer && order.customer.id) {
          const reorderCustomerData = await customerService.getCustomer(order.customer.id);
          reorderQuote.customer = reorderCustomerData.data.getCustomer;
          dispatch(QuoteAction.setQuote(reorderQuote));
        }

        for (const productVariant of productVariants) {
          const variant = cloneDeep(productVariant);
          delete variant.product;
          const dataToAddProduct = {
            product: productVariant.product,
            variant,
            quantity: orderVariantQuantity[productVariant.id],
          };
          await dispatch(QuoteAction.addProduct(dataToAddProduct, false));
        }

        for (const customLineItem of customLineItems) {
          const dataToAddCustomSale = {
            name: customLineItem.name,
            quantity: customLineItem.quantity,
            price: String(customLineItem.originalUnitPriceSet?.shopMoney?.amount),
            taxable: Boolean(customLineItem.taxLines.length),
            note: QuoteItemService.getItemNote(customLineItem),
          };
          dispatch(QuoteAction.addCustomProduct(dataToAddCustomSale));
        }

        const {cannotAddProductList} = cloneDeep(getState().core.checkout.index);
        if (cannotAddProductList && cannotAddProductList.length > 0) {
          dispatch(this.setIsOpenReorderConfirmPopup(true));
        } else {
          dispatch(this.handleProcessReorder());
        }
      } catch (error) {
        dispatch(this.handleReorderFailed());
      }
    };
  }

  /**
   * Confirm Add Available Qty Reorder
   * @param {*} dataToAddProducts
   * @returns {(function(*): Promise<void>)|*}
   */
  confirmAddAvailableQtyReorder(dataToAddProducts) {
    return async (dispatch) => {
      try {
        for (const dataToAddProduct of dataToAddProducts) {
          await dispatch(QuoteAction.addProduct(dataToAddProduct));
        }
        dispatch(this.handleProcessReorder());
      } catch (error) {
        dispatch(this.handleReorderFailed());
      }
    };
  }

  /**
   * Handle Process Reorder
   * @param {*} dataToAddProducts
   * @returns {(function(*): Promise<void>)|*}
   */
  handleProcessReorder() {
    return async (dispatch, getState) => {
      try {
        const carts = await multiCartService.searchByCurrentPos();
        const activeCartId = cloneDeep(getState().core.multiCart.activeCartId);
        const activeCart = activeCartId ? carts.find((cart) => cart.id === activeCartId) : carts[carts.length - 1];

        const isEmptyActiveCart = (activeCart.lineItems.length === 0) && !activeCart.customer;

        const reorderQuote = cloneDeep(getState().core.checkout.quote);
        if (isEmptyActiveCart) {
          localStorageService.setActiveCartId(activeCartId);
          await dispatch(MultiCartAction.updateCart(reorderQuote));
        } else {
          await dispatch(MultiCartAction.addCartWithQuoteData(reorderQuote));
          const newActiveCartId = cloneDeep(getState().core.multiCart.activeCartId);
          localStorageService.setActiveCartId(newActiveCartId);
        }
        dispatch(this.handleReorderSuccess());
      } catch (error) {
        dispatch(this.handleReorderFailed());
      }

    };
  }

  /**
   * Set Is Loading Reorder
   * @param {*} isLoadingReorder
   * @returns {{type: string, isLoadingReorder}}
   */
  setIsLoadingReorder(isLoadingReorder) {
    return {
      type: OrderConstant.SET_IS_LOADING_REORDER,
      isLoadingReorder,
    };
  }

  /**
   * Handle Reorder Success
   * @param {*} result
   * @returns {{type: string, result}}
   */
  handleReorderSuccess(result) {
    return {
      type: OrderConstant.HANDLE_REORDER_SUCCESS,
      result,
    };
  }

  /**
   * Handle Reorder Failed
   * @param {*} error
   * @returns {{type: string, error}}
   */
  handleReorderFailed(error) {
    return {
      type: OrderConstant.HANDLE_REORDER_FAILED,
      error,
    };
  }

  /**
   * Set Is Open Reorder Confirm Popup
   * @param {*} isOpenReorderConfirmPopup
   * @returns {{type: string, isOpenReorderConfirmPopup}}
   */
  setIsOpenReorderConfirmPopup(isOpenReorderConfirmPopup) {
    return {
      type: OrderConstant.SET_IS_OPEN_REORDER_CONFIRM_POPUP,
      isOpenReorderConfirmPopup,
    };
  }

  /**
   * Set Is Redirect To Checkout Page
   * @param {*} isRedirectToCheckoutPage
   * @returns {{type: string, isRedirectToCheckoutPage}}
   */
  setIsRedirectToCheckoutPage(isRedirectToCheckoutPage) {
    return {
      type: OrderConstant.SET_IS_REDIRECT_TO_CHECK_OUT_PAGE,
      isRedirectToCheckoutPage,
    };
  }

  /**
   * Reset State
   * @returns {object}
   */
  resetState() {
    return {
      type: OrderConstant.RESET_STATE,
    };
  }

  /**
   * Reprint Order
   * @returns {{type: string}}
   */
  reprintOrder() {
    return {
      type: OrderConstant.REPRINT_ORDER,
    };
  }

  /**
   * Send Email
   * @param {*} sendEmailInfo
   * @returns  {(function(*): Promise<void>)|*}
   */
  sendEmail(sendEmailInfo, messageSuccess) {
    return async (dispatch) => {
      dispatch({
        type: OrderConstant.SEND_EMAIL,
        ...sendEmailInfo,
      });

      try {
        const response = await OrderService.sendEmail(sendEmailInfo);
        if (response && response.data && response.data.sendEmail && response.data.sendEmail.userErrors && response.data.sendEmail.userErrors.length) {
          const errorMessages = [];
          response.data.sendEmail.userErrors.forEach(
            (error) => {
              if (error.field === null) {
                errorMessages.push(error.message);
              }
            },
          );
          dispatch(this.sendEmailFailed(errorMessages.join(', ')));
        } else {
          dispatch(this.sendEmailSuccess(response.data.sendEmail, messageSuccess));
        }

      } catch (error) {
        dispatch(this.sendEmailFailed(error));
      }
    };
  }

  /**
   * Send Email Success
   * @param {*} sendEmailResponse
   * @returns {{type: string, result}}
   */
  sendEmailSuccess(result, message) {
    toast.success(
      i18n.t(`${message}`),
      {
        position: toast.POSITION.TOP_CENTER,
        className: 'wrapper-messages messages-success',
      },
    );
    return {
      type: OrderConstant.SEND_EMAIL_SUCCESS,
      result,
    };
  }

  /**
   * Set Is Loading Reprint Order
   * @returns {{type: string}}
   */
  setIsLoadingReprint(isReprint) {
    return {
      type: OrderConstant.SET_IS_LOADING_REPRINT,
      isReprint,
    };
  }

  /**
   * Reprint Order Success
   * @returns {{type: string}}
   */
  reprintOrderSuccess() {
    return {
      type: OrderConstant.REPRINT_ORDER_SUCCESS,
    };
  }

  /**
   * Reprint Order Failed
   * @returns {{type: string}}
   */
  reprintOrderFailed() {
    return {
      type: OrderConstant.REPRINT_ORDER_FAILED,
    };
  }

  /**
   * Send Email Failed
   * @param {*} error
   * @returns {{type: string, error}}
   */
  sendEmailFailed(error) {
    toast.error(
      i18n.t('Send email failed!'),
      {
        position: toast.POSITION.TOP_CENTER,
        className: 'wrapper-messages messages-error',
      },
    );
    return {
      type: OrderConstant.SEND_EMAIL_FAILED,
      error,
    };
  }


  /**
   * Set Is Open Send Email Popup
   * @param {*} isOpenSendEmailPopup
   * @returns {{type: string, isOpenSendEmailPopup}}
   */
  setIsOpenSendEmailPopup(isOpenSendEmailPopup) {
    return {
      type: OrderConstant.SET_IS_OPEN_SEND_EMAIL_POPUP,
      isOpenSendEmailPopup,
    };
  }


  /**
   * Set Is Open Fulfill Item Page
   * @param {*} isOpenFulfillItemPage
   * @returns {object}
   */
  setIsOpenFulfillItemPage(isOpenFulfillItemPage) {
    return {
      type: OrderConstant.SET_IS_OPEN_FULFILL_ITEM_PAGE,
      isOpenFulfillItemPage,
    };
  }

  /**
   * Get Fulfill Item Page
   * @returns {Promise}
   */
  getFulfillItemsPage() {
    return async (dispatch, getState) => {
      dispatch({
        type: OrderConstant.GET_FULFILL_ITEMS_PAGE,
      });

      try {
        const {order} = getState().core.order.orderDetail;

        const orderTemp = _.cloneDeep(order);

        const orderId = order?.id;

        const res = await OrderService.getFulfillmentOrder(orderId);

        const fulfillmentOrder = res.data.getFulfillmentOrder.fulfillment_orders;

        const lineItemTemp = OrderService.rebuildFulfillmentOrderLineItems(fulfillmentOrder);

        dispatch(this.getFulfillmentOrder(lineItemTemp));

        const newOrder = await OrderService.updateOrderAfterClickBtnFulfill(orderTemp, lineItemTemp);

        dispatch(this.getFulfillItemsPageSuccess(newOrder));

      } catch (error) {
        throw new Error(i18n.t(`Error Get Fulfill Items Page: {{error}}`, {error}));
      }
    };
  }


  /**
   * Get Fulfillment Order
   * @param {*} fulfillmentOrderLineItems
   * @returns {object}
   */
  getFulfillmentOrder(fulfillmentOrderLineItems) {
    return {
      type: OrderConstant.GET_FULFILLMENT_ORDER,
      fulfillmentOrderLineItems,
    };
  }


   /**
   * Get Fulfill ITems Page Success
   * @param {*} newOrder
   * @returns {object}
   */
  getFulfillItemsPageSuccess(newOrder) {
    return {
      type: OrderConstant.GET_FULFILL_ITEMS_PAGE_SUCCESS,
      newOrder,
    };
  }

  /**
   * Create Fulfillment Order
   * @returns {Promise}
   */
  createFulfillmentOrder(items) {
    return async (dispatch, getState) => {
      dispatch({
        type: OrderConstant.CREATE_FULFILLMENT_ORDER,
      });

      const {order, fulfillmentOrderLineItems} = getState().core.order.orderDetail;

      const isCurrentLocationFulfillOnlineOrder = LocationService.isCurrentLocationFulfillOnlineOrder();
      try {
        if (!isCurrentLocationFulfillOnlineOrder) {
          throw new Error(`This location is not allowed to fulfill online orders!`);
        }

        const input = await OrderService.buildInputCreateFulfillmentOrder(items, fulfillmentOrderLineItems);

        if (!input.length) {
          throw new Error('');
        }
        const response = await OrderService.createFulfillmentOrder(input);

        const isSuccess = response?.data?.createFulfillmentOrder?.success || '';

        if (!isSuccess) {
          throw new Error('');
        }

        const res = await OrderService.getOrder(order?.id);
        const newOrder = res?.data?.getOrder || {};
        const orderTemp = _.cloneDeep(newOrder);

        const status = _.cloneDeep(newOrder?.displayFulfillmentStatus);
        dispatch(this.createFulfillmentOrderSuccess(_.cloneDeep(orderTemp), status));

      } catch (error) {
        dispatch(this.createFulfillmentOrderFailed(error));
      }
    };
  }

  /**
   * Verify Can Fulfill All Items
   * @param {*} quote
   * @returns {(function(*): Promise<void>)|*}
   */
  verifyCanFulfillAllItems(quote) {
    return async (dispatch) => {
      dispatch({
        type: CheckoutConstant.VERIFY_CAN_FULFILL_ALL_ITEMS,
        quote,
      });
      try {
        const verifyData = await OrderService.verifyCanFulfillAllItems(quote);
        dispatch(this.verifyCanFulfillAllItemsSuccess(verifyData.isSuccess));
      } catch (error) {
        dispatch(this.verifyCanFulfillAllItemsFailed(error));
      }
    };
  }

  /**
   * Verify Can Fulfill All Items Success
   * @param {*} canFulfillAllItems
   * @returns {{type: string, canFulfillAllItems: Boolean}}
   */
  verifyCanFulfillAllItemsSuccess(canFulfillAllItems) {
    return {
      type: CheckoutConstant.VERIFY_CAN_FULFILL_ALL_ITEMS_SUCCESS,
      canFulfillAllItems,
    };
  }

  /**
   * Verify Can Fulfill All Items Failed
   * @param {*} error
   * @returns {{type: string, error}}
   */
  verifyCanFulfillAllItemsFailed(error) {
    return {
      type: CheckoutConstant.VERIFY_CAN_FULFILL_ALL_ITEMS_FAILED,
      error,
    };
  }

  /**
   * Add Shipping And Billing Address To Quote
   * @param {*} quote
   */
  addShippingAndBillingAddress(quote) {
    if (!quote.customer && !shippingService.isGuestDefaultShippingAddress(quote)) {
      quote.billingAddress = cloneDeep(quote.shippingAddress);
    } else if (quote.customer) {
      quote.billingAddress = quote.customer.defaultAddress ? cloneDeep(quote.customer.defaultAddress) : cloneDeep(quote.shippingAddress);
    }
  }

  /**
   * Complete Oder
   * if isComfirmedOrderCompleteError = true, will not verify insufficient quantity item
   *
   * @param {*} quote
   * @param {*} isFulfillAllItems
   * @param {*} listPaid
   * @param {*} totalPaid
   * @param {*} balance
   * @param {*} carts
   * @param {*} activeCartId
   * @param {*} isComfirmedOrderCompleteError
   * @returns {(function(*): Promise<void>)|*}
   */
  completeOrder(quote, isFulfillAllItems, listPaid, carts, activeCartId, isComfirmedOrderCompleteError = false) {
    return async (dispatch) => {
      dispatch({
        type: OrderConstant.COMPLETE_ORDER,
        quote,
        isProcessingOrder: true,
      });

      try {
        this.addShippingAndBillingAddress(quote);
        const quoteData = DraftOrderService.convertQuoteToDraftOrderInput(cloneDeep(quote));
        if (isFulfillAllItems && !isComfirmedOrderCompleteError) {
          const verifyData = await OrderService.verifyCanFulfillAllItems(quote);
          if (!verifyData.isSuccess) {
            dispatch(this.setErrorCompleteProductList(verifyData.errorCompleteProductList));
            dispatch(this.setIsProcessingOrder(false));
            return;
          }
        }
        OrderService.addCustomAttributeCreateOrder(quoteData);
        OrderService.removeCustomAttributeLineItem(quoteData, [QuoteConstant.TMP_ITEM_ID_CUSTOM_ATTRIBUTE_KEY]);

        const session = OrderService.buildInputSessionAddTransaction();

        const response = await OrderService.orderComplete({
          quote: quoteData,
          listPaid,
          isFulfillAllItems,
          originAddress: OrderService.getOriginAddressToFulfillOrder(),
          shop: staffService.getShopUrl(),
          locationId: LocationService.getTailCurrentLocationId(),
          session,
        });

        if (response && response.data && response.data.orderComplete && response.data.orderComplete.userErrors && response.data.orderComplete.userErrors.length > 0) {
          dispatch(this.completeOrderFailed(response.data.orderComplete.userErrors));
        } else {
          response.data.orderComplete.order = {
            ...quote,
            ...response.data.orderComplete.order,
            lineItems: {
              items: cloneDeep(quote.lineItems),
            },
            customAttributes: cloneDeep(quoteData.customAttributes),
          };
          dispatch(this.getOrderSuccess(response.data.orderComplete.order));
          dispatch(this.printReceiptAfterCreateOrder());

          // call function CheckoutPaymentAction.handleCompletedOrerPayment() to close all payment page, prepare to redirect to CheckoutCatalog page
          dispatch(CheckoutPaymentAction.handleCompletedOrerPayment());
          await dispatch(MultiCartAction.deleteCart(carts, {id: activeCartId}));
          await dispatch(MultiCartAction.addCart());
          dispatch(CheckoutAction.checkoutToCatalog());
          dispatch(this.completeOrderSuccess(response.data.orderComplete));
        }
      } catch (error) {
        dispatch(this.completeOrderFailed(error));
      }
    };
  }

  /**
   * Set Error Complete Product List (The Order Qty < On Hand Qty)
   * @param {*} errorCompleteProductList
   * @returns {{type: string, errorCompleteProductList, isOpenErrorCompletePopup}}
   */
  setErrorCompleteProductList(errorCompleteProductList) {
    return {
      type: OrderConstant.SET_ERROR_COMPLETE_PRODUCT_LIST,
      errorCompleteProductList,
      isOpenErrorCompletePopup: true,
    };
  }

    /**
     * Set Is Open Error Complete Popup
     * @param {*} isOpenErrorCompletePopup
     * @returns {{type: string, isOpenErrorCompletePopup}}
     */
  setIsOpenErrorCompletePopup(isOpenErrorCompletePopup) {
    return {
      type: OrderConstant.SET_IS_OPEN_ERROR_COMPLETE_POPUP,
      isOpenErrorCompletePopup,
    };
  }

  /**
   * Set Is Processing Order
   * @param {*} isProcessingOrder
   * @returns {{type: string, isProcessingOrder}}
   */
  setIsProcessingOrder(isProcessingOrder) {
    return {
      type: OrderConstant.SET_IS_PROCESSING_ORDER,
      isProcessingOrder,
    };
  }

  /**
   * Print Receipt After Create Order
   * @returns {{type: string}}
   */
  printReceiptAfterCreateOrder() {
    return {
      type: OrderConstant.PRINT_RECEIPT_AFTER_CREATE_ORDER,
    };
  }

  /**
   * Print Receipt After Create Order Success
   * @returns {{type: string}}
   */
  printReceiptAfterCreateOrderSuccess() {
    return {
      type: OrderConstant.PRINT_RECEIPT_AFTER_CREATE_ORDER_SUCCESS,
    };
  }

  /**
   * Print Receipt After Create Order Failed
   * @returns {{type: string}}
   */
  printReceiptAfterCreateOrderFailed() {
    return {
      type: OrderConstant.PRINT_RECEIPT_AFTER_CREATE_ORDER_FAILED,
    };
  }

  /**
   * Complete Order Success
   * @param {*} result
   * @returns {{type: string, result}}
   */
  completeOrderSuccess(result) {
    toast.success(
      i18n.t(`Order {{orderDisplay}} has been created successfully`, {
        orderDisplay: `${result.order?.name}`,
      }),
      {
        position: toast.POSITION.TOP_CENTER,
        className: 'wrapper-messages messages-success',
      },
    );
    return {
      type: OrderConstant.COMPLETE_ORDER_SUCCESS,
      result,
    };
  }

  /**
   * Complete Order Failed
   * @param {*} userErrors
   * @returns {{type: string, userErrors}}
   */
  completeOrderFailed(userErrors) {
    toast.error(
      i18n.t(`Order has been created failed`),
      {
        position: toast.POSITION.TOP_CENTER,
        className: 'wrapper-messages messages-success',
      },
    );
    return {
      type: OrderConstant.COMPLETE_ORDER_FAILED,
      userErrors,
    };
  }

  /**
   * Create fulfillment Success
   * @returns
   */
  createFulfillmentOrderSuccess(newOrder, statusOrder) {
    toast.success(
      i18n.t('Fulfillment created successfully.'),
      {
        className: 'wrapper-messages messages-success toast-fulfillment-confirm',
        closeOnClick: true,
        position: toast.POSITION.TOP_CENTER,
      },
    );
    return {
      type: OrderConstant.CREATE_FULFILLMENT_ORDER_SUCCESS,
      newOrder,
      statusOrder,
    };
  }

  /**
   * Create Fulfillment Order Failed
   * @returns {object}
   */
  createFulfillmentOrderFailed(error) {
    const message = error.message || i18n.t('Fulfillment created failed!');
    toast.error(
      message,
      {
        className: 'wrapper-messages messages-warning toast-fulfillment-confirm',
        closeOnClick: true,
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      },
    );
    return {
      type: OrderConstant.CREATE_FULFILLMENT_ORDER_FAILED,
    };
  }

  /**
   * Reset Data After Fulfill
   * @returns {object}
   */
  resetDataAfterFulfill() {
    return {
      type: OrderConstant.RESET_DATE_AFTER_FULFILL,
    };
  }
}

/**
 * @type {OrderActionClass}
 */
const OrderAction = ActionFactory.get(OrderActionClass);
export default OrderAction;
