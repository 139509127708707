import React from 'react';

import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import OrderHistory from '../../component/order/OrderHistory.jsx';

function OrderPage() {
  return (
    <OrderHistory />
  );
}

OrderPage.className = 'OrderPage';
export default FunctionComponentFactory.get(OrderPage);
