import {toast} from 'react-toastify';

import Config from '../../config/Config';
import i18n from '../../config/i18n';
import DenominationConstant from '../constant/DenominationConstant';
import DenominationService from '../../service/denomination/DenominationService';
import ActionFactory from '../../framework/factory/ActionFactory';

import LoadingAction from './LoadingAction';

export class DenominationActionClass {

  /**
   * Get Denominations
   * @param currencyCode
   * @param atLoadingPage
   * @returns {(function(*): Promise<void>)|*}
   */
  getDenominations(currencyCode, atLoadingPage = false) {
    if (!currencyCode) {
      return null;
    }
    return async (dispatch) => {
      dispatch({
        type: DenominationConstant.GET_DENOMINATIONS,
        currencyCode,
        atLoadingPage,
      });
      try {
        const result = await DenominationService.getDenominationsByCurrencyCode(currencyCode);
        if (result && result.data && result.data.getDenominationsByCurrencyCode) {
          const denominations = result.data.getDenominationsByCurrencyCode;
          await DenominationService.saveToLocalStorage(denominations);
          Config.denominations = denominations;
          if (atLoadingPage) {
            dispatch(LoadingAction.updateFinishedList(DenominationConstant.TYPE_GET_DENOMINATIONS));
          }
          dispatch(this.getDenominationsSuccess(denominations));
          return;
        }
        toast.error(
          i18n.t("Failed to get denominations data. Please contact technical support."),
        );
        dispatch(this.getDenominationsFailed(result));
      } catch (error) {
        toast.error(
          i18n.t("Failed to get denominations data. Please contact technical support."),
        );
        dispatch(this.getDenominationsFailed(error.message));
      }
    };
  }

  /**
   * Get Denominations Success
   * @param denominations
   * @returns {{denominations: *[], type: string}}
   */
  getDenominationsSuccess(denominations = []) {
    return {
      type: DenominationConstant.GET_DENOMINATIONS_SUCCESS,
      denominations,
    };
  }

  /**
   * Get Denominations Failed
   * @param error
   * @returns {{type: string, error}}
   */
  getDenominationsFailed(error) {
    return {
      type: DenominationConstant.GET_DENOMINATIONS_FAILED,
      error,
    };
  }
}

const DenominationAction = ActionFactory.get(DenominationActionClass);
export default DenominationAction;
