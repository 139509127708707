import ActionFactory from '../../../framework/factory/ActionFactory';
import CheckoutConstant from '../../constant/checkout/CheckoutConstant';

export class CheckoutActionClass {

  /**
   * Checkout To Select Payments
   * @param quote
   * @param initPayments
   * @returns {{quote, initPayments: boolean, type: string}}
   */
  checkoutToSelectPayments(quote, initPayments = false) {
    return {
      type: CheckoutConstant.CHECKOUT_TO_SELECT_PAYMENTS,
      quote,
      initPayments,
    };
  }

  /**
   *  whenever user click back on select payment, product list show
   * @return {{type: string, quote: *}}
   */
  checkoutToCatalog() {
    return (dispatch, getState) => {
      const quote = getState().core.checkout.quote;
      const QuoteAction = require('./QuoteAction').default;
      dispatch({
        type: CheckoutConstant.CHECKOUT_TO_CATALOG,
      });
      dispatch(QuoteAction.removeQuoteDiscountExceptCustomDiscount(quote));
    };
  }

  switchPage(page) {
    return {
      type: CheckoutConstant.CHECKOUT_SWITCH_PAGE,
      page,
    };
  }
}

/**
 * @type {CheckoutActionClass}
 */
const CheckoutAction = ActionFactory.get(CheckoutActionClass);
export default CheckoutAction;
