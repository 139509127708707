import MenuConstant from "../constant/MenuConstant";


/**
 *  init state for reducer
 *
 * @type {{active: *, items: *}}
 */
const initialState = {
  isOpen: false,
  needUpdateMenuItems: false,
};

/**
 * receive action from Menu Action
 *
 * @param state
 * @param action
 * @return {{active: *, items: *}}
 * @constructor
 */
export default function MenuReducer(state = initialState, action) {
  switch (action.type) {
    case MenuConstant.OPEN:
      return {...state, isOpen: true};
    case MenuConstant.CLOSE:
      return {...state, isOpen: false};
    case MenuConstant.SET_NEED_UPDATE_MENU_ITEMS: {
      return {...state, needUpdateMenuItems: action.needUpdateMenuItems};
    }
    default:
      return state;
  }
}
