import ActionFactory from "../../framework/factory/ActionFactory";
import StaffService from "../../service/staff/StaffService";
import StaffConstant from "../constant/StaffConstant";
import {SettingMessageError} from "../../config/MessageError";
import settingConstant from "../constant/SettingConstant";

export class SettingActionClass {

  /**
   * change password
   *
   * @param username
   * @param currentPassword
   * @param newPassword
   * @param confirmPassword
   * @param shop
   * @returns {(function(*): Promise<void>)|*}
   */
  changePassword(username, currentPassword, newPassword, confirmPassword, shop) {
    return async (dispatch) => {
      dispatch({
        type: StaffConstant.STAFF_CHANGE_PASSWORD,
      });
      try {
        if (currentPassword.length < 7 || newPassword.length < 7) {
          dispatch(this.changePasswordFailed(SettingMessageError.CHANGE_PASSWORD.PASSWORD_MUST_AT_LEAST_7_CHARACTERS));
          return;
        }

        if (newPassword !== confirmPassword) {
          dispatch(this.changePasswordFailed(SettingMessageError.CHANGE_PASSWORD.PASSWORD_CONFIRMATION_MUST_BE_SAME_NEW_PASSWORD));
          return;
        }

        if (!StaffService.validatePassword(newPassword)) {
          dispatch(this.changePasswordFailed(SettingMessageError.CHANGE_PASSWORD.PASSWORD_MUST_INCLUDE_NUMERIC_AND_ALPHABETIC));
          return;
        }

        const response = await StaffService.changePassword(username, currentPassword, newPassword, shop);
        if (response.data.changePasswordStaff.result) {
          dispatch(this.changePasswordSuccess());
        } else if (!response.data.changePasswordStaff.result && response.data.changePasswordStaff.error) {
          dispatch(this.changePasswordFailed(response.data.changePasswordStaff.error));
        }
      } catch (error) {
        dispatch(this.changePasswordFailed(error?.message));
      }
    };
  }

  /**
   * change password success
   *
   * @returns {{type: string}}
   */

  changePasswordSuccess() {
    return {
      type: StaffConstant.STAFF_CHANGE_PASSWORD_SUCCESS,
    };
  }

  /**
   * reset state after change status success
   *
   * @returns {{type: string}}
   */
  reset() {
    return {
      type: settingConstant.RESET_CHANGE_PASSWORD_STATE,
    };
  }

  /**
   * change password failed
   *
   * @returns {{type: string}}
   */
  changePasswordFailed(error) {
    return {
      type: StaffConstant.STAFF_CHANGE_PASSWORD_FAILED,
      error,
    };
  }
}

const SettingAction = ActionFactory.get(SettingActionClass);
export default SettingAction;
