import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {cloneDeep} from 'lodash';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import ProductList from '../catalog/ProductList.jsx';
import QuoteService from '../../../../service/checkout/QuoteService';
import CurrencyService from '../../../../service/CurrencyService';
import QuoteAction from '../../../action/checkout/QuoteAction';

function CartFooter() {
  const dispatch = useDispatch();

  const {quote} = useSelector((state) => state.core.checkout);
  const {currentPage} = useSelector((state) => state.core.checkout.index);
  const showOnPages = [ProductList.className];

  const canShow = showOnPages.includes(currentPage);

  const itemsQty = useMemo(() => {
    return QuoteService.getTotalItemsQty(quote);
  }, [quote]);

  const actionsClass = canShow ? 'actions' : 'hidden';
  const chargeButtonClass = useMemo(() => {
    if (!canShow) {
      return 'hidden';
    }
    return itemsQty ? "btn btn-default btn-total" : "btn btn-default btn-total disabled";
  }, [canShow, itemsQty]);

  const handleClickChargeButton = () => {
    if (!itemsQty) { return false; }
    dispatch(QuoteAction.checkoutQuote(cloneDeep((quote))));
  };

  return (
    <>
      <div className={actionsClass}>
        <button
          className={chargeButtonClass}
          type="button"
          onClick={handleClickChargeButton}
        >
          {CurrencyService.format(QuoteService.getSubtotal(quote))}
        </button>
      </div>
      <div className="loader-cart">
        <div className="loader-product" />
      </div>
    </>
  );
}

CartFooter.className = 'CartFooter';
CartFooter.propTypes = {};

export default FunctionComponentFactory.get(CartFooter);
