const customerActionPlugin = {
  saveCustomerPopupSuccess: {
    shopify: {
      sortOrder: 10,
      disabled: false,

      /* Add new tags to array tags config when add/update customer*/
      before(customer) {
        const ConfigService = require("../../../../service/config/ConfigService").default;
        const Config = require("../../../../config/Config").default;
        const underScore = require("lodash");
        const tags = customer.tags;
        const currentConfig = ConfigService.getConfigFromLocalStorage();
        currentConfig.customerAttributeFormMetadata = currentConfig.customerAttributeFormMetadata.map(
          (attribute) => {
            if (attribute.attributeCode === 'tags') {
              const currentValueArray = [];
              const newOptionsArray = [];
              attribute.options.forEach((item) => {
                currentValueArray.push(item.value);
              });
              const newOption = underScore.union(currentValueArray, tags);
              newOption.forEach(
                (value) => {
                  newOptionsArray.push(
                    {
                      value,
                      label: value,
                    },
                  );
                },
              );
              attribute.options = newOptionsArray;
            }
            return attribute;
          },
        );
        ConfigService.saveToLocalStorage(currentConfig);
        Config.config = currentConfig;
      },
    },
  },
};
export default customerActionPlugin;
