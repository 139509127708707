const productConstant = {
  GET_PRODUCTS: '[PRODUCT] GET_PRODUCTS',
  GET_PRODUCTS_SUCCESS: '[PRODUCT] GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILED: '[PRODUCT] GET_PRODUCTS_FAILED',

  SEARCH_BY_BARCODE: '[PRODUCT] SEARCH_BY_BARCODE',

  SET_TEXT_TO_SEARCH: '[PRODUCT] SET_TEXT_TO_SEARCH',

  VIEW_PRODUCT: '[PRODUCT] VIEW_PRODUCT',
  GET_PRODUCT_OPTIONS: '[PRODUCT] GET_PRODUCT_OPTIONS',
  GET_PRODUCT_OPTIONS_SUCCESS: '[PRODUCT] GET_PRODUCT_OPTIONS_SUCCESS',
  GET_PRODUCT_OPTIONS_FAILED: '[PRODUCT] GET_PRODUCT_OPTIONS_FAILED',
  GET_PRODUCT_VARIANT_BY_PRODUCT_OPTIONS: '[PRODUCT] GET_PRODUCT_VARIANT_BY_PRODUCT_OPTIONS',
  GET_PRODUCT_VARIANT_BY_PRODUCT_OPTIONS_SUCCESS: '[PRODUCT] GET_PRODUCT_VARIANT_BY_PRODUCT_OPTIONS_SUCCESS',
  GET_PRODUCT_VARIANT_BY_PRODUCT_OPTIONS_FAILED: '[PRODUCT] GET_PRODUCT_VARIANT_BY_PRODUCT_OPTIONS_FAILED',

  GET_PRODUCT_VARIANT_BY_IDS_VARIANT_SUCCESS: '[PRODUCT] GET_PRODUCT_VARIANT_BY_IDS_VARIANT_SUCCESS',
  VIEW_PRODUCT_ADD_COLLECTION_IDS: '[PRODUCT] VIEW_PRODUCT_ADD_COLLECTION_IDS',

  STATUS_ACTIVE: 'ACTIVE',
  STATUS_ARCHIVED: 'ARCHIVED',
  STATUS_DRAFT: 'DRAFT',

  PAGE_SIZE: 16,
  ACTION_FIRST_PAGE: 'FIRST',
  ACTION_NEXT_PAGE: 'NEXT',
  ACTION_PREVIOUS_PAGE: 'PREVIOUS',
};

export default productConstant;
