import _ from 'lodash';

import ShippingConstant from '../../constant/ShippingConstant';

const initialState = {
  isShowShippingPopup: false,
  addresses: [],
  defaultAddress: {},
  selectedAddress: {},
  isLoadingShippingPopup: false,
  currentCustomerId: null,
  shippingMethods: [],
  selectedShippingMethod: {},
};

/**
 * Receive actions and process
 * @param state
 * @param action
 * @returns {{}}
 */
const shippingPopupReducer = (state = initialState, action) => {
  let currentAddress = '';
  let defaultAddress = state.defaultAddress;
  switch (action.type) {
    case ShippingConstant.SHOW_SHIPPING_POPUP:
      return {
        ...state,
        isShowShippingPopup: true,
      };
    case ShippingConstant.HIDE_SHIPPING_POPUP:
      return {
        ...state,
        isShowShippingPopup: false,
        addresses: [],
        defaultAddress: {},
        selectedAddress: {},
        isLoadingShippingPopup: false,
        currentCustomerId: null,
        selectedShippingMethod: {},
        shippingMethods: [],
      };
    case ShippingConstant.GET_ADDRESS_LISTING:
      return {
        ...state,
        isLoadingShippingPopup: true,
        currentCustomerId: action.customerId,
      };
    case ShippingConstant.GET_ADDRESS_LISTING_SUCCESS:
      return {
        ...state,
        addresses: action.addresses,
        defaultAddress: action.defaultAddress,
        selectedAddress: action.defaultAddress,
        isLoadingShippingPopup: false,
      };
    case ShippingConstant.GET_ADDRESS_LISTING_ERROR:
      return {
        ...state,
        isLoadingShippingPopup: false,
      };
    case ShippingConstant.SELECT_ADDRESS:
      return {
        ...state,
        selectedAddress: action.address,
      };
    case ShippingConstant.SHOW_SHIPPING_ADDRESS_POPUP:
      return {
        ...state,
        isShowShippingPopup: false,
      };
    case ShippingConstant.HIDE_SHIPPING_ADDRESS_POPUP:
      return {
        ...state,
        isShowShippingPopup: true,
      };
    case ShippingConstant.SAVE_NEW_ADDRESS_AS_GUEST:
      if (state.addresses && state.addresses.length) {
        currentAddress = _.cloneDeep(state.addresses);
      } else {
        currentAddress = [];
      }
      currentAddress.push(action.address);
      if (action.defaultAddress) {
        defaultAddress = action.defaultAddress;
      }
      return {
        ...state,
        addresses: currentAddress,
        isShowShippingPopup: true,
        defaultAddress,
        selectedAddress: action.address,
      };
    case ShippingConstant.SAVE_NEW_ADDRESS_WITH_CUSTOMER_SUCCESS:
      return {
        ...state,
        addresses: action.addresses,
        isShowShippingPopup: true,
        defaultAddress: action.defaultAddress,
      };
    case ShippingConstant.SET_SHIPPING_METHOD_SUCCESS:
      return {
        ...state,
        shippingMethods: action.shippingMethods,
        isLoadingShippingPopup: false,
      };
    case ShippingConstant.SET_SELECTED_SHIPPING_METHOD:
      return {
        ...state,
        selectedShippingMethod: action.selectedShippingMethod,
      };

    default:
      return state;
  }
};

export default shippingPopupReducer;
