import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import SessionService from '../../../../service/session/SessionService';
import SessionAction from '../../../action/SessionAction';
import SessionConstant from '../../../constant/SessionConstant';
import ModalCash from '../modal-session/ModalCash.jsx';

const PutMoneyIn = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const title = t('Put Money In');
  const subTitle = t('Fill in this form if you put money in the cash-drawer');
  const titleAction = t('Put In');

  const handlePutMoneyIn = (amount, textNote) => {
    const currenSession = SessionService.getSessionInProcess();
    const params = {
      transaction_increment_id: `${localStorage.getItem('pos_name')}-${Date.now()}`,
      session_id: Number(currenSession?.id),
      location_id: localStorage.getItem('location_id'),
      value: Number(amount),
      note: textNote?.trim(),
      type: SessionConstant.TRANSACTION_PUT_IN,
      name_payment_method: SessionConstant.NAME_PAYMENT_METHOD_CASH,
    };

    dispatch(SessionAction.addTransaction(params));
  };

  return (
    <ModalCash
      title={title}
      titleAction={titleAction}
      subTitle={subTitle}
      handleAction={handlePutMoneyIn}
    />
  );
};

PutMoneyIn.className = 'PutMoneyInComponent';
export default FunctionComponentFactory.get(PutMoneyIn);
