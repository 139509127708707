import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import FunctionComponentFactory from '../../framework/factory/FunctionComponentFactory';
import LoginComponent from '../component/staff/login.jsx';
import ShopAction from "../action/ShopAction";
import LocalStorageService from '../../service/LocalStorageService';
import StaffService from '../../service/staff/StaffService';

export function LoginPageComponent() {
  const params = useParams();
  const shop = params.shop;
  const dispatch = useDispatch();
  const {shopPublicInfo} = useSelector((state) => { return state.core.shopReducer; });

  useEffect(() => {
    if (shop) {
      dispatch(ShopAction.getShopPublicInfo(shop));
    }
  }, [shop, dispatch]);

  const navigate = useNavigate();
  useEffect(() => {
    if (!(LocalStorageService.getToken() && !parseInt(StaffService.getSharingAccount(), 10))) {
      return;
    }
    navigate('/');
  }, [navigate]);
  return (
    <LoginComponent shop={shop} shopPublicInfo={shopPublicInfo} />
  );
}

LoginPageComponent.className = 'LoginPageComponent';
export default FunctionComponentFactory.get(LoginPageComponent);
