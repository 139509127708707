import React, {Fragment, useState} from "react";
import {cloneDeep} from "lodash";
import {Form} from "react-bootstrap";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";

import FunctionComponentFactory from "../../../../../framework/factory/FunctionComponentFactory";
import CustomDiscountConstant from "../../../../constant/checkout/quote/CustomDiscountConstant";
import CurrencyConstant from "../../../../constant/CurrencyConstant";
import CurrencyService from "../../../../../service/CurrencyService";
import i18n from "../../../../../config/i18n";
import NumPad from '../../../lib/react-numpad/index';
import OrderService from "../../../../../service/order/OrderService";
import OrderRefundAction from "../../../../action/order/refund/OrderRefundAction";

import ErrorModalComponent from "./ErrorModal.jsx";

function RefundAdjustments(props) {
  const dispatch = useDispatch();
  const {order} = props;
  const {adjustments, total} = useSelector((state) => state.core.order.orderRefund);
  const getAdjustmentAmount = (amount, precision = CurrencyConstant.DEFAULT_DISPLAY_PRECISION) => {
    return Number(amount).toFixed(precision);
  };

  const getAdjustmentType = (type) => {
    if (type === CustomDiscountConstant.SHOPIFY_DISCOUNT_TYPE_FIXED) {
      return CurrencyService.getCurrencySymbol();
    }
  };
  const listKey = Object.keys(adjustments);
  const maxShippingRefundAmount = order.totalShippingPriceSet.shopMoney.amount - order.totalRefundedShippingSet.shopMoney.amount;
  const maxRefundAmount = order.totalReceivedSet.shopMoney.amount - order.totalRefundedSet.shopMoney.amount;
  const handleChangeAdjustmentValue = (val, key) => {
    const adjustmentsClone = cloneDeep(adjustments);
    if (val.amount && key === 'Refund' && val.amount > maxRefundAmount) {
      val.amount = maxRefundAmount;
    }

    if (val.amount && key === 'Shipping' && val.amount > maxShippingRefundAmount) {
      val.amount = maxShippingRefundAmount;
    }
    adjustmentsClone[`${key}`] = {...adjustmentsClone[`${key}`], ...val};
    dispatch(OrderRefundAction.updateAdjustments(adjustmentsClone));
  };

  const [isOpenModal, setIsOpenModal] = useState(false);

  const hideModal = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <div className="block-content">
        <div className="scroll-content">
          <div className="block-refund-adjustments">
            <div className="box">
              <div className="box-title">{i18n.t('Refund Amount')}</div>
              <div className="box-content">
                {
                  listKey.map((key) => {
                    return (
                      <div className="form-group" key={adjustments[`${key}`].key}>
                        <Form.Label>
                          {i18n.t(`${adjustments[`${key}`].label}`)}
                          {key === 'Refund' &&
                            <>
                              <br />
                              <p>{i18n.t(`(include Shipping)`)}</p>
                            </>
                          }
                        </Form.Label>
                        <div
                          className="control"
                        >
                          <NumPad.CustomNumber
                            onChange={(val) => {
                              handleChangeAdjustmentValue({amount: val}, key);
                            }}
                            position="centerLeft"
                            sync={false}
                            arrow="left"
                            isShowAction
                            key={key}
                            value={adjustments[`${key}`].amount}
                          >
                            <span className="form-control">
                              {getAdjustmentAmount(adjustments[`${key}`].amount)}
                            </span>
                            <span className="label">{getAdjustmentType(adjustments[`${key}`].type)}</span>
                          </NumPad.CustomNumber>
                          <div className="t-alert">
                            <p>{
                              i18n.t('{{maxAmount}} available to refund', {maxAmount: key === 'Refund' ? OrderService.formatPrice(maxRefundAmount) : OrderService.formatPrice(maxShippingRefundAmount)})
                            }
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
          <div className="block-refund-totals">
            <>
              <div className="box-title">{i18n.t('Refund Totals')}</div>
              <div className="box-content">
                <ul>
                  <li key="subtotal">
                    <span className="label">{i18n.t('Subtotal')}</span>
                    <span className="value">{OrderService.formatPrice(total - adjustments.Shipping.amount)}</span>
                  </li>
                  <li key="Shipping">
                    <span className="label">{i18n.t(adjustments.Shipping.labelInTotalCart)}</span>
                    <span className="value">{OrderService.formatPrice(adjustments.Shipping.amount)}</span>
                  </li>
                  <li key="total" className="total">
                    <span className="label">{i18n.t('Grand Total')}</span>
                    <span className="value">{OrderService.formatPrice(total)}</span>
                  </li>
                </ul>
              </div>
            </>
          </div>
        </div>
      </div>
      <div className="block-bottom">
        <div className="actions-accept">
          <button
            className="btn btn-cancel" type="button"
            onClick={() => {
              dispatch(OrderRefundAction.handleBackFromAdjustment());
            }}
          >
            {i18n.t('Back')}
          </button>
          <button
            className="btn btn-default" type="button"
            onClick={() => {
              if (parseFloat(adjustments.Shipping.amount) > parseFloat(adjustments.Refund.amount)) {
                setIsOpenModal(true);
              } else {
                dispatch(OrderRefundAction.handleOpenCompleteRefund());
              }
            }}
          >
            {i18n.t('Next')}
          </button>
        </div>
      </div>
      {isOpenModal &&
        <ErrorModalComponent
          isOpenModal={isOpenModal}
          hide={hideModal}
          title="Error"
          modalContent="You must not fill in the Refund Shipping amount higher than Refund Total amount"
        />}
    </>
  );
}
RefundAdjustments.className = 'RefundAdjustments';
RefundAdjustments.propTypes = {
  order: PropTypes.object,
};
export default FunctionComponentFactory.get(RefundAdjustments);
