import ServiceFactory from '../framework/factory/ServiceFactory';
import CurrencyConstant from '../view/constant/CurrencyConstant';

export class NumberServiceClass {
  className = 'NumberServiceClass';

  /**
   * Count the number of decimal
   *
   * @param {number} number
   * @return {number}
   */
  countDecimal(number) {
    if ((parseFloat(number) % 1) !== 0) {
      if (number.toString().indexOf('e') !== -1) {
        return Math.abs(number.toString().split('e')[1]);
      }
      return number.toString().split(".")[1].length;
    }
    return 0;
  }

  /**
   * sum an array number
   *
   * @param {Array.<number>} numbers
   * @return {*|any}
   */
  addNumber(...numbers) {
    return numbers.reduce((aNumberP, bNumberP) => {
      let aNumber = aNumberP;
      let bNumber = bNumberP;
      if (!aNumber) {
        aNumber = 0;
      }
      if (!bNumber) {
        bNumber = 0;
      }
      const maxCountNumberDecimal = Math.max(this.countDecimal(aNumber), this.countDecimal(bNumber));
      return parseFloat((Number(aNumber) + Number(bNumber)).toFixed(Math.min(maxCountNumberDecimal, 20)));
    });
  }

  /**
   * Minus 2 numbers
   * @param {number} aNumberP
   * @param {number} bNumberP
   * @return {number}
   */
  minusNumber(aNumberP, bNumberP) {
    let aNumber = aNumberP;
    let bNumber = bNumberP;
    if (!aNumber) {
      aNumber = 0;
    }
    if (!bNumber) {
      bNumber = 0;
    }
    const maxCountNumberDecimal = Math.max(this.countDecimal(aNumber), this.countDecimal(bNumber));
    return parseFloat((Number(aNumber) - Number(bNumber)).toFixed(Math.min(maxCountNumberDecimal, 20)));
  }

  /**
   * Remainder 2 numbers
   * @param aNumber
   * @param bNumber
   * @returns {undefined|number}
   */
  remainderNumber(aNumber, bNumber) {
    if (!aNumber || !bNumber || bNumber === 0) {
      return undefined;
    }
    let temp = aNumber / bNumber;
    temp = temp.toFixed(5);
    return this.minusNumber(aNumber, this.multipleNumber(temp, bNumber));
  }

  /**
   * Multiple 2 numbers
   * @param {Array.<number>} numbers
   * @return {number}
   */
  multipleNumber(...numbers) {
    return numbers.reduce((aNumberP, bNumberP) => {
      let aNumber = aNumberP;
      let bNumber = bNumberP;
      if (!aNumber) {
        aNumber = 0;
      }
      if (!bNumber) {
        bNumber = 0;
      }
      const maxCountNumberDecimal = this.countDecimal(aNumber) + this.countDecimal(bNumber);
      return parseFloat((Number(aNumber) * Number(bNumber)).toFixed(Math.min(maxCountNumberDecimal, 20)));
    });
  }

  /**
   * convert number to price precision decimal
   * @param valueP
   * @param precision
   * @returns {string}
   */
  convertNumberToPriceHasPrecision(valueP, precision = CurrencyConstant.DEFAULT_DISPLAY_PRECISION) {
    let value = valueP;
    if (!value) {
      value = 0;
    }
    const multiple = 10 ** precision;
    value = parseFloat(value) / multiple;
    value = value.toFixed(Math.min(precision, 20));
    return value;
  }

  /**
   * Get Decimal Separator
   * @returns {string}
   */
  getDecimalSeparator() {
    let number = 1.1;
    number = number.toLocaleString().substring(1, 2);
    return number;
  }
}

/**
 * @type {NumberServiceClass}
 */
const NumberService = ServiceFactory.get(NumberServiceClass);
export default NumberService;
