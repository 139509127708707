const orderRefundConstant = {
  REFUND_STEP: {
    ITEMS: "Items",
    SUCCESS: "Success",
    ADJUSTMENTS: "Amount",
    COMPLETE: "COMPLETE",
  },
  RESTOCK_TYPE: {
    NO_RESTOCK: 'no_restock',
    CANCEL: 'cancel',
    RETURN: 'return',
  },
};

export default orderRefundConstant;
