import React, {Fragment, useEffect} from "react";
import {get} from 'lodash';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {Button} from "react-bootstrap";

import FunctionComponentFactory from "../../../../../framework/factory/FunctionComponentFactory";
import OrderPaymentAction from "../../../../action/order/payment/OrderPaymentAction";
import i18n from "../../../../../config/i18n";
import OrderService from "../../../../../service/order/OrderService";

import PaymentItem from "./PaymentItem.jsx";

function ListPayment(props) {
  const {listPayment, isAddPaymentMethod} = useSelector((state) => state.core.order.orderPayment);
  const {remaining, grandTotal, order} = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(OrderPaymentAction.getListPayment());
  }, [dispatch]);
  const label = remaining < grandTotal ? 'Remaining' : 'Total';

  const handleBackAction = () => {
    dispatch(OrderPaymentAction.handleOpenCompletePayment());
  };

  const listKey = Object.keys(listPayment);
  return (
    <>
      <div className="block-content block-content1" data-scrollbar>
        <ul className="payment-total">
          <li>
            <span className="label">{i18n.t(`${label}`)}</span>
            <span className="value">{OrderService.formatPrice(remaining)}</span>
          </li>
        </ul>
        <ul className="payment-list">
          {
            listKey.map((key) => {
              return <PaymentItem key={key} payment={get(listPayment, `${key}`)} paymentItemKey={key} />;
            })
          }
        </ul>
        {
          order.totalCapturableSet.shopMoney.amount > 0 &&
          <>
            <span className="capturable-message">
              {i18n.t('{{totalCapturableSet}} has been authorized by third-party provider.', {
                totalCapturableSet: OrderService.formatPrice(order.totalCapturableSet.shopMoney.amount),
              })}
            </span>
            <br />
            <span className="capturable-message">
              {i18n.t('Total to be paid: {{grandTotal}}', {grandTotal: OrderService.formatPrice(grandTotal)})}
            </span>
          </>
        }
      </div>
      {
        isAddPaymentMethod &&
        <div className="block-bottom">
          <div className="actions-accept">
            <Button className="btn btn-default btn-cancel" type="button" onClick={() => handleBackAction()}>
              {i18n.t("Back")}
            </Button>
          </div>
        </div>
      }
    </>
  );
}

ListPayment.className = 'ListPaymentOrder';
ListPayment.propTypes = {
  remaining: PropTypes.number,
  grandTotal: PropTypes.number,
  order: PropTypes.object,
};

export default FunctionComponentFactory.get(ListPayment);
