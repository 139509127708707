import PropTypes from "prop-types";
import React, {Fragment, useEffect} from "react";
import moment from "moment";
import JsBarcode from 'jsbarcode';
import {useSelector} from "react-redux";

import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import OrderService from "../../../service/order/OrderService";
import OrderConstant from "../../constant/OrderConstant";
import i18n from "../../../config/i18n";

import HeaderReceipt from "./HeaderReceipt.jsx";

function ToPrintRefundComponent(props) {
  const {orderData, storeInfo, adjustments} = props;
  const {listSelectedItems, listRefundPayment} = useSelector((state) => state.core.order.orderRefund);
  const listRefundItems = listSelectedItems.map((item) => {
    if (item.qtyRefund > 0) {
      return item;
    }

    return null;
  }).filter(Boolean);
  let totalRefund = 0;
  listSelectedItems.forEach((item) => {
    totalRefund += parseFloat(item.qtyRefund);
  });
  useEffect(() => {
    updateBarcode(orderData);
  }, [orderData]);
  function getTemplate() {
    return getTemplateReceipt();
  }


  /**
   *  get template receipt
   *
   * @param {object} orderData
   * @return template receipt
   */
  function getTemplateReceipt() {
    const printedAt = moment().format('LLL');
    const borderBottom = (
      <>
        <tr>
          <td colSpan="4">
            <hr />
          </td>
        </tr>
        <div className="spacer" />
      </>
    );


    const classNameRefunded = "reprint";
    const starLine = "*****";
    const colonDisplay = ":";
    const printType = 'Refund Receipt';
    return (
      <>
        <div className="block-printreceipt">
          <HeaderReceipt orderData={orderData} storeInfo={storeInfo} printType={printType} />
          <div className="spacer" />
          <table>
            <thead>
              <tr>
                <th className="t-name">{i18n.t('Item')}</th>
                <th className="t-qty text-right">{i18n.t('Qty')}</th>
                <th className="t-price text-right">{i18n.t('Price')}</th>
                <th className="t-total text-right">{i18n.t('Subtotal')}</th>
              </tr>
            </thead>
            <tbody>
              {getTemplateItems(listRefundItems, orderData)}
            </tbody>
          </table>
          <table>
            <tbody>
              {borderBottom}
              {getTemplateRefund()}
              {
              getTemplateTotal(
                i18n.t('Subtotal'),
                OrderService.formatPrice(parseFloat(adjustments.Refund.amount) - parseFloat(adjustments.Shipping.amount)),
                'refundSubtotal',
                false,
              )
            }
              {
              getTemplateTotal(
                i18n.t('Shipping'),
                OrderService.formatPrice(parseFloat(adjustments.Shipping.amount)),
                'Shipping',
                false,
              )
            }
              {
              getTemplateTotal(
                i18n.t('Grand Total Refunded'),
                OrderService.formatPrice(parseFloat(adjustments.Refund.amount)),
                'grandTotal',
                true,
              )
            }
              <div className="spacer" />
              {borderBottom}
              {
              listRefundPayment.map((payment) => {
                return getTemplateTotal(
                  i18n.t('Refund to {{title}}', {title: payment.title}),
                  OrderService.formatPrice(parseFloat(payment.amount)),
                  `refund${payment.code}`,
                  false,
                );
              })
            }
            </tbody>
          </table>
          <br />
          <div className="text-center">
            <p>{i18n.t('Thank you for your purchase!')}</p>
            <svg id="barcode" />
            <div className={classNameRefunded}>
              <span>{starLine}</span><strong>{i18n.t('REFUNDED')}</strong><span>{starLine}</span>
            </div>

            <div className="reprint">{i18n.t('Printed At')}{colonDisplay} {printedAt}</div>
          </div>

        </div>
      </>
    );
  }


  /**
   *
   * @param items
   * @param order
   * @returns {Array}
   */
  function getTemplateItems(items, order) {
    return (
      items?.map((item) => {
        return getTemplateItem(item, items, order);
      })
    );
  }

  /**
   *
   * @param item
   * @param items
   * @param order
   * @returns {*}
   */
  function getTemplateItem(item, items, order) {
    return getTemplateDetailItem(item, order);
  }

  /**
   * get template detail item
   * @param item
   * @param order
   * @return {*}
   */
  function getTemplateDetailItem(item) {
    const originalUnitPrice = item?.originalUnitPriceSet?.shopMoney?.amount;
    const totalTax = item.qtyRefund * (OrderService.calcTaxLineItem(item, item) / item.quantity);
    const totalDiscount = item.qtyRefund * item.totalDiscountSet.shopMoney.amount;
    const rowTotal = (item.qtyRefund * item.originalUnitPriceSet.shopMoney.amount) + totalTax - totalDiscount;
    const itemId = item.id;
    const name = item.name;
    const variantDisplay = item.variantTitle?.replaceAll(" /", ";");
    const classForName = "t-name";
    const classForVariantDisplay = "t-variant-name";

    const price = OrderService.formatPrice(originalUnitPrice);
    return (
      <Fragment key={`${itemId}itemWrap`}>
        <div className="spacer" />
        <Fragment key={itemId}>
          <tr>
            <td className={classForName}>{name}</td>
            <td className="t-qty text-right">{item.qtyRefund}</td>
            <td className="t-price text-right">{price}</td>
            <td className="t-total text-right">
              <div>{OrderService.formatPrice(rowTotal)}</div>
            </td>
          </tr>
          <tr>
            <td className={classForVariantDisplay}>{variantDisplay}</td>
          </tr>
        </Fragment>
        <div className="spacer" />
      </Fragment>
    );
  }

  function updateBarcode(order) {
    if (!order || !order.name || order.name.length === 0) {
      return;
    }

    JsBarcode("#barcode", order.name.substring(1), {
      font: 'Helvetica',
      fontSize: OrderConstant.BARCODE_FONT_SIZE,
      width: OrderConstant.BARCODE_WIDTH,
      height: OrderConstant.BARCODE_HEIGHT,
      displayValue: true,
    });
  }

  /**
   * get template refund from data order
   *
   * @return {template}
   */
  function getTemplateRefund() {
    return getTemplateTotal(i18n.t('Total items refunded'), totalRefund, "refundTotal");
  }

  /**
   * get template total
   *
   * @param title
   * @param display
   * @param key
   * @param isBold
   * @param hasPadding
   * @param paddingParent
   * @return {*}
   */
  function getTemplateTotal(title, display, key, isBold, hasPadding = false, paddingParent = false) {
    const classParent = paddingParent ? "t-name t-bundle" : "";
    const className = hasPadding ? "t-name t-bundle" : "t-name";
    const titleResult = isBold ? <b>{title}</b> : title;
    const displayResult = isBold ? <b>{display}</b> : display;
    return (
      <Fragment key={key}>
        <tr>
          <td className={classParent} colSpan="3">
            <div className={className}>{titleResult}</div>
          </td>
          <td className="text-right">{displayResult}</td>
        </tr>
      </Fragment>
    );
  }

  function template() {
    return (
      <div className="">
        {getTemplate()}
      </div>
    );
  }
  return (
    <>
      {template()}
    </>
  );
}

ToPrintRefundComponent.propTypes = {
  orderData: PropTypes.object,
  storeInfo: PropTypes.object,
  adjustments: PropTypes.object,
};


ToPrintRefundComponent.className = "ToPrintRefundComponent";
export default FunctionComponentFactory.get(ToPrintRefundComponent);
