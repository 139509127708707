import {cloneDeep} from "lodash";
import {toast} from "react-toastify";

import ActionFactory from "../../../../framework/factory/ActionFactory";
import CheckoutConstant from "../../../constant/checkout/CheckoutConstant";
import QuoteService from "../../../../service/checkout/QuoteService";
import i18n from "../../../../config/i18n";

export class ConflictOrderDiscountActionClass {

  /**
   * Open Conflict Custom Order Discount And Coupon
   * @param appliedCustomDiscountLineItems
   * @returns {{type: string, appliedCustomDiscountLineItems: *}}
   */
  openConflictCustomOrderDiscountAndCoupon() {
    return {
      type: CheckoutConstant.OPEN_CONFLICT_CUSTOM_ORDER_DISCOUNT_AND_COUPON_POPUP,
    };
  }

  /**
   * Close Conflict Custom Order Discount And Coupon
   * @returns {{type: string}}
   */
  closeConflictCustomOrderDiscountAndCoupon() {
    return {
      type: CheckoutConstant.CLOSE_CONFLICT_CUSTOM_ORDER_DISCOUNT_AND_COUPON_POPUP,
    };
  }

  /**
   * Handle Remove Custom Order Discount To Apply Coupon
   * @param quote
   * @returns {{type: string}}
   */
  handleRemoveCustomOrderDiscount(quote) {
    return async (dispatch) => {
      let quoteData = cloneDeep(quote);
      const quoteAction = require("../QuoteAction").default;
      const result = QuoteService.removeQuoteDiscountExceptCustomDiscount(quoteData);
      if (result && result.quote) {
        quoteData = result.quote;
        await dispatch(quoteAction.checkoutQuote(quoteData));
        dispatch(quoteAction.setShowConflictCouponSubmittedPopup(true));
      } else {
        toast.error(i18n.t("Can not remove custom order discount"));
      }
    };
  }
}

/**
 * @type {ConflictOrderDiscountActionClass}
 */
const ConflictOrderDiscountAction = ActionFactory.get(ConflictOrderDiscountActionClass);
export default ConflictOrderDiscountAction;
