const stockConstant = {
  SHOW_POPUP: '[STOCK] SHOW_POPUP',
  HIDE_POPUP: '[STOCK] HIDE_POPUP',
  SHOW_EXTERNAL_STOCK: '[STOCK] SHOW_EXTERNAL_STOCK',
  SHOW_EXTERNAL_STOCK_RESULT: '[STOCK] SHOW_EXTERNAL_STOCK_RESULT',
  SHOW_EXTERNAL_STOCK_PRODUCT_OPTIONS: '[STOCK] SHOW_EXTERNAL_STOCK_PRODUCT_OPTIONS',
  SHOW_EXTERNAL_STOCK_PRODUCT_OPTIONS_RESULT: '[STOCK] SHOW_EXTERNAL_STOCK_PRODUCT_OPTIONS_RESULT',
  SHOW_EXTERNAL_STOCK_BY_OPTIONS: '[STOCK] SHOW_EXTERNAL_STOCK_BY_OPTIONS',
};

export default stockConstant;
