import React from 'react';
import SmoothScrollbar from "smooth-scrollbar";
import {Button} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import FunctionComponentFactory
  from '../../../framework/factory/FunctionComponentFactory';
import '../../../assets/SharingAccount.css';
import '../../../assets/Location.css';
import StaffAction from '../../action/StaffAction';

export function SharingAccountPopup() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const handleLogout = () => {
    dispatch(StaffAction.logout());
  };
  const handleContinueLogin = () => {
    dispatch(StaffAction.continueLogin());
  };
  const setBlockContentElement = (element) => {
    if (element) {
      SmoothScrollbar.init(element);
    }
  };
  return (
    <div className="wrapper-switch-device">
      <div className="block-title" />
      <div className="block-content" data-scrollbar ref={setBlockContentElement}>
        <div className="img-switchdevice">
          <p>{t('The system has detected that your account is being used on another device.')}</p>
          <p>{t('Do you want to log out of that device and log in the current one?')}</p>
        </div>
      </div>
      <div className="block-bottom">
        <Button variant="cancel" type="button" onClick={() => handleLogout()}>
          {t('Cancel')}
        </Button>
        <Button variant="default" type="button" onClick={() => handleContinueLogin()}>
          {t('Continue To Login')}
        </Button>
      </div>
    </div>
  );
}
SharingAccountPopup.className = 'SharingAccountPopup';
SharingAccountPopup.propTypes = {};
export default FunctionComponentFactory.get(SharingAccountPopup);
