import React from 'react';
import PropTypes from 'prop-types';

import FunctionComponentFactory
    from '../../../framework/factory/FunctionComponentFactory';
import '../../../assets/Payment.css';
import '../../../assets/Menu.css';

function BackButton(props) {
  const {onClickBack} = props;

  const handleClickBack = () => {
    onClickBack();
  };

  return (
    <div className="fixed-wrapper-header">
      <div className="header-left">
        <span
          aria-hidden="true"
          onClick={handleClickBack}
          className="back-payment"
        />
      </div>
    </div>
  );
}

BackButton.className = 'BackButton';
BackButton.propTypes = {
  onClickBack: PropTypes.func.isRequired,
};

export default FunctionComponentFactory.get(BackButton);
