import ServiceFactory from "../../../framework/factory/ServiceFactory";
import {fire} from '../../../event-bus';

import AbstractQuoteService from "./AbstractService";
import QuoteTotalSubtotalService from './total/QuoteTotalSubtotalService';

export class QuoteTotalServiceClass extends AbstractQuoteService {
  static className = 'QuoteTotalServiceClass';

  isInitTotalCollectors = false;

  totalCollectors = [
    {
      name: "subtotalPrice",
      class: QuoteTotalSubtotalService,
      sort_order: 100,
    },
  ];

  /**
   * Init total collectors
   */
  initTotalCollectors() {
    if (this.isInitTotalCollectors) {
      return;
    }
    fire('service_quote_init_total_collectors', {service: this});
    this.isInitTotalCollectors = true;
  }

  /**
   * Collect total of quote
   *
   * @param quote
   * @return {object}
   */
  collectTotals(quote) {
    this.initTotalCollectors();
    this.resetQuoteData(quote);
    fire('service_quote_collect_totals_before', {quote});
    const totalFieldArray = [
      'subtotalPrice',
    ];

    const total = {};
    totalFieldArray.forEach((totalField) => { total[totalField] = 0; });
    this.totalCollectors.sort((aItem, bItem) => aItem.sort_order - bItem.sort_order);
    this.totalCollectors.map((collector) => collector.class.collect(quote, total));
    fire('service_quote_collect_totals_after', {quote});
    return total;
  }
}

/** @type QuoteTotalServiceClass */
const QuoteTotalService = ServiceFactory.get(QuoteTotalServiceClass);

export default QuoteTotalService;
