import AbstractResourceModel from "../AbstractResourceModel";

export default class ProductResourceModel extends AbstractResourceModel {
  static className = 'ProductResourceModel';

  resourceName = 'Product';

  /**
   * Get Products
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProducts(input) {
    return this.getResourceOnline().getProducts(input);
  }

  /**
   * Get product options
   * @param id
   * @param isIncludeCollection
   * @returns {*}
   */
  getProductOptions(id, isIncludeCollection = false) {
    return this.getResourceOnline().getProductOptions(id, isIncludeCollection);
  }

  /**
   * Get Product With One Variant
   *
   * @param id
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProductWithOneVariant(id) {
    return this.getResourceOnline().getProductWithOneVariant(id);
  }

  /**
   * Search By Barcode
   * @param query
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  searchByBarcode(query) {
    return this.getResourceOnline().searchByBarcode(query);
  }

  /**
   * get products by collection id
   *
   * @param questionId
   * @param productInput
   * @returns {Promise<unknown>|Promise<ApolloQueryResult<*>>}
   */
  getProductsByCollectionId(collectionId, productInput) {
    return this.getResourceOnline().getProductsByCollectionId(collectionId, productInput);
  }


  /**
   * Get Product Variant By Product Options
   * @param productId
   * @param selectedOptions
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProductVariantByProductOptions(productId, selectedOptions) {
    return this.getResourceOnline().getProductVariantByProductOptions(productId, selectedOptions);
  }

  /**
   * Get Product Variant By Their Ids
   * @param variantIds
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getProductVariants(variantIds) {
    return this.getResourceOnline().getProductVariants(variantIds);
  }

  /** Get Product Variant By Ids Variant
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getQuantityProductVariant(input) {
    return this.getResourceOnline().getQuantityProductVariant(input);
  }
}
