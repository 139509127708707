import AccountInformation from "../view/component/settings/settings-list/AccountInformation.jsx";

const SettingsListConfig = {

  /**
   * get items
   *
   * @return {*|*[]}
   */
  getItems() {
    return this.items;
  },

  /**
   * settings list items
   */
  items: [
    {
      id: "My Account",
      title: 'My Account',
      name: 'My Account',
      component: AccountInformation,
      className: "li-account",
    },
  ],
};
export default SettingsListConfig;
