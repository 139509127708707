import EditPriceConstant from "../../constant/checkout/EditPriceConstant";

const initialState = {
  customLineItems: {},
};

const editPriceReducer = (state = initialState, action) => {
  switch (action.type) {
    case EditPriceConstant.SAVE_CUSTOM_LINE_ITEM:
      return {
        ...state,
        customLineItems: {
          ...state.customLineItems,
          [action.customLineItemDefault.id]: action.customLineItemDefault,
        },
      };
    case EditPriceConstant.RESET_CUSTOM_LINE_ITEM:
      return {
        ...state,
        customLineItems: initialState.customLineItems,
      };
    default:
      return state;
  }
};

export default editPriceReducer;
