import AbstractResourceModel from "../AbstractResourceModel";

export default class CollectionResourceModel extends AbstractResourceModel {
  static className = 'CollectionResourceModel';

  resourceName = 'Collection';

  /**
   * Get Collections
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getCollections(input) {
    return this.getResourceOnline().getCollections(input);
  }
}

