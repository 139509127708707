import React, {useEffect, useRef, useState} from 'react';
import {t} from 'i18next';
import PropTypes from 'prop-types';

import SearchConstant from '../../../constant/SearchConstant';
import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';

const OrderSearch = (props) => {
  const {searchKey, changeSearchKey, setIsSearching, isSearching, blurSearchBox, cancelSearching} = props;
  const [searchBoxValue, setSearchBoxValue] = useState('');

  const searchBoxElement = useRef();

  const handleFocusSearch = () => {
    setIsSearching(true);
  };

  const handleCancelSearch = () => {
    setSearchBoxValue('');
    cancelSearching();
  };

  const clearSearchBox = () => {
    setSearchBoxValue('');
    searchBoxElement.current.focus();
  };

  const handleOnKeyUp = (event) => {
    if (event.key === SearchConstant.ENTER_KEY && searchKey !== searchBoxValue) {
      changeSearchKey(searchBoxValue);
    }
  };

  const handleOnChange = (event) => {
    setSearchBoxValue(event.target.value);
  };

  const handleBlurSearchBox = () => {
    if (searchBoxValue) {
      return;
    }
    if (searchKey) {
      cancelSearching();
    } else {
      blurSearchBox();
    }
  };

  useEffect(() => {
    const newSearchBoxValue = isSearching ? searchKey : '';
    setSearchBoxValue(newSearchBoxValue);

  }, [isSearching, searchKey]);

  const getRemoveButton = () => {
    if (!searchBoxValue) {
      return null;
    }
    return (
      <button
        className="btn-remove"
        type="button"
        onClick={clearSearchBox}
        aria-label={t("Remove Text Search Button")}
      />
    );
  };

  const getCannelButton = () => {
    if (!isSearching) {
      return null;
    }

    return (
      <button
        className="btn-cannel" type="button"
        onClick={handleCancelSearch}
        aria-label={t("Cannel Search Button")}
      >
        {t('Cancel')}
      </button>
    );
  };

  return (
    <div className="block-search">
      <div className="box-search">
        <button className="btn-search" type="button">
          <span>{t("search")}</span>
        </button>

        <input
          type="text" className="input-search form-control order-input-search"
          placeholder={t("Search by order, customer info ")}
          aria-label={t("radioOption1")}
          autoComplete="false"
          onKeyUp={handleOnKeyUp}
          value={searchBoxValue}
          onChange={handleOnChange}
          onFocus={handleFocusSearch}
          onBlur={handleBlurSearchBox}
          ref={searchBoxElement}
        />
        {getRemoveButton()}
      </div>

      {getCannelButton()}
    </div>
  );
};

OrderSearch.propTypes = {
  setIsSearching: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
  searchKey: PropTypes.string,
  changeSearchKey: PropTypes.func.isRequired,
  blurSearchBox: PropTypes.func.isRequired,
  cancelSearching: PropTypes.func.isRequired,
};

OrderSearch.className = "OrderSearch";
export default FunctionComponentFactory.get(OrderSearch);
