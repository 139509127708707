import StockConstant from "../../constant/StockConstant";

const initialState = {
  isShowExternalStock: false,
  product: '',
  externalStock: [],
  isLoading: false,
  productOptions: [],
  isLoadingOptions: false,
  preSelectOptions: null,
  canBack: false,
};

/**
 * External Stock reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */
const externalStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case StockConstant.SHOW_EXTERNAL_STOCK:
      return {
        ...state,
        isShowExternalStock: true,
        product: action.product,
        externalStock: [],
        productOptions: [],
        isLoading: true,
        preSelectOptions: action.preSelectOptions,
        canBack: action.canBack,
      };
    case StockConstant.SHOW_EXTERNAL_STOCK_RESULT:
      return {
        ...state,
        externalStock: action.result,
        isLoading: false,
      };
    case StockConstant.SHOW_EXTERNAL_STOCK_PRODUCT_OPTIONS:
      return {
        ...state,
        product: action.product,
        externalStock: [],
        productOptions: [],
        isLoading: false,
        isShowExternalStock: true,
        isLoadingOptions: true,
        preSelectOptions: action.preSelectOptions,
        canBack: action.canBack,
      };
    case StockConstant.SHOW_EXTERNAL_STOCK_BY_OPTIONS:
      return {
        ...state,
        isLoading: true,
      };
    case StockConstant.SHOW_EXTERNAL_STOCK_PRODUCT_OPTIONS_RESULT:
      return {
        ...state,
        productOptions: action.result,
        isLoadingOptions: false,
      };
    case StockConstant.SHOW_POPUP:
      return {
        ...state,
        isShowExternalStock: true,
      };
    case StockConstant.HIDE_POPUP:
      return {
        ...state,
        isShowExternalStock: false,
        preSelectOptions: null,
        productOptions: [],
        canBack: false,
        product: '',
      };
    default:
      return state;
  }
};

export default externalStockReducer;
