import Extension from "../framework/Extension";
import CheckoutPage from '../view/pages/checkout/index.jsx';
import OrderPage from '../view/pages/order/index.jsx';
import SessionManagementPage from '../view/pages/session/index.jsx';
import {
  CheckoutIcon,
  MenuOrderIcon,
  MenuSessionIcon,
  SettingIcon,
} from '../assets/images';
import PermissionConstant from '../view/constant/PermissionConstant';
import SettingsPage from "../view/pages/settings/index.jsx";

/**
 * Menu config
 * @type {{items: Array}} - list menu item
 * @type {{defaultItem(): (T|undefined|{id, title, path, component, className, sortOrder}|*), items: *[]}}
 */
const MenuConfig = {

  /**
   * get default active menu item, after loading complete
   * @return {T | undefined | {id, title, path, component, className, sortOrder} | *}
   */
  defaultItem() {
    return this.items.find((item) => {
      return item.id === 'checkout';
    }) || this.items[0];
  },

  /**
   *
   * @return {*|*[]}
   */
  getMenuItem() {

    /**
     * merge extension menu with core menu
     * @type {Array}
     */
    let menuFromExtension = Extension.ExtensionConfig.menu;
    menuFromExtension = menuFromExtension &&
        Object.keys(menuFromExtension).length ? Object.values(menuFromExtension) : [];
    const unsortedMenu = MenuConfig.items.concat(menuFromExtension);

    unsortedMenu.forEach((item) => {
      if (item.component.name === '') {
        item.component = item.component();
      }
    });

    /**
     *  sort menu by sortOrder property value
     *  @param object, object
     *  @return Array
     * */
    return unsortedMenu.sort((item1, item2) => {
      return item1.sortOrder - item2.sortOrder;
    });
  },

  /**
   * menu items
   */
  items: [
    {
      id: "checkout",
      title: "Checkout",
      path: "/checkout",
      component: CheckoutPage,
      className: "checkout",
      icon: CheckoutIcon,
      sortOrder: 10,
    },
    {
      id: "order",
      title: "Order History",
      path: "/order",
      component: OrderPage,
      className: "item-order",
      icon: MenuOrderIcon,
      sortOrder: 20,
      acl: PermissionConstant.PERMISSION_ORDER_CREATE_FROM_STAFF_LOCATION,
    },
    {
      id: "session",
      title: "Session Management",
      path: "/session",
      component: SessionManagementPage,
      className: "item-session",
      icon: MenuSessionIcon,
      sortOrder: 30,
    },
    {
      id: "settings",
      title: "Settings",
      path: "/settings",
      component: SettingsPage,
      className: "item-settings",
      icon: SettingIcon,
      sortOrder: 100,
    },
  ],
};

export default MenuConfig;
