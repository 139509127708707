import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from './GraphqlAbstract';

export class GraphqlOrderClass extends GraphqlAbstract {
  static className = 'GraphqlOrderClass';

  getOrders(input) {
    return this.queryWithGQLBuilder(
    'getOrders',
      {
        input: {
          name: 'input',
          type: 'GetOrdersInput',
          value: input,
          required: true,
        },
      },
      [
        {
          items: [
            'id',
            'createdAt',
            'displayFulfillmentStatus',
            'displayFinancialStatus',
            {
              totalPriceSet: [
                {
                  presentmentMoney: ['amount', 'currencyCode'],
                },
                {
                  shopMoney: ['amount', 'currencyCode'],
                },
              ],
            },
            {
              totalReceivedSet: [
                {
                  presentmentMoney: ['amount', 'currencyCode'],
                },
                {
                  shopMoney: ['amount', 'currencyCode'],
                },
              ],
            },
            'name',
          ],
        },
        {
          pageInfo: [
            'hasNextPage',
            'endCursor',
            'hasPreviousPage',
            'startCursor',
          ],
        },
      ],
      {
        operationName: 'getOrders',
      },
    );
  }


  getOrder(id) {
    return this.queryWithGQLBuilder(
    'getOrder',
      {
        id: {
          name: 'id',
          type: 'String!',
          value: id,
        },
      },
      [
        'id',
        'name',
        'createdAt',
        'closedAt',
        'cancelledAt',
        'taxesIncluded',
        {
          refunds: [
            "id",
          ],
        },
        'requiresShipping',
        {
          customAttributes: [
            'key',
            'value',
          ],
        },
        'refundable',
        {
          customer: [
            'id',
            'displayName',
            'phone',
            'email',
          ],
        },
        'displayFulfillmentStatus',
        'displayFinancialStatus',
        {
          fulfillments: [
            'totalQuantity',
          ],
        },
        {
          originalTotalPriceSet: [
            {
              shopMoney: [
                'amount',
                'currencyCode',
              ],
            },
          ],
        },
        {
          totalCapturableSet: [
            {
              shopMoney: [
                'amount',
                'currencyCode',
              ],
            },
          ],
        },
        {
          totalDiscountsSet: [
            {
              shopMoney: [
                'amount',
                'currencyCode',
              ],
            },
          ],
        },
        {
          totalPriceSet: [
            {
              shopMoney: [
                'amount',
                'currencyCode',
              ],
            },
          ],
        },
        {
          totalTaxSet: [
            {
              shopMoney: [
                'amount',
                'currencyCode',
              ],
            },
          ],
        },
        {
          totalShippingPriceSet: [
            {
              shopMoney: [
                'amount',
                'currencyCode',
              ],
            },
          ],
        },
        {
          totalReceivedSet: [
            {
              shopMoney: [
                'amount',
                'currencyCode',
              ],
            },
          ],
        },
        {
          totalRefundedSet: [
            {
              shopMoney: [
                'amount',
                'currencyCode',
              ],
            },
          ],
        },
        {
          totalRefundedShippingSet: [
            {
              shopMoney: [
                'amount',
                'currencyCode',
              ],
            },
          ],
        },
        {
          subtotalPriceSet: [
            {
              shopMoney: [
                'amount',
                'currencyCode',
              ],
            },
          ],
        },
        {
          lineItems: [
            {
              items: [
                'id',
                'name',
                'quantity',
                'unfulfilledQuantity',
                'refundedQuantity',
                'sku',
                {
                  customAttributes: [
                    'key',
                    'value',
                  ],
                },
                {
                  variant: [
                    'id',
                  ],
                },
                {
                  originalUnitPriceSet: [
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                {
                  discountedTotalSet: [
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                {
                  discountedUnitPriceSet: [
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                {
                  originalTotalSet: [
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                {
                  totalDiscountSet: [
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                {
                  taxLines: [
                    {
                      priceSet: [
                        {
                          shopMoney: [
                            "amount",
                            "currencyCode",
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  discountAllocations: [
                    {
                      allocatedAmountSet: [
                        {
                          presentmentMoney: [
                            'amount',
                            'currencyCode',
                          ],
                        },
                        {
                          shopMoney: [
                            'amount',
                            'currencyCode',
                          ],
                        },
                      ],
                    },
                    {
                      discountApplication: [
                        'allocationMethod',
                        'index',
                        'targetSelection',
                        'targetType',
                        {
                          value: [
                            {
                              '... on Money': [
                                'amount',
                                'currencyCode',
                              ],
                            },
                            {
                              '... on PricingPercentageValue': [
                                'percentage',
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                'variantTitle',
                {
                  product: [
                    "id",
                    "title",
                    'isGiftCard',
                  ],
                },
                {
                  variant: ['id'],
                },
              ],
            },
            {
              pageInfo: [
                "hasNextPage",
                "endCursor",
              ],
            },
          ],
        },
        'unpaid',
        {
          shippingLine: [
            "code",
            "title",
            {
              discountedPriceSet: [
                {
                  shopMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
              ],
            },
          ],
        },
        {
          shippingAddress: [
            "firstName",
            "lastName",
            "address1",
            "address2",
            "province",
            "city",
            "zip",
            "country",
          ],
        },
        {
          billingAddress: [
            "firstName",
            "lastName",
            "address1",
            "address2",
            "province",
            "city",
            "zip",
            "country",
          ],
        },
        {
          transactions: [
            "id",
            "gateway",
            "formattedGateway",
            {
              amountSet: [
                {
                  shopMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
              ],
            },
            "kind",
            "status",
            "createdAt",
          ],
        },
      ],

      {
        operationName: 'getOrder',
      },
    );
  }

  /**
   * Send Email
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  sendEmail(variables = {}) {
    return this.mutationWithGQLBuilder(
        'sendEmail',
      {
        input: {
          name: 'input',
          type: 'OrderInvoiceSendInput',
          value: variables,
        },
      },
      [
        {
          order: [
            "id",
          ],
        },
        {
          userErrors: [
            'field',
            'message',
          ],
        },
      ],
      {
        operationName: 'sendEmail',
      },
      );
  }

  /**
   * Cancel Order
   * @param {*} cancelOrderInfo
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  cancelOrder(cancelOrderInfo) {
    return this.mutationWithGQLBuilder(
      'cancelOrder',
      {
        input: {
          name: 'input',
          type: 'GetCancelOrderInput!',
          value: cancelOrderInfo,
        },
      },
      [
        {
          order: [
            "id",
            "cancelled_at",
            "checkout_id",
            "name",
            "cancel_reason",
          ],
        },
        "notice",
      ],
      {
        operationName: 'cancelOrder',
      },
    );
  }

  /**
   * change password
   *
   * @param listPaid
   * @param order
   * @param shop
   * @param locationId
   * @return Promise<ApolloQueryResult<*>>
   */
  createRecordPayment(listPaid, order, shop, session) {
    const input = {
      listPaid,
      order,
      shop,
      session,
    };

    return this.mutationWithGQLBuilder(
      'createRecordPayment',
      {
        input: {
          name: 'input',
          type: 'CreateRecordPaymentInput!',
          value: input,
        },
      },
      ['result', 'error'],
      {
        operationName: 'createRecordPayment',
      },
    );
  }

  /**
   * Complete Order
   *
   * @param input
   * @return {Promise<ApolloQueryResult<*>>}
   */
  orderComplete(input) {
    return this.mutationWithGQLBuilder(
      "orderComplete",
      {
        input: {
          name: 'input',
          type: 'OrderCompleteInput!',
          value: input,
        },
      },
      [
        {
          order: [
            'id',
            'name',
            'createdAt',
            'requiresShipping',
            'displayFulfillmentStatus',
            'displayFinancialStatus',
            'unpaid',
            {
              shippingAddress: [
                "firstName",
                "lastName",
                "address1",
                "address2",
                "province",
                "city",
                "zip",
                "country",
              ],
            },
            {
              billingAddress: [
                "firstName",
                "lastName",
                "address1",
                "address2",
                "province",
                "city",
                "zip",
                "country",
              ],
            },
            {
              totalReceivedSet: [
                {
                  shopMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
              ],
            },
            {
              transactions: [
                "id",
                "gateway",
                "formattedGateway",
                {
                  amountSet: [
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                "kind",
                "createdAt",
                "status",
              ],
            },
          ],
        },
        {
          userErrors: [
            'field',
            'message',
          ],
        },
      ],
      {
        operationName: 'orderComplete',
      },
    );
  }

  /**
   * Get Fulfillment Order
   * @param {*} orderId
   * @returns Promise<ApolloQueryResult<*>>
   */
  getFulfillmentOrder(orderId) {
    return this.queryWithGQLBuilder(
      'getFulfillmentOrder',
      {
        orderId: {
          name: 'orderId',
          type: 'String',
          value: orderId,
        },
      },
      [
        {
          fulfillment_orders:
          [
            'id',
            'order_id',
            'assigned_location_id',
            'status',
            'supported_actions',
            {
              line_items: [
                'id',
                'shop_id',
                'fulfillment_order_id',
                'quantity',
                'line_item_id',
                'inventory_item_id',
                'fulfillable_quantity',
                'variant_id',
              ],
            },
          ],
        },
      ],
      {
        operationName: 'getFulfillmentOrder',
      },
    );
  }

  /**
   * Crate fulfillment order
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  createFulfillmentOrder(input) {
    return this.mutationWithGQLBuilder(
      'createFulfillmentOrder',
      {
        input: {
          name: 'input',
          type: '[FulfillmentV2Input!]',
          value: input,
        },
      },
      [
        'success',
        'error',
      ],
      {
        operationName: 'createFulfillmentOrder',
      },
    );
  }

  /**
   * Fulfillment Order Move
   * @param {*} input
   * @returns {Promise}
   */
  fulfillmentOrderMove(input) {
    return this.mutationWithGQLBuilder(
      'fulfillmentOrderMove',
      {
        input: {
          name: 'input',
          type: '[FulfillmentOrderMoveInput]',
          value: input,
        },
      },
      [
        'success',
        'error',
        'idFulfillOrder',
      ],
      {
        operationName: 'fulfillmentOrderMove',
      },

    );
  }

  /**
   * create record refund
   * @param refundLineItems
   * @param transactions
   * @param shipping
   * @param note
   * @param order
   * @return {Promise}
   */
  createRecordRefund(refundLineItems, transactions, shipping, note, order, shop, session) {
    const input = {
      refundLineItems,
      transactions,
      shipping,
      note,
      order,
      shop,
      session,
    };
    return this.mutationWithGQLBuilder(
      'createRecordRefund',
      {
        input: {
          name: 'input',
          type: 'CreateRecordRefundInput!',
          value: input,
        },
      },
      ['result', 'error'],
      {
        operationName: 'createRecordRefund',
      },
    );
  }

  /**
   *
   * update customer for order when refund
   * @param order
   * @param customer
   * @param shop
   */
  updateOrderCustomer(order, customer, shop) {
    const input = {
      order,
      customer,
      shop,
    };
    return this.mutationWithGQLBuilder(
      'updateCustomerOrder',
      {
        input: {
          name: 'input',
          type: 'UpdateCustomerOrderInput!',
          value: input,
        },
      },
      ['result', 'error'],
      {
        operationName: 'updateCustomerOrder',
      },
    );
  }
}

export default DataResourceFactory.get(GraphqlOrderClass);
