import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import PermissionService from '../permission/PermissionService';

/**
 * Staff Service Class
 */
export class MenuServiceClass extends CoreService {
  static className = 'MenuServiceClass';

  /**
   * Is Item Allowed
   * @param item
   * @returns {boolean}
   */
  isItemAllowed(item) {
    let isAllowed = true;
    if ((item.acl && !PermissionService.isAllowed(item.acl)) ||
      (item.component && item.component.acl && !PermissionService.isAllowed(item.component.acl))
    ) {
      isAllowed = false;
    }
    return isAllowed;
  }
}

/**
 * @type {MenuServiceClass}
 */
const MenuService = ServiceFactory.get(MenuServiceClass);

export default MenuService;
