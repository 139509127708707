import {combineReducers} from 'redux';

import index from './checkout/index';
import quote from './checkout/QuoteReducer';
import editPrice from './checkout/EditPriceReducer';
import PaymentReducer from "./checkout/PaymentReducer";
import fulfill from "./checkout/FulfillReducer";
import conflictDiscount from "./checkout/ConflictDiscount";

export default combineReducers({
  index,
  quote,
  payment: PaymentReducer,
  editPrice,
  fulfill,
  conflictDiscount,
});
