import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';

function CustomSaleCheckboxComponent(props) {
  function onChange(event) {
    const value = event.target.checked ? 1 : 0;
    if (props.inputFieldOnChange) {
      props.inputFieldOnChange(props.Code, value);
    }
  }

  const noSpan = 'no';
  function template() {
    const {Label, OneRow, Disabled} = props;
    const colClassName = OneRow ? "col-sm-12" : "col-sm-6";
    return (
      <div className={colClassName}>
        <Form.Label className="pull-left"> {Label} </Form.Label>
        <div className="checkbox pull-right">
          <Form.Label>
            <input
              type="checkbox"
              checked={props.value}
              disabled={Disabled}
              onChange={(event) => onChange(event)}
            />
            <span><span>{noSpan}</span></span>
          </Form.Label>
        </div>
      </div>
    );
  }

  return template();
}

CustomSaleCheckboxComponent.className = 'CustomSaleCheckboxComponent';
CustomSaleCheckboxComponent.propTypes = {
  Label: PropTypes.string,
  Options: PropTypes.object,
  OneRow: PropTypes.bool,
  Code: PropTypes.string,
  Disabled: PropTypes.bool,
  inputFieldOnChange: PropTypes.func,
  value: PropTypes.number,
};

export default React.memo(FunctionComponentFactory.get(CustomSaleCheckboxComponent));
