import CustomerConstant from '../../constant/CustomerConstant';
import AddressConstant from '../../constant/AddressConstant';

const initialState = {
  isShowCustomerPopup: false,
  currentCustomer: {},
  userErrors: [],
  isLoading: false,
  isSaveSuccess: false,
  isLoadingCustomerDetail: false,
};

/**
 * Receive action from Customer Action
 *
 * @param state = {customers: []}
 * @param action
 * @returns {*}
 */
const customerPopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case CustomerConstant.SHOW_EDIT_CUSTOMER_POPUP:
      return {
        ...state,
        currentCustomer: action.customer,
        isShowCustomerPopup: true,
        isSaveSuccess: false,
        isLoadingCustomerDetail: true,
        userErrors: [],
      };
    case CustomerConstant.SHOW_EDIT_CUSTOMER_POPUP_SUCCESS:
      return {
        ...state,
        currentCustomer: action.customer,
        isShowCustomerPopup: true,
        isLoadingCustomerDetail: false,
      };
    case CustomerConstant.SHOW_EDIT_CUSTOMER_POPUP_FAIL:
      return {
        ...state,
        error: action.error,
        isShowCustomerPopup: true,
        isLoadingCustomerDetail: false,
      };
    case CustomerConstant.SHOW_CREATE_CUSTOMER_POPUP:
      return {
        ...state,
        isShowCustomerPopup: true,
        currentCustomer: {},
        isSaveSuccess: false,
        userErrors: [],
        updateForOrder: action.updateForOrder,
      };
    case CustomerConstant.HIDE_CREATE_CUSTOMER_POPUP:
      return {
        ...state,
        isShowCustomerPopup: false,
      };
    case CustomerConstant.SAVE_CUSTOMER_POPUP:
      return {
        ...state,
        isShowCustomerPopup: true,
        userErrors: [],
        isLoading: true,
      };
    case CustomerConstant.SAVE_CUSTOMER_POPUP_SUCCESS:
      return {
        ...state,
        isShowCustomerPopup: false,
        isLoading: false,
        isSaveSuccess: true,
      };
    case CustomerConstant.SAVE_CUSTOMER_POPUP_FAIL:
      return {
        ...state,
        isShowCustomerPopup: true,
        isLoading: false,
      };
    case CustomerConstant.SAVE_CUSTOMER_POPUP_VALIDATE_FAIL:
      return {
        ...state,
        isShowCustomerPopup: true,
        userErrors: action.userErrors,
        isLoading: false,
      };
    case AddressConstant.SHOW_ADD_ADDRESS_POPUP:
      return {
        ...state,
        isShowCustomerPopup: false,
      };
    case AddressConstant.SHOW_EDIT_ADDRESS_POPUP:
      return {
        ...state,
        isShowCustomerPopup: false,
      };
    case AddressConstant.HIDE_ADD_ADDRESS_POPUP:
      return {
        ...state,
        isShowCustomerPopup: true,
      };
    case AddressConstant.SAVE_ADDRESS_POPUP_SUCCESS:
      return {
        ...state,
        isShowCustomerPopup: true,
      };
    case CustomerConstant.UPDATE_CURRENT_CUSTOMER_IN_POPUP:
      return {
        ...state,
        currentCustomer: action.currentCustomer,
      };
    default:
      return state;
  }
};

export default customerPopupReducer;
