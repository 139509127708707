import ProductConstant from '../../constant/ProductConstant';

const initialState = {
  product: null,
  productOptions: null,
  isLoading: false,
  variant: null,
};

/**
 * Receive action from Product Action
 *
 * @param state = {products: []}
 * @param action
 * @returns {*}
 */
const viewProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProductConstant.VIEW_PRODUCT: {
      const {product} = action;
      return {
        ...state,
        product,
        productOptions: null,
        variant: null,
      };
    }
    case ProductConstant.GET_PRODUCT_OPTIONS:
      return {...state, isLoading: true};
    case ProductConstant.VIEW_PRODUCT_ADD_COLLECTION_IDS:
      if (state.product) {
        return {
          ...state,
          product: {
            ...state.product,
            collectionIds: action.collectionIds,
          },
        };
      } else {
        return state;
      }
    case ProductConstant.GET_PRODUCT_OPTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        productOptions: action.productOptions,
      };
    }
    case ProductConstant.GET_PRODUCT_OPTIONS_FAILED:
      return {...state, isLoading: false};
    case ProductConstant.GET_PRODUCT_VARIANT_BY_PRODUCT_OPTIONS:
      return {...state, isLoading: true};
    case ProductConstant.GET_PRODUCT_VARIANT_BY_PRODUCT_OPTIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        variant: action.variant,
      };
    }
    case ProductConstant.GET_PRODUCT_VARIANT_BY_PRODUCT_OPTIONS_FAILED:
      return {...state, isLoading: false};
    default:
      return state;
  }
};

export default viewProductReducer;
