import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Form} from "react-bootstrap";

import FunctionComponentFactory from "../../../../../framework/factory/FunctionComponentFactory";
import OrderService from "../../../../../service/order/OrderService";
import i18n from "../../../../../config/i18n";
import NumPad from '../../../lib/react-numpad/index';
import PaymentConstant from "../../../../constant/PaymentConstant";

function AddPaymentRefund(props) {
  const {
    remaining,
    listRefundPayment,
    handleListRefundPayment,
  } = props;
  const getIconImage = (payment) => {
    if (payment.icon_path) {
      return (
        <span className="img">
          <img alt={payment.code} className="img payment-offline-icon" src={`${process.env.REACT_APP_API_URL}${payment.icon_path}`} />
        </span>
      );
    }

    if (payment.shopifyPaymentMethod) {
      return <div className="img image-default image-shopify" />;
    }

    return <div className={`img image-default image-${payment.code}`} />;
  };
  const getSelectedPaymentRefundContent = () => {
    return (
      <>
        {
          listRefundPayment.map((refundPayment, index) => {
            const belowNumpad = (parseFloat(refundPayment.use_reference_number) === 1 || refundPayment.code === PaymentConstant.CREDIT_CARD)
              ? (
                <div className="payment-reference">
                  <Form.Control
                    placeholder={i18n.t("Reference No")}
                    className="value form-control"
                    name="referenceNo"
                    maxLength={255}
                    type="text" value={refundPayment.referenceNo || ''}
                    onChange={(event) => {
                      handleListRefundPayment(index, {referenceNo: event.target.value});
                    }}
                    autoComplete="off"
                  />
                </div>
              ) : (<></>);
            return (
              <div key={refundPayment.code} className="payment-full-amount">
                <div className="info">
                  {getIconImage(refundPayment)}
                  <span className="label">
                    {i18n.t(refundPayment.title)}
                  </span>
                  <NumPad.CustomNumber
                    onChange={(val) => {
                      handleListRefundPayment(index, {val});
                    }}
                    position="centerLeft"
                    sync={false}
                    arrow="left"
                    isShowAction
                    value={refundPayment.amount}
                  >
                    <Form.Control type="text" className="value form-control" value={OrderService.formatPrice(refundPayment.amount)} readOnly />
                  </NumPad.CustomNumber>
                  <div className="max-amount-message">
                    <p>{
                      i18n.t('{{maxAmount}} available to refund', {maxAmount: OrderService.formatPrice(refundPayment.maxAmount)})
                    }
                    </p>
                  </div>
                  <div className="validation-text">
                    {refundPayment.error}
                  </div>
                  {belowNumpad}
                </div>
              </div>
            );
          })
        }
      </>
    );
  };
  return (
    <>
      <div className="box">
        <div className="box-title">
          <strong className="title">{i18n.t('Remaining')}</strong>
          <span className="price">
            {OrderService.formatPrice(remaining)}
          </span>
        </div>
        {
          getSelectedPaymentRefundContent()
         }
      </div>
    </>
  );
}

AddPaymentRefund.className = 'AddPaymentRefund';
AddPaymentRefund.propTypes = {
  remaining: PropTypes.number,
  listRefundPayment: PropTypes.arrayOf(PropTypes.object),
  handleListRefundPayment: PropTypes.func,
};
export default FunctionComponentFactory.get(AddPaymentRefund);

