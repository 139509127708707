import ShopConstant from "../constant/ShopConstant";

const initialState = {
  error: '',
  shopPublicInfo: {},
};

/**
 * receive action from Shop Action
 *
 * @param state
 * @param action
 * @returns {*}
 */
const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case ShopConstant.GET_PUBLIC_INFO_SUCCESS:
      return {
        ...state,
        shopPublicInfo: action.shop,
      };
    case ShopConstant.GET_PUBLIC_INFO_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default shopReducer;
