import AbstractResourceModel from "../AbstractResourceModel";

export default class DenominationResourceModel extends AbstractResourceModel {
  static className = 'DenominationResourceModel';

  resourceName = 'Denomination';

  /**
   * Get Denominations By Currency Code
   *
   * @param currencyCode
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getDenominationsByCurrencyCode(currencyCode) {
    return this.getResourceOnline().getDenominationsByCurrencyCode(currencyCode);
  }
}
