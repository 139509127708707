import AbstractResourceModel from "../AbstractResourceModel";

export default class SessionResourceModel extends AbstractResourceModel {
  static className = 'SessionResourceModel';

  resourceName = 'Session';

    /**
   * Get Sessions
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getSessions(input) {
    return this.getResourceOnline().getSessions(input);
  }

  /**
   * Get Session Detail
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getSessionDetail(input) {
    return this.getResourceOnline().getSessionDetail(input);
  }

  /**
   * Open Session
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  openSession(input) {
    return this.getResourceOnline().openSession(input);
  }

  /**
   * Close Session
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  closeSession(input) {
    return this.getResourceOnline().closeSession(input);
  }

  /**
   * Put Money In
   * @param {} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  addTransaction(input) {
    return this.getResourceOnline().addTransaction(input);
  }

}
