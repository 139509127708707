import ServiceFactory from "../framework/factory/ServiceFactory";
import CountryResourceModel
  from '../resource-model/CountryResourceModel';
import LocalStorageConstant from '../view/constant/LocalStorageConstant';
import Config from "../config/Config";

import CoreService from './CoreService';
import LocalStorageService from './LocalStorageService';

class CountryServiceClass extends CoreService {
  className = 'CountryServiceClass';

  resourceModel = CountryResourceModel;

  CACHE = {};

  /**
   * Get all countries
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getAllCountriesOnline() {
    return this.getResourceModel().getAllCountriesOnline();
  }

  /**
   * Save all countries to Local storage
   * @param data
   */
  saveToLocalStorage(data) {
    Config.countries = data;
    LocalStorageService.set(LocalStorageConstant.COUNTRIES, JSON.stringify(data));
  }

  /**
   * Get country data
   * @returns {*|*[]}
   */
  getCountriesData() {
    if (!(Config.countries)) {
      const countryString = LocalStorageService.get(LocalStorageConstant.COUNTRIES);
      if (countryString) {
        Config.countries = JSON.parse(countryString);
      } else {
        Config.countries = [];
      }
    }
    return Config.countries;
  }

  /**
   * Load country data
   * @param countryCode
   */
  loadCountryData(countryCode) {
    const countries = this.getCountriesData();
    if (!this.CACHE[countryCode]) {
      this.CACHE[countryCode] = countries.find(
        (country) => {
          return country.code === countryCode;
        },
      );
    }
    return this.CACHE[countryCode];
  }
}

/**
 * @type {CountryServiceClass}
 */
const CountryService = ServiceFactory.get(CountryServiceClass);
export default CountryService;
