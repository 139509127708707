import React from "react";

import FunctionComponentFactory from "../../../framework/factory/FunctionComponentFactory";
import OpenMenuButton from "../menu/OpenMenuButton.jsx";

import SettingsList from "./SettingsList.jsx";

function Settings() {
  return (
    <>
      <OpenMenuButton />
      <SettingsList />
    </>
  );
}

Settings.className = 'Settings';
Settings.propTypes = {};

export default FunctionComponentFactory.get(Settings);
