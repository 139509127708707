import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';

import FunctionComponentFactory
  from '../../../../../framework/factory/FunctionComponentFactory';

function CustomerCheckBoxComponent(props) {
  const {label, oneRow, value, code, setFieldValueByCode, disabled} = props;
  const className = oneRow ? "col-sm-12" : "col-sm-6";

  const inputRef = useRef(null);

  /**
   * onchange
   */
  const onChange = () => {
    setFieldValueByCode(code, inputRef.current.checked);
  };
  return (
    <div className={className}>
      <Form.Label className="pull-left">{label}</Form.Label>
      <div className="checkbox pull-right">
        <Form.Label>
          <input
            aria-label={label}
            ref={inputRef}
            type="checkbox"
            checked={value}
            onChange={onChange}
            disabled={disabled}
          />
          {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
          <span><span>no</span></span>
        </Form.Label>
      </div>
    </div>
  );
}

CustomerCheckBoxComponent.className = 'CustomerCheckBoxComponent';
CustomerCheckBoxComponent.propTypes = {
  label: PropTypes.string,
  oneRow: PropTypes.bool,
  value: PropTypes.bool,
  code: PropTypes.string,
  disabled: PropTypes.bool,
  setFieldValueByCode: PropTypes.func,
};

export default React.memo(FunctionComponentFactory.get(CustomerCheckBoxComponent));
