import DataResourceFactory from "../../framework/factory/DataResourceFactory";
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';

import OmcAbstract from "./OmcAbstract";
import GraphqlStock from './graphql/GraphqlStock';

export class OmcStock extends OmcAbstract {
  static className = 'OmcStock';

  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlStock,
  };


  /**
   * Get Stock of one variant product
   * @returns {*}
   */
  getStockOneVariantProduct(id) {
    const resource = this.getApiResource();
    return resource.getStockOneVariantProduct(id);
  }

  /**
   * Get Stock by product options
   * @param id
   * @param productOptions
   * @returns {*}
   */
  getStockByProductOptions(id, productOptions) {
    const resource = this.getApiResource();
    return resource.getStockByProductOptions(id, productOptions);
  }
}

/**
 * @type {OmcStaff}
 */
export default DataResourceFactory.get(OmcStock);
