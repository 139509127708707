const ScanConstant = {
  REMOVE_SCAN_STRING_KEYS: ['backspace', 'delete'],
  NON_SCAN_KEYS: ['control'],
  SHIFT_MAP_KEYS: {
    1: "!",
    2: "@",
    3: "#",
    4: "$",
    5: "%",
    6: "^",
    7: "&",
    8: "*",
    9: "(",
    0: ")",
    "-": "_",
    "=": "+",
    "[": "{",
    "]": "}",
    ";": ":",
    "'": '"',
    ",": '<',
    ".": '>',
    "/": '?',
    "`": '~',
  },
  MAX_DIFF_TIME_WITH_SCAN_BARCODE: 50,
  MIN_BARCODE_LENGTH: 5,
  SET_BARCODE_STRING: "[SCAN] SET BARCODE STRING",
  SET_SCAN_PAGE: "[SCAN] SET SCAN PAGE",
  SCAN_PAGES: {
    PRODUCT: "PRODUCT",
    CUSTOMER: "CUSTOMER",
    ORDER: "ORDER",
    ONHOLD_ORDER: "ONHOLD_ORDER",
    LOGIN: "LOGIN",
  },
};

export default ScanConstant;
