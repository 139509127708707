import {cloneDeep} from "lodash";

import ActionFactory from "../../../../framework/factory/ActionFactory";
import QuoteService from "../../../../service/checkout/QuoteService";
import CheckoutConstant from "../../../constant/checkout/CheckoutConstant";
import EditPriceAction from "../EditPriceAction";
import DiscountConstant from "../../../constant/checkout/quote/DiscountConstant";

export class ConflictOrderDiscountActionClass {

  /**
   * Open Conflict Automatic Discount Popup
   * @param appliedCustomDiscountLineItems
   * @returns {{type: string, appliedCustomDiscountLineItems: *}}
   */
  openConflictAutoDiscountPopup(appliedCustomDiscountLineItems) {
    return {
      type: CheckoutConstant.OPEN_CONFLICT_PRODUCT_AUTO_DISCOUNT_POPUP,
      appliedCustomDiscountLineItems,
    };
  }

  /**
   * Close Conflict Automatic Discount Popup
   * @returns {{type: string}}
   */
  closeConflictAutoDiscountPopup() {
    return {
      type: CheckoutConstant.CLOSE_CONFLICT_PRODUCT_AUTO_DISCOUNT_POPUP,
    };
  }

  /**
   * Open Conflict Coupon Discount Popup
   * @param appliedCustomDiscountLineItems
   * @returns {{type: string, appliedCustomDiscountLineItems: *}}
   */
  openConflictCouponDiscountPopup(appliedCustomDiscountLineItems) {
    return {
      type: CheckoutConstant.OPEN_CONFLICT_PRODUCT_COUPON_DISCOUNT_POPUP,
      appliedCustomDiscountLineItems,
    };
  }

  /**
   * Close Conflict Coupon Discount Popup
   * @returns {{type: string}}
   */
  closeConflictCouponDiscountPopup() {
    return {
      type: CheckoutConstant.CLOSE_CONFLICT_PRODUCT_COUPON_DISCOUNT_POPUP,
    };
  }

  /**
   * Handle Checkout With Automatic Product Discount
   * @param quote
   * @returns {(function(*): void)|*}
   */
  handleCheckoutWithAutoProductDiscount(quote) {
    return (dispatch) => {
      const quoteData = cloneDeep(quote);
      quoteData.ignoreDiscounts = null;
      dispatch(this.handleCheckoutWithProductDiscount(quoteData));
    };
  }

  /**
   * Handle Checkout With Product Coupon
   * @param quote
   * @returns {(function(*): void)|*}
   */
  handleCheckoutWithProductCoupon(quote) {
    return async (dispatch) => {
      const quoteData = cloneDeep(quote);
      quoteData.ignoreDiscounts = null;
      await dispatch(this.handleCheckoutWithProductDiscount(quoteData));
      const quoteAction = require("../QuoteAction").default;
      dispatch(quoteAction.setShowConflictCouponSubmittedPopup(true));
    };
  }

  /**
   * Handle Checkout With Product Discount
   * @param quote
   * @returns {(function(*): void)|*}
   */
  handleCheckoutWithProductDiscount(quote) {
    return async (dispatch) => {
      const quoteAction = require("../QuoteAction").default;
      const result = QuoteService.removeQuoteCustomDiscountLineItem(quote, true);
      dispatch(EditPriceAction.resetCustomPriceLineItem());
      if (result && result.quote) {
        dispatch(quoteAction.setQuote(result.quote));
        await dispatch(quoteAction.checkoutQuote(result.quote));
      }
    };
  }

  /**
   * Handle Checkout With Custom Price Line Items
   * @param quote
   * @returns {(function(*): void)|*}
   */
  handleCheckoutWithCustomPrice(quote) {
    return (dispatch) => {
      const quoteData = cloneDeep(quote);
      const quoteAction = require("../QuoteAction").default;

      /* Ignore all automatic product discounts */
      quoteData.ignoreDiscounts = [
        {
          ignoreDiscountClass: DiscountConstant.DISCOUNT_CLASS_PRODUCT,
          ignoreRuleTypes: [DiscountConstant.DISCOUNT_BASIC_AUTO, DiscountConstant.DISCOUNT_BUY_X_GET_Y_AUTO],
        },
      ];
      dispatch(quoteAction.checkoutQuote(quoteData));
    };
  }
}

/**
 * @type {ConflictOrderDiscountActionClass}
 */
const ConflictOrderDiscountAction = ActionFactory.get(ConflictOrderDiscountActionClass);
export default ConflictOrderDiscountAction;
