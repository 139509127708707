import DataResourceFactory from '../../framework/factory/DataResourceFactory';

import Abstract from './IndexedDbAbstract';

export class IndexedDbErrorLog extends Abstract {
  static className = 'IndexedDbErrorLog';

  main_table = 'error_log';
  primary_key = 'action_id';
  offline_id_prefix = 'action';
}

export default DataResourceFactory.get(IndexedDbErrorLog);

