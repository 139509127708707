import React from 'react';

import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import Loading from '../../component/loading/Loading.jsx';

function LoadingPage() {
  return (
    <Loading />
  );
}

LoadingPage.className = 'LoadingPage';
export default FunctionComponentFactory.get(LoadingPage);
