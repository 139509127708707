const multiCartConstant = {
  GET_LIST_CART: '[MULTI_CART] GET_LIST_CART',
  GET_LIST_CART_FAILED: '[MULTI_CART] GET_LIST_CART_FAILED',
  SELECT_CART: '[MULTI_CART] SELECT_CART',
  ADD_NEW_CART: '[MULTI_CART] ADD_NEW_CART',
  DELETE_CART: '[MULTI_CART] DELETE_CART',
  UPDATE_CART: '[MULTI_CART] UPDATE_CART',
  REMOVE_ALL_MULTI_CART: '[MULTI_CART] REMOVE_ALL_MULTI_CART',
  RESET_ALL_MULTI_CART: '[MULTI_CART] RESET_ALL_MULTI_CART',
};

export default multiCartConstant;
