import ServiceFactory from "../../../framework/factory/ServiceFactory";
import ProductTypeConstant from "../../../view/constant/ProductTypeConstant";

import AbstractQuoteService from "./AbstractService";
import AddSimpleProductService from './add-product/AddSimpleProductService';

export class AddProductServiceClass extends AbstractQuoteService {
  static className = 'AddProductServiceClass';

  addProductServices = {
    [ProductTypeConstant.SIMPLE]: AddSimpleProductService,
  };

  /**
   * Get Add product service per product type
   *
   * @param product
   * @return {*}
   */
  getAddProductService(product) {
    if (typeof product === 'string' && this.addProductServices[product]) {
      return this.addProductServices[product];
    }
    return this.addProductServices.simple;
  }

    /**
     * Add product to cart
     *
     * @param quote
     * @param data
     * @return {*|{type, data}|void}
     */
  addProduct(quote, data, isShowErrorToastMessage = true) {
    return this.getAddProductService(data.product).addProduct(quote, data, isShowErrorToastMessage);
  }

}

/** @type AddProductServiceClass */
const AddProductService = ServiceFactory.get(AddProductServiceClass);

export default AddProductService;
