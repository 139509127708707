import DataResourceFactory from '../../framework/factory/DataResourceFactory';
import ApiResourceConstant
  from '../../view/constant/data-resource/ApiResourceConstant';

import OmcAbstract from "./OmcAbstract";
import GraphqlConfig from './graphql/GraphqlConfig';


export class OmcConfig extends OmcAbstract {
  static className = 'OmcConfig';
  apiResources = {
    [ApiResourceConstant.GRAPHQL]: GraphqlConfig,
  };

  /**
   * Get Config
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getConfig() {
    return this.getApiResource().getConfig();
  }
}

export default DataResourceFactory.get(OmcConfig);
