import Dexie from 'dexie';

export {default as IndexedDbErrorLog} from './IndexedDbErrorLog';

const db = new Dexie("magestore_pos");

db.version(1).stores({
  error_log: 'action_id, uuid, order, staff_id, created_at',
});

db.version(3).stores({
  error_log: 'action_id, uuid, order, staff_id, created_at',
  cart: 'id, pos_id, count, quote',
});

db.open();

export default db;
