import _ from 'lodash';

import ProductList from '../../component/checkout/catalog/ProductList.jsx';
import QuoteConstant from '../../constant/checkout/QuoteConstant';
import CheckoutConstant from '../../constant/checkout/CheckoutConstant';
import Payment from '../../component/checkout/Payment.jsx';

const initialState = {
  currentPage: ProductList.className,
  addedItemIdInQuote: null,
  updatedItemIdInQuote: null,
  isApplyingDiscount: false,
  isAppliedDiscount: false,
  cannotAddProductList: [],
  isApplyingCoupon: false,
  isAppliedCoupon: false,
  applyCouponErrorMessage: null,
  isRemovingCoupon: false,
  conflictCouponSubmitted: null,
  showConflictCouponSubmittedPopup: false,
};

/**
 * receive action from Loading Action
 *
 * @param state = initialState
 * @param action
 * @returns {*}
 */
const index = (state = _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case QuoteConstant.PRODUCT_ADD_QUOTE:
      return {...state, addedItemIdInQuote: action.addedItemId};
    case QuoteConstant.PRODUCT_UPDATED_QUOTE:
      return {...state, updatedItemIdInQuote: action.updatedItemId};
    case CheckoutConstant.CHECKOUT_TO_SELECT_PAYMENTS:
      return {...state, currentPage: Payment.className};
    case CheckoutConstant.CHECKOUT_TO_CATALOG:
      return {...state, currentPage: ProductList.className};
    case CheckoutConstant.CHECKOUT_SWITCH_PAGE:
      return {...state, currentPage: action.page};
    case QuoteConstant.SET_IS_APPLYING_DISCOUNT:
      return {...state, isApplyingDiscount: action.isApplyingDiscount};
    case QuoteConstant.SET_IS_APPLIED_DISCOUNT:
      return {...state, isAppliedDiscount: action.isAppliedDiscount};
    case QuoteConstant.SET_CUSTOM_DISCOUNT:
      return {
        ...state,
        isApplyingDiscount: true,
        isAppliedDiscount: false,
      };
    case QuoteConstant.SET_CUSTOM_DISCOUNT_SUCCESS:
      return {
        ...state,
        isApplyingDiscount: false,
        isAppliedDiscount: true,
      };
    case QuoteConstant.SET_CUSTOM_DISCOUNT_FAILED:
      return {...state, isApplyingDiscount: false};
    case QuoteConstant.SET_CUSTOM_CHARGE_TAX:
      return {
        ...state,
      };
    case QuoteConstant.SET_CUSTOM_CHARGE_TAX_SUCCESS:
      return {
        ...state,
      };
    case QuoteConstant.SET_CUSTOM_CHARGE_TAX_FAILED:
      return {
        ...state,
      };
    case QuoteConstant.HANDLE_APPLY_COUPON:
      return {
        ...state,
        isApplyingCoupon: true,
        isAppliedCoupon: false,
        applyCouponErrorMessage: null,
      };
    case QuoteConstant.HANDLE_APPLY_COUPON_SUCCESS:
      return {
        ...state,
        isApplyingCoupon: false,
        isAppliedCoupon: true,
        applyCouponErrorMessage: null,
      };
    case QuoteConstant.HANDLE_APPLY_COUPON_FAILED:
      return {
        ...state,
        isApplyingCoupon: false,
        applyCouponErrorMessage: action.applyCouponErrorMessage,
      };
    case QuoteConstant.HANDLE_REMOVE_COUPON:
      return {
        ...state,
        isRemovingCoupon: true,
      };
    case QuoteConstant.HANDLE_REMOVE_COUPON_SUCCESS:
      return {
        ...state,
        isRemovingCoupon: false,
      };
    case QuoteConstant.HANDLE_REMOVE_COUPON_FAILED:
      return {
        ...state,
        isRemovingCoupon: false,
      };
    case QuoteConstant.SET_CONFLICT_COUPON_SUBMITTED:
      return {
        ...state,
        conflictCouponSubmitted: action.conflictCouponSubmitted,
      };
    case QuoteConstant.SET_SHOW_CONFLICT_COUPON_SUBMITTED_POPUP:
      return {
        ...state,
        showConflictCouponSubmittedPopup: action.showConflictCouponSubmittedPopup,
      };
    case QuoteConstant.ADD_PRODUCT_FAIL:
      return {
        ...state,
        cannotAddProductList: [
          ...state.cannotAddProductList,
          action.invalidProduct,
        ],
      };
    case QuoteConstant.RESET_CANNOT_ADD_PRODUCT_LIST:
      return {
        ...state,
        cannotAddProductList: [],
      };
    default: {
      return state;
    }
  }
};

export default index;
