const orderConstant = {
  PLACE_ORDER_AFTER: '[ORDER] PLACE_ORDER_AFTER',
  SEARCH_ORDER: '[ORDER] SEARCH_ORDER',
  SEARCH_ORDER_RESULT: '[ORDER] SEARCH_ORDER_RESULT',
  SEARCH_ORDER_ERROR: '[ORDER] SEARCH_ORDER_ERROR',

  REPRINT_ORDER: '[ORDER] REPRINT_ORDER',
  REPRINT_ORDER_SUCCESS: '[ORDER] REPRINT_ORDER_SUCCESS',
  REPRINT_ORDER_FAILED: '[ORDER] REPRINT_ORDER_FAILED',
  SET_IS_LOADING_REPRINT: '[ORDER] SET_IS_LOADING_REPRINT',


  PRINT_CREDITMEMO: '[ORDER] PRINT_CREDITMEMO',

  TAKE_PAYMENT: '[ORDER] TAKE_PAYMENT',
  TAKE_PAYMENT_RESULT: '[ORDER] TAKE_PAYMENT_RESULT',

  SEND_EMAIL: '[ORDER] SEND_EMAIL',
  SEND_EMAIL_SUCCESS: '[ORDER] SEND_EMAIL_SUCCESS',
  SEND_EMAIL_FAILED: '[ORDER] SEND_EMAIL_FAILED',
  SEND_EMAIL_RESULT: '[ORDER] SEND_EMAIL_RESULT',

  ADD_NOTE: '[ORDER] ADD_NOTE',
  ADD_NOTE_SUCCESS: '[ORDER] ADD_NOTE_SUCCESS',
  ADD_NOTE_FAILED: '[ORDER] ADD_NOTE_FAILED',
  SET_IS_OPEN_ADD_NOTE_POPUP: '[ORDER] SET_IS_OPEN_ADD_NOTE_POPUP',

  CANCEL: '[ORDER] CANCEL',
  CANCEL_RESULT: '[ORDER] CANCEL_RESULT',
  CANCEL_ORDER_AFTER: '[ORDER] CANCEL_ORDER_AFTER',
  CANCEL_ORDER_SUCCESS: '[ORDER] CANCEL_ORDER_SUCCESS',
  CANCEL_ORDER_FAILED: '[ORDER] CANCEL_ORDER_FAILED',
  ORDER_CANCEL_REASON_OPTIONS: [
    {value: 'customer', label: 'Customer changed'},
    {value: 'fraud', label: 'Fraudulent order'},
    {value: 'inventory', label: 'Items unavailable'},
    {value: 'declined', label: 'Payment declined'},
    {value: 'staff', label: 'Staff error'},
    {value: 'other', label: 'Other'},
  ],
  SET_IS_OPEN_ORDER_CANCEL_POPUP: '[ORDER] SET_IS_OPEN_ORDER_CANCEL_POPUP',

  TAKE_PAYMENT_PROCESS_PAYMENT: '[ORDER] TAKE_PAYMENT_PROCESS_PAYMENT',
  TAKE_PAYMENT_PROCESS_PAYMENT_RESULT: '[ORDER] TAKE_PAYMENT_PROCESS_PAYMENT_RESULT',
  TAKE_PAYMENT_PROCESS_PAYMENT_ERROR: '[ORDER] TAKE_PAYMENT_PROCESS_PAYMENT_ERROR',
  TAKE_PAYMENT_PROCESS_SINGLE_PAYMENT_RESULT: '[ORDER] TAKE_PAYMENT_PROCESS_SINGLE_PAYMENT_RESULT',
  TAKE_PAYMENT_PROCESS_SINGLE_PAYMENT_ERROR: '[ORDER] TAKE_PAYMENT_PROCESS_SINGLE_PAYMENT_ERROR',

  REMOVE_PAYMENT: '[ORDER] REMOVE_PAYMENT',
  ADD_PAYMENT: '[ORDER] ADD_PAYMENT',

  SYNC_ACTION_UPDATE_DATA_FINISH: '[ORDER] SYNC_ACTION_UPDATE_DATA_FINISH',
  RESET_UPDATED_ORDERS_LIST: '[ORDER] RESET_UPDATED_ORDERS_LIST',
  SYNC_DELETED_ORDER_FINISH: '[ORDER] SYNC_DELETED_ORDER_FINISH',

  // Config Path
  XML_PATH_CONFIG_SYNC_ORDER_TIME: "webpos/offline/order_time",
  XML_PATH_CONFIG_SYNC_ORDER_SINCE: "webpos/offline/order_since",

  ORDER_SINCE_24H: '24h',
  ORDER_SINCE_7_DAYS: '7days',
  ORDER_SINCE_MONTH: 'month',
  ORDER_SINCE_YTD: 'YTD',
  ORDER_SINCE_2_YTD: '2YTD',

  GET_LIST_ORDER_STATUSES: '[ORDER] GET_LIST_ORDER_STATUSES',
  TYPE_GET_LIST_ORDER_STATUSES: 'get_list_order_statuses',

  PAGE_SIZE: 16,

  SORT_ORDER_DESC: "DESC",

  GET_ORDERS: '[ORDER] GET_ORDERS',
  GET_ORDERS_SUCCESS: '[ORDER] GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILED: '[ORDER] GET_ORDERS_FAILED',

  GET_ORDER: '[ORDER] GET_ORDER',
  GET_ORDER_SUCCESS: '[ORDER] GET_ORDER_SUCCESS',
  GET_ORDER_FAILED: '[ORDER] GET_ORDER_FAILED',

  SET_IS_LOADING_ORDER_DETAIL_PAGE: "[ORDER] SET_IS_LOADING_ORDER_DETAIL_PAGE",

  SET_CURRENT_ORDER: '[ORDER] CURRENT_ORDER',

  ACTION_FIRST_PAGE: 'FIRST',
  ACTION_NEXT_PAGE: 'NEXT',
  ACTION_PREVIOUS_PAGE: 'PREVIOUS',

  FINANCIAL_STATUS_AUTHORIZED: 'AUTHORIZED',
  FINANCIAL_STATUS_PENDING: 'PENDING',
  FINANCIAL_STATUS_PARTIALLY_PAID: 'PARTIALLY_PAID',
  FINANCIAL_STATUS_PAID: 'PAID',
  FINANCIAL_STATUS_PARTIALLY_REFUNDED: 'PARTIALLY_REFUNDED',
  FINANCIAL_STATUS_REFUNDED: 'REFUNDED',
  FINANCIAL_STATUS_VOIDED: 'VOIDED',

  FULFILLMENT_STATUS_UNFULFILLED: 'UNFULFILLED',
  FULFILLMENT_STATUS_FULFILLED: 'FULFILLED',
  FULFILLMENT_STATUS_PARTIALLY_FULFILLED: 'PARTIALLY_FULFILLED',
  FULFILLMENT_STATUS_ON_HOLD: 'ON_HOLD',
  FULFILLMENT_STATUS_RESTOCKED: 'RESTOCKED',

  ORDER_TRANSACTION_KIND_SALE: 'SALE',
  ORDER_TRANSACTION_KIND_CAPTURE: 'CAPTURE',
  ORDER_TRANSACTION_KIND_REFUND: 'REFUND',
  ORDER_TRANSACTION_STATUS_SUCCESS: 'SUCCESS',
  ORDER_TRANSACTION_STATUS_PENDING: 'PENDING',

  DISCOUNT_APPLICATION_ALLOCATION_ENTITLED: "ENTITLED",
  DISCOUNT_APPLICATION_ALLOCATION_EXPLICIT: "EXPLICIT",
  DISCOUNT_APPLICATION_ALLOCATION_ALL: "ALL",

  SET_IS_REDIRECT_TO_CHECK_OUT_PAGE: '[ORDER] SET_IS_REDIRECT_TO_CHECK_OUT_PAGE',
  SET_IS_OPEN_REORDER_CONFIRM_POPUP: '[ORDER] SET_IS_OPEN_REORDER_CONFIRM_POPUP',

  HANDLE_REORDER: '[ORDER] HANDLE_REORDER',
  HANDLE_REORDER_SUCCESS: '[ORDER] HANDLE_REORDER_SUCCESS',
  HANDLE_REORDER_FAILED: '[ORDER] HANDLE_REORDER_FAILED',
  SET_IS_LOADING_REORDER: '[ORDER] SET_IS_LOADING_REORDER',

  RESET_STATE: "[ORDER] RESET_STATE",

  BUTTON_PLUS: '[ORDER] BUTTON_PLUS',
  BUTTON_MINUS: '[ORDER] BUTTON_MINUS',
  CHANGE_NUMPAD: '[ORDER] CHANGE_NUMPAD',
  SET_IS_OPEN_FULFILL_ITEM_PAGE: '[ORDER] SET_IS_OPEN_FULFILL_ITEM_PAGE',
  BARCODE_HEIGHT: 70,
  BARCODE_WIDTH: 2.6,
  BARCODE_FONT_SIZE: 24,
  SET_IS_OPEN_SEND_EMAIL_POPUP: "[ORDER] SET_IS_OPEN_SEND_EMAIL_POPUP",

  // eslint-disable-next-line no-control-regex, no-useless-escape
  REGEX_EMAIL: /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)*(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i,
  HANDLE_OPEN_TAKE_PAYMENT: "[ORDER] HANDLE_OPEN_TAKE_PAYMENT",
  HANDLE_OPEN_LIST_PAYMENT: "[ORDER] HANDLE_OPEN_LIST_PAYMENT",
  HANDLE_OPEN_EDIT_PAYMENT: "[ORDER] HANDLE_OPEN_EDIT_PAYMENT",
  HANDLE_OPEN_COMPLETE_PAYMENT: "[ORDER] HANDLE_OPEN_COMPLETE_PAYMENT",
  GET_LIST_PAYMENT_METHOD_SUCCESS: "[ORDER] GET_LIST_PAYMENT_METHOD_SUCCESS",
  BACK_FROM_EDIT_PAYMENT: "[ORDER] BACK_FROM_EDIT_PAYMENT",
  ACCEPT_PAYMENT: "[ORDER] ACCEPT_PAYMENT",
  SET_REMAINING: "[ORDER] SET_REMAINING",
  REMOVE_PAID_ITEM: "[ORDER] REMOVE_PAID_ITEM",
  UPDATE_PAID_ITEM: "[ORDER] UPDATE_PAID_ITEM",
  CREATE_RECORD_PAYMENT: "[ORDER] CREATE_RECORD_PAYMENT",
  CREATE_RECORD_PAYMENT_SUCCESSFUL: "[ORDER] CREATE_RECORD_PAYMENT_SUCCESSFUL",
  CREATE_RECORD_PAYMENT_FAILED: "[ORDER] CREATE_RECORD_PAYMENT_FAILED",
  RESET_PAYMENT_STATE: "[ORDER] RESET_PAYMENT_STATE",
  UPDATE_ORDER: "[ORDER] UPDATE_ORDER",

  CUSTOM_ATTRIBUTE_KEY_CASHIER: 'Cashier',
  CUSTOM_ATTRIBUTE_KEY_LOCATION: 'Location',
  CUSTOM_ATTRIBUTE_KEY_POS: 'POS',
  GUEST_DISPLAY: 'Guest',
  COMPLETE_ORDER: '[ORDER] COMPLETE_ORDER',
  COMPLETE_ORDER_SUCCESS: '[ORDER] COMPLETE_ORDER_SUCCESS',
  COMPLETE_ORDER_FAILED: '[ORDER] COMPLETE_ORDER_FAILED',
  SET_ERROR_COMPLETE_PRODUCT_LIST: '[ORDER] SET_ERROR_COMPLETE_PRODUCT_LIST',
  SET_IS_OPEN_ERROR_COMPLETE_POPUP: '[ORDER] SET_IS_OPEN_ERROR_COMPLETE_POPUP',
  SET_IS_PROCESSING_ORDER: '[ORDER] SET_IS_PROCESSING_ORDER',

  PRINT_RECEIPT_AFTER_CREATE_ORDER: '[ORDER] PRINT_RECEIPT_AFTER_CREATE_ORDER',
  PRINT_RECEIPT_AFTER_CREATE_ORDER_SUCCESS: '[ORDER] PRINT_RECEIPT_AFTER_CREATE_ORDER_SUCCESS',
  PRINT_RECEIPT_AFTER_CREATE_ORDER_FAILED: '[ORDER] PRINT_RECEIPT_AFTER_CREATE_ORDER_FAILED',

  OPEN_FULFILLMENT_CONFIRM: "[ORDER] OPEN_FULFILLMENT_CONFIRM",
  OPEN_CANCEL_CREATE_SHIPMENT: "[ORDER] OPEN_CANCEL_CREATE_SHIPMENT",
  GET_FULFILLMENT_ORDER_SUCCESS: "[ORDER] GET_FULFILLMENT_ORDER_SUCCESS",
  GET_FULFILL_ITEMS_PAGE: '[ORDER] GET_FULFILL_ITEMS_PAGE',
  GET_FULFILL_ITEMS_PAGE_SUCCESS: '[ORDER] GET_FULFILL_ITEMS_PAGE_SUCCESS',
  CREATE_FULFILLMENT_ORDER: '[ORDER] CREATE_FULFILLMENT_ORDER',
  CREATE_FULFILLMENT_ORDER_SUCCESS: '[ORDER] CREATE_FULFILLMENT_ORDER_SUCCESS',
  CREATE_FULFILLMENT_ORDER_FAILED: '[ORDER] CREATE_FULFILLMENT_ORDER_FAILED',
  RESET_DATE_AFTER_FULFILL: '[ORDER] RESET_DATE_AFTER_FULFILL',
  GET_FULFILLMENT_ORDER: '[ORDER] GET_FULFILLMENT_ORDER',
  STATUS_CREATE_FULFILLMENT: 'create_fulfillment',
  CLOSE: "[ORDER] CLOSE",
  OPEN: "[ORDER] OPEN",
  SAVE: "[ORDER] SAVE",
  NO: '[ORDER] NO',
  YES: '[ORDER] YES',
  SUCCESS: '[ORDER] SUCCESS',
  FAILED: '[ORDER] FAILED',
  HANDLE_OPEN_REFUND: "[ORDER] HANDLE_OPEN_REFUND",
  HANDLE_OPEN_SELECT_ITEM_REFUND: "[ORDER] HANDLE_OPEN_SELECT_ITEM_REFUND",
  HANDLE_CLOSE_REFUND: "[ORDER] HANDLE_CLOSE_REFUND",
  HANDLE_OPEN_ADJUSTMENTS: "[ORDER] HANDLE_OPEN_ADJUSTMENTS",
  SET_CURRENT_TOTAL_WHEN_SELECT_ITEMS: "[ORDER] SET_CURRENT_TOTAL_WHEN_SELECT_ITEMS",
  SET_CURRENT_TOTAL: "[ORDER] SET_CURRENT_TOTAL",
  UPDATE_LIST_SELECTED_ITEMS: "[ORDER] UPDATE_LIST_SELECTED_ITEMS",
  UPDATE_LIST_ADJUSTMENTS: "[ORDER] UPDATE_LIST_ADJUSTMENTS",
  SET_IS_MAX_QTY_REFUND: "[ORDER] SET_IS_MAX_QTY_REFUND",
  UPDATE_IS_MAX_QTY_REFUND: "[ORDER] UPDATE_IS_MAX_QTY_REFUND",
  HANDLE_OPEN_COMPLETE_REFUND: "[ORDER] HANDLE_OPEN_COMPLETE_REFUND",
  HANDLE_UPDATE_ADJUSTMENT_REFUND_AMOUNT: "[ORDER] HANDLE_UPDATE_ADJUSTMENT_REFUND_AMOUNT",
  CREATE_REFUND_FAILED: "[ORDER] CREATE_REFUND_FAILED",


  CREATE_REFUND_SUCCESS: "[ORDER] CREATE_REFUND_SUCCESS",
  SET_IS_LOADING_REFUND: "[ORDER] SET_IS_LOADING_REFUND",
  SET_LIST_SELECTED_ITEMS_TYPE: {
    DEFAULT: 'default',
    MAX_QTY: 'max_qty',
  },
};

export default orderConstant;
