import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import SubscriptionResourceModel from "../../resource-model/subscription/SubscriptionResourceModel";
import PlatformService from "../PlatformService";

/**
 * Staff Service Class
 */
export class SubscriptionServiceClass extends CoreService {
  static className = 'SubscriptionServiceClass';
  resourceModel = SubscriptionResourceModel;

  /**
   * validate permissions
   * @param listPermissionRequire
   * @param shop
   * @return {{promise}}
   */
  validatePermission(listPermissionRequire, shop) {
    if (PlatformService.isSaasPlatform()) { return this.getResourceModel().validatePermissionPlan(listPermissionRequire, shop); }

    return {
      data: {
        validatePermissions: {
          result: true,
          error: null,
        },
      },
    };
  }
}


/**
 * @type {SubscriptionServiceClass}
 */
const subscriptionService = ServiceFactory.get(SubscriptionServiceClass);

export default subscriptionService;
