import React, {Fragment} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import FunctionComponentFactory from '../../../../../framework/factory/FunctionComponentFactory';
import "../../../../../assets/OrderCancel.css";
import "../../../../../assets/Order.css";
import ConflictProductDiscountAction from '../../../../action/checkout/conflict-discount/ConflictProductDiscountAction';


const ConflictProductCouponDiscountComponent = () => {
  const {isOpenConflictProductCouponDiscountPopup, appliedCustomDiscountLineItems} = useSelector((state) => state.core.checkout.conflictDiscount);
  const {quote} = useSelector((state) => state.core.checkout);

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleClosePopup = () => {
    dispatch(ConflictProductDiscountAction.closeConflictCouponDiscountPopup());
  };

  const handleConfirmApplyProductCoupon = () => {
    dispatch(ConflictProductDiscountAction.handleCheckoutWithProductCoupon(quote));
    handleClosePopup();
  };

  const productDataDisplay = (productData) => {
    return (
      <>
        {productData.quantity} x {productData.title}
      </>
    );
  };

  return (
    <>
      <Modal
        size="lg"
        className="popup-edit-customer popup-reorder-confirm"
        dialogClassName="popup-create-customer in"
        show={isOpenConflictProductCouponDiscountPopup}
      >
        <div className="modal-header">
          <h4 className="modal-title" id="order-cancel-modal-title">{t('Confirmation')}</h4>
        </div>
        <div data-scrollbar className="modal-body">
          <br />
          <div className="text reorder-confirm-text">
            {t('To apply a product discount Coupon, ')}
            <span className="reorder-confirm-strong-text">
              {t('you have to remove custom price')}
            </span>
          </div>
          <div className="text reorder-confirm-text">
            {t('Note: Custom prices on these items will be removed:')}
          </div>
          {
            appliedCustomDiscountLineItems?.length > 0 && appliedCustomDiscountLineItems.map((productData) => {
              return (
                <div className="text reorder-confirm-text" key={productData.id}>
                  {productDataDisplay(productData)}
                </div>
              );
            })
          }
          <div className="text reorder-confirm-text">
            {t('Are you sure to remove them?')}
          </div>
          <div className="actions-bottom">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
            <a className="btn" onClick={handleClosePopup}>{t('No')}</a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
            <a className="btn" onClick={handleConfirmApplyProductCoupon} >{t('Yes')}</a>
          </div>
        </div>
      </Modal>
    </>
  );
};

ConflictProductCouponDiscountComponent.className = 'ConflictProductCouponDiscountComponent';
ConflictProductCouponDiscountComponent.propTypes = {
};

export default FunctionComponentFactory.get(ConflictProductCouponDiscountComponent);
