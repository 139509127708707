import {cloneDeep, filter, sortBy} from 'lodash';

import CoreService from "../CoreService";
import ServiceFactory from "../../framework/factory/ServiceFactory";
import LocalStorageService from "../LocalStorageService";
import LocalStorageConstant from "../../view/constant/LocalStorageConstant";
import PaymentConstant from "../../view/constant/PaymentConstant";

export class PaymentServiceClass extends CoreService {
  static className = 'PaymentService';

  /**
   * Get list payment from local storage
   *
   * @returns {string|Array|Object}
   */
  getListPayment() {
    const settings = this.getSettings();

    const listPayment = {};
    for (const item of settings) {
      const {path, value} = item;
      if (path.includes(PaymentConstant.SETTING_PREFIX)) {
        const pathParts = path.split('/').slice(2);
        const key = pathParts[0];
        if (!listPayment[key]) {
          listPayment[key] = {};
        }
        const lastPart = pathParts[pathParts.length - 1];
        listPayment[key][lastPart] = value;
        listPayment[key].code = key;
      }
    }

    return sortBy(listPayment, ['sort_order', 'title']);
  }

  /**
   * Get list payment active
   *
   * @returns {string|Array|Object}
   */
  getListPaymentActive() {
    const listPayment = this.getListPayment();
    return filter(listPayment, ((payment) => {
      if (parseFloat(payment.active) !== 1) {
        return;
      }

      return payment;
    }));
  }


  /**
   * Get list payment that not contains pay later payment method
   *
   * @returns {string|Array|Object}
   */
  getListPaymentNotPayLater() {
    const listPayment = this.getListPayment();
    return filter(listPayment, ((payment) => {
      if ((payment.use_pay_later && parseFloat(payment.use_pay_later) !== 0) || (parseFloat(payment.active) !== 1)) {
        return;
      }

      return payment;
    }));
  }

  /**
   * Get list settings from local storage
   *
   * @returns {string|Array|Object}
   */
  getSettings() {
    return JSON.parse(LocalStorageService.get(LocalStorageConstant.CONFIG)).settings;
  }

  /**
   * Mapping code of payment in transaction to title
   *
   * @param transactions
   *
   * @returns {Array}
   */
  mapFromCodeToTitle(transactions) {
    const transactionCloneDeep = cloneDeep(transactions);
    const listPayment = this.getListPayment();
    for (const transaction of transactionCloneDeep) {
      for (const payment of listPayment) {
        if (transaction.gateway === payment.code) {
          transaction.formattedGateway = payment.title;
          break;
        }
      }
    }
    return transactionCloneDeep;
  }
}

/**
 * @type {PaymentServiceClass}
 */
const PaymentService = ServiceFactory.get(PaymentServiceClass);
export default PaymentService;
