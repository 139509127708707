import MenuConstant from '../constant/MenuConstant';
import ActionFactory from '../../framework/factory/ActionFactory';

export class MenuActionClass {

  /**
   * Open menu
   * @returns {{type: string}}
   */
  open() {
    return {
      type: MenuConstant.OPEN,
    };
  }

  /**
   * Close menu
   * @returns {{type: string}}
   */
  close() {
    return {
      type: MenuConstant.CLOSE,
    };
  }

  /**
   * Set need update menu items
   * @param needUpdateMenuItems
   * @returns {{needUpdateMenuItems: boolean, type: string}}
   */
  setNeedUpdateMenuItems(needUpdateMenuItems = false) {
    return {
      type: MenuConstant.SET_NEED_UPDATE_MENU_ITEMS,
      needUpdateMenuItems,
    };
  }
}

const MenuAction = ActionFactory.get(MenuActionClass);
export default MenuAction;
