import {toast} from "react-toastify";

import AbstractQuoteService from "../AbstractService";
import i18n from "../../../../config/i18n";
import NumberService from '../../../NumberService';
import ConfigService from '../../../config/ConfigService';
import ProductStockService from '../../../product/stock/ProductStockService';
import ProductService from '../../../product/ProductService';

export default class AbstractAddProductService extends AbstractQuoteService {
  static className = 'AbstractAddProductService';


  /**
   * Get Add Qty
   * @param product
   * @param qty
   * @returns {number}
   */
  getAddQty(product, qty) {
    let addQty = qty;
    const minSaleQty = ProductStockService.getMinSaleQty();
    if (minSaleQty > addQty) {
      addQty = 0;
      const qtyIncrement = ProductStockService.getQtyIncrement();
      while (minSaleQty > addQty) {
        addQty += qtyIncrement;
      }
    }
    return addQty;
  }

    /**
     * Add product to quote
     *
     * @param {object} quote
     * @param {object} data
     * @return {*}
     */
  addProduct(quote, data, isShowErrorToastMessage = true) {
    const updateItem = quote.lineItems.find((item) => item.variant.id === data.variant.id && !item.appliedDiscount);
    const QuoteItemService = require('../QuoteItemService').default;
    let addedItemId = null;
    let totalItemsQtyIncart = 0;
    if (updateItem) {
      totalItemsQtyIncart = updateItem.quantity;
    } else {
      data.quantity = this.getAddQty(data.product, data.quantity);
    }
    let totalQty = parseFloat(data.quantity);
    totalQty = NumberService.addNumber(totalQty, totalItemsQtyIncart);
    const validateQty = this.validateQty(data.product, data.variant, data.quantity, totalQty);
    if (!validateQty.success) {
      if (isShowErrorToastMessage) {
        toast.error(i18n.t(validateQty.message));
      }
      return validateQty;
    }
    if (updateItem) {
      updateItem.product = data.product;
      updateItem.variant = data.variant;
      updateItem.quantity = NumberService.addNumber(updateItem.quantity, data.quantity);
      addedItemId = updateItem.id;
    } else {
      const item = {
        ...QuoteItemService.createItem(data, parseFloat(data.quantity), quote),
      };
      quote.lineItems.push(item);
      addedItemId = item.id;
    }

    return {
      success: true,
      quote,
      added_item_id: addedItemId,
    };
  }

    /**
     * Validate added qty
     *
     * @param {object} product
     * @param {object} variant
     * @param {number} qty
     * @param {number} totalQty
     * @return {object}
     */
  validateQty(product, variant, qty, totalQty) {
    if (!ProductService.isActive(product)) {
      return {
        success: false,
        message: i18n.t("The product status is inactive!"),
        cannotAddQty: totalQty,
      };
    }
    const inventoryLevel = ProductStockService.getInventoryLevel(variant);
    if (!inventoryLevel) {
      return {
        success: false,
        message: i18n.t("This product is not available in this location!"),
        cannotAddQty: totalQty,
      };
    }
    const availableQty = ProductStockService.getAvailableQty(variant);
    if (!availableQty && !ConfigService.isAllowToAddOutOfStockProduct() && ProductStockService.isManageStock(product)) {
      return {
        success: false,
        message: i18n.t(
          "{{name}} has no available qty in the location!",
          {
            name: product.title,
          },
        ),
        cannotAddQty: totalQty,
      };
    }
    const minSaleQty = ProductStockService.getMinSaleQty();
    if (minSaleQty > totalQty) {
      return {
        success: false,
        message: i18n.t("The minimum quantity of {{name}} to sell is {{qty}}!", {
          name: product.title,
          qty: minSaleQty,
        }),
        cannotAddQty: totalQty,
      };
    }
    let maxSaleQty = ProductStockService.getMaxSaleQty();
    if (ConfigService.isAllowToAddOutOfStockProduct() || !ProductStockService.isManageStock(product)) {
      maxSaleQty = Math.max(maxSaleQty, 0);
    } else {
      const productQty = ProductStockService.getAvailableQty(variant);
      maxSaleQty = Math.min(maxSaleQty, productQty);
    }

    if (totalQty > maxSaleQty) {
      return {
        success: false,
        message: i18n.t("The maximum quantity of {{name}} to sell is {{qty}}!", {
          name: product.title,
          qty: maxSaleQty,
        }),
        cannotAddQty: totalQty - Math.max(maxSaleQty, 0),
      };
    }
    return {success: true};
  }
}
