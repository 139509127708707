import PropTypes from "prop-types";
import React, {Fragment, useEffect} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import JsBarcode from 'jsbarcode';

import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import layout from "../../../framework/Layout";
import OrderService from "../../../service/order/OrderService";
import OrderConstant from "../../constant/OrderConstant";
import PaymentService from "../../../service/payment/PaymentService";

import HeaderReceipt from "./HeaderReceipt.jsx";

function ToPrintComponent(props) {
  const {orderData, storeInfo} = props;
  const {t} = useTranslation();
  const {isReprint} = props;
  useEffect(() => {
    updateBarcode(orderData);
  }, [orderData]);
  function getTemplate() {
    return getTemplateReceipt();
  }


  /**
   *  get template receipt
   *
   * @param {object} orderData
   * @return template receipt
   */
  function getTemplateReceipt() {
    const printedAt = moment().format('LLL');

    const classNameReprint = isReprint ? "reprint" : "hidden";

    const isRefunded = (orderData.displayFinancialStatus === OrderConstant.FINANCIAL_STATUS_PARTIALLY_REFUNDED) || (orderData.displayFulfillmentStatus === OrderConstant.FINANCIAL_STATUS_REFUNDED);

    const classNameRefunded = isRefunded ? "reprint" : "hidden";
    const starLine = "*****";
    const colonDisplay = ":";
    return (
      <>
        <div className="block-printreceipt">
          <HeaderReceipt orderData={orderData} storeInfo={storeInfo} />
          <div className="spacer" />
          <table>
            <thead>
              <tr>
                <th className="t-name">{t('Item')}</th>
                <th className="t-qty text-right">{t('Qty')}</th>
                <th className="t-price text-right">{t('Price')}</th>
                <th className="t-total text-right">{t('Subtotal')}</th>
              </tr>
            </thead>
            <tbody>
              {getTemplateItems(orderData?.lineItems?.items, orderData, null)}
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td colSpan="4">
                  <hr />
                </td>
              </tr>
              <div className="spacer" />
              {getTemplateSubtotal()}
              {getTemplateDiscount()}

              {layout('print')('template_receipt_layout')('discount_after')()(ToPrintComponent, orderData)}

              {getTemplateShipping()}
              {getTemplateTax()}
              {layout('print')('template_receipt')('tax_after')()(ToPrintComponent, orderData)}
              {getTemplateGrandTotal()}
              <div className="spacer" />


              {layout('print')('template_receipt_layout')('grand_total_after')()(ToPrintComponent, orderData)}

              {getTemplatePaymentArea(orderData)}
              {layout('print')('template_receipt')('payment_after')()(ToPrintComponent)}
            </tbody>
          </table>
          <br />
          <div className="text-center">
            <p>{t('Thank you for your purchase!')}</p>
            <svg id="barcode" />
            <div className={classNameReprint}>
              <span>{starLine}</span><strong>{t('REPRINT')}</strong><span>{starLine}</span>
            </div>
            <div className={classNameRefunded}>
              <span>{starLine}</span><strong>{t('REFUNDED')}</strong><span>{starLine}</span>
            </div>

            <div className="reprint">{t('Printed At')}{colonDisplay} {printedAt}</div>
          </div>

        </div>
      </>
    );
  }


    /**
     *
     * @param items
     * @param order
     * @returns {Array}
     */
  function getTemplateItems(items, order) {
    return (
      items?.map((item) => {
        return getTemplateItem(item, items, order);
      })
    );
  }

  /**
   *
   * @param item
   * @param items
   * @param order
   * @returns {*}
   */
  function getTemplateItem(item, items, order) {
    return getTemplateDetailItem(item, order);
  }

  /**
   * get template detail item
   * @param item
   * @param isChildren
   * @param order
   * @param isCreditMemoItem
   * @return {*}
   */
  function getTemplateDetailItem(item, order) {
    const originalUnitPrice = item?.originalUnitPriceSet?.shopMoney?.amount;
    const discountedTotalPrice = OrderService.calcDiscountedTotalLineItem(order, item);
    const discountedUnitPrice = discountedTotalPrice / item.quantity;
    const itemId = item.id;
    const name = item.name;
    const variantDisplay = item.variantTitle?.replaceAll(" /", ";");
    const classForName = "t-name";
    const classForVariantDisplay = "t-variant-name";

    const displayOriginalPrice = `(${OrderService.formatPrice(originalUnitPrice)})`;
    const classOriginalPrice = (originalUnitPrice === discountedUnitPrice) ? "hidden" : "";
    const price = OrderService.formatPrice(discountedUnitPrice);
    const rowTotal = OrderService.formatPrice(discountedTotalPrice);
    return (
      <Fragment key={`${itemId}itemWrap`}>
        <div className="spacer" />
        <Fragment key={itemId}>
          <tr>
            <td className={classForName}>
              {name}
              <div className={classForVariantDisplay}>{variantDisplay}</div>
            </td>
            <td className="t-qty text-right">{item.quantity}</td>
            <td className="t-price text-right">{price}
              <div className={classOriginalPrice}>{displayOriginalPrice}</div>
            </td>
            <td className="t-total text-right">
              <div>{rowTotal}</div>
            </td>
          </tr>
        </Fragment>
        {item?.refundedQuantity > 0 && getTemplateRefundedItem(item, order)}
        <div className="spacer" />
      </Fragment>
    );
  }


  /**
   *
   * @param item
   * @param order
   * @return {*}
   */
  function getTemplateRefundedItem(item, order) {
    const discountedTotalPrice = OrderService.calcDiscountedTotalLineItem(order, item);
    const discountedUnitPrice = discountedTotalPrice / item.quantity;
    const itemId = item.id;
    const qtyRefunded = item.refundedQuantity;
    const amountRefunded = discountedUnitPrice * qtyRefunded;
    if (qtyRefunded) {
      return (
        <Fragment key={`${itemId}-refund`}>
          <tr>
            <td className="t-name t-refund-label">{t('REFUNDED')}</td>
            <td className="t-qty text-right">
              {qtyRefunded}
            </td>
            <td className="t-price text-right">{OrderService.formatPrice(discountedUnitPrice)}</td>
            <td className="t-total text-right">{OrderService.formatPrice(amountRefunded)}</td>
          </tr>
        </Fragment>
      );
    } else {
      return null;
    }
  }

  /**
   *  get template payments from payments data order
   *
   * @return template payments
   */
  function getTemplatePayments() {
    const kindList = [OrderConstant.ORDER_TRANSACTION_KIND_SALE, OrderConstant.ORDER_TRANSACTION_KIND_CAPTURE];
    const statusList = [OrderConstant.ORDER_TRANSACTION_STATUS_SUCCESS];
    const payments = OrderService.getTransactionsByKindsAndStatus(orderData.transactions, kindList, statusList);
    const transactionsMapping = PaymentService.mapFromCodeToTitle(payments);
    if (payments && orderData.displayFinancialStatus !== OrderConstant.FINANCIAL_STATUS_PENDING) {
      return (
        transactionsMapping?.map((payment, index) => {
          if (!payment.amountSet.shopMoney.amount) {
            return null;
          }

          if (payment.amountSet.shopMoney.amount > 0) {
            return getTemplatePayment(payment, index, orderData);
          }

          return null;
        })
      );
    }
  }

  /**
   * get template payment from payment data
   * @param payment
   * @param index
   * @param order
   * @param titlePrefix
   * @returns {*}
   */
  function getTemplatePayment(payment, index, order, titlePrefix = '') {
    const paymentAmount = payment.amountSet.shopMoney.amount;
    const title = `${titlePrefix}${payment.formattedGateway}`;

    return getTemplateTotal(
        title,
        OrderService.formatPrice(paymentAmount),
        index.toString(), false, true,
    );
  }


  /**
   *  get template payment area  from data order
   *
   * @return template total due
   */
  function getTemplatePaymentArea(order) {
    const grandTotal = OrderService.calcGrandTotalOrder(order);
    if (grandTotal) {
      return (
        <>
          <tr>
            <td colSpan="4">
              <hr />
            </td>
          </tr>
          <div className="spacer" />
          {getTemplateTotalPaid()}
          {getTemplatePayments()}
          {getTemplateTotalDue()}
          {getTemplateTotalChange()}
          {getTemplateTotalRefunded()}
          <div className="spacer" />
          <tr>
            <td colSpan="4">
              <hr />
            </td>
          </tr>
        </>
      );
    }
  }

  /**
   *  get template total due from data order
   * @return template total due
   */
  function getTemplateTotalPaid() {
    const totalPaidDisplay = OrderService.getTotalReceivedDisplay(orderData);
    return getTemplateTotal(t('Paid'), totalPaidDisplay, 'Paid', true);
  }

  /**
   * get template total due from data order
   *
   * @return {template}
   */
  function getTemplateTotalDue() {
    const totalDue = OrderService.calcTotalDue(orderData);
    return totalDue ? getTemplateTotal(t('Due'), OrderService.formatPrice(totalDue), 'Due', true) : null;
  }

  /**
   *  get template total change from data order
   *
   * @param orderData
   * @return {template}
   */
  function getTemplateTotalChange() {
    const totalChange = OrderService.calcTotalChange(orderData);
    if (totalChange > 0) {
      const totalChangeDisplay = OrderService.formatPrice(totalChange);
      return getTemplateTotal(t('Change'), totalChangeDisplay, "Change", true);
    }
  }

  /**
   * get template total refunded
   * @param orderData
   */
  function getTemplateTotalRefunded() {
    const totalRefundTransactionAmount = OrderService.calcTotalRefundedValue(orderData);
    return totalRefundTransactionAmount ? getTemplateTotal(t('Total Refunded'), OrderService.formatPrice(totalRefundTransactionAmount), 'Total Refunded', true) : null;
  }

  /**
   * get template subtotal from data order
   *
   * @return {template}
   */
  function getTemplateSubtotal() {
    const subtotalDisplay = OrderService.formatPrice(OrderService.calcSubtotalOrder(orderData));
    return getTemplateTotal(t('Subtotal'), subtotalDisplay, "Subtotal");
  }

  /**
   *  get template discount from data order
   *
   * @return {template}
   */
  function getTemplateDiscount() {
    const discountTotalAmount = OrderService.calcDiscountOrder(orderData);
    return discountTotalAmount ? getTemplateTotal(t('Discount'), OrderService.formatPrice(discountTotalAmount), "Discount") : null;
  }

  /**
   * get template shipping from data order
   *
   * @return {template}
   */
  function getTemplateShipping() {
    const shippingAmount = orderData.totalShippingPriceSet?.shopMoney.amount;
    if (orderData.requiresShipping && shippingAmount) {
      const shippingDisplay = OrderService.formatPrice(OrderService.calcShippingOrder(orderData));
      return getTemplateTotal(t('Shipping'), shippingDisplay, "Shipping");
    } else {
      return null;
    }
  }


  /**
   * get template tax from data order
   *
   * @param orderData
   * @return {*}
   */
  function getTemplateTax() {
    if (orderData && !orderData.taxesIncluded && orderData?.totalTaxSet && (orderData?.totalTaxSet.shopMoney.amount !== 0)) {
      const taxDisplay = OrderService.formatPrice(OrderService.calcTaxOrder(orderData));
      return getTemplateTotal(t('Tax'), taxDisplay, "Tax");
    } else {
      return <></>;
    }
  }

  /**
   * get template grand total from data order
   *
   * @return {*}
   */
  function getTemplateGrandTotal() {
    const grandTotalDisplay = OrderService.formatPrice(OrderService.calcGrandTotalOrder(orderData));
    return getTemplateTotal(t('Grand Total'), grandTotalDisplay, "GrandTotal", true);
  }

  /**
   * get template total
   *
   * @param title
   * @param display
   * @param key
   * @param isBold
   * @param hasPadding
   * @param paddingParent
   * @return {*}
   */
  function getTemplateTotal(title, display, key, isBold, hasPadding = false, paddingParent = false) {
    const classParent = paddingParent ? "t-name t-bundle" : "";
    const className = hasPadding ? "t-name t-bundle" : "t-name";
    const titleResult = isBold ? <b>{title}</b> : title;
    const displayResult = isBold ? <b>{display}</b> : display;
    return (
      <Fragment key={key}>
        <tr>
          <td className={classParent} colSpan="3">
            <div className={className}>{titleResult}</div>
          </td>
          <td className="text-right">{displayResult}</td>
        </tr>
      </Fragment>
    );
  }

  function updateBarcode(order) {
    if (!order || !order.name || order.name.length === 0) {
      return;
    }
    JsBarcode("#barcode", order.name.substring(1), {
      font: 'Helvetica',
      fontSize: OrderConstant.BARCODE_FONT_SIZE,
      width: OrderConstant.BARCODE_WIDTH,
      height: OrderConstant.BARCODE_HEIGHT,
      displayValue: true,
    });
  }

  function template() {
    return (
      <div className="">
        {getTemplate()}
      </div>
    );
  }
  return (
    <>
      {template()}
    </>
  );
}

ToPrintComponent.propTypes = {
  orderData: PropTypes.object,
  isReprint: PropTypes.bool,
  storeInfo: PropTypes.object,
};


ToPrintComponent.className = "ToPrintComponent";
export default FunctionComponentFactory.get(ToPrintComponent);
