import {Button, Modal} from 'react-bootstrap';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import SessionAction from '../../../action/SessionAction';

export function ModalNoPermission() {
  const {t} = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(true);
  const dispatch = useDispatch();
  const confirm = () => {
    setIsOpenModal(false);
    dispatch(SessionAction.setTypeSessionPopup(''));
  };
  return (
    <Modal
      size="sm"
      className="popup-messages"
      show={isOpenModal} onHide={() => confirm()}
    >
      <Modal.Body>
        <h3 className="title">{t('No permission')}</h3>
        <p>{t('You don\'t have the permission to open the session, please contact the store manager!')}</p>
      </Modal.Body>
      <Modal.Footer className="close-modal">
        <Button onClick={() => confirm()}>{t('OK')}</Button>
      </Modal.Footer>
    </Modal>
  );
}
ModalNoPermission.className = 'ModalNoPermissionComponent';
ModalNoPermission.propTypes = {};
export default FunctionComponentFactory.get(ModalNoPermission);
