import ActionFactory from '../../framework/factory/ActionFactory';
import ScanConstant from '../constant/ScanConstant';

export class ScanActionClass {

  /**
   * Set barcode string
   *
   * @param barcodeString
   * @returns {{type: string, barcodeString: string}}
   */
  setBarcodeString(barcodeString = "") {
    return {
      type: ScanConstant.SET_BARCODE_STRING,
      barcodeString,
    };
  }

  /**
   * Set Scan Page
   *
   * @param scanPage
   * @returns {{scanPage: string, type: string}}
   */
  setScanPage(scanPage = "") {
    return {
      type: ScanConstant.SET_SCAN_PAGE,
      scanPage,
    };
  }
}

/**
 * @type {ScanActionClass}
 */
const ScanAction = ActionFactory.get(ScanActionClass);
export default ScanAction;
