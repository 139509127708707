import {combineReducers} from 'redux';

import CustomerListReducer from './customer/CustomerListReducer';
import CustomerOnCartReducer from './customer/CustomerOnCartReducer';
import CustomerPopupReducer from './customer/CustomerPopupReducer';
import CustomerAddressReducer from './customer/CustomerAddressReducer';


export default combineReducers({
  customerList: CustomerListReducer,
  customerOnCart: CustomerOnCartReducer,
  customerPopupReducer: CustomerPopupReducer,
  customerAddressReducer: CustomerAddressReducer,
});
