import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {cloneDeep} from 'lodash';

import FunctionComponentFactory
  from '../../../../../../framework/factory/FunctionComponentFactory';
import ProductAction from '../../../../../action/ProductAction';
import ProductService from '../../../../../../service/product/ProductService';
import DropdownOption from '../option/Dropdown.jsx';
import CurrencyService from '../../../../../../service/CurrencyService';
import ProductStockService from '../../../../../../service/product/stock/ProductStockService';
import NumberService from '../../../../../../service/NumberService';
import ConfigService from '../../../../../../service/config/ConfigService';
import AddProductService from '../../../../../../service/checkout/quote/AddProductService';
import QuoteAction from '../../../../../action/checkout/QuoteAction';
import StockAction from '../../../../../action/StockAction';

function ConfigurableProduct(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const acceptKeys = useMemo(
    () => ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.', ',', 'backspace', 'delete', 'enter'],
    [],
  );
  const {closePopup} = props;

  const {product, productOptions, isLoading, variant} = useSelector((state) => state.core.product.viewProduct);
  const {quote} = useSelector((state) => state.core.checkout);

  const modalBody = useRef();
  const addToCartButton = useRef();

  const [configOptions, setConfigOptions] = useState({});
  const [enableAddToCartButton, setEnableAddToCartButton] = useState(false);
  const [currentQty, setCurrentQty] = useState('');
  const [decimalSymbol, setDecimalSymbol] = useState('.');
  const [price, setPrice] = useState(0);
  const [numpadLeft, setNumpadLeft] = useState(0);
  const [numpadTop, setNumpadTop] = useState(0);
  const [showNumpad, setShowNumpad] = useState(false);
  const [isSelectedAllOptions, setIsSelectedAllOptions] = useState(false);

  const getMinimumValidQty = () => {
    return ProductStockService.getQtyIncrement();
  };

  const maxValidQty = useMemo(() => {
    if (!variant) {
      return 0;
    }
    const cartItemQtys = AddProductService.getProductTotalItemsQtyInCart(null, quote, variant.id);
    const availableQty = ProductStockService.getAvailableQty(variant);
    const maxQty = NumberService.minusNumber(availableQty, cartItemQtys);
    if (maxQty < 0) {
      return 0;
    }
    return maxQty;
  }, [quote, variant]);

  const calculateNumpadPosition = (event) => {
    if (modalBody.current) {
      if (modalBody.current.offsetWidth > 0) {
        const newNumpadLeft = (modalBody.current.offsetWidth + modalBody.current.getBoundingClientRect().left - 20);
        if (numpadLeft !== newNumpadLeft) {
          setNumpadLeft(newNumpadLeft);
        }
      }
    }
    setNumpadTop(event.target.getBoundingClientRect().top - 110);
  };

  /**
   * Show numpad when click qty field
   */
  const handleShowNumpad = (event) => {
    calculateNumpadPosition(event);
    if (variant) {
      setShowNumpad(true);
    }
  };

  /**
   * Hide numpad when click anywhere except it-self
   */
  const hideNumpad = useCallback(() => {
    setShowNumpad(false);
    const minQty = getMinimumValidQty();
    if (
      isNaN(currentQty) ||
      currentQty === '' ||
      currentQty < minQty
    ) {
      setCurrentQty(String(minQty));
    }
  }, [currentQty]);

  const getDecimalSeparator = () => {
    let number = 1.1;
    number = number.toLocaleString().substring(1, 2);
    return number;
  };

  useEffect(() => {
    setDecimalSymbol(getDecimalSeparator());
  }, []);

  useEffect(() => {
    if (!variant) {
      setPrice(0);
      setCurrentQty('');
      return;
    }
    setPrice(Number(variant.price));
    setCurrentQty(String(getMinimumValidQty()));
    setEnableAddToCartButton(true);
  }, [variant]);

  useEffect(() => {
    if (!configOptions || Object.keys(configOptions).length === 0) {
      return;
    }
    let isSelectAllOptions = true;
    const selectOptions = [];
    productOptions.forEach(
      (productOption) => {
        if (configOptions[productOption.id]) {
          selectOptions.push(
            {
              id: productOption.id,
              value: configOptions[productOption.id],
            },
          );
        } else {
          isSelectAllOptions = false;
        }
      },
    );
    setIsSelectedAllOptions(isSelectAllOptions);
    if (isSelectAllOptions) {
      dispatch(ProductAction.getProductVariantByProductOptions(product, selectOptions));
    } else {
      setCurrentQty('');
    }
  }, [configOptions, product]); /* eslint-disable-line react-hooks/exhaustive-deps */

  const clickConfigOption = (optionId, value) => {
    setConfigOptions(
      {
        ...configOptions,
        [optionId]: value,
      },
    );
  };

  /**
   * Handle close popup
   */
  const handleClosePopup = () => {
    setConfigOptions({});
    setEnableAddToCartButton(false);
    setCurrentQty('');
    setPrice(0);
    setShowNumpad(false);
    setIsSelectedAllOptions(false);
    closePopup();
  };

  /**
   * Validate qty before add
   *
   * @param quantity
   * @return {boolean}
   */
  const validateQty = useCallback((quantity) => {
    const qty = parseFloat(quantity);
    const cartItemQtys = AddProductService.getProductTotalItemsQtyInCart(null, quote, variant.id);
    const totalQtys = NumberService.addNumber(qty, cartItemQtys);
    const result = AddProductService.getAddProductService(product).validateQty(product, variant, qty, totalQtys);
    if (result.success === false) {
      toast.error(t(result.message));
      return false;
    }
    return true;
  }, [quote, variant, product, t]);

  const clickNumpad = useCallback((character) => {
    if (!showNumpad) {
      return;
    }
    let newCurrentQty = currentQty;
    const key = character ? character.toLowerCase() : "";
    const isDecimalProduct = ProductStockService.isQtyDecimal();
    if (acceptKeys.indexOf(key) !== -1) {
      if (key === 'backspace' || key === 'delete') {
        newCurrentQty = newCurrentQty.substring(0, newCurrentQty.length - 1);
      } else if ((key === '.' || key === ',') && isDecimalProduct) {
        if (newCurrentQty.indexOf('.') === -1) {
          newCurrentQty += '.';
        }
      } else if (key !== 'enter' && key !== '.' && key !== ',') {
        newCurrentQty += key;
      } else {
        hideNumpad();
        return;
      }
      if (newCurrentQty === '' || isNaN(newCurrentQty)) {
        setCurrentQty('');
        return;
      }
      const validateQtyResult = validateQty(newCurrentQty);
      if (!validateQtyResult) {
        if (newCurrentQty > maxValidQty) {
          newCurrentQty = maxValidQty;
        } else if (newCurrentQty < getMinimumValidQty()) {
          newCurrentQty = getMinimumValidQty();
        }
      }
      setCurrentQty(String(newCurrentQty));
    }
  }, [showNumpad, currentQty, acceptKeys, validateQty, hideNumpad, maxValidQty]);

  const handleOnKeyupEvent = useCallback((event) => {
    clickNumpad(event.key);
  }, [clickNumpad]);

  useEffect(() => {
    document.body.addEventListener('keyup', handleOnKeyupEvent);
    return () => {
      document.body.removeEventListener('keyup', handleOnKeyupEvent);
    };
  }, [handleOnKeyupEvent]);

  const minusQty = () => {
    if (!variant) {
      return;
    }
    let qty = Number(currentQty);
    const inventoryLevel = ProductStockService.getInventoryLevel(variant);
    if (!inventoryLevel) {
      return;
    }
    const qtyIncrement = ProductStockService.getQtyIncrement();
    qty = NumberService.minusNumber(qty, qtyIncrement);
    if (qty < getMinimumValidQty()) {
      qty = getMinimumValidQty();
    }
    const validateQtyResult = validateQty(qty);
    if (!validateQtyResult) {
      if (qty > maxValidQty && !ConfigService.isAllowToAddOutOfStockProduct()) {
        qty = maxValidQty;
      }
    }
    setCurrentQty(String(qty));
  };

  const addQty = () => {
    if (!variant) {
      return;
    }
    let qty = Number(currentQty);
    const inventoryLevel = ProductStockService.getInventoryLevel(variant);
    if (!inventoryLevel) {
      return;
    }
    const qtyIncrement = ProductStockService.getQtyIncrement();
    qty = NumberService.addNumber(qty, qtyIncrement);
    const validateQtyResult = validateQty(qty);
    if (!validateQtyResult) {
      if (qty < getMinimumValidQty()) {
        qty = getMinimumValidQty();
      }
      if (qty > maxValidQty && !ConfigService.isAllowToAddOutOfStockProduct()) {
        qty = maxValidQty;
      }
    }
    setCurrentQty(String(qty));
  };

  const checkExternalStock = () => {
    if (!variant || !ProductService.isAllowCheckExternalStock()) {
      return;
    }
    dispatch(StockAction.showExternalStock(product, configOptions, true));
  };

  const handleAddToCart = () => {
    if (!variant || !isSelectedAllOptions) {
      toast.error(t('Please choose all option.'));
      return false;
    }
    const validateQtyResult = validateQty(currentQty);
    if (!validateQtyResult) {
      return false;
    }
    const addToQuoteData = {
      product: cloneDeep(product),
      variant: cloneDeep(variant),
      quantity: Number(currentQty),
    };
    dispatch(QuoteAction.addProduct(addToQuoteData));
    handleClosePopup();
  };

  const getAvailableQty = () => {
    if (variant && ProductStockService.getInventoryLevel(variant)) {
      if (!ProductStockService.isManageStock(product)) {
        return t("No Manage Stock");
      }

      return ProductStockService.getAvailableQty(variant);
    }
    return "";
  };

  const getProductOptionsTemplate = () => {
    if (!productOptions) {
      return null;
    }
    return productOptions.map((option) => {
      return (
        <DropdownOption
          key={option.id}
          clickConfigOption={clickConfigOption}
          configOptions={configOptions}
          option={option}
        />
      );
    });
  };

  const getNumpadComponent = () => {
    if (!showNumpad ||
      (!ConfigService.isAllowToAddOutOfStockProduct() && maxValidQty === 0)
    ) {
      if (showNumpad) {
        setShowNumpad(false);
      }
      return null;
    }
    const decimalButton = variant && ProductStockService.isQtyDecimal()
      ? (
        <li onClick={() => clickNumpad(decimalSymbol)} aria-hidden="true">
          <span>{decimalSymbol}</span>
        </li>
      )
      : <li />;
    return (
      <>
        <div
          className="modal-backdrop fade in show popover-backdrop popover-backdrop_option"
          style={{position: 'absolute'}}
          onClick={hideNumpad}
          aria-hidden="true"
        />
        <div
          className="popover fade right in show" role="tooltip" id="numpad-configure-product"
          style={{
            top: `${numpadTop}px`,
            left: `${numpadLeft}px`,
            position: 'absolute',
          }}
        >
          <div className="arrow" style={{top: '50%'}} />
          <div className="popover-content">
            <div className="popup-calculator">
              <ul className="list-number">
                <li onClick={() => clickNumpad('1')} aria-hidden="true">{t("1")}</li>
                <li onClick={() => clickNumpad('2')} aria-hidden="true">{t("2")}</li>
                <li onClick={() => clickNumpad('3')} aria-hidden="true">{t("3")}</li>
                <li onClick={() => clickNumpad('4')} aria-hidden="true">{t("4")}</li>
                <li onClick={() => clickNumpad('5')} aria-hidden="true">{t("5")}</li>
                <li onClick={() => clickNumpad('6')} aria-hidden="true">{t("6")}</li>
                <li onClick={() => clickNumpad('7')} aria-hidden="true">{t("7")}</li>
                <li onClick={() => clickNumpad('8')} aria-hidden="true">{t("8")}</li>
                <li onClick={() => clickNumpad('9')} aria-hidden="true">{t("9")}</li>
                {decimalButton}
                <li onClick={() => clickNumpad('0')} aria-hidden="true">{t("0")}</li>
                <li className="clear-number" aria-hidden="true" onClick={() => clickNumpad('delete')} />
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  };

  const getDisplayPrice = () => {
    if (!variant) {
      return '';
    }
    return CurrencyService.format(price);
  };

  const loaderClass = isLoading ? "loader-product" : "";
  const checkExternalClassName = (variant && ProductService.isAllowCheckExternalStock()) ? "value product-check-avail" : "value";

  return (
    <>
      <div
        className="modal-content"
        style={{
          display: product && ProductService.isComposite(product) ? "" : "none",
        }}
      >
        <div className="modal-header">
          <button
            type="button" className="cancel" data-dismiss="modal"
            onClick={() => handleClosePopup()}
          >
            {t('Cancel')}
          </button>
          <h4 className="modal-title">{ProductService.getProductName(product)}</h4>
        </div>
        <div className="modal-body" ref={modalBody}>
          <div className="product-options-wrapper">
            {getProductOptionsTemplate()}
          </div>
          <div className={loaderClass} />
          <div className={`product-info-price${isLoading ? " hidden" : ""}`}>
            <span className="label">{t('Price')}</span>
            <span className="value">
              {getDisplayPrice()}
            </span>
          </div>
          <div className={`product-avail-qty${isLoading ? " hidden" : ""}`}>
            <span className="label">{t('Avail Qty')}</span>
            <span className={checkExternalClassName} onClick={checkExternalStock} aria-hidden="true">
              {getAvailableQty()}
            </span>
          </div>
        </div>
        <div className="modal-bottom">
          <div className="product-field-qty">
            <div className={`box-field-qty ${variant ? "" : "disabled"}`}>
              <span className="form-control qty" onClick={handleShowNumpad} aria-hidden="true">
                {currentQty}
              </span>
              <div
                className="btn-number qtyminus"
                data-field="qty"
                onClick={minusQty}
                aria-hidden="true"
              >
                {t("-")}
              </div>
              <div
                className="btn-number qtyplus"
                data-field="qty"
                onClick={addQty}
                aria-hidden="true"
              >
                {t("+")}
              </div>
            </div>
          </div>
          <button
            type="button" ref={addToCartButton}
            className={`addtocart btn-default ${(enableAddToCartButton ? '' : 'disabled')}`}
            onClick={handleAddToCart}
          >
            {t('Add to Cart')}
          </button>
          {getNumpadComponent()}
        </div>
      </div>
    </>
  );
}

ConfigurableProduct.className = 'ConfigurableProduct';
ConfigurableProduct.propTypes = {
  closePopup: PropTypes.func.isRequired,
};

export default React.memo(FunctionComponentFactory.get(ConfigurableProduct));
