import {toast} from 'react-toastify';
import _ from 'lodash';

import ActionFactory from '../../framework/factory/ActionFactory';
import ShippingConstant from '../constant/ShippingConstant';
import ShippingService from '../../service/shipping/ShippingService';
import i18n from '../../config/i18n';
import AddressService from '../../service/customer/AddressService';
import CustomerService from '../../service/customer/CustomerService';

import QuoteAction from './checkout/QuoteAction';


export class ShippingActionClass {
  className = 'ShippingAction';

  /**
   * Show shipping popup
   * @returns {{type: string}}
   */
  showShippingPopup() {
    return {
      type: ShippingConstant.SHOW_SHIPPING_POPUP,
    };
  }

  /**
   * Hide shipping popup
   * @returns {{type: string}}
   */
  hideShippingPopup() {
    return {
      type: ShippingConstant.HIDE_SHIPPING_POPUP,
    };
  }

  /**
   * Show address listing
   * @returns {{type: string}}
   */
  getAddressListing(customerId) {
    return async (dispatch, getState) => {
      dispatch({
        type: ShippingConstant.GET_ADDRESS_LISTING,
        customerId,
      });
      try {
        const response = await ShippingService.getAddressListingByCustomerId(customerId);

        const {quote = {}} = getState().core.checkout;

        const newQuote = _.clone(quote);
        if (response && response.data && response.data.getCustomer) {
          dispatch(this.getAddressListingSuccess(response.data.getCustomer));

          if (newQuote?.shippingAddress?.id) {
            dispatch(this.selectAddress(newQuote?.shippingAddress));
          }

          const line = newQuote.shippingLine || {};
          const shippingMethod = {
            handle: line.shippingRateHandle,
            title: line.title,
            price: {
              amount: line.price,
            },
          };

          dispatch(this.setSelectedShippingMethod(shippingMethod));

          if (ShippingService.isCustomerDefaultShippingAddress(newQuote)) {
            newQuote.shippingAddress = newQuote?.customer?.defaultAddress;
          }

          dispatch(this.setShippingMethod(newQuote));
        }
      } catch (error) {
        dispatch(this.getAddressListingError(error.message));
      }
    };
  }

  /**
   * Show address listing
   * @param customer
   * @returns {{addresses: ([*]|string[]|*), type: string, defaultAddress: *}}
   */
  getAddressListingSuccess(customer) {
    return {
      type: ShippingConstant.GET_ADDRESS_LISTING_SUCCESS,
      addresses: customer.addresses,
      defaultAddress: customer.defaultAddress,
    };
  }

  /**
   * Get Address Listing Guest
   * @returns
   */
  getAddressListingGuest() {
    return (dispatch, getState) => {
      const {quote = {}} = getState().core.checkout;
      const newQuote = _.clone(quote);

      if (ShippingService.isGuestDefaultShippingAddress(newQuote)) {
        newQuote.shippingAddress = null;
      }

      if (newQuote.shippingAddress) {
        const addressTemp = {
          addresses: [newQuote?.shippingAddress],
          defaultAddress: newQuote.shippingAddress || [],
        };
        dispatch(this.getAddressListingSuccess(addressTemp));
      }

      if (newQuote?.shippingAddress?.id) {
        dispatch(this.selectAddress(_.cloneDeep(newQuote?.shippingAddress)));
      }

      const line = newQuote.shippingLine || {};
      const shippingMethod = {
        handle: line.shippingRateHandle,
        title: line.title,
        price: {
          amount: line.price,
        },
      };

      dispatch(this.setSelectedShippingMethod(shippingMethod));
      dispatch(this.setShippingMethod(newQuote));
    };
  }

  /**
   * Select address
   * @param address
   * @returns {{address, type: string}}
   */
  selectAddress(address) {
    return {
      type: ShippingConstant.SELECT_ADDRESS,
      address,
    };
  }

  /**
   * show address listing error
   * @returns {{type: string}}
   */
  getAddressListingError(message) {
    toast.error(
      i18n.t(message),
      {
        className: 'wrapper-messages messages-warning',
      },
    );
    return {
      type: ShippingConstant.GET_ADDRESS_LISTING_ERROR,
      message,
    };
  }

  /**
   * Show shipping address popup
   * @returns {{type: string}}
   */
  showShippingAddressPopup() {
    return {
      type: ShippingConstant.SHOW_SHIPPING_ADDRESS_POPUP,
    };
  }

  /**
   * Hide shipping address popup
   * @returns {{type: string}}
   */
  hideShippingAddressPopup() {
    return {
      type: ShippingConstant.HIDE_SHIPPING_ADDRESS_POPUP,
    };
  }

  /**
   * Save shipping address popup
   * @returns {{type: string}}
   */
  saveShippingAddressPopup(address) {
    return async (dispatch, getState) => {
      const currentCustomerId = getState().core.shipping.shippingPopup.currentCustomerId;
      const currentAddress = getState().core.shipping.shippingPopup.addresses;

      const {quote = {}} = getState().core.checkout;

      const newQuote = _.cloneDeep(quote);

      const currentDefaultAddress = getState().core.shipping.shippingPopup.defaultAddress;
      let newAddress = _.cloneDeep(address);
      newAddress.id = AddressService.generateUnsavedId();
      newAddress = AddressService.convertAddressInFormToAddressListing(newAddress);
      let defaultAddress = null;
      if (newAddress.is_default_address) {
        defaultAddress = newAddress;
      } else {
        defaultAddress = currentDefaultAddress;
      }
      if (AddressService.isAddressExist(newAddress, currentAddress)) {
        dispatch(this.saveShippingAddressPopupFail('This address has existed'));
      } else if (currentCustomerId) {
        dispatch({
          type: ShippingConstant.SAVE_NEW_ADDRESS_WITH_CUSTOMER,
          currentCustomerId,
        });
        let newCurrentAddress = _.cloneDeep(currentAddress);
        if (newCurrentAddress && newCurrentAddress.length) {
          newCurrentAddress.push(newAddress);
        } else {
          newCurrentAddress = [newAddress];
        }
        try {
          const response = await CustomerService.saveCustomer(
            {
              id: currentCustomerId,
              addresses: newCurrentAddress,
              defaultAddress,
            },
          );
          if (response && response.data && response.data.saveCustomer && response.data.saveCustomer.userErrors && response.data.saveCustomer.userErrors.length) {
            const errorMessages = [];
            response.data.saveCustomer.userErrors.forEach(
                (error) => {
                  if (error.field === null) {
                    errorMessages.push(error.message);
                  }
                },
              );
            if (errorMessages.length) {
              dispatch(this.saveShippingAddressPopupFail(errorMessages.join(', ')));
            }
          } else {
            dispatch(this.saveShippingAddressPopupSuccess(response.data.saveCustomer.customer.addresses, response.data.saveCustomer.customer.defaultAddress));

            newQuote.shippingAddress = {...newAddress};
            dispatch(this.setShippingMethod(newQuote));

            const addressSelected = AddressService.isAddressExist(newAddress, response.data.saveCustomer.customer.addresses);
            if (addressSelected) {
              dispatch(this.selectAddress(addressSelected));
            }
          }
        } catch (error) {
          dispatch(this.saveShippingAddressPopupFail(error.message));
        }

      } else {
        newQuote.shippingAddress = {...newAddress};
        dispatch(this.setShippingMethod(newQuote));

        dispatch({
          type: ShippingConstant.SAVE_NEW_ADDRESS_AS_GUEST,
          address: newAddress,
          defaultAddress,
        });
      }
    };
  }

  /**
   * Save success
   * @param addresses
   * @param defaultAddress
   * @returns {{addresses, type: string, defaultAddress}}
   */
  saveShippingAddressPopupSuccess(addresses, defaultAddress) {
    return {
      type: ShippingConstant.SAVE_NEW_ADDRESS_WITH_CUSTOMER_SUCCESS,
      addresses,
      defaultAddress,
    };
  }

  /**
   * Save shipping address fail
   * @param message
   * @returns {{type: string}}
   */
  saveShippingAddressPopupFail(message) {
    toast.error(
      i18n.t(message),
    );
    return {
      type: ShippingConstant.SAVE_NEW_ADDRESS_WITH_CUSTOMER_FAIL,
    };
  }

  /**
   * Set Shipping Method
   * @param {*} newQuote
   * @returns {{type: string}}
   */
  setShippingMethod(newQuote) {
    return async (dispatch) => {
      try {
        dispatch({
          type: ShippingConstant.SHOW_LOADING_SHIPPING_METHOD,
        });
        const methods = await ShippingService.getShippingMethod(newQuote) || [];

        dispatch(this.setShippingMethodSuccess(methods));
        dispatch(this.setHideLoadingShippingMethod());
      } catch (error) {
        throw new Error(`Error shipping method: {{error}}`, {error});
      }
    };

  }

  /**
   * Set shipping method success
   * @param {*} methods
   * @returns {object}
   */
  setShippingMethodSuccess(methods) {
    return {
      type: ShippingConstant.SET_SHIPPING_METHOD_SUCCESS,
      shippingMethods: methods,
    };
  }

  /**
   * Set Hide Loading Shipping Method
   * @returns {object}
   */
  setHideLoadingShippingMethod() {
    return {
      type: ShippingConstant.HIDE_LOADING_SHIPPING_METHOD,
    };
  }

  /**
   * Set Selected Shipping Method
   * @param {*} selectedShippingMethod
   * @returns {object}
   */
  setSelectedShippingMethod(selectedShippingMethod) {
    return {
      type: ShippingConstant.SET_SELECTED_SHIPPING_METHOD,
      selectedShippingMethod,
    };
  }

  /**
   * Save Shipping
   * @returns {Promise}
   */
  saveShipping() {
    return (dispatch, getState) => {
      const {selectedAddress, selectedShippingMethod} = getState().core.shipping.shippingPopup;
      const {quote} = getState().core.checkout;

      const newQuote = _.clone(quote);
      newQuote.shippingAddress = _.cloneDeep(selectedAddress);
      newQuote.shipping_method = selectedShippingMethod;

      const shippingLine = {
        price: `${selectedShippingMethod?.price?.amount}`,
        shippingRateHandle: selectedShippingMethod?.handle,
        title: selectedShippingMethod?.title,
      };

      newQuote.shippingLine = {
        ...newQuote.shippingLine,
        ...shippingLine,
      };

      dispatch(QuoteAction.setQuote({...newQuote}));

      const newQuoteTemp = _.cloneDeep(newQuote);
      if (newQuoteTemp.billingAddress) {
        delete newQuoteTemp.billingAddress?.is_default_address;
      }

      if (newQuoteTemp.shippingAddress) {
        delete newQuoteTemp.shippingAddress?.is_default_address;
      }

      dispatch(QuoteAction.checkoutQuote(newQuoteTemp));

      dispatch(this.hideShippingPopup());

    };
  }
}

const ShippingAction = ActionFactory.get(ShippingActionClass);
export default ShippingAction;
