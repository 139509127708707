import AbstractFactory from "./AbstractFactory";

export class ResourceModelFactoryClass extends AbstractFactory {

  /**
   * get target resource model to use
   *
   * @param object
   * @return {*}
   */
  get(object) {
    return this.getObject(`resource_model`, object);
  }
}

const resourceModelFactory = new ResourceModelFactoryClass();
export default resourceModelFactory;
