import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Button, Modal, Spinner} from "react-bootstrap";
import PropTypes from "prop-types";
import {cloneDeep} from "lodash";

import FunctionComponentFactory from "../../../../framework/factory/FunctionComponentFactory";
import i18n from "../../../../config/i18n";
import OrderService from "../../../../service/order/OrderService";
import CheckoutPaymentAction from "../../../action/checkout/payment/CheckoutPaymentAction";
import CreateShipment from "../../take-shipment/view/component/checkout/CreateShipment.jsx";
import OrderAction from '../../../action/OrderAction';
import ErrorCompletePopup from "../../order/order-complete/ErrorCompletePopup.jsx";

import CompletePaymentItem from "./CompletePaymentItem.jsx";

function CompletePaymentCheckout(props) {
  const dispatch = useDispatch();
  const {listPaid, isLoading} = useSelector((state) => state.core.checkout.payment);
  const {quote} = useSelector((state) => state.core.checkout);
  const {isProcessingOrder} = useSelector((state) => state.core.order.orderComplete);

  const {canFulfillAllItems} = useSelector((state) => state.core.checkout.fulfill);
  const [isCreateShipment, setIsCreateShipment] = useState(canFulfillAllItems);
  const canChangeFulfillToggle = canFulfillAllItems;
  const {carts, activeCartId} = useSelector((state) => state.core.multiCart);

  const {isOpenErrorCompletePopup} = useSelector((state) => state.core.order.orderComplete);

  const fulfillAllItemsChange = (event) => {
    if (!canChangeFulfillToggle) {
      return;
    }
    setIsCreateShipment(event.target.checked);
  };
  useEffect(() => {
    if (!canFulfillAllItems) {
      setIsCreateShipment(false);
    }
  }, [canFulfillAllItems, setIsCreateShipment]);


  let title;
  let balance = props.grandTotal;
  let totalPaid = 0;
  listPaid.forEach((paid) => {
    balance -= parseFloat(paid.amount);
    totalPaid += parseFloat(paid.amount);
  });

  if (balance >= 0) {
    title = 'Remaining';
  } else {
    title = 'Change';
  }
  const [isOpenModal, setIsOpenModel] = useState(false);

  const buttonTitle = i18n.t(balance > 0 ? i18n.t('Mark as Partial') : i18n.t('Complete Order'));
  const modalContent = i18n.t(`{{totalPaidDisplay}} has been paid towards the {{grandTotalDisplay}} total of this order. Press ‘Complete Order’ to continue.`, {
    totalPaidDisplay: OrderService.formatPrice(totalPaid),
    grandTotalDisplay: OrderService.formatPrice(props.grandTotal),
  });

  const listPaidView = listPaid.length > 0
    ? listPaid.map((paid, index) => {
      return (<CompletePaymentItem key={`${paid.selectedPayment.code}-${paid.amount}`} paid={paid} balance={balance} index={index} quote={quote} />);
    }) : null;

  const checkHasPayLater = () => {
    let result = false;
    for (const paid of listPaid) {
      if (paid.selectedPayment.use_pay_later && parseFloat(paid.selectedPayment.use_pay_later) !== 0) {
        result = true;
      }
    }

    return result;
  };

  const addPayment = (balance > 0 && !checkHasPayLater()) ? (
    <div
      role="presentation" className="payment-full-amount add-payment" onClick={() => {
        dispatch(CheckoutPaymentAction.setRemaining(balance));
        dispatch(CheckoutPaymentAction.handleOpenListPayment());
      }}
    >
      <div className="info">
        <span className="label">{i18n.t('Add Payment')}</span>
      </div>
      <span className="add-cash" />
    </div>
  ) : null;

  const buttonAcceptClassName = `btn btn-default btn-complete ${(isLoading || isProcessingOrder) ? 'disabled' : ''}`;

  /**
   * Cancel popup
   */
  const cancelPopup = () => {
    setIsOpenModel(false);
  };

  const handleCreateOrder = (isComfirmedOrderCompleteError = false) => {
    if (balance > 0 && !isOpenModal && !isComfirmedOrderCompleteError) {
      setIsOpenModel(true);
    } else {
      dispatch(OrderAction.completeOrder(cloneDeep(quote), isCreateShipment, listPaid, carts, activeCartId, isComfirmedOrderCompleteError));
      setIsOpenModel(false);
    }
  };

  const buttonCompleteOrderContent = isProcessingOrder ? <Spinner animation="border" variant="light" /> : buttonTitle;
  return (
    <>
      <div className="block-content has-create-shipment-button" data-scrollbar>
        <ul className="payment-total">
          <li className="total">
            <span className="label">{i18n.t(`${title}`)}</span>
            <span className="value">{OrderService.formatPrice(balance < 0 ? balance * -1 : balance)}</span>
          </li>
        </ul>
        <>
          {listPaidView}
          {addPayment}
        </>
      </div>
      <CreateShipment isCreateShipment={isCreateShipment} fulfillAllItemsChange={fulfillAllItemsChange} canChange={canChangeFulfillToggle} />
      <div className="block-bottom">
        <div className="actions-accept" id="order-actions-accept">
          <button
            className={buttonAcceptClassName}
            type="button"
            data-toggle="modal"
            data-target="#popup-completeOrder"
            onClick={() => { handleCreateOrder(); }}
          >
            {buttonCompleteOrderContent}
          </button>
        </div>
      </div>
      <>
        <Modal
          size="sm"
          className="popup-messages popup-partial-payment"
          show={isOpenModal} onHide={() => cancelPopup()}
        >
          <Modal.Body>
            <h3 className="title">{i18n.t('Payment Incomplete')}</h3>
            <p className="description-message">{modalContent}</p>
          </Modal.Body>
          <Modal.Footer className="modal-footer actions-2column">
            <Button className="modal-button-payment" onClick={() => cancelPopup()}>{i18n.t('Cancel')}</Button>
            <Button className="modal-button-payment" onClick={() => { handleCreateOrder(); }}>{i18n.t('Complete Order')}</Button>
          </Modal.Footer>
        </Modal>
      </>
      {isOpenErrorCompletePopup && <ErrorCompletePopup handleCreateOrder={handleCreateOrder} />}
    </>
  );
}

CompletePaymentCheckout.className = 'CompletePaymentCheckout';
CompletePaymentCheckout.propTypes = {
  grandTotal: PropTypes.number,
};
export default FunctionComponentFactory.get(CompletePaymentCheckout);
