import {combineReducers} from 'redux';

import loginReducer from './staff/LoginReducer';
import logoutReducer from './staff/LogoutReducer';
import continueLoginReducer from './staff/ContinueLoginReducer';

export default combineReducers({
  loginReducer,
  logoutReducer,
  continueLoginReducer,
});
