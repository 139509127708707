import React, {Fragment} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import FunctionComponentFactory from '../../../../../framework/factory/FunctionComponentFactory';
import "../../../../../assets/OrderCancel.css";
import "../../../../../assets/Order.css";
import ConflictOrderDiscountAction from '../../../../action/checkout/conflict-discount/ConflictOrderDiscountAction';

const ConflictCustomOrderDiscountComponent = () => {
  const {isOpenConflictCustomOrderDiscount} = useSelector((state) => state.core.checkout.conflictDiscount);
  const {quote} = useSelector((state) => state.core.checkout);

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleClosePopup = () => {
    dispatch(ConflictOrderDiscountAction.closeConflictCustomOrderDiscountAndCoupon());
  };

  const handleRemoveCustomOrderDiscount = () => {
    dispatch(ConflictOrderDiscountAction.handleRemoveCustomOrderDiscount(quote));
    handleClosePopup();
  };


  return (
    <>
      <Modal
        size="lg"
        className="popup-edit-customer popup-reorder-confirm"
        dialogClassName="popup-create-customer in"
        show={isOpenConflictCustomOrderDiscount}
      >
        <div className="modal-header">
          <h4 className="modal-title" id="order-cancel-modal-title">{t('Confirmation')}</h4>
        </div>
        <div data-scrollbar className="modal-body">
          <br />
          <div className="text reorder-confirm-text">
            {t('To apply Coupon, ')}
            <span className="reorder-confirm-strong-text">
              {t('you have to remove the custom order discount.')}
            </span>
          </div>
          <div className="text reorder-confirm-text">
            {t('Are you sure to remove it?')}
          </div>
          <div className="actions-bottom">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
            <a className="btn" onClick={handleClosePopup}>{t('No')}</a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
            <a className="btn" onClick={handleRemoveCustomOrderDiscount} >{t('Yes')}</a>
          </div>
        </div>
      </Modal>
    </>
  );
};

ConflictCustomOrderDiscountComponent.className = 'ConflictCustomOrderDiscountComponent';
ConflictCustomOrderDiscountComponent.propTypes = {
};

export default FunctionComponentFactory.get(ConflictCustomOrderDiscountComponent);
