import ServiceFactory from "../../framework/factory/ServiceFactory";
import CoreService from '../CoreService';
import CollectionResourceModel
  from '../../resource-model/collection/CollectionResourceModel';
import {ProductPlaceHolder} from '../../assets/images';

/**
 * Staff Service Class
 */
export class CollectionServiceClass extends CoreService {
  static className = 'CollectionServiceClass';
  resourceModel = CollectionResourceModel;

  /**
   * Get Collections
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getCollections(input) {
    return this.getResourceModel().getCollections(input);
  }

  /**
   * Get Collection Name
   * @param collection
   * @returns {*}
   */
  getCollectionName(collection) {
    return collection.title;
  }

  /**
   * Get Collection Image
   * @param collection
   * @returns {*|null}
   */
  getCollectionImage(collection) {
    if (collection && collection.featuredImage && collection.featuredImage.url) {
      return collection.featuredImage.url;
    }
    return ProductPlaceHolder;
  }
}


/**
 * @type {CollectionServiceClass}
 */
const collectionService = ServiceFactory.get(CollectionServiceClass);

export default collectionService;
