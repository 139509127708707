import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import FunctionComponentFactory from '../../../../../framework/factory/FunctionComponentFactory';
import NumPad from '../../../lib/react-numpad';
import OrderConstant from '../../../../constant/OrderConstant';
import OrderService from '../../../../../service/order/OrderService';

const OrderFulfillItem = ({item, handleUpdateQty}) => {
  const {t} = useTranslation();

  const name = item?.name || '';
  const productId = item?.id;
  const qtyLeft = item?.qtyLeft;

  const sku = item.sku ? `[${item.sku}]` : '';
  const options = item.variantTitle?.replaceAll(" /", ",");

  const getQtyOnHand = () => {
    if (!OrderService.isAssignedToCurrentLocation(item)) {
      return 'N/A';
    }

    if (!item.isTrackedQuantity) {
      return 'No track quantity';
    }

    return item.qtyOnHand || 0;
  };

  const handelClickMinus = (id) => {
    handleUpdateQty(id, OrderConstant.BUTTON_MINUS);
  };

  const handleClickPlus = (id) => {
    handleUpdateQty(id, OrderConstant.BUTTON_PLUS);
  };

  const handleChangeNumPad = (id, value) => {
    handleUpdateQty(id, OrderConstant.CHANGE_NUMPAD, value);
  };

  const getErrorMessage = () => {
    return (
      <div className="t-alert">
        <p>{item.errorMessage}</p>
      </div>
    );
  };

  const btnMinusClass = item.disabled === OrderConstant.BUTTON_MINUS ? 'btn-number qtyminus disabled' : 'btn-number qtyminus';
  const btnPlusClass = item.disabled === OrderConstant.BUTTON_PLUS ? 'btn-number qtyplus disabled' : 'btn-number qtyplus';

  if (Number(qtyLeft) === 0) {
    return <></>;
  }

  return (
    <tr className={`item-${productId}`}>
      <td className="t-col">&nbsp;</td>
      <td className="t-product">
        <p className="title">
          <b>{name}</b>
        </p>
        <p className="sku"><b>{sku}</b></p>
        {options}
      </td>
      <td className="t-qty-on-hand">
        <span><b>{getQtyOnHand()}</b></span>
      </td>
      <td className="t-qty-left">
        <p><b>{qtyLeft}</b></p>
      </td>
      <td className="t-qty-fulfill">
        <div className="product-field-qty">
          <div className="box-field-qty">
            <NumPad.CustomIntegerNumber
              position="centerRight"
              sync={false}
              isShowAction
              arrow="right"
              value={item.qtyFulfill}
              onChange={(value) => handleChangeNumPad(productId, value)}
            >
              <span className="form-control qty">{item.qtyFulfill || 0}</span>
            </NumPad.CustomIntegerNumber>
            <button
              className={btnMinusClass}
              data-type="minus"
              data-field="qty"
              type="button"
              onClick={() => handelClickMinus(productId)}
            > {t("-")}
            </button>
            <button
              className={btnPlusClass}
              data-type="plus"
              data-field="qty"
              type="button"
              onClick={() => handleClickPlus(productId)}
            >{t("+")}
            </button>
          </div>
        </div>
        {getErrorMessage()}
      </td>
    </tr>
  );
};

OrderFulfillItem.propTypes = {
  item: PropTypes.object.isRequired,
  handleUpdateQty: PropTypes.func.isRequired,
};

OrderFulfillItem.className = 'OrderFulfillItemComponent';
export default FunctionComponentFactory.get(OrderFulfillItem);
