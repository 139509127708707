import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from './GraphqlAbstract';

export class GraphqlDraftOrderClass extends GraphqlAbstract {
  static className = 'GraphqlDraftOrderClass';

  /**
   * Draft Order Calculate
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  draftOrderCalculate(input) {
    return this.mutationWithGQLBuilder(
      "draftOrderCalculate",
      {
        input: {
          name: 'input',
          type: 'DraftOrderInput',
          value: input,
          required: true,
        },
      },
      [
        {
          calculatedDraftOrder: [
            {
              appliedDiscount: [
                {
                  amountSet: [
                    {
                      presentmentMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                {
                  amountV2: [
                    'amount',
                    'currencyCode',
                  ],
                },
                'description',
                'title',
                'value',
                'valueType',
              ],
            },
            {
              availableShippingRates: [
                'handle',
                {
                  price: [
                    'amount',
                    'currencyCode',
                  ],
                },
                'title',
              ],
            },
            'billingAddressMatchesShippingAddress',
            'currencyCode',
            {
              lineItems: [
                {
                  appliedDiscount: [
                    {
                      amountSet: [
                        {
                          presentmentMoney: [
                            'amount',
                            'currencyCode',
                          ],
                        },
                        {
                          shopMoney: [
                            'amount',
                            'currencyCode',
                          ],
                        },
                      ],
                    },
                    {
                      amountV2: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                    'description',
                    'title',
                    'value',
                    'valueType',
                  ],
                },
                'custom',
                {
                  customAttributes: [
                    'key',
                    'value',
                  ],
                },
                {
                  customAttributesV2: [
                    'key',
                    'value',
                  ],
                },
                {
                  discountedTotal: [
                    'amount',
                    'currencyCode',
                  ],
                },
                {
                  discountedTotalSet: [
                    {
                      presentmentMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                {
                  discountedUnitPrice: [
                    'amount',
                    'currencyCode',
                  ],
                },
                {
                  discountedUnitPriceSet: [
                    {
                      presentmentMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                {
                  image: [
                    'altText',
                    'height',
                    'id',
                    'url',
                    'width',
                  ],
                },
                'isGiftCard',
                'name',
                {
                  originalTotal: [
                    'amount',
                    'currencyCode',
                  ],
                },
                {
                  originalTotalSet: [
                    {
                      presentmentMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                {
                  originalUnitPrice: [
                    'amount',
                    'currencyCode',
                  ],
                },
                {
                  originalUnitPriceSet: [
                    {
                      presentmentMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                'quantity',
                'requiresShipping',
                'sku',
                'taxable',
                'title',
                {
                  totalDiscount: [
                    'amount',
                    'currencyCode',
                  ],
                },
                {
                  totalDiscountSet: [
                    {
                      presentmentMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                'variantTitle',
                'vendor',
                {
                  weight: [
                    'unit',
                    'value',
                  ],
                },
              ],
            },
            {
              lineItemsSubtotalPrice: [
                {
                  presentmentMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
                {
                  shopMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
              ],
            },
            'marketName',
            'marketRegionCountryCode',
            'phone',
            'presentmentCurrencyCode',
            {
              shippingLine: [
                'carrierIdentifier',
                'code',
                'custom',
                'deliveryCategory',
                {
                  discountAllocations: [
                    {
                      allocatedAmountSet: [
                        {
                          presentmentMoney: [
                            'amount',
                            'currencyCode',
                          ],
                        },
                        {
                          shopMoney: [
                            'amount',
                            'currencyCode',
                          ],
                        },
                      ],
                    },
                    {
                      discountApplication: [
                        'allocationMethod',
                        'index',
                        'targetSelection',
                        'targetType',
                        {
                          value: [
                            {
                              '... on Money': [
                                'amount',
                                'currencyCode',
                              ],
                            },
                            {
                              '... on PricingPercentageValue': [
                                'percentage',
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  discountedPriceSet: [
                    {
                      presentmentMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                'id',
                {
                  originalPriceSet: [
                    {
                      presentmentMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                'phone',
                'shippingRateHandle',
                'source',
                {
                  taxLines: [
                    'channelLiable',
                    {
                      priceSet: [
                        {
                          presentmentMoney: [
                            'amount',
                            'currencyCode',
                          ],
                        },
                        {
                          shopMoney: [
                            'amount',
                            'currencyCode',
                          ],
                        },
                      ],
                    },
                    'rate',
                    'ratePercentage',
                    'title',
                  ],
                },
                'title',
              ],
            },
            'subtotalPrice',
            {
              subtotalPriceSet: [
                {
                  presentmentMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
                {
                  shopMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
              ],
            },
            {
              taxLines: [
                'channelLiable',
                {
                  priceSet: [
                    {
                      presentmentMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                    {
                      shopMoney: [
                        'amount',
                        'currencyCode',
                      ],
                    },
                  ],
                },
                'rate',
                'ratePercentage',
                'title',
              ],
            },
            {
              totalDiscountsSet: [
                {
                  presentmentMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
                {
                  shopMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
              ],
            },
            {
              totalLineItemsPriceSet: [
                {
                  presentmentMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
                {
                  shopMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
              ],
            },
            'totalPrice',
            {
              totalPriceSet: [
                {
                  presentmentMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
                {
                  shopMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
              ],
            },
            'totalShippingPrice',
            {
              totalShippingPriceSet: [
                {
                  presentmentMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
                {
                  shopMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
              ],
            },
            'totalTax',
            {
              totalTaxSet: [
                {
                  presentmentMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
                {
                  shopMoney: [
                    'amount',
                    'currencyCode',
                  ],
                },
              ],
            },
          ],
        },
        {
          userErrors: [
            'field',
            'message',
          ],
        },
      ],
      {
        operationName: 'draftOrderCalculate',
      },
    );
  }
}

/**
 * @type {GraphqlDraftOrderClass}
 */
export default DataResourceFactory.get(GraphqlDraftOrderClass);
