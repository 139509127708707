import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import FunctionComponentFactory
  from '../../../framework/factory/FunctionComponentFactory';
import '../../../assets/Menu.css';
import ScanAction from '../../action/ScanAction';
import ScanConstant from '../../constant/ScanConstant';

function Scan() {
  const dispatch = useDispatch();

  const {scanPage} = useSelector((state) => state.core.scan);

  const scanString = useRef('');
  const lastCharacter = useRef('');
  const currentStringLength = useRef(0);
  const lastScanTimeStamp = useRef(Date.now());
  const currentScanPage = useRef(scanPage);

  useEffect(() => {
    currentScanPage.current = scanPage;
  }, [scanPage]);

  const isSwipeCardScreen = () => {
    const nameOnCardElement = document.getElementById('name-on-card');
    return nameOnCardElement && nameOnCardElement.checkVisibility({
      checkOpacity: true,
      checkVisibilityCSS: true,
    });
  };

  const resetScanString = () => {
    scanString.current = "";
    lastCharacter.current = "";
    currentStringLength.current = 0;
    lastScanTimeStamp.current = Date.now();
    setTimeout(() => dispatch(ScanAction.setBarcodeString(), 500));
  };

  const scanBarcode = (event) => {
    if (isSwipeCardScreen()) {
      return;
    }
    if (!currentScanPage.current) {
      return;
    }
    if (currentScanPage.current === ScanConstant.SCAN_PAGES.LOGIN) {
      return;
    }
    let key = event.key;
    if (!key) {
      return;
    }
    key = key.toString();
    const lowerCaseKey = key.toLowerCase();
    if (ScanConstant.NON_SCAN_KEYS.includes[lowerCaseKey]) {
      return;
    }
    if (lowerCaseKey === 'shift') {
      lastCharacter.current = lowerCaseKey;
      if (!currentStringLength.current) {
        lastScanTimeStamp.current = Date.now();
      }
      currentStringLength.current += 1;
      return;
    }
    const currentTime = Date.now();
    const diffTime = currentTime - lastScanTimeStamp.current;
    currentStringLength.current += 1;
    if (ScanConstant.REMOVE_SCAN_STRING_KEYS.includes(lowerCaseKey)) {
      resetScanString();
      return;
    }

    if ((diffTime / currentStringLength.current) < ScanConstant.MAX_DIFF_TIME_WITH_SCAN_BARCODE) {
      if (lowerCaseKey === 'enter' &&
        scanString.current &&
        scanString.current.length >= ScanConstant.MIN_BARCODE_LENGTH
      ) {
        dispatch(ScanAction.setBarcodeString(scanString.current));
        resetScanString();
      } else if (lowerCaseKey === 'enter') {
        resetScanString();
      } else {
        if (lastCharacter.current === 'shift') {
          if (key.length === 1) {
            if (ScanConstant.SHIFT_MAP_KEYS[key]) {
              key = ScanConstant.SHIFT_MAP_KEYS[key];
            } else {
              key = key.toUpperCase();
            }
          } else {
            lastCharacter.current = lowerCaseKey;
            return;
          }
        }
        lastCharacter.current = lowerCaseKey;
        scanString.current += key;
      }
    } else {
      lastScanTimeStamp.current = Date.now();
      if (lowerCaseKey === 'enter') {
        resetScanString();
      } else {
        if (lastCharacter.current === 'shift') {
          key = key.toUpperCase();
        }
        currentStringLength.current = 1;
        scanString.current = key;
      }
      lastCharacter.current = lowerCaseKey;
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', scanBarcode);
    return () => {
      document.removeEventListener('keyup', scanBarcode);
    };
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  return null;
}

Scan.className = 'Scan';
Scan.propTypes = {};

export default FunctionComponentFactory.get(Scan);
