import {combineReducers} from 'redux';

import Extension from "../../framework/Extension";

import staffReducer from './StaffReducer';
import loading from './LoadingReducer';
import config from './ConfigReducer';
import shopReducer from './ShopReducer';
import posReducer from './PosReducer';
import menu from './MenuReducer';
import checkout from './CheckoutReducer';
import signout from './SignoutReducer';
import product from './ProductReducer';
import customer from './CustomerReducer';
import stock from './StockReducer';
import scan from './ScanReducer';
import collection from './CollectionReducer';
import orderReducer from './OrderReducer';
import sessionReducer from './SessionReducer';
import shipping from './ShippingReducer';
import multiCart from "./MultiCartReducer";
import setting from './SettingReducer';

/**
 * Init core reducer
 *
 * @returns {Reducer<any>}
 */
function coreReducer() {
  return combineReducers({
    staffReducer,
    loading,
    config,
    shopReducer,
    posReducer,
    menu,
    setting,
    checkout,
    signout,
    product,
    stock,
    scan,
    multiCart,
    customer,
    collection,
    order: orderReducer,
    session: sessionReducer,
    shipping,
  });
}


/**
 * Check extension reducer
 *
 * @returns {*}
 */
function extensionReducer() {
  if (Extension.ExtensionConfig.reducer && Object.keys(Extension.ExtensionConfig.reducer).length) {
    return combineReducers({...Extension.ExtensionConfig.reducer});
  }

  return false;
}

/**
 * Root reducer
 *
 * @returns {*}
 */
function rootReducer() {
  const extension = extensionReducer();
  if (!extension) {
    return {
      core: coreReducer(),
    };
  }
  return {
    core: coreReducer(),
    extension: extensionReducer(),
  };
}

export default combineReducers(rootReducer());
