import ServiceFactory from "../../../framework/factory/ServiceFactory";
import GuestCustomerService from '../../customer/GuestCustomerService';
import LocationService from '../../location/LocationService';
import QuoteConstant from '../../../view/constant/checkout/QuoteConstant';

import AbstractQuoteService from "./AbstractService";

export class QuoteAddressServiceClass extends AbstractQuoteService {
  static className = 'QuoteAddressService';

  /**
   * Create address
   * @param address
   * @param customer
   * @returns {{firstName: (string|string), address_type: string, customer_address_id: (null|*), phone: (string|string), quote_id: null, address_id: (*|null), customer_id: (null|*), email: (*|string), lastName: (string|string)}}
   */
  createAddress(address = null, customer = null) {
    const customerFirstName = customer ? customer.firstName : GuestCustomerService.getFirstName();
    const customerLastName = customer ? customer.lastName : GuestCustomerService.getLastName();
    const customerPhone = (customer && customer.phone) ? customer.phone : GuestCustomerService.getPhone();
    const currentLocationAddress = LocationService.getAddressCurrentLocation();
    return {
      firstName: address ? address.firstName : customerFirstName,
      lastName: address ? address.lastName : customerLastName,
      phone: address ? address.phone : customerPhone,
      address1: address ? address.address1 : currentLocationAddress.address1,
      address2: address ? address.address2 : currentLocationAddress.address2,
      city: address ? address.city : currentLocationAddress.city,
      country: address ? address.country : currentLocationAddress.country,
      countryCode: address ? address.countryCode : currentLocationAddress.countryCode,
      province: address ? address.province : currentLocationAddress.province,
      provinceCode: address ? address.provinceCode : currentLocationAddress.provinceCode,
      zip: address ? address.zip : currentLocationAddress.zip,
      company: (address && address.company) ? address.company : null,
    };
  }


  /**
   * Update quote address
   *
   * @param quoteAddress
   * @param address
   * @param customer
   * @return {object}
   */
  updateAddress(quoteAddress = {}, address = null, customer = null) {
    const customerFirstName = customer ? customer.firstName : GuestCustomerService.getFirstName();
    const customerLastName = customer ? customer.firstName : GuestCustomerService.getLastName();
    const customerPhone = (customer && customer.phone) ? customer.phone : GuestCustomerService.getPhone();
    const currentLocationAddress = LocationService.getAddressCurrentLocation();
    quoteAddress.id = address?.id;
    quoteAddress.firstName = address ? address.firstName : customerFirstName;
    quoteAddress.lastName = address ? address.lastName : customerLastName;
    quoteAddress.phone = address ? address.phone : customerPhone;
    quoteAddress.company = (address && address.company) ? address.company : null;
    quoteAddress.address1 = address ? address.address1 : currentLocationAddress.address1;
    quoteAddress.address2 = address ? address.address2 : currentLocationAddress.address2;
    quoteAddress.city = address ? address.city : currentLocationAddress.city;
    quoteAddress.country = address ? address.country : currentLocationAddress.country;
    quoteAddress.countryCode = address ? address.countryCode : currentLocationAddress.countryCode;
    quoteAddress.province = address ? address.country : currentLocationAddress.province;
    quoteAddress.provinceCode = address ? address.provinceCode : currentLocationAddress.provinceCode;
    quoteAddress.zip = address ? address.zip : currentLocationAddress.zip;
    return quoteAddress;
  }

  /**
   *
   * @param quote
   * @returns {*}
   */
  createTempAddress(quote) {
    if (!quote.billingAddress) {
      quote.billingAddress = this.createAddress();
    }
    if (!quote.shippingAddress) {
      quote.shippingAddress = this.createAddress();
    }
    if (!quote.customer) {
      quote.checkout_method = QuoteConstant.METHOD_GUEST;
      quote.email = GuestCustomerService.getEmail();
      quote.phone = GuestCustomerService.getPhone();
      quote.customerId = null;
    }
    return quote;
  }
}

/** @type QuoteAddressServiceClass */
const quoteAddressService = ServiceFactory.get(QuoteAddressServiceClass);

export default quoteAddressService;
