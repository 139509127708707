const staffPlugin = {
  getApiResource: {
    shopify: {
      sortOrder: 10,
      disabled: false,
      around() {
        const ObjectManager = require("../../../../../framework/ObjectManager").default;
        const shopifyGraphql = require("../../../data/omc/graphql/GraphqlStaff").default;
        return ObjectManager.get(shopifyGraphql);
      },
    },
  },
};
export default staffPlugin;
