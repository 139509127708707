import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Form} from "react-bootstrap";

import i18n from "../../../../../config/i18n";
import OrderService from "../../../../../service/order/OrderService";
import NumPad from '../../../lib/react-numpad/index';

function RefundTableItem(props) {
  const messageError = `Cannot restock unfulfilled item(s)`;
  const {orderProduct, handleListRefund, handleUpdateIsMaxQtyRefund, isMaxQtyRefund, order} = props;

  const isProductGiftCard = orderProduct?.product?.isGiftCard;
  const [typeClickBtnWithGiftCard, setTypeClickBtnWithGiftCard] = useState(null);

  let qtyRefund = orderProduct.qtyRefund;
  let canDecreaseQty = (parseFloat(qtyRefund) > 0);
  let canIncreaseQty = (parseFloat(qtyRefund) < parseFloat(orderProduct.refundAmountAvailable));
  let canReturnToStock = ((parseFloat(qtyRefund) > 0) && (parseFloat(qtyRefund) > parseFloat(orderProduct.unfulfilledQuantity)));
  let messageReturnStock = (parseFloat(qtyRefund) > parseFloat(orderProduct.unfulfilledQuantity) ? `Qty to return: ` : messageError);
  if (parseFloat(qtyRefund) === 0) {
    messageReturnStock = null;
  }
  const [refundMessageError, setRefundMessageError] = useState();
  let isReturnToStock = orderProduct.isReturnToStock;
  const totalTax = qtyRefund * (OrderService.calcTaxLineItem(orderProduct, orderProduct) / orderProduct.quantity);
  const totalDiscount = qtyRefund * parseFloat(OrderService.calcDiscountLineItem(order, orderProduct));
  const rowTotal = (qtyRefund * orderProduct.originalUnitPriceSet.shopMoney.amount) + totalTax - totalDiscount;
  const skuContent = `[${i18n.t((orderProduct.sku && orderProduct?.sku?.length > 0) ? orderProduct?.sku : ``)}]`;
  if (isMaxQtyRefund && !isProductGiftCard) {
    isReturnToStock = true;
    qtyRefund = orderProduct.refundAmountAvailable;
    canReturnToStock = true;
    if (orderProduct.refundAmountAvailable > orderProduct.unfulfilledQuantity) {
      messageReturnStock = `Qty to return: `;
    } else {
      messageReturnStock = messageError;
      canReturnToStock = false;
    }
    canIncreaseQty = false;
    canDecreaseQty = true;
  }


  switch (typeClickBtnWithGiftCard) {
    case 0:
      canDecreaseQty = false;
      canIncreaseQty = true;
      break;
    case 1:
      canDecreaseQty = true;
      canIncreaseQty = false;
      break;
  }

  if (isMaxQtyRefund && isProductGiftCard) {
    canIncreaseQty = false;
    canDecreaseQty = true;
  }

  useEffect(() => {
    if (isMaxQtyRefund && isProductGiftCard) {
      setRefundMessageError("You cannot refund Giftcard products on POS. Please refund from Admin");
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMaxQtyRefund]);

  const totalCanRestock = qtyRefund - orderProduct.unfulfilledQuantity;

  const messageDisplay = messageReturnStock === messageError ? i18n.t('{{messageReturnStock}}', {messageReturnStock})
    : i18n.t('{{messageReturnStock}} {{totalCanRestock}}', {messageReturnStock, totalCanRestock});

  const handleIncreaseRefundQty = () => {
    if (isProductGiftCard) {
      setTypeClickBtnWithGiftCard(1);
      setRefundMessageError("You cannot refund Giftcard products on POS. Please refund from Admin");
      return;
    }

    if (qtyRefund + 1 > orderProduct.refundAmountAvailable) {
      setRefundMessageError("Qty to Refund cannot be greater than Qty Left.");
    } else {
      handleListRefund(orderProduct, {qtyRefund: qtyRefund + 1});
      setRefundMessageError(null);
    }
  };

  const handleDecreaseRefundQty = () => {
    if (isProductGiftCard) {
      setRefundMessageError("You cannot refund Giftcard products on POS. Please refund from Admin");
      setTypeClickBtnWithGiftCard(0);
      return;
    }

    if (qtyRefund - 1 < 0) {
      setRefundMessageError("Qty to Refund cannot be smaller than 0.");
    } else {
      handleListRefund(orderProduct, {qtyRefund: qtyRefund - 1});
      setRefundMessageError(null);
      handleUpdateIsMaxQtyRefund(false);
    }
  };

  const handleChangeQtyRefund = (val) => {
    const valInt = parseInt(val, 10);
    if (valInt > orderProduct.refundAmountAvailable) {
      setRefundMessageError("Qty to Refund cannot be greater than Qty Left.");
    } else {
      setRefundMessageError(null);
    }

    handleListRefund(orderProduct, {qtyRefund: Math.min(valInt, orderProduct.refundAmountAvailable)});
  };

  return (
    <>
      <tr className={`item-${orderProduct?.product?.id}`}>
        <td className="t-col">&nbsp;</td>
        <td className="t-product">
          <p
            className="title"
          ><b>{i18n.t(orderProduct.name)}</b>
          </p>
          {orderProduct?.sku && <p className="sku"><b>{skuContent}</b></p>}
          {
            orderProduct.variantTitle && (
              <p className="option">
                {i18n.t(orderProduct.variantTitle.replaceAll(" /", ","))}
              </p>
            )
          }
        </td>
        <td className="t-qty">
          <span>{orderProduct.refundAmountAvailable}</span>
        </td>
        <td className="t-qtyrefund">
          <div className="product-field-qty">
            <div className="box-field-qty">
              <span className="form-control qty">
                <NumPad.CustomIntegerNumber
                  onChange={(val) => {
                    handleChangeQtyRefund(val);
                  }}
                  position="centerLeft"
                  sync={false}
                  arrow="left"
                  isShowAction
                  value={qtyRefund}
                >
                  <span className="value">{qtyRefund}</span>
                </NumPad.CustomIntegerNumber>
              </span>
              <button
                type="button"
                className={`btn-number qtyminus${canDecreaseQty ? "" : " disabled"}`}
                data-field="qty"
                onClick={() => {
                  handleDecreaseRefundQty();
                }}
              >
                {i18n.t('-')}
              </button>
              <button
                type="button"
                className={`btn-number qtyplus${canIncreaseQty ? "" : " disabled"}`}
                data-field="qty"
                onClick={() => {
                  handleIncreaseRefundQty();
                }}
              >
                {i18n.t('+')}
              </button>
            </div>
          </div>
          {refundMessageError && (
          <div className="t-alert">
            <p>{i18n.t(`${refundMessageError}`)}</p>
          </div>
          )}
        </td>
        <td className="t-return">
          <Form.Label className="label-checkbox">
            <Form.Control
              disabled={!canReturnToStock}
              type="checkbox"
              defaultChecked
              onClick={(event) => {
                if (!event.target.checked) {
                  handleUpdateIsMaxQtyRefund(false);
                }
                handleListRefund(orderProduct, {isReturnToStock: event.target.checked});
                return event;
              }}
            />
            <span>&nbsp;</span>
          </Form.Label>
          {(messageReturnStock && isReturnToStock) && (
            <div className="t-alert">
              <p>{messageDisplay}</p>
            </div>
          )}
        </td>
        <td className="t-price">{OrderService.formatPrice(orderProduct.originalUnitPriceSet.shopMoney.amount)}</td>
        <td className="t-tax">{OrderService.formatPrice(totalTax)}</td>
        <td className="t-discount">
          {OrderService.formatPrice(totalDiscount)}
        </td>
        <td className="t-rowtotal">
          <p><b>{OrderService.formatPrice(rowTotal)}</b></p>
        </td>
        <td className="t-col">&nbsp;</td>
      </tr>
    </>
  );
}
RefundTableItem.className = 'RefundTableItem';
RefundTableItem.propTypes = {
  orderProduct: PropTypes.object,
  handleListRefund: PropTypes.func,
  handleUpdateIsMaxQtyRefund: PropTypes.func,
  isMaxQtyRefund: PropTypes.bool,
  order: PropTypes.object,
};
export default RefundTableItem;
