import React, {Fragment} from 'react';
import {Modal} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from "prop-types";

import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import "../../../../assets/OrderCancel.css";
import "../../../../assets/Order.css";
import OrderAction from '../../../action/OrderAction';

const ErrorCompletePopup = (props) => {
  const {handleCreateOrder} = props;
  const {isOpenErrorCompletePopup, errorCompleteProductList} = useSelector((state) => state.core.order.orderComplete);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const handleClosePopup = () => {
    dispatch(OrderAction.setIsOpenErrorCompletePopup(false));
  };

  const handleContinueOrder = () => {
    const isComfirmedOrderCompleteError = true;
    // if set isComfirmedOrderCompleteError = true, create order will not verify insufficient quantity of all line items
    handleCreateOrder(isComfirmedOrderCompleteError);
    handleClosePopup();
  };

  function insufficientQtyDisplay(productData) {
    return `${productData.insufficientQty} x ${productData.product.title}`;
  }

  return (
    <>
      <Modal
        size="lg"
        className="popup-edit-customer popup-reorder-confirm"
        dialogClassName="popup-create-customer in"
        show={isOpenErrorCompletePopup}
      >
        <div className="modal-header">
          <h4 className="modal-title" id="order-cancel-modal-title">{t('Error')}</h4>
        </div>
        <div data-scrollbar className="modal-body">
          <br />
          <div className="text reorder-confirm-text">
            {t('Some product(s) in the cart is not available in the store to be shipped anymore:')}
          </div>
          {
            errorCompleteProductList.length > 0 && errorCompleteProductList.map((productData) => {
              return (
                <div className="text reorder-confirm-text" key={productData?.variant?.id}>
                  {insufficientQtyDisplay(productData)}
                </div>
              );
            })
          }
          <div className="text reorder-confirm-text">
            {t('Are you sure you want to ship them immediately?')}
          </div>
          <div className="text reorder-confirm-alert-text">
            {t('Note: Please contact with the manager if there is any error in product stock.')}
          </div>
          <div className="actions-bottom">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
            <a className="btn" onClick={handleClosePopup}>{t('No')}</a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
            <a className="btn" onClick={handleContinueOrder} >{t('Yes')}</a>
          </div>
        </div>
      </Modal>
    </>
  );
};

ErrorCompletePopup.className = 'ErrorCompletePopup';
ErrorCompletePopup.propTypes = {
  handleCreateOrder: PropTypes.func,
};

export default FunctionComponentFactory.get(ErrorCompletePopup);
