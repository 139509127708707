import {cloneDeep} from "lodash";
import {toast} from "react-toastify";

import ProductConstant from '../constant/ProductConstant';
import ProductService from '../../service/product/ProductService';
import {fire} from "../../event-bus";
import ActionFactory from "../../framework/factory/ActionFactory";
import ProductStockService from "../../service/product/stock/ProductStockService";
import i18n from "../../config/i18n";

import QuoteAction from "./checkout/QuoteAction";

export class ProductActionClass {

  /**
   * Get Products
   *
   * @param input
   * @returns {(function(*): Promise<void>)|*}
   */
  getProducts(input) {
    return async (dispatch) => {
      dispatch({
        type: ProductConstant.GET_PRODUCTS,
        input,
      });
      try {
        const response = await ProductService.getProducts(input);
        dispatch(this.getProductsSuccess(response.data.getProducts));
      } catch (error) {
        dispatch(this.getProductsFailed(error.message));
      }
    };
  }

  /**
   * Get Products Success
   *
   * @param result
   * @returns {{result, type: string}}
   */
  getProductsSuccess(result) {
    return {
      type: ProductConstant.GET_PRODUCTS_SUCCESS,
      items: result.items,
      pageInfo: result.pageInfo,
    };
  }

  /**
   * Get Products Failed
   *
   * @param error
   * @returns {{type: string, error}}
   */
  getProductsFailed(error) {
    return {
      type: ProductConstant.GET_PRODUCTS_FAILED,
      error,
    };
  }

  /**
   * Search by barcode
   * @param barcode
   * @returns {(function(*, *): Promise<*|undefined>)|*}
   */
  searchByBarcode(barcode) {
    return async (dispatch, getState) => {
      dispatch({
        type: ProductConstant.SEARCH_BY_BARCODE,
        barcode,
      });
      try {
        const state = getState();
        const response = await ProductService.processBarcode(barcode, state);
        const result = response.data.searchByBarcode;
        fire('search_barcode_result_after', result);
        if (
          result &&
          result.items &&
          result.items.length === 1 &&
          !result.pageInfo.hasNextPage
        ) {
          const variant = cloneDeep(result.items[0]);
          const product = cloneDeep(variant.product);
          delete variant.product;

          const addToQuoteData = {
            product,
            variant,
            quantity: ProductStockService.getQtyIncrement(),
          };
          dispatch(QuoteAction.addProduct(addToQuoteData));
          return;
        }
        if (!result.items.length) {
          toast.error(
              i18n.t("Barcode {{barcode}} does not match with any product in the current location!", {barcode}),
          );
          return;
        }
        if (result.items.length && result.pageInfo.hasNextPage) {
          dispatch(this.setTextToSearch(barcode));
        }
      } catch (error) {
        return error.message;
      }
    };
  }

  /**
   * Set Text To Search
   *
   * @param text
   * @returns {{text, type: string}}
   */
  setTextToSearch(text = '') {
    return {
      type: ProductConstant.SET_TEXT_TO_SEARCH,
      text,
    };
  }

  /**
   * get products by collection id
   *
   * @param questionId
   * @param productInput
   * @returns {Promise<unknown>|Promise<ApolloQueryResult<*>>}
   */
  getProductsByCollectionId(collectionId, productInput) {
    return async (dispatch) => {
      dispatch({
        type: ProductConstant.GET_PRODUCTS,
        input: productInput,
        collectionId,
      });
      try {
        const response = await ProductService.getProductsByCollectionId(collectionId, productInput);
        dispatch(this.getProductsSuccess(response.data.getProductsByCollectionId.products));
      } catch (error) {
        dispatch(this.getProductsFailed(error.message));
      }
    };
  }


  /**
   * View product action
   *
   * @param product
   * @returns {{product: null, isShowExternalStock: boolean, canBack: boolean, type: string}}
   */
  viewProduct(product = null) {
    return (dispatch) => {
      dispatch({
        type: ProductConstant.VIEW_PRODUCT,
        product: cloneDeep(product),
      });
      if (product && product.id) {
        dispatch(this.getProductOptions(product));
      }
    };
  }

  /**
   * Get Product Options
   * @param product
   * @returns {(function(*): Promise<void>)|*}
   */
  getProductOptions(product) {
    return async (dispatch) => {
      dispatch({
        type: ProductConstant.GET_PRODUCT_OPTIONS,
        product,
      });
      try {
        const response = await ProductService.getProductOptions(product.id, true);
        dispatch(this.getProductOptionsSuccess(response.data.getProductOptions.productOptions));
        dispatch(this.addCollectionIdsToProductPopupView(response.data.getProductOptions.collectionIds));
      } catch (error) {
        toast.error(i18n.t("Unable to get product's options"));
        dispatch(this.getProductOptionsFailed(error.message));
      }
    };
  }

  /**
   * Get Product Options Success
   * @param productOptions
   * @returns {{productOptions, type: string}}
   */
  getProductOptionsSuccess(productOptions) {
    return {
      type: ProductConstant.GET_PRODUCT_OPTIONS_SUCCESS,
      productOptions,
    };
  }

  /**
   * Add collection ids
   * @param collectionIds
   * @returns {{collectionIds, type: string}}
   */
  addCollectionIdsToProductPopupView(collectionIds) {
    return {
      type: ProductConstant.VIEW_PRODUCT_ADD_COLLECTION_IDS,
      collectionIds,
    };
  }

  /**
   * Get Product Options Failed
   * @param error
   * @returns {{type: string, error}}
   */
  getProductOptionsFailed(error) {
    return {
      type: ProductConstant.GET_PRODUCT_OPTIONS_FAILED,
      error,
    };
  }

  /**
   * Get Product Variant By Product Options
   * @param product
   * @param selectedOptions
   * @returns {(function(*): Promise<void>)|*}
   */
  getProductVariantByProductOptions(product, selectedOptions) {
    return async (dispatch) => {
      dispatch({
        type: ProductConstant.GET_PRODUCT_VARIANT_BY_PRODUCT_OPTIONS,
        product,
        selectedOptions,
      });
      try {
        const response = await ProductService.getProductVariantByProductOptions(product.id, selectedOptions);
        const variant = response.data.getProductVariantByProductOptions;
        if (!variant) {
          toast.error(i18n.t("Unable to get product's variant"));
          dispatch(this.getProductVariantByProductOptionsFailed("Unable to get product's variant"));
          return;
        }
        dispatch(this.getProductVariantByProductOptionsSuccess(variant));
      } catch (error) {
        toast.error(i18n.t("Unable to get product's variant"));
        dispatch(this.getProductVariantByProductOptionsFailed(error.message));
      }
    };
  }

  /**
   * Get Product Variant By Product Options Success
   * @param variant
   * @returns {{variant, type: string}}
   */
  getProductVariantByProductOptionsSuccess(variant) {
    return {
      type: ProductConstant.GET_PRODUCT_VARIANT_BY_PRODUCT_OPTIONS_SUCCESS,
      variant,
    };
  }

  /**
   * Get Product Variant By Product Options Failed
   * @param error
   * @returns {{type: string, error}}
   */
  getProductVariantByProductOptionsFailed(error) {
    return {
      type: ProductConstant.GET_PRODUCT_VARIANT_BY_PRODUCT_OPTIONS_FAILED,
      error,
    };
  }

}

/**
 * @type {ProductActionClass}
 */
const ProductAction = ActionFactory.get(ProductActionClass);
export default ProductAction;
