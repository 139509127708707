import {toast} from "react-toastify";

import StaffConstant from "../constant/StaffConstant";
import SettingConstant from "../constant/SettingConstant";

const initialState = {
  selectedId: 'My Account',
  isLoading: false,
  changePasswordStatus: false,
  messageError: '',
};

const SettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case StaffConstant.STAFF_CHANGE_PASSWORD:
      return {...state, isLoading: true};
    case StaffConstant.STAFF_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        changePasswordStatus: true,
      };
    case StaffConstant.STAFF_CHANGE_PASSWORD_FAILED:
      if (action.error.length > 0) { toast.error(action.error); }
      return {
        ...state,
        isLoading: false,
        messageError: action.error,
      };
    case SettingConstant.RESET_CHANGE_PASSWORD_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default SettingReducer;
