import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import FunctionComponentFactory from "../../../../framework/factory/FunctionComponentFactory";
import ProductList from "../catalog/ProductList.jsx";
import MultiCartAction from "../../../action/MultiCartAction";

function RemoveCart() {
  const [isDisabled, setIsDisabled] = useState(true);
  const {quote} = useSelector((state) => state.core.checkout);
  const {carts, activeCartId} = useSelector((state) => state.core.multiCart);
  const {currentPage} = useSelector((state) => state.core.checkout.index);
  const [buttonClass, setButtonClass] = useState('btn btn-delete disabled');
  const dispatch = useDispatch();

  /**
   * check delete cart or not
   *
   * @returns {boolean}
   * @param current
   */
  const canDeleteCart = (current) => {
    return current === ProductList.className;
  };

  const handleDeleteCart = () => {
    dispatch(MultiCartAction.deleteCart(carts, {id: activeCartId}));
  };

  const buttonDeleteText = 'delete';

  useEffect(() => {
    if (canDeleteCart(currentPage)) {
      setButtonClass('btn btn-delete');
      setIsDisabled(false);
    } else {
      setButtonClass('btn btn-delete disabled');
      setIsDisabled(true);
    }
  }, [currentPage, quote]);

  return (
    <>
      <button className={buttonClass} disabled={isDisabled} type="button" onClick={handleDeleteCart}>
        <span>{buttonDeleteText}</span>
      </button>
    </>
  );
}

RemoveCart.className = 'RemoveCart';
RemoveCart.propTypes = {};
export default FunctionComponentFactory.get(RemoveCart);
