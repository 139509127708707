import $ from 'jquery';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Modal, Spinner} from 'react-bootstrap';
import SmoothScrollbar from 'smooth-scrollbar';
import '../../../../assets/Customer.css';
import _ from 'lodash';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import layout from '../../../../framework/Layout';
import CustomerFieldService
  from '../../../../service/customer/CustomerFieldService';
import CustomerAction from '../../../action/CustomerAction';
import AddressAction from '../../../action/AddressAction';
import CustomerPopupConstant
  from '../../../constant/customer/CustomerPopupConstant';
import CustomerPopupService
  from '../../../../service/customer/CustomerPopupService';

import CustomerInputComponent from "./field/CustomerInputComponent.jsx";
import CustomerComboBoxComponent from "./field/CustomerComboBoxComponent.jsx";
import CustomerComboBoxSuggestComponent from "./field/CustomerComboBoxSuggestComponent.jsx";
import CustomerAddressListing from './customer-popup/CustomerAddressListing.jsx';
import AddressCustomerPopup from "./address/AddressCustomerPopup.jsx";
import CustomerSelectComponent from './field/CustomerSelectComponent.jsx';
import CustomerCheckBoxComponent from './field/CustomerCheckBoxComponent.jsx';

function CustomerPopup() {
  const {t} = useTranslation();
  const customerFields = CustomerPopupService.setRowCustomerField(CustomerFieldService.getCustomerFormConfig());
  const dispatch = useDispatch();
  const {isShowCustomerPopup, userErrors, isLoading, isSaveSuccess, isLoadingCustomerDetail, updateForOrder} = useSelector((state) => { return state.core.customer.customerPopupReducer; });
  const [currentCustomer, setCurrentCustomer] = useState({});
  const currentEditCustomer = useSelector((state) => { return state.core.customer.customerPopupReducer.currentCustomer; });
  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    return () => {
      if (isSaveSuccess) {
        setCurrentCustomer({});
      }
    };
  }, [isSaveSuccess]);

  useEffect(() => {
    const newErrorMessages = {};
    userErrors.forEach(
      (userError) => {
        const fieldErrors = userError.field;
        fieldErrors.forEach(
          (field) => {
            if (newErrorMessages[field]) {
              newErrorMessages[field].push(userError.message);
            } else {
              newErrorMessages[field] = [userError.message];
            }
          },
        );
      },
    );
    setErrorMessages(newErrorMessages);
  }, [userErrors]);

  useEffect(() => {
    setCurrentCustomer(currentEditCustomer);
  }, [currentEditCustomer]);

  const validateByField = (field, value) => {
    if (field.required && !value) {
      setErrorMessages(
        {
          ...errorMessages,
          [field.code]: ['This is a required field'],
        },
      );
    } else {
      setErrorMessages(
        {
          ...errorMessages,
          [field.code]: [],
        },
      );
    }
  };

  const handleOnblur = (field, value) => {
    validateByField(field, value);
  };

  /**
   * Set height popup
   * @param elementHeight
   */
  const heightPopup = (elementHeight) => {
    if (!elementHeight) {
      return;
    }
    const height = $(window).height();
    $(elementHeight).css('height', `${height}px`);
  };

  const setPopupCustomerElement = (elementPopup) => {
    if (!elementPopup) {
      return;
    }
    SmoothScrollbar.init(elementPopup);
    heightPopup('.popup-edit-customer .modal-dialog');
  };

  const cancelPopup = () => {
    setCurrentCustomer({});
    dispatch(CustomerAction.hideCreateCustomerPopup());
  };

  const setFieldValueByCode = (code, value, isMultiValue = false) => {
    const newCurrentCustomer = _.cloneDeep(currentCustomer);
    let currentValue = newCurrentCustomer[code];
    if (isMultiValue) {
      if (currentValue && currentValue.length) {
        if (!currentValue.includes(value)) {
          currentValue.push(value);
        }
      } else {
        currentValue = [value];
      }
    } else {
      currentValue = value;
    }
    setCurrentCustomer(
      {
        ...currentCustomer,
        [code]: currentValue,
      },
    );
  };

  const setFieldValue = (field, value, isMultiValue = false) => {
    const code = field.code;
    validateByField(field, value);
    setFieldValueByCode(code, value, isMultiValue);
  };

  const getFieldError = (field) => {
    if (errorMessages[field]) {
      return errorMessages[field].join(', ');
    } else {
      return '';
    }
  };

  const renderField = (field, oneRow) => {
    switch (field.type) {
      case CustomerPopupConstant.TYPE_FIELD_INPUT:
        return (
          <CustomerInputComponent
            code={field.code}
            field={field}
            label={field.label}
            key={`${field.code}_input`}
            oneRow={oneRow}
            maxLength={field.maxLength}
            required={field.required}
            setFieldValue={setFieldValue}
            setFieldValueByCode={setFieldValueByCode}
            value={currentCustomer[field.code]}
            error={getFieldError(field.code)}
            handleOnblur={handleOnblur}
          />
        );
      case CustomerPopupConstant.TYPE_FIELD_SELECT:
        return (
          <CustomerSelectComponent
            code={field.code}
            field={field}
            label={field.label}
            key={`${field.code}_select`}
            oneRow
            value={currentCustomer[field.code]}
            maxLength={field.maxLength}
            required={field.required}
            setFieldValue={setFieldValue}
            setFieldValueByCode={setFieldValueByCode}
            options={field.options}
            error={getFieldError(field.code)}
          />
        );
      case CustomerPopupConstant.TYPE_FIELD_CHECKBOX:
        return (
          <CustomerCheckBoxComponent
            field={field}
            code={field.code}
            label={field.label}
            key={`${field.code}_checkbox`}
            oneRow={oneRow}
            setFieldValue={setFieldValue}
            setFieldValueByCode={setFieldValueByCode}
            value={currentCustomer[field.code]}
            error={getFieldError(field.code)}
          />
        );
      case CustomerPopupConstant.TYPE_FIELD_COMBOBOX:
        return (
          <CustomerComboBoxComponent
            field={field}
            code={field.code}
            label={field.label}
            key={`${field.code}_input`}
            oneRow
            value={currentCustomer[field.code]}
            maxLength={field.maxLength}
            required={field.required}
            setFieldValue={setFieldValue}
            setFieldValueByCode={setFieldValueByCode}
            error={getFieldError(field.code)}
          />
        );
      case CustomerPopupConstant.TYPE_FIELD_COMBOBOX_SUGGEST:
        return (
          <CustomerComboBoxSuggestComponent
            field={field}
            code={field.code}
            label={field.label}
            key={`${field.code}_input`}
            oneRow
            value={currentCustomer[field.code.replace('_suggest', '')]}
            maxLength={field.maxLength}
            required={field.required}
            options={field.options}
            setFieldValue={setFieldValue}
            setFieldValueByCode={setFieldValueByCode}
            error={getFieldError(field.code)}
          />
        );
      default:
        return '';
    }
  };

  const saveCustomer = () => {
    const customerToSaved = _.cloneDeep(currentCustomer);
    dispatch(CustomerAction.saveCustomerPopup(customerToSaved, updateForOrder));
  };

  const SaveComponent = isLoading ? (
    <Spinner className="loading" animation="border" role="status">
      <span className="visually-hidden" />
    </Spinner>
  ) : (
    <button type="button" className="save" onClick={() => saveCustomer()}>
      {t('Save')}
    </button>
  );

  const modalTitle = (currentEditCustomer && currentEditCustomer.id)
    ? currentEditCustomer.displayName : t('New Customer');

  const showAddressPopup = () => {
    dispatch(AddressAction.showCreateAddressPopup());
  };

  const CustomerDetailContent = isLoadingCustomerDetail ? (
    <div data-scrollbar className="modal-body">
      <div className="loader-product" />
    </div>
    )
    : (
      <>
        <div data-scrollbar ref={setPopupCustomerElement} className="modal-body">
          <div className="box-group">
            {
              customerFields.map((arrField, index) => {
                const isCheckBox = Boolean((arrField.length === 1) &&
                  arrField[0].type === CustomerPopupConstant.TYPE_FIELD_CHECKBOX);
                const className = isCheckBox ? "row form-group form-checkbox" : "row form-group";
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className={className} key={`group_${index}`}>
                    {
                      arrField.map((field) => {
                        return ((arrField.length > 1)
                          ? renderField(field, false)
                          : renderField(field, true));
                      })
                    }
                  </div>
                );
              })
            }
          </div>
          <div className="box-group modal-actions">
            {layout('customer')('customer_popup')('address_button_before')()(this)}
            <div className="row">
              <div className="col-sm-12 toggle-shipping-customer">
                <span>{t('Address')}</span>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                <a className="action" onClick={showAddressPopup} />
              </div>
            </div>
            <div className="address-content">
              <div className="row">
                <CustomerAddressListing
                  addresses={currentCustomer.addresses}
                  defaultAddress={currentCustomer.defaultAddress}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  return (
    <>
      <Modal
        size="lg"
        className="popup-edit-customer"
        dialogClassName="popup-create-customer in"
        show={isShowCustomerPopup}
      >
        <div className="modal-header">
          <button
            type="button"
            className="cancel"
            data-dismiss="modal"
            aria-label={t('Close')}
            onClick={() => cancelPopup()}
          >
            {t('Cancel')}
          </button>
          <h4 className="modal-title">{modalTitle}</h4>
          {SaveComponent}
        </div>
        {CustomerDetailContent}
      </Modal>
      <AddressCustomerPopup currentCustomer={currentCustomer} />
    </>
  );
}

CustomerPopup.className = 'CustomerPopup';
CustomerPopup.propTypes = {};

export default React.memo(FunctionComponentFactory.get(CustomerPopup));
