import React, {
  Fragment,
  useEffect,
  useMemo, useRef,
  useState,
} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import '../../../../assets/CustomDiscountPrice.css';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import Payment from '../Payment.jsx';
import {fire} from '../../../../event-bus';
import CurrencyService from '../../../../service/CurrencyService';
import QuoteService from '../../../../service/checkout/QuoteService';

import CartTotalDiscount from './totals/CartTotalDiscount.jsx';
import CartTotalShipping from './totals/CartTotalShipping.jsx';
import CartTotalTax from './totals/CartTotalTax.jsx';

function CartTotals() {
  const {t} = useTranslation();
  const {currentPage} = useSelector((state) => state.core.checkout.index);
  const {quote} = useSelector((state) => state.core.checkout);

  const [totals, setTotals] = useState([]);
  const [isShowBackdrop, setIsShowBackdrop] = useState(false);

  const backdrop = useRef();

  /**
   *
   * @return {boolean}
   */
  const canShow = useMemo(() => {
    let showOnPages = [Payment.className];
    const eventDataBefore = {showOnPages};

    /* Event cart total construct after */
    fire('cart_totals_init_show_on_pages_after', eventDataBefore);
    showOnPages = eventDataBefore.showOnPages;
    return showOnPages.includes(currentPage);
  }, [currentPage]);

  /**
   *  Add data to totals
   *
   * @param totalsArray
   * @param code
   * @param title
   * @param value
   * @param unit
   * @return rules
   */
  const addToTotals = (totalsArray, code, title, value, unit) => {
    totalsArray.push({
      code,
      title,
      value,
      unit,
    });
  };

  useEffect(() => {
    if (!canShow) {
      return;
    }
    const totalsArray = [];
    const subtotalPrice = QuoteService.getLineItemsSubtotal(quote);
    if (subtotalPrice !== undefined) {
      addToTotals(totalsArray, "subtotal", t('Subtotal'), subtotalPrice, "");
    }

    const totalDiscount = -QuoteService.getOrderDiscountAmount(quote);
    addToTotals(
      totalsArray,
      "discount",
      t('Discount'),
      totalDiscount,
      "",
    );

    const totalShippingPrice = QuoteService.getTotalShippingAmount(quote);
    addToTotals(
      totalsArray,
      "shipping",
      t(quote.shippingLine && !quote.shippingLine.custom && quote.shippingLine.shippingRateHandle ? 'Shipping' : "Add Shipping"),
      totalShippingPrice,
      "",
    );

    const taxAmount = quote.totalTax;
    if (taxAmount) {
      addToTotals(totalsArray, "tax", t('Tax'), taxAmount || 0, "");
    }

    /* Event prepare cart total after */
    fire('view_cart_total_prepare_after', {totalsArray, quote});

    addToTotals(totalsArray, "grand_total", t('Total'), quote.totalPrice || 0, "");
    setTotals(totalsArray);
  }, [quote, canShow, t]);

  const showBackdrop = () => {
    setIsShowBackdrop(true);
    backdrop.current.style.display = "block";
  };

  const hideBackdrop = () => {
    setIsShowBackdrop(false);
    backdrop.current.style.display = "none";
  };

  useEffect(() => {
    if (backdrop.current) {
      document.body.removeChild(backdrop.current);
    }
    backdrop.current = document.createElement("div");
    backdrop.current.className = "modal-backdrop fade in show popover-backdrop";
    backdrop.current.style.display = "none";
    backdrop.current.onclick = () => hideBackdrop();
    document.body.appendChild(backdrop.current);
  }, []);

  const getTemplateTotal = (total) => {
    switch (total.code) {
      case "discount":
        return (
          <CartTotalDiscount
            key={total.code}
            quote={quote}
            total={total}
            showBackdrop={showBackdrop}
            hideBackdrop={hideBackdrop}
            isShowBackdrop={isShowBackdrop}
          />
        );
      case "shipping":
        return (
          <CartTotalShipping
            key={total.code}
            quote={quote}
            total={total}
          />
        );
      case "tax":
        return (
          <CartTotalTax
            key={total.code}
            quote={quote}
            total={total}
            showBackdrop={showBackdrop}
            hideBackdrop={hideBackdrop}
            isShowBackdrop={isShowBackdrop}
          />
        );
      default:
        break;
    }

    const displayValue = CurrencyService.format(Math.abs(total.value));
    return (
      <Fragment key={total.code}>
        <li className={total.code}>
          <span className="mark">{total.title}</span>
          <span className="amount">{displayValue}</span>
        </li>
      </Fragment>
    );
  };

  const getTemplateTotals = () => {
    return totals.map((total) => getTemplateTotal(total));
  };

  return (
    <>
      <div
        className="cart-totals"
        style={{display: canShow ? "block" : "none"}}
      >
        <ul>
          {getTemplateTotals()}
        </ul>
      </div>
    </>
  );
}

CartTotals.className = 'CartTotals';
CartTotals.propTypes = {};

export default FunctionComponentFactory.get(CartTotals);
