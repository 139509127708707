import AbstractResourceModel from "../AbstractResourceModel";

export default class DraftOrderResourceModel extends AbstractResourceModel {
  static className = 'DraftOrderResourceModel';

  resourceName = 'DraftOrder';

  /**
   * Draft Order Calculate
   * @param input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  draftOrderCalculate(input) {
    return this.getResourceOnline().draftOrderCalculate(input);
  }
}
