import React, {useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import QuoteService from '../../../../service/checkout/QuoteService';
import layout from '../../../../framework/Layout';
import QuoteItemService
  from '../../../../service/checkout/quote/QuoteItemService';
import QuoteAction from '../../../action/checkout/QuoteAction';

import CartItem from './CartItem.jsx';

function CartItems() {
  const dispatch = useDispatch();
  const {quote} = useSelector((state) => state.core.checkout);
  const {addedItemIdInQuote, updatedItemIdInQuote} = useSelector((state) => state.core.checkout.index);
  const listRef = useRef();
  const scrollToItem = (itemId) => {
    const elements = listRef.current.getElementsByClassName(`item ${itemId}`);
    if (elements && elements.length) {
      elements[0].scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      listRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (!addedItemIdInQuote) {
      return;
    }
    scrollToItem(addedItemIdInQuote);
    dispatch(QuoteAction.addedItemIdInQuote(null));
  }, [addedItemIdInQuote, dispatch]);

  useEffect(() => {
    if (!updatedItemIdInQuote) {
      return;
    }
    scrollToItem(updatedItemIdInQuote);
    dispatch(QuoteAction.updatedItemIdInQuote(null));
  }, [updatedItemIdInQuote, dispatch]);

  const getItems = useCallback(() => {
    const quoteItems = QuoteService.getItems(quote);
    return _.orderBy(quoteItems, 'id', 'desc');
  }, [quote]);

  return (
    <ul className="minicart" ref={listRef}>
      <div className="minicart-content">
        {layout('checkout')('cart')('cart_items')('cart_items_before')()()}
        {
          getItems().map((item) => {
            return <CartItem quote={quote} item={item} key={QuoteItemService.getItemId(item)} />;
          })
        }
      </div>
    </ul>
  );
}

CartItems.className = 'CartItems';
CartItems.propTypes = {};

export default FunctionComponentFactory.get(CartItems);
