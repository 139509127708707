import React, {useState} from 'react';
import {Form, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import SmoothScrollbar from "smooth-scrollbar";
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

import NumPad from '../../lib/react-numpad';
import FunctionComponentFactory from '../../../../framework/factory/FunctionComponentFactory';
import SessionService from '../../../../service/session/SessionService';
import SessionAction from '../../../action/SessionAction';
import SessionConstant from '../../../constant/SessionConstant';

const ModalCash = (props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {title, subTitle, handleAction, titleAction} = props;

  const setModalSessionElement = (element) => {
    if (!element) {
      return;
    }
    SmoothScrollbar.init(element);
  };

  const [amount, setAmount] = useState(0);
  const [textNote, setTextNote] = useState();

  const handleClickCancel = () => {
    dispatch(SessionAction.setTypeSessionPopup(SessionConstant.CLOSE_ALL_POPUP));
  };

  const handleChangeAmountPut = (val) => {
    setAmount(val);
  };

  const handleChangeTextNote = (event) => {
    setTextNote(event.target.value);
  };

  const handleActionModal = () => {
    handleAction(amount, textNote);
  };

  const getBtnModal = () => {
    const btnClass = textNote?.trim() && amount > 0 ? "btn btn-default" : "btn btn-default disabled";

    return (
      <button
        className={btnClass}
        type="button"
        onClick={handleActionModal}
      >
        {titleAction}
      </button>
    );
  };

  return (
    <>
      <Modal
        size="lg"
        className="fade in popup-session"
        dialogClassName="modal-fixheight"
        show
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="cancel"
              data-dismiss="modal"
              aria-label={t("Close")}
              onClick={handleClickCancel}
            >
              {t('Cancel')}
            </button>
            <h4 className="modal-title">{title}</h4>
          </div>
          <div data-scrollbar ref={setModalSessionElement} className="modal-body">
            <p className="color-gray text-center">
              {subTitle}
            </p>
            <NumPad.CustomNumber
              onChange={(val) => handleChangeAmountPut(val)}
              position="centerRight"
              sync={false}
              isShowAction
              arrow="right"
              value={amount}
            >
              <div
                className="opening-balance"
                data-container="body"
                data-toggle="popover"
                data-placement="right"
              >
                <span className="title">{t('Amount')}</span>
                <span className="price">{SessionService.formatAmount(amount)}</span>
              </div>
            </NumPad.CustomNumber>
            <div className="form-textarea _sm">
              <Form.Control
                as="textarea"
                placeholder={t('Reason (required)')}
                onChange={handleChangeTextNote}
              />
            </div>
          </div>
          <div className="modal-footer ">
            {getBtnModal()}
          </div>
        </div>
      </Modal>
    </>
  );
};

ModalCash.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  titleAction: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired,
};

ModalCash.className = 'ModalCashComponent';
export default FunctionComponentFactory.get(ModalCash);
