import DataResourceFactory from "../../../framework/factory/DataResourceFactory";

import GraphqlAbstract from "./GraphqlAbstract";

export class GraphqlSessionClass extends GraphqlAbstract {
  static className = 'GraphqlSessionClass';

  /**
   * Get Sessions
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getSessions(input) {
    return this.queryWithGQLBuilder(
      'getSessions',
      {
        input: {
          name: 'input',
          type: 'GetSessionsInput',
          value: input,
          require: true,
        },
      },
      [
        {
          items: [
            'id',
            'staff_id',
            'pos_id',
            'opened_at',
            'closed_at',
            'opening_amount',
            'collected_revenue',
          ],
        },
        {
          page_info: ['page_size', 'current_page', 'total_pages'],
        },
        'total_count',
      ],
      {
        operationName: 'getSessions',
      },
    );
  }

  /**
   * Open Session
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  openSession(input) {
    return this.mutationWithGQLBuilder(
      'openSession',
      {
        input: {
          name: 'input',
          type: 'OpenSessionInput',
          value: input,
          require: true,
        },
      },
      [
        {
          session: [
            'id',
            'staff_id',
            'pos_id',
            'location_id',
            'opened_at',
            'opening_amount',
            'status',
            'base_currency_code',
          ],
        },
      ],
      {
        operationName: 'openSession',
      },
    );
  }

  /**
   * Close Session
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  closeSession(input) {
    return this.mutationWithGQLBuilder(
      'closeSession',
      {
        input: {
          name: 'input',
          type: 'CloseSessionInput',
          value: input,
          require: true,
        },
      },
      [
        {
          session: [
            'id',
            'staff_id',
            'pos_id',
            'location_id',
            'opened_at',
            'closed_at',
            'opening_amount',
            'closed_amount',
            'status',
            'shift_currency_code',
            'base_currency_code',
            'closed_note',
          ],
        },
      ],
      {
        operationName: 'closeSession',
      },
    );
  }

  /**
   * Get Session Detail
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  getSessionDetail(input) {
    return this.queryWithGQLBuilder(
      'getSessionDetail',
      {
        input: {
          name: 'input',
          type: 'GetSessionsDetailInput',
          value: input,
          require: true,
        },
      },
      [
        {
          session: [
            'id',
            'staff_id',
            'pos_id',
            'location_id',
            'opened_at',
            'closed_at',
            'opening_amount',
            'closed_amount',
            'status',
            'shift_currency_code',
            'base_currency_code',
            'closed_note',
            'base_closed_amount',
            'base_opening_amount',
            {
              transaction: ['id', 'note', 'value', 'type', 'created_at', 'transaction_increment_id', 'order_increment_id', 'name_payment_method'],
            },
          ],
        },
      ],
      {
        operationName: 'getSessionDetail',
      },
    );
  }

  /**
   * Put Money In
   * @param {*} input
   * @returns {Promise<ApolloQueryResult<*>>}
   */
  addTransaction(input) {
    return this.mutationWithGQLBuilder(
      'addTransaction',
      {
        input: {
          name: 'input',
          type: 'AddTransactionInput',
          value: input,
          require: true,
        },
      },
      [
        {
          transaction: [
            'id',
            'transaction_increment_id',
            'session_id',
            'location_id',
            'value',
            'note',
            'type',
            'base_currency_code',
            'created_at',
          ],
        },
        'success',
        'message',
      ],
      {
        operationName: 'addTransaction',
      },
    );
  }
}

/**
 * @type {GraphqlSessionClass}
 */
export default DataResourceFactory.get(GraphqlSessionClass);
