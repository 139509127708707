import React, {forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import "../../../assets/Print.css";
import {useSelector} from 'react-redux';

import FunctionComponentFactory from '../../../framework/factory/FunctionComponentFactory';
import SessionService from '../../../service/session/SessionService';
import SessionConstant from '../../constant/SessionConstant';

const TemplateReport = forwardRef((props, ref) => {
  const {t} = useTranslation();

  const {totalPutMoneyIn = 0,
        totalTakeMoneyOut = 0,
        totalCustomerPaidCash = 0,
        totalRefundCash = 0,
        totalCashIn = 0,
        totalCashOut = 0,
        totalAmount = 0,
        totalRefunded = 0,
        addTransaction = {}} = props;

  const {sessionSelected} = useSelector((state) => state.core.session.sessionList);

  const session = SessionService.getSessionRender(sessionSelected);
  const sessionIsOpen = session?.status;

  const titleTemplate = sessionIsOpen ? t('X - REPORT') : t('Z - REPORT');

  const sessionID = `${localStorage.getItem('pos_name')}-${session?.opened_at}`;

  const posName = SessionService.getPosName();
  const opened = SessionService.formatDate(session?.opened_at, "MM/DD/YYYY h:mm A");
  const closed = SessionService.formatDate(session?.closed_at, "MM/DD/YYYY h:mm A");
  const openingBalance = SessionService.formatAmount(session?.opening_amount);
  const closedBalance = SessionService.formatAmount(session?.closed_amount);

  const titleTheoretical = sessionIsOpen ? t('Theoretical Cash Amount') : t('Theoretical Closing Balance');

  const theoreticalClosingBalance = (session?.opening_amount + totalCashIn + totalCashOut) || 0;
  const theoreticalCashAmount = SessionService.formatAmount(theoreticalClosingBalance);

  const payIns = SessionService.formatAmount(totalPutMoneyIn);
  const payouts = SessionService.formatAmount(-totalTakeMoneyOut);

  const location = SessionService.getCurrentLocationForReport();

  const netAmount = SessionService.formatAmount(totalAmount + totalRefunded);

  const cashDifferent = (session?.closed_amount) - theoreticalClosingBalance;

  const printedAt = SessionService.formatDate(Date.now(), "ddd, MMM D, YYYY h:mm A");

  const infoPayments = [];

  if (totalCustomerPaidCash || totalRefundCash || totalPutMoneyIn || totalTakeMoneyOut) {
    infoPayments.push({
      title: SessionConstant.CASH_TITLE,
      netAmount: Number(totalCustomerPaidCash) + Number(totalRefundCash),
    });
  }

  for (const [key, value] of Object.entries(addTransaction)) {
    if (key === SessionConstant.TRANSACTION_PUT_IN ||
      key === SessionConstant.TRANSACTION_PAID_CASH ||
      key === SessionConstant.TRANSACTION_TAKE_OUT ||
      key === SessionConstant.TRANSACTION_REFUND_CASH) {
      continue;
    }

    if (Object.prototype.hasOwnProperty.call(addTransaction, `${key}Total`) && value.length) {
      if (Number(addTransaction[`${key}Total`]) === 0) {
        continue;
      }

      infoPayments.push({
        title: value[0]?.name_payment_method,
        netAmount: addTransaction[`${key}Total`],
      });
    }
  }

  const getTemplateRowInTable = (title, display, key, isBold, hasPadding) => {
    const titleClassName = hasPadding ? "t-name t-bundle" : "t-name";
    const show = isBold ? <b>{display}</b> : display;
    const showTitle = isBold ? <b>{title}</b> : title;

    const content = show
    ? (
      <tr key={key}>
        <td>
          <div className={titleClassName}>{showTitle}</div>
        </td>
        <td className="text-right">{show}</td>
      </tr>
    ) : null;

    return content;
  };

  const getTemplateRowOneColBold = (display, key) => {
    return (
      <tr key={key}>
        <td className="text-center">
          <div className="text-center"><b>{display}</b></div>
        </td>
      </tr>
    );
  };

  const getTemplateRowTwoColBold = (title, display, key) => {
    return (
      <tr key={key}>
        <td>
          <div className="text-center"><b>{title}</b></div>
        </td>
        <td className="text-center">
          <div className="text-center"><b>{display}</b></div>
        </td>
      </tr>
    );
  };

  const getTemplatePaymentsReport = () => {
    return (
      infoPayments.map((infoPayment) => {
        return getTemplateRowInTable(
          infoPayment.title,
          SessionService.formatAmount(infoPayment.netAmount),
          infoPayment.title,
          false,
          false,
        );
      })
    );
  };

  const getTableFirst = () => {
    const closedRow = sessionIsOpen
    ? null
    : getTemplateRowInTable(t("Closed"), closed, "Closed", false, false);

    return (
      <table>
        <tbody>
          {
            getTemplateRowInTable(
              t('Location'),
              location,
              'Location',
              false,
              false,
            )
          }
          {
            getTemplateRowInTable(
              t('POS'),
              posName,
              t('POS'),
              false,
              false,
            )
          }
          {
            getTemplateRowInTable(
              t('Opened'),
              opened,
              'Opened',
              false,
              false,
            )
          }
          {closedRow}
        </tbody>
      </table>
    );
  };

  const getTableSecond = () => {
    const firstRow = sessionIsOpen ? getTemplateRowOneColBold(t('NET AMOUNT'), 'title')
    : getTemplateRowTwoColBold(t('NET AMOUNT'), t('CASH DIFFERENCE'), 'title');

    const secondRow = sessionIsOpen
    ? getTemplateRowOneColBold(netAmount, 'amount')
    : getTemplateRowTwoColBold(
        netAmount,
        SessionService.formatAmount(cashDifferent),
        'amount',
      );

    return (
      <table>
        <tbody>
          {firstRow}
          {secondRow}
        </tbody>
      </table>
    );
  };

  const getTableFour = () => {
    const cashDifferentRow = sessionIsOpen
    ? null
    : getTemplateRowInTable(
        t("Cash Difference"),
        SessionService.formatAmount(cashDifferent),
        "Cash Difference",
        false,
        false,
      );

    const closeBalanceRow = sessionIsOpen
      ? null
      : getTemplateRowInTable(
          t("Closing Balance"),
          closedBalance,
          "Closing Balance",
          true,
          false,
        );
    return (
      <table>
        <tbody>
          {
            getTemplateRowInTable(
              t('#Cash'),
              '',
              'Cash',
              true,
              false,
            )
          }
          {
            getTemplateRowInTable(
              t('Opening Balance'),
              openingBalance,
              'Opening Balance',
              false,
              false,
            )
          }

          {
            getTemplateRowInTable(
              t('Cash Sales'),
            SessionService.formatAmount(totalCustomerPaidCash),
              'Cash Sales',
              false,
              false,
            )
          }
          {
            getTemplateRowInTable(
              t('Cash Refund'),
              SessionService.formatAmount(totalRefundCash),
              'Cash Refund',
              false,
              false,
            )
          }
          {
            getTemplateRowInTable(
              t('Pay Ins'),
              payIns,
              'Pay Ins',
              false,
              false,
            )
          }
          {
            getTemplateRowInTable(
              t('Payouts'),
              payouts,
              'Payouts',
              false,
              false,
            )
          }
          {
            getTemplateRowInTable(
              titleTheoretical,
              theoreticalCashAmount,
              titleTheoretical,
              true,
              false,
            )
          }
          {cashDifferentRow}
          {closeBalanceRow}
        </tbody>
      </table>
    );
  };

  const getTableFive = () => {
    return (
      <table>
        <tbody>
          {
            getTemplateRowInTable(
              `#${t('Payment Method')}`,
              '',
              'Payment Method',
              true,
              false,
            )
          }
          {getTemplatePaymentsReport()}
        </tbody>
      </table>
    );
  };

  const getTableSix = () => {
    return (
      <table>
        <tbody>
          {
            getTemplateRowInTable(
              `#${t('Sales')}`,
              '', 'Sales',
              true,
              false,
            )
          }
          {
            getTemplateRowInTable(
              t('Total Amount'),
              SessionService.formatAmount(totalAmount),
              'Total Amount',
              false,
              false,
            )
          }
          {
            getTemplateRowInTable(
              t('Refund Amount'),
              SessionService.formatAmount(totalRefunded),
              'Refund Amount',
              false,
              false,
            )
          }
          {
            getTemplateRowInTable(
              t('Net Amount'),
              netAmount,
              'Net Amount',
              false,
              false,
            )
          }
        </tbody>
      </table>
    );
  };

  return (
    <div className="" ref={ref} >
      <div className="block-printreceipt">
        <div className="title">{titleTemplate}</div>
        <p className="">
          {t(`Session ID: {{sessionID}}`, {sessionID})}
        </p>
        <br />
        {getTableFirst()}
        <br />
        {getTableSecond()}
        <br />
        {getTableFour()}
        <br />
        {getTableFive()}
        <br />
        {getTableSix()}
        <br />

        <div className="text-center">
          <div className="reprint">
            <b>{t("Printed At: ") + printedAt}</b>
          </div>
        </div>
      </div>
    </div>
  );
});

TemplateReport.propTypes = {
  totalPutMoneyIn: PropTypes.number,
  totalTakeMoneyOut: PropTypes.number,
  totalCustomerPaidCash: PropTypes.number,
  totalRefundCash: PropTypes.number,
  totalCashIn: PropTypes.number,
  totalCashOut: PropTypes.number,
  totalAmount: PropTypes.number,
  totalRefunded: PropTypes.number,
  addTransaction: PropTypes.object,
};

TemplateReport.displayName = 'TemplateReport';
TemplateReport.className = 'TemplateReportComponent';

export default FunctionComponentFactory.get(TemplateReport);
