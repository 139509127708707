import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import FunctionComponentFactory
  from '../../../../framework/factory/FunctionComponentFactory';
import ShippingAction from '../../../action/ShippingAction';
import CurrencyService from '../../../../service/CurrencyService';

const ShippingMethodListing = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const {shippingMethods = [], selectedShippingMethod} = useSelector((state) => state.core.shipping.shippingPopup);
  const {isLoadingShippingMethod} = useSelector((state) => state.core.shipping.shippingMethod);

  const handleChangeShippingMethod = (shipping) => {
    dispatch(ShippingAction.setSelectedShippingMethod(shipping));
  };

  const getLoading = () => {
    if (!isLoadingShippingMethod) {
      return;
    }

    return <div className="loader-product" />;
  };

  const getShippingEmpty = () => {
    if (shippingMethods?.length || isLoadingShippingMethod) {
      return null;
    }

    return (
      <li>
        <span className="empty">
          {t('Sorry, there is no available shipping method.')}
        </span>
      </li>
    );
  };

  const isChecked = (shipping) => {
    return selectedShippingMethod?.handle === shipping?.handle;
  };

  const getListShipping = () => {
    if (!shippingMethods?.length || isLoadingShippingMethod) {
      return null;
    }

    return (
      <ul>
        { shippingMethods && shippingMethods.map((shipping) => {
          const title = shipping.title || '';
          const amount = shipping?.price?.amount || 0;
          const key = shipping?.handle || Math.random();
          const fee = CurrencyService.format(amount);
          return (
            <li key={`shipping-${key}`} >
              <label htmlFor={key}>
                <input
                  id={key}
                  type="radio"
                  name="radio1"
                  aria-label={t("radioOption1")}
                  onChange={() => handleChangeShippingMethod(shipping)}
                  checked={isChecked(shipping)}
                />
                <span className="shipping">
                  <span className="title">{title}</span>
                  <span className="value">
                    {fee}
                  </span>
                </span>
              </label>
            </li>);
        })}
      </ul>

    );
  };

  return (
    <ul className="list">
      {getListShipping()}
      {getShippingEmpty()}
      {getLoading()}
    </ul>
  );
};

ShippingMethodListing.propTypes = {
};

ShippingMethodListing.className = 'ShippingMethodListingComponent';
export default FunctionComponentFactory.get(ShippingMethodListing);
