import OrderConstant from '../../constant/OrderConstant';

const initialState = {
  order: {},
  isLoading: true,
  isReprint: false,
  isOpenSendEmailPopup: false,
  isOpenAddNotePopup: false,
  isAddNoteSuccess: false,
  isOpenOrderCancelPopup: false,
  isOpenFulfillItemPage: false,
  isLoadingBtnFulfill: false,
  dataAfterFulfill: null,
  fulfillmentOrderLineItems: null,
  isOpenReorderConfirmPopup: false,
  isRedirectToCheckoutPage: false,
  isLoadingReorder: false,
};

const orderDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case OrderConstant.GET_ORDER:
      return {
        ...state,
        isLoading: true,
      };
    case OrderConstant.GET_ORDER_SUCCESS:
      return {
        ...state,
        order: action.order,
        isLoading: false,
      };
    case OrderConstant.GET_ORDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case OrderConstant.REPRINT_ORDER:
      return {
        ...state,
        isReprint: true,
      };
    case OrderConstant.SET_IS_LOADING_REPRINT:
      return {
        ...state,
        isReprint: action.isReprint,
      };
    case OrderConstant.REPRINT_ORDER_SUCCESS:
      return {
        ...state,
        isReprint: false,
      };
    case OrderConstant.REPRINT_ORDER_FAILED:
      return {
        ...state,
        isReprint: false,
      };
    case OrderConstant.SET_IS_OPEN_SEND_EMAIL_POPUP:
      return {
        ...state,
        isOpenSendEmailPopup: action.isOpenSendEmailPopup,
      };
    case OrderConstant.SET_IS_OPEN_ADD_NOTE_POPUP:
      return {
        ...state,
        isOpenAddNotePopup: action.isOpenAddNotePopup,
      };
    case OrderConstant.ADD_NOTE:
      return {
        ...state,
        isOpenAddNotePopup: true,
      };
    case OrderConstant.ADD_NOTE_SUCCESS:
      return {
        ...state,
        isOpenAddNotePopup: false,
        isAddNoteSuccess: true,
      };
    case OrderConstant.ADD_NOTE_FAILED:
      return {
        ...state,
        isOpenAddNotePopup: false,
        isAddNoteSuccess: false,
      };
    case OrderConstant.SET_IS_OPEN_ORDER_CANCEL_POPUP:
      return {
        ...state,
        isOpenOrderCancelPopup: action.isOpenOrderCancelPopup,
      };
    case OrderConstant.UPDATE_ORDER:
      return {
        ...state,
        order: {...state.order, ...action.updateData},
      };
    case OrderConstant.SET_IS_OPEN_FULFILL_ITEM_PAGE:
      return {
        ...state,
        isOpenFulfillItemPage: action.isOpenFulfillItemPage,
      };
    case OrderConstant.GET_FULFILLMENT_ORDER:
      return {
        ...state,
        fulfillmentOrderLineItems: action.fulfillmentOrderLineItems,
      };
    case OrderConstant.GET_FULFILLMENT_ORDER_SUCCESS:
      return {
        ...state,
        order: {...action.newOrder},
      };
    case OrderConstant.GET_FULFILL_ITEMS_PAGE: {
      return {
        ...state,
        isLoadingBtnFulfill: true,
      };
    }
    case OrderConstant.CREATE_FULFILLMENT_ORDER: {
      return {
        ...state,
        isLoadingBtnFulfill: true,
      };
    }
    case OrderConstant.GET_FULFILL_ITEMS_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadingBtnFulfill: false,
        isOpenFulfillItemPage: true,
        order: {...action.newOrder},
      };
    }
    case OrderConstant.CREATE_FULFILLMENT_ORDER_SUCCESS: {
      return {
        ...state,
        isOpenFulfillItemPage: false,
        order: {...action.newOrder},
        isLoadingBtnFulfill: false,
        dataAfterFulfill: {
          type: OrderConstant.SUCCESS,
          message: action?.message,
          statusOrder: action.statusOrder,
        },
      };
    }
    case OrderConstant.CREATE_FULFILLMENT_ORDER_FAILED: {
      return {
        ...state,
        isOpenFulfillItemPage: false,
        isLoadingBtnFulfill: false,
        dataAfterFulfill: {
          type: OrderConstant.FAILED,
          message: action?.message,
        },
      };
    }
    case OrderConstant.RESET_DATE_AFTER_FULFILL: {
      return {
        ...state,
        dataAfterFulfill: null,
      };
    }
    case OrderConstant.SET_IS_REDIRECT_TO_CHECK_OUT_PAGE:
      return {
        ...state,
        isRedirectToCheckoutPage: action.isRedirectToCheckoutPage,
      };
    case OrderConstant.HANDLE_REORDER:
      return {
        ...state,
        isLoadingReorder: true,
      };
    case OrderConstant.SET_IS_LOADING_REORDER:
      return {
        ...state,
        isLoadingReorder: action.isLoadingReorder,
      };
    case OrderConstant.HANDLE_REORDER_SUCCESS:
      return {
        ...state,
        isLoadingReorder: false,
        isRedirectToCheckoutPage: true,
      };
    case OrderConstant.HANDLE_REORDER_FAILED:
      return {
        ...state,
        isLoadingReorder: false,
      };
    case OrderConstant.SET_IS_OPEN_REORDER_CONFIRM_POPUP:
      return {
        ...state,
        isOpenReorderConfirmPopup: action.isOpenReorderConfirmPopup,
      };
    case OrderConstant.SET_IS_LOADING_ORDER_DETAIL_PAGE:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export default orderDetailReducer;
